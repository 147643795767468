import { render, staticRenderFns } from "./TheClients.vue?vue&type=template&id=599977f4&"
import script from "./TheClients.vue?vue&type=script&lang=js&"
export * from "./TheClients.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports