/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import { navySeal } from '@jack/provider';
import { isActionAllowed } from '@jack/dataStores/$utils/helpers';

export default {
  namespaced: true,
  state: {
    notificationList: null,
  },
  mutations: {
    SET_NOTIFICATION_LIST(state, newData) { state.notificationList = newData; },
  },
  getters: {
    getterNotificationList: (state) => state.notificationList,
    getterNotificationCount: (state) => {
      if (!state.notificationList) return 0;
      return state.notificationList.filter(({ read }) => !read).length;
    },
  },
  actions: {
    report({ commit, rootGetters, dispatch }, { payload = {}, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'NOTIFICATION.REPORT', dispatch)) return undefined;

      return navySeal.apiWsDivision.user.notification.report({ payload, httpQuery })
        .then((notifications) => {
          commit('SET_NOTIFICATION_LIST', notifications);
        });
    },
    patch({
      rootGetters, getters, commit, dispatch,
    }, { payload = {}, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'NOTIFICATION.PATCH', dispatch)) return undefined;

      return navySeal.apiWsDivision.user.notification.patch({ payload, httpQuery })
        .then((notification) => {
          const notificationList = getters.getterNotificationList;
          commit(
            'SET_NOTIFICATION_LIST',
            notificationList.reduce((acc, el) => {
              if (Number(el.nid) !== Number(payload.nid)) {
                acc.push(el);
                return acc;
              }

              acc.push({ ...el, ...notification });
              return acc;
            }, []),
          );
        });
    },
  },
};
