<template>
  <header
    :class="['header-top', {
      'mobile-view': mobileView,
      'computer-view': !mobileView
    }]"
  >
    <div class="header-nav top">
      <div class="header-logo">
        <b-image
          :src="theme ? logo.white : logo.black"
          alt="DEVMOOD logo"
        />
      </div>

      <div class="header-nav-item">
        <b-button
          type="is-tertiary"
          @click="$router.push({ path: '/notifications' })"
        >
          <b-icon
            icon="bell"
            pack="fa"
            class="m-0 p-0"
          />
          <div
            v-if="notificationCount"
            class="badge notification fly-over"
          >
            {{ notificationCount }}
          </div>
        </b-button>
        <b-button
          type="is-tertiary"
          @click="openDropdown('top')"
        >
          <InitialsPicture
            v-if="fullName"
            :name="fullName"
            class="item"
            :size="40"
          />
        </b-button>
        <template v-if="open === 'top'">
          <div class="header-dropdown top">
            <div class="header-dropdown-content">
              <template v-for="el in menuResponsive.top">
                <div
                  v-if="el.active"
                  :key="el.name"
                >
                  <b-button
                    v-if="!el.event"
                    :icon-pack="el.pack"
                    :icon-left="el.icon"
                    type="is-tertiary"
                    tag="router-link"
                    :to="el.path"
                    :disabled="el.disabled"
                    @click.native="redirect(el, false)"
                  />
                </div>
              </template>
              <b-button
                v-if="currentEnv === 'dev'"
                type="is-tertiary"
                icon-pack="fa"
                :icon-left="devMode ? 'laptop' : 'laptop-code'"
                @click="devMode = !devMode"
              />
              <b-button
                type="is-tertiary"
                icon-pack="fa"
                :icon-left="theme ? 'sun' : 'moon'"
                :class="[{
                  'text-color-9': !theme,
                  'text-color-4': theme,
                }]"
                @click="updateTheme(!theme)"
              />
              <b-button
                v-if="currentEnv === 'dev'"
                type="is-tertiary"
                icon-pack="fa"
                icon-left="rocket"
                :class="[{
                }]"
                @click="toggleWebsocket()"
              />
              <!-- 'text-color-2': !socketIsConnected,
                  'text-color-5': socketIsConnected, -->
              <b-button
                type="is-tertiary"
                icon-pack="fa"
                icon-left="power-off"
                @click="logout()"
              />
            </div>
          </div>
        </template>
      </div>
    </div>
  </header>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */
import { mapGetters, mapActions } from 'vuex';

import SocketMixins from '@jack/socket/$utils/SocketMixins.mixins';
import TheMenuMixins from '../$mixins/TheMenuMixins.menu.mixins';

const logoWhite = require('@jack/public/assets/images/icons/DEVMOOD_TEXT_WHITE_SVG.svg');
const logoBlack = require('@jack/public/assets/images/icons/DEVMOOD_TEXT_BLACK_SVG.svg');

export default {
  name: 'MenuTopMobile',
  mixins: [
    TheMenuMixins,
    SocketMixins,
  ],
  props: {
    mobileView: { type: Boolean, required: true },
  },
  data() {
    return {
      currentPath: {
        domain: '/dashboard',
        path: '/dashboard/user',
      },
      logo: {
        white: logoWhite,
        black: logoBlack,
      },
    };
  },
  computed: {
    ...mapGetters({
      open: 'TheMenu/getterOpenSubMenu',
      myAuthenticate: 'TheMenu/myAuthenticate',
      notificationCount: 'TheMenu/notificationCount',
    }),
    fullName() {
      if (!this.myAuthenticate || !this.myAuthenticate.details) return '';
      const { details: { firstName, lastName } } = this.myAuthenticate;
      return `${firstName} ${lastName || ''}`;
    },
  },
  methods: {
    ...mapActions({
      UP_openSubMenu: 'TheMenu/UP_openSubMenu',
    }),
    openDropdown(item) {
      if (this.open && this.open === item) {
        this.UP_openSubMenu('');
        return;
      }
      this.UP_openSubMenu(item);
    },
    redirect(args, redirect = true) {
      return Promise.resolve()
        .then(() => { if (this.open) this.UP_openSubMenu(''); })
        .then(() => {
          if (this.currentPath.path.includes(this.$route.path)) return;
          if (!redirect) return;
          this.currentPath = args;
          this.$router.push({ path: args.path });
        });
    },
  },
};
</script>
