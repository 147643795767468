/* eslint-disable max-len */
import { SnackbarProgrammatic as Snackbar } from 'buefy';
import { dispatchAndForward, dispatchFailure, snackBarFailure } from '../$utils/helpers';

export default {
  getters: {
    jobList: (_state, _getters, _rootState, rootGetters) => {
      const { 'Job/getterJobList': jobList } = rootGetters;
      return jobList;
    },
    job: (_state, _getters, _rootState, rootGetters) => {
      const { 'Job/getterJob': job } = rootGetters;
      return job;
    },
  },
  actions: {
    reportJobList({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'Job/report',
        context: { payload, httpQuery },
        textNotifier: 'report job list.',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({ message: 'Unable to report job list.', ...snackBarFailure });
          return dispatchFailure(action);
        });
    },
    getJob({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'Job/get',
        context: { payload, httpQuery },
        textNotifier: 'get job.',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({ message: 'Unable to get job.', ...snackBarFailure });
          return dispatchFailure(action);
        });
    },
    bulkInsertJobs({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'Company/bulkInsertJobs/create',
        context: { payload, httpQuery },
        textNotifier: 'add a list of jobs.',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({ message: 'Unable add a list of jobs.', ...snackBarFailure });
          return dispatchFailure(action);
        });
    },
    patchStateJob({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'Job/patch',
        context: { payload, httpQuery },
        textNotifier: 'patch job.',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({ message: 'Unable to patch job.', ...snackBarFailure });
          return dispatchFailure(action);
        });
    },
  },
};
