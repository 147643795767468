export default {
  computed: {
    colors() {
      return [
        '132, 132, 132',
        '255, 153, 146',
        '255, 194, 159',
        '255, 217, 160',
        '163, 223, 129',
        '166, 231, 176',
        '144, 226, 213',
        '144, 195, 247',
        '165, 155, 237',
        '210, 178, 246',
        '253, 174, 243',
        '255, 156, 193',
      ];
    },
  },
};
