<template>
  <div>
    TheBreadcrumbs
  </div>
</template>

<script>
export default {
  name: 'TheBreadcrumbs',
};
</script>
