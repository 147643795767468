import TheTemplates from './TheTemplates';
import TheTemplatesAdd from './TheTemplatesAdd';

export default [
  {
    path: '/templates',
    name: 'Templates',
    component: TheTemplates,
    meta: {
      hasMenu: true,
      viewStoreNavigation: 'TheTemplates',
      requireAuth: true,
    },
    children: [
      {
        path: '/templates/add',
        name: 'Templates - Add template',
        components: { add: TheTemplatesAdd },
        meta: {
          hasMenu: true,
          viewStoreNavigation: 'TheTemplates',
          requireAuth: true,
        },
      },
    ],
  },
];
