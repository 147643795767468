<template>
  <b-collapse
    v-if="open"
    :open="open"
    aria-id="contentAppointmentId"
    class="collapse"
  >
    <div class="collapse-content">
      <div class="content">
        <form>
          <template v-if="(options.search && personList.length)">
            <b-field :label="$_('Link a contact to the appointment')">
              <article
                v-if="selected"
                class="person is-justify-content-center"
              >
                <div class="content is-flex is-align-items-center is-justify-content-space-between">
                  <Contact
                    :pid="selected.pid"
                    :details="selected.details"
                  />
                  <div class="buttons">
                    <b-button
                      type="is-tertiary"
                      icon-left="xmark"
                      icon-pack="fa"
                      @click="selected = null"
                    />
                  </div>
                </div>
              </article>
              <b-autocomplete
                v-model="inputSearch"
                :data="personFiltered"
                field="fullName"
                :placeholder="$_('Search a by name, email, number...')"
                icon="magnifying-glass"
                icon-pack="fa"
                @select="option => selected = option"
              >
                <template slot-scope="props">
                  <Contact
                    :pid="props.option.pid"
                    :details="props.option.details"
                    :is-clickable="false"
                  />
                </template>
              </b-autocomplete>
            </b-field>
          </template>

          <b-field :label="$_('Starts at')">
            <b-datetimepicker
              v-model="appointment.date"
              :placeholder="$_('Click to select...')"
              icon="calendar"
              :icon-right="appointment.date ? 'close-circle' : ''"
              icon-pack="fa"
              :datetime-formatter="time => $moment(time).format('YYYY-MM-DD HH:mm:ss')"
              :min-datetime="new Date()"
              :timepicker="timepicker"
              @icon-right-click="appointment.date = null"
            />
          </b-field>

          <b-field
            v-if="appointment.date"
            :label="$_('Ends at')"
          >
            <b-datetimepicker
              v-model="end"
              :placeholder="$_('Click to select...')"
              icon="calendar"
              :icon-right="end ? 'close-circle' : ''"
              icon-pack="fa"
              position="is-top-right"
              :min-datetime="appointment.date"
              :timepicker="timepicker"
              @icon-right-click="end = null"
            />
          </b-field>

          <b-field :label="$_('Title')">
            <b-input
              v-model="appointment.label"
              required
            />
          </b-field>

          <b-field :label="$_('Description')">
            <b-input
              v-model="appointment.description"
              type="textarea"
            />
          </b-field>

          <br>

          <div class="buttons">
            <b-button
              type="is-primary"
              icon-pack="fa"
              icon-left="check"
              expanded
              :disabled="disable"
              @click="addAppointment()"
            />
          </div>
        </form>
      </div>
    </div>
  </b-collapse>
</template>

<script>
/* eslint-disable-next-line */
import { debounce } from '@jack/addons/$utils/helpers';

export default {
  name: 'AddAppointment',
  props: {
    open: { type: Boolean, required: true },
    personList: { type: Array, required: true },
    client: { type: Object, default: () => {} },
    options: { type: Object, required: true },
  },
  data() {
    return {
      appointment: {
        date: null,
        label: null,
        description: null,
      },
      end: null,
      search: '',
      inputSearch: '',
      selected: null,
      project: null,
    };
  },
  computed: {
    timepicker() {
      const min = new Date();
      min.setHours(6);
      const max = new Date();
      max.setHours(20);

      return {
        incrementMinutes: 10,
        minTime: min,
        maxTime: max,
      };
    },
    disable() {
      if (!this.duration || !this.appointment.label) return true;
      return false;
    },
    duration() {
      if (!this.appointment.date || !this.end) return 0;
      const end = this.$moment(this.end);
      const start = this.$moment(this.appointment.date);
      return end.diff(start, 'minutes');
    },
    personFiltered() {
      if (!this.search) return this.personList;
      return this.personList.reduce((acc, person) => {
        const { details: { fullName, email, number } } = person;
        const _fullName = fullName ? fullName.toLowerCase() : null;
        const _email = email ? email.toLowerCase() : '';
        const _number = number || '';
        const _search = this.search.toLowerCase();

        if (
          _fullName.includes(_search)
          || _email.includes(_search)
          || _number.includes(_search)
        ) {
          acc.push(person);
        }
        return acc;
      }, []);
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    inputSearch: debounce(function (newVal) {
      this.search = newVal;
    }),
  },
  methods: {
    addAppointment() {
      this.$emit('addAppointment', {
        createdAt: this.$moment().format(),
        state: 'CONFIRMED',
        details: {
          ...this.appointment,
          clientCid: this.client ? this.client.cid : null,
          personPid: this.selected ? this.selected.pid : null,
          projectPid: this.project ? this.project.pid : null,
          companyCid: this.options.cid ? this.options.cid : null,
          duration: this.duration,
        },
      });
    },
  },
};
</script>
