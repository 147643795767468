/* eslint-disable import/no-extraneous-dependencies */
import { navySeal } from '@jack/provider';
import { isActionAllowed } from '@jack/dataStores/$utils/helpers';

export default {
  namespaced: true,
  state: { },
  mutations: {},
  getters: {},
  actions: {
    post({ dispatch, commit, rootGetters }, { payload = {}, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'PERSON_TAG.CREATE', dispatch)) return undefined;

      return navySeal.apiWsMi.person.tag.post({ payload, httpQuery })
        .then((tag) => {
          const { 'Person/getterPerson': person } = rootGetters;
          if (!person.tags) person.tags = [tag.tagTid];
          else person.tags.push(tag.tagTid);
          commit('Person/SET_PERSON', person, { root: true });
          return tag;
        });
    },
    delete({ commit, rootGetters, dispatch }, { payload = {}, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'PERSON_TAG.DELETE', dispatch)) return undefined;

      return navySeal.apiWsMi.person.tag.delete({ payload, httpQuery })
        .then(() => {
          const { 'Person/getterPerson': person } = rootGetters;
          commit(
            'Person/SET_PERSON',
            {
              ...person,
              tags: person.tags.filter((tid) => tid !== payload.tid),
            },
            { root: true },
          );
          return true;
        });
    },
  },
};
