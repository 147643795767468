/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-param-reassign */
import { io as SocketIO } from 'socket.io-client';

import SMixin from './$mixins';
import SEmitter from './$emitter';
import SLogger from './$logger';
import SListener from './$listener';

const {
  VUE_APP_ANGLICO_BASE_URL: _connection,
} = process.env;

export default class SocketIoService {
  constructor({
    connection, vuex, debug, options = { withCredentials: true },
  }) {
    SLogger.debug = debug;
    this.io = this.connect(connection, options);
    this.emitter = new SEmitter(vuex, this);
    this.listener = new SListener(vuex, this);
    this.store = vuex;
    this.instance = this;
  }

  install(Vue) {
    Vue.prototype.$socket = this.io;
    Vue.prototype.$vueSocketIo = this;
    Vue.mixin(SMixin);

    SLogger.info('Initialized');
  }

  connect(connection = _connection, options) {
    if (connection && typeof connection === 'object') {
      SLogger.info('Received socket.io-client instance');
      return connection;
    }
    if (typeof connection === 'string') {
      SLogger.info('Received connection string');
      const io = SocketIO(connection, options);
      // this.emitter = new SEmitter(this.store, this.instance);
      // this.listener = new SListener(this.instance);

      /* eslint-disable-next-line */
      return this.io = io;
    }
    throw new Error('Unsupported connection type');
  }

  reconnect(connection = _connection, options) {
    this.disconnect();
    this.connect(connection, { withCredentials: true, ...options });
  }

  disconnect() {
    SLogger.info('Disconnect');
    this.io.disconnect();
  }

  hardQuit() {
    this.io.on('quit', () => {});
  }
}
