var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{class:['default-picture', {
    'bg1-lighten-30': _vm.color === 1,
    'bg2-lighten-30': _vm.color === 2,
    'bg3-lighten-30': _vm.color === 3,
    'bg4-lighten-30': _vm.color === 4,
    'bg5-lighten-30': _vm.color === 5,
    'bg6-lighten-30': _vm.color === 6,
    'bg7-lighten-30': _vm.color === 7,
    'bg8-lighten-30': _vm.color === 8,
    'bg9-lighten-30': _vm.color === 9,
    'bg10-lighten-20': _vm.color === 10,
    'bg11-lighten-10': _vm.color === 11,
    'bg12-lighten-30': _vm.color === 12,
    'is-8x8': _vm.size === 8,
    'is-16x16': _vm.size === 16,
    'is-18x18': _vm.size === 18,
    'is-24x24': _vm.size === 24,
    'is-32x32': _vm.size === 32,
    'is-40x40': _vm.size === 40,
    'is-48x48': _vm.size === 48,
    'is-64x64': _vm.size === 64,
    'is-96x96': _vm.size === 96,
    'is-128x128': _vm.size === 128,
  }],on:{"click":function($event){return _vm.$emit('click')}}},[_c('span',{class:['title is-small', {
      'text-color-1-60': _vm.color === 1,
      'text-color-2-60': _vm.color === 2,
      'text-color-3-60': _vm.color === 3,
      'text-color-4-60': _vm.color === 4,
      'text-color-5-60': _vm.color === 5,
      'text-color-6-60': _vm.color === 6,
      'text-color-7-60': _vm.color === 7,
      'text-color-8-60': _vm.color === 8,
      'text-color-9-60': _vm.color === 9,
      'text-color-10-60': _vm.color === 10,
      'text-color-11-60': _vm.color === 11,
      'text-color-12-60': _vm.color === 12,
    }]},[_vm._v(" "+_vm._s(_vm.initials)+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }