import { navySeal } from '../../../$providers';
import { isActionAllowed } from '../../$utils/helpers';

export default {
  namespaced: true,
  state: {
    accountDashboardView: {},
  },
  mutations: {
    SET_ACCOUNT_DASHBOARD_VIEW(state, newData) { state.accountDashboardView = newData; },
  },
  getters: {
    getterAccountDashboardView: (state) => state.accountDashboardView,
  },
  actions: {
    get({ commit, rootGetters, dispatch }, { payload, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'ACCOUNT_DASHBOARD_VIEW.GET', dispatch)) return undefined;

      return navySeal.apiWsDivision.account.accountDashboardView.get({ payload, httpQuery })
        .then((accountDashboardView) => {
          commit('SET_ACCOUNT_DASHBOARD_VIEW', accountDashboardView);
        });
    },
  },
};
