var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{class:[{ 'body-locked': _vm.lockBody }],attrs:{"id":"app"}},[_c('div',{class:[{
      'app': !_vm.isAuthPage,
      'w-100': _vm.isAuthPage,
      'mobile-view': _vm.windowWidth < 601,
      'is-flex-direction-column': _vm.windowWidth >= 601,
    }]},[(_vm.routeHasMenu && !_vm.isAuthPage && _vm.windowWidth < 601)?_c('MenuTopMobile',{attrs:{"mobile-view":_vm.windowWidth < 601}}):_vm._e(),_c('div',{class:['app-container', { 'computer-view': _vm.windowWidth >= 601 && !_vm.isAuthPage}]},[(_vm.windowWidth >= 601 && _vm.routeHasMenu)?_c('header',{class:['app-header', { 'responsive': _vm.responsive }]},[_c('TheMenu',{attrs:{"responsive":_vm.responsive}})],1):_vm._e(),_c('div',{class:['app-content', {
          'responsive': _vm.responsive,
          'app-mobile': _vm.windowWidth < 601,
          'no-menu': !_vm.routeHasMenu
        }]},[_c('transition',{attrs:{"name":"fade"}},[(_vm.authorizedAccess)?_c('router-view'):_vm._e()],1),(_vm.hasWsNotifications)?_c('WsNotifiers',{attrs:{"ws-notifiers":_vm.wsNotifiers},on:{"deleteNotif":function($event){return _vm.closeWsNotif($event)}}}):_vm._e()],1)]),(_vm.windowWidth < 601 && !_vm.isAuthPage && _vm.routeHasMenu)?_c('MenuBottomMobile',{on:{"openModal":function($event){return _vm.openModal($event)}}}):_vm._e()],1),_c('b-modal',{attrs:{"has-modal-card":"","trap-focus":"","destroy-on-hide":false,"aria-role":"dialog","aria-label":"Example Modal","close-button-aria-label":"Close","aria-modal":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{staticClass:"modal-container"},[_c(_vm.modalCmpActionAdd,{tag:"componet",on:{"close":function($event){_vm.modalOpenActionAdd = false}}})],1)]},proxy:true}]),model:{value:(_vm.modalOpenActionAdd),callback:function ($$v) {_vm.modalOpenActionAdd=$$v},expression:"modalOpenActionAdd"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }