<template>
  <section class="pr-container">
    <template v-if="projectsList && projectsList.length">
      <article
        v-for="project in projectsList"
        :key="project.pid"
        class="is-flex is-align-items-center mb-4"
      >
        <ProjectCard :project="project" />
        <div class="buttons">
          <b-button
            type="is-tertiary"
            icon-pack="fa"
            icon-left="trash"
            class="text-color-1-20 mx-3"
            @click="$emit('deleteProject', { pid: project.pid })"
          />
        </div>
      </article>
    </template>
    <NoData
      v-else
      :title="$_('You have no projects related to this customer.')"
      :content="$_('Register a new project with this client to get access to new features')"
      class="mt-5"
    />
  </section>
</template>

<script>
export default {
  name: 'ProjectList',
  props: {
    projectsList: { type: Array, default: () => [] },
    conditions: { type: Object, required: true },
    currencies: { type: Object, required: true },
  },
};
</script>
