<template>
  <section>
    <div class="dashboard">
      <div class="content">
        <DevDetails :cnt="{name: 'formatClientList', obj: formatClientList}" />
        <template v-if="formatClientList.length">
          <div
            :class="['cardDashboard', {
              'col-1': gridResponsive === 1,
              'col-2': gridResponsive === 2,
              'col-3': gridResponsive === 3,
              'col-4': gridResponsive === 4,
              'col-5': gridResponsive === 5,
              'col-6': gridResponsive === 6,
            }]"
          >
            <article
              v-for="client in formatClientList"
              :key="client.cid"
              class="card no-border walletCard"
            >
              <div class="card-header border-0">
                <div>
                  <div class="card-title">
                    <p class="has-text-secondary subtext is-big end">
                      {{ $moment(client.createdAt).format('YYYY-MM-DD') }}
                    </p>
                    <p>{{ client.firstName }} {{ client.lastName }}</p>
                  </div>
                </div>
                <div class="card-action">
                  <div class="buttons">
                    <b-button
                      type="is-ghost"
                      icon-pack="fa"
                      icon-left="eye"
                      tag="router-link"
                      :to="`/clients/overview-view/client/${client.cid}`"
                    />
                  </div>
                </div>
              </div>
              <div
                v-if="client.socialList && client.socialList.length"
                class="card-content pl-0 pr-0 pt-0"
              >
                <div

                  class="buttons is-flex"
                >
                  <b-button
                    v-for="(social, i) in client.socialList"
                    :key="i"
                    type="is-ghost"
                    icon-pack="fa"
                    :icon-left="social.platformName.toLowerCase()"
                    tag="a"
                    :href="`https://www.${social.platformName.toLowerCase()}.com/${social.username}`"
                    target="blank"
                    class="m-0"
                  />
                </div>
              </div>
            </article>
          </div>
        </template>
      </div>
    </div>
    <router-view name="overviewClientSidePanel" />
    <router-view name="overviewClientSidePannelEdit" />
    <router-view name="overviewClientSidePannelClient" />
  </section>
</template>

<script>
/* eslint-disable max-len */
import { mapGetters } from 'vuex';

export default {
  name: 'TheClientsOverviewView',
  props: {
    userClientList: { type: Object, required: true },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      windowWidth: 'TheMenu/getterWindowWidth',
    }),
    formatClientList() {
      if (!this.userClientList) return [];

      const datas = Object.values(this.userClientList).reduce((acc, row) => {
        const { details: { clientCid, ...details }, ...rest } = row;
        acc.push({ ...details, ...rest });

        return acc;
      }, []);

      return datas.sort((a, b) => this.$moment(b.createdAt).unix() - this.$moment(a.createdAt).unix());
    },
    gridResponsive() {
      const { windowWidth } = this;
      if (windowWidth <= 800) return 1;
      if (windowWidth <= 1200) return 2;
      if (windowWidth <= 1600) return 3;
      return 4;
    },
  },
};
</script>
