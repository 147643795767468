<template>
  <header class="header-bottom">
    <nav class="header-nav bottom">
      <div
        v-for="item in menuResponsive.bottom"
        :key="item.name"
        class="header-nav-item"
      >
        <template v-if="!item.children.length">
          <b-button
            :icon-pack="item.pack"
            :icon-left="item.icon"
            type="is-tertiary"
            tag="router-link"
            :to="item.path"
            :disabled="item.disabled"
            @click.native="redirect(item, false)"
          />
        </template>
        <template v-else>
          <b-button
            :icon-pack="item.pack"
            :icon-left="item.icon"
            type="is-tertiary"
            @click="openDropdown(item)"
          />
          <template v-if="open && open === item.name">
            <div class="header-dropdown bottom">
              <div class="header-dropdown-content">
                <template v-for="el in item.children">
                  <div
                    v-if="el.active"
                    :key="el.name"
                  >
                    <b-button
                      v-if="!el.event"
                      :icon-pack="el.pack"
                      :icon-left="el.icon"
                      type="is-tertiary"
                      tag="router-link"
                      :to="el.path"
                      :disabled="el.disabled"
                      @click.native="redirect(el, false)"
                    />
                    <b-button
                      v-else
                      :icon-pack="el.pack"
                      :icon-left="el.icon"
                      type="is-tertiary"
                      :disabled="el.disabled"
                      @click="$emit('openModal', el.event); UP_openSubMenu('')"
                    />
                  </div>
                </template>
              </div>
            </div>
          </template>
        </template>
      </div>
    </nav>
  </header>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */
import { mapGetters, mapActions } from 'vuex';

import SocketMixins from '@jack/socket/$utils/SocketMixins.mixins';
import TheMenuMixins from '../$mixins/TheMenuMixins.menu.mixins';

export default {
  name: 'MenuBottomMobile',
  mixins: [
    TheMenuMixins,
    SocketMixins,
  ],
  data() {
    return {
      currentPath: {
        domain: '/dashboard',
        path: '/dashboard/user',
      },
      modalCmp: null,
    };
  },
  computed: {
    ...mapGetters({
      open: 'TheMenu/getterOpenSubMenu',
    }),
  },
  methods: {
    ...mapActions({
      UP_openSubMenu: 'TheMenu/UP_openSubMenu',
    }),
    redirect(args, redirect = true) {
      return Promise.resolve()
        .then(() => { if (this.open) this.UP_openSubMenu(''); })
        .then(() => {
          if (this.currentPath.path.includes(this.$route.path)) return;
          if (!redirect) return;
          this.currentPath = args;
          this.$router.push({ path: args.path });
        });
    },
    openDropdown(item) {
      if (this.open && this.open === item.name) {
        this.UP_openSubMenu('');
        return;
      }
      this.UP_openSubMenu(item.name);
    },
  },
};
</script>
