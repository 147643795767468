<template>
  <section class="dashboard">
    <div class="content">
      <header class="multibar is-align-items-center">
        <div class="header-actions end">
          <div class="is-flex is-align-items-center">
            <div class="buttons ml-3">
              <b-tooltip
                type="is-dark"
                label="Add new project"
                position="is-bottom"
                append-to-body
              >
                <b-button
                  type="is-tertiary"
                  tag="router-link"
                  to="/projects/add"
                  icon-pack="fa"
                  icon-left="plus"
                />
              </b-tooltip>
              <!-- <b-tooltip
                type="is-dark"
                label="Filters"
                position="is-bottom"
                append-to-body
              >
                <b-button
                  type="is-tertiary"
                  icon-pack="fa"
                  icon-left="filter"
                  class="ml-5"
                  @click="toggleSidePanelFilters()"
                />
              </b-tooltip> -->
            </div>
          </div>
        </div>
      </header>

      <br>

      <DevDetails :cnt="{name: 'projectFetchedFormatted', obj: projectFetchedFormatted}" />
      <template v-if="projectFetchedFormatted && Object.values(projectFetchedFormatted).length">
        <div class="dashboard-latest-container px-3">
          <article
            v-for="project in projectFetchedFormatted"
            :key="project.pid"
            class="w-100 mb-3"
          >
            <ProjectCard :project="project" />
          </article>
        </div>
      </template>
      <NoData
        v-else
        title="No projects found"
        content="You may register a new project at any time"
        class="noProject"
      />
    </div>

    <!-- <FilterPanel
      v-if="openFilter"
      :queries="queries"
      :filters="filters"
      @refresh="fetchPersons()"
      @close="toggleSidePanelFilters()"
      @clear="clearFilters()"
      @update:by="queries.by = $event"
      @update:search="queries.search = $event"
      @update:location="queries.location = $event"
      @update:hasMail="queries.hasMail = $event"
      @update:hasNumber="queries.hasNumber = $event"
      @update:refreshed="queries.refreshed = $event"
    /> -->
    <div
      v-if="openFilter"
      class="overlayWithLock"
      @click="quitPanel()"
    />
  </section>
</template>

<script>
/* eslint-disable-next-line */
/* eslint-disable import/no-extraneous-dependencies */
import filterHttpQueryMixins from '@jack/addons/common/$mixins/filterHttpQuery.mixins';
import { debounce } from '@jack/addons/$utils/helpers';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TheProjects',
  mixins: [filterHttpQueryMixins],
  data() {
    return {
      openFilter: false,
      loading: false,
      search: '',
      inputSearch: '',
      queries: {
        pagin: {
          limit: 50,
          offset: 0,
        },
        location: null,
        search: null,
        by: 'name',
        hasMail: false,
        hasNumber: false,
        refreshed: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      currentUid: 'TheProjects/currentUid',
      userProjectList: 'TheProjects/userProjectList',
    }),
    filters() {
      return {
        by: {
          optionsSearch: [
            { name: 'Name', key: 'name' },
            { name: 'Description', key: 'description' },
            { name: 'Head line', key: 'head_line' },
            { name: 'Job', key: 'job' },
          ],
        },
        search: {},
        location: {},
        hasMail: {},
        hasNumber: {},
        refreshed: {},
      };
    },
    projectFetchedFormatted() {
      if (!this.userProjectList || !Object.values(this.userProjectList).length) return null;

      const query = Object.values(this.userProjectList).reduce((acc, project) => {
        if (!project.details) return acc;
        let company = null;
        const { company: _company } = project.client;
        if (_company && _company.length) {
          const { details, ...rest } = _company[0];
          company = { ...rest, ...details };
        }

        const { details: { label } } = project;

        if (this.search) {
          const findLabel = !!label.toLowerCase().includes(this.search.toLowerCase());

          if (findLabel) acc.push(project);

          return acc;
        }

        acc.push({ ...project, client: { ...project.client, company } });
        return acc;
      }, []);

      query.sort((a, b) => this.$moment(b.createdOn).unix() - this.$moment(a.createdOn).unix());

      return query.arrayMapper('pid');
    },
    newProject() {
      const today = this.$moment().format('YYYY-MM-DD');
      return {
        path: '/home/calendar/sidePanel/addProject',
        query: {
          start: today,
        },
      };
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    inputSearch: debounce(function (newVal) {
      this.search = newVal;
    }, 0),
  },
  mounted() {
    // const payload = { uid: this.currentUid };
    // const httpQuery = { details: 'true' };
    this.fetchProjects();
  },
  methods: {
    ...mapActions({
      reportProjectListByUser: 'TheProjects/reportProjectListByUser',
    }),
    fetchProjects() {
      this.loading = true;
      const payload = { uid: this.currentUid };
      const httpQuery = {
        details: 'true', withClient: 'true', withFavorite: 'true', withPayment: 'true', withAppointment: 'true',
      };
      return Promise.resolve()
        .then(() => this.reportProjectListByUser({ payload, httpQuery }))
        .then(() => { this.loading = false; });
    },
    quitPanel() {
      if (this.openFilter) this.openFilter = false;
    },
  },
};
</script>
