<template>
  <section>
    <div class="multibar">
      <h2 class="title is-big start">
        Linkedin - Search Export
      </h2>
    </div>

    <br>

    <b-steps
      v-model="activeStep"
      size="is-small"
      :has-navigation="false"
    >
      <template v-for="(step, i) in steps">
        <b-step-item
          v-if="step.displayed"
          :key="step.label"
          :label="step.label"
          :type="step.type"
        >
          <br>

          <JackStepControls
            v-if="step.stepVisible"
            :active-step.sync="activeStep"
            :step-detail="`${i + 1}/${steps.length - 1} - Search jobs`"
            :next-is-allowed="step.stepControl"
            :prev="step.prev"
            :next="step.next"
          />

          <br>

          <component
            :is="step.cmp"
            v-if="agent"
            v-model="form"
            :agent="agent"
            @search="launchAgent()"
          />
        </b-step-item>
      </template>
    </b-steps>
  </section>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */
import { mapGetters, mapActions } from 'vuex';
import SocketMixins from '@jack/socket/$utils/SocketMixins.mixins';

export default {
  name: 'TheNetworkingManagePhantomsSidePanelSearchExport',
  mixins: [SocketMixins],
  data() {
    return {
      dev: false,
      activeStep: 0,
      form: {
        sessionCookie: null,
        search: null,
        numberOfResultsPerLaunch: null,
        numberOfResultsPerSearch: null,
        firstCircle: null,
        secondCircle: null,
        thirdCircle: null,
        category: null,
        csvName: null,
        filters: null,
        numberOfLinesPerLaunch: null,
        columnName: null,
        removeDuplicateProfiles: null,
        watcherMode: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      agentList: 'TheNetworking/agentList',
      agent: 'TheNetworking/agent',
    }),
    currentAgent() {
      const { pbaid } = this.$route.params;
      return this.agentList[pbaid];
    },
    argumentsAgent() {
      if (!this.agent) return [];

      const { arguments: _arguments } = this.currentAgent;
      const { required, properties } = JSON.parse(_arguments);

      const inputToDisplay = ['sessionCookie', 'search'];

      return Object.entries(properties).reduce((acc, [key, value]) => {
        const base = { ...value, key, required: !!required.includes(key) };

        if (!inputToDisplay.includes(key)) return acc;
        acc.push({ ...base });

        return acc;
      }, []);
    },
    steps() {
      return [
        {
          label: 'Connect your Linkedin cookie', cmp: 'SidePanelAgentConnectToLinkedin', displayed: true, type: 'is-primary', stepVisible: true, stepControl: this.stepControl1, prev: false, next: true,
        },
        {
          label: 'What do you search ?', cmp: 'SidePanelAgentSearch', displayed: true, type: 'is-primary', stepVisible: true, stepControl: false, prev: true, next: false,
        },
        {
          label: '', cmp: 'SidePanelAgentResult', displayed: true, type: 'is-success', stepVisible: false, stepControl: false, prev: false, next: false,
        },
      ];
    },
    stepControl1() {
      const { sessionCookie } = this.form;
      if (sessionCookie) return true;
      return false;
    },
  },
  mounted() {
    this.fetchAgent();
  },
  methods: {
    ...mapActions({
      _fetchAgent: 'TheNetworking/fetch',
      _launchAgent: 'TheNetworking/launch',
    }),
    fetchAgent() {
      if (!this.currentAgent) return;
      const { extrefId: id } = this.currentAgent;
      this._fetchAgent({ httpQuery: { id } })
        .then(() => {
          if (!this.agent) return;
          const { argument } = this.agent;

          const argumentFetched = JSON.parse(argument);
          const defaultValueKeys = ['category', 'numberOfResultsPerLaunch', 'columnName', 'firstCircle', 'secondCircle', 'thirdCircle', 'removeDuplicateProfiles'];

          Object.entries(argumentFetched)
            .forEach(([key, value]) => {
              if (defaultValueKeys.includes(key)) {
                if (key === 'category') this.form.category = 'Jobs';
                if (key === 'numberOfResultsPerLaunch') this.form.numberOfResultsPerLaunch = 10;
                if (key === 'columnName') this.form.columnName = null;
                if (key === 'firstCircle') this.form.firstCircle = true;
                if (key === 'secondCircle') this.form.secondCircle = true;
                if (key === 'thirdCircle') this.form.thirdCircle = true;
                if (key === 'removeDuplicateProfiles') this.form.removeDuplicateProfiles = true;
              }
              this.form[key] = value;
            });
        });
    },
    launchAgent() {
      this.activeStep += 1;
      const { pbaid } = this.$route.params;
      const argument = Object.entries(this.form).reduce((acc, [key, value]) => {
        if (!value) return acc;
        acc[key] = value;
        return acc;
      }, {});

      const payload = { pbaid, arguments: JSON.stringify(argument) };

      if (this.dev) payload.isStub = 'agentsLaunch';
      return Promise.all([
        this._launchAgent({ payload }),
        this.subscribeStream({
          entite: 'agent',
          id: pbaid,
          method: 'launchAgent',
          options: { pbaid, insert: this.$route.query.insert },
        }),
      ]);
    },
  },
};
</script>
