<template>
  <section class="company-employees-container">
    <div class="company-employees-header">
      <div class="company-profile-picture">
        <div
          v-if="client.company.logo"
          :class="['profile-picture item size', {
            'is-8x8': size === 8,
            'is-16x16': size === 16,
            'is-18x18': size === 18,
            'is-24x24': size === 24,
            'is-32x32': size === 32,
            'is-40x40': size === 40,
            'is-48x48': size === 48,
            'is-64x64': size === 64,
            'is-96x96': size === 96,
            'is-128x128': size === 128,
          }]"
          :style="`background:url('${client.company.logo}')`"
        />
        <InitialsPicture
          v-else
          :name="client.company.name"
          class="item"
          :size="size"
        />
      </div>
      <div
        v-if="displayEmployees"
        class="employees-profile-picture"
      >
        <template v-for="(person, index) in client.persons">
          <b-tooltip
            v-if="person && index <= 2"
            :key="person.pid"
            type="is-dark"
            :label="person.fullName ? person.fullName : person.details.fullName"
            position="is-bottom"
            append-to-body
            class="picture"
          >
            <template v-if="isClickable">
              <router-link :to="`/networking/contacts/${person.pid}/details`">
                <div
                  v-if="person.imageUrl"
                  class="profile-picture item size is-32x32 cursor-pointer"
                  :style="`background:url('${person.imageUrl}')`"
                />
                <InitialsPicture
                  v-else
                  :name="person.fullName ? person.fullName : person.details.fullName"
                  class="item cursor-pointer"
                  :size="32"
                />
              </router-link>
            </template>
            <template v-else>
              <div
                v-if="person.imageUrl"
                :class="['profile-picture item size is-32x32', {
                  'is-8x8': size === 8,
                  'is-16x16': size === 16,
                  'is-18x18': size === 18,
                  'is-24x24': size === 24,
                  'is-32x32': size === 32,
                  'is-40x40': size === 40,
                  'is-48x48': size === 48,
                  'is-64x64': size === 64,
                  'is-96x96': size === 96,
                  'is-128x128': size === 128,
                }]"
                :style="`background:url('${person.imageUrl}')`"
              />
              <InitialsPicture
                v-else
                :name="person.fullName ? person.fullName : person.details.fullName"
                class="item"
                :size="32"
              />
            </template>
          </b-tooltip>
        </template>
      </div>
    </div>
    <div class="company-employees-content ml-5">
      <template v-if="isClickable">
        <router-link :to="`/networking/companies/${client.company.cid}/details`">
          <p class="subtitle is-big p-0 m-0 cursor-pointer">
            {{ client.company.name }}
          </p>
          <p
            v-if="client.company.description"
            class="disclamer is-small has-text-secondary m-0 cursor-pointer"
          >
            {{ client.company.description.slice(0, 100) }}...
          </p>
        </router-link>
      </template>
      <template v-else>
        <p class="subtitle is-big p-0 m-0">
          {{ client.company.name }}
        </p>
        <p
          v-if="client.company.description"
          class="disclamer is-small has-text-secondary m-0"
        >
          {{ client.company.description.slice(0, 100) }}...
        </p>
      </template>
    </div>
  </section>
</template>

<script>
export default {
  name: 'CompanyEmployeesContact',
  props: {
    client: { type: Object, required: true },
    size: { type: Number, default: 40 },
    displayEmployees: { type: Boolean, default: true },
    isClickable: { type: Boolean, default: true },
  },
};
</script>
