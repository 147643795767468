<template>
  <TableSkeleton v-if="loading" />
  <b-table
    v-else
    :data="data"
    :total="total"
    :backend-pagination="backendPagination"
    :paginated="paginated"
    :per-page="perPage"
    :pagination-size="paginationSize"
    :current-page="page"
    backend-sorting
    :default-sort-direction="defaultSortOrder"
    :default-sort="[sortField, sortOrder]"
    :opened-detailed="defaultOpenedDetails"
    :detailed="isActive"
    :detail-key="detailKey"
    hoverable
    :checkable="checkable"
    :checked-rows.sync="localChecked"
    :sticky-header="stickyHeader"
    sticky-checkbox
    :mobile-cards="false"
    @page-change="$emit('onPageChange', $event)"
    @sort="onSort"
  >
    <b-table-column
      v-for="column in columns"
      :key="column.col"
      :visible="column.visible"
      :label="column.label"
      :field="column.col"
      :sortable="column.isSortable"
      :sticky="column.sticky"
      :width="column.width"
      :centered="column.centered"
      :cell-class="column.cellClass"
    >
      <template
        v-if="column.base === 'INSTAGRAM'
          || column.base === 'TIKTOK'
          || column.base === 'FACEBOOK'
          || column.base === 'SNAPCHAT'
          || column.base === 'TWITTER'
          || column.base === 'LINKEDIN'"
        v-slot:header
      >
        <div class="jackHeaderTable">
          <b-icon
            pack="fab"
            :icon="column.base.toLowerCase()"
          />
          <span class="start">
            {{ column.label }}
          </span>
        </div>
      </template>
      <template v-slot="props">
        <template v-if="column.cmp === 'flat'">
          {{ props.row[column.col] || '-' }}
        </template>

        <template v-if="column.cmp === 'bool'">
          <b-icon
            pack="fas"
            icon="circle"
            :class="[{
              'has-text-success': props.row[column.col],
              'has-text-danger': !props.row[column.col],
            }]"
          />
        </template>

        <template v-if="column.cmp === 'isOpen'">
          <b-icon
            pack="fas"
            icon="circle"
            :class="[{
              'has-text-success': !props.row[column.col],
              'has-text-danger': props.row[column.col],
            }]"
          />
        </template>

        <template v-if="column.cmp === 'symbol'">
          <template v-if="props.row[column.col]">
            <span>{{ props.row[column.col] }} {{ column.symbol }}</span>
          </template>
          <template v-else>
            <span>-</span>
          </template>
        </template>

        <template v-if="column.cmp === 'date'">
          <span v-if="props.row[column.col]">
            {{ $moment(props.row[column.col]).format('MMMM Do, YYYY') }}
          </span>
          <span v-else>-</span>
        </template>

        <template v-if="column.cmp === 'nested'">
          <span v-if="column.base && props.row[column.base]">
            {{ props.row[column.base][column.nestedKey1] || '-' }}
          </span>
          <span v-else>
            -
          </span>
        </template>

        <template v-if="column.event">
          <b-button
            v-if="column.buttonType === 'tertiary'"
            :type="column.type"
            icon-pack="fas"
            :icon-right="column.icon"
            @click="$emit(column.event, props.row)"
          />
          <b-button
            v-else
            :type="column.type"
            :label="column.label"
            @click="$emit(column.event, props.row)"
          />
        </template>
      </template>
    </b-table-column>
    <template #detail="props">
      <DevDetails :cnt="{name: 'props.row', obj: props.row}" />
      <DevDetails :cnt="{name: 'columns', obj: columns}" />
    </template>
  </b-table>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Table',
  props: {
    data: {
      type: Array,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    backendPagination: {
      type: Boolean,
      required: false,
    },
    perPage: {
      type: Number,
      required: true,
    },
    paginationSize: {
      type: String,
      default: 'is-small',
    },
    defaultSortOrder: {
      type: String,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    checkedRows: {
      type: Array,
      required: true,
    },
    selected: {
      type: Object,
      required: true,
    },
    sortField: {
      type: String,
      required: true,
    },
    detailKey: {
      type: String,
      default: 'cid',
    },
    sortOrder: {
      type: String,
      required: true,
    },
    checkable: {
      type: Boolean,
      default: true,
    },
    paginated: {
      type: Boolean,
      default: true,
    },
    stickyHeader: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      defaultOpenedDetails: [],
    };
  },
  computed: {
    ...mapGetters({
      isActive: 'DevDetails/isActive',
    }),
    localSelected: {
      get() { return this.selected; },
      set(newValue) { this.$emit('update:selected', newValue); },
    },
    localChecked: {
      get() { return this.checkedRows; },
      set(newValue) { this.$emit('update:checkedRows', newValue); },
    },
  },
  methods: {
    onSort(field, order) {
      this.$emit('onSort', [field, order]);
    },
  },
};
</script>
