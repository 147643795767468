/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import { getters, actions } from '@jack/dataStores/commonViewStores';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {
    ...getters.authenticate,
    ...getters.tag,
  },
  actions: {
    ...actions.tag,
  },
};
