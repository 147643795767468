<template>
  <aside class="jackSidePanel">
    <div class="multibar">
      <b-button
        class="start is-tertiary"
        icon-pack="fa"
        icon-left="chevron-left"
        @click="$emit('home')"
      />
      <b-button
        class="end is-tertiary"
        icon-pack="fas"
        icon-right="times"
        @click="$emit('quit')"
      />
    </div>

    <br>

    <header>
      <h2 class="title m-0">Bulk actions - Import companies</h2>
      <p class="text is-small has-text-secondary">
        Extract the employees of a company on LinkedIn
      </p>
    </header>

    <br>

    <b-steps
      v-model="activeStep"
      size="is-small"
      :has-navigation="false"
    >
      <template v-for="(step, i) in steps">
        <b-step-item
          v-if="step.displayed"
          :key="step.label"
          :label="step.label"
          :type="step.type"
        >
          <br>

          <JackStepControls
            v-if="step.stepVisible"
            :active-step.sync="activeStep"
            :step-detail="`${i + 1}/${steps.length - 1} - Export companies`"
            :next-is-allowed="step.stepControl"
            :prev="step.prev"
            :next="step.next"
          />

          <br>

          <component
            :is="step.cmp"
            v-model="argument"
            :agent="agent"
            :checked-rows="checkedRows"
            @launchAgent="launchAgent($event)"
          />
        </b-step-item>
      </template>
    </b-steps>
  </aside>
</template>

<script>
/* eslint-disable max-len */
export default {
  name: 'BulkImportCompany',
  model: { prop: 'argument' },
  props: {
    checkedRows: { type: Array, required: true },
    agent: { type: Object, required: true },
    argument: { type: Object, required: true },
  },
  data() {
    return {
      open: false,
      activeStep: 0,
      urlNgrok: 'https://568c-80-119-178-45.eu.ngrok.io',
    };
  },
  computed: {
    steps() {
      const _base = {
        displayed: true, type: 'is-primary', stepVisible: true, stepControl: false, prev: false, next: false,
      };
      return [
        {
          ..._base,
          label: 'Connect your Linkedin cookie',
          cmp: 'SidePanelAgentConnectToLinkedin',
          stepControl: !!this.argument.sessionCookie,
          next: true,
        },
        {
          ..._base,
          label: 'Companies selected',
          cmp: 'BulkImportCompaniesSelected',
          prev: true,
        },
        {
          ..._base,
          label: '',
          cmp: 'SidePanelAgentResult',
          type: 'is-success',
        },
      ];
    },
  },
  methods: {
    launchAgent(copyCheckedRows) {
      const companies = copyCheckedRows.reduce((acc, el) => {
        if (!el.active || !el.companyUrl) return acc;
        if (!el.companyUrl) { acc.error.push(el); }

        acc.toFetch.push(el.companyUrl.split('company/')[1].replace('/', ''));
        return acc;
      }, {
        toFetch: [],
        error: [],
      });

      this.argument.spreadsheetUrl = `${this.urlNgrok}/api-ws-mi/bulkExportFromCsv?entite=company&slugs=${companies.toFetch.join(',')}`;
      this.$emit('launchAgent');
      this.activeStep = this.activeStep + 1;
    },
  },
};
</script>
