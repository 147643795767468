<template>
  <article
    class="kanban-item selected"
    :style="{top: `${posMouse.y}px`, left: `${posMouse.x}px`}"
  >
    <div class="is-flex is-justify-content-space-between is-align-items-center w-100">
      <p class="subtext is-small m-0">
        {{ item.label }}
      </p>
    </div>
  </article>
</template>

<script>
export default {
  name: 'KanbanItemCopy',
  props: {
    item: { type: Object, required: true },
    posMouse: { type: Object, required: true },
  },
};
</script>
