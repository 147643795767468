import TheMembers from './TheMembers';

export default [
  {
    path: '/members',
    name: 'Members',
    component: TheMembers,
    meta: {
      hasMenu: true,
      viewStoreNavigation: 'TheMembers',
      requireAuth: true,
    },
    children: [],
  },
];
