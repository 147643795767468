<template>
  <div>
    TheRating
  </div>
</template>

<script>
export default {
  name: 'TheRating',
};
</script>
