/* eslint-disable max-len */
import { v4 as uuidv4 } from 'uuid';

export default {
  namespaced: true,
  state: {
    wsNotificationList: {},
  },
  mutations: {
    PUSH_NOTIFICATION_IN_NOTIFICATION_LIST(state, newData) {
      state.wsNotificationList = {
        ...state.wsNotificationList,
        [uuidv4()]: { ...newData },
      };
    },
    DELETE_NOTIFICATION_IN_NOTIFICATION_LIST(state, newData) {
      state.wsNotificationList = Object.values(state.wsNotificationList)
        .reduce((acc, notif) => {
          if (newData.id === notif.id) return acc;
          acc[notif.id] = { ...notif };
          return acc;
        }, {});
    },
  },
  getters: {
    gettersWsNotificationList: (state) => state.wsNotificationList,
  },
  actions: {
    push({ commit }, notifications) {
      const id = uuidv4();
      commit('PUSH_NOTIFICATION_IN_NOTIFICATION_LIST', { id, ...notifications });
    },
    delete({ commit }, notifications) {
      commit('DELETE_NOTIFICATION_IN_NOTIFICATION_LIST', notifications);
    },
  },
};
