// import { joe } from '../../../$providers';
// import { isActionAllowed } from '../../$utils/helpers';

export default {
  namespaced: true,
  state: {
    theme: localStorage.getItem('theme') || 'DARK',
  },
  getters: {
    getterTheme: (state) => state.theme,
  },
  actions: {
    // setTheme({ commit, rootGetters }, { payload }) {
    // if (!isActionAllowed(rootGetters, 'USER.SET_THEME')) return undefined;

    // if (!['LIGHT', 'DARK', 'AUTO'].includes(payload.theme)) return undefined;

    // const { uid } = rootGetters['Authenticate/getterAuthenticate'];

    // return clark.api.user.setTheme.post({ payload: { ...payload, uid }, httpQuery: {} })
    //   .then(() => {
    //     commit('SET_THEME', payload.theme);
    //     localStorage.setItem('theme', payload.theme);
    //   });
    // },
  },
  mutations: {
    SET_THEME(state, theme) { state.theme = theme; },
  },
};
