<template>
  <section>
    <div class="multibar">
      <div class="buttons end">
        <b-button
          type="is-tertiary"
          :icon-left="!openKanbanCreate ? 'plus' : 'minus'"
          icon-pack="fa"
          :class="[{
            'turn-signal': !kanbanList.length
          }]"
          @click="openKanbanCreate = !openKanbanCreate"
        />
      </div>
    </div>

    <KanbanForm
      v-if="openKanbanCreate"
      v-model="kanban"
      @update:kanban="kanban = $event"
      @createKanban="createKanban()"
    />

    <div v-else>
      {{ kanbanList }}
    </div>
  </section>
</template>

<script>
export default {
  name: 'ProjectKanban',
  props: {
    kanbanList: { type: Object, required: true },
    userUid: { type: Number, required: true },
  },
  data() {
    return {
      openKanbanCreate: false,
      kanban: {
        label: null,
        description: null,
      },
    };
  },
  methods: {
    createKanban() {
      this.$emit('createKanban', {
        ...this.kanban,
        createdAt: this.$moment().format(),
        endedAt: null,
        userUid: this.userUid,
      });
      this.openKanbanCreate = false;
    },
  },
};
</script>
