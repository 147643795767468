<template>
  <article
    :class="['kanban-item', {
      'zone': selected && selected.kiid === kiid
    }]"
    draggable
    @dragstart="$emit('startDrag', { evt: $event, item })"
    @dragend.prevent="$emit('dragEnd')"
  >
    <div class="is-flex is-justify-content-space-between is-align-items-center w-100">
      <p class="subtext is-small m-0">
        {{ item.label }} ({{ item.order }})
      </p>
      <b-button
        type="is-ghost"
        icon-pack="fa"
        icon-left="ellipsis-vertical"
        class="m-0 p-0"
        @click="$emit('openPanel', { type: 'item:details', kiid })"
      />
    </div>
  </article>
</template>

<script>
export default {
  name: 'KanbanItem',
  props: {
    kiid: { type: Number, required: true },
    item: { type: Object, required: true },
    index: { type: Number, required: true },
    selected: { type: Object, default: null },
  },
};
</script>
