<template>
  <section
    :class="[{
      'hide': hide
    }]"
  >
    <p class="subtitle is-small m-0">
      Explain in a few words the reason for this meeting
    </p>

    <b-field
      label="Reason of the appointment"
      class="bg-solitude"
    >
      <b-input
        v-model="localLabel"
        required
      />
    </b-field>

    <ButtonForm
      :disabled="disabled"
      @next="$emit('next')"
    />
  </section>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */
import animationMixins from '../$mixins/animation.mixins';

export default {
  name: 'LabelForm',
  mixins: [animationMixins],
  props: {
    user: { type: Object, default: () => {} },
    appointment: { type: Object, required: true },
    scAppointment: { type: Object, required: true },
    ms: { type: Number, required: true },
  },
  computed: {
    localLabel: {
      get() { return this.appointment.label; },
      set(newValue) { this.$emit('update:label', newValue); },
    },
    disabled() {
      return !!(this.scAppointment.validate(this.appointment).error || !this.appointment.label);
    },
  },
};
</script>
