/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import { actions, getters } from '@jack/dataStores/commonViewStores';

export default {
  namespaced: true,
  getters: {
    ...getters.authenticate,
  },
  actions: {
    ...actions.authenticate,
  },
};
