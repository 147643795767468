/* eslint-disable no-extend-native */
/* eslint-disable func-names */
import Vue from 'vue';
import moment from 'moment';

import languages from './languages';

const cache = {
  defaultLang: 'FR',
};

const translate = (key) => {
  if (!cache.lang) cache.lang = cache.defaultLang;
  const { lang } = cache;

  if (!languages[lang]) console.error(`${languages.FR['Language not found']} (${lang})`);
  if (!languages[lang][key]) console.error(`${languages.FR['Translate not found']} (${key})`);

  return languages[lang][key];
};

cache.format = translate('YYYY-MM-DD');

Array.prototype.arrayMapper = function (key) {
  return this.reduce((acc, entry) => {
    acc[entry[key]] = entry;
    return acc;
  }, {});
};

String.prototype.capitalize = function () {
  const [firstLetter, ...rest] = this;
  return firstLetter.toUpperCase() + rest.join('').toLowerCase();
};

Vue.prototype.$cache = function (key, value) {
  cache[key] = value;
};
Vue.prototype.$moment = moment;
Vue.prototype.$formatDate = (date = moment()) => moment(date).format(cache.format);
Vue.prototype.$currentQuery = function (query) {
  return this.$route.query[query];
};
Vue.prototype.$currentParam = function (param) {
  return this.$route.params[param];
};

Vue.prototype.$reformat = function (param) {
  const keys = ['pid', 'cid', 'fetchedAt', 'extrefId', 'source', 'refreshAt', 'accountAid', 'userUid'];
  return Object.entries(param).reduce((acc, [key, value]) => {
    if (keys.includes(key)) {
      acc[key] = value;
      return acc;
    }
    acc.details[key] = value;
    return acc;
  }, { details: {} });
};

Vue.prototype.$_ = (key) => translate(key);
Vue.prototype.$lang = () => (cache.lang
  ? cache.lang.toLowerCase()
  : cache.defaultLang.toLowerCase());
