<template>
  <aside
    :class="['kanban-column', {
      'zone': selected.column && selected.column.kcid === kcid
    }]"
  >
    <div
      class="kanban-column-header"
      draggable
      @dragstart="$emit('startDrag', { type: 'drag-column', evt: $event, column })"
      @dragend.prevent="$emit('dragEnd')"
    >
      <p class="subtext is-small m-0">
        {{ column.label }} ({{ column.order }})
      </p>
      <b-button
        type="is-ghost"
        icon-pack="fa"
        icon-left="ellipsis-vertical"
        class="m-0 p-0"
        @click="$emit('openPanel', { type: 'column:details', kcid })"
      />
    </div>

    <div class="kanban-column-content">
      <hr class="mt-0">

      <template v-if="itemsSorted.length">
        <div
          v-for="(item, indexItem) in itemsSorted"
          :key="item.kiid"
          class="kanban-item-dropzone"
          @drop="onDrop({ item })"
          @dragover.prevent
          @dragenter.prevent="onDragEnter({ indexItem, indexColumn: index, id: column.kcid })"
        >
          <KanbanItem
            :kiid="item.kiid"
            :item="item"
            :index="indexItem"
            :selected="selectedItem"
            @openPanel="$emit('openPanel', { ...$event, kcid })"
            @startDrag="startDrag({ ...$event, item })"
            @dragEnd="dragEnd()"
          />
        </div>
      </template>
      <section
        class="kanban-item add"
        @click="$emit('openPanel', { type: 'item:add', kcid })"
      >
        <b-icon
          icon="plus"
          pack="fa"
          size="is-small"
        />
      </section>
    </div>
  </aside>
</template>

<script>
import TheProjectsEngine from '../$mixins/TheProjectsEngine.mixins';

export default {
  name: 'KanbanColumn',
  mixins: [TheProjectsEngine],
  props: {
    kcid: { type: Number, required: true },
    column: { type: Object, required: true },
    index: { type: Number, required: true },
    selected: { type: Object, default: null },
  },
  data() {
    return {
      selectedItem: null,
      pos: { column: null, card: null },
    };
  },
  computed: {
    itemsSorted() {
      if (!this.column || !this.column.items || !this.column.items.length) return [];
      const arr = this.column.items.slice().sort((a, b) => a.order - b.order);

      return arr.sort((a, b) => a.order - b.order);
    },
  },
  methods: {
    startDrag({ evt, item }) {
      /* eslint-disable no-param-reassign */
      evt.dataTransfer.dropEffect = 'move';
      evt.dataTransfer.effectAllowed = 'move';
      evt.dataTransfer.setData('column', this.column.order);
      evt.dataTransfer.setData('item', item.order);

      this.$emit('selected:item', item);
      this.selectedItem = item;
    },
    onDrop({ item }) {
      this.$emit('update:orderKanbanItem', {
        payload: { ...item, kcid: this.kcid },
      });

      this.restartConfig();
    },
    onDragEnter({ indexItem, indexColumn, id }) {
      this.$emit('update:posItem', { column: indexColumn + 1, card: indexItem + 1, id });
    },
    dragEnd() {
      this.$emit('unselected:item');
      this.restartConfig();
    },
    restartConfig() { this.selectedItem = null; },
  },
};
</script>
