/* eslint-disable import/no-extraneous-dependencies */
import { navySeal } from '@jack/provider';
import { isActionAllowed } from '@jack/dataStores/$utils/helpers';

export default {
  namespaced: true,
  state: {
    templateList: {},
  },
  mutations: {
    SET_TEMPLATE_LIST(state, newData) { state.templateList = newData; },
    ADD_TEMPLATE_IN_LIST(state, newData) { state.templateList[newData.tid] = newData; },
    UPDATE_TEMPLATE_IN_TEMPLATE_LIST(state, newData) {
      state.templateList[newData.tid] = { ...state.templateList[newData.tid], ...newData };
    },
  },
  getters: {
    getterTemplateList: (state) => state.templateList,
  },
  actions: {
    post({
      commit, rootGetters, dispatch, getters,
    }, { payload = {}, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'TEMPLATE.CREATE', dispatch)) return undefined;

      return navySeal.apiWsJoe.template.post({ payload, httpQuery })
        .then((template) => {
          const { getterTemplateList: _templates } = getters;
          commit('SET_TEMPLATE_LIST', { ..._templates, [template.tid]: template });
          return template;
        });
    },
    patch({ commit, rootGetters, dispatch }, { payload = {}, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'TEMPLATE.PATCH', dispatch)) return undefined;

      return navySeal.apiWsJoe.template.patch({ payload, httpQuery })
        .then((template) => {
          commit('UPDATE_TEMPLATE_IN_TEMPLATE_LIST', template);
          return template;
        });
    },
    delete({
      commit, getters, rootGetters, dispatch,
    }, { payload = {}, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'TEMPLATE.DELETE', dispatch)) return undefined;

      return navySeal.apiWsJoe.template.delete({ payload, httpQuery })
        .then(() => {
          const { getterTemplateList: _templates } = getters;
          const templates = Object.values(_templates).reduce((acc, template) => {
            if (Number(payload.tid) === Number(template.tid)) return acc;
            acc.push(template);
            return acc;
          }, []);
          commit('SET_TEMPLATE_LIST', templates);
        });
    },
  },
};
