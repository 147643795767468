<template>
  <main class="is-flex">
    <TheStyleGuildeMenu :menu-items="navigation.menuItems" />
    <div class="view">
      <router-view />
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TheStyleGuide',
  computed: {
    ...mapGetters({
      navigation: 'TheStyleGuide/navigation',
    }),
  },
};
</script>
