<template>
  <section class="p-container">
    <div
      v-if="projectPayload.details.price"
      class="text"
    >
      <div class="is-block is-flex is-align-items-center">
        <template v-if="projectPayload.details.currency">
          <span>{{ currencies[projectPayload.details.currency].name }}</span>
        </template>
        <span class="bigtitle ml-3 no-lineh-bigtitle">
          {{ format(Number(projectPayload.details.price)) }}
        </span>
      </div>
      <template v-if="projectPayload.details.condition">
        <span class="has-text-secondary disclamer">
          {{ conditions[projectPayload.details.condition].name }}
        </span>
      </template>
    </div>
    <div class="ml-5">
      <div>
        <router-link :to="`/projects/${projectPayload.pid}`">
          <p class="text is-small m-0">
            {{ projectPayload.details.label }}
          </p>
        </router-link>
        <p class="subtext is-small has-text-secondary">
          {{ projectPayload.details.description }}
        </p>
      </div>

      <template v-if="projectPayload.details.startDate">
        <br>
        <div class="is-flex is-align-items-center">
          <div>
            <p class="text is-small m-0">{{ $_('Start date') }}</p>
            <p class="subtext has-text-secondary is-small">
              {{ $moment(projectPayload.details.startDate).format($_('YYYY-MM-DD')) }}
            </p>
          </div>
          <div
            v-if="projectPayload.details.endDate"
            class="ml-5"
          >
            <p class="text is-small m-0">{{ $_('End date') }}</p>
            <p class="subtext has-text-secondary is-small">
              {{ $moment(projectPayload.details.endDate).format($_('YYYY-MM-DD')) }}
            </p>
          </div>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */
import nFormatter from '@jack/addons/$mixins/nFormatter';

export default {
  name: 'ProjectCard',
  props: {
    project: { type: Object, required: true },
  },
  computed: {
    conditions() {
      return [
        { name: this.$_('Fixed'), key: 'fixed' },
        { name: this.$_('Per hour'), key: 'per-hour' },
        { name: this.$_('Per day'), key: 'per-day' },
        { name: this.$_('Per week'), key: 'per-week' },
        { name: this.$_('Per month'), key: 'per-month' },
        { name: this.$_('Per quarter'), key: 'per-quarter' },
        { name: this.$_('Per year'), key: 'per-year' },
      ].arrayMapper('key');
    },
    currencies() {
      return [
        { name: '€', key: 'EUR' },
        { name: '$', key: 'USD' },
        { name: '£', key: 'GBP' },
        { name: '¥', key: 'JPY' },
      ].arrayMapper('key');
    },
    projectPayload() {
      if (this.project && this.project.detail) return this.project;
      const keysDetail = ['clientCid', 'label', 'description', 'startDate', 'endDate'];

      return Object.entries(this.project)
        .reduce((acc, [key, value]) => {
          if (!keysDetail.includes(key)) {
            acc[key] = value;
            return acc;
          }
          acc.details[key] = value;
          return acc;
        }, { details: {} });
    },
  },
  methods: {
    format(nb) {
      return nFormatter(nb, 1);
    },
  },
};
</script>
