<template>
  <div v-if="type === 'picture-card'">
    <article
      v-for="i in iteration"
      :key="i"
      class="is-flex is-align-items-center"
    >
      <figure class="media-left p-0 m-0">
        <p class="image is-64x64">
          <b-skeleton
            circle
            width="64px"
            height="64px"
          />
        </p>
      </figure>
      <div class="media-content">
        <div class="content">
          <p>
            <b-skeleton height="80px" />
          </p>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
export default {
  name: 'Skeleton',
  props: {
    iteration: { type: Number, default: 3 },
    type: { type: String, required: true },
  },
};
</script>
