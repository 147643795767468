<template>
  <div class="multibar mt-2">
    <b-button
      v-if="!disabled"
      type="is-tertiary"
      icon-pack="fa"
      icon-left="arrow-right"
      class="end"
      :disabled="disabled"
      @click="next()"
    />
  </div>
</template>

<script>
import animationMixins from '../$mixins/animation.mixins';

export default {
  name: 'ButtonForm',
  mixins: [animationMixins],
  props: {
    disabled: { type: Boolean, required: true },
  },
};
</script>
