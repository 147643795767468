/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import { getters, actions } from '@jack/dataStores/commonViewStores';

export default {
  namespaced: true,
  state: {},
  getters: {
    ...getters.agents,
    ...getters.authenticate,
    ...getters.client,
    ...getters.categories,
    ...getters.payment,
    ...getters.project,
    ...getters.spends,
    ...getters.user,
  },
  actions: {
    ...actions.agents,
    ...actions.authenticate,
    ...actions.client,
    ...actions.categories,
    ...actions.payment,
    ...actions.project,
    ...actions.spends,
    ...actions.user,
  },
};
