<template>
  <section>
    <div class="is-flex is-align-items-center is-justify-content-flex-end">
      <b-tooltip
        type="is-dark"
        :label="!open ? $_('Add a new project') : $_('Close')"
        position="is-left"
      >
        <b-button
          type="is-tertiary"
          icon-pack="fa"
          :icon-left="!open ? 'plus' : 'xmark'"
          expanded
          :class="[{
            'turn-signal': !entite.projects
          }]"
          @click="open = !open"
        />
      </b-tooltip>
    </div>
    <DevDetails :cnt="{name: 'projectSorted', obj: projectSorted}" />

    <div class="company-project">
      <div class="projects-container">
        <AddProject
          v-if="open"
          :open="open"
          :client="client"
          :conditions="conditions"
          :currencies="currencies"
          @addProject="addProject($event)"
        />
        <ProjectList
          v-else
          :projects-list="projectSorted"
          :conditions="conditions.arrayMapper('key')"
          :currencies="currencies.arrayMapper('key')"
          @deleteProject="$emit('deleteProject', { ...$event, cid: client.cid })"
        />
      </div>
    </div>
  </section>
</template>

<script>
/* eslint-disable max-len */
export default {
  name: 'Project',
  props: {
    entite: { type: Object, required: true },
  },
  data() {
    return {
      open: false,
    };
  },
  computed: {
    client() {
      if (!this.entite.client) return null;
      return this.entite.client;
    },
    projectSorted() {
      if (!this.entite.projects) return [];

      return this.entite.projects;
      // .sort((a, b) => moment(b.createdAt).unix() - moment(a.createdAt).unix());
    },
    conditions() {
      return [
        { name: 'Fixed', key: 'fixed' },
        { name: 'Per hour', key: 'per-hour' },
        { name: 'Per day', key: 'per-day' },
        { name: 'Per week', key: 'per-week' },
        { name: 'Per month', key: 'per-month' },
        { name: 'Per quarter', key: 'per-quarter' },
        { name: 'Per year', key: 'per-year' },
      ];
    },
    currencies() {
      return [
        { name: '€', key: 'EUR' },
        { name: '$', key: 'USD' },
        { name: '£', key: 'GBP' },
        { name: '¥', key: 'JPY' },
      ];
    },
  },
  methods: {
    addProject(event) {
      this.$emit('addProject', { cid: this.client.cid, ...event });
      this.open = false;
    },
  },
};
</script>
