<template>
  <div>
    BoolFilter
  </div>
</template>

<script>
export default {
  name: 'BoolFilter',
  model: {
    prop: 'filterQuery',
  },
  props: {
    filterQuery: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    step: {
      type: Number,
      default: 1,
    },
    label: {
      type: String,
      default: 'Filter range',
    },
  },
};
</script>
