<template>
  <!-- eslint-disable max-len -->
  <section>
    <DevDetails :cnt="{name: 'persons', obj: persons}" />
    <section v-if="!open">
      <form>
        <b-field label="Search employee(s)">
          <b-autocomplete
            v-model="inputSearch"
            :data="employees"
            field="fullName"
            clear-on-select
            @select="option => addPerson({ ...option, personToLinkToCompany: false })"
          >
            <template #header>
              <b-button
                type="is-ghost"
                label="Add new person"
                @click="openCreatePerson()"
              />
            </template>
            <template #empty>No results for "{{ inputSearch }}"</template>
            <template slot-scope="props">
              <div class="media">
                <div class="media-left">
                  <div
                    v-if="props.option.details.imageUrl"
                    class="profile-picture item size is-40x40"
                    :style="`background:url('${props.option.details.imageUrl}')`"
                  />
                  <InitialsPicture
                    v-else
                    :name="props.option.details.fullName"
                    class="item"
                    :size="40"
                  />
                </div>
                <div class="media-content">
                  <p class="text is-small cursor-pointer p-0 m-0">
                    {{ props.option.details.fullName }}
                  </p>
                  <p class="disclamer has-text-secondary">{{ props.option.details.jobTitle }}</p>
                </div>
              </div>
            </template>
          </b-autocomplete>
        </b-field>
      </form>

      <br>

      <div class="is-flex is-justify-content-center">
        <p class="text is-big">Or</p>
      </div>

      <form>
        <b-field label="Search contact(s)">
          <b-autocomplete
            v-model="inputSearchRequest"
            :data="Object.values(personList)"
            field="fullName"
            clear-on-select
            @select="option => addPerson({ ...option, personToLinkToCompany: true })"
          >
            <template #empty>No results for "{{ inputSearchRequest }}"</template>
            <template slot-scope="props">
              <div
                v-if="props.option"
                class="media"
              >
                <div class="media-left">
                  <div
                    v-if="props.option.imageUrl"
                    class="profile-picture item size is-40x40"
                    :style="`background:url('${props.option.imageUrl}')`"
                  />
                  <InitialsPicture
                    v-else
                    :name="props.option.fullName"
                    class="item"
                    :size="40"
                  />
                </div>
                <div class="media-content">
                  <p class="text is-small cursor-pointer p-0 m-0">
                    {{ props.option.fullName }}
                  </p>
                  <p class="disclamer has-text-secondary">{{ props.option.jobTitle }}</p>
                </div>
              </div>
            </template>
          </b-autocomplete>
        </b-field>
      </form>

      <template v-if="Object.values(persons).length">
        <br>

        <AddPersonItem
          v-for="person in persons"
          :key="person.pid"
          :person="person"
          @removePerson="removePerson($event)"
        />
      </template>

      <br>

      <div class="buttons">
        <b-button
          type="is-success"
          icon-pack="fa"
          icon-left="user-plus"
          expanded
          :disabled="!Object.values(persons).length"
          @click="createClient()"
        />
      </div>
    </section>

    <section v-else>
      <div class="">
        <div class="is-flex is-align-items-center is-justify-content-space-between is-flex-direction-row-reverse">
          <b-button
            type="is-tertiary"
            icon-pack="fa"
            icon-left="xmark"
            @click="open = false"
          />
          <b-button
            v-if="choiceAddPerson"
            type="is-tertiary"
            icon-pack="fa"
            icon-left="chevron-left"
            @click="choiceAddPerson = null"
          />
        </div>
      </div>

      <br>

      <AddPersonChoice
        v-if="!choiceAddPerson"
        @choice="choiceAddPerson = $event"
      />

      <PhantomSteps
        v-if="choiceAddPerson === 'LINKEDIN'"
        v-model="activeStep"
        :agent="agentFetchProfile"
        @update:activeStep="activeStep = $event"
        @launchAgent="launch($event)"
      />

      <FormNewPerson
        v-if="choiceAddPerson === 'DEFAULT'"
        :company-cid="Number(company.cid)"
        :user-uid="userUid"
        :account-aid="accountAid"
        @newEmployee="$emit('newDefaultEmployee', $event)"
      />
    </section>
  </section>
</template>

<script>
/* eslint-disable max-len */
/* eslint-disable-next-line */
import { debounce } from '@jack/addons/$utils/helpers';
const moment = require('moment');

export default {
  name: 'CompanyNewClient',
  props: {
    company: { type: Object, required: true },
    agentFetchProfile: { type: Object, required: true },
    personList: { type: Object, default: () => {} },
    userUid: { type: Number, required: true },
    accountAid: { type: Number, required: true },
  },
  data() {
    return {
      activeStep: 0,
      persons: {},
      search: '',
      searchRequest: '',
      inputSearch: '',
      inputSearchRequest: '',
      selected: [],
      open: false,
      choiceAddPerson: null,
    };
  },
  computed: {
    employees() {
      if (!this.company.employees || !this.company.employees.length) return [];
      if (!this.search) this.company.employees.map((el) => ({ ...el, fullName: el.details.fullName }));

      return this.company.employees.reduce((acc, el) => {
        const search = this.search.toLowerCase();
        const _fullName = el.details.fullName.toLowerCase();

        if (_fullName.includes(search)) acc.push({ ...el, fullName: el.details.fullName });
        return acc;
      }, []);
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    inputSearch: debounce(function (newVal) {
      this.search = newVal;
    }),
    // eslint-disable-next-line func-names
    inputSearchRequest: debounce(function (newVal) {
      this.searchRequest = newVal;
      if (newVal.length >= 3) {
        this.$emit('findPerson', newVal);
      }
    }, 500),
  },
  methods: {
    launch(event) {
      this.$emit('newLinkedinEmployee', event);
      this.activeStep += 1;
    },
    createClient() {
      const pidsToLinkToCompany = Object.values(this.persons).reduce((acc, person) => {
        if (person.personToLinkToCompany) acc.push(person.pid);
        return acc;
      }, []);
      const payload = {
        createdAt: moment().format(),
        companyToCreate: null,
        personsToCreate: null,
        companyCidToLink: this.company.cid,
        personPidsToLink: Object.values(this.persons).map(({ pid }) => pid),
      };

      this.$emit('newDefaultEmployee', { pidsToLinkToCompany, ...payload });
    },
    openCreatePerson() {
      this.open = true;
      this.inputSearch = '';
    },
    addPerson(option) {
      if ((option && option.pid) && !this.persons[option.pid]) {
        this.persons = {
          ...this.persons,
          [option.pid]: { ...option },
        };
      }
    },
    removePerson(pid) {
      const newPersons = Object.values(this.persons).reduce((acc, person) => {
        if (Number(person.pid) === Number(pid)) return acc;
        acc[person.pid] = { ...person };
        return acc;
      }, {});

      this.persons = newPersons;
    },
  },
};
</script>
