import TheCGV from './TheCGV';

export default [
  {
    path: '/cgv',
    name: 'Conditions Générales d\'Utilisation',
    component: TheCGV,
    meta: {
      hasMenu: false,
      viewStoreNavigation: 'TheCGV',
      requireAuth: false,
    },
    children: [],
  },
];
