<template>
  <div class="multibar">
    <div class="start buttons m-0">
      <b-button
        class="is-tertiary m-0"
        icon-pack="fas"
        icon-right="arrow-left"
        tag="router-link"
        :to="previous"
      />
    </div>
    <!-- <div class="end buttons">
      <b-button
        class="is-tertiary"
        icon-pack="fas"
        icon-right="times"
        tag="router-link"
        :to="pathHome"
      />
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'JackSidePanelHeader',
  props: {
    pathHome: { type: String, required: true },
    previous: { type: Object, required: true },
  },
};
</script>
