/* eslint-disable max-len */
import { SnackbarProgrammatic as Snackbar } from 'buefy';
import { dispatchAndForward, dispatchFailure, snackBarFailure } from '../$utils/helpers';

export default {
  getters: {
    agentList: (_state, _getters, _rootState, rootGetters) => {
      const { 'PhantomBuster/agents/getterAgentList': agentList } = rootGetters;

      return agentList;
    },
    agent: (_state, _getters, _rootState, rootGetters) => {
      const { 'PhantomBuster/agents/getterAgent': agent } = rootGetters;

      return agent;
    },
  },
  actions: {
    report({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'PhantomBuster/agents/report',
        context: { payload, httpQuery },
        config: {
          start: false,
          end: false,
          error: 'Unable to fetch all agents',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    fetch({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'PhantomBuster/agents/fetch',
        context: { payload, httpQuery },
        config: {
          start: false,
          end: false,
          error: 'Unable to fetch agent',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    fetchAllAndInsertAgents({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'PhantomBuster/agents/fetchAllAndInsertAgents',
        context: { payload, httpQuery },
        textNotifier: 'fetch all agents and insert in database',
        retry: { onSuccess: false, onFailure: false },
        config: {
          start: false,
          end: true,
          endContent: 'Your PhantomBuster\'s agent are saved successfully',
          error: 'Unable to fetch all agents and insert in database',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    launch({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'PhantomBuster/agents/launch',
        context: { payload, httpQuery },
        textNotifier: 'launch agent',
        retry: { onSuccess: false, onFailure: false },
        config: {
          start: false,
          end: true,
          endContent: 'Your PhantomBuster\'s agent was launched successfully',
          error: 'Unable to launch agent',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
  },
};
