<template>
  <section class="first-connection">
    <div class="side-logo">
      <b-image
        class="devmood-logo"
        :src="theme ? logo.white : logo.black"
        alt="DEVMOOD logo"
      />
    </div>
    <div class="side-content">
      <router-view />
    </div>
  </section>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */
import TheMenuMixins from '../menu/$mixins/TheMenuMixins.menu.mixins';

const logoWhite = require('@jack/public/assets/images/icons/DEVMOOD_TEXT_WHITE_SVG.svg');
const logoBlack = require('@jack/public/assets/images/icons/DEVMOOD_TEXT_BLACK_SVG.svg');

export default {
  name: 'TheFirstConnection',
  mixins: [TheMenuMixins],
  data() {
    return {
      logo: {
        white: logoWhite,
        black: logoBlack,
      },
    };
  },
};
</script>
