import TheScheduleAppointment from './TheScheduleAppointment';

export default [
  {
    path: '/schedule-appointment',
    name: 'Schedule appointment',
    component: TheScheduleAppointment,
    meta: {
      hasMenu: false,
      viewStoreNavigation: 'TheScheduleAppointment',
      requireAuth: false,
    },
    children: [],
  },
];
