<template>
  <div>
    AddContactByLinkedin
  </div>
</template>

<script>
export default {
  name: 'AddContactByLinkedin',
};
</script>
