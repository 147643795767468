<template>
  <!-- eslint-disable max-len -->
  <section>
    <br>

    <DevDetails :cnt="{name: 'containerList', obj: containerList}" />
    <template v-if="containerList && containerList.length">
      <ContainerCard
        v-for="container in containerList"
        :key="container.pbcid"
        :container="container"
        @refreshContainer="refreshContainer($event)"
        @resultContainer="resultContainer($event)"
        @outputContainer="outputContainer($event)"
      />
    </template>

    <div
      v-else
      class="managementSummary"
    >
      <NoData
        title="No containers for this phantom in database... Yet!"
        content="You can search at any time with this phantom or fetch your all containers from PhantomBuster"
        action
        :buttons-action="buttonsAction"
        @changePage="$emit('changePage', 'searchExport')"
        @fetchContainersAndInsert="fetchContainersAndInsert()"
      />
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

const moment = require('moment');

export default {
  name: 'TheNetworkingManagePhantomsSidePanelSummary',
  data() {
    return {
      outputOpen: [],
    };
  },
  computed: {
    ...mapGetters({
      agentList: 'TheNetworking/agentList',
      _containerList: 'TheNetworking/containerList',
    }),
    currentPbaid() {
      return this.$route.params.pbaid;
    },
    containerList() {
      return Object.values(this._containerList)
        .filter(({ pbaid }) => Number(pbaid) === Number(this.currentPbaid))
        .sort((a, b) => moment(b.createdAt).unix() - moment(a.createdAt).unix());
    },
    buttonsAction() {
      return [
        {
          label: 'Start a search',
          type: 'is-primary',
          event: 'changePage',
          buttonType: 'event',
          pack: 'fa',
          left: 'magnifying-glass',
        },
        {
          label: 'Fetch containers',
          type: 'is-primary',
          event: 'fetchContainersAndInsert',
          buttonType: 'event',
          pack: 'fa',
          left: 'ghost',
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      fetchAndUpdate: 'TheNetworking/fetchAndUpdate',
      fetchResult: 'TheNetworking/fetchResult',
      fetchOutput: 'TheNetworking/fetchOutput',
      fetchAllAndInsertContainers: 'TheNetworking/fetchAllAndInsertContainers',
    }),
    refreshContainer(container) {
      const { pbcid, containerId: id } = container;
      this.fetchAndUpdate({
        payload: { pbcid, pbaid: this.currentPbaid },
        httpQuery: { id, withRuntimeEvents: 'true' },
      });
    },
    resultContainer(container) {
      const { pbcid, containerId: id } = container;
      this.fetchResult({
        payload: { pbcid, insert: this.$route.query.insert },
        httpQuery: { id },
      });
    },
    outputContainer(container) {
      if (container.output) return;
      const { pbcid, containerId: id } = container;
      this.fetchOutput({
        payload: { pbcid, pbaid: this.currentPbaid },
        httpQuery: { id },
      });
    },
    fetchContainersAndInsert() {
      const { extrefId: agentId } = this.agentList[this.currentPbaid];
      this.fetchAllAndInsertContainers({
        payload: { pbaid: this.currentPbaid },
        httpQuery: { agentId },
      });
    },
  },
};
</script>
