<template>
  <Notes
    :notes-sorted="notesSorted"
    @addNote="$emit('addNote', $event)"
    @deleteNote="$emit('deleteNote', $event)"
  />
</template>

<script>
import moment from 'moment';

export default {
  name: 'CompanyNotes',
  props: {
    company: { type: Object, required: true },
    companyNotesList: { type: Object, required: true },
  },
  data() {
    return {
      value: '',
    };
  },
  computed: {
    notesSorted() {
      if (!this.companyNotesList || !Object.values(this.companyNotesList).length) return null;
      return Object.values(this.companyNotesList)
        .map((el) => ({ ...el, arrContent: el.content.split('\n') }))
        .sort((a, b) => moment(b.createdAt).unix() - moment(a.createdAt).unix());
    },
  },
};
</script>
