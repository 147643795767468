<template>
  <section
    :class="[{
      'hide': hide
    }]"
  >
    <p class="subtitle is-small m-0">
      You can give more details in comments about the reason of this appointment
    </p>

    <b-field
      label="Description (optional)"
      class="bg-solitude"
    >
      <b-input
        v-model="localDescription"
        type="textarea"
        required
      />
    </b-field>

    <ButtonForm
      :disabled="disabled"
      @next="$emit('next')"
    />
  </section>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */
import animationMixins from '../$mixins/animation.mixins';

export default {
  name: 'DescriptionForm',
  mixins: [animationMixins],
  props: {
    user: { type: Object, default: () => {} },
    appointment: { type: Object, required: true },
    scAppointment: { type: Object, required: true },
    ms: { type: Number, required: true },
  },
  computed: {
    localDescription: {
      get() { return this.appointment.description; },
      set(newValue) { this.$emit('update:description', newValue); },
    },
    disabled() {
      return !!this.scAppointment.validate(this.appointment).error;
    },
  },
};
</script>
