<template>
  <!-- eslint-disable max-len -->
  <div v-if="!loading">
    <section class="dashboard-user-header">
      <article class="dashboard-user-resume mb-5">
        <h3 class="subtitle is-small">{{ $_('Monthly summary') }}</h3>
        <DashboardCard
          v-if="cardTurnover"
          :title="$_('Revenues')"
          size="ultraTitle"
          :data="cardTurnover"
          symbol="€"
          class="mb-5"
        />
        <div class="is-flex is-align-items-start mb-5">
          <DashboardCard
            v-if="cardClient"
            :title="$_('Clients')"
            size="bigtitle"
            :data="cardClient"
          />
          <DashboardCard
            v-if="cardProjects"
            :title="$_('Projects')"
            size="bigtitle"
            :data="cardProjects"
            class="ml-5"
          />
        </div>
        <div class="is-flex is-align-items-start mb-5">
          <DashboardCard
            v-if="userDashboardView.countPerson"
            :title="$_('Contacts added')"
            size="bigtitle"
            :data="{ currentMonth: userDashboardView.countPerson }"
          />
          <DashboardCard
            v-if="userDashboardView.countCompany"
            :title="$_('Companies added')"
            size="bigtitle"
            :data="{ currentMonth: userDashboardView.countCompany }"
            class="ml-5"
          />
        </div>
      </article>
      <DashboardHelloWorld
        class="dashboard-user-appointments mb-3"
        :appointments="userDashboardView.appointment || []"
        :clients="clients"
        :persons="persons"
        :companies="companies"
      />
    </section>

    <br>
    <!-- <DashboardCard
        v-if="cardSpends"
        title="Expenses"
        :data="cardSpends"
        symbol="€"
        :add="addRedirection('addSpends')"
      />
    </section> -->
    <!--
      <div class="multibar">
        <p class="start subtitle">View of expenses</p>
      </div>

      <div class="containerChart w-100">
        <ChartLine
          v-if="cardSpendsChartLine"
          id-chart="turnover2"
          :data="cardSpendsChartLine"
        />
      </div>
    -->
    <article v-if="cardPaymentsChartLine">
      <h3 class="subtitle is-small">{{ $_('Payments received in the month') }}</h3>

      <div class="containerChart">
        <ChartLine
          id-chart="turnover"
          :data="cardPaymentsChartLine"
        />
      </div>
    </article>

    <br>

    <div class="dashboard-latest gap-8">
      <section
        v-if="lastClientsOfCurrentMonth && lastClientsOfCurrentMonth.length"
        class="mb-3 w-100"
      >
        <h3 class="subtitle is-small">{{ $_('Latest registered clients') }}</h3>
        <div class="dashboard-latest-container px-3">
          <article
            v-for="client in lastClientsOfCurrentMonth"
            :key="client.cid"
            class="w-100 mb-3"
          >
            <Contact
              v-if="client.persons && !client.company"
              :pid="client.persons[0].pid"
              :details="persons[client.persons[0].pid].details"
              class="mt-3"
              :size="64"
            />
            <CompanyEmployeesContact
              v-if="client.company"
              :client="client"
              :size="64"
            />
          </article>
        </div>
      </section>

      <section
        v-if="contactHistorySorted"
        class="mb-3 w-100"
      >
        <h3 class="subtitle is-small">{{ $_('Latest registered contact history') }}</h3>
        <div class="dashboard-latest-container">
          <div
            v-for="ch in contactHistorySorted"
            :key="ch.chid"
            class="cch-container mr-3"
          >
            <ContactHistoryCard
              :persons="persons"
              :company="companies"
              :contact-history="ch"
              :display-company="!!ch.companyCid"
            />
          </div>
        </div>
      </section>
    </div>

    <br>

    <article v-if="cardContactHistoryChartBar">
      <div class="multibar align-items-center">
        <h3 class="subtitle is-small start">{{ $_('History of contacts made') }}</h3>
        <div class="buttons end">
          <b-dropdown
            v-model="dropdownContactHistories"
            multiple
            aria-role="list"
            position="is-top-left"
            class="revert"
          >
            <template #trigger>
              <b-button
                type="is-tertiary"
                icon-pack="fa"
                icon-left="ellipsis-vertical"
              />
            </template>
            <template v-for="state in Object.values(states)">
              <b-dropdown-item
                v-if="!state.base"
                :key="state.key"
                :value="state"
                aria-role="listitem"
              >
                <div class="is-flex is-align-items-center">
                  <b-icon
                    :pack="state.pack"
                    :icon="state.icon"
                  />
                  <span class="ml-3">{{ state.name }}</span>
                </div>
              </b-dropdown-item>
            </template>
          </b-dropdown>
        </div>
      </div>
      <div class="containerChart">
        <ChartBar
          id-chart="contactHistory"
          :data="cardContactHistoryChartBar"
        />
      </div>
    </article>

    <br>

    <div class="dashboard-latest gap-8">
      <section
        v-if="lastProjectsOfCurrentMonth && lastProjectsOfCurrentMonth.length"
        class="mb-3 w-100"
      >
        <h3 class="subtitle is-small">{{ $_('Latest registered projects') }}</h3>
        <div class="dashboard-latest-container">
          <article
            v-for="project in lastProjectsOfCurrentMonth"
            :key="project.pid"
            class="w-100 mb-3"
          >
            <ProjectCard :project="project" />
          </article>
        </div>
      </section>

      <section
        v-if="notesSorted"
        class="mb-3 w-100"
      >
        <h3 class="subtitle is-small">{{ $_('Latest registered notes') }}</h3>
        <div class="dashboard-latest-container">
          <template
            v-for="note in notesSorted"
          >
            <NoteCard
              v-if="note"
              :key="note.cnid"
              :note="note"
              :actions="false"
              :companies="companies"
              :persons="persons"
              with-source
              class="mr-3"
            />
          </template>
        </div>
      </section>
    </div>
  </div>
  <LogoAnimated
    v-else
    class="h-full"
  />
</template>

<script>
/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import { mapGetters, mapActions } from 'vuex';

import contactHistoryState from '@jack/addons/common/$mixins/contactHistoryState.mixins';
import dashboardColumnsMixins from './$mixins/TheDashboardColumns.mixins.columns';
import dashboardFunctionsMixins from './$mixins/TheDashboardFunctions.mixins.functions';

export default {
  name: 'TheDashboardUserView',
  mixins: [
    dashboardColumnsMixins,
    dashboardFunctionsMixins,
    contactHistoryState,
  ],
  data() {
    return {
      loading: false,
      dropdownContactHistories: [],
      io: null,
    };
  },
  computed: {
    ...mapGetters({
      userDashboardView: 'TheDashboard/userDashboardView',
      currentUid: 'TheDashboard/currentUid',
      socketToken: 'TheDashboard/socketToken',
    }),
    notesSorted() {
      if (!this.userDashboardView.notes || !this.userDashboardView.notes.length) return null;
      return this.userDashboardView.notes
        .map((el) => ({ ...el, arrContent: el.content ? el.content.split('\n') : null }))
        .sort((a, b) => this.$moment(b.createdAt).unix() - this.$moment(a.createdAt).unix());
    },
    persons() {
      if (!this.userDashboardView.persons || !this.userDashboardView.persons.length) return {};
      return this.userDashboardView.persons.arrayMapper('pid');
    },
    companies() {
      if (!this.userDashboardView.companies || !this.userDashboardView.companies.length) return {};
      return this.userDashboardView.companies.arrayMapper('cid');
    },
    clients() {
      if (!this.userDashboardView.clients || !this.userDashboardView.clients.length) return {};
      return this.userDashboardView.clients.arrayMapper('cid');
    },
    cardTurnover() {
      const { payments } = this.userDashboardView;
      if (!payments || !payments.length) return this.financialDataFormatting({ lastMonth: [], currentMonth: [] });
      return this.financialDataFormatting(this.formatDataCurrentAndLastMonth(payments));
    },
    cardSpends() {
      const { spends } = this.userDashboardView;
      if (!spends || !spends.length) return this.financialDataFormatting({ lastMonth: [], currentMonth: [] });

      return this.financialDataFormatting(this.formatDataCurrentAndLastMonth(spends));
    },
    cardClient() {
      if (!this.userDashboardView.clients) return this.entityFormatting({ lastMonth: [], currentMonth: [] });

      return this.entityFormatting(this.formatDataCurrentAndLastMonth(this.userDashboardView.clients));
    },
    cardProjects() {
      if (!this.userDashboardView.projects) return this.entityFormatting({ lastMonth: [], currentMonth: [] });

      return this.entityFormatting(this.formatDataCurrentAndLastMonth(this.userDashboardView.projects));
    },
    cardPaymentsChartLine() {
      if (!this.cardTurnover) return null;
      return {
        labels: this.labelOfCurrentAndLastMonth,
        datasets: [{
          label: this.$_('Last month'),
          data: this.financialDataGraph(this.cardTurnover.datasLastMonth),
          backgroundColor: ['rgba(77, 77, 77, .5)'],
          borderColor: ['rgba(77, 77, 77, 0.8)'],
          spanGaps: true,
          fill: true,
          color: '77, 77, 77',
        },
        {
          label: this.$_('Current month'),
          data: this.financialDataGraph(this.cardTurnover.datasCurrentMonth),
          backgroundColor: ['rgba(60, 166, 242, .5)'],
          borderColor: ['rgba(60, 166, 242, 0.8)'],
          spanGaps: true,
          fill: true,
          color: '60, 166, 242',
          withoutBg: true,
        }],
      };
    },
    cardSpendsChartLine() {
      if (!this.cardSpends) return null;
      return {
        labels: this.labelOfCurrentAndLastMonth,
        datasets: [{
          label: this.$_('Last month'),
          data: this.financialDataGraph(this.cardSpends.datasLastMonth),
          backgroundColor: ['rgba(26, 188, 156, .5)'],
          borderColor: ['rgba(26, 188, 156, 0.8)'],
          spanGaps: true,
          fill: true,
        },
        {
          label: this.$_('Current month'),
          data: this.financialDataGraph(this.cardSpends.datasCurrentMonth),
          backgroundColor: ['rgba(60, 166, 242, .5)'],
          borderColor: ['rgba(60, 166, 242, 0.8)'],
          spanGaps: true,
          fill: true,
        }],
      };
    },
    cardContactHistoryChartBar() {
      if (!this.userDashboardView.contactHistory || !this.userDashboardView.contactHistory.length) return null;
      return {
        labels: this.labelOfCurrentWeek,
        datasets: Object.values(this.states).filter(({ base }) => base)
          .concat(...this.dropdownContactHistories)
          .reduce((acc, { key, name: label, color }) => {
            acc.push({
              label,
              data: this.contactHistoryOfThisCurrentWeekGraph(
                this.datasOfThisCurrentWeek(this.userDashboardView.contactHistory),
                key,
              ),
              backgroundColor: [`rgba(${color}, .5)`],
              borderColor: [`rgba(${color}, .8)`],
              spanGaps: true,
              fill: true,
              color,
            });

            return acc;
          }, []),
      };
    },
    lastClientsOfCurrentMonth() {
      const { clients } = this.userDashboardView;

      if (!clients || !clients.length) return null;

      return clients
        .filter(({ company, persons }) => company || persons)
        .sort((a, b) => this.$moment(b.createdAt).unix() - this.$moment(a.createdAt).unix())
        .slice(0, 5);
    },
    lastProjectsOfCurrentMonth() {
      const { projects } = this.userDashboardView;

      if (!projects || !projects.length) return null;

      return projects
        .sort((a, b) => this.$moment(b.createdAt).unix() - this.$moment(a.createdAt).unix())
        .slice(0, 5);
    },
    contactHistorySorted() {
      const { contactHistory } = this.userDashboardView;
      if (!contactHistory || !contactHistory.length) return null;

      return contactHistory.sort((a, b) => this.$moment(b.created_at).unix() - this.$moment(a.created_at).unix());
    },
  },
  mounted() {
    this.fetchUserDashboardView();
  },
  methods: {
    ...mapActions({
      getUserDashboardView: 'TheDashboard/getUserDashboardView',
    }),
    openDetails(event, entity) {
      this.$router.push(`/${entity[0]}/overview-view/${entity[1]}/${event[entity[2]]}`);
    },
    addRedirection(entity) {
      const today = this.$formatDate();
      return {
        path: `/home/calendar/sidePanel/${entity}`,
        query: { start: today },
      };
    },
    fetchUserDashboardView() {
      this.loading = true;
      return Promise.resolve()
        .then(() => this.getUserDashboardView({ payload: { uid: this.currentUid } }))
        .then(() => { this.loading = false; });
    },
  },
};
</script>
