<template>
  <!-- eslint-disable max-len -->
  <section>
    <p class="text is-big m-0">{{ $_('Customize your appointment request link.') }}</p>

    <br>

    <form @submit.prevent="$emit('genreateAppointmentLink', formatQueries)">
      <legend class="text is-small mb-3">{{ $_('Range date') }}</legend>
      <b-field
        v-for="option in rangeDateOptions"
        :key="option.value"
      >
        <b-radio
          v-model="dateRangeType"
          size="is-small"
          :native-value="option.value"
        >
          {{ option.label }}
        </b-radio>
      </b-field>

      <template v-if="dateRangeType === 'maxDate'">
        <b-field
          :label="$_('Number of days')"
          class="mt-3"
        >
          <b-numberinput
            v-model="range.max"
            :controls="false"
          />
        </b-field>
      </template>
      <template v-if="dateRangeType === 'dateRange'">
        <b-field
          :label="$_('Date range')"
          class="mt-3"
        >
          <b-datepicker
            v-model="range.beetween"
            :placeholder="$_('Click to select...')"
            :min-date="new Date()"
            range
          />
        </b-field>
      </template>

      <br>

      <legend class="text is-small">{{ $_('Duration of the appointment') }}</legend>
      <b-field :label="`${$_('Duration')} *`">
        <b-select v-model="appointment.duration">
          <option
            v-for="option in durationOptions"
            :key="option.value"
            :value="option.value"
          >
            {{ option.label }}
          </option>
        </b-select>
      </b-field>

      <template v-if="appointment.duration === 0">
        <br>
        <div class="is-flex is-align-items-center gap-8">
          <b-numberinput
            v-model="timeCustom"
            :controls="false"
            class="w-100"
            :max="typeOfTime === 'min' ? 720 : 12"
          />
          <b-select v-model="typeOfTime">
            <option value="min">{{ $_('Minutes') }}</option>
            <option value="hrs">{{ $_('Hours') }}</option>
          </b-select>
        </div>
      </template>

      <br>

      <legend class="text is-small">{{ $_('Appointment') }}</legend>

      <b-field :label="`${$_('Label of appointment')} *`">
        <b-input
          v-model="appointment.label"
          required
        />
      </b-field>

      <b-field :label="$_('Description of appointment')">
        <b-input
          v-model="appointment.description"
          type="textarea"
        />
      </b-field>

      <template v-if="!hasEmail">
        <br>

        <b-field>
          <b-switch
            v-model="queries.mail"
            size="is-small"
            class="switch-custom-param"
          >
            <span class="text is-small">{{ $_('Request email ?') }}</span>
          </b-switch>
        </b-field>
      </template>

      <template v-if="!hasNumber">
        <br>

        <b-field>
          <b-switch
            v-model="queries.number"
            size="is-small"
            class="switch-custom-param"
          >
            <span class="text is-small">{{ $_('Request phone number ?') }}</span>
          </b-switch>
        </b-field>
      </template>

      <br>

      <b-tooltip
        type="is-dark"
        :label="$_('Generate an appointment link')"
        position="is-top"
        class="w-100 mb-5"
      >
        <b-button
          type="is-primary"
          native-type="submit"
          icon-pack="fa"
          icon-left="check"
          :disabled="disable"
          expanded
        />
      </b-tooltip>
    </form>
  </section>
</template>

<script>
/* eslint-disable max-len */
import { mapActions } from 'vuex';

export default {
  name: 'FormScheduledAppointment',
  props: {
    entite: { type: Object, required: true },
    appointmentToken: { type: Object, required: true },
    type: { type: String, default: 'company' },
  },
  data() {
    return {
      queries: {
        newPerson: false,
        label: true,
        duration: true,
        description: true,
        mail: false,
        number: false,
      },
      appointment: {
        label: null,
        duration: null,
        description: null,
      },
      range: {
        max: 30,
        beetween: [],
      },
      dateRangeType: 'maxDate',
      timeCustom: null,
      typeOfTime: null,
    };
  },
  computed: {
    hasEmail() { return !!this.entite.details.mail; },
    hasNumber() { return !!this.entite.details.number; },
    timeCustomeFormat() {
      if (this.appointment.duration) return this.appointment.duration;
      if (!this.timeCustom && !this.typeOfTime) return 0;
      if (this.typeOfTime === 'hrs') return this.timeCustom * 60;
      return this.timeCustom;
    },
    dateRangeFormat() {
      if (this.dateRangeType === 'maxDate') {
        const dates = [
          this.$moment().format('YYYY-MM-DD'),
          this.$moment().add(this.range.max, 'days').format('YYYY-MM-DD'),
        ];
        return { key: this.dateRangeType, value: dates.join(';') };
      }
      if (this.dateRangeType === 'dateRange') {
        return {
          key: this.dateRangeType,
          value: this.range.beetween.map((el) => this.$moment(el).format('YYYY-MM-DD')).join(';'),
        };
      }
      return { key: this.dateRangeType, value: true };
    },
    disable() {
      if (!this.appointment.label || !this.timeCustomeFormat) return true;
      if (this.dateRangeType === 'maxDate' && !this.range.max) return true;
      if (this.dateRangeType === 'dateRange' && !this.range.beetween.length) return true;
      return false;
    },
    maxDate() {
      const max = new Date();
      max.setHours(8);
      return max;
    },
    rangeDateOptions() {
      return [
        { label: this.$_('Choose a maximum date'), value: 'maxDate' },
        { label: this.$_('Choose a date range'), value: 'dateRange' },
        { label: this.$_('No end date'), value: 'noEndDate' },
      ];
    },
    durationOptions() {
      return [
        { label: '15 min', value: 15 },
        { label: '30 min', value: 30 },
        { label: '45 min', value: 45 },
        { label: '60 min', value: 60 },
        { label: this.$_('Custom'), value: 0 },
      ];
    },
    formatQueries() {
      const config = {
        bool: { arr: ['mail', 'number'], value: true },
        data: { arr: ['label', 'description'], value: this.appointment },
        computed: { arr: ['duration'], value: this.timeCustomeFormat },
      };
      return Object.entries(this.queries).reduce((acc, [key, value]) => {
        if (!value) return acc;
        if (config.bool.arr.includes(key)) acc.push(`${key}=${config.bool.value}`);
        if (config.data.arr.includes(key)) acc.push(`${key}=${config.data.value[key]}`);
        if (config.computed.arr.includes(key)) acc.push(`${key}=${config.computed.value}`);
        return acc;
      }, [`${this.dateRangeFormat.key}=${this.dateRangeFormat.value}`]);
    },
  },
  methods: {
    ...mapActions({ pushWsNotification: 'TheWsNotifier/push' }),
    copy() {
      navigator.clipboard.writeText(this.generateUrl);
      this.pushWsNotification({ type: 'info', title: 'Schedule appointment link copied!' });
    },
  },
};
</script>
