/* eslint-disable max-len */
import { SnackbarProgrammatic as Snackbar } from 'buefy';
import { dispatchAndForward, dispatchFailure, snackBarFailure } from '../$utils/helpers';

export default {
  getters: {
    clientList: (_state, _getters, _rootState, rootGetters) => {
      const { 'Client/getterClientList': clientList } = rootGetters;
      return clientList;
    },
    client: (_state, _getters, _rootState, rootGetters) => {
      const { 'Client/getterClient': client } = rootGetters;
      return client;
    },
  },
  actions: {
    reportClientList({ dispatch }, { payload, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'Client/report',
        context: { payload, httpQuery },
        config: {
          start: false,
          end: false,
          endContent: 'Client reported successfully',
          error: 'Unable to report client.',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    getClient({ dispatch }, { payload, httpQuery }) {
      const action = {
        dispatch,
        target: 'Client/get',
        context: { payload, httpQuery },
        config: {
          start: false,
          end: false,
          endContent: 'Client get successfully',
          error: 'Unable to get client.',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    createClient({ dispatch }, { payload = {}, httpQuery = {}, options = {} }) {
      const action = {
        dispatch,
        target: 'Client/create',
        context: { payload, httpQuery, options },
        config: {
          start: false,
          end: true,
          endContent: 'Client created successfully',
          error: 'Unable to create client.',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    updateClient({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'Client/update',
        context: { payload, httpQuery },
        config: {
          start: false,
          end: true,
          endContent: 'Client updated successfully',
          error: 'Unable to update client.',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    deleteClient({ dispatch }, { payload = {}, httpQuery = {}, options = {} }) {
      const action = {
        dispatch,
        target: 'Client/delete',
        context: { payload, httpQuery, options },
        config: {
          start: false,
          end: true,
          endContent: 'Client deleted successfully',
          error: 'Unable to delete client.',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    linkPerson({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'Client/linkPerson/create',
        context: { payload, httpQuery },
        config: {
          start: false,
          end: false,
          endContent: 'Person linked to client successfully',
          error: 'Unable to link a person to the client.',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    unlinkPerson({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'Client/unlinkPerson/create',
        context: { payload, httpQuery },
        config: {
          start: false,
          end: false,
          endContent: 'Person unlinked to client successfully',
          error: 'Unable to unlink a person to the client.',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    linkCompany({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'Client/linkCompany/create',
        context: { payload, httpQuery },
        config: {
          start: false,
          end: false,
          endContent: 'Company linked to client successfully',
          error: 'Unable to link a company to the client.',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    unlinkCompany({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'Client/unlinkCompany/create',
        context: { payload, httpQuery },
        config: {
          start: false,
          end: false,
          endContent: 'Company unlinked to client successfully',
          error: 'Unable to unlink a company to the client.',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    addTag({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'Client/tag/post',
        context: { payload, httpQuery },
        config: {
          start: false,
          end: false,
          endContent: 'Tag linked to client successfully',
          error: 'Unable to link a tag to the client.',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    removeTag({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'Client/tag/delete',
        context: { payload, httpQuery },
        config: {
          start: false,
          end: false,
          endContent: 'Tag unlinked to client successfully',
          error: 'Unable to unlink a tag to the client.',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
  },
};
