/* eslint-disable import/no-extraneous-dependencies */
import { navySeal } from '@jack/provider';
import { isActionAllowed } from '@jack/dataStores/$utils/helpers';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {},
  actions: {
    create({ commit, rootGetters, dispatch }, { payload = {}, httpQuery = {}, options = {} }) {
      if (!isActionAllowed(rootGetters, 'BULK_INSERT_CONTACTS.CREATE', dispatch)) return undefined;
      const { persons, ..._payload } = payload;

      return navySeal.apiWsMi.company.bulkInsertContacts.post({ payload: _payload, httpQuery })
        .then(() => {
          if (!Object.values(options).length) return;
          const { 'Company/getterCompany': company } = rootGetters;
          if (!company.employees) {
            company.employees = Object.values(options);
          } else {
            company.employees.push(...Object.values(options));
          }

          commit('Company/SET_COMPANY', company, { root: true });
        });
    },
  },
};
