<template>
  <section
    :class="[{
      'hide': hide
    }]"
  >
    <p class="subtitle is-small m-0">
      Please enter your number so that {{ user.name }} can contact you by phone.
    </p>

    <b-field
      label="Phone number"
      class="bg-solitude"
    >
      <vue-tel-input
        v-model="person.number"
        default-country="fr"
        :preferred-countries="preferredCountries"
        :input-options="inputOptions"
        :auto-format="false"
        invalid-msg="Invalid phone number"
        valid-characters-only
        @country-changed="changeCodeCountry($event)"
        @validate="validPhoneNumberMethod({ ...$event, key: 'person' })"
      />
    </b-field>

    <ButtonForm
      :disabled="!validPhoneNumber"
      @next="$emit('next')"
    />
  </section>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */
import vueTelInputMixins from '@jack/addons/$mixins/vueTelInput.mixins';
import animationMixins from '../$mixins/animation.mixins';

export default {
  name: 'NumberForm',
  mixins: [
    animationMixins,
    vueTelInputMixins,
  ],
  props: {
    user: { type: Object, default: () => {} },
    person: { type: Object, required: true },
    ms: { type: Number, required: true },
  },
  computed: {
    localNumber: {
      get() { return this.person.number; },
      set(newValue) { this.$emit('update:number', newValue); },
    },
    disabled() {
      return !!(this.scPerson.validate(this.person).error || !this.person.number);
    },
  },
};
</script>
