import { use, registerComponent } from '../../$utils/plugins';

import Autocomplete from './Autocomplete';
import FullCalendarView from './FullCalendarView';
import JsonReader from './JsonReader';
import Table from './Table';
import TableSkeleton from './TableSkeleton';
import Helper from './Helper';
import NoData from './NoData';
import TheFilter from './TheFilter';
import TooltipAction from './TooltipAction';
import BulkActions from './BulkActions';
import WsNotifiers from './WsNotifiers';
import WsNotifierCard from './WsNotifierCard';
import Skeleton from './Skeleton';
import InitialsPicture from './InitialsPicture';
import Contact from './Contact';
import Company from './Company';
import CompanyEmployeesContact from './CompanyEmployeesContact';
import ProjectCard from './ProjectCard';
import PaymentCard from './PaymentCard';
import ContactHistoryCard from './ContactHistoryCard';
import NoteCard from './NoteCard';
import LogoAnimated from './LogoAnimated';
import ModalChooseTemplate from './ModalChooseTemplate';
import TemplateCard from './TemplateCard';
import ContactChoice from './ContactChoice';
import TagAutocomplete from './TagAutocomplete';
import TagList from './TagList';

// TheFilter - Item
import RangeFilter from './Filter/RangeFilter';

// Fullcalendar.js
import JackFullCalendarTooltip from './fullCalendar/JackFullCalendarTooltip';

// Chart.js
import ChartJs from './Chartjs/ChartJs';
import ChartBar from './Chartjs/ChartBar';
import ChartLine from './Chartjs/ChartLine';

// CardIdentity
import PersonCI from './CardIdentity/PersonCI.vue';

// Side Panel
import JackSidePanelSummary from './sidePanel/JackSidePanelSummary';
import JackTabClientProjectHistory from './sidePanel/JackTabClientProjectHistory';
import JackTabClientPaymentHistory from './sidePanel/JackTabClientPaymentHistory';

// Side Panel - Global
import JackSidePanelHeader from './sidePanel/utils/JackSidePanelHeader';
import JackStepControls from './sidePanel/utils/JackStepControls';

// Side Panel - Summary
import JackSidePanelSummaryEventCardItem from './sidePanel/Summary/JackSidePanelSummaryEventCardItem';

// Side Panel - Client
import JackSidePanelAddClientInformationForm from './sidePanel/Client/JackSidePanelAddClientInformationForm';
import JackSidePanelAddClientSocialForm from './sidePanel/Client/JackSidePanelAddClientSocialForm';
import JackSidePanelAddClientConfirmation from './sidePanel/Client/JackSidePanelAddClientConfirmation';
import JackSidePanelAddClientSuccessCreate from './sidePanel/Client/JackSidePanelAddClientSuccessCreate';
import JackSidePanelClientDetails from './sidePanel/Client/JackSidePanelClientDetails';

// Side Panel - Payment
import JackSidePanelAddPaymentClientForm from './sidePanel/Payment/JackSidePanelAddPaymentClientForm';
import JackSidePanelAddPaymentProjectForm from './sidePanel/Payment/JackSidePanelAddPaymentProjectForm';
import JackSidePanelAddPaymentForm from './sidePanel/Payment/JackSidePanelAddPaymentForm';
import JackSidePanelAddPaymentConfirmation from './sidePanel/Payment/JackSidePanelAddPaymentConfirmation';
import JackSidePanelAddPaymentSuccessCreate from './sidePanel/Payment/JackSidePanelAddPaymentSuccessCreate';

// Side Panel - Project
import JackSidePanelAddProjectClientForm from './sidePanel/Project/JackSidePanelAddProjectClientForm';
import JackSidePanelAddProjectInformationForm from './sidePanel/Project/JackSidePanelAddProjectInformationForm';
import JackSidePanelAddProjectConfirmation from './sidePanel/Project/JackSidePanelAddProjectConfirmation';
import JackSidePanelAddProjectSuccessCreate from './sidePanel/Project/JackSidePanelAddProjectSuccessCreate';
import JackSidePanelProjectDetails from './sidePanel/Project/JackSidePanelProjectDetails';

// Side Panel - Spends
import JackSidePanelAddSpendForm from './sidePanel/Spends/JackSidePanelAddSpendForm';
import JackSidePanelAddSpendConfirmation from './sidePanel/Spends/JackSidePanelAddSpendConfirmation';
import JackSidePanelAddSpendSuccessCreate from './sidePanel/Spends/JackSidePanelAddSpendSuccessCreate';

// Side Panel - Appointment
import JackSidePanelAddAppointmentInformationForm from './sidePanel/Appointment/JackSidePanelAddAppointmentInformationForm';
import JackSidePanelAddAppointmentLinkForm from './sidePanel/Appointment/JackSidePanelAddAppointmentLinkForm';
import JackSidePanelAddAppointmentConfirmation from './sidePanel/Appointment/JackSidePanelAddAppointmentConfirmation';
import JackSidePanelAddAppointmentSuccess from './sidePanel/Appointment/JackSidePanelAddAppointmentSuccess';

// BulkActions
import BulkImportCompany from './bulkActions/BulkImportCompany';
import BulkExportEmployees from './bulkActions/BulkExportEmployees';
import BulkImportCompaniesSelected from './bulkActions/importCompany/BulkImportCompaniesSelected';

// Person
import AddPersonItem from './Person/AddPersonItem';
import AddPersonChoice from './Person/AddPersonChoice';
import FormNewPerson from './Person/FormNewPerson';

// Filter panel
import FilterPanel from './FilterPanel/FilterPanel';

// Notes
import Notes from './Notes/Notes';
import NoteTextarea from './Notes/NoteTextarea';

// Contact history
import ContactHistory from './ContactHistory/ContactHistory';
import AddContactHistory from './ContactHistory/AddContactHistory';
import ContactHistoryList from './ContactHistory/ContactHistoryList';

// Appointment
import Appointment from './Appointment/Appointment';
import AddAppointment from './Appointment/AddAppointment';
import AppointmentList from './Appointment/AppointmentList';
import AppointmentInfo from './Appointment/AppointmentInfo';
import AppointmentTokenInfo from './Appointment/AppointmentTokenInfo';
import ModalScheduleAppointment from './Appointment/ModalScheduleAppointment';
import FormScheduledAppointment from './Appointment/FormScheduledAppointment';

// Project
import Project from './Project/Project';
import AddProject from './Project/AddProject';
import ProjectList from './Project/ProjectList';

// Payment
import Payment from './Payment/Payment';
import AddPayment from './Payment/AddPayment';
import PaymentList from './Payment/PaymentList';

// Paginations
import Paginations from './Paginations/Paginations';

// Notification
import Notification from './Notification/Notification';

import AddContactBySearch from './AddContact/AddContactBySearch';
import AddContactByYourself from './AddContact/AddContactByYourself';
import AddContactByLinkedin from './AddContact/AddContactByLinkedin';
import AddContactByInstagram from './AddContact/AddContactByInstagram';
import AddCompanyByYourself from './AddCompany/AddCompanyByYourself';
import AddCompanyByLinkedin from './AddCompany/AddCompanyByLinkedin';
import AddCompanyByInstagram from './AddCompany/AddCompanyByInstagram';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, Autocomplete);
    registerComponent(Vue, FullCalendarView);
    registerComponent(Vue, JsonReader);
    registerComponent(Vue, Table);
    registerComponent(Vue, TableSkeleton);
    registerComponent(Vue, Helper);
    registerComponent(Vue, NoData);
    registerComponent(Vue, TheFilter);
    registerComponent(Vue, TooltipAction);
    registerComponent(Vue, BulkActions);
    registerComponent(Vue, WsNotifiers);
    registerComponent(Vue, WsNotifierCard);
    registerComponent(Vue, Skeleton);
    registerComponent(Vue, InitialsPicture);
    registerComponent(Vue, Contact);
    registerComponent(Vue, Company);
    registerComponent(Vue, CompanyEmployeesContact);
    registerComponent(Vue, ProjectCard);
    registerComponent(Vue, PaymentCard);
    registerComponent(Vue, ContactHistoryCard);
    registerComponent(Vue, NoteCard);
    registerComponent(Vue, LogoAnimated);
    registerComponent(Vue, ModalChooseTemplate);
    registerComponent(Vue, TemplateCard);
    registerComponent(Vue, ContactChoice);
    registerComponent(Vue, TagAutocomplete);
    registerComponent(Vue, TagList);

    // TheFilter - Item
    registerComponent(Vue, RangeFilter);

    // Fullcalendar.js
    registerComponent(Vue, JackFullCalendarTooltip);

    // Chart.js
    registerComponent(Vue, ChartJs);
    registerComponent(Vue, ChartBar);
    registerComponent(Vue, ChartLine);

    // CardIdentity
    registerComponent(Vue, PersonCI);
    // Side Panel
    registerComponent(Vue, JackSidePanelSummary);
    registerComponent(Vue, JackTabClientProjectHistory);
    registerComponent(Vue, JackTabClientPaymentHistory);

    // Side Panel - Global
    registerComponent(Vue, JackSidePanelHeader);
    registerComponent(Vue, JackStepControls);

    // Side Panel - Summary
    registerComponent(Vue, JackSidePanelSummaryEventCardItem);

    // Side Panel - Client
    registerComponent(Vue, JackSidePanelAddClientInformationForm);
    registerComponent(Vue, JackSidePanelAddClientSocialForm);
    registerComponent(Vue, JackSidePanelAddClientConfirmation);
    registerComponent(Vue, JackSidePanelAddClientSuccessCreate);
    registerComponent(Vue, JackSidePanelClientDetails);

    // Side Panel - Payment
    registerComponent(Vue, JackSidePanelAddPaymentClientForm);
    registerComponent(Vue, JackSidePanelAddPaymentProjectForm);
    registerComponent(Vue, JackSidePanelAddPaymentForm);
    registerComponent(Vue, JackSidePanelAddPaymentConfirmation);
    registerComponent(Vue, JackSidePanelAddPaymentSuccessCreate);

    // Side Panel - Project
    registerComponent(Vue, JackSidePanelAddProjectClientForm);
    registerComponent(Vue, JackSidePanelAddProjectInformationForm);
    registerComponent(Vue, JackSidePanelAddProjectConfirmation);
    registerComponent(Vue, JackSidePanelAddProjectSuccessCreate);
    registerComponent(Vue, JackSidePanelProjectDetails);

    // Side Panel - Spends
    registerComponent(Vue, JackSidePanelAddSpendForm);
    registerComponent(Vue, JackSidePanelAddSpendConfirmation);
    registerComponent(Vue, JackSidePanelAddSpendSuccessCreate);

    // Side Panel - Appointment
    registerComponent(Vue, JackSidePanelAddAppointmentInformationForm);
    registerComponent(Vue, JackSidePanelAddAppointmentLinkForm);
    registerComponent(Vue, JackSidePanelAddAppointmentConfirmation);
    registerComponent(Vue, JackSidePanelAddAppointmentSuccess);

    // BulkActions
    registerComponent(Vue, BulkImportCompany);
    registerComponent(Vue, BulkExportEmployees);
    registerComponent(Vue, BulkImportCompaniesSelected);

    // Person
    registerComponent(Vue, AddPersonItem);
    registerComponent(Vue, AddPersonChoice);
    registerComponent(Vue, FormNewPerson);

    // Filter panel
    registerComponent(Vue, FilterPanel);

    // Notes
    registerComponent(Vue, Notes);
    registerComponent(Vue, NoteTextarea);

    // Contact history
    registerComponent(Vue, ContactHistory);
    registerComponent(Vue, AddContactHistory);
    registerComponent(Vue, ContactHistoryList);

    // Paginations
    registerComponent(Vue, Paginations);

    // Appointment
    registerComponent(Vue, Appointment);
    registerComponent(Vue, AddAppointment);
    registerComponent(Vue, AppointmentList);
    registerComponent(Vue, AppointmentInfo);
    registerComponent(Vue, AppointmentTokenInfo);
    registerComponent(Vue, ModalScheduleAppointment);
    registerComponent(Vue, FormScheduledAppointment);

    // Project
    registerComponent(Vue, Project);
    registerComponent(Vue, AddProject);
    registerComponent(Vue, ProjectList);

    // Payment
    registerComponent(Vue, Payment);
    registerComponent(Vue, AddPayment);
    registerComponent(Vue, PaymentList);
    registerComponent(Vue, AddContactBySearch);
    registerComponent(Vue, AddContactByYourself);
    registerComponent(Vue, AddContactByLinkedin);
    registerComponent(Vue, AddContactByInstagram);
    registerComponent(Vue, AddCompanyByYourself);
    registerComponent(Vue, AddCompanyByLinkedin);
    registerComponent(Vue, AddCompanyByInstagram);

    // Notification
    registerComponent(Vue, Notification);
  },
};

use(Plugin);

export default Plugin;

export {
  Autocomplete,
  FullCalendarView,
  JsonReader,
  Table,
  TableSkeleton,
  Helper,
  NoData,
  TheFilter,
  TooltipAction,
  BulkActions,
  WsNotifiers,
  WsNotifierCard,
  Skeleton,
  InitialsPicture,
  Contact,
  Company,
  CompanyEmployeesContact,
  ProjectCard,
  PaymentCard,
  ContactHistoryCard,
  NoteCard,
  LogoAnimated,
  ModalChooseTemplate,
  TemplateCard,
  ContactChoice,
  TagAutocomplete,
  TagList,

  // TheFilter - Item
  RangeFilter,

  // Fullcalendar.js
  JackFullCalendarTooltip,

  // Chart.js
  ChartJs,
  ChartBar,
  ChartLine,

  // CardIdentity
  PersonCI,

  // Side Panel
  JackSidePanelSummary,
  JackTabClientProjectHistory,
  JackTabClientPaymentHistory,

  // Side Panel - Global
  JackSidePanelHeader,
  JackStepControls,

  // Side Panel - Summary
  JackSidePanelSummaryEventCardItem,

  // Side Panel - Client
  JackSidePanelAddClientInformationForm,
  JackSidePanelAddClientSocialForm,
  JackSidePanelAddClientConfirmation,
  JackSidePanelAddClientSuccessCreate,
  JackSidePanelClientDetails,

  // Side Panel - Payment
  JackSidePanelAddPaymentClientForm,
  JackSidePanelAddPaymentProjectForm,
  JackSidePanelAddPaymentForm,
  JackSidePanelAddPaymentConfirmation,
  JackSidePanelAddPaymentSuccessCreate,

  // Side Panel - Project
  JackSidePanelAddProjectClientForm,
  JackSidePanelAddProjectInformationForm,
  JackSidePanelAddProjectConfirmation,
  JackSidePanelAddProjectSuccessCreate,
  JackSidePanelProjectDetails,

  // Side Panel - Spends
  JackSidePanelAddSpendForm,
  JackSidePanelAddSpendConfirmation,
  JackSidePanelAddSpendSuccessCreate,

  // Side Panel - Appointment
  JackSidePanelAddAppointmentInformationForm,
  JackSidePanelAddAppointmentLinkForm,
  JackSidePanelAddAppointmentConfirmation,
  JackSidePanelAddAppointmentSuccess,

  // BulkActions
  BulkImportCompany,
  BulkExportEmployees,
  BulkImportCompaniesSelected,

  // Person
  AddPersonItem,
  AddPersonChoice,
  FormNewPerson,

  // Filter panel
  FilterPanel,

  // Notes
  Notes,
  NoteTextarea,

  // Contact history
  ContactHistory,
  AddContactHistory,
  ContactHistoryList,

  // Paginations
  Paginations,

  // Appointment
  Appointment,
  AddAppointment,
  AppointmentList,
  AppointmentInfo,
  AppointmentTokenInfo,
  ModalScheduleAppointment,
  FormScheduledAppointment,

  // Project
  Project,
  AddProject,
  ProjectList,

  // Payment
  Payment,
  AddPayment,
  PaymentList,
  AddContactBySearch,
  AddContactByYourself,
  AddContactByLinkedin,
  AddContactByInstagram,
  AddCompanyByYourself,
  AddCompanyByLinkedin,
  AddCompanyByInstagram,

  // Notification
  Notification,
};
