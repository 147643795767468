<template>
  <Project
    :entite="person"
    @addProject="$emit('addProject', $event)"
    @deleteProject="$emit('deleteProject', $event)"
  />
</template>

<script>
export default {
  name: 'PersonProjects',
  props: {
    person: { type: Object, required: true },
  },
};
</script>
