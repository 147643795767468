<template>
  <div>
    <TagList
      :entite="person"
      :tag-list="tagList"
    />
    <section>
      <template v-for="info in informations">
        <div
          v-if="person.details[info.key]"
          :key="info.key"
          class="mb-2"
        >
          <span class="text">{{ info.label }}</span>
          <p class="subtext has-text-secondary">{{ person.details[info.key] }}</p>
        </div>
      </template>
    </section>
    <section v-if="person.companies && person.companies.length">
      <span class="text">Companies</span>
      <article
        v-for="company in person.companies"
        :key="company.pid"
        class="person is-justify-content-center"
      >
        <div class="content is-flex is-align-items-center is-justify-content-space-between">
          <div class="is-flex is-align-items-center">
            <div
              v-if="company.details.logo"
              class="profile-picture item size is-48x48"
              :style="`background:url('${company.details.logo}')`"
            />
            <InitialsPicture
              v-else
              :name="company.details.name"
              class="item"
              :size="48"
            />
            <div class="ml-5">
              <!-- <p
                  class="subtitle is-big cursor-pointer p-0 m-0"
                > -->
              <router-link
                :to="`/networking/companies/${company.cid}/details`"
                class="subtitle is-big cursor-pointer"
              >
                {{ company.details.name }}
              </router-link>
              <!-- </p> -->
              <p class="subtext is-small has-text-secondary">{{ company.details.tagLine }}</p>
            </div>
          </div>

          <b-icon
            v-if="company.isContact"
            pack="fa"
            icon="fire"
            class="text-color-3 ml-5"
          />
        </div>
      </article>
    </section>
  </div>
</template>

<script>
export default {
  name: 'PersonInformations',
  props: {
    person: { type: Object, required: true },
    tagList: { type: Object, required: true },
  },
  computed: {
    informations() {
      return [
        { name: 'Followers:', key: 'subscribers' },
        { name: 'Job title:', key: 'jobTitle' },
        { name: 'Description:', key: 'description' },
      ];
    },
  },
};
</script>
