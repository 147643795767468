<template>
  <article class="walletCard no-shadow transparent w-100">
    <div class="multibar is-align-items-center">
      <p class="text is-small start m-0">{{ title }}</p>
      <b-button
        v-if="add"
        tag="router-link"
        type="is-tertiary"
        icon-pack="fa"
        icon-left="plus"
        :to="add"
        class="end"
        size="small"
      />
    </div>
    <div class="is-flex is-align-items-center">
      <p class="title is-big m-0 mr-2">
        <span
          :class="[size, {
            'text-color-5': type === 'is-success',
            'text-color-2': type === 'is-danger',
          }]"
        >
          {{ data.currentMonth }}
        </span>
        <span
          v-if="symbol"
          :class="[size, {
            'text-color-5': type === 'is-success',
            'text-color-2': type === 'is-danger',
          }]"
        >
          {{ symbol }}
        </span>
      </p>
      <b-tag
        v-if="data.percent && data.percent !== Infinity"
        :type="Math.sign(data.percent) === 1 ? 'is-success' : 'is-danger'"
      >
        <b-icon
          pack="fa"
          :icon="Math.sign(data.percent) === 1 ? 'arrow-up' : 'arrow-down'"
        />
        <span>{{ Math.round(data.percent) }}%</span>
      </b-tag>
    </div>
    <div class="is-flex is-align-items-center">
      <p class="text has-text-secondary">
        {{ data.lastMonth }}
        <span v-if="symbol">{{ symbol }}</span>
      </p>
    </div>
  </article>
</template>

<script>
export default {
  name: 'DashboardCard',
  props: {
    data: { type: Object, required: true },
    title: { type: String, required: true },
    size: { type: String, default: '' },
    symbol: { type: String, default: '' },
    type: { type: String, default: '' },
    add: { type: Object, default: () => {} },
  },
};
</script>
