<template>
  <div>
    TheToast
  </div>
</template>

<script>
export default {
  name: 'TheToast',
};
</script>
