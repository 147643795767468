<template>
  <article
    :class="['card choice mb-5', {
      'disabled': choice.disabled
    }]"
    @click="$emit('select', { choice, entite })"
  >
    <div class="choice-container">
      <b-icon
        :pack="choice.pack"
        :icon="choice.icon"
        size="is-large"
        class="mt-1"
      />
      <div>
        <p
          :class="['mb-0', {
            'bigtitle': !sidepanel,
            'title': sidepanel,
          }]"
        >
          {{ choice.name }}
        </p>
        <p class="lead has-text-secondary">
          {{ choice.description }}
        </p>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  name: 'ContactChoice',
  props: {
    choice: { type: Object, required: true },
    entite: { type: String, required: true },
    sidepanel: { type: Boolean, default: false },
  },
};
</script>
