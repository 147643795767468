/* eslint-disable import/no-extraneous-dependencies */
import { getters, actions } from '@jack/dataStores/commonViewStores';

export default {
  namespaced: true,
  state: {},
  getters: {
    ...getters.templates,
    ...getters.authenticate,
  },
  actions: {
    ...actions.templates,
  },
};
