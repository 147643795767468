/* eslint-disable max-len */
import { dispatchAndForward, dispatchFailure } from '../$utils/helpers';

export default {
  getters: {
    myAuthenticate: (_state, _getters, _rootState, rootGetters) => {
      const { 'Authenticate/getterAuthenticate': getterAuthenticate } = rootGetters;

      return getterAuthenticate;
    },
    currentAuthenticateAid: (_state, _getters, _rootState, rootGetters) => {
      const { 'Authenticate/getterAuthenticate': getterAuthenticate } = rootGetters;

      return getterAuthenticate.aid;
    },
    currentAccountAid: (_state, _getters, _rootState, rootGetters) => {
      const { 'Authenticate/getterAuthenticate': getterAuthenticate } = rootGetters;

      return getterAuthenticate.account.aid;
    },
    currentUid: (_state, _getters, _rootState, rootGetters) => {
      const { 'Authenticate/getterAuthenticate': getterAuthenticate } = rootGetters;

      return getterAuthenticate.uid;
    },
    currentBaid: (_state, _getters, _rootState, rootGetters) => {
      const { 'Authenticate/getterAuthenticate': getterAuthenticate } = rootGetters;

      return getterAuthenticate.bookAccount.baid;
    },
    myDetails(_state, _getters, _rootState, rootGetters) {
      const { 'Authenticate/getterAuthenticate': getterAuthenticate } = rootGetters;
      return getterAuthenticate.details;
    },
    myPassName(_state, _getters, _rootState, rootGetters) {
      const { 'Authenticate/getterAuthenticate': getterAuthenticate } = rootGetters;

      return getterAuthenticate.passName;
    },
    admin(_state, getters, _rootState, rootGetters) {
      const { 'Authenticate/getterAuthenticate': getterAuthenticate } = rootGetters;

      return getterAuthenticate.isAdmin;
    },
    adminOnAccount(_state, getters, _rootState, rootGetters) {
      const { 'Authenticate/getterAuthenticate': getterAuthenticate } = rootGetters;
      if (!getterAuthenticate) return false;
      return getterAuthenticate.account.hasUser.find(({ userUid }) => Number(userUid) === Number(getterAuthenticate.uid)).isAdmin;
    },
    myAccountList(_state, _getters, _rootState, rootGetters) {
      const { 'Authenticate/getterAuthenticate': getterAuthenticate } = rootGetters;

      return getterAuthenticate.accountList;
    },
    myClientList(_state, _getters, _rootState, rootGetters) {
      const { 'Authenticate/getterAuthenticate': getterAuthenticate } = rootGetters;

      return getterAuthenticate.hasClient;
    },
  },
  actions: {
    logIn({ dispatch }, { payload }) {
      const action = {
        dispatch,
        target: 'Authenticate/connectUser',
        context: { payload },
        config: {
          start: false,
          end: false,
          error: 'Unable to log in',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    reconnect({ dispatch }) {
      const action = {
        dispatch,
        target: 'Authenticate/reconnectUser',
        context: {},
        config: {
          start: false,
          end: false,
          error: 'Unable to log in',
        },
      };

      return dispatchAndForward(action)
        .catch((error) => {
          dispatchFailure(action);
          throw new Error(error);
        });
    },
    logout({ dispatch }) {
      const action = {
        dispatch,
        target: 'Authenticate/disconnectUser',
        context: { },
        config: {
          start: false,
          end: false,
          error: 'Unable to log out',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    changePassword({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'Authenticate/changePassword/post',
        context: { payload, httpQuery },
        textNotifier: 'Change password',
        retry: { onSuccess: false, onFailure: false },
        config: {
          start: false,
          end: true,
          endContent: 'Password was successfully changed',
          error: 'Unable to change password',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
  },
};
