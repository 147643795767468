<template>
  <section
    :class="['form-add', {
      'side-panel': sidepanel
    }]"
  >
    <div class="content-form">
      <h1 class="title is-small">
        {{ $_('Create a contact by yourself') }}
      </h1>

      <form @submit.prevent="newContactByYourself()">
        <template v-for="input in inputs">
          <b-field
            v-if="input.key !== 'vue-tel-input'"
            :key="input.key"
            :label="input.label"
            :class="['mb-2', {
              'bg-solitude': !sidepanel
            }]"
          >
            <component
              :is="input.cmp"
              v-model="person[input.key]"
              :required="input.required"
              :type="input.type"
            />
          </b-field>
          <b-field
            v-if="input.key === 'vue-tel-input'"
            :key="input.key"
            :label="input.label"
            :class="[{
              'bg-solitude': !sidepanel
            }]"
          >
            <vue-tel-input
              v-model="person.number"
              default-country="fr"
              :preferred-countries="preferredCountries"
              :input-options="inputOptions"
              :auto-format="false"
              :invalid-msg="$_('Invalid phone number')"
              valid-characters-only
              @country-changed="changeCodeCountry($event)"
              @validate="validPhoneNumberMethod($event)"
            />
          </b-field>
        </template>

        <br>

        <b-button
          native-type="submit"
          type="is-primary"
          icon-pack="fa"
          icon-left="check"
          expanded
          :disabled="disableButton"
        />
      </form>
    </div>
  </section>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */
import vueTelInputConfig from '@jack/addons/common/$mixins/vueTelInputConfig';

export default {
  name: 'AddContactByYourself',
  mixins: [vueTelInputConfig],
  props: {
    userUid: { type: Number, default: null },
    accountAid: { type: Number, default: null },
    sidepanel: { type: Boolean, default: false },
  },
  data() {
    return {
      person: {
        firstName: null,
        lastName: null,
        description: null,
        headLine: null,
        imageUrl: null,
        location: null,
        profileUrl: null,
        backgroundUrl: null,
        mail: null,
        number: null,
        subscribers: null,
        jobTitle: null,
      },
    };
  },
  computed: {
    disableButton() {
      if (!this.person.firstName || !this.person.lastName) return true;
      return false;
    },
    inputs() {
      return {
        firstName: {
          label: `${this.$_('First name')} *`, key: 'firstName', cmp: 'b-input', required: true, type: 'text',
        },
        lastName: {
          label: `${this.$_('Last name')} *`, key: 'lastName', cmp: 'b-input', required: true, type: 'text',
        },
        mail: {
          label: 'Email', key: 'mail', cmp: 'b-input', required: false, type: 'email',
        },
        number: {
          label: this.$_('Phone number'), key: 'number', cmp: 'vue-tel-input', required: false,
        },
      };
    },
  },
  methods: {
    validPhoneNumberMethod({ valid, formatted }) {
      this.validPhoneNumber = valid;
      if (!formatted) return;
      this.person.number = formatted.replace(/ /g, '');
    },
    newContactByYourself() {
      this.$emit('newContactByYourself', {
        fetchedAt: this.$moment().format(),
        source: 'DEFAULT',
        refreshAt: null,
        extrefId: null,
        userUid: this.userUid,
        accountAid: this.accountAid,
        details: {
          ...this.person,
          number: this.person.number ? this.person.number : null,
          fullName: `${this.person.firstName} ${this.person.lastName}`,
        },
      });
    },
  },
};
</script>
