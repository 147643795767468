<template>
  <section class="page404">
    <h1 class="ultraTitle text-color-1-20">404</h1>
    <div class="buttons">
      <b-button
        type="is-tertiary"
        icon-pack="fa"
        icon-left="home"
        tag="router-link"
        to="/dashboard/user"
      />
    </div>
  </section>
</template>

<script>

export default {
  name: 'TheError404',
};
</script>
