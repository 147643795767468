<template>
  <!-- eslint-disable max-len -->
  <section>
    <p class="lead is-big">
      {{ $_('Welcome') }}
    </p>

    <br>

    <p class="lead is-small">
      {{ $_('We are delighted to welcome you for the first time.') }}
    </p>

    <br>

    <p class="lead is-small">
      {{ $_('Before you begin to explore the features and benefits of our service, please take a moment to read carefully and accept our') }}
      <router-link
        to="/cgu"
        target="_blank"
      >
        {{ $_('CGU') }}.
      </router-link>
    </p>

    <br>

    <b-button
      type="is-success"
      :label="$_('Accept Terms and conditions of use')"
      icon-left="check"
      icon-pack="fa"
      expanded
      @click="acceptCGU()"
    />
  </section>
</template>

<script>
export default {
  name: 'TheFirstConnectionWelcome',
  methods: {
    acceptCGU() {
      this.$router.push('/first-connection/change-password');
    },
  },
};
</script>
