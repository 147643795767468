<template>
  <aside class="jackSidePanel">
    <div
      class="is-flex is-align-items-center is-justify-content-space-between"
    >
      <div class="start">
        <h2 class="bigtitle is-big m-0">Summary</h2>
        <p class="has-text-secondary text">{{ dateSelected }}</p>
      </div>

      <div class="buttons">
        <b-dropdown
          aria-role="list"
          class="end"
          position="is-bottom-left"
        >
          <template #trigger>
            <b-button
              type="is-tertiary"
              icon-pack="fa"
              icon-left="plus"
            />
          </template>
          <template v-for="action in arrayActions">
            <b-dropdown-item
              v-if="action.display"
              :key="action.name"
              aria-role="listitem"
              :disabled="action.disabled"
              @click="$router.push(action.redirect)"
            >
              <div class="media">
                <b-icon
                  class="media-left"
                  pack="fa"
                  :icon="action.icon"
                />
                <div class="media-content">
                  <p class="subtitle m-0">{{ action.name }}</p>
                  <p class="subtext is-small has-text-secondary">{{ action.description }}</p>
                </div>
              </div>
            </b-dropdown-item>
          </template>
        </b-dropdown>
        <b-button
          type="is-tertiary"
          icon-pack="fa"
          icon-left="times"
          class="ml-3"
          @click="$router.push({ path: '/calendar' })"
        />
      </div>
    </div>

    <br>

    <JackSidePanelSummary
      :path="`${path}/sidePanel`"
      :retrieve-events-of-the-day="retrieveEventsOfTheDay"
    />
    <DevDetails :cnt="{name: 'retrieveEventsOfTheDay', obj: retrieveEventsOfTheDay}" />

    <!-- <router-view
      name="addClient"
      :path-home="path"
      :go-back="currentPath"
    /> -->
    <router-view
      name="event"
      :path-home="path"
      :go-back="currentPath"
    />
    <!-- <router-view
      name="addProject"
      :path-home="path"
      :go-back="currentPath"
    /> -->
    <router-view
      name="addPayment"
      :path-home="path"
      :go-back="currentPath"
    />
    <!-- <router-view
      name="addSpends"
      :path-home="path"
      :go-back="currentPath"
    /> -->
    <router-view
      name="addAppointment"
      :path-home="path"
      :go-back="currentPath"
    />
  </aside>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TheSidePanel',
  props: {
    path: { type: String, required: true },
    allEventsOfMonth: { type: Array, required: true },
  },
  data() {
    return {
      selected: null,
    };
  },
  computed: {
    ...mapGetters({
      myAuthenticate: 'TheSidePanel/myAuthenticate',
    }),
    started() {
      return this.$currentQuery('start');
    },
    ended() {
      return this.$currentQuery('end');
    },
    retrieveEventsOfTheDay() {
      if (!this.allEventsOfMonth || !this.allEventsOfMonth.length) return [];
      const { started } = this;
      return this.allEventsOfMonth
        .filter(({ start, end }) => start === started
          || this.$moment(started).isBetween(start, end)
          || end === started)
        .map(({ extendedProps }) => extendedProps);
    },
    dateSelected() {
      const { started, ended } = this;
      let startFormatted = null;
      let endFormatted = null;
      let textEnd;

      if (started) startFormatted = this.$moment(started).format('MMMM Do, YYYY');
      if (ended) {
        endFormatted = this.$moment(ended).format('MMMM Do, YYYY');
        textEnd = ` - ${endFormatted}`;
      }

      return `${startFormatted}${ended ? textEnd : ''}`;
    },
    isMultipleDateSelected() {
      const { start, end } = this.$route.query;
      const numberOfDaysSelected = this.$moment(end).diff(this.$moment(start), 'days');

      if (!numberOfDaysSelected) return true;
      return false;
    },
    arrayActions() {
      return [
        {
          name: 'Appointment',
          description: 'Add a new appointment with a company or a contact',
          icon: 'briefcase',
          display: true,
          disabled: false,
          redirect: {
            path: `${this.path}/sidePanel/addAppointment`,
            query: this.$route.query,
          },
        },
        {
          name: 'Client',
          description: 'Add a new client with a company or a contact',
          icon: 'user-plus',
          display: false,
          disabled: true,
          redirect: {
            path: `${this.path}/sidePanel/addClient`,
            query: this.$route.query,
          },
        },
        {
          name: 'Project',
          description: 'Add a project from a client',
          icon: 'palette',
          display: false,
          disabled: true,
          redirect: {
            path: `${this.path}/sidePanel/addProject`,
            query: this.$route.query,
          },
        },
        {
          name: 'Payment',
          description: 'Add a payment from a project',
          icon: 'dollar-sign',
          display: true,
          disabled: false,
          redirect: {
            path: `${this.path}/sidePanel/addPayment`,
            query: this.$route.query,
          },
        },
        {
          name: 'Spends',
          description: 'Add a new spends personal or professional',
          icon: 'hand-holding-usd',
          display: true,
          disabled: true,
          redirect: {
            path: `${this.path}/sidePanel/addSpends`,
            query: this.$route.query,
          },
        },
      ];
    },
    currentPath() {
      return {
        path: `${this.path}/sidePanel`,
        query: this.$route.query,
      };
    },
  },
};
</script>
