/* eslint-disable max-len */
import { dispatchAndForward, dispatchFailure } from '../$utils/helpers';

export default {
  getters: {
    appointmentToken: (_state, _getters, _rootState, rootGetters) => {
      const { 'AppointmentToken/getterAppointmentToken': appointmentToken } = rootGetters;
      return appointmentToken;
    },
    appointmentTokenError: (_state, _getters, _rootState, rootGetters) => {
      const { 'AppointmentToken/getterAppointmentTokenError': appointmentTokenError } = rootGetters;
      return appointmentTokenError;
    },
    affiliateResults: (_state, _getters, _rootState, rootGetters) => {
      const { 'AppointmentToken/affiliate/getterAffiliateHasResult': affiliateResults } = rootGetters;
      return affiliateResults;
    },
  },
  actions: {
    createAppointmentToken({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'AppointmentToken/generate/create',
        context: { payload, httpQuery },
        config: {
          start: false,
          end: true,
          endContent: 'Appointment token generate successfully',
          error: 'Unable to generate appointment token',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    getAppointmentToken({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'AppointmentToken/get',
        context: { payload, httpQuery },
        config: {
          start: false,
          end: false,
          error: 'Unable to fetch appointment token',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    resetAppointmentToken({ dispatch }) {
      const action = {
        dispatch,
        target: 'AppointmentToken/reset',
        context: { },
        config: {
          start: false,
          end: false,
          error: 'Unable to reset appointment token',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    appointmentTokenAffiliate({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'AppointmentToken/affiliate/create',
        context: { payload, httpQuery },
        config: {
          start: false,
          end: false,
          error: 'Unable to reset appointment token',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
  },
};
