<template>
  <section
    :class="[{
      'hide': hide
    }]"
  >
    <div class="is-flex is-align-items-center">
      <b-icon
        icon="circle-check"
        pack="fa-regular"
        size="is-large"
        class="bigtitle ml-6 mt-5 text-color-5"
      />
      <p class="title is-big ml-6 mt-5">Thank you!</p>
    </div>
  </section>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */
import animationMixins from '../$mixins/animation.mixins';

export default {
  name: 'ThankYou',
  mixins: [
    animationMixins,
  ],
  props: {
    user: { type: Object, default: () => {} },
    person: { type: Object, required: true },
    ms: { type: Number, required: true },
  },
  mounted() {
    this.$emit('finished');
  },
};
</script>
