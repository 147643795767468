import ThePricing from './ThePricing';

export default [
  {
    path: '/pricing',
    name: 'Pricing',
    component: ThePricing,
    meta: {
      hasMenu: true,
      viewStoreNavigation: 'ThePricing',
      requireAuth: true,
    },
    children: [],
  },
];
