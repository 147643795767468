<template>
  <div>
    TheSymbol
  </div>
</template>

<script>
export default {
  name: 'TheSymbol',
};
</script>
