<template>
  <section>
    ProjectMembers
  </section>
</template>

<script>
export default {
  name: 'ProjectMembers',
};
</script>
