<template>
  <div class="content is-flex is-align-items-center">
    <div
      v-if="img"
      :class="['profile-picture item size', {
        'is-8x8': size === 8,
        'is-16x16': size === 16,
        'is-18x18': size === 18,
        'is-24x24': size === 24,
        'is-32x32': size === 32,
        'is-40x40': size === 40,
        'is-48x48': size === 48,
        'is-64x64': size === 64,
        'is-96x96': size === 96,
        'is-128x128': size === 128,
      }]"
      :style="`background:url('${img}');`"
      @click="$emit('click')"
    />
    <InitialsPicture
      v-else
      :name="name"
      class="item"
      :size="size"
      @click="$emit('click')"
    />
    <div class="ml-5">
      <p
        class="subtitle is-big cursor-pointer p-0 m-0"
        @click="$emit('click')"
      >
        {{ name }}
      </p>
      <p class="disclamer is-small has-text-secondary m-0">{{ location }}</p>
      <p class="subtext is-small has-text-secondary">{{ jobTitle }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PersonCI',
  props: {
    img: { type: String, default: '' },
    name: { type: String, default: '' },
    location: { type: String, default: '' },
    jobTitle: { type: String, default: '' },
    size: { type: Number, default: 64 },
  },
};
</script>
