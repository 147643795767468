/* eslint-disable import/no-extraneous-dependencies */
import { navySeal } from '@jack/provider';

import accountDashboardView from './accountDashboardView.store';
import features from './Feature';
import linkClient from './Link/Client';
import unlinkClient from './Unlink/Client';

export default {
  namespaced: true,
  state: {
    account: null,
  },
  mutations: {
    SET_ACCOUNT(state, newData) { state.account = newData; },
  },
  getters: {
    getterAccount: (state) => state.account,
  },
  actions: {
    get({ commit }, { payload, httpQuery }) {
      return navySeal.apiWsDivision.account.get({ payload, httpQuery })
        .then((account) => {
          commit('SET_ACCOUNT', account);
        });
    },
  },
  modules: {
    accountDashboardView,
    features,
    linkClient,
    unlinkClient,
  },
};
