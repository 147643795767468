<template>
  <section class="form-add kanban">
    <div class="content-form">
      <h1 class="title is-small">
        Create new kanban
      </h1>

      <form @submit.prevent="createKanban()">
        <template v-for="input in inputs">
          <b-field
            v-if="input.key !== 'vue-tel-input'"
            :key="input.key"
            :label="`${input.label}${input.required ? ' *' : ''}`"
            class="bg-solitude"
          >
            <component
              :is="input.cmp"
              v-model="kanban[input.key]"
              :required="input.required"
              :type="input.type"
            />
          </b-field>
        </template>

        <br>

        <b-button
          native-type="submit"
          type="is-primary"
          icon-pack="fa"
          icon-left="check"
          expanded
          :disabled="disableButton"
        />
      </form>
    </div>
  </section>
</template>

<script>
export default {
  name: 'KanbanForm',
  props: {
    userUid: { type: Number, required: true },
  },
  data() {
    return {
      kanban: {
        label: null,
        description: null,
      },
    };
  },
  computed: {
    currentPid() { return this.$currentParam('pid'); },
    inputs() {
      return {
        label: {
          label: 'Label', key: 'label', cmp: 'b-input', required: true, type: 'text',
        },
        description: {
          label: 'Description', key: 'description', cmp: 'b-input', required: false, type: 'textarea',
        },
      };
    },
    disableButton() {
      if (!this.kanban.label) return true;
      return false;
    },
  },
  methods: {
    createKanban() {
      this.$emit('createKanban', {
        ...this.kanban,
        createdAt: this.$moment().format(),
        endedAt: null,
        userUid: this.userUid,
        pid: this.currentPid,
      });
    },
  },
};
</script>
