<template>
  <section>
    <div class="multibar">
      <h3 class="start title is-small">
        Is this appointment related to a client?
      </h3>
    </div>

    <br>

    <b-field>
      <b-switch
        v-model="client"
        @input="fetchClientList(client)"
      >
        Link a client
      </b-switch>
    </b-field>

    <template v-if="client">
      <br>

      <div class="multibar">
        <div class="buttons end">
          <b-button
            tag="router-link"
            :to="toNewClient"
            label="Create a new client"
            type="is-ghost"
          />
        </div>
      </div>
      <template v-if="clientSelected">
        <div>
          <h4 class="start title is-small">Client selected</h4>

          <br>

          <Table
            v-if="Object.values(clientSelected)"
            :data="clientSelected"
            :loading="loading"
            :columns="Object.values(columns)"
            :total="total"
            :page="page"
            :per-page.sync="perPage"
            :sort-field="sortField"
            :sort-order="sortOrder"
            :default-sort-order="defaultSortOrder"
            :selected.sync="rowSelected"
            :checked-rows.sync="checkedRows"
            :checkable="false"
            :sticky-header="false"
            detail-key="cid"
          />
        </div>
      </template>

      <br>

      <div class="jackTableAddProject">
        <Table
          v-if="myClientList"
          :data="myClientList"
          :loading="loading"
          :columns="Object.values(columnsClientsFetched)"
          :total="total"
          :page="page"
          :per-page.sync="perPage"
          :sort-field="sortField"
          :sort-order="sortOrder"
          :default-sort-order="defaultSortOrder"
          :selected.sync="rowSelected"
          :checked-rows.sync="checkedRows"
          :checkable="false"
          :sticky-header="false"
          detail-key="cid"
          @selectClient="selectClient($event)"
        />
      </div>
    </template>
  </section>
</template>

<script>
import clientSelectColumns from '../../../$mixins/clientSelectTable.columns.mixins';

export default {
  name: 'JackSidePanelAddAppointmentLinkForm',
  mixins: [clientSelectColumns],
  model: {
    prop: 'newAppointment',
  },
  props: {
    selected: { type: Object, default: () => {} },
    newAppointment: { type: Object, default: () => {} },
    myClientList: { type: Array, required: true },
  },
  data() {
    return {
      client: false,
      project: false,
      loading: false,
      total: 0,
      page: 0,
      perPage: 20,
      sortField: 'cid',
      sortOrder: 'asc',
      defaultSortOrder: 'asc',
      rowSelected: {},
      checkedRows: [],
      inputSearch: '',
      search: '',
      clientSelected: null,
      collapses: [
        { title: 'Link a client' },
        { title: 'Link a project' },
      ],
      open: true,
    };
  },
  computed: {
    localNewAppointment: {
      get() { return this.newAppointment; },
      set(newValue) { this.$emit('update:newAppointment', newValue); },
    },
    toNewClient() {
      return {
        path: '/home/calendar/sidePanel/addClient',
        query: this.$route.query,
      };
    },
  },
  methods: {
    selectClient({ socialList, projectList, ...row }) {
      this.localNewAppointment.details.client = row;
      this.clientSelected = [row];
    },
    fetchClientList(client) {
      this.$emit('fetchClientList', client);
      if (!client) this.clientSelected = null;
    },
  },
};
</script>
