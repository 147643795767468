<template>
  <section>
    <ClientHeader
      :background-url="company.details.banner || ''"
      :image-url="company.details.logo || ''"
      :name="company.details.name"
    />
    <br>

    <div class="dashboard mt-5">
      <div class="content">
        <div class="header-title start">
          <h1 class="title is-big">
            {{ company.details.name }}
          </h1>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ClientCompany',
  props: {
    company: { type: Object, required: true },
    contactsCompany: { type: Array, default: () => [] },
    options: { type: Array, required: true },
  },
};
</script>
