<template>
  <!-- eslint-disable max-len -->
  <article
    class="w-100 is-flex is-align-items-center is-justify-content-space-between mb-5"
  >
    <div>
      <p class="subtitle is-medium">
        {{ template.subject }}
      </p>
      <template v-if="content && content.length">
        <template v-for="(c, i) in content">
          <p
            v-if="i <= 2"
            :key="`#-${i}-${c}`"
            class="text is-small has-text-secondary"
          >
            {{ c }}
          </p>
        </template>
      </template>
    </div>
    <div class="buttons">
      <b-button
        type="is-tertiary"
        icon-pack="fa"
        icon-left="envelope"
        tag="a"
        :href="`mailto:${mail}?subject=${template.subject}&body=${!content.length ? '' : content.join('%0D%0A')}`"
      />
    </div>
  </article>
</template>

<script>
export default {
  name: 'TemplateCard',
  props: {
    template: { type: Object, required: true },
    mail: { type: String, required: true },
  },
  computed: {
    content() {
      if (!this.template.content) return [];
      return this.template.content.split('\n');
    },
  },
};
</script>
