/* eslint-disable import/no-extraneous-dependencies */
import { navySeal } from '@jack/provider';
import { isActionAllowed } from '@jack/dataStores/$utils/helpers';

import favorite from './favorite.store';
import employee from './employee.store';
import tag from './tag.store';
import bulkInsertCompanies from './bulkInsertCompanies.store';
import bulkInsertContacts from './bulkInsertContacts.store';

export default {
  namespaced: true,
  state: {
    companyList: {},
    company: null,
    searchCompany: {},
  },
  mutations: {
    SET_COMPANY_LIST(state, newData) { state.companyList = newData; },
    SET_COMPANY(state, newData) { state.company = newData; },
    UPDATE_COMPANY_IN_COMPANY_LIST(state, newData) {
      state.companyList[newData.cid] = { ...state.companyList[newData.cid], ...newData };
    },
    SET_SEARCH_COMPANY_LIST(state, newData) { state.searchCompany = newData; },
  },
  getters: {
    getterCompanyList: (state) => state.companyList,
    getterCompany: (state) => state.company,
    getterSearchCompany: (state) => state.searchCompany,
  },
  actions: {
    report({ commit, rootGetters, dispatch }, { payload = {}, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'COMPANY.REPORT', dispatch)) return undefined;

      return navySeal.apiWsMi.company.report({ payload, httpQuery })
        .then((companies) => {
          const companyList = companies.reduce((acc, { cid, details, ...company }) => {
            acc[cid] = { cid, ...details, ...company };
            return acc;
          }, {});

          commit('SET_COMPANY_LIST', companyList);
        });
    },
    search({ commit, rootGetters, dispatch }, { payload = {}, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'COMPANY.REPORT', dispatch)) return undefined;

      return navySeal.apiWsMi.company.report({ payload, httpQuery })
        .then((companies) => {
          const companyList = companies.reduce((acc, { cid, details, ...company }) => {
            acc[cid] = { cid, ...details, ...company };
            return acc;
          }, {});

          commit('SET_SEARCH_COMPANY_LIST', companyList);
        });
    },
    get({ commit, rootGetters, dispatch }, { payload = {}, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'COMPANY.GET', dispatch)) return undefined;

      return navySeal.apiWsMi.company.get({ payload, httpQuery })
        .then((company) => {
          commit('SET_COMPANY', company);
          commit('UPDATE_COMPANY_IN_COMPANY_LIST', company);
          commit('ContactHistory/SET_CONTACT_HISTORY_LIST', { entite: 'company', newData: company.histories }, { root: true });
          commit('Notes/SET_NOTES_LIST', { entite: 'company', newData: company.notes }, { root: true });
        });
    },
    patch({
      commit, getters, rootGetters, dispatch,
    }, { payload = {}, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'COMPANY.GET', dispatch)) return undefined;

      return navySeal.apiWsMi.company.patch({ payload, httpQuery })
        .then((details) => {
          const { company: _company } = getters;
          commit('SET_COMPANY', { ..._company, details: { ...details } });
        });
    },
    update({ commit, rootGetters, dispatch }, { payload = {}, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'COMPANY.GET', dispatch)) return undefined;

      return navySeal.apiWsMi.company.update({ payload, httpQuery })
        .then((company) => {
          const { details, ...rest } = company;
          commit('SET_COMPANY', company);
          commit('UPDATE_COMPANY_IN_COMPANY_LIST', { ...details, ...rest });
        });
    },
  },
  modules: {
    favorite,
    employee,
    tag,
    bulkInsertCompanies,
    bulkInsertContacts,
  },
};
