/* eslint-disable object-curly-newline */
/* eslint-disable import/prefer-default-export */
import { builder } from '../engine';

const target = 'apiWsMi';

const company = {
  report: ({ payload, httpQuery }) => builder({ url: '/company', method: 'REPORT', payload, httpQuery, target }),
  get: ({ payload, httpQuery }) => builder({ url: `/company/${payload.cid}`, method: 'GET', payload, httpQuery, target }),
  update: ({ payload, httpQuery }) => builder({ url: `/company/${payload.cid}`, method: 'PUT', payload, httpQuery, target }),
  patch: ({ payload, httpQuery }) => builder({ url: `/company/${payload.cid}`, method: 'PATCH', payload, httpQuery, target }),
  bulkInsertCompanies: {
    post: ({ payload, httpQuery }) => builder({ url: '/company/bulkInsertCompanies', method: 'POST', payload, httpQuery, target }),
  },
  favorite: {
    post: ({ payload, httpQuery }) => builder({ url: `/company/${payload.cid}/favorite`, method: 'POST', payload, httpQuery, target }),
    delete: ({ payload, httpQuery }) => builder({ url: `/company/${payload.cid}/favorite`, method: 'DELETE', payload, httpQuery, target }),
  },
  employee: {
    post: ({ payload, httpQuery }) => builder({ url: `/company/${payload.cid}/employee`, method: 'POST', payload, httpQuery, target }),
    delete: ({ payload, httpQuery }) => builder({ url: `/company/${payload.cid}/employee/${payload.pid}`, method: 'POST', payload, httpQuery, target }),
  },
  bulkInsertContacts: {
    post: ({ payload, httpQuery }) => builder({ url: `/company/${payload.cid}/bulkInsertContacts`, method: 'POST', payload, httpQuery, target }),
  },
  tag: {
    post: ({ payload, httpQuery }) => builder({ url: `/company/${payload.cid}/tag/${payload.tid}`, method: 'POST', payload, httpQuery, target }),
    delete: ({ payload, httpQuery }) => builder({ url: `/company/${payload.cid}/tag/${payload.tid}`, method: 'DELETE', payload, httpQuery, target }),
  },
};

const contactHistory = {
  report: ({ payload, httpQuery }) => builder({ url: '/contactHistory', method: 'report', payload, httpQuery, target }),
  post: ({ payload, httpQuery }) => builder({ url: '/contactHistory', method: 'POST', payload, httpQuery, target }),
  delete: ({ payload, httpQuery }) => builder({ url: `/contactHistory/${payload.chid}`, method: 'DELETE', payload, httpQuery, target }),
};

const job = {
  report: ({ payload, httpQuery }) => builder({ url: '/job', method: 'REPORT', payload, httpQuery, target }),
  get: ({ payload, httpQuery }) => builder({ url: `/job/${payload.jid}`, method: 'GET', payload, httpQuery, target }),
  patch: ({ payload, httpQuery }) => builder({ url: `/job/${payload.jid}`, method: 'PATCH', payload, httpQuery, target }),
  bulkInsertJobs: {
    post: ({ payload, httpQuery }) => builder({ url: '/job/bulkInsertJobs', method: 'POST', payload, httpQuery, target }),
  },
};

const notes = {
  report: ({ payload, httpQuery }) => builder({ url: '/notes', method: 'report', payload, httpQuery, target }),
  post: ({ payload, httpQuery }) => builder({ url: '/notes', method: 'POST', payload, httpQuery, target }),
  delete: ({ payload, httpQuery }) => builder({ url: `/notes/${payload.nid}`, method: 'DELETE', payload, httpQuery, target }),
};

const person = {
  report: ({ payload, httpQuery }) => builder({ url: '/person', method: 'REPORT', payload, httpQuery, target }),
  get: ({ payload, httpQuery }) => builder({ url: `/person/${payload.pid}`, method: 'GET', payload, httpQuery, target }),
  post: ({ payload, httpQuery }) => builder({ url: '/person/', method: 'POST', payload, httpQuery, target }),
  update: ({ payload, httpQuery }) => builder({ url: `/person/${payload.pid}`, method: 'PUT', payload, httpQuery, target }),
  patch: ({ payload, httpQuery }) => builder({ url: `/person/${payload.pid}`, method: 'PATCH', payload, httpQuery, target }),
  delete: ({ payload, httpQuery }) => builder({ url: `/person/${payload.pid}`, method: 'DELETE', payload, httpQuery, target }),
  bulkInsertPersons: {
    post: ({ payload, httpQuery }) => builder({ url: '/person/bulkInsertPersons', method: 'POST', payload, httpQuery, target }),
  },
  tag: {
    post: ({ payload, httpQuery }) => builder({ url: `/person/${payload.pid}/tag/${payload.tid}`, method: 'POST', payload, httpQuery, target }),
    delete: ({ payload, httpQuery }) => builder({ url: `/person/${payload.pid}/tag/${payload.tid}`, method: 'DELETE', payload, httpQuery, target }),
  },
};

const phantomBuster = {
  agents: {
    report: ({ payload, httpQuery }) => builder({ url: '/phantomBuster/agents/', method: 'REPORT', payload, httpQuery, target }),
    fetch: ({ payload, httpQuery }) => builder({ url: '/phantomBuster/agents/fetch', method: 'GET', payload, httpQuery, target }),
    postFetchAndInsert: ({ payload, httpQuery }) => builder({ url: '/phantomBuster/agents/fetch-and-insert', method: 'POST', payload, httpQuery, target }),
    postFetchAllAndInsert: ({ payload, httpQuery }) => builder({ url: '/phantomBuster/agents/fetch-all-and-insert', method: 'POST', payload, httpQuery, target }),
    launch: ({ payload, httpQuery }) => builder({ url: `/phantomBuster/agents/launch-agent/${payload.pbaid}`, method: 'POST', payload, httpQuery, target }),
  },
  containers: {
    report: ({ payload, httpQuery }) => builder({ url: '/phantomBuster/containers', method: 'REPORT', payload, httpQuery, target }),
    get: ({ payload, httpQuery }) => builder({ url: `/phantomBuster/containers/${payload.pbcid}`, method: 'GET', payload, httpQuery, target }),
    fetchAllAndInsert: ({ payload, httpQuery }) => builder({ url: '/phantomBuster/containers/fetch-all-and-insert', method: 'POST', payload, httpQuery, target }),
    fetchAndUpdate: ({ payload, httpQuery }) => builder({ url: `/phantomBuster/containers/fetch-and-update/${payload.pbcid}`, method: 'PATCH', payload, httpQuery, target }),
    fetchResult: ({ payload, httpQuery }) => builder({ url: `/phantomBuster/containers/fetch-result/${payload.pbcid}`, method: 'PATCH', payload, httpQuery, target }),
    fetchOutput: ({ payload, httpQuery }) => builder({ url: `/phantomBuster/containers/fetch-output/${payload.pbcid}`, method: 'PATCH', payload, httpQuery, target }),
  },
  token: {
    post: ({ payload, httpQuery }) => builder({ url: '/phantomBuster/token/', method: 'POST', payload, httpQuery, target }),
    patch: ({ payload, httpQuery }) => builder({ url: `/phantomBuster/token/${payload.pbtid}`, method: 'PATCH', payload, httpQuery, target }),
    delete: ({ payload, httpQuery }) => builder({ url: `/phantomBuster/token/${payload.pbtid}`, method: 'DELETE', payload, httpQuery, target }),
  },
  organization: {
    fetchAndInsert: ({ payload, httpQuery }) => builder({ url: '/phantomBuster/organization/fetch-and-insert', method: 'POST', payload, httpQuery, target }),
  },
};

export {
  company,
  contactHistory,
  job,
  notes,
  person,
  phantomBuster,
};
