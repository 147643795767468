export default {
  data() {
    return {
      columns: {
        pid: {
          col: 'pid', label: 'pid', visible: false, isSortable: false, cmp: 'flat',
        },
        clientCid: {
          col: 'clientCid', label: 'Client ID', visible: false, isSortable: false, cmp: 'flat',
        },
        action: {
          col: 'action', label: 'Actions', visible: true, isSortable: false, event: 'openDetails', buttonType: 'tertiary', icon: 'eye', type: 'is-ghost', width: 20, centered: true,
        },
        isOpen: {
          col: 'isOpen', label: 'Project completed', visible: true, isSortable: true, cmp: 'isOpen', width: 160, centered: true,
        },
        label: {
          col: 'label', label: 'Label', visible: true, isSortable: true, cmp: 'flat',
        },
        price: {
          col: 'price', label: 'Price', visible: true, isSortable: true, cmp: 'symbol', symbol: '€', centered: true,
        },
        createdAt: {
          col: 'createdAt', label: 'Created at', visible: true, isSortable: true, cmp: 'date',
        },
      },
    };
  },
};
