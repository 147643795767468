import { use, registerComponent } from '../../$utils/plugins';

import SidePanelAgentConnectToLinkedin from './SidePanelAgentConnectToLinkedin';
import SidePanelAgentSearch from './SidePanelAgentSearch';
import SidePanelAgentResult from './SidePanelAgentResult';
import ContainerCard from './ContainerCard';

// Company
import CompanyInformations from './CompanyInformations';
import CompanyEmployees from './CompanyEmployees';
import CompanyCommunicationHistory from './CompanyCommunicationHistory';
import CompanyNewClient from './CompanyNewClient';
import CompanyNotes from './CompanyNotes';
import CompanyJobsAlert from './CompanyJobsAlert';
import CompanyAppointments from './CompanyAppointments';
import CompanyProjects from './CompanyProjects';
import CompanyPayments from './CompanyPayments';
import CompanySettings from './CompanySettings';

// Person
import PersonAppointments from './PersonAppointments';
import PersonCommunicationHistory from './PersonCommunicationHistory';
import PersonInformations from './PersonInformations';
import PersonNewClient from './PersonNewClient';
import PersonNotes from './PersonNotes';
import PersonPayments from './PersonPayments';
import PersonProjects from './PersonProjects';
import PersonSettings from './PersonSettings';
import PhantomSteps from './PhantomSteps';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, SidePanelAgentConnectToLinkedin);
    registerComponent(Vue, SidePanelAgentSearch);
    registerComponent(Vue, SidePanelAgentResult);
    registerComponent(Vue, ContainerCard);

    // Company
    registerComponent(Vue, CompanyInformations);
    registerComponent(Vue, CompanyEmployees);
    registerComponent(Vue, CompanyCommunicationHistory);
    registerComponent(Vue, CompanyNewClient);
    registerComponent(Vue, CompanyNotes);
    registerComponent(Vue, CompanyJobsAlert);
    registerComponent(Vue, CompanyAppointments);
    registerComponent(Vue, CompanyProjects);
    registerComponent(Vue, CompanyPayments);
    registerComponent(Vue, CompanySettings);

    // Person
    registerComponent(Vue, PersonAppointments);
    registerComponent(Vue, PersonCommunicationHistory);
    registerComponent(Vue, PersonInformations);
    registerComponent(Vue, PersonNewClient);
    registerComponent(Vue, PersonNotes);
    registerComponent(Vue, PersonPayments);
    registerComponent(Vue, PersonProjects);
    registerComponent(Vue, PersonSettings);
    registerComponent(Vue, PhantomSteps);
  },
};

use(Plugin);

export default Plugin;

export {
  SidePanelAgentConnectToLinkedin,
  SidePanelAgentSearch,
  SidePanelAgentResult,
  ContainerCard,

  // Company
  CompanyInformations,
  CompanyEmployees,
  CompanyCommunicationHistory,
  CompanyNewClient,
  CompanyNotes,
  CompanyJobsAlert,
  CompanyAppointments,
  CompanyProjects,
  CompanyPayments,
  CompanySettings,

  // Person
  PersonAppointments,
  PersonCommunicationHistory,
  PersonInformations,
  PersonNewClient,
  PersonNotes,
  PersonPayments,
  PersonProjects,
  PersonSettings,
  PhantomSteps,
};
