<template>
  <ContactHistory
    :entite="company"
    :history-sorted="historySorted"
    :person-list="company.employees"
    @addContactHistory="$emit('addContactHistory', $event)"
    @deleteContactHistory="$emit('deleteContactHistory', $event)"
  />
</template>

<script>
/* eslint-disable max-len */
import moment from 'moment';

export default {
  name: 'CompanyCommunicationHistory',
  props: {
    company: { type: Object, required: true },
    companyContactHistoryList: { type: Object, required: true },
  },
  computed: {
    historySorted() {
      if (!this.companyContactHistoryList || !Object.values(this.companyContactHistoryList).length) return null;
      return Object.values(this.companyContactHistoryList)
        .sort((a, b) => moment(b.createdAt).unix() - moment(a.createdAt).unix());
    },
  },
};
</script>
