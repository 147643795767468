/* eslint-disable import/no-extraneous-dependencies */
import { navySeal } from '@jack/provider';
import { isActionAllowed } from '@jack/dataStores/$utils/helpers';

export default {
  namespaced: true,
  state: {
    notes: {
      company: {},
      person: {},
    },
  },
  mutations: {
    SET_NOTES_LIST(state, { entite, newData }) {
      state.notes[entite.toLowerCase()] = newData.reduce((acc, { nid, ...rest }) => {
        acc[nid] = { nid, ...rest };
        return acc;
      }, {});
    },
    UPDATE_NOTES_LIST(state, { entite, newData }) {
      state.notes[entite.toLowerCase()] = {
        ...state.notes[entite.toLowerCase()],
        [newData.nid]: { ...newData },
      };
    },
    DELETE_NOTES_IN_LIST(state, { entite, newData }) {
      state.notes[entite.toLowerCase()] = Object.values(state.notes[entite.toLowerCase()])
        .reduce((acc, note) => {
          if (newData.nid === note.nid) return acc;
          acc[note.nid] = { ...note };
          return acc;
        }, {});
    },
  },
  getters: {
    gettersCompanyNotesList: (state) => state.notes.company,
    gettersPersonNotesList: (state) => state.notes.person,
  },
  actions: {
    report({ commit, rootGetters, dispatch }, { payload = {}, httpQuery = {}, options = {} }) {
      if (!isActionAllowed(rootGetters, 'NOTES.REPORT', dispatch)) return undefined;
      const { entite } = options;

      return navySeal.apiWsMi.notes.report({ payload, httpQuery })
        .then((companyNotes) => {
          commit('SET_NOTES_LIST', { entite, newData: companyNotes });
        });
    },
    create({ commit, rootGetters, dispatch }, { payload = {}, httpQuery = {}, options = {} }) {
      const { entite } = options;
      if (!isActionAllowed(rootGetters, 'NOTES.CREATE', dispatch)) return undefined;

      return navySeal.apiWsMi.notes.post({ payload, httpQuery })
        .then((companyNotes) => { commit('UPDATE_NOTES_LIST', { entite, newData: companyNotes }); });
    },
    delete({ commit, rootGetters, dispatch }, { payload = {}, httpQuery = {}, options = {} }) {
      if (!isActionAllowed(rootGetters, 'NOTES.DELETE', dispatch)) return undefined;
      const { entite } = options;

      return navySeal.apiWsMi.notes.delete({ payload, httpQuery })
        .then(() => { commit('DELETE_NOTES_IN_LIST', { entite, newData: { nid: payload.nid } }); });
    },
  },
};
