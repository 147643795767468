<template>
  <section class="as-container">
    <template v-if="appointmentTokens && appointmentTokens.length">
      <AppointmentTokenInfo
        v-for="at in appointmentTokens"
        :key="`appointmentToken-${at.atid}`"
        :appointment-token="at"
        :entite="entite"
        @deleteAppointmentToken="$emit('deleteAppointmentToken', $event)"
      />
    </template>
    <hr>
    <template v-if="appointmentsList && appointmentsList.length">
      <template v-for="a in appointmentsList">
        <AppointmentInfo
          v-if="!a.over"
          :key="`appointment-${a.aid}`"
          :appointment="a"
          @changeStateAppointment="$emit('changeStateAppointment', $event)"
          @deleteAppointment="$emit('deleteAppointment', $event)"
        />
      </template>
    </template>
    <NoData
      v-else
      :title="$_('You do not have any recorded appointments.')"
      :content="$_('Save your next appointments and find them in the calendar')"
      class="mt-5"
    />
  </section>
</template>

<script>
export default {
  name: 'AppointmentList',
  props: {
    entite: { type: Object, required: true },
    appointmentsList: { type: Array, default: () => [] },
    appointmentTokens: { type: Array, default: () => [] },
  },
};
</script>
