<template>
  <section>
    <b-notification
      v-model="notification"
      :class="['jackHelper mb-5', {
        'warning': contentType === 'warning',
        'success': contentType === 'success',
        'danger': contentType === 'danger',
        'info': contentType === 'info',
      }]"
      :closable="false"
      :type="type"
    >
      <div class="content multibar">
        <b-icon
          v-if="iconLeft"
          :pack="packLeft"
          :icon="iconLeft"
          :class="[{
            'text-color-1': color === '1',
            'text-color-2': color === '2',
            'text-color-3': color === '3',
            'text-color-4': color === '4',
            'text-color-5': color === '5',
            'text-color-6': color === '6',
            'text-color-7': color === '7',
            'text-color-8': color === '8',
            'text-color-9': color === '9',
            'text-color-10': color === '10',
            'text-color-11': color === '11',
            'text-color2-1': color === '12',
          }]"
          size="is-large"
        />
        <b-taglist
          v-if="tag"
        >
          <b-tag :type="tagType">
            {{ tagLabel }}
          </b-tag>
        </b-taglist>

        <p
          v-if="Array.isArray(content)"
          class="is-flex-direction-column is-align-items-flex-start ml-3"
        >
          <template>
            <span
              v-for="helper in content"
              :key="helper"
            >
              <p
                :class="['text is-small', {
                  'has-text-primary': contentType === 'primary',
                  'has-text-secondary': contentType === 'secondary',
                  'has-text-danger': contentType === 'danger',
                  'has-text-success': contentType === 'success',
                  'has-text-warning': contentType === 'warning',
                  'text-color-1': color === '1',
                  'text-color-2': color === '2',
                  'text-color-3': color === '3',
                  'text-color-4': color === '4',
                  'text-color-5': color === '5',
                  'text-color-6': color === '6',
                  'text-color-7': color === '7',
                  'text-color-8': color === '8',
                  'text-color-9': color === '9',
                  'text-color-10': color === '10',
                  'text-color-11': color === '11',
                  'text-color2-1': color === '12',
                }]"
              >
                {{ helper }}
              </p>
            </span>
          </template>
        </p>
        <p
          v-else
          :class="['text is-small', {
            'has-text-primary': contentType === 'primary',
            'has-text-secondary': contentType === 'secondary',
            'has-text-danger': contentType === 'danger',
            'has-text-success': contentType === 'success',
            'has-text-warning': contentType === 'warning',
            'text-color-1': color === '1',
            'text-color-2': color === '2',
            'text-color-3': color === '3',
            'text-color-4': color === '4',
            'text-color-5': color === '5',
            'text-color-6': color === '6',
            'text-color-7': color === '7',
            'text-color-8': color === '8',
            'text-color-9': color === '9',
            'text-color-10': color === '10',
            'text-color-11': color === '11',
            'text-color2-1': color === '12',
          }]"
        >
          {{ content }}
        </p>
      </div>
    </b-notification>
  </section>
</template>

<script>
export default {
  name: 'Helper',
  model: {
    prop: 'notification',
  },
  props: {
    notification: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'is-secondary',
    },
    tag: {
      type: Boolean,
      default: false,
    },
    tagType: {
      type: String,
      default: 'is-primary',
    },
    tagLabel: {
      type: String,
      default: 'New',
    },
    link: {
      type: Boolean,
      default: false,
    },
    linkLabel: {
      type: String,
      default: 'redirection',
    },
    iconLeft: {
      type: String,
      default: '',
    },
    iconRight: {
      type: String,
      default: '',
    },
    packLeft: {
      type: String,
      default: 'fa',
    },
    packRight: {
      type: String,
      default: 'fa',
    },
    content: {
      type: [String, Array],
      default: 'No content !',
    },
    close: {
      type: Boolean,
      default: false,
    },
    contentType: {
      type: String,
      default: 'secondary',
    },
    color: {
      type: String,
      default: '0',
    },
  },
};
</script>
