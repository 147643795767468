export default {
  methods: {
    resetParameters() {
      this.page = 1;
      this.queries.pagin.offset = 0;
    },
    clearFilters() {
      this.resetParameters();
      Object.entries(this.queries).forEach(([key, value]) => {
        if (key === 'pagin') {
          this.queries[key].limit = 12;
        } else if (key === 'by') {
          this.queries[key] = 'name';
        } else if (typeof value === 'boolean') {
          this.queries[key] = false;
        } else {
          this.queries[key] = null;
        }
      });
    },
  },
};
