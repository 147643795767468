export default {
  data() {
    return {
      columns: {
        cid: {
          col: 'cid', label: 'cid', visible: false, isSortable: false, cmp: 'flat',
        },
        firstName: {
          col: 'firstName', label: 'First name', visible: true, isSortable: false, cmp: 'flat',
        },
        lastName: {
          col: 'lastName', label: 'Last name', visible: true, isSortable: false, cmp: 'flat',
        },
        number: {
          col: 'number', label: 'Number', visible: true, isSortable: false, cmp: 'flat',
        },
        createdAt: {
          col: 'createdAt', label: 'Created at', visible: true, isSortable: false, cmp: 'date',
        },
      },
      columnsClientsFetched: {
        cid: {
          col: 'cid', label: 'cid', visible: false, isSortable: false, cmp: 'flat',
        },
        action: {
          col: 'action', label: 'Action', visible: true, isSortable: true, event: 'selectClient', buttonType: 'tertiary', type: 'is-ghost', icon: 'user-check', sticky: true,
        },
        firstName: {
          col: 'firstName', label: 'First name', visible: true, isSortable: false, cmp: 'flat',
        },
        lastName: {
          col: 'lastName', label: 'Last name', visible: true, isSortable: false, cmp: 'flat',
        },
        number: {
          col: 'number', label: 'Number', visible: true, isSortable: false, cmp: 'flat',
        },
        'ig.username': {
          col: 'ig.username', base: 'INSTAGRAM', nestedKey1: 'username', label: 'Username', visible: true, isSortable: false, cmp: 'nested',
        },
        'tk.username': {
          col: 'tk.username', base: 'TIKTOK', nestedKey1: 'username', label: 'Username', visible: true, isSortable: false, cmp: 'nested',
        },
        'fb.username': {
          col: 'fb.username', base: 'FACEBOOK', nestedKey1: 'username', label: 'Username', visible: true, isSortable: false, cmp: 'nested',
        },
        'sc.username': {
          col: 'sc.username', base: 'SNAPCHAT', nestedKey1: 'username', label: 'Username', visible: true, isSortable: false, cmp: 'nested',
        },
        'tw.username': {
          col: 'tw.username', base: 'TWITTER', nestedKey1: 'username', label: 'Username', visible: true, isSortable: false, cmp: 'nested',
        },
        'li.username': {
          col: 'li.username', base: 'LINKEDIN', nestedKey1: 'username', label: 'Username', visible: true, isSortable: false, cmp: 'nested',
        },
        createdAt: {
          col: 'createdAt', label: 'Created at', visible: true, isSortable: false, cmp: 'date',
        },
      },
    };
  },
};
