<template>
  <section>
    <div class="multibar">
      <h3 class="start title is-small">
        Search a client
      </h3>
    </div>

    <br>
    <DevDetails :cnt="{name: 'newPayment', obj: newPayment}" />

    <b-field>
      <b-input
        v-model="inputSearch"
        placeholder="Search..."
        type="search"
        icon-pack="fas"
        icon="search"
      />
    </b-field>

    <br>

    <div v-if="clientSelected">
      <div class="multibar">
        <h4 class="start title is-small">Client selected</h4>
        <div class="buttons end">
          <b-button
            label="Next"
            type="is-ghost"
            @click="$emit('fetchProject')"
          />
        </div>
      </div>

      <br>

      <Table
        v-if="Object.values(clientSelected)"
        :data="clientSelected"
        :loading="loading"
        :columns="Object.values(columns)"
        :total="total"
        :page="page"
        :per-page.sync="perPage"
        :sort-field="sortField"
        :sort-order="sortOrder"
        :default-sort-order="defaultSortOrder"
        :selected.sync="rowSelected"
        :checked-rows.sync="checkedRows"
        :checkable="false"
        :sticky-header="false"
        detail-key="cid"
      />
    </div>

    <br>

    <div class="jackTableAddProject">
      <Table
        v-if="clientFetchedFormated.length"
        :data="clientFetchedFormated"
        :loading="loading"
        :columns="Object.values(columnsClientsFetched)"
        :total="total"
        :page="page"
        :per-page.sync="perPage"
        :sort-field="sortField"
        :sort-order="sortOrder"
        :default-sort-order="defaultSortOrder"
        :selected.sync="rowSelected"
        :checked-rows.sync="checkedRows"
        :checkable="false"
        detail-key="cid"
        @selectClient="selectClient($event)"
      />
      <template v-else>
        <NoData
          title="No client were found"
          action
          :buttons-action="buttonsAction"
          @reset="inputSearch = ''"
        />
        <br>

        <div class="multibar">
          <b-button
            label="Create a new client"
            class="end"
            tag="router-link"
            :to="{
              path: '/home/sidePanel/addClient',
              query: $route.query
            }"
            type="is-primary"
            icon-pack="fas"
            icon-left="user-plus"
          />
        </div>
      </template>
    </div>
  </section>
</template>

<script>
/* eslint-disable max-len */
/* eslint-disable-next-line */
import { debounce } from '@jack/addons/$utils/helpers';
import clientSelectColumns from '../../../$mixins/clientSelectTable.columns.mixins';

export default {
  name: 'JackSidePanelAddPaymentClientForm',
  mixins: [clientSelectColumns],
  model: {
    prop: 'newPayment',
  },
  props: {
    selected: { type: Object, default: () => {} },
    newPayment: { type: Object, required: true },
    clients: { type: Array, required: true },
  },
  data() {
    return {
      loading: false,
      total: 0,
      page: 0,
      perPage: 20,
      sortField: 'cid',
      sortOrder: 'asc',
      defaultSortOrder: 'asc',
      rowSelected: {},
      checkedRows: [],
      inputSearch: '',
      search: '',
      clientSelected: null,
    };
  },
  computed: {
    localNewPayment: {
      get() { return this.newPayment; },
      set(newValue) { this.$emit('update:newPayment', newValue); },
    },
    selectedFormated() {
      if (!Object.values(this.selected).length) return [];
      const { details, ...rest } = this.selected;
      return [
        { ...rest, ...details },
      ];
    },
    clientFetchedFormated() {
      if (!this.clients.length) return [];

      const query = this.clients.reduce((acc, client) => {
        const { details, socialList, ...rest } = client;

        if (this.search) {
          const firstNameFind = details ? !!details.firstName.toLowerCase().includes(this.search.toLowerCase()) : false;
          const lastNameFind = details ? !!details.lastName.toLowerCase().includes(this.search.toLowerCase()) : false;
          const numberFind = details ? !!details.number.includes(this.search) : false;
          const usernameFind = socialList.length ? socialList.find(({ username }) => username.toLowerCase().includes(this.search.toLowerCase())) : false;

          if (firstNameFind || lastNameFind || numberFind || usernameFind) {
            acc.push({ ...rest, ...details, ...socialList.arrayMapper('platformName') });
          }
        } else {
          acc.push({ ...rest, ...details, ...socialList.arrayMapper('platformName') });
        }
        return acc;
      }, []);

      return query;
    },
    buttonsAction() {
      return [
        {
          label: 'Reset filter', type: 'is-primary', event: 'reset', buttonType: 'event',
        },
      ];
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    inputSearch: debounce(function (newVal) {
      this.search = newVal;
    }, 500),
  },
  methods: {
    selectClient(row) {
      this.localNewPayment.client = row;
      this.clientSelected = [row];
    },
  },
};
</script>
