<template>
  <section
    :class="[{
      'hide': hide
    }]"
  >
    <p class="subtitle is-small m-0">
      Please select a date and a time from the list
    </p>

    <b-field
      label="Date"
      class="bg-solitude"
    >
      <b-datetimepicker
        v-model="localDate"
        placeholder="Click to select..."
        position="is-top-right"
        :datepicker="datepicker"
        :timepicker="timepicker"
        :min-date="dates[0]"
        :max-date="dates[1]"
      />
    </b-field>

    <ButtonForm
      :disabled="disabled"
      @next="$emit('next')"
    />
  </section>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */
import animationMixins from '../$mixins/animation.mixins';

export default {
  name: 'DateForm',
  mixins: [animationMixins],
  props: {
    user: { type: Object, default: () => {} },
    appointment: { type: Object, required: true },
    appointments: { type: Array, required: true },
    scAppointment: { type: Object, required: true },
    ms: { type: Number, required: true },
    duration: { type: Number, required: true },
    dates: { type: Array, required: true },
  },
  computed: {
    localDate: {
      get() { return this.appointment.date; },
      set(newValue) { this.$emit('update:date', newValue); },
    },
    day() {
      if (!this.localDate) return null;
      return this.$moment(this.localDate).format('ddd').toLowerCase();
    },
    datepicker() {
      const minDate = this.dates.length ? new Date(this.dates[0]) : new Date();
      if (!this.dates.length) minDate.setDate(1);
      else minDate.setDate(minDate.getDate() - 1);
      const maxDate = this.dates.length ? new Date(this.dates[1]) : '';

      return {
        unselectableDaysOfWeek: Object.values(this.user.settings.availabilities)
          .reduce((acc, el) => {
            if (el.active) return acc;
            acc.push(el.key);
            return acc;
          }, []),
        nearbyMonthDays: false,
        minDate,
        maxDate,
      };
    },
    timepicker() {
      if (!this.day) return {};
      const { day, user: { settings: { availabilities } } } = this;

      return {
        minTime: this.formatDateFunction(availabilities[day].am.start.split(':')),
        maxTime: this.formatDateFunction(availabilities[day].pm.end.split(':')),
        incrementMinutes: 15,
        unselectableTimes: this.unselectedTimes,
      };
    },
    disabled() {
      if (!this.appointment.date) return true;
      if (this.scAppointment.validate(this.appointment).error) return true;
      const date = this.$moment(this.appointment.date);
      const hours = date.format('HH:mm');
      const day = date.format('ddd').toLowerCase();
      const {
        am: { start: amStart, end: amEnd },
        pm: { start: pmStart, end: pmEnd },
      } = this.user.settings.availabilities[day];

      if (
        (hours < amStart || hours > amEnd)
        && (hours < pmStart || hours > pmEnd)
      ) return true;

      return false;
    },
    disponibilities() {
      if (!this.day) return [];
      return Object.values(this.user.settings.availabilities[this.day])
        .reduce((acc, el) => {
          const { start, end } = el;
          const sFormat = this.$moment(start, 'HH:mm');
          const eFormat = this.$moment(end, 'HH:mm');
          const diff = eFormat.diff(sFormat, 'minutes');

          if (!diff) return acc;

          /* eslint-disable-next-line */
          for (let i = 0; i < diff / 15; i++) {
            acc.push(this.$moment(
              this.formatDateFunction(this.$moment(sFormat).add(i * 15, 'minutes').format('HH:mm').split(':')),
            ).format('HH:mm'));
          }
          return acc;
        }, []);
    },
    unavailable() {
      if (!this.localDate) return [];
      return this.appointments.reduce((acc, el) => {
        const { date, duration } = el;
        const dSelected = this.$moment(this.localDate);
        const dFormated = this.$moment(date);

        if (!dSelected.isSame(dFormated, 'day')) return acc;

        acc.push(this.$moment(this.formatDateFunction(dFormated.format('HH:mm').split(':'))).format('HH:mm'));

        /* eslint-disable-next-line */
        for (let i = 1; i < (duration / 15) + (this.duration / 15); i++) {
          acc.push(this.$moment(
            this.formatDateFunction(
              this.$moment(date)
                .subtract(this.duration, 'minutes')
                .add(i * 15, 'minutes')
                .format('HH:mm')
                .split(':'),
            ),
          ).format('HH:mm'));
        }

        return acc;
      }, []);
    },
    unselectedTimes() {
      const _disponibilities = this.disponibilities;
      const _unavailable = this.unavailable;

      return new Array(96)
        .fill()
        .map((_, i) => this.$moment().startOf('day').add(i * 15, 'minutes').format('HH:mm'))
        .reduce((acc, el) => {
          if (_disponibilities.includes(el)) {
            if (_unavailable.includes(el)) {
              acc.push(this.formatDateFunction(el.split(':')));
              return acc;
            }
            return acc;
          }
          acc.push(this.formatDateFunction(el.split(':')));
          return acc;
        }, []);
    },
  },
  methods: {
    formatDateFunction(time) {
      const date = new Date();
      const [hours, minutes] = time;
      date.setHours(hours);
      date.setMinutes(minutes);
      date.setSeconds(0);
      date.setMilliseconds(0);

      return date;
    },
  },
};
</script>
