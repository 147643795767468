<template>
  <div class="jackSidePanel">
    <JackSidePanelHeader
      :path-home="pathHome"
      :previous="goBack"
    />

    <br>

    <section>
      <DevDetails :cnt="{name: 'eventsView', obj: eventsView}" />
      <template v-if="Object.values(eventsView).length">
        <header class="is-flex is-flex-direction-column is-align-items-flex-start">
          <b-tag
            v-if="currentEvent.category"
            type="is-info"
            class="mb-5"
          >
            {{ currentEvent.category }}
          </b-tag>
          <p class="text has-text-secondary m-0">Created at: {{ currentEvent.createdAt }}</p>
          <h2 class="bigtitle is-big m-0">{{ currentEvent.title }}</h2>
          <h3
            v-if="currentEvent.subtitle"
            class="subtitle is-small m-0 has-text-secondary"
          >
            <b-icon
              v-if="currentEvent.icon"
              :icon="currentEvent.icon"
              :pack="currentEvent.pack"
            />
            {{ currentEvent.subtitle }}
          </h3>
        </header>

        <template v-if="currentEvent.content">
          <br>

          <div class="content">
            <p class="text">{{ currentEvent.content }}</p>
          </div>
        </template>

        <template v-if="currentEvent.redirects">
          <br>
          <div class="multibar">
            <div class="buttons end">
              <b-button
                v-for="redirect in currentEvent.redirects"
                :key="redirect.to"
                tag="router-link"
                :to="redirect.to"
                :label="redirect.text"
                :type="redirect.type"
              />
            </div>
          </div>
        </template>
      </template>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TheSidePanelEvent',
  props: {
    pathHome: { type: String, required: true },
  },
  computed: {
    ...mapGetters({
      eventsView: 'TheSidePanel/eventsView',
      currentUid: 'TheSidePanel/currentUid',
    }),
    currentEid() {
      return this.$route.query.eid;
    },
    currentType() {
      return this.$route.query.type;
    },
    currentStart() {
      return this.$route.query.start;
    },
    goBack() {
      return {
        path: '/home/calendar/sidePanel',
        query: { start: this.currentStart },
      };
    },
    currentEvent() {
      if (!this.eventsView) return null;

      const { type, createdAt } = this.eventsView;
      const currentData = this.eventsView[this.currentType];

      let datas = {
        type,
        createdAt: this.$moment(createdAt).format('YYYY-MM-DD'),
      };

      if (currentData) {
        switch (this.currentType) {
          case 'client':
            datas = {
              ...datas,
              title: `${currentData.firstName} ${currentData.lastName}`,
              redirects: [
                {
                  to: `/clients/overview-view/client/${this.eventsView.cid}`, text: 'Open details client', type: 'is-primary',
                },
              ],
            };
            break;
          case 'project':
            datas = {
              ...datas,
              category: `${currentData.price}€`,
              title: currentData.label,
              content: `${currentData.description}`,
              redirects: [
                {
                  to: `/clients/overview-view/client/${this.eventsView.cid}`, text: 'Open details client', type: 'is-ghost',
                },
                {
                  to: `/projects/overview-view/project/${this.eventsView.projectPid}`, text: 'Open details project', type: 'is-primary',
                },
              ],
            };
            break;
          case 'payment':
            datas = {
              ...datas,
              title: `+ ${currentData.deposit}€`,
              subtitle: currentData.description,
              redirects: [
                {
                  to: `/projects/overview-view/project/${this.eventsView.projectPid}`, text: 'Open details project', type: 'is-primary',
                },
              ],
              pack: 'fa',
              icon: 'user-plus',
            };
            break;
          case 'spend':
            datas = {
              ...datas,
              category: currentData.category,
              title: `- ${currentData.deposit}€`,
              subtitle: currentData.categoryLabel,
              content: currentData.label,
              pack: currentData.pack,
              icon: currentData.icon,
            };
            break;
          case 'appointment':
            datas = {
              ...datas,
              category: this.$moment(currentData.createdAt).format('MMMM Do, YYYY | HH:mm'),
              title: currentData.label,
              content: currentData.description,
              redirects: currentData.clientCid
                ? [{ to: `/clients/overview-view/client/${currentData.clientCid}`, text: 'Open details client', type: 'is-primary' }]
                : null,
            };

            break;
          default:
            break;
        }
      }

      return datas;
    },
  },
  mounted() {
    const payload = { uid: this.currentUid, eid: this.currentEid };

    return Promise.all([
      this.getEventsView({ payload }),
    ]);
  },
  methods: {
    ...mapActions({
      getEventsView: 'TheSidePanel/getEventsView',
    }),
  },
};
</script>
