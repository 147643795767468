<template>
  <section
    ref="card"
    class="summary-card"
  >
    <p class="summary-card-hour">
      {{ $moment(event.createdFor).format('HH:mm') }}
    </p>
    <div class="summary-card-timeline">
      <div :class="['timeline-icon', event.event]">
        <b-icon
          :pack="event.pack"
          :icon="event.icon"
        />
      </div>
      <div
        class="timeline bottom"
        :style="`height:${height}px`"
      />
    </div>
    <div class="summary-card-content">
      <template v-if="event.type === 'NEW_CLIENT'">
        <article class="w-100">
          <Contact
            v-if="event.client.persons && !event.client.company"
            :pid="event.client.persons[0].pid"
            :details="event.client.persons[0]"
            :size="64"
          />
          <CompanyEmployeesContact
            v-if="event.client.company"
            :client="event.client"
            :size="64"
          />
        </article>
      </template>
      <template v-if="event.type === 'NEW_PROJECT'">
        <ProjectCard :project="event.project" />
      </template>
      <template v-if="event.type === 'NEW_SPEND'">
        New spend
      </template>
      <template v-if="event.type === 'NEW_PAYMENT'">
        <div class="is-flex is-align-items-center">
          <div class="is-flex is-align-items-center">
            <span class="text-color-5-60">
              {{ currencies[event.payment.currency].name }}
            </span>
            <span class="bigtitle ml-3 no-lineh-bigtitle text-color-5">
              {{ format(Number(event.payment.deposit), 1) }}
            </span>
          </div>
          <div class="pa-content ml-5">
            <router-link
              :to="`/projects/${event.payment.projectPid}/details`"
              class="subtitle is-big"
            >
              {{ event.payment.description }}
            </router-link>
          </div>
        </div>
      </template>
      <template v-if="event.type === 'NEW_APPOINTMENT'">
        <div class="appointment-content">
          <component
            :is="event.appointment.personPid ? 'router-link' : 'p'"
            :to="`/networking/contacts/${event.appointment.personPid}/details`"
            class="text is-big m-0"
          >
            {{ event.appointment.label }}
          </component>
          <p
            v-if="event.appointment"
            class="text has-text-secondary is-small"
          >
            {{ event.appointment.description }}
          </p>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */
import nFormatter from '@jack/addons/$mixins/nFormatter';

export default {
  name: 'JackSidePanelSummaryEventCardItem',
  props: {
    event: { type: Object, required: true },
    path: { type: String, required: true },
  },
  data() {
    return {
      height: 0,
    };
  },
  computed: {
    currencies() {
      return [
        { name: '€', key: 'EUR' },
        { name: '$', key: 'USD' },
        { name: '£', key: 'GBP' },
        { name: '¥', key: 'JPY' },
      ].arrayMapper('key');
    },
  },
  mounted() {
    if (this.$refs && this.$refs.card) {
      this.height = this.$refs.card.clientHeight - 40;
    }
  },
  methods: {
    detailEvent(eventItem) {
      this.$router.push({
        path: `${this.path}/event`,
        query: {
          ...this.$route.query,
          eid: eventItem.eid,
          type: eventItem.event,
        },
      });
    },
    format(nb) {
      return nFormatter(nb, 1);
    },
  },
};
</script>
