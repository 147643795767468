import TheDashboard from './TheDashboard';
import TheDashboardAccountView from './TheDashboardAccountView';
import TheDashboardUserView from './TheDashboardUserView';

export default [
  {
    path: '/dashboard',
    redirect: '/dashboard/user',
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: TheDashboard,
    meta: {
      hasMenu: true,
      viewStoreNavigation: 'TheDashboard',
      requireAuth: true,
    },
    children: [
      {
        path: '/dashboard/user',
        name: 'Dashboard - User',
        meta: {
          hasMenu: true,
          viewStoreNavigation: 'TheDashboard',
          requireAuth: true,
        },
        components: {
          userView: TheDashboardUserView,
        },
      },
      {
        path: '/dashboard/account',
        name: 'Dashboard - Account',
        meta: {
          hasMenu: true,
          viewStoreNavigation: 'TheDashboard',
          requireAuth: true,
        },
        components: {
          accountView: TheDashboardAccountView,
        },
      },
    ],
  },
];
