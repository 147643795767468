/* eslint-disable import/no-extraneous-dependencies */
import { navySeal } from '@jack/provider';
import { isActionAllowed } from '@jack/dataStores/$utils/helpers';

export default {
  namespaced: true,
  state: {
    agentList: {},
    agent: null,
  },
  mutations: {
    SET_PHANTOMBUSTER_AGENT_LIST(state, newData) { state.agentList = newData; },
    SET_PHANTOMBUSTER_AGENT(state, newData) { state.agent = newData; },
  },
  getters: {
    getterAgentList: (state) => state.agentList,
    getterAgent: (state) => state.agent,
  },
  actions: {
    report({ commit, rootGetters, dispatch }, { payload = {}, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'PHANTOM_BUSTER_AGENTS.REPORT', dispatch)) return undefined;

      return navySeal.apiWsMi.phantomBuster.agents.report({ payload, httpQuery })
        .then((agents) => {
          const { agentList, containerList } = agents.reduce((acc, agent) => {
            const { pbaid, hasContainers, ...rest } = agent;
            if (hasContainers) {
              hasContainers.forEach(({ container: { pbcid, ...container } }) => {
                acc.containerList[pbcid] = { ...container, pbcid, pbaid };
              });
            }
            acc.agentList[pbaid] = { pbaid, ...rest };
            return acc;
          }, {
            agentList: {},
            containerList: {},
          });

          commit('SET_PHANTOMBUSTER_AGENT_LIST', agentList);
          commit('PhantomBuster/containers/SET_PHANTOMBUSTER_CONTAINERS_LIST', containerList, { root: true });
        });
    },
    fetch({ commit, rootGetters, dispatch }, { payload = {}, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'PHANTOM_BUSTER_AGENTS.FETCH', dispatch)) return undefined;

      return navySeal.apiWsMi.phantomBuster.agents.fetch({ payload, httpQuery })
        .then((agent) => {
          commit('SET_PHANTOMBUSTER_AGENT', agent);
        });
    },
    fetchAllAndInsertAgents({ commit, rootGetters, dispatch }, { payload = {}, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'PHANTOM_BUSTER_AGENTS.FETCH_ALL_AND_INSERT', dispatch)) return undefined;

      return navySeal.apiWsMi.phantomBuster.agents.postFetchAllAndInsert({ payload, httpQuery })
        .then((agents) => {
          const agentList = agents.reduce((acc, agent) => {
            const { pbaid } = agent;
            acc[pbaid] = { ...agent };
            return acc;
          }, {});

          commit('SET_PHANTOMBUSTER_AGENT_LIST', agentList);
        });
    },
    launch({ commit, rootGetters, dispatch }, { payload = {}, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'PHANTOM_BUSTER_AGENTS.LAUNCH', dispatch)) return undefined;

      const { 'Authenticate/getterAuthenticate': authenticate } = rootGetters;
      const { account: { aid: accountAid } } = authenticate;

      return navySeal.apiWsMi.phantomBuster.agents.launch({
        payload: { accountAid, ...payload }, httpQuery,
      })
        .then((container) => {
          commit(
            'PhantomBuster/containers/UPDATE_PHANTOMBUSTER_CONTAINER_IN_CONTAINER_LIST',
            { ...container, pbaid: payload.pbaid },
            { root: true },
          );
        });
    },
  },
};
