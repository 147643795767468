<template>
  <section class="w-100">
    <p class="lead is-small">
      {{ $_('Please enter a new password to validate your account.') }}
    </p>

    <br>

    <ChangePassword
      :horizontal="false"
      expanded
      @updatePassword="updatePassword($event)"
    />
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TheFirstConnectionChangePassword',
  computed: {
    ...mapGetters({
      myAuthenticate: 'TheSettingsProfile/myAuthenticate',
      myDetails: 'TheSettingsProfile/myDetails',
      myPassName: 'TheSettingsProfile/myPassName',
    }),
  },
  methods: {
    ...mapActions({
      _changePassword: 'TheSettingsProfile/changePassword',
    }),
    updatePassword(payload) {
      Promise.resolve()
        .then(() => this._changePassword({
          payload: {
            ...payload,
            userUid: this.myAuthenticate.uid,
            aid: this.myAuthenticate.aid,
            passName: this.myAuthenticate.passName,
          },
        }))
        .then(() => this.$router.push({ path: '/dashboard/user' }));
    },
  },
};
</script>
