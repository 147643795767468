/* eslint-disable import/no-extraneous-dependencies */
import { dispatchFailure } from '@jack/dataStores/$utils/helpers';

export default class IoListener {
  static staticEvents = [
    'connect',
    'error',
    'disconnect',
    'reconnect',
    'reconnect_attempt',
    'reconnecting',
    'reconnect_error',
    'reconnect_failled',
    'connect_error',
    'connect_timeout',
    'connecting',
    'ping',
    'pong',
    'mq',
  ];

  constructor(vuex, instance) {
    this.instance = instance;
    this.store = vuex;
    this.register();
  }

  register(evt, callback, component) {
    if (!callback || !component) return;
    IoListener.staticEvents.forEach((_evt) => {
      this.instance.io.on(_evt, (args) => {
        if ((args && args.error) && process.env.VUE_APP_MODE !== 'dev') {
          const { dispatch } = this.store;
          dispatchFailure({ dispatch, config: { error: 'An error has occurred' } });
          return;
        }
        callback.call(component, args);
      });
    });
  }

  onEvent(evt, args) {
    this.instance.emitter.emit(evt, args);
  }
}
