<template>
  <section class="tooltipAction">
    <div class="tooltipActionContent">
      <div class="content">
        <p>
          <span class="subtitle is-big mr-2">{{ tooltipActionData.label }}</span>
          <span class="has-text-secondary">selected</span>
        </p>
        <div class="divider-horizontal" />
        <div class="buttons">
          <b-button
            v-if="buttonClose"
            :type="typeClose"
            :label="labelClose"
            size="is-small"
            @click="$emit('cancel')"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'TooltipAction',
  props: {
    tooltipActionData: { type: Object, required: true },
    buttonClose: { type: Boolean, default: true },
    typeClose: { type: String, default: 'is-ghost' },
    labelClose: { type: String, default: 'Cancel' },
  },
};
</script>
