<template>
  <section class="cgu">
    Conditions Générales de Vente
  </section>
</template>

<script>
export default {
  name: 'TheCGV',
};
</script>
