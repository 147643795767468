<template>
  <div id="jackAuthenticate">
    <section class="authenticate-container">
      <div class="authenticate-form-container">
        <div class="authenticate-form">
          <form
            class="w-100"
            @submit.prevent="connectUser({ ...newAuthentication, method: 'DEFAULT' })"
          >
            <h2 class="title is-medium">Welcome 👋</h2>

            <br>

            <b-field
              label="Email"
              class="bg-solitude"
            >
              <b-input
                v-model="newAuthentication.passName"
                required
                placeholder="name@company.com"
                expanded
              />
            </b-field>

            <br>

            <b-field
              label="Password"
              class="bg-solitude"
            >
              <b-input
                v-model="newAuthentication.passPhrase"
                type="password"
                required
                placeholder="*********"
                password-reveal
                expanded
              />
            </b-field>

            <br>

            <div class="buttons">
              <b-button
                type="is-primary"
                label="Login"
                native-type="submit"
                expanded
              />
            </div>
          </form>

          <!-- <div class="buttons is-right mt-3">
            <b-button
              tag="router-link"
              to="/auth/reset-password"
              type="is-ghost"
              class="no-padding-horizontal"
              label="Reset password"
            />
          </div> -->
        </div>
      </div>
      <div class="authenticate-image">
        <b-image
          class="bg-svg"
          :src="background"
          alt="Fond devmood"
        />
        <b-image
          :src="theme ? logo.white : logo.black"
          alt="Devmood logo"
        />
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */
import { mapActions, mapGetters } from 'vuex';

const moment = require('moment');
const logoWhite = require('@jack/public/assets/images/icons/DEVMOOD_TEXT_WHITE_SVG.svg');
const logoBlack = require('@jack/public/assets/images/icons/DEVMOOD_TEXT_BLACK_SVG.svg');
const cccoil = require('@jack/public/assets/images/cccoil.svg');

export default {
  name: 'TheAuthenticate',
  data() {
    return {
      newAuthentication: {
        passName: null,
        passPhrase: null,
      },
      logo: {
        white: logoWhite,
        black: logoBlack,
      },
      background: cccoil,
    };
  },
  computed: {
    ...mapGetters({
      myAuthenticate: 'TheAuthenticate/myAuthenticate',
      theme: 'Starter/getterTheme',
    }),
  },
  watch: {
    theme(newVal) {
      if (newVal) {
        document.getElementsByTagName('html')[0].classList.add('theme--dark');
        this.updateTheme(true);
      } else {
        document.getElementsByTagName('html')[0].classList.remove('theme--dark');
        this.updateTheme(false);
      }
    },
  },
  mounted() {
    this.setThemeWithTime();
  },
  methods: {
    ...mapActions({
      logIn: 'TheAuthenticate/logIn',
      reconnectUser: 'TheAuthenticate/reconnect',
      disconnectUser: 'TheAuthenticate/logOut',
      updateTheme: 'Starter/updateTheme',
      pushWsNotification: 'TheWsNotifier/push',
    }),
    setThemeWithTime() {
      const currentTime = moment().hours();

      if ((currentTime >= 18 && currentTime <= 24) || (currentTime >= 0 && currentTime <= 8)) {
        this.updateTheme(true);
      }
    },
    connectUser(payload) {
      this.logIn({ payload })
        .then(() => {
          this.pushWsNotification({ type: 'success', title: `Welcome back ${this.myAuthenticate.details.firstName} !` });
          this.$router.push('/dashboard/user');
        });
    },
  },
};
</script>
