<template>
  <section id="TheHome">
    <router-view />
  </section>
</template>

<script>
export default {
  name: 'TheHome',
};
</script>
