<template>
  <section>
    <div class="multibar">
      <h3 class="start title is-small">
        Informations spend
      </h3>
    </div>

    <br>

    <fieldset>
      <b-dropdown
        v-model="newSpend.details.categories"
        class="mb-5"
        expanded
      >
        <template #trigger="{ active }">
          <div>
            <b-button
              :label="newSpend.details.categories.label"
              :icon-pack="newSpend.details.categories.pack"
              :icon-left="newSpend.details.categories.icon"
              type="is-dashed"
              expanded
              :icon-right="active ? 'menu-up' : 'menu-down'"
            />
          </div>
        </template>
        <b-dropdown-item
          v-for="category in categoriesList"
          :key="category.cid"
          :value="category"
        >
          <div class="media">
            <b-icon
              class="media-left"
              :pack="category.pack"
              :icon="category.icon"
            />
            <div
              class="media-content is-flex is-align-items-center is-justify-content-space-between"
            >
              <h3>{{ category.label }}</h3>
              <small class="subtext is-big">{{ category.cid }}</small>
            </div>
          </div>
        </b-dropdown-item>
      </b-dropdown>

      <b-dropdown
        v-model="newSpend.details.type"
        class="mb-5"
        expanded
      >
        <template #trigger="{ active }">
          <div>
            <b-button
              :label="newSpend.details.type.label"
              :icon-pack="newSpend.details.type.pack"
              :icon-left="newSpend.details.type.icon"
              type="is-dashed"
              expanded
              :icon-right="active ? 'menu-up' : 'menu-down'"
            />
          </div>
        </template>
        <b-dropdown-item
          v-for="purchase in purchaseTypes"
          :key="purchase.cid"
          :value="purchase"
        >
          <div class="media">
            <b-icon
              class="media-left"
              :pack="purchase.pack"
              :icon="purchase.icon"
            />
            <div
              class="media-content is-flex is-align-items-center is-justify-content-space-between"
            >
              <h3>{{ purchase.label }}</h3>
              <small class="subtext is-big">{{ purchase.value }}</small>
            </div>
          </div>
        </b-dropdown-item>
      </b-dropdown>

      <br>

      <b-field
        label="Label"
      >
        <b-input
          v-model="newSpend.details.label"
          required
        />
      </b-field>

      <br>

      <b-field
        label="Price"
      >
        <b-numberinput
          v-model="newSpend.details.deposit"
          required
          min="0"
          min-step="0.01"
          icon-pack="fas"
        />
      </b-field>
    </fieldset>
  </section>
</template>

<script>
export default {
  name: 'JackSidePanelAddSpendForm',
  model: {
    prop: 'newSpend',
  },
  props: {
    newSpend: { type: Object, required: true },
    categoriesList: { type: Array, required: true },
  },
  computed: {
    purchaseTypes() {
      return [
        {
          value: 'PERSONAL', label: 'Personal purchase', icon: 'hand-holding-heart', pack: 'fa',
        },
        {
          value: 'PROFESSIONAL', label: 'Professional purchase', icon: 'user-tie', pack: 'fa',
        },
      ];
    },
  },
};
</script>
