<template>
  <!-- eslint-disable max-len -->
  <main
    id="app"
    :class="[{ 'body-locked': lockBody }]"
  >
    <div
      :class="[{
        'app': !isAuthPage,
        'w-100': isAuthPage,
        'mobile-view': windowWidth < 601,
        'is-flex-direction-column': windowWidth >= 601,
      }]"
    >
      <MenuTopMobile
        v-if="routeHasMenu && !isAuthPage && windowWidth < 601"
        :mobile-view="windowWidth < 601"
      />
      <div :class="['app-container', { 'computer-view': windowWidth >= 601 && !isAuthPage}]">
        <header
          v-if="windowWidth >= 601 && routeHasMenu"
          :class="['app-header', { 'responsive': responsive }]"
        >
          <TheMenu :responsive="responsive" />
        </header>
        <div
          :class="['app-content', {
            'responsive': responsive,
            'app-mobile': windowWidth < 601,
            'no-menu': !routeHasMenu
          }]"
        >
          <transition name="fade">
            <router-view v-if="authorizedAccess" />
          </transition>
          <WsNotifiers
            v-if="hasWsNotifications"
            :ws-notifiers="wsNotifiers"
            @deleteNotif="closeWsNotif($event)"
          />
        </div>
      </div>
      <MenuBottomMobile
        v-if="windowWidth < 601 && !isAuthPage && routeHasMenu"
        @openModal="openModal($event)"
      />
    </div>
    <b-modal
      v-model="modalOpenActionAdd"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="Example Modal"
      close-button-aria-label="Close"
      aria-modal
    >
      <template #default>
        <div class="modal-container">
          <componet
            :is="modalCmpActionAdd"
            @close="modalOpenActionAdd = false"
          />
        </div>
      </template>
    </b-modal>
  </main>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';

import SocketMixins from '@jack/socket/$utils/SocketMixins.mixins';

const {
  VUE_APP_BUILD_DATE: buildDate,
  VUE_APP_VERSION: version,
} = process.env;

console.log(`
  DEVMOOD
  Version: ${version}
  Builded on ${moment(buildDate).format('YYYY-MM-DD HH:mm:ss')}
  
`);

export default {
  name: 'App',
  mixins: [SocketMixins],
  data() {
    return {
      refusedAccess: {
        title: 'Connexion required',
      },
      modalOpenActionAdd: false,
      modalCmpActionAdd: null,
      tokenSocket: undefined,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'TheAuthenticate/myAuthenticate',
      isAllCustomFetched: 'Starter/isAllCustomFetched',
      windowWidth: 'TheMenu/getterWindowWidth',
      wsNotifiers: 'TheWsNotifier/gettersWsNotificationList',
      theme: 'Starter/getterTheme',
      socketToken: 'TheMenu/socketToken',
      socketInstance: 'TheMenu/socketInstance',
      account: 'TheMenu/account',
      userLanguage: 'TheMenu/userLanguage',
    }),
    hasTokenAuth() {
      if (!this.socketToken) return false;
      if (this.socketToken === this.tokenSocket) return false;
      return true;
    },
    hasWsNotifications() {
      if (!Object.values(this.wsNotifiers).length) return false;
      return true;
    },
    responsive() {
      if (this.windowWidth <= 1200) return true;
      return false;
    },
    isAuthPage() {
      if (this.$route.path.includes('auth')) return true;
      return false;
    },
    lockBody() {
      if (this.$route.meta.lockBodySidePanel) return true;
      return false;
    },
    routeHasMenu() {
      return this.$route.meta.hasMenu;
    },
    authorizedAccess() {
      const { meta } = this.$route;
      if (meta && meta.requireAuth) {
        if (!this.currentUser) return false;

        if (!this.isAllCustomFetched) this.initApplication();
        if (!this.isAllCustomFetched) return false;

        // if (!this.socketInstance) this.launchSocket();
        // if (process.env.VUE_APP_MODE !== 'dev') this.toggleWebsocket();

        // if (meta.requiredRights) {
        //   const { rights } = this.currentUser;
        //   const matchedRights = Object.keys(rights)
        //   // eslint-disable-next-line max-len
        //     .reduce((acc, right) => (meta.requiredRights.includes(right) ? [...acc, right] : acc), []);

        //   const willAllow = matchedRights.length === meta.requiredRights.length;
        //   if (!willAllow) {
        //     this.updateRefuseAccess({
        //       title: 'Rights Required',
        //       reason: [
        //         `You don't have required right(s) to access this page. (${this.$route.name})`,
        //         `${meta.requiredRights.join(', ')}`,
        //         'Contact administrator.',
        //       ],
        //     });
        //   }
        //   return willAllow;
        // }
      }
      return true;
    },
  },
  watch: {
    theme(newVal) {
      if (newVal) {
        document.getElementsByTagName('html')[0].classList.add('theme--dark');
        this.updateTheme(true);
      } else {
        document.getElementsByTagName('html')[0].classList.remove('theme--dark');
        this.updateTheme(false);
      }
    },
    userLanguage(newVal) {
      this.$cache('lang', newVal);
    },
  },
  mounted() {
    this.changeTitlePage();
    this.UP_windowWidth(window.innerWidth);
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
      window.addEventListener('mousemove', ({ clientX: x, clientY: y }) => this.UP_posMouse({ x, y }));
    });

    this.setThemeWithTime();
  },
  beforeDestroy() { window.removeEventListener('resize', this.onResize); },
  updated() {
    this.changeTitlePage();
    if (this.hasTokenAuth) {
      this.reconnectSocket();
    }
  },
  methods: {
    ...mapActions({
      reconnectUser: 'TheAuthenticate/reconnect',
      initApplication: 'Starter/initApplication',
      deleteWsNotification: 'TheWsNotifier/delete',
      UP_windowWidth: 'TheMenu/UP_windowWidth',
      UP_posMouse: 'TheMenu/UP_posMouse',
      updateTheme: 'Starter/updateTheme',
      initSocket: 'TheMenu/initSocket',
    }),
    setThemeWithTime() {
      const currentTime = moment().hours();
      if ((currentTime >= 17 && currentTime <= 24) || (currentTime >= 0 && currentTime <= 8)) {
        this.updateTheme(true);
      }
    },
    onResize() {
      return this.UP_windowWidth(window.innerWidth);
    },
    closeWsNotif(event) { this.deleteWsNotification(event); },
    openModal(event) {
      this.modalOpenActionAdd = true;
      this.modalCmpActionAdd = event;
    },
    changeTitlePage() { document.title = `DEVMOOD | ${this.$route.name}`; },
    reconnectSocket() {
      this.$nextTick(() => {
        this.tokenSocket = this.socketToken;
        this.sockets.reconnect({ auth: { token: this.socketToken } });
        this.initSocket({ payload: { io: this.$vueSocketIo.emitter } });
      });
    },
  },
};
</script>

<style lang="scss">
@import '@/Morpheus/scss/index';
html, body {
  background-color: var(--solitude);

  scrollbar-color: $color7 $color7-10;
  scrollbar-width: auto;
}
#app {
  display: flex;
}
.fade-enter-active {
  transition: opacity 0.5s ease;
}
.fade-leave-active { display: none; }
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
