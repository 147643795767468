/* eslint-disable max-len */
import { dispatchAndForward, dispatchFailure } from '../$utils/helpers';

export default {
  getters: {
    account: (_state, _getters, _rootState, rootGetters) => {
      const { 'Account/getterAccount': account } = rootGetters;
      return account;
    },
    accountAid: (_state, _getters, _rootState, rootGetters) => {
      const { 'Account/getterAccount': account } = rootGetters;
      if (!account || !account.aid) return null;
      return account.aid;
    },
    accountDashboard: (_state, _getters, _rootState, rootGetters) => {
      const { 'Account/accountDashboardView/getterAccountDashboardView': accountDashboard } = rootGetters;
      return accountDashboard;
    },
    accountFeatures: (_state, _getters, _rootState, rootGetters) => {
      const { 'Account/features/getterFeatures': accountFeatures } = rootGetters;
      return accountFeatures;
    },
  },
  actions: {
    getAccount({ dispatch }, { payload, httpQuery }) {
      const action = {
        dispatch,
        target: '',
        context: { payload, httpQuery },
        config: {
          start: false,
          end: false,
          error: 'Unable to get account list',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    createAccountLinkClient({ dispatch }, { payload, httpQuery }) {
      const action = {
        dispatch,
        target: 'Account/LinkClient/post',
        context: { payload, httpQuery },
        config: {
          start: false,
          end: false,
          error: 'Unable to link client to user',
        },
      };
      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    createAccountUnlinkClient({ dispatch }, { payload, httpQuery }) {
      const action = {
        dispatch,
        target: 'Account/UnlinkClient/post',
        context: { payload, httpQuery },
        config: {
          start: false,
          end: false,
          error: 'Unable to unlink client to user',
        },
      };
      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    accountDashboardView({ dispatch, getters }) {
      const { currentAccountAid: aid } = getters;

      const action = {
        dispatch,
        target: 'Account/accountDashboardView/get',
        context: {
          payload: { aid },
          httpQuery: {},
        },
        config: {
          start: false,
          end: false,
          error: 'Unable to get account dashboard view',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
  },
};
