<template>
  <section>
    <header class="multibar is-align-items-center">
      <div class="header-title start">
        <p class="title is-small">Liked</p>
      </div>
      <div class="header-actions end">
        <b-field>
          <b-input
            v-model="inputSearch"
            placeholder="Search job liked"
            type="search"
            icon="magnifying-glass"
            icon-pack="fa"
            icon-right="xmark"
            icon-right-clickable
            @icon-right-click="inputSearch = ''"
          />
        </b-field>
      </div>
    </header>

    <br>

    <DevDetails :cnt="{name: 'jobFiltered', obj: jobFiltered}" />

    <Table
      v-if="jobFiltered && jobFiltered.length"
      :data="jobFiltered"
      :columns="Object.values(columns)"
      :loading="loading"
      :total="jobFiltered.length"
      :page="page"
      :per-page="perPage"
      :sort-field="sortField"
      :sort-order="sortOrder"
      :default-sort-order="defaultSortOrder"
      :selected.sync="selected"
      :checked-rows.sync="checkedRows"
      :backend-sorting="false"
      :sticky-header="false"
      detail-key="jid"
    />
    <NoData
      v-else
      title="No jobs liked were found... Yet!"
      content="You can like new alerts at any time"
      action
      :buttons-action="noDataButtons"
      class="noLiked"
    />
    <BulkActions
      v-show="checkedRows && checkedRows.length"
      :number-selected="checkedRows.length"
      :to-action="{
        path: '/networking/liked/bulkActions',
      }"
      @cancel="cancelBulk"
    />

    <router-view
      name="bulkActions"
      path="/networking/liked"
      :actions="bulkActions"
      :checked-rows="checkedRows"
    />

    <div
      v-if="$route.meta.lockBodySidePanel"
      class="overlayWithLock"
    />
  </section>
</template>

<script>
/* eslint-disable-next-line */
import { debounce } from '@jack/addons/$utils/helpers';
import { mapGetters, mapActions } from 'vuex';
import TheNetworkingAttractiveJobColumns from './$mixins/TheNetworkingAttractiveJobColumns.columns.mixins';

export default {
  name: 'TheNetworkingLiked',
  mixins: [TheNetworkingAttractiveJobColumns],
  data() {
    return {
      search: '',
      inputSearch: '',
      pagin: { min: 0, max: 50 },
      state: 'INTERESTED',
      loading: false,
      page: 0,
      perPage: 50,
      sortField: 'jid',
      sortOrder: 'asc',
      defaultSortOrder: 'asc',
      selected: {},
      checkedRows: [],
      currentCampaignViewDetailled: null,
    };
  },
  computed: {
    ...mapGetters({
      jobList: 'TheNetworking/jobList',
    }),
    jobFiltered() {
      if (!this.jobList || !this.jobList.length) return [];
      if (!this.search) {
        return this.jobList.map(({ details, ...rest }) => ({ ...details, ...rest }));
      }

      return this.jobList.reduce((acc, job) => {
        const { details: { title, companyName, description }, ...rest } = job;
        /* eslint-disable max-len */
        if (title && title.toLowerCase().includes(this.search.toLowerCase())) acc.push({ ...rest, ...job.details });
        if (companyName && companyName.toLowerCase().includes(this.search.toLowerCase())) acc.push({ ...rest, ...job.details });
        if (description && description.toLowerCase().includes(this.search.toLowerCase())) acc.push({ ...rest, ...job.details });

        return acc;
      }, []);
    },
    sources() { return { LINKEDIN: { icon: 'linkedin', pack: 'fa' } }; },
    bulkActions() { return ['IMPORT_COMPANY']; },
    noDataButtons() {
      return [{
        buttonType: 'link',
        label: 'Like jobs',
        type: 'is-primary',
        pack: 'fa',
        left: 'heart',
        to: '/networking/alerts',
      }];
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    inputSearch: debounce(function (newVal) {
      this.search = newVal;
    }, 500),
  },
  mounted() {
    this.fetchJobList();
  },
  methods: {
    ...mapActions({
      reportJobList: 'TheNetworking/reportJobList',
    }),
    fetchJobList() {
      const { min, max } = this.pagin;
      this.reportJobList({
        httpQuery: {
          pagin: `${min.toString()},${max.toString()}`,
          state: this.state,
        },
      });
    },
    cancelBulk() {
      this.checkedRows = [];
      if (this.$route.path.includes('/networking/management/bulkActionsPanel')) {
        this.$router.replace({ path: '/networking/management' });
      }
    },
  },
};
</script>
