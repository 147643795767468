<template>
  <div>
    <router-view />

    <div
      v-if="$route.meta.lockBodySidePanel"
      class="overlayWithLock"
    />
  </div>
</template>

<script>
export default {
  name: 'TheSettings',
};
</script>
