var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('b-notification',{class:['jackHelper mb-5', {
      'warning': _vm.contentType === 'warning',
      'success': _vm.contentType === 'success',
      'danger': _vm.contentType === 'danger',
      'info': _vm.contentType === 'info',
    }],attrs:{"closable":false,"type":_vm.type},model:{value:(_vm.notification),callback:function ($$v) {_vm.notification=$$v},expression:"notification"}},[_c('div',{staticClass:"content multibar"},[(_vm.iconLeft)?_c('b-icon',{class:[{
          'text-color-1': _vm.color === '1',
          'text-color-2': _vm.color === '2',
          'text-color-3': _vm.color === '3',
          'text-color-4': _vm.color === '4',
          'text-color-5': _vm.color === '5',
          'text-color-6': _vm.color === '6',
          'text-color-7': _vm.color === '7',
          'text-color-8': _vm.color === '8',
          'text-color-9': _vm.color === '9',
          'text-color-10': _vm.color === '10',
          'text-color-11': _vm.color === '11',
          'text-color2-1': _vm.color === '12',
        }],attrs:{"pack":_vm.packLeft,"icon":_vm.iconLeft,"size":"is-large"}}):_vm._e(),(_vm.tag)?_c('b-taglist',[_c('b-tag',{attrs:{"type":_vm.tagType}},[_vm._v(" "+_vm._s(_vm.tagLabel)+" ")])],1):_vm._e(),(Array.isArray(_vm.content))?_c('p',{staticClass:"is-flex-direction-column is-align-items-flex-start ml-3"},[_vm._l((_vm.content),function(helper){return _c('span',{key:helper},[_c('p',{class:['text is-small', {
                'has-text-primary': _vm.contentType === 'primary',
                'has-text-secondary': _vm.contentType === 'secondary',
                'has-text-danger': _vm.contentType === 'danger',
                'has-text-success': _vm.contentType === 'success',
                'has-text-warning': _vm.contentType === 'warning',
                'text-color-1': _vm.color === '1',
                'text-color-2': _vm.color === '2',
                'text-color-3': _vm.color === '3',
                'text-color-4': _vm.color === '4',
                'text-color-5': _vm.color === '5',
                'text-color-6': _vm.color === '6',
                'text-color-7': _vm.color === '7',
                'text-color-8': _vm.color === '8',
                'text-color-9': _vm.color === '9',
                'text-color-10': _vm.color === '10',
                'text-color-11': _vm.color === '11',
                'text-color2-1': _vm.color === '12',
              }]},[_vm._v(" "+_vm._s(helper)+" ")])])})],2):_c('p',{class:['text is-small', {
          'has-text-primary': _vm.contentType === 'primary',
          'has-text-secondary': _vm.contentType === 'secondary',
          'has-text-danger': _vm.contentType === 'danger',
          'has-text-success': _vm.contentType === 'success',
          'has-text-warning': _vm.contentType === 'warning',
          'text-color-1': _vm.color === '1',
          'text-color-2': _vm.color === '2',
          'text-color-3': _vm.color === '3',
          'text-color-4': _vm.color === '4',
          'text-color-5': _vm.color === '5',
          'text-color-6': _vm.color === '6',
          'text-color-7': _vm.color === '7',
          'text-color-8': _vm.color === '8',
          'text-color-9': _vm.color === '9',
          'text-color-10': _vm.color === '10',
          'text-color-11': _vm.color === '11',
          'text-color2-1': _vm.color === '12',
        }]},[_vm._v(" "+_vm._s(_vm.content)+" ")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }