<template>
  <section>
    <div class="card-content">
      <div class="multibar">
        <h3 class="subtitle start">
          {{ title }}
        </h3>

        <div class="end buttons">
          <b-button
            tag="router-link"
            :to="to"
            label="See all"
            type="is-ghost"
          />
        </div>
      </div>

      <br>

      <Table
        v-if="data"
        :data="data"
        :loading="loading"
        :columns="Object.values(columns)"
        :total="data.length"
        :page="page"
        :per-page.sync="perPage"
        :sort-field="keyArray"
        :sort-order="sortOrder"
        :default-sort-order="defaultSortOrder"
        :selected.sync="rowSelected"
        :checked-rows.sync="checkedRows"
        :checkable="false"
        :sticky-header="false"
        :detail-key="keyArray"
        :paginated="false"
        @openDetails="$emit('openDetails', $event)"
      />
    </div>
  </section>
</template>

<script>
export default {
  name: 'DashboardTable',
  props: {
    title: { type: String, required: true },
    to: { type: String, required: true },
    data: { type: Array, required: true },
    columns: { type: Object, required: true },
    keyArray: { type: String, required: true },
    loading: { type: Boolean, required: true },
  },
  data() {
    return {
      total: 0,
      page: 1,
      perPage: 5,
      sortOrder: 'asc',
      defaultSortOrder: 'asc',
      rowSelected: {},
      checkedRows: [],
    };
  },
};
</script>
