/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import { navySeal } from '@jack/provider';
import { isActionAllowed } from '@jack/dataStores/$utils/helpers';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {},
  actions: {
    create({ commit, rootGetters, dispatch }, { payload, httpQuery }) {
      if (!isActionAllowed(rootGetters, 'CLIENT_LINK_PERSON.CREATE', dispatch)) return undefined;

      const { cid, ..._payload } = payload;
      return navySeal.apiWsJoe.client.link.person.post({ payload: _payload, httpQuery })
        .then(({ personPid }) => {
          if (cid) {
            const { 'Company/getterCompany': company } = rootGetters;
            if (!company.client) return;

            const person = company.employees.find(({ pid }) => Number(pid) === Number(personPid));
            company.client.persons.push(person);

            commit('Company/SET_COMPANY', company, { root: true });
          }
        });
    },
  },
  modules: {},
};
