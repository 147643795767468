<template>
  <section
    :class="['default-picture', {
      'bg1-lighten-30': color === 1,
      'bg2-lighten-30': color === 2,
      'bg3-lighten-30': color === 3,
      'bg4-lighten-30': color === 4,
      'bg5-lighten-30': color === 5,
      'bg6-lighten-30': color === 6,
      'bg7-lighten-30': color === 7,
      'bg8-lighten-30': color === 8,
      'bg9-lighten-30': color === 9,
      'bg10-lighten-20': color === 10,
      'bg11-lighten-10': color === 11,
      'bg12-lighten-30': color === 12,
      'is-8x8': size === 8,
      'is-16x16': size === 16,
      'is-18x18': size === 18,
      'is-24x24': size === 24,
      'is-32x32': size === 32,
      'is-40x40': size === 40,
      'is-48x48': size === 48,
      'is-64x64': size === 64,
      'is-96x96': size === 96,
      'is-128x128': size === 128,
    }]"
    @click="$emit('click')"
  >
    <span
      :class="['title is-small', {
        'text-color-1-60': color === 1,
        'text-color-2-60': color === 2,
        'text-color-3-60': color === 3,
        'text-color-4-60': color === 4,
        'text-color-5-60': color === 5,
        'text-color-6-60': color === 6,
        'text-color-7-60': color === 7,
        'text-color-8-60': color === 8,
        'text-color-9-60': color === 9,
        'text-color-10-60': color === 10,
        'text-color-11-60': color === 11,
        'text-color-12-60': color === 12,
      }]"
    >
      {{ initials }}
    </span>
  </section>
</template>

<script>
export default {
  name: 'InitialsPicture',
  props: {
    name: { type: String, required: true },
    size: { type: Number, default: 64 },
  },
  computed: {
    color() {
      return Math.floor(this.seed(this.name) * (12 - 1) + 1);
    },
    initials() {
      return this.name.split(' ').slice(0, 2).map((e) => e.charAt(0)).join('');
    },
  },
  methods: {
    seed(str) {
      if (!str) return 0;
      let nb = 0;
      for (let i = 0; i < str.length; i += 1) {
        nb += str.charCodeAt(i);
      }
      const x = Math.sin(nb / str.length) * 10000;
      return x - Math.floor(x);
    },
  },
};
</script>
