/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import { navySeal } from '@jack/provider';
import { isActionAllowed } from '@jack/dataStores/$utils/helpers';

export default {
  namespaced: true,
  state: {
    kanbanList: {},
    kanban: {},
  },
  mutations: {
    SET_KANBAN_LIST(state, newData) { state.kanbanList = newData; },
    SET_KANBAN(state, newData) { state.kanban = newData; },
    UPDATE_KANBAN_IN_KANBAN_LIST(state, newData) {
      state.kanbanList[newData.kid] = { ...state.kanbanList[newData.kid], ...newData };
    },
  },
  getters: {
    getterKanbanList: (state) => state.kanbanList,
    getterKanban: (state) => state.kanban,
  },
  actions: {
    get({ commit, rootGetters, dispatch }, { payload = {}, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'KANBAN.GET', dispatch)) return undefined;

      return navySeal.apiWsJoe.project.kanban.get({ payload, httpQuery })
        .then((kanban) => {
          commit('SET_KANBAN', kanban);
        });
    },
    post({ commit, rootGetters, dispatch }, { payload = {}, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'KANBAN.CREATE', dispatch)) return undefined;

      return navySeal.apiWsJoe.project.kanban.post({ payload, httpQuery })
        .then(({ kanban }) => {
          commit('SET_KANBAN', kanban);
          commit('UPDATE_KANBAN_IN_KANBAN_LIST', kanban);

          return kanban;
        });
    },
    patch({ commit, rootGetters, dispatch }, { payload = {}, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'KANBAN.PATCH', dispatch)) return undefined;

      return navySeal.apiWsJoe.project.kanban.patch({ payload, httpQuery })
        .then((kanban) => {
          commit('SET_KANBAN', kanban);
          commit('UPDATE_KANBAN_IN_KANBAN_LIST', kanban);
        });
    },
    delete({ commit, rootGetters, dispatch }, { payload = {}, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'KANBAN.DELETE', dispatch)) return undefined;

      return navySeal.apiWsJoe.project.kanban.delete({ payload, httpQuery })
        .then(() => {

        });
    },
  },
};
