import { use, registerComponent } from '../../$utils/plugins';

/* eslint-disable no-multi-spaces */

import JackMenuItem from './JackMenuItem';
import MenuTopMobile from './MenuTopMobile';
import MenuBottomMobile from './MenuBottomMobile';
import MenuAddPayment from './MenuAddPayment';
import MenuAddProject from './MenuAddProject';
import MenuAddClient from './MenuAddClient';
import MenuAddAppointment from './MenuAddAppointment';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, JackMenuItem);
    registerComponent(Vue, MenuTopMobile);
    registerComponent(Vue, MenuBottomMobile);
    registerComponent(Vue, MenuAddPayment);
    registerComponent(Vue, MenuAddProject);
    registerComponent(Vue, MenuAddClient);
    registerComponent(Vue, MenuAddAppointment);
  },
};

use(Plugin);

export default Plugin;

export {
  JackMenuItem,
  MenuTopMobile,
  MenuBottomMobile,
  MenuAddPayment,
  MenuAddProject,
  MenuAddClient,
  MenuAddAppointment,
};
