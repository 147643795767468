<template>
  <Project
    :entite="company"
    @addProject="$emit('addProject', $event)"
    @deleteProject="$emit('deleteProject', $event)"
  />
</template>

<script>
export default {
  name: 'CompanyProjects',
  props: {
    company: { type: Object, required: true },
  },
};
</script>
