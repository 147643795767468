/* eslint-disable import/no-extraneous-dependencies */
import { getters, actions } from '@jack/dataStores/commonViewStores';

export default {
  namespaced: true,
  state: {},
  getters: {
    ...getters.authenticate,
    ...getters.agents,
    ...getters.client,
    ...getters.company,
    ...getters.contactHistory,
    ...getters.containers,
    ...getters.job,
    ...getters.notes,
    ...getters.person,
    ...getters.project,
    ...getters.tag,
    ...getters.appointment,
  },
  actions: {
    ...actions.agents,
    ...actions.client,
    ...actions.company,
    ...actions.contactHistory,
    ...actions.containers,
    ...actions.job,
    ...actions.notes,
    ...actions.payment,
    ...actions.person,
    ...actions.project,
    ...actions.user,
    ...actions.tag,
    ...actions.appointment,
  },
};
