import TheStyleGuide from './TheStyleGuide';

import TheAccordion from './components/Accordion/TheAccordion';
import TheAlert from './components/Alerts/TheAlert';
import TheBreadcrumb from './components/Breadcrumb/TheBreadcrumbs';
import TheBullet from './components/Bullets/TheBullet';
import TheButton from './components/Buttons/TheButton';
import TheCard from './components/Cards/TheCard';
import TheCarousel from './components/Carousel/TheCarousel';
import TheForm from './components/Forms/TheForm';
import TheIndicator from './components/Indicator/TheIndicator';
import TheModal from './components/Modal/TheModal';
import TheOverlay from './components/Overlay/TheOverlay';
import ThePagination from './components/Pagination/ThePagination';
import ThePopover from './components/Popovers/ThePopover';
import ThePulse from './components/Pulse/ThePulse';
import TheRating from './components/Rating/TheRating';
import TheRibbon from './components/Ribbon/TheRibbon';
import TheRotate from './components/Rotate/TheRotate';
import TheSeparator from './components/Separator/TheSeparator';
import TheSymbol from './components/Symbol/TheSymbol';
import TheTable from './components/Tables/TheTable';
import TheTab from './components/Tabs/TheTab';
import TheToast from './components/Toast/TheToast';
import TheTooltip from './components/Tooltips/TheTooltip';
import TheChartJs from './components/ChartJs/TheChartJs';
import TheFullCalendar from './components/FullCalendar/TheFullCalendar';

export default [
  {
    path: '/style-guide',
    name: 'Style guide',
    component: TheStyleGuide,
    meta: {
      hasMenu: true,
      requireAuth: true,
      viewStoreNavigation: 'TheStyleGuide',
    },
    children: [
      {
        path: '/style-guide/accordion',
        name: 'Style guide - Accordion',
        component: TheAccordion,
        meta: {
          hasMenu: true,
          requireAuth: true,
          viewStoreNavigation: 'TheStyleGuide',
        },
      },
      {
        path: '/style-guide/alert',
        name: 'Style guide - Alert',
        component: TheAlert,
        meta: {
          hasMenu: true,
          requireAuth: true,
          viewStoreNavigation: 'TheStyleGuide',
        },
      },
      {
        path: '/style-guide/breadcrumb',
        name: 'Style guide - Breadcrumb',
        component: TheBreadcrumb,
        meta: {
          hasMenu: true,
          requireAuth: true,
          viewStoreNavigation: 'TheStyleGuide',
        },
      },
      {
        path: '/style-guide/bullet',
        name: 'Style guide - Bullet',
        component: TheBullet,
        meta: {
          hasMenu: true,
          requireAuth: true,
          viewStoreNavigation: 'TheStyleGuide',
        },
      },
      {
        path: '/style-guide/button',
        name: 'Style guide - Button',
        component: TheButton,
        meta: {
          hasMenu: true,
          requireAuth: true,
          viewStoreNavigation: 'TheStyleGuide',
        },
      },
      {
        path: '/style-guide/card',
        name: 'Style guide - Card',
        component: TheCard,
        meta: {
          hasMenu: true,
          requireAuth: true,
          viewStoreNavigation: 'TheStyleGuide',
        },
      },
      {
        path: '/style-guide/carousel',
        name: 'Style guide - Carousel',
        component: TheCarousel,
        meta: {
          hasMenu: true,
          requireAuth: true,
          viewStoreNavigation: 'TheStyleGuide',
        },
      },
      {
        path: '/style-guide/form',
        name: 'Style guide - Form',
        component: TheForm,
        meta: {
          hasMenu: true,
          requireAuth: true,
          viewStoreNavigation: 'TheStyleGuide',
        },
      },
      {
        path: '/style-guide/indicator',
        name: 'Style guide - Indicator',
        component: TheIndicator,
        meta: {
          hasMenu: true,
          requireAuth: true,
          viewStoreNavigation: 'TheStyleGuide',
        },
      },
      {
        path: '/style-guide/modal',
        name: 'Style guide - Modal',
        component: TheModal,
        meta: {
          hasMenu: true,
          requireAuth: true,
          viewStoreNavigation: 'TheStyleGuide',
        },
      },
      {
        path: '/style-guide/overlay',
        name: 'Style guide - Overlay',
        component: TheOverlay,
        meta: {
          hasMenu: true,
          requireAuth: true,
          viewStoreNavigation: 'TheStyleGuide',
        },
      },
      {
        path: '/style-guide/pagination',
        name: 'Style guide - Pagination',
        component: ThePagination,
        meta: {
          hasMenu: true,
          requireAuth: true,
          viewStoreNavigation: 'TheStyleGuide',
        },
      },
      {
        path: '/style-guide/popover',
        name: 'Style guide - Popover',
        component: ThePopover,
        meta: {
          hasMenu: true,
          requireAuth: true,
          viewStoreNavigation: 'TheStyleGuide',
        },
      },
      {
        path: '/style-guide/pulse',
        name: 'Style guide - Pulse',
        component: ThePulse,
        meta: {
          hasMenu: true,
          requireAuth: true,
          viewStoreNavigation: 'TheStyleGuide',
        },
      },
      {
        path: '/style-guide/rating',
        name: 'Style guide - Rating',
        component: TheRating,
        meta: {
          hasMenu: true,
          requireAuth: true,
          viewStoreNavigation: 'TheStyleGuide',
        },
      },
      {
        path: '/style-guide/ribbon',
        name: 'Style guide - Ribbon',
        component: TheRibbon,
        meta: {
          hasMenu: true,
          requireAuth: true,
          viewStoreNavigation: 'TheStyleGuide',
        },
      },
      {
        path: '/style-guide/rotate',
        name: 'Style guide - Rotate',
        component: TheRotate,
        meta: {
          hasMenu: true,
          requireAuth: true,
          viewStoreNavigation: 'TheStyleGuide',
        },
      },
      {
        path: '/style-guide/separator',
        name: 'Style guide - Separator',
        component: TheSeparator,
        meta: {
          hasMenu: true,
          requireAuth: true,
          viewStoreNavigation: 'TheStyleGuide',
        },
      },
      {
        path: '/style-guide/symbol',
        name: 'Style guide - Symbol',
        component: TheSymbol,
        meta: {
          hasMenu: true,
          requireAuth: true,
          viewStoreNavigation: 'TheStyleGuide',
        },
      },
      {
        path: '/style-guide/table',
        name: 'Style guide - Table',
        component: TheTable,
        meta: {
          hasMenu: true,
          requireAuth: true,
          viewStoreNavigation: 'TheStyleGuide',
        },
      },
      {
        path: '/style-guide/tab',
        name: 'Style guide - Tab',
        component: TheTab,
        meta: {
          hasMenu: true,
          requireAuth: true,
          viewStoreNavigation: 'TheStyleGuide',
        },
      },
      {
        path: '/style-guide/toast',
        name: 'Style guide - Toast',
        component: TheToast,
        meta: {
          hasMenu: true,
          requireAuth: true,
          viewStoreNavigation: 'TheStyleGuide',
        },
      },
      {
        path: '/style-guide/tooltip',
        name: 'Style guide - Tooltip',
        component: TheTooltip,
        meta: {
          hasMenu: true,
          requireAuth: true,
          viewStoreNavigation: 'TheStyleGuide',
        },
      },
      {
        path: '/style-guide/chart-js',
        name: 'Style guide - Chart.js',
        component: TheChartJs,
        meta: {
          hasMenu: true,
          requireAuth: true,
          viewStoreNavigation: 'TheStyleGuide',
        },
      },
      {
        path: '/style-guide/full-calendar',
        name: 'Style guide - FullCalendar',
        component: TheFullCalendar,
        meta: {
          hasMenu: true,
          requireAuth: true,
          viewStoreNavigation: 'TheStyleGuide',
        },
      },
    ],
  },
];
