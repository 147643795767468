/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import { navySeal } from '@jack/provider';

export default {
  namespaced: true,
  state: {
    instance: null,
    token: null,
  },
  mutations: {
    SET_SOCKET_INSTANCE(state, newData) { state.instance = newData; },
    SET_SOCKET_TOKEN(state, newData) { state.token = newData; },
  },
  getters: {
    getterSocketInstance: (state) => state.instance,
    getterSocketToken: (state) => state.token,
  },
  actions: {
    initSocket({ commit }, { payload = {} }) {
      const { io } = payload;
      commit('SET_SOCKET_INSTANCE', io);
    },
    io_mq(_, context) { return navySeal.socketIo.mq(context); },
  },
};
