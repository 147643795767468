/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
/* eslint-disable import/no-extraneous-dependencies */
import { navySeal } from '@jack/provider';
import { isActionAllowed } from '@jack/dataStores/$utils/helpers';

export default {
  namespaced: true,
  state: {
    appointmentList: null,
    appointment: null,
  },
  mutations: {
    SET_APPOINTMENT_LITS(state, newData) { state.appointmentList = newData; },
    SET_APPOINTMENT(state, newData) { state.appointment = newData; },
  },
  getters: {
    getterAppointmentList: (state) => state.appointmentList,
    getterAppointment: (state) => state.appointment,
  },
  actions: {
    report({ commit, rootGetters, dispatch }, { payload, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'USER_APPOINTMENT.REPORT', dispatch)) return undefined;

      return navySeal.apiWsDivision.user.appointment.report({ payload, httpQuery })
        .then((appointments) => {
          commit('SET_APPOINTMENT_LITS', appointments.arrayMapper('aid'));
        });
    },
    get({ commit, rootGetters, dispatch }, { payload, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'USER_APPOINTMENT.GET', dispatch)) return undefined;

      return navySeal.apiWsDivision.user.appointment.get({ payload, httpQuery })
        .then(({ details, ...rest }) => {
          commit('SET_APPOINTMENT', { ...details, ...rest });
        });
    },
    post({ dispatch, commit, rootGetters }, { payload, httpQuery = {}, options = {} }) {
      if (!isActionAllowed(rootGetters, 'USER_APPOINTMENT.CREATE', dispatch)) return undefined;

      return navySeal.apiWsDivision.user.appointment.post({ payload, httpQuery })
        .then((results) => {
          const { 'Company/getterCompany': company } = rootGetters;
          const { 'Person/getterPerson': person } = rootGetters;

          const {
            event: { eid, accountAid: aid, uid, appointmentAid, createdAt, type },
            appointment,
          } = results;
          const newEvent = {
            eid,
            aid,
            uid,
            appointmentAid,
            createdAt,
            type,
            appointment,
            cid: null,
            projectPid: null,
            paymentPid: null,
            sid: null,
            client: null,
            project: null,
            payment: null,
            spend: null,
          };

          const { entite } = options;

          switch (entite) {
            case 'COMPANY':
              if (company.appointments) company.appointments.push(appointment);
              else company.appointments = [appointment];
              commit('SET_APPOINTMENT', appointment);
              commit('Company/SET_COMPANY', company, { root: true });
              break;
            case 'PERSON':
              if (person.appointments) person.appointments.push(appointment);
              else person.appointments = [appointment];
              commit('SET_APPOINTMENT', appointment);
              commit('Person/SET_PERSON', person, { root: true });
              break;
            default:
              break;
          }

          dispatch('User/eventsView/up_addEventEventsViewList', newEvent, { root: true });
          return appointment;
        });
    },
    patch({ rootGetters, dispatch, commit }, { payload, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'USER_APPOINTMENT.PATCH', dispatch)) return undefined;

      return navySeal.apiWsDivision.user.appointment.patch({ payload, httpQuery })
        .then(() => {
          const { 'Person/getterPerson': person } = rootGetters;
          const { appointments } = person;

          const mapped = appointments.arrayMapper('aid');
          mapped[payload.aid].state = payload.state;
          commit('Person/SET_PERSON', { ...person, appointments: Object.values(mapped) }, { root: true });
        });
    },
    put({ rootGetters, dispatch }, { payload, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'USER_APPOINTMENT.UPDATE', dispatch)) return undefined;

      return navySeal.apiWsDivision.user.appointment.put({ payload, httpQuery });
    },
    delete({ commit, rootGetters, dispatch }, { payload, httpQuery = {}, options = {} }) {
      if (!isActionAllowed(rootGetters, 'USER_APPOINTMENT.DELETE', dispatch)) return undefined;

      return navySeal.apiWsDivision.user.appointment.delete({ payload, httpQuery })
        .then(() => {
          const { 'Company/getterCompany': company } = rootGetters;
          const { 'Person/getterPerson': person } = rootGetters;
          const { entite } = options;

          switch (entite) {
            case 'COMPANY':
              if (company && company.appointments) {
                const newAppointment = company.appointments.reduce((acc, appoint) => {
                  if (Number(appoint.aid) !== Number(payload.aid)) acc.push(appoint);
                  return acc;
                }, []);
                company.appointments = newAppointment.length ? newAppointment : null;

                commit('Company/SET_COMPANY', company, { root: true });
              }
              break;
            case 'PERSON':
              if (person && person.appointments) {
                const newAppointment = person.appointments.reduce((acc, appoint) => {
                  if (Number(appoint.aid) !== Number(payload.aid)) acc.push(appoint);
                  return acc;
                }, []);
                person.appointments = newAppointment.length ? newAppointment : null;

                commit('Person/SET_PERSON', person, { root: true });
              }
              break;
            default:
              break;
          }
        });
    },
  },
};
