/* eslint-disable object-curly-newline */
/* eslint-disable import/prefer-default-export */
import { builder } from '../engine';

const target = 'apiWsDivision';

const account = {
  get: ({ payload, httpQuery }) => builder({ url: `/account/${payload.aid}`, method: 'GET', payload, httpQuery, target }),
  link: {
    post: ({ payload, httpQuery }) => builder({ url: `/account/${payload.aid}/link`, method: 'POST', payload, httpQuery, target }),
    client: {
      post: ({ payload, httpQuery }) => builder({ url: `/account/${payload.aid}/link/:uid/client/${payload.cid}`, method: 'POST', payload, httpQuery, target }),
    },
  },
  unlink: {
    post: ({ payload, httpQuery }) => builder({ url: `/account/${payload.aid}/unlink`, method: 'POST', payload, httpQuery, target }),
    client: {
      post: ({ payload, httpQuery }) => builder({ url: `/account/${payload.aid}/unlink/:uid/client/${payload.cid}`, method: 'POST', payload, httpQuery, target }),
    },
  },
  accountDashboardView: {
    get: ({ payload, httpQuery }) => builder({ url: `/account/${payload.aid}/accountDashboardView`, method: 'GET', payload, httpQuery, target }),
  },
};

const authenticate = {
  post: ({ payload, httpQuery }) => builder({ url: '/authenticate', method: 'POST', payload, httpQuery, target }),
  get: ({ payload, httpQuery }) => builder({ url: '/authenticate', method: 'GET', payload, httpQuery, target }),
  delete: ({ payload, httpQuery }) => builder({ url: '/authenticate', method: 'DELETE', payload, httpQuery, target }),
  changePassword: {
    post: ({ payload, httpQuery }) => builder({ url: `/authenticate/${payload.aid}/changePassword`, method: 'POST', payload, httpQuery, target }),
  },
};

const user = {
  post: ({ payload, httpQuery }) => builder({ url: '/user', method: 'POST', payload, httpQuery, target }),
  bookAccount: {
    get: ({ payload, httpQuery }) => builder({ url: `/user/${payload.uid}/bookAccount/${payload.baid}`, method: 'GET', payload, httpQuery, target }),
    put: ({ payload, httpQuery }) => builder({ url: `/user/${payload.uid}/bookAccount/${payload.baid}`, method: 'PUT', payload, httpQuery, target }),
    payment: {
      report: ({ payload, httpQuery }) => builder({ url: `/user/${payload.uid}/bookAccount/${payload.baid}/payment`, method: 'REPORT', payload, httpQuery, target }),
      get: ({ payload, httpQuery }) => builder({ url: `/user/${payload.uid}/bookAccount/${payload.baid}/payment/${payload.pid}`, method: 'GET', payload, httpQuery, target }),
      post: ({ payload, httpQuery }) => builder({ url: `/user/${payload.uid}/bookAccount/${payload.baid}/payment`, method: 'POST', payload, httpQuery, target }),
      put: ({ payload, httpQuery }) => builder({ url: `/user/${payload.uid}/bookAccount/${payload.baid}/payment/${payload.pid}`, method: 'PUT', payload, httpQuery, target }),
      delete: ({ payload, httpQuery }) => builder({ url: `/user/${payload.uid}/bookAccount/${payload.baid}/payment/${payload.pid}`, method: 'DELETE', payload, httpQuery, target }),
    },
    spends: {
      report: ({ payload, httpQuery }) => builder({ url: `/user/${payload.uid}/bookAccount/${payload.baid}/spends`, method: 'REPORT', payload, httpQuery, target }),
      get: ({ payload, httpQuery }) => builder({ url: `/user/${payload.uid}/bookAccount/${payload.baid}/spends/${payload.sid}`, method: 'GET', payload, httpQuery, target }),
      post: ({ payload, httpQuery }) => builder({ url: `/user/${payload.uid}/bookAccount/${payload.baid}/spends`, method: 'POST', payload, httpQuery, target }),
      put: ({ payload, httpQuery }) => builder({ url: `/user/${payload.uid}/bookAccount/${payload.baid}/spends/${payload.sid}`, method: 'PUT', payload, httpQuery, target }),
      delete: ({ payload, httpQuery }) => builder({ url: `/user/${payload.uid}/bookAccount/${payload.baid}/spends/${payload.sid}`, method: 'DELETE', payload, httpQuery, target }),
    },
  },
  dashboardView: {
    report: ({ payload, httpQuery }) => builder({ url: `/user/${payload.uid}/dashboardView`, method: 'REPORT', payload, httpQuery, target }),
  },
  eventsView: {
    report: ({ payload, httpQuery }) => builder({ url: `/user/${payload.uid}/eventsView`, method: 'REPORT', payload, httpQuery, target }),
    get: ({ payload, httpQuery }) => builder({ url: `/user/${payload.uid}/eventsView/${payload.eid}`, method: 'GET', payload, httpQuery, target }),
  },
  client: {
    report: ({ payload, httpQuery }) => builder({ url: `/user/${payload.uid}/client`, method: 'REPORT', payload, httpQuery, target }),
    get: ({ payload, httpQuery }) => builder({ url: `/user/${payload.uid}/client/${payload.cid}`, method: 'GET', payload, httpQuery, target }),
    put: ({ payload, httpQuery }) => builder({ url: `/user/${payload.uid}/client/${payload.cid}`, method: 'PUT', payload, httpQuery, target }),
    delete: ({ payload, httpQuery }) => builder({ url: `/user/${payload.uid}/client/${payload.cid}`, method: 'DELETE', payload, httpQuery, target }),
  },
  project: {
    report: ({ payload, httpQuery }) => builder({ url: `/user/${payload.uid}/project`, method: 'REPORT', payload, httpQuery, target }),
    get: ({ payload, httpQuery }) => builder({ url: `/user/${payload.uid}/project/${payload.pid}`, method: 'GET', payload, httpQuery, target }),
    setIsOpen: {
      post: ({ payload, httpQuery }) => builder({ url: `/user/${payload.uid}/project/${payload.pid}/setIsOpen`, method: 'POST', payload, httpQuery, target }),
      delete: ({ payload, httpQuery }) => builder({ url: `/user/${payload.uid}/project/${payload.pid}/setIsOpen`, method: 'DELETE', payload, httpQuery, target }),
    },
  },
  spends: {
    report: ({ payload, httpQuery }) => builder({ url: `/user/${payload.uid}/spends`, method: 'REPORT', payload, httpQuery, target }),
  },
  appointment: {
    report: ({ payload, httpQuery }) => builder({ url: `/user/${payload.uid}/appointment`, method: 'REPORT', payload, httpQuery, target }),
    get: ({ payload, httpQuery }) => builder({ url: `/user/${payload.uid}/appointment/${payload.aid}`, method: 'GET', payload, httpQuery, target }),
    post: ({ payload, httpQuery }) => builder({ url: `/user/${payload.uid}/appointment`, method: 'POST', payload, httpQuery, target }),
    patch: ({ payload, httpQuery }) => builder({ url: `/user/${payload.uid}/appointment/${payload.aid}`, method: 'PATCH', payload, httpQuery, target }),
    put: ({ payload, httpQuery }) => builder({ url: `/user/${payload.uid}/appointment/${payload.aid}`, method: 'PUT', payload, httpQuery, target }),
    delete: ({ payload, httpQuery }) => builder({ url: `/user/${payload.uid}/appointment/${payload.aid}`, method: 'DELETE', payload, httpQuery, target }),
  },
  userDashboardView: {
    get: ({ payload, httpQuery }) => builder({ url: `/user/${payload.uid}/userDashboardView`, method: 'GET', payload, httpQuery, target }),
  },
  settings: {
    put: ({ payload, httpQuery }) => builder({ url: `/user/${payload.uid}/settings`, method: 'PUT', payload, httpQuery, target }),
  },
  notification: {
    report: ({ payload, httpQuery }) => builder({ url: `/user/${payload.uid}/notification`, method: 'REPORT', payload, httpQuery, target }),
    patch: ({ payload, httpQuery }) => builder({ url: `/user/${payload.uid}/notification/${payload.nid}`, method: 'PATCH', payload, httpQuery, target }),
  },
  language: {
    patch: ({ payload, httpQuery }) => builder({ url: `/user/${payload.uid}/language`, method: 'PATCH', payload, httpQuery, target }),
  },
};
export {
  account,
  authenticate,
  user,
};
