<template>
  <section>
    <b-notification
      class="jackHelper warning"
      :closable="false"
    >
      <div class="content">
        <b-icon
          pack="fa"
          icon="circle-info"
          class="text-color-3"
          size="is-large"
        />

        <div class="is-flex is-flex-direction-column ml-5">
          <p class="text text-color-3 m-0">
            For a question of security of your Linkedin account, the script takes several minutes to scrape the data you have requested. Please wait a few minutes to receive the results of your search
          </p>
        </div>
      </div>
    </b-notification>
    <b-notification
      class="jackHelper success"
      :closable="false"
    >
      <div class="content">
        <b-icon
          pack="fa"
          icon="circle-check"
          class="text-color-5"
          size="is-large"
        />

        <div class="is-flex is-flex-direction-column ml-5">
          <p class="text text-color-5 m-0">
            The phantom {{ agent.script }} was successfully launched
          </p>
        </div>
      </div>
    </b-notification>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'SidePanelAgentResult',
  props: {
    agent: { type: Object, default: () => {} },
  },
  computed: {
    ...mapGetters({
      containerList: 'TheJobs/containerList',
    }),
  },
  methods: {
    ...mapActions({
      fetchAllContainers: 'TheJobs/fetchAllContainers',
    }),
  },
};
</script>
