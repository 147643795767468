/* eslint-disable no-param-reassign */
/* eslint-disable import/no-extraneous-dependencies */
import axios from 'axios';

import ErrorBuilder from '../addons/$utils/Errors';
import { store } from './index';

axios.defaults.withCredentials = true;

const interceptors = (instance) => instance.interceptors.response.use((response) => {
  const { data, ...rest } = response;
  const { __authenticate__, __response__ } = data;
  // console.log('🚀 ~ file: engine.js ~ line 29 ~ instance.interceptors.response.use ~ data', data);
  if (!__authenticate__) return Promise.resolve({ data: __response__, ...rest });
  return store && store.dispatch
    ? store.dispatch('Authenticate/up_authenticate', __authenticate__, { root: true })
      .then(() => ({ data: __response__, ...rest }))
    : Promise.resolve({ data: __response__, ...rest });
}, (error) => {
  console.log('🚀 ~ file: engine.js ~ line 71 ~ instance.interceptors.response.use ~ error', error);
  return Promise.reject(error);
});

const connectors = {
  apiWsJoe: () => {
    const instance = axios.create({
      baseURL: `${process.env.VUE_APP_NAVYSEAL_BASE_URL}/api-ws-joe`,
      withCredentials: true,
      headers: { 'Content-Type': 'application/json' },
    });

    interceptors(instance);

    return instance;
  },
  apiWsMi: () => {
    const instance = axios.create({
      baseURL: `${process.env.VUE_APP_NAVYSEAL_BASE_URL}/api-ws-mi`,
      withCredentials: true,
      headers: { 'Content-Type': 'application/json' },
    });

    interceptors(instance);

    return instance;
  },
  apiWsDivision: () => {
    const instance = axios.create({
      baseURL: `${process.env.VUE_APP_NAVYSEAL_BASE_URL}/api-ws-division`,
      withCredentials: true,
      headers: { 'Content-Type': 'application/json' },
    });

    interceptors(instance);

    return instance;
  },
  apiSocials: () => {
    const instance = axios.create({
      baseURL: 'https://www.instagram.com/',
      headers: { 'Content-Type': 'application/json' },
    });

    // interceptors(instance);

    return instance;
  },
  socketIo: ({ instance, context }) => {
    instance.emit('mq', context);
    return instance;
  },
};

const internals = {
  // eslint-disable-next-line arrow-body-style
  parseResponse: ({ data }) => {
    return data;
  },
  // eslint-disable-next-line arrow-body-style
  parseError: (error) => {
    // console.log('🚀 ~ file: engine.js ~ line 33 ~ error.response', error);
    throw new ErrorBuilder({
      message: error.response.data,
      status: error.response.status,
      statusText: error.response.statusText,
    });
  },
  httpQueries: (context) => {
    let httpQuery;

    let session = 'admin@joe.com';
    if (process.env.session) session = `${process.env.session}@devmood.fr`;

    if (process.env.VUE_APP_MODE === 'dev' && context.target !== 'apiSocials') {
      httpQuery = { ...context.httpQuery, testSession: session };
    } else {
      httpQuery = context.httpQuery || {};
    }

    const queries = Object.entries(httpQuery);
    const httpQueries = queries
      .reduce((acc, [key, val], index) => {
        if (index > 0 && index <= (queries.length - 1)) {
          acc += val && typeof val !== 'boolean' ? `&${key}=${val}` : `&${key}`;
        } else {
          acc += val && typeof val !== 'boolean' ? `?${key}=${val}` : `?${key}`;
        }
        return acc;
      }, '');

    return `${context.url}${httpQueries}`;
  },
};

const socketBuilder = (context) => {
  const { 'Socket/getterSocketInstance': instance } = store.getters;
  return connectors.socketIo({ instance, context });
};

const requestBuilder = (context) => {
  const { payload: data, target, method } = context;
  const url = internals.httpQueries(context);
  return connectors[target]().request({ url, data, method });
};

const builder = (context) => requestBuilder(context)
  .then(internals.parseResponse)
  .catch(internals.parseError);

const sBuilder = (context) => socketBuilder(context);

export {
  builder,
  sBuilder,
};
