/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import { navySeal } from '@jack/provider';
import { isActionAllowed } from '@jack/dataStores/$utils/helpers';

export default {
  namespaced: true,
  state: {
    kanbanColumnList: {},
    kanban: {},
  },
  mutations: {
    SET_KANBAN_COLUMN_COLUMN_LIST(state, newData) { state.kanbanColumnList = newData; },
    SET_KANBAN_COLUMN_COLUMN(state, newData) { state.kanbanColumn = newData; },
    UPDATE_KANBAN_COLUMN_IN_KANBAN_COLUMN_LIST(state, newData) {
      state.kanbanColumnList[newData.kcid] = { ...state.kanbanColumnList[newData.kcid], ...newData };
    },
  },
  getters: {
    getterKanbanColumnList: (state) => state.kanbanColumnList,
    getterKanbanColumn: (state) => state.kanbanColumn,
  },
  actions: {
    post({ commit, rootGetters, dispatch }, { payload = {}, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'KANBAN_COLUMN.CREATE', dispatch)) return undefined;

      return navySeal.apiWsJoe.project.kanban.kanbanColumn.post({ payload, httpQuery })
        .then((kanbanColumn) => {
          const { 'Project/kanban/getterKanbanList': kanbanList } = rootGetters;
          kanbanList[payload.kid].columns.push({ ...kanbanColumn, items: [] });

          commit('UPDATE_KANBAN_COLUMN_IN_KANBAN_COLUMN_LIST', kanbanColumn);
          commit('Project/kanban/SET_KANBAN_LIST', kanbanList, { root: true });
          return kanbanColumn;
        });
    },
    update({ commit, rootGetters, dispatch }, { payload = {}, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'KANBAN_COLUMN.UPDATE', dispatch)) return undefined;

      const { newKanbanColumns, ...rest } = payload;
      const { kid } = payload;
      const { 'Project/kanban/getterKanbanList': kanbanList } = rootGetters;
      const columns = newKanbanColumns.arrayMapper('kcid');
      kanbanList[kid].columns = kanbanList[kid].columns
        .reduce((acc, c) => {
          acc.push({ ...c, ...columns[c.kcid] });
          return acc;
        }, []);

      commit('Project/kanban/SET_KANBAN_LIST', kanbanList, { root: true });

      return navySeal.apiWsJoe.project.kanban.kanbanColumn.put({ payload: { ...rest }, httpQuery })
        .then((kanbanColumn) => {
          commit('SET_KANBAN_COLUMN_COLUMN_LIST', kanbanColumn);
        });
    },
    patch({ commit, rootGetters, dispatch }, { payload = {}, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'KANBAN_COLUMN.PATCH', dispatch)) return undefined;

      return navySeal.apiWsJoe.project.kanban.kanbanColumn.patch({ payload, httpQuery })
        .then((kanbanColumn) => {
          commit('SET_KANBAN_COLUMN', kanbanColumn);
          commit('UPDATE_KANBAN_COLUMN_IN_KANBAN_COLUMN_LIST', kanbanColumn);
        });
    },
    delete({ commit, rootGetters, dispatch }, { payload = {}, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'KANBAN_COLUMN.DELETE', dispatch)) return undefined;

      return navySeal.apiWsJoe.project.kanban.kanbanColumn.delete({ payload, httpQuery })
        .then(() => {
          const { 'Project/kanban/getterKanbanList': kanbanList } = rootGetters;
          const columns = kanbanList[payload.kid].columns.arrayMapper('kcid');

          delete columns[payload.kcid];
          kanbanList[payload.kid] = { ...kanbanList[payload.kid], columns: Object.values(columns) };

          commit('Project/kanban/SET_KANBAN_LIST', kanbanList, { root: true });
        });
    },
  },
};
