/* eslint-disable no-multi-spaces */

// Side panel
import TheSidePanel from '@jack/addons/common/TheSidePanel';
import TheSidePanelAddAppointment from '@jack/addons/common/TheSidePanelAddAppointment';
import TheSidePanelAddClient from '@jack/addons/common/TheSidePanelAddClient';
import TheSidePanelAddProject from '@jack/addons/common/TheSidePanelAddProject';
import TheSidePanelAddPayment from '@jack/addons/common/TheSidePanelAddPayment';
import TheSidePanelAddSpends from '@jack/addons/common/TheSidePanelAddSpends';

// Side Panel event
import TheSidePanelEvent from '@jack/addons/common/TheSidePanelEvent';
import TheCalendar from './TheCalendar';

export default [
  {
    path: '/calendar',
    name: 'Calendar',
    component: TheCalendar,
    meta: {
      hasMenu: true,
      viewStoreNavigation: 'TheHome',
      requireAuth: true,
    },
    children: [
      {
        path: '/calendar/sidePanel',
        name: 'Side panel - Calendar',
        components: { sidePanel: TheSidePanel },
        meta: {
          hasMenu: true,
          viewStoreNavigation: 'TheHome',
          requireAuth: true,
          lockBodySidePanel: true,
        },
        children: [
          {
            path: '/calendar/sidePanel/event/',
            name: 'Side panel - Calendar - Event',
            components: { event: TheSidePanelEvent },
            meta: {
              hasMenu: true,
              viewStoreNavigation: 'TheHome',
              requireAuth: true,
              lockBodySidePanel: true,
            },
          },
          {
            path: '/calendar/sidePanel/addAppointment',
            name: 'Side panel - Calendar - Add new appointment',
            components: { addAppointment: TheSidePanelAddAppointment },
            meta: {
              hasMenu: true,
              viewStoreNavigation: 'TheHome',
              requireAuth: true,
              lockBodySidePanel: true,
            },
          },
          {
            path: '/calendar/sidePanel/addClient',
            name: 'Side panel - Calendar - Add new client',
            components: { addClient: TheSidePanelAddClient },
            meta: {
              hasMenu: true,
              viewStoreNavigation: 'TheHome',
              requireAuth: true,
              lockBodySidePanel: true,
            },
          },
          {
            path: '/calendar/sidePanel/addProject',
            name: 'Side panel - Calendar - Add new project',
            components: { addProject: TheSidePanelAddProject },
            meta: {
              hasMenu: true,
              viewStoreNavigation: 'TheHome',
              requireAuth: true,
              lockBodySidePanel: true,
            },
          },
          {
            path: '/calendar/sidePanel/addPayment',
            name: 'Side panel - Calendar - Add new payment',
            components: { addPayment: TheSidePanelAddPayment },
            meta: {
              hasMenu: true,
              viewStoreNavigation: 'TheHome',
              requireAuth: true,
              lockBodySidePanel: true,
            },
          },
          {
            path: '/calendar/sidePanel/addSpends',
            name: 'Side panel - Calendar - Add new spend',
            components: { addSpends: TheSidePanelAddSpends },
            meta: {
              hasMenu: true,
              viewStoreNavigation: 'TheHome',
              requireAuth: true,
              lockBodySidePanel: true,
            },
          },
        ],
      },
    ],
  },
];
