/* eslint-disable max-len */
import { SnackbarProgrammatic as Snackbar } from 'buefy';
import { dispatchAndForward, dispatchFailure, snackBarFailure } from '../$utils/helpers';

export default {
  getters: {
    spendsList: (_state, _getters, _rootState, rootGetters) => {
      const { 'User/spends/getterSpendsList': spendsList } = rootGetters;
      return spendsList;
    },
    spends: (_state, _getters, _rootState, rootGetters) => {
      const { 'User/spends/getterSpends': spends } = rootGetters;
      return spends;
    },
  },
  actions: {
    reportSpendsList({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'User/spends/report',
        context: { payload, httpQuery },
        textNotifier: 'report spends list.',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({ message: 'Unable to report spends list.', ...snackBarFailure });
          return dispatchFailure(action);
        });
    },
    getSpends({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'User/spends/get',
        context: { payload, httpQuery },
        textNotifier: 'get spends.',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({ message: 'Unable to get spends.', ...snackBarFailure });
          return dispatchFailure(action);
        });
    },
    createSpends({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'User/spends/post',
        context: { payload, httpQuery },
        textNotifier: 'create new spends.',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({ message: 'Unable to create a spends.', ...snackBarFailure });
          return dispatchFailure(action);
        });
    },
    updateSpends({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'User/spends/put',
        context: { payload, httpQuery },
        textNotifier: 'update spends.',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({ message: 'Unable to update spends.', ...snackBarFailure });
          return dispatchFailure(action);
        });
    },
    deleteSpends({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'User/spends/delete',
        context: { payload, httpQuery },
        textNotifier: 'delete spends.',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({ message: 'Unable to delete a spends.', ...snackBarFailure });
          return dispatchFailure(action);
        });
    },
  },
};
