/* eslint-disable max-len */
import { SnackbarProgrammatic as Snackbar } from 'buefy';
import { dispatchAndForward, dispatchFailure, snackBarFailure } from '../$utils/helpers';

export default {
  getters: {
    templateList: (_state, _getters, _rootState, rootGetters) => {
      const { 'Templates/getterTemplateList': templateList } = rootGetters;
      return templateList;
    },
  },
  actions: {
    createTemplate({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'Templates/post',
        context: { payload, httpQuery },
        config: {
          start: false,
          end: true,
          endContent: 'Template created successfully',
          error: 'Unable to create template',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    patchTemplate({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'Templates/patch',
        context: { payload, httpQuery },
        config: {
          start: false,
          end: true,
          endContent: 'Template updated successfully',
          error: 'Unable to updated template',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    deleteTemplate({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'Templates/delete',
        context: { payload, httpQuery },
        config: {
          start: false,
          end: true,
          endContent: 'Template deleted successfully',
          error: 'Unable to delete this template',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
  },
};
