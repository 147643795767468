var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content is-flex is-align-items-center"},[(_vm.img)?_c('div',{class:['profile-picture item size', {
      'is-8x8': _vm.size === 8,
      'is-16x16': _vm.size === 16,
      'is-18x18': _vm.size === 18,
      'is-24x24': _vm.size === 24,
      'is-32x32': _vm.size === 32,
      'is-40x40': _vm.size === 40,
      'is-48x48': _vm.size === 48,
      'is-64x64': _vm.size === 64,
      'is-96x96': _vm.size === 96,
      'is-128x128': _vm.size === 128,
    }],style:(`background:url('${_vm.img}');`),on:{"click":function($event){return _vm.$emit('click')}}}):_c('InitialsPicture',{staticClass:"item",attrs:{"name":_vm.name,"size":_vm.size},on:{"click":function($event){return _vm.$emit('click')}}}),_c('div',{staticClass:"ml-5"},[_c('p',{staticClass:"subtitle is-big cursor-pointer p-0 m-0",on:{"click":function($event){return _vm.$emit('click')}}},[_vm._v(" "+_vm._s(_vm.name)+" ")]),_c('p',{staticClass:"disclamer is-small has-text-secondary m-0"},[_vm._v(_vm._s(_vm.location))]),_c('p',{staticClass:"subtext is-small has-text-secondary"},[_vm._v(_vm._s(_vm.jobTitle))])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }