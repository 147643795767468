<template>
  <section
    id="TheMessaging"
    class="dashboard"
  >
    <div class="content">
      <NoData
        title="Messaging is coming soon"
        content="Stay patient, we are making it to the smallest detail"
        class="noMessaging"
        action
        :buttons-action="buttonsAction"
        @click="handleClick()"
      />
    </div>
  </section>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */
import SocketMixins from '@jack/socket/$utils/SocketMixins.mixins';
import { mapActions } from 'vuex';

export default {
  name: 'TheMessaging',
  mixins: [SocketMixins],
  computed: {
    buttonsAction() {
      return [
        {
          buttonType: 'event', label: 'Emit event socket', type: 'is-primary', event: 'click',
        },
      ];
    },
  },
  methods: {
    ...mapActions({ IOMq: 'TheMessaging/IOMq' }),
    handleClick() {
      this.IOMq({ events: 'public.notifications.refresh.user:1,12' });
    },
  },
};
</script>
