export default {
  methods: {
    moveUp(obj, arr) {
      if (obj.order + 1 > arr.length + 1) return obj;
      return { ...obj, order: obj.order + 1 };
    },
    moveDown(obj) {
      if (obj.order === 1) return obj;
      return { ...obj, order: obj.order - 1 };
    },
    moveElement({
      arr, id, from, to, key,
    }) {
      const type = from < to;
      const toChange = arr.slice(
        type ? from - 1 : to - 1,
        type ? to : from,
      );
      return {
        before: arr.slice(0, type ? from - 1 : to - 1),
        change: toChange.reduce((acc, el) => {
          if (Number(el[key]) === Number(id)) {
            acc.push({ ...el, order: to });
            return acc;
          }
          acc.push(type ? this.moveDown(el) : this.moveUp(el, toChange.length));
          return acc;
        }, []),
        after: arr.slice(
          type ? to : from,
          arr.length + 1,
        ),
      };
    },
    cleanColumnOfItem({
      arr, id, key, target,
    }) {
      if (!arr || !arr.length) return [];
      return arr
        .reduce((acc, column) => {
          const { kcid, items } = column;
          const isIn = !!items.find((el) => Number(el[key]) === Number(id));
          const isTarget = column.order === target;
          if (isIn && !isTarget) {
            const newItems = items.filter((el) => el[key] !== Number(id));
            acc[kcid] = { ...column, items: newItems };
            return acc;
          }
          acc[kcid] = { ...column };
          return acc;
        }, {});
    },
    addItem({
      arr, oTarget, item,
    }) {
      if (!arr || !arr.length) return [];
      return arr
        .reduce((acc, el) => {
          if (el.order === oTarget) acc.push({ ...item, order: oTarget });
          if (el.order >= oTarget) {
            acc.push(this.moveUp(el, arr.length));
            return acc;
          }
          acc.push(el);
          return acc;
        }, [])
        .sort((a, b) => a.order - b.order);
    },
    moveItem(options) {
      const { columns, item, pos: { column: cPos, card: iPos, id: cId } } = options;
      let updatedColumns = { ...columns };

      const { kanbanColumnKcid: kcid } = item;
      const { order: cOrder } = updatedColumns[kcid];

      const cleanedColumn = this.cleanColumnOfItem({
        arr: Object.values(updatedColumns), order: item.order, id: item.kiid, key: 'kiid', target: cPos,
      });

      if (cPos !== cOrder) {
        const updatedItems = this.addItem({
          arr: cleanedColumn[cId].items, oTarget: iPos, key: 'kiid', item, iOrder: updatedColumns[kcid].order, cPos,
        });

        cleanedColumn[cId].items = updatedItems;
        updatedColumns = cleanedColumn;
      } else {
        const updatedItems = this.moveElement({
          arr: cleanedColumn[cId].items, id: item.kiid, from: item.order, to: iPos, key: 'kiid',
        });
        cleanedColumn[cId].items = [
          ...updatedItems.before,
          ...updatedItems.change,
          ...updatedItems.after,
        ];
        updatedColumns = cleanedColumn;
      }

      return Object.values(updatedColumns);
    },
  },
};
