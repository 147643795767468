import TheFirstConnection from './TheFirstConnection';
import TheFirstConnectionWelcome from './TheFirstConnectionWelcome';
import TheFirstConnectionChangePassword from './TheFirstConnectionChangePassword';

export default [
  {
    path: '/first-connection',
    redirect: '/first-connection/welcome',
  },
  {
    path: '/first-connection',
    name: 'First connection',
    component: TheFirstConnection,
    meta: {
      hasMenu: false,
      viewStoreNavigation: 'TheFirstConnection',
      requireAuth: true,
    },
    children: [
      {
        path: '/first-connection/welcome',
        name: 'First connection - Welcome',
        component: TheFirstConnectionWelcome,
        meta: {
          hasMenu: false,
          viewStoreNavigation: 'TheFirstConnection',
          requireAuth: true,
        },
      },
      {
        path: '/first-connection/change-password',
        name: 'First connection - Change password',
        component: TheFirstConnectionChangePassword,
        meta: {
          hasMenu: false,
          viewStoreNavigation: 'TheFirstConnection',
          requireAuth: true,
        },
      },
    ],
  },
];
