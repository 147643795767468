<template>
  <article
    :class="['ws-notifier', {
      'ws-n-success': notif.type === 'success',
      'ws-n-danger': notif.type === 'danger',
      'ws-n-info': notif.type === 'info',
      'ws-n-warning': notif.type === 'warning',
      'ended': progressWidth >= 100 || ended
    }]"
  >
    <div class="ws-close">
      <b-button
        type="is-tertiary ultra-small"
        icon-pack="fa"
        icon-left="xmark"
        @click="closeNotif(1000); ended = true"
      />
    </div>
    <div class="ws-contents">
      <div class="ws-icon">
        <b-icon
          pack="fa"
          size="is-large"
          :icon="icons[notif.type]"
        />
      </div>
      <div class="ws-content">
        <p
          :class="['subtext is-big', {

            'text-color-5': notif.type === 'success',
            'text-color-2': notif.type === 'danger',
            'text-color-7': notif.type === 'info',
            'text-color-3': notif.type === 'warning',
          }]"
        >
          {{ notif.title }}
        </p>
        <p
          :class="['disclamer is-small', {

            'text-color-5': notif.type === 'success',
            'text-color-2': notif.type === 'danger',
            'text-color-7': notif.type === 'info',
            'text-color-3': notif.type === 'warning',
          }]"
        >
          {{ notif.content }}
        </p>
      </div>
    </div>
    <div
      :class="['bar-time-container', {
        'bg-color-5-40': notif.type === 'success',
        'bg-color-2-40': notif.type === 'danger',
        'bg-color-7-40': notif.type === 'info',
        'bg-color-3-40': notif.type === 'warning',
      }]"
    >
      <div
        :class="['bar-time', {
          'bg-color-5-90': notif.type === 'success',
          'bg-color-2-90': notif.type === 'danger',
          'bg-color-7-90': notif.type === 'info',
          'bg-color-3-90': notif.type === 'warning',
        }]"
        :style="`width:${progressWidth}%`"
      />
    </div>
  </article>
</template>

<script>
export default {
  name: 'WsNotifierCard',
  props: {
    notif: { type: Object, required: true },
  },
  data() {
    return {
      ended: false,
      progressWidth: 0, // initial width = 0
      duration: 1 * 5000, // concert duration, ms
      start: (new Date()).valueOf(), // valueOf returns number of ms -> easy calculations
      icons: {
        success: 'circle-check',
        danger: 'circle-xmark',
        info: 'circle-info',
        warning: 'circle-exclamation',
      },
    };
  },
  computed: {
    end() {
      return this.start + this.duration;
    },
  },
  mounted() {
    this.startEvent(this.start, this.end);
    this.closeNotif(this.duration + 1000);
  },
  methods: {
    startEvent(start, end) {
      const int = setInterval(() => {
        const now = (new Date()).valueOf();
        if (now >= end) {
          this.progressWidth = 100;
          clearInterval(int); return;
        }
        this.progressWidth = ((now - start) / (end - start)) * 100;
      }, 10);
    },
    closeNotif(time) {
      setTimeout(() => {
        this.$emit('deleteNotif', this.notif);
      }, time);
    },
  },
};
</script>
