<template>
  <article class="appointment-token-card">
    <DevDetails :cnt="{name: 'appointmentToken', obj: appointmentToken}" />
    <div class="appointment-token-content">
      <div class="appointment-token-infos">
        <b-tooltip
          type="is-dark"
          :label="isExploited || isExpired ? 'Close' : 'Open'"
          position="is-right"
        >
          <b-icon
            pack="fa"
            :icon="isExploited || isExpired ? 'lock' : 'unlock'"
            :class="[{
              'text-color-2': isExploited || isExpired,
              'text-color-5': !isExploited && !isExpired,
            }]"
          />
        </b-tooltip>
        <template v-if="isExploited">
          <b-tooltip
            type="is-dark"
            :label="!isExploited ? 'Has not been exploited' : 'Exploited'"
            position="is-right"
          >
            <b-icon
              pack="fa"
              icon="circle-check"
              :class="[{
                'text-color-5': isExploited,
              }]"
            />
          </b-tooltip>
        </template>
        <div>
          <p class="subtext is-small m-0">{{ appointmentToken.label }}</p>
          <p
            v-if="appointmentToken.description"
            class="subtext is-small has-text-secondary m-0"
          >
            {{ appointmentToken.description }}
          </p>
        </div>
      </div>
      <div class="appointment-token-actions">
        <div class="buttons is-flex-wrap-nowrap">
          <b-tooltip
            type="is-dark"
            label="Copy link"
            position="is-left"
          >
            <b-button
              type="is-tertiary"
              icon-pack="fa"
              icon-left="copy"
              :disabled="isExploited || isExpired"
              @click="copy()"
            />
          </b-tooltip>
          <b-tooltip
            type="is-dark"
            label="Send per email"
            position="is-left"
          >
            <b-button
              tag="a"
              type="is-tertiary"
              icon-pack="fa"
              icon-left="envelope"
              class="w-100"
              :href="mailContent"
              :disabled="!entite.mail"
            />
          </b-tooltip>
          <b-tooltip
            type="is-dark"
            label="Delete token"
            position="is-left"
          >
            <b-button
              type="is-tertiary"
              icon-pack="fa"
              icon-left="trash"
              class="text-color-2"
              @click="$emit('deleteAppointmentToken', { atid: appointmentToken.atid })"
            />
          </b-tooltip>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
/* eslint-disable max-len */
import { mapActions } from 'vuex';

export default {
  name: 'AppointmentTokenInfo',
  props: {
    appointmentToken: { type: Object, required: true },
    entite: { type: Object, required: true },
  },
  computed: {
    isExpired() {
      const today = this.$moment();
      return this.$moment(this.appointmentToken.expireAt).isBefore(today);
    },
    isExploited() { return this.appointmentToken.exploited; },
    formatQueries() {
      const config = [
        'label',
        'description',
        'dateRange',
        'maxDate',
        'noEndDate',
        'duration',
        'expireAt',
        'mail',
        'number',
      ];
      const keys = Object.keys(this.appointmentToken);
      return config.reduce((acc, key) => {
        if (!keys.includes(key)) return acc;
        if (this.appointmentToken[key] === null) return acc;
        if (key === 'maxDate' || key === 'dateRange') {
          acc.push(`${key}=${this.appointmentToken[key].split(';').join(',')}`);
          return acc;
        }
        acc.push(`${key}=${this.appointmentToken[key]}`);
        return acc;
      }, []);
    },
    generateUrl() {
      const { token } = this.appointmentToken;
      return `${process.env.VUE_APP_URL}/schedule-appointment?v=${this.formatQueries.join(';')};t=${token}`;
    },
    mailContent() {
      if (!this.entite.details.email) return '';
      const mail = this.entite.details.email;
      const subject = 'Reserve a time slot for an appointment.';
      const body = `Hello,%0D%0A%0D%0AHere is a link to book an appointment. See you soon. %0D%0A%0D%0A${this.generateUrl}`;

      return `mailto:${mail}?subject=${subject}&body=${body}`;
    },
  },
  methods: {
    ...mapActions({ pushWsNotification: 'TheWsNotifier/push' }),
    copy() {
      navigator.clipboard.writeText(this.generateUrl);
      this.pushWsNotification({ type: 'info', title: 'Schedule appointment link copied!' });
    },
  },
};
</script>
