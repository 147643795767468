<template>
  <section>
    <div class="multibar">
      <h3 class="start title is-small">
        Add social media of the new client
      </h3>
    </div>

    <br>

    <div class="buttons">
      <b-button
        label="Add new social media"
        type="is-dashed"
        icon-pack="fas"
        icon-left="plus"
        expanded
        @click="addNewSocialMedia()"
      />
    </div>

    <br>

    <template v-if="newClient && newClient.socialList.length">
      <div class="multibar">
        <h3 class="start subtitle is-small">
          Selected social networks
        </h3>
      </div>

      <br>
      <DevDetails :cnt="{name: 'newClient', obj: newClient}" />

      <template v-for="(social, i) in newClient.socialList">
        <div
          v-if="social"
          :key="social.id"
        >
          <DevDetails :cnt="{name: 'social', obj: social}" />
          <div
            v-if="newClient.socialList[i]"
            class="card transparent"
          >
            <div class="card-content">
              <div class="is-flex is-align-items-center is-justify-content-space-between">
                <h4 class="subtitle is-small m-0">New social media</h4>
                <div class="buttons">
                  <b-button
                    class="is-ghost m-0"
                    icon-pack="fas"
                    icon-right="times"
                    @click="$emit('deleteSocial', social)"
                  />
                </div>
              </div>
              <fieldset>
                <b-field
                  label="Select a platform"
                  horizontal
                >
                  <b-select
                    v-model="newClient.socialList[i].platformName"
                    expanded
                  >
                    <option
                      v-for="platform in socialList"
                      :key="platform"
                      :value="platform"
                    >
                      {{ platform.capitalize() }}
                    </option>
                  </b-select>
                </b-field>
                <b-field
                  horizontal
                  label="Username"
                >
                  <b-input
                    v-model="newClient.socialList[i].username"
                    required
                  />
                </b-field>
              </fieldset>
            </div>
          </div>

          <br>
        </div>
      </template>
    </template>
  </section>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */
import VModelMixin from '../../../../$mixins/VModelMixin';

export default {
  name: 'JackSidePanelAddClientSocialForm',
  mixins: [VModelMixin],
  model: {
    prop: 'newClient',
  },
  props: {
    newClient: { type: Object, default: () => {} },
  },
  computed: {
    socialList() {
      return ['INSTAGRAM', 'TIKTOK', 'FACEBOOK', 'SNAPCHAT', 'TWITTER', 'LINKEDIN'];
    },
  },
  methods: {
    addNewSocialMedia() {
      this.newClient.socialList.push({
        id: this.newClient.socialList.length,
        platformName: null,
        username: null,
      });
    },
  },
};
</script>
