/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import { navySeal } from '@jack/provider';

export default {
  namespaced: true,
  state: {
    bookAccount: null,
  },
  mutations: {
    SET_BOOK_ACCOUNT(state, newData) { state.bookAccount = newData; },
  },
  getters: {
    getterBookAccount: (state) => state.bookAccount,
  },
  actions: {
    get({ commit }, { payload }) {
      return navySeal.apiWsDivision.user.bookAccount.get({ payload, httpQuery: {} })
        .then((bookAccount) => {
          commit('SET_BOOK_ACCOUNT', bookAccount);
        });
    },
    put({ commit }, { payload }) {
      return navySeal.apiWsDivision.user.bookAccount.put({ payload, httpQuery: {} })
        .then((bookAccount) => {
          commit('SET_BOOK_ACCOUNT', bookAccount);
        });
    },
  },
};
