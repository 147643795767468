<template>
  <section v-if="clients">
    <div class="multibar">
      <h3 class="start title is-small">
        Select a project
      </h3>
    </div>
    <DevDetails :cnt="{name: 'newPayment', obj: newPayment}" />
    <DevDetails :cnt="{name: 'projectSelected', obj: projectSelected}" />
    <DevDetails :cnt="{name: 'projects', obj: projects}" />
    <DevDetails :cnt="{name: 'selected', obj: selected}" />

    <br>

    <b-field>
      <b-input
        v-model="inputSearch"
        placeholder="Search..."
        type="search"
        icon-pack="fas"
        icon="search"
      />
    </b-field>

    <br>

    <template v-if="projectSelected">
      <div>
        <h4 class="start title is-small">Project selected of </h4>

        <br>

        <Table
          v-if="Object.values(projectSelected)"
          :data="projectSelected"
          :loading="loading"
          :columns="Object.values(columns)"
          :total="total"
          :page="page"
          :per-page.sync="perPage"
          :sort-field="sortField"
          :sort-order="sortOrder"
          :default-sort-order="defaultSortOrder"
          :selected.sync="rowSelected"
          :checked-rows.sync="checkedRows"
          :checkable="false"
          :sticky-header="false"
          detail-key="cid"
        />
      </div>
    </template>

    <br>

    <div class="jackTableAddProject">
      <h4 class="start title is-small">All projects</h4>

      <br>

      <Table
        v-if="projectFetchedFormated.length"
        :data="projectFetchedFormated"
        :loading="loading"
        :columns="Object.values(columns)"
        :total="total"
        :page="page"
        :per-page.sync="perPage"
        :sort-field="sortField"
        :sort-order="sortOrder"
        :default-sort-order="defaultSortOrder"
        :selected.sync="rowSelected"
        :checked-rows.sync="checkedRows"
        :checkable="false"
        :sticky-header="false"
        detail-key="cid"
        @selectProject="selectProject($event)"
      />
      <template v-else>
        <NoData
          title="No projects were found"
          action
          :buttons-action="buttonsAction"
          @reset="inputSearch = ''"
        />
        <br>

        <div class="multibar">
          <b-button
            label="Create a new project"
            class="end"
            tag="router-link"
            :to="{
              path: '/home/sidePanel/addProject',
              query: $route.query
            }"
            type="is-primary"
            icon-pack="fas"
            icon-left="plus"
          />
        </div>
      </template>
    </div>
  </section>
</template>

<script>
/* eslint-disable max-len */
/* eslint-disable-next-line */
import { debounce } from '@jack/addons/$utils/helpers';
import projectSelectColumns from '../../../$mixins/projectSelectTable.columns.mixins';

export default {
  name: 'JackSidePanelAddPaymentProjectForm',
  mixins: [projectSelectColumns],
  model: {
    prop: 'newPayment',
  },
  props: {
    selected: { type: Object, default: () => {} },
    newPayment: { type: Object, required: true },
    projects: { type: Array, required: true },
    clients: { type: Array, required: true },
  },
  data() {
    return {
      loading: false,
      total: 0,
      page: 0,
      perPage: 20,
      sortField: 'cpid',
      sortOrder: 'asc',
      defaultSortOrder: 'asc',
      rowSelected: {},
      checkedRows: [],
      inputSearch: '',
      search: '',
      projectSelected: null,
    };
  },
  computed: {
    localNewPayment: {
      get() { return this.newPayment; },
      set(newValue) { this.$emit('update:newPayment', newValue); },
    },
    selectedFormated() {
      if (!Object.values(this.selected).length) return [];
      const { details, ...rest } = this.selected;
      return [
        { ...rest, ...details },
      ];
    },
    projectFetchedFormated() {
      if (!this.projects) return [];

      const query = this.projects.reduce((acc, project) => {
        const {
          label, description, price, isOpen,
        } = project;

        if (!isOpen) return acc;

        if (this.search) {
          const labelFind = label ? !!label.toLowerCase().includes(this.search.toLowerCase()) : false;
          const descriptionFind = description ? !!description.toLowerCase().includes(this.search.toLowerCase()) : false;
          const priceFind = price ? !!toString(price).includes(this.search) : false;

          if (labelFind || descriptionFind || priceFind) {
            acc.push({ ...project });
          }
        } else {
          acc.push({ ...project });
        }
        return acc;
      }, []);

      return query;
    },
    buttonsAction() {
      return [
        {
          label: 'Reset filter', type: 'is-primary', event: 'reset', buttonType: 'event',
        },
      ];
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    inputSearch: debounce(function (newVal) {
      this.search = newVal;
    }, 500),
  },
  methods: {
    selectProject(row) {
      this.localNewPayment.project = row;
      this.projectSelected = [row];
    },
  },
};
</script>
