/* eslint-disable import/no-extraneous-dependencies */
import { navySeal } from '@jack/provider';
// import { isActionAllowed } from '@jack/dataStores/$utils/helpers';

export default {
  namespaced: true,
  state: {
    profile: {},
  },
  mutations: {
    SET_PROFILE(state, newData) { state.profile = newData; },
  },
  getters: {
    getterProfile: (state) => state.profile,
  },
  actions: {
    get({ commit }, { payload = {}, httpQuery = {} }) {
      // , rootGetters, dispatch
      // if (!isActionAllowed(rootGetters, 'INSTAGRAM.GET', dispatch)) return undefined;

      return navySeal.apiSocials.instagram.get({ payload, httpQuery })
        .then((profile) => commit('SET_PROFILE', profile));
    },
  },
  modules: {},
};
