/* eslint-disable max-len */
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      startLastMonth: this.$moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
      endLastMonth: this.$moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
      startCurrentMonth: this.$moment().startOf('month').format('YYYY-MM-DD'),
      endCurrentMonth: this.$moment().endOf('month').format('YYYY-MM-DD'),
    };
  },
  computed: {
    ...mapGetters({
      windowWidth: 'TheMenu/getterWindowWidth',
    }),
    labelOfCurrentAndLastMonth() {
      return Array(31).fill().reduce((acc, _, i) => {
        const _i = i + 1;
        acc.push(`${this.$_('D.')}${_i.toString()}`);
        return acc;
      }, []);
    },
    labelOfCurrentWeek() {
      const startWeek = moment().startOf('week');
      return Array(7).fill().reduce((acc, _, i) => {
        acc.push(moment(startWeek).add(i, 'days').format('MMM Do'));
        return acc;
      }, []);
    },
    gridResponsive() {
      const { windowWidth } = this;
      if (windowWidth <= 600) return 1;
      if (windowWidth <= 1200) return 2;
      return 4;
    },
    gridResponsiveChart() {
      const { windowWidth } = this;
      if (windowWidth <= 1300) return 1;
      return 2;
    },
  },
  methods: {
    formatDataCurrentAndLastMonth(datas) {
      if (!datas) return null;

      return datas.reduce((acc, row) => {
        if (moment(row.createdAt).isBetween(this.startLastMonth, this.endLastMonth)) acc.lastMonth.push(row);
        if (moment(row.createdAt).isBetween(this.startCurrentMonth, this.endCurrentMonth)) acc.currentMonth.push(row);
        return acc;
      }, {
        lastMonth: [],
        currentMonth: [],
      });
    },
    datasOfThisCurrentWeek(datas, key = 'createdAt') {
      if (!datas || !datas.length) return null;
      const start = moment(moment().startOf('week')).format('YYYY-MM-DD');
      return datas.reduce((acc, row) => {
        const date = moment(row[key]);

        if (date.isSame(start) || date.isAfter(start)) {
          if (acc[date.format('MMM Do')]) acc[date.format('MMM Do')].push(row);
          else acc[date.format('MMM Do')] = [row];
          return acc;
        }
        return acc;
      }, {});
    },
    contactHistoryOfThisCurrentWeekGraph(datas, key) {
      if (!datas) return null;
      return this.labelOfCurrentWeek.reduce((acc, el) => {
        if (!datas[el]) {
          acc.push(null);
          return acc;
        }
        const filter = datas[el].filter(({ state }) => state === key);
        acc.push(filter.length ? filter.length : null);
        return acc;
      }, []);
    },
    financialDataFormatting(entity) {
      let lastMonthTurnover = 0;
      let currentMonthTurnover = 0;

      const datasLastMonth = entity.lastMonth.reduce((acc, row) => {
        const { deposit } = row;
        lastMonthTurnover += deposit;
        acc.push(row);
        return acc;
      }, []);

      const datasCurrentMonth = entity.currentMonth.reduce((acc, row) => {
        const { deposit } = row;
        currentMonthTurnover += deposit;
        acc.push(row);
        return acc;
      }, []);

      return {
        percent: (currentMonthTurnover / lastMonthTurnover) * 100 - 100,
        lastMonth: lastMonthTurnover,
        currentMonth: currentMonthTurnover,
        datasLastMonth,
        datasCurrentMonth,
      };
    },
    entityFormatting(entity) {
      const lastMonth = entity.lastMonth.length;
      const currentMonth = entity.currentMonth.length;

      return {
        percent: (currentMonth / lastMonth) * 100 - 100,
        lastMonth,
        currentMonth,
      };
    },
    financialDataGraph(entity) {
      return Array(31).fill().reduce((acc, _, i) => {
        let sum = 0;
        const _i = i + 1;
        const filterPaymentsOfDay = entity.filter(({ createdAt }) => Number(this.$moment(createdAt).format('D')) === _i);

        if (!filterPaymentsOfDay.length) {
          if (_i === 1) acc.push(0);
          else acc.push(null);
          return acc;
        }

        /* eslint-disable-next-line */
            filterPaymentsOfDay.forEach(({ deposit }) => sum += deposit);
        acc.push(sum);

        return acc;
      }, []);
    },
    entityDataGraph(entity) {
      return Array(31).fill().reduce((acc, _, i) => {
        let sum = 0;
        const _i = i + 1;
        const filterOfDay = entity.filter(({ createdAt }) => Number(this.$moment(createdAt).format('D')) === _i);

        if (!filterOfDay.length) {
          if (_i === 1) acc.push(0);
          else acc.push(null);
          return acc;
        }

        /* eslint-disable-next-line */
        sum += filterOfDay.length;
        acc.push(sum);

        return acc;
      }, []);
    },
  },
};
