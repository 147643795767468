<template>
  <section class="form-add template w-100">
    <div class="content-form">
      <form @submit.prevent="_createTemplate()">
        <b-field
          label="Subject"
          class="bg-solitude"
        >
          <b-input v-model="template.subject" />
        </b-field>

        <br>

        <b-field
          label="Content"
          class="bg-solitude"
        >
          <b-input
            v-model="template.content"
            type="textarea"
            required
          />
        </b-field>

        <br>

        <div class="buttons end">
          <b-button
            type="is-primary"
            icon-pack="fa"
            icon-left="check"
            native-type="submit"
            expanded
            :disabled="!template.subject || !template.content"
          />
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TheTemplatesAdd',
  data() {
    return {
      open: null,
      template: {
        subject: null,
        content: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      accountAid: 'TheTemplates/currentAccountAid',
    }),
  },
  methods: {
    ...mapActions({
      createTemplate: 'TheTemplates/createTemplate',
    }),
    _createTemplate() {
      return Promise.resolve()
        .then(() => this.createTemplate({
          payload: {
            ...this.template,
            accountAid: this.accountAid,
            createdAt: this.$moment().format(),
            editedAt: null,
          },
        }))
        .then(() => this.$router.push({ path: '/templates' }));
    },
  },
};
</script>
