var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"company-employees-container"},[_c('div',{staticClass:"company-employees-header"},[_c('div',{staticClass:"company-profile-picture"},[(_vm.client.company.logo)?_c('div',{class:['profile-picture item size', {
          'is-8x8': _vm.size === 8,
          'is-16x16': _vm.size === 16,
          'is-18x18': _vm.size === 18,
          'is-24x24': _vm.size === 24,
          'is-32x32': _vm.size === 32,
          'is-40x40': _vm.size === 40,
          'is-48x48': _vm.size === 48,
          'is-64x64': _vm.size === 64,
          'is-96x96': _vm.size === 96,
          'is-128x128': _vm.size === 128,
        }],style:(`background:url('${_vm.client.company.logo}')`)}):_c('InitialsPicture',{staticClass:"item",attrs:{"name":_vm.client.company.name,"size":_vm.size}})],1),(_vm.displayEmployees)?_c('div',{staticClass:"employees-profile-picture"},[_vm._l((_vm.client.persons),function(person,index){return [(person && index <= 2)?_c('b-tooltip',{key:person.pid,staticClass:"picture",attrs:{"type":"is-dark","label":person.fullName ? person.fullName : person.details.fullName,"position":"is-bottom","append-to-body":""}},[(_vm.isClickable)?[_c('router-link',{attrs:{"to":`/networking/contacts/${person.pid}/details`}},[(person.imageUrl)?_c('div',{staticClass:"profile-picture item size is-32x32 cursor-pointer",style:(`background:url('${person.imageUrl}')`)}):_c('InitialsPicture',{staticClass:"item cursor-pointer",attrs:{"name":person.fullName ? person.fullName : person.details.fullName,"size":32}})],1)]:[(person.imageUrl)?_c('div',{class:['profile-picture item size is-32x32', {
                'is-8x8': _vm.size === 8,
                'is-16x16': _vm.size === 16,
                'is-18x18': _vm.size === 18,
                'is-24x24': _vm.size === 24,
                'is-32x32': _vm.size === 32,
                'is-40x40': _vm.size === 40,
                'is-48x48': _vm.size === 48,
                'is-64x64': _vm.size === 64,
                'is-96x96': _vm.size === 96,
                'is-128x128': _vm.size === 128,
              }],style:(`background:url('${person.imageUrl}')`)}):_c('InitialsPicture',{staticClass:"item",attrs:{"name":person.fullName ? person.fullName : person.details.fullName,"size":32}})]],2):_vm._e()]})],2):_vm._e()]),_c('div',{staticClass:"company-employees-content ml-5"},[(_vm.isClickable)?[_c('router-link',{attrs:{"to":`/networking/companies/${_vm.client.company.cid}/details`}},[_c('p',{staticClass:"subtitle is-big p-0 m-0 cursor-pointer"},[_vm._v(" "+_vm._s(_vm.client.company.name)+" ")]),(_vm.client.company.description)?_c('p',{staticClass:"disclamer is-small has-text-secondary m-0 cursor-pointer"},[_vm._v(" "+_vm._s(_vm.client.company.description.slice(0, 100))+"... ")]):_vm._e()])]:[_c('p',{staticClass:"subtitle is-big p-0 m-0"},[_vm._v(" "+_vm._s(_vm.client.company.name)+" ")]),(_vm.client.company.description)?_c('p',{staticClass:"disclamer is-small has-text-secondary m-0"},[_vm._v(" "+_vm._s(_vm.client.company.description.slice(0, 100))+"... ")]):_vm._e()]],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }