<template>
  <section
    id="TheJobs"
    class="dashboard"
  >
    <div class="content">
      <router-view name="alert" />
      <router-view name="management" />
      <router-view name="liked" />
      <router-view name="companies" />
      <router-view name="contacts" />
    </div>
  </section>
</template>

<script>

export default {
  name: 'TheNetworking',
};
</script>
