export default {
  data() {
    return {
      hide: false,
    };
  },
  methods: {
    next() {
      this.hide = true;
      setTimeout(() => {
        this.$emit('next');
      }, this.ms);
    },
  },
};
