<template>
  <section class="form-add">
    <div class="content-form">
      <h1 class="title is-small">
        Create contact by instagram
      </h1>
      {{ profileInstagram }}
      <form
        @submit.prevent="fetchProfileInstagram()"
      >
        <b-field
          label="Username"
          class="bg-solitude w-100"
        >
          <b-input
            v-model="username"
            expanded
            required
          />
        </b-field>

        <br>

        <div class="buttons">
          <b-button
            native-type="submit"
            type="is-primary"
            icon-pack="fa"
            icon-left="check"
            expanded
            :disabled="!username"
          />
        </div>
      </form>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AddContactByInstagram',
  props: {
    profileInstagram: { type: Object, default: () => {} },
  },
  data() {
    return {
      username: null,
    };
  },
  methods: {
    fetchProfileInstagram() {
      this.$emit('fetchProfileInstagram', { username: this.username });
    },
  },
};
</script>
