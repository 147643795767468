import { dispatchAndForward, dispatchFailure } from '../../$utils/helpers';

export default {
  forceRefresh: ({ store }) => {
    const { getters, dispatch } = store;
    const { 'Authenticate/getterAuthenticate': authenticate } = getters;
    const { uid } = authenticate;

    const action = {
      dispatch,
      target: 'User/notification/report',
      context: {
        payload: { uid },
        httpQuery: {},
      },
      config: { start: false, end: false },
    };

    return dispatchAndForward(action)
      .catch(() => dispatchFailure(action));
  },
};
