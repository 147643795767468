<template>
  <article
    class="is-flex is-align-items-center mb-4"
  >
    <div class="is-flex is-align-items-center w-100">
      <div class="pa-container">
        <div class="calendar">
          <div class="calendar-month">
            <span>{{ $_($moment(payment.createdAt).format('MMM')) }}</span>
          </div>
          <div class="calendar-day">
            <span>{{ $moment(payment.createdAt).format('DD') }}</span>
          </div>
        </div>
      </div>
      <div class="pa-content">
        <p class="text">{{ payment.details.description }}</p>
        <p
          v-if="payment.project"
          class="subtext m-0"
        >
          {{ payment.project.details.label }}
        </p>
        <p
          v-if="payment.project"
          class="subtext has-text-secondary"
        >
          {{ payment.project.details.description }}
        </p>
      </div>
    </div>
    <div class="ml-5 is-block is-flex is-align-items-center">
      <template v-if="payment.project && payment.project.details.currency">
        <span class="text-color-5-60">
          {{ currencies[payment.project.details.currency].name }}
        </span>
      </template>
      <span class="bigtitle ml-3 no-lineh-bigtitle text-color-5">
        {{ format(Number(payment.details.deposit), 1) }}
      </span>
    </div>
    <div class="buttons">
      <b-button
        type="is-tertiary"
        icon-pack="fa"
        icon-left="trash"
        class="text-color-1-20 mx-3"
        @click="$emit('deletePayment', { pid: payment.pid })"
      />
    </div>
  </article>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */
import nFormatter from '@jack/addons/$mixins/nFormatter';

export default {
  name: 'PaymentCard',
  props: {
    payment: { type: Object, required: true },
  },
  computed: {
    currencies() {
      return [
        { name: '€', key: 'EUR' },
        { name: '$', key: 'USD' },
        { name: '£', key: 'GBP' },
        { name: '¥', key: 'JPY' },
      ].arrayMapper('key');
    },
  },
  methods: {
    format(nb) {
      return nFormatter(nb, 1);
    },
  },
};
</script>
