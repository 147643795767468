/* eslint-disable import/no-extraneous-dependencies */
import { navySeal } from '@jack/provider';
import { isActionAllowed } from '@jack/dataStores/$utils/helpers';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {},
  actions: {
    create({ commit, rootGetters, dispatch }, { payload = {}, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'FAVORITE.CREATE', dispatch)) return undefined;

      return navySeal.apiWsMi.company.favorite.post({ payload, httpQuery })
        .then((favorite) => {
          const { cid } = payload;
          const { 'Company/getterCompany': company } = rootGetters;

          commit('Company/UPDATE_COMPANY_IN_COMPANY_LIST', { cid, favorite }, { root: true });
          commit('Company/SET_COMPANY', { ...company, favorite }, { root: true });
        });
    },
    delete({ commit, rootGetters, dispatch }, { payload = {}, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'FAVORITE.DELETE', dispatch)) return undefined;

      return navySeal.apiWsMi.company.favorite.delete({ payload, httpQuery })
        .then(() => {
          const { cid } = payload;
          const { 'Company/getterCompany': company } = rootGetters;

          commit('Company/UPDATE_COMPANY_IN_COMPANY_LIST', { cid, favorite: null }, { root: true });
          commit('Company/SET_COMPANY', { ...company, favorite: null }, { root: true });
        });
    },
  },
};
