<template>
  <div class="paginations">
    <b-button
      type="is-tertiary"
      icon-pack="fa"
      icon-left="chevron-left"
      :disabled="offset === 0"
      @click="$emit('prev')"
    />
    <span class="currentPage">
      {{ page }}
    </span>
    <b-button
      type="is-tertiary"
      icon-pack="fa"
      icon-left="chevron-right"
      :disabled="!list.length || list.length < limit"
      @click="$emit('next')"
    />
  </div>
</template>

<script>
export default {
  name: 'Paginations',
  props: {
    offset: { type: Number, required: true },
    limit: { type: Number, required: true },
    page: { type: Number, required: true },
    list: { type: Array, required: true },
  },
};
</script>
