<template>
  <section class="mt">
    <div class="is-flex is-align-items-center">
      <b-input
        v-model="inputSearch"
        placeholder="Search employee"
        type="search"
        icon="magnifying-glass"
        icon-pack="fa"
        icon-right="xmark"
        icon-right-clickable
        class="w-100"
        @icon-right-click="inputSearch = ''"
      />
      <b-tooltip
        type="is-dark"
        label="Fetch employees"
        position="is-top"
      >
        <b-button
          type="is-tertiary"
          icon-pack="fa"
          :icon-left="!open ? 'plus' : 'minus'"
          class="ml-3"
          expanded
          @click="open = !open"
        />
      </b-tooltip>
    </div>
    <template v-if="open">
      <section
        v-if="!subSelected"
        class="client-add-choices side-panel mt-3"
      >
        <template v-for="choice in createChoices.contact.children">
          <ContactChoice
            v-if="choice.visible"
            :key="choice.key"
            :choice="choice"
            entite="subSelected"
            sidepanel
            @select="select($event)"
          />
        </template>
      </section>

      <section v-else>
        <br>
        <div class="multibar">
          <b-button
            class="start"
            icon-pack="fa"
            icon-left="arrow-left-long"
            type="is-tertiary"
            @click="reset()"
          />
        </div>

        <br>

        <template v-if="subSelected">
          <component
            :is="cmpsCreate[subSelected]"
            sidepanel
            :user-uid="userUid"
            :account-aid="accountAid"
            @contactList="$emit('contactList', $event); resetPanel()"
            @newContactByYourself="$emit('newContactByYourself', $event); resetPanel()"
          />
        </template>
      </section>
    </template>

    <br>
    <DevDetails :cnt="{name: 'personFiltered', obj: personFiltered}" />

    <template v-if="!open">
      <template v-if="personFiltered.length">
        <article
          v-for="person in personFiltered"
          :key="person.pid"
          class="person is-justify-content-center"
        >
          <div class="content is-flex is-align-items-center is-justify-content-space-between">
            <Contact
              v-if="person"
              :pid="person.pid"
              :details="person.details"
              :size="48"
            />

            <b-button
              v-if="company.client"
              type="is-tertiary"
              icon-pack="fa"
              icon-left="fire"
              :class="[{
                'text-color-1': !person.personOnFire,
                'text-color-3': person.personOnFire,
              }]"
              @click="togglePersonLinkedToClient(person)"
            />
          </div>
        </article>
      </template>
      <NoData
        v-else
        title="No employees are linked to this company... Yet!"
        content="You can link a contact to this company as an employee at any time"
        class="mt-5"
      />
    </template>
  </section>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable-next-line */
import { debounce } from '@jack/addons/$utils/helpers';
import TheClientsAddMixins from '../../clients/$mixins/TheClientsAddMixins.mixins';
import engine from '../$utils/engine';

const { valuesOfEntities } = engine();

export default {
  name: 'CompanyEmployees',
  mixins: [TheClientsAddMixins],
  props: {
    company: { type: Object, required: true },
    agentImportEmployees: { type: Object, required: true },
    userUid: { type: Number, default: null },
    accountAid: { type: Number, default: null },
  },
  data() {
    return {
      open: false,
      sessionCookie: '',
      activeStep: 0,
      search: '',
      inputSearch: '',
      urlNgrok: 'https://568c-80-119-178-45.eu.ngrok.io',
    };
  },
  computed: {
    agentImportEmployeesWithoutCompaniesToProcess() {
      const agent = this.agentImportEmployees;
      const steps = JSON.parse(this.agentImportEmployees.steps).steps.reduce((acc, step) => {
        if (step.slug === 'companies-to-process') return acc;
        acc.steps.push(step);
        return acc;
      }, { steps: [] });

      agent.steps = JSON.stringify(steps);
      return agent;
    },
    personFiltered() {
      if (!this.company.employees || !Object.values(this.company.employees).length) return [];
      const pidsInClientInsered = this.company.client
        ? this.company.client.persons.map(({ pid }) => pid)
        : [];

      if (!this.search) {
        return Object.values(this.company.employees).reduce((acc, el) => {
          const personOnFire = !!pidsInClientInsered.includes(el.pid);
          const value = valuesOfEntities(el, personOnFire ? 50 : 0);
          const args = { ...el, personOnFire, value };

          acc.push({ ...args });
          return acc;
        }, []).sort((a, b) => b.value - a.value);
      }

      return this.company.employees.reduce((acc, person) => {
        const search = this.search.toLowerCase();
        const _fullName = person.details.fullName.toLowerCase();
        const _jobTitle = person.details.jobTitle.toLowerCase();

        if (_fullName.includes(search) || _jobTitle.includes(search)) {
          const personOnFire = !!pidsInClientInsered.includes(person.pid);
          const value = valuesOfEntities(person, personOnFire ? 50 : 0);
          acc.push({ ...person, personOnFire, value });
        }
        return acc;
      }, []).sort((a, b) => b.value - a.value);
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    inputSearch: debounce(function (newVal) {
      this.search = newVal;
    }),
  },
  methods: {
    launchAgent(event) {
      if (!this.company.companyUrl) {
        this.$emit(
          'pushWsNotification',
          { type: 'danger', title: 'Linkedin company URL not found', content: 'This company don\'t have a Linkedin page...' },
        );
        return;
      }
      const [name] = this.company.companyUrl.split('/company/')[1].split('/');
      this.$emit('importEmployees', {
        ...event,
        spreadsheetUrl: `${this.urlNgrok}/api-ws-mi/bulkExportFromCsv?entite=company&slugs=/${name}`,
      });
      this.activeStep += 1;
    },
    togglePersonLinkedToClient(person) {
      if (!this.company.client) return;
      this.$emit('toggleLinkedPersonToClient', {
        method: person.personOnFire ? 'unlinkPerson' : 'linkPerson',
        personPid: person.pid,
        clientCid: this.company.client.cid,
      });
    },
    resetPanel() {
      this.open = false;
      this.selected = false;
      this.subSelected = false;
    },
  },
};
</script>
