/* eslint-disable no-multi-spaces */
import TheAuthenticate from './TheAuthenticate';

export default [
  {
    path: '/',
    redirect: '/auth',
  },
  {
    path: '/auth',
    component: TheAuthenticate,
    name: 'Login',
  },
];
