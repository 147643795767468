var render = function render(){var _vm=this,_c=_vm._self._c;return _c('article',{class:['cch', {
    'bg-danger': _vm.states[_vm.contactHistory.state].type === 'danger',
    'bg-warning': _vm.states[_vm.contactHistory.state].type === 'warning',
    'bg-success': _vm.states[_vm.contactHistory.state].type === 'success',
    'bg-info': _vm.states[_vm.contactHistory.state].type === 'info',
    'bg-linkedin': _vm.states[_vm.contactHistory.state].type === 'linkedin',
    'bg-mail': _vm.states[_vm.contactHistory.state].type === 'mail',
    'bg-facebook': _vm.states[_vm.contactHistory.state].type === 'facebook',
    'bg-twitter': _vm.states[_vm.contactHistory.state].type === 'twitter',
    'bg-telegram': _vm.states[_vm.contactHistory.state].type === 'telegram',
    'bg-whatsapp': _vm.states[_vm.contactHistory.state].type === 'whatsapp',
    'bg-slack': _vm.states[_vm.contactHistory.state].type === 'slack',
    'bg-discord': _vm.states[_vm.contactHistory.state].type === 'discord',
  }]},[_c('div',{staticClass:"icon ml-3"},[_c('b-icon',{class:[{
        'text-color-2': _vm.states[_vm.contactHistory.state].type === 'danger',
        'text-color-3': _vm.states[_vm.contactHistory.state].type === 'warning',
        'text-color-5': _vm.states[_vm.contactHistory.state].type === 'success',
        'text-white': _vm.states[_vm.contactHistory.state].type === 'info',
        'text-linkedin': _vm.states[_vm.contactHistory.state].type === 'linkedin',
        'text-mail': _vm.states[_vm.contactHistory.state].type === 'mail',
        'text-facebook': _vm.states[_vm.contactHistory.state].type === 'facebook',
        'text-twitter': _vm.states[_vm.contactHistory.state].type === 'twitter',
        'text-telegram': _vm.states[_vm.contactHistory.state].type === 'telegram',
        'text-whatsapp': _vm.states[_vm.contactHistory.state].type === 'whatsapp',
        'text-slack': _vm.states[_vm.contactHistory.state].type === 'slack',
        'text-discord': _vm.states[_vm.contactHistory.state].type === 'discord',
      }],attrs:{"pack":_vm.states[_vm.contactHistory.state].pack,"icon":_vm.states[_vm.contactHistory.state].icon,"size":"is-large"}})],1),_c('div',{staticClass:"w-100 ml-5"},[_c('p',{staticClass:"text is-small m-0"},[_vm._v(_vm._s(_vm.states[_vm.contactHistory.state].name))]),_c('div',{staticClass:"is-flex is-align-items-flex-start is-justify-content-flex-start"},[(_vm.displayCompany && _vm.company)?_c('article',{staticClass:"w-100"},[_c('Company',{staticClass:"mt-3",attrs:{"cid":_vm.contactHistory.companyCid,"details":_vm.company[_vm.contactHistory.companyCid].details}})],1):_vm._e(),_c('article',{staticClass:"w-100"},[(_vm.persons[_vm.contactHistory.personPid])?_c('Contact',{staticClass:"mt-3",attrs:{"pid":_vm.persons[_vm.contactHistory.personPid].pid,"details":_vm.persons[_vm.contactHistory.personPid].details}}):_vm._e()],1)]),_c('div',{staticClass:"multibar mt-3"},[_c('p',{staticClass:"end disclamer has-text-secondary"},[_vm._v(" "+_vm._s(_vm.$moment(_vm.contactHistory.createdAt).format('MMMM Do, YYYY - HH:mm:ss'))+" ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }