<template>
  <section>
    <div class="is-flex is-align-items-center is-justify-content-flex-end">
      <b-tooltip
        type="is-dark"
        :label="!open ? $_('Record a contact') : $_('Close')"
        position="is-left"
      >
        <b-button
          type="is-tertiary"
          icon-pack="fa"
          :icon-left="!open ? 'plus' : 'xmark'"
          expanded
          :class="[{
            'turn-signal': !entite.histories
          }]"
          @click="open = !open"
        />
      </b-tooltip>
    </div>
    <DevDetails :cnt="{name: 'historySorted', obj: historySorted}" />
    <div class="company-contact-history">
      <div class="histories-container">
        <AddContactHistory
          v-if="open"
          :open="open"
          :states="states"
          :person-list="personList || []"
          :options="options"
          @addContactHistory="$emit('addContactHistory', $event)"
          @close="open = false"
        />

        <ContactHistoryList
          v-else
          :history-sorted="historySorted"
          :states="states"
          :person-list="personList"
          class="mt-5"
          @deleteContactHistory="$emit('deleteContactHistory', $event)"
        />
      </div>
    </div>
  </section>
</template>

<script>
/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import contactHistoryState from '@jack/addons/common/$mixins/contactHistoryState.mixins';

export default {
  name: 'ContactHistory',
  mixins: [contactHistoryState],
  props: {
    entite: { type: Object, required: true },
    historySorted: { type: Array, default: () => [] },
    personList: { type: Array, default: () => [] },
    options: { type: Object, default: () => ({ search: true }) },
  },
  data() {
    return {
      open: false,
    };
  },
};
</script>
