export default {
  namespaced: true,
  state: {
    user: null,
  },
  mutations: {
    SET_PHANTOMBUSTER_USER(state, newData) { state.user = newData; },
  },
  getters: {
    getterOrganization: (state) => state.user,
  },
  actions: {},
};
