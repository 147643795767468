<template>
  <div>
    <div class="multibar">
      <h3 class="start title is-small">
        Insert information of the new client
      </h3>
    </div>

    <br>

    <fieldset>
      <b-field
        horizontal
        label="First name"
      >
        <b-input
          v-model="newClient.details.firstName"
          required
        />
      </b-field>
      <b-field
        horizontal
        label="Last name"
      >
        <b-input
          v-model="newClient.details.lastName"
          required
        />
      </b-field>
      <b-field
        horizontal
        label="Number"
      >
        <vue-tel-input
          v-model="newClient.details.number"
          default-country="fr"
          :preferred-countries="preferredCountries"
          :input-options="inputOptions"
          :auto-format="false"
          invalid-msg="Invalid phone number"
          valid-characters-only
          @country-changed="changeCodeCountry($event)"
          @validate="validPhoneNumberMethod($event)"
        />
      </b-field>
    </fieldset>
  </div>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */
import VModelMixin from '../../../../$mixins/VModelMixin';

export default {
  name: 'JackSidePanelAddClientInformationForm',
  mixins: [VModelMixin],
  model: {
    prop: 'newClient',
  },
  props: {
    newClient: { type: Object, default: () => {} },
  },
  data() {
    return {
      validPhoneNumber: null,
    };
  },
  computed: {
    preferredCountries() {
      return ['fr'];
    },
    inputOptions() {
      return {
        placeholder: 'Enter a phone number',
        required: true,
        showDialCode: true,
        mode: 'national',
        styleClasses: [
          {
            'is-danger': this.validPhoneNumber === false,
            'is-success': this.validPhoneNumber === true,
          },
        ],
      };
    },
  },
  methods: {
    changeCodeCountry({ dialCode }) {
      this.newClient.codeCountry = `+${dialCode}`;
    },
    validPhoneNumberMethod({ valid, formatted }) {
      this.validPhoneNumber = valid;
      this.newClient.details.number = formatted.replace(/ /g, '');
    },
  },
};
</script>
