<template>
  <section>
    <div
      v-if="open && responsive"
      class="overlayWithLock transparent"
      @click="quitPanel()"
    />
    <div
      id="TheMenu"
      :class="['jackMenu', {
        'active': open,
        'responsive': responsive
      }]"
    >
      <div class="content">
        <div class="start">
          <template v-if="responsive">
            <b-menu>
              <b-menu-list>
                <b-menu-item @click="open = !open">
                  <template #label>
                    <b-icon
                      pack="fa"
                      icon="bars"
                      class="subtext is-small ml-2"
                    />
                  </template>
                </b-menu-item>
              </b-menu-list>
            </b-menu>

            <br>
          </template>

          <nav>
            <template v-for="{ legend, apps, visible } in menu">
              <div
                v-if="apps.length && visible"
                :key="legend"
                class="mb-5"
              >
                <legend
                  :class="['has-text-secondary is-small mb-3', {
                    'subtext': !responsive,
                    'disclamer': responsive,
                  }]"
                >
                  {{ legend }}
                </legend>

                <b-menu>
                  <b-menu-list>
                    <template v-for="item in apps">
                      <b-menu-item
                        v-if="item.active"
                        :key="item.name"
                        :expanded="item.expanded"
                        :tag="item.children.length ? 'a' : 'router-link'"
                        :to="item.children.length ? '' : item.path"
                        :disabled="item.disabled"
                        class="m-0"
                        @click="item.children.length ? open = true : undefined"
                      >
                        <template #label="props">
                          <div class="multibar">
                            <div class="is-flex is-align-items-center start">
                              <b-icon
                                :pack="item.pack"
                                :icon="item.icon"
                                class="subtext is-small ml-2"
                              />
                              <span
                                :class="['subtext is-small ml-2', {
                                  'text-hide-anim': responsive,
                                  'hide': responsive && !open,
                                  'no-hide': responsive && open,
                                }]"
                              >
                                {{ item.name }}
                              </span>
                            </div>
                            <b-icon
                              v-if="item.children.length"
                              class="is-pulled-right end"
                              pack="fas"
                              :icon="props.expanded ? 'sort-up' : 'sort-down'"
                            />
                            <div
                              v-if="item.badge && item.badgeContent"
                              class="end badge notification"
                            >
                              {{ item.badgeContent }}
                            </div>
                          </div>
                        </template>
                        <template v-if="item.children.length && hasCollapse">
                          <template v-for="child in item.children">
                            <b-menu-item
                              v-if="child.active"
                              :key="child.name"
                              tag="router-link"
                              :to="child.path"
                              :disabled="child.disabled"
                            >
                              <template #label>
                                <div class="is-flex is-align-items-center">
                                  <b-icon
                                    :pack="child.pack"
                                    :icon="child.icon"
                                    class="subtext is-small ml-2"
                                  />
                                  <span
                                    :class="['subtext is-small ml-2', {
                                      'text-hide-anim': responsive,
                                      'hide': responsive && !open,
                                      'no-hide': responsive && open,
                                    }]"
                                  >
                                    {{ child.name }}
                                  </span>
                                </div>
                              </template>
                            </b-menu-item>
                          </template>
                        </template>
                      </b-menu-item>
                    </template>
                  </b-menu-list>
                </b-menu>
              </div>
            </template>
          </nav>
        </div>

        <footer class="TheMenuFooter">
          <div class="multibar">
            <span
              v-if="currentEnv === 'dev'"
              class="has-text-centered"
            >
              {{ windowWidth }}
            </span>
          </div>
          <div class="TheMenuFooterButtons">
            <b-button
              v-if="currentEnv === 'dev'"
              type="is-tertiary"
              icon-pack="fa"
              :icon-left="devMode ? 'laptop' : 'laptop-code'"
              @click="devMode = !devMode"
            />
            <b-button
              type="is-tertiary"
              icon-pack="fa"
              :icon-left="theme ? 'sun' : 'moon'"
              :class="[{
                'text-color-9': !theme,
                'text-color-4': theme,
              }]"
              @click="updateTheme(!theme)"
            />

            <!-- 'text-color-2': !socketIsConnected,
                'text-color-5': socketIsConnected, -->
            <b-button
              type="is-tertiary"
              icon-pack="fa"
              icon-left="power-off"
              @click="logout()"
            />
          </div>
        </footer>
      </div>
    </div>
  </section>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */
import { mapGetters } from 'vuex';

// import SocketMixins from '@jack/socket/$utils/SocketMixins.mixins';
import TheMenuMixins from './$mixins/TheMenuMixins.menu.mixins';

export default {
  name: 'TheMenu',
  mixins: [
    TheMenuMixins,
    // SocketMixins,
  ],
  props: {
    responsive: { type: Boolean, required: true },
  },
  data() {
    return {
      open: true,
    };
  },
  computed: {
    ...mapGetters({
      adminOnAccount: 'TheMenu/adminOnAccount',
    }),
  },
  methods: {
    quitPanel() {
      if (this.open) this.open = false;
    },
  },
};
</script>
