<template>
  <section
    v-if="appointmentSettings.availabilities"
    id="appointment-settings"
    class="settings-content-section"
  >
    <header class="settings-content-header">
      <legend class="text is-small">Appointment setting</legend>
    </header>
    <div class="settings-content-content py-5">
      <b-field>
        <b-switch
          v-model="appointmentSettings.available"
          class="switch-horizontal-reverse"
        >
          <span class="subtext is-big px-4">Available ?</span>
        </b-switch>
      </b-field>
      <template v-if="!appointmentSettings.available">
        <br>
        <b-field
          label="When you come back ?"
          class="bg-solitude flex-grow-1"
          horizontal
        >
          <b-datepicker
            v-model="appointmentSettings.comeBackOn"
            :min-date="new Date()"
          />
        </b-field>
      </template>
      <br>
      <label class="subtext is-big">Disponibilities</label>
      <form @submit.prevent="saveAppointmentSettings()">
        <AppointmentSettings
          v-for="day in weeks"
          :key="day.key"
          :day="day"
          :appointment-settings="appointmentSettings.availabilities"
          @update:appointmentSettings="updateAppointmentSettings($event)"
          @update:appointmentSettingsHours="updateAppointmentSettingsHours($event)"
        />
        <br>
        <div class="multibar">
          <div class="buttons end">
            <b-button
              type="is-primary"
              icon-pack="fa"
              icon-left="check"
              native-type="submit"
              :disabled="disableButtonSaveAppointmentSettings"
            />
          </div>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TheSettingsProfileAppointmentSettings',
  data() {
    return {
      appointmentSettings: {},
    };
  },
  computed: {
    ...mapGetters({
      _appointmentSettings: 'TheSettingsProfile/appointmentSettings',
    }),
    disableButtonSaveAppointmentSettings() {
      if (!this.appointmentSettings.available && !this.appointmentSettings.comeBackOn) return true;
      return JSON.stringify(this.appointmentSettings) === JSON.stringify(this._appointmentSettings);
    },
    weeks() {
      return [
        { label: 'SUN', key: 'sun' },
        { label: 'MON', key: 'mon' },
        { label: 'TUE', key: 'tue' },
        { label: 'WED', key: 'wed' },
        { label: 'THU', key: 'thu' },
        { label: 'FRI', key: 'fri' },
        { label: 'SAT', key: 'sat' },
      ];
    },
  },
  watch: {
    /* eslint-disable func-names */
    'appointmentSettings.available': function (val) { if (val) this.resetAppointmentSettingsComeBackOn(); },
  },
  mounted() {
    this.appointmentSettings = JSON.parse(JSON.stringify(this._appointmentSettings));
    if (this.appointmentSettings.comeBackOn) {
      this.appointmentSettings.comeBackOn = new Date(this.appointmentSettings.comeBackOn);
    }
  },
  methods: {
    ...mapActions({
      _updateAppointmentSettings: 'TheSettingsProfile/updateAppointmentSettings',
    }),
    resetAppointmentSettingsComeBackOn() { this.appointmentSettings.comeBackOn = null; },
    saveAppointmentSettings() {
      if (this.appointmentSettings.available && this.appointmentSettings.comeBackOn) {
        this.appointmentSettings.comeBackOn = null;
      }
      const payload = { appointmentSettings: { ...this.appointmentSettings } };
      delete payload.appointmentSettings.userUid;

      this._updateAppointmentSettings({ payload });
    },
    updateAppointmentSettings({ key, type, value }) {
      this.appointmentSettings.availabilities[key][type] = value;
    },
    updateAppointmentSettingsHours({
      key, at, type, value,
    }) {
      this.appointmentSettings.availabilities[key][at][type] = value;
    },
  },
};
</script>
