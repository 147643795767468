/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import { navySeal } from '@jack/provider';
import { isActionAllowed } from '@jack/dataStores/$utils/helpers';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {},
  actions: {
    create({ dispatch, rootGetters }, { payload, httpQuery }) {
      if (!isActionAllowed(rootGetters, 'CLIENT_LINK_COMPANY.CREATE', dispatch)) return undefined;

      return navySeal.apiWsJoe.client.link.company.post({ payload, httpQuery });
    },
  },
  modules: {},
};
