<template>
  <!-- eslint-disable max-len -->
  <article class="noData card no-border no-shadow transparent">
    <div class="card-content is-flex is-justify-content-center is-flex-direction-column is-align-items-center">
      <template v-if="title">
        <div class="card-title">
          <h3 class="title is-small text-center">
            {{ title }}
          </h3>
        </div>
      </template>

      <template v-if="content">
        <p class="has-text-secondary text text-center">
          {{ content }}
        </p>
        <br>
      </template>

      <template v-if="action">
        <div class="multibar mt-5">
          <div class="buttons end">
            <template v-for="button in buttonsAction">
              <b-button
                v-if="button && button.buttonType === 'event'"
                :key="button.label"
                :label="button.label"
                :type="button.type"
                :icon-pack="button.pack"
                :icon-left="button.left"
                :icon-right="button.right"
                @click="$emit(button.event)"
              />
              <b-button
                v-if="button && button.buttonType === 'link'"
                :key="button.label"
                :label="button.label"
                :type="button.type"
                :icon-pack="button.pack"
                :icon-left="button.left"
                :icon-right="button.right"
                tag="router-link"
                :to="button.to"
              />
            </template>
          </div>
        </div>
      </template>
    </div>
  </article>
</template>

<script>
export default {
  name: 'NoData',
  props: {
    title: { type: String, default: '' },
    content: { type: String, default: '' },
    action: { type: Boolean, default: false },
    buttonsAction: { type: Array, default: () => [] },
  },
};
</script>
