import TheNetworking from './TheNetworking';
import TheNetworkingAlert from './TheNetworkingAlert';
import TheNetworkingLiked from './TheNetworkingLiked';
import TheNetworkingCompanies from './TheNetworkingCompanies';
import TheNetworkingCompanyDetails from './views/TheNetworkingCompanyDetails';
import TheNetworkingContacts from './TheNetworkingContacts';
import TheNetworkingContactsDetails from './views/TheNetworkingContactsDetails';
import TheNetworkingManagePhantoms from './TheNetworkingManagePhantoms';
import TheNetworkingManagePhantomsAgent from './views/TheNetworkingManagePhantomsAgent';
import TheBulkActionsSidePanel from '../common/views/bulkActions/TheBulkActionsSidePanel';

export default [
  {
    path: '/networking',
    name: 'Networking',
    component: TheNetworking,
    meta: {
      hasMenu: true,
      viewStoreNavigation: 'TheNetworking',
      requireAuth: true,
    },
    children: [
      {
        path: '/networking/alerts',
        name: 'Networking - Alerts',
        meta: {
          hasMenu: true,
          viewStoreNavigation: 'TheNetworking',
          requireAuth: true,
        },
        components: {
          alert: TheNetworkingAlert,
        },
      },
      {
        path: '/networking/liked',
        name: 'Networking - Liked',
        meta: {
          hasMenu: true,
          viewStoreNavigation: 'TheNetworking',
          requireAuth: true,
        },
        components: {
          liked: TheNetworkingLiked,
        },
        children: [
          {
            path: '/networking/liked/bulkActions',
            name: 'Networking - Attractive networking - Bulk actions',
            meta: {
              hasMenu: true,
              viewStoreNavigation: 'TheNetworking',
              requireAuth: true,
              lockBodySidePanel: true,
            },
            components: {
              bulkActions: TheBulkActionsSidePanel,
            },
          },
        ],
      },
      {
        path: '/networking/companies',
        name: 'Networking - Companies',
        meta: {
          hasMenu: true,
          viewStoreNavigation: 'TheNetworking',
          requireAuth: true,
        },
        components: {
          companies: TheNetworkingCompanies,
        },
        children: [
          {
            path: '/networking/companies/:cid/details',
            name: 'Networking - Companies - Details',
            meta: {
              hasMenu: true,
              viewStoreNavigation: 'TheNetworking',
              requireAuth: true,
              lockBodySidePanel: true,
            },
            components: {
              details: TheNetworkingCompanyDetails,
            },
          },
        ],
      },
      {
        path: '/networking/contacts',
        name: 'Networking - Contacts',
        meta: {
          hasMenu: true,
          viewStoreNavigation: 'TheNetworking',
          requireAuth: true,
        },
        components: {
          contacts: TheNetworkingContacts,
        },
        children: [
          {
            path: '/networking/contacts/:pid/details',
            name: 'Networking - Contacts - Details',
            meta: {
              hasMenu: true,
              viewStoreNavigation: 'TheNetworking',
              requireAuth: true,
              lockBodySidePanel: true,
            },
            components: {
              details: TheNetworkingContactsDetails,
            },
          },
        ],
      },
      {
        path: '/networking/manage-phantoms',
        name: 'Networking - Manage phantoms',
        meta: {
          hasMenu: true,
          viewStoreNavigation: 'TheNetworking',
          requireAuth: true,
        },
        components: {
          management: TheNetworkingManagePhantoms,
        },
        children: [
          {
            path: '/networking/manage-phantoms/agent/:pbaid',
            name: 'Networking - Manage phantoms - Agent',
            meta: {
              hasMenu: true,
              viewStoreNavigation: 'TheNetworking',
              requireAuth: true,
              lockBodySidePanel: true,
            },
            components: {
              agent: TheNetworkingManagePhantomsAgent,
            },
          },
        ],
      },
    ],
  },
];
