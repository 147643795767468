var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',{staticClass:"dashboard"},[_c('div',{staticClass:"content"},[_c('DevDetails',{attrs:{"cnt":{name: 'formatClientList', obj: _vm.formatClientList}}}),(_vm.formatClientList.length)?[_c('div',{class:['cardDashboard', {
            'col-1': _vm.gridResponsive === 1,
            'col-2': _vm.gridResponsive === 2,
            'col-3': _vm.gridResponsive === 3,
            'col-4': _vm.gridResponsive === 4,
            'col-5': _vm.gridResponsive === 5,
            'col-6': _vm.gridResponsive === 6,
          }]},_vm._l((_vm.formatClientList),function(client){return _c('article',{key:client.cid,staticClass:"card no-border walletCard"},[_c('div',{staticClass:"card-header border-0"},[_c('div',[_c('div',{staticClass:"card-title"},[_c('p',{staticClass:"has-text-secondary subtext is-big end"},[_vm._v(" "+_vm._s(_vm.$moment(client.createdAt).format('YYYY-MM-DD'))+" ")]),_c('p',[_vm._v(_vm._s(client.firstName)+" "+_vm._s(client.lastName))])])]),_c('div',{staticClass:"card-action"},[_c('div',{staticClass:"buttons"},[_c('b-button',{attrs:{"type":"is-ghost","icon-pack":"fa","icon-left":"eye","tag":"router-link","to":`/clients/overview-view/client/${client.cid}`}})],1)])]),(client.socialList && client.socialList.length)?_c('div',{staticClass:"card-content pl-0 pr-0 pt-0"},[_c('div',{staticClass:"buttons is-flex"},_vm._l((client.socialList),function(social,i){return _c('b-button',{key:i,staticClass:"m-0",attrs:{"type":"is-ghost","icon-pack":"fa","icon-left":social.platformName.toLowerCase(),"tag":"a","href":`https://www.${social.platformName.toLowerCase()}.com/${social.username}`,"target":"blank"}})}),1)]):_vm._e()])}),0)]:_vm._e()],2)]),_c('router-view',{attrs:{"name":"overviewClientSidePanel"}}),_c('router-view',{attrs:{"name":"overviewClientSidePannelEdit"}}),_c('router-view',{attrs:{"name":"overviewClientSidePannelClient"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }