<template>
  <div class="jackSidePanel">
    <JackSidePanelHeader
      :path-home="pathHome"
      :previous="goBack"
    />

    <br>

    <div class="multibar">
      <h2 class="title is-big start">
        Add new payment
      </h2>
    </div>

    <br>
    <DevDetails :cnt="{name: 'newPayment', obj: newPayment}" />

    <b-steps
      v-model="activeStep"
      size="is-small"
      :has-navigation="false"
    >
      <template v-for="(step, i) in steps">
        <b-step-item
          v-if="step.displayed"
          :key="step.label"
          :label="step.label"
          :type="step.type"
        >
          <br>

          <JackStepControls
            v-if="step.stepVisible"
            :active-step.sync="activeStep"
            :step-detail="`${i + 1}/${steps.length - 1} - Create payment`"
            :next-is-allowed="step.stepControl"
            :prev="step.prev"
            :next="step.next"
          />

          <br>

          <component
            :is="step.cmp"
            v-model="newPayment"
            :selected="client"
            :clients="clientList"
            :projects="projectList"
            @addNewPayment="addNewPayment()"
            @fetchProject="fetchProject()"
          />
        </b-step-item>
      </template>
    </b-steps>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TheSidePanelAddPayment',
  props: {
    pathHome: { type: String, required: true },
    goBack: { type: Object, required: true },
  },
  data() {
    return {
      activeStep: 0,
      newPayment: {
        client: null,
        project: null,
        details: {
          description: null,
          deposit: null,
          currency: 'EUR',
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      client: 'TheSidePanel/client',
      project: 'TheSidePanel/project',
      payment: 'TheSidePanel/payment',
      clientList: 'TheSidePanel/clientList',
      projectList: 'TheSidePanel/projectList',
      myAuthenticate: 'TheSidePanel/myAuthenticate',
      currentBaid: 'TheSidePanel/currentBaid',
      currentUid: 'TheSidePanel/currentUid',
      currentAccountAid: 'TheSidePanel/currentAccountAid',
    }),
    steps() {
      return [
        {
          label: 'Client', cmp: 'JackSidePanelAddPaymentClientForm', displayed: true, type: 'is-primary', stepVisible: true, stepControl: this.stepControl1, prev: false, next: false,
        },
        {
          label: 'Project', cmp: 'JackSidePanelAddPaymentProjectForm', displayed: true, type: 'is-primary', stepVisible: true, stepControl: this.stepControl2, prev: true, next: true,
        },
        {
          label: 'Payment', cmp: 'JackSidePanelAddPaymentForm', displayed: true, type: 'is-primary', stepVisible: true, stepControl: this.stepControl3, prev: true, next: true,
        },
        {
          label: 'Confirmation', cmp: 'JackSidePanelAddPaymentConfirmation', displayed: true, type: 'is-primary', stepVisible: true, stepControl: false, prev: true, next: false,
        },
        {
          label: '', cmp: 'JackSidePanelAddPaymentSuccessCreate', displayed: true, type: 'is-success', stepVisible: false, stepControl: false, prev: true, next: false,
        },
      ];
    },
    allClientOfAccount() {
      return this.myAuthenticate.hasClient.map(({ clientCid }) => clientCid);
    },
    stepControl1() {
      const { client } = this.newPayment;
      if (client && Object.values(client).length) return true;
      return false;
    },
    stepControl2() {
      const { project } = this.newPayment;
      if (project && Object.values(project).length) return true;
      return false;
    },
    stepControl3() {
      const { details: { deposit } } = this.newPayment;
      if (deposit) return true;
      return false;
    },
  },
  mounted() {
    return Promise.all([
      this.clientList
        ? this.reportClientList({ payload: { cids: this.allClientOfAccount } })
        : null,
    ]);
  },
  methods: {
    ...mapActions({
      reportClientList: 'TheSidePanel/reportClientList',
      reportProjectList: 'TheSidePanel/reportProjectList',
      getPayment: 'TheSidePanel/getPayment',
      createPayment: 'TheSidePanel/createPayment',
    }),
    addNewPayment() {
      this.createPayment({
        payload: {
          uid: this.currentUid,
          userUid: this.currentUid,
          aid: this.currentAccountAid,
          cid: this.newPayment.client.cid,
          baid: this.currentBaid,
          projectPid: this.newPayment.project.pid,
          clientCid: this.newPayment.client.cid,
          createdAt: this.$moment(this.$currentQuery('start')).add(12, 'hours').format(),
          details: {
            ...this.newPayment.details,
          },
        },
      })
        .then(() => {
          if (this.payment) this.activeStep += 1;
        });
    },
    fetchProject() {
      if (!this.newPayment.client) return null;
      this.reportProjectList({
        payload: { cid: this.newPayment.client.cid },
        httpQuery: { details: 'true' },
      })
        .then(() => { this.activeStep += 1; });

      return this.projectList;
    },
  },
};
</script>
