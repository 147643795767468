<template>
  <aside class="jackSidePanel">
    <div class="multibar">
      <b-button
        class="start is-ghost"
        icon-pack="fa"
        icon-left="chevron-left"
        label="Home"
        @click="$emit('home')"
      />
      <b-button
        class="end is-ghost"
        icon-pack="fas"
        icon-right="times"
        @click="$emit('quit')"
      />
    </div>

    <br>

    <header>
      <h2 class="title m-0">Bulk actions - Export employees</h2>
      <p class="text is-small has-text-secondary">
        Extract the employees of a company on LinkedIn
      </p>
    </header>

    <br>
    test
  </aside>
</template>

<script>
export default {
  name: 'BulkExportEmployees',
  props: {
    checkedRows: { type: Array, required: true },
  },
  data() {
    return {
      open: false,
      arguments: {
        advancedSettings: null,
        columnName: null,
        csvName: null,
        filters: null,
        numberOfCompaniesPerLaunch: null,
        numberOfPagePerCompany: null,
        positionFilter: null,
        sessionCookie: null,
        spreadsheetUrl: null,
      },
    };
  },
  computed: {
    copyCheckedRows() {
      return this.checkedRows.reduce((acc, el) => {
        const findCompany = acc.find(({ companyName }) => companyName === el.companyName);

        if (!findCompany) acc.push(({ ...el, active: true }));
        return acc;
      }, []);
    },
  },
  methods: {
    launchAgent() {
      const companies = this.copyCheckedRows.reduce((acc, el) => {
        if (!el.active) return acc;
        const slug = el.companyUrl.split('company/')[1].replace('/', '');
        acc.push(slug);
        return acc;
      }, []);

      this.$emit('launchAgent', { datas: companies, agent: '3295' });
    },
  },
};
</script>
