import TheNotifications from './TheNotifications';

export default [
  {
    path: '/notifications',
    name: 'Notifications',
    component: TheNotifications,
    meta: {
      hasMenu: true,
      viewStoreNavigation: 'TheNotifications',
      requireAuth: true,
    },
    children: [],
  },
];
