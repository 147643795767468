<template>
  <aside class="jackSidePanel no-shadow">
    <JackSidePanelHeader
      :path-home="pathHome"
      :previous="goBack"
    />

    <br>

    <div class="multibar">
      <h2 class="title is-big start">Add new appointment</h2>
    </div>

    <br>
    <DevDetails :cnt="{name: 'newAppointment', obj: newAppointment}" />
    <b-steps
      v-model="activeStep"
      size="is-small"
      :has-navigation="false"
    >
      <template v-for="(step, i) in steps">
        <b-step-item
          v-if="step.displayed"
          :key="step.label"
          :label="step.label"
          :type="step.type"
        >
          <JackStepControls
            v-if="step.stepVisible"
            :active-step.sync="activeStep"
            :step-detail="`${i + 1}/${steps.length - 1} - Create appointment`"
            :next-is-allowed="step.stepControl"
            :prev="step.prev"
            :next="step.next"
          />

          <br>

          <component
            :is="step.cmp"
            v-model="newAppointment"
            :my-client-list="clientList"
            @createNewAppointment="createNewAppointment()"
            @fetchClientList="fetchClientList($event)"
          />
        </b-step-item>
      </template>
    </b-steps>
  </aside>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TheSidePanelAddAppointment',
  props: {
    pathHome: { type: String, required: true },
    goBack: { type: Object, required: true },
  },
  data() {
    return {
      activeStep: 0,
      newAppointment: {
        details: {
          label: null,
          description: null,
          date: null,
          client: null,
          projectPid: null,
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      clientList: 'TheSidePanel/clientList',
      myClientList: 'TheSidePanel/myClientList',
      appointment: 'TheSidePanel/appointment',
    }),
    steps() {
      return [
        {
          label: 'Informations', cmp: 'JackSidePanelAddAppointmentInformationForm', displayed: true, type: 'is-primary', stepVisible: true, stepControl: this.stepControl1, prev: false, next: true,
        },
        {
          label: 'Link to a client', cmp: 'JackSidePanelAddAppointmentLinkForm', displayed: true, type: 'is-primary', stepVisible: true, stepControl: true, prev: true, next: true,
        },
        {
          label: 'Confirmation', cmp: 'JackSidePanelAddAppointmentConfirmation', displayed: true, type: 'is-primary', stepVisible: true, stepControl: true, prev: true, next: true,
        },
        {
          label: '', cmp: 'JackSidePanelAddAppointmentSuccess', displayed: true, type: 'is-success', stepVisible: true, stepControl: true, prev: false, next: false,
        },
      ];
    },
    stepControl1() {
      const { details: { date, label } } = this.newAppointment;
      if (date && label) return true;
      return false;
    },
  },
  methods: {
    ...mapActions({
      reportClientList: 'TheSidePanel/reportClientList',
      createAppointment: 'TheSidePanel/createAppointment',
    }),
    fetchClientList(event) {
      if (!event) this.newAppointment.details.client = null;
      if (event && !this.clientList.length) {
        const cids = this.myClientList.map(({ clientCid: cid }) => cid);

        this.reportClientList({ payload: { cids } });
      }
    },
    createNewAppointment() {
      const { details: { date, client, ...details } } = this.newAppointment;
      const payload = {
        createdAt: this.$moment().format(),
        details: {
          ...details,
          date: this.$moment(date).format(),
          clientCid: client ? client.cid : null,
        },
      };

      this.createAppointment({ payload })
        .then(() => {
          if (this.appointment) this.activeStep += 1;
        });
    },
  },
};
</script>
