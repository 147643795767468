/* eslint-disable max-len */
import { dispatchAndForward, dispatchFailure } from '../$utils/helpers';

export default {
  getters: {
    socketInstance: (_state, _getters, _rootState, rootGetters) => {
      const { 'Socket/getterSocketInstance': instance } = rootGetters;
      return instance;
    },
    socketToken: (_state, _getters, _rootState, rootGetters) => {
      const { 'Socket/getterSocketToken': token } = rootGetters;
      return token;
    },
  },
  actions: {
    initSocket({ dispatch }, { payload = {}, httpQuery = {}, options = {} }) {
      const action = {
        dispatch,
        target: 'Socket/initSocket',
        context: { payload, httpQuery, options },
        config: {
          start: false,
          end: false,
          error: 'Unable to updated tag',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    IOMq({ dispatch }, context) {
      const action = { dispatch, target: 'Socket/io_mq', context };
      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
  },
};
