<template>
  <article
    v-if="project"
    class="dashboard"
  >
    <div class="content">
      <DevDetails :cnt="{name: 'project', obj: project}" />
      <div class="multibar is-align-items-center py-5">
        <h2 class="start title is-big m-0">
          {{ project.label }}
        </h2>

        <nav class="end buttons">
          <b-button
            v-for="nav in menu"
            :key="nav.key"
            tag="router-link"
            :to="nav.path"
            :label="nav.name"
            :icon-pack="nav.pack"
            :icon-left="nav.icon"
            class="is-ghost tab gap-4"
          />
        </nav>
      </div>

      <router-view
        :name="currentTab"
        :kanban-list="kanbanList"
        :client="client"
        :payment-list="paymentList"
        :project="_project"
        :user-uid="currentUid"
      />
    </div>
  </article>
  <div v-else>
    Loading...
  </div>
</template>

<script>
/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TheProjectsDetails',
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      project: 'TheProjects/userProject',
      currentUid: 'TheProjects/currentUid',
      kanbanList: 'TheProjects/kanbanList',
    }),
    currentTab() { return this.$route.fullPath.split('/')[3]; },
    currentPid() {
      return this.$currentParam('pid');
    },
    menu() {
      return [
        { name: 'Overview', key: 'overview', pack: 'fa', icon: 'circle-info', display: true, path: `/projects/${this.currentPid}/overview` },
        { name: 'Activities', key: 'activities', pack: 'fa', icon: 'list-check', display: true, path: `/projects/${this.currentPid}/activities` },
        { name: 'Members', key: 'members', pack: 'fa', icon: 'people-group', display: true, path: `/projects/${this.currentPid}/members` },
        { name: 'Settings', key: 'settings', pack: 'fa', icon: 'gear', display: true, path: `/projects/${this.currentPid}/settings` },
      ];
    },
    client() {
      if (!this.project) return {};
      return this.project.client;
    },
    paymentList() {
      if (!this.project) return [];
      return this.project.paymentList;
    },
    _project() {
      if (!this.project) return {};
      const { client, kanbanList, paymentList, ...project } = this.project;
      return project;
    },
  },
  mounted() { this.fetchProject(); },
  methods: {
    ...mapActions({
      getProjectByUser: 'TheProjects/getProjectByUser',
    }),
    fetchProject() {
      this.loading = true;
      return Promise.resolve()
        .then(() => this.getProjectByUser({
          payload: { uid: this.currentUid, pid: this.currentPid },
          httpQuery: {
            details: 'true',
            client: 'true',
            paymentList: 'true',
            kanbanList: 'true',
          },
        }))
        .then(() => { this.loading = false; });
    },
  },
};
</script>
