var render = function render(){var _vm=this,_c=_vm._self._c;return _c('article',[(_vm.isClickable)?[_c('router-link',{staticClass:"contact-card is-clickable",attrs:{"to":`/networking/contacts/${_vm.pid}/details`}},[(_vm.details.imageUrl)?_c('div',{class:['profile-picture item size', {
          'is-8x8': _vm.size === 8,
          'is-16x16': _vm.size === 16,
          'is-18x18': _vm.size === 18,
          'is-24x24': _vm.size === 24,
          'is-32x32': _vm.size === 32,
          'is-40x40': _vm.size === 40,
          'is-48x48': _vm.size === 48,
          'is-64x64': _vm.size === 64,
          'is-96x96': _vm.size === 96,
          'is-128x128': _vm.size === 128,
        }],style:(`background:url('${_vm.details.imageUrl}')`)}):_c('InitialsPicture',{staticClass:"item",attrs:{"name":_vm.details.fullName,"size":_vm.size}}),_c('div',{staticClass:"ml-5"},[_c('p',{staticClass:"subtitle is-big cursor-pointer p-0 m-0"},[_vm._v(" "+_vm._s(_vm.details.fullName)+" ")]),_c('p',{staticClass:"disclamer is-small has-text-secondary m-0"},[_vm._v(" "+_vm._s(_vm.details.location)+" ")]),_c('p',{staticClass:"subtext is-small has-text-secondary"},[_vm._v(" "+_vm._s(_vm.details.jobTitle)+" ")])])],1)]:[_c('div',{staticClass:"contact-card"},[(_vm.details.imageUrl)?_c('div',{class:['profile-picture item size', {
          'is-8x8': _vm.size === 8,
          'is-16x16': _vm.size === 16,
          'is-18x18': _vm.size === 18,
          'is-24x24': _vm.size === 24,
          'is-32x32': _vm.size === 32,
          'is-40x40': _vm.size === 40,
          'is-48x48': _vm.size === 48,
          'is-64x64': _vm.size === 64,
          'is-96x96': _vm.size === 96,
          'is-128x128': _vm.size === 128,
        }],style:(`background:url('${_vm.details.imageUrl}')`)}):_c('InitialsPicture',{staticClass:"item",attrs:{"name":_vm.details.fullName,"size":_vm.size}}),_c('div',{staticClass:"ml-5"},[(_vm.details.fullName)?_c('p',{staticClass:"subtitle is-big p-0 m-0"},[_vm._v(" "+_vm._s(_vm.details.fullName)+" ")]):_vm._e(),(_vm.details.location)?_c('p',{staticClass:"disclamer is-small has-text-secondary m-0"},[_vm._v(" "+_vm._s(_vm.details.location)+" ")]):_vm._e(),(_vm.details.jobTitle )?_c('p',{staticClass:"subtext is-small has-text-secondary"},[_vm._v(" "+_vm._s(_vm.details.jobTitle)+" ")]):_vm._e()])],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }