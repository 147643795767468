<template>
  <div>
    AddCompanyByInstagram
  </div>
</template>

<script>
export default {
  name: 'AddCompanyByInstagram',
};
</script>
