<template>
  <div>
    <section class="kanban">
      <template v-if="renderDynamic && renderDynamic.length">
        <div
          v-for="(column, indexColumn) in renderDynamic"
          :key="column.kcid"
          class="kanban-column-dropzone"
          @drop="onDrop({ type: 'drop-zone-column', evt: $event, index: indexColumn })"
          @dragover.prevent
          @dragenter.prevent="onDragEnter({ index: indexColumn })"
        >
          <KanbanColumn
            :kcid="column.kcid"
            :column="column"
            :index="indexColumn"
            :selected="selected"
            @openPanel="$emit('openPanel', { ...$event, kid })"
            @startDrag="startDrag({ ...$event, id: column.kcid, index: indexColumn })"
            @dragEnd="dragEnd()"
            @selected:item="selectedItem($event)"
            @unselected:item="dragEnd()"
            @update:orderKanbanItem="onDropItem($event)"
            @update:posItem="updatePosItem($event)"
          />
        </div>
      </template>
      <aside
        class="kanban-column add"
        @click="$emit('openPanel', { type: 'column:add', kid })"
      >
        <b-icon
          icon="plus"
          pack="fa"
          size="is-large"
        />
      </aside>
    </section>
  </div>
</template>

<script>
/* eslint-disable object-curly-newline */
/* eslint-disable no-unused-vars */
import TheProjectsEngine from '../$mixins/TheProjectsEngine.mixins';

export default {
  name: 'Kanban',
  mixins: [TheProjectsEngine],
  props: {
    kid: { type: Number, required: true },
    columns: { type: Array, required: true },
    index: { type: String, required: true },
  },
  data() {
    return {
      type: null,
      selected: {
        column: null,
        item: null,
      },
      pos: {
        column: null,
        item: { column: null, card: null, id: null },
      },
    };
  },
  computed: {
    renderDynamic() {
      if (!this.columns.length) return [];

      let columns;
      let arr = this.columns
        .slice()
        .sort((a, b) => a.order - b.order);
      const arrMapped = arr.arrayMapper('kcid');

      switch (this.type) {
        case 'column':
          if (!this.selected.column || !this.pos.column) break;
          columns = this.moveElement({
            arr, key: 'kcid', id: this.selected.column.kcid, from: this.selected.column.order, to: this.pos.column,
          });

          if (columns) arr = [...columns.before, ...columns.change, ...columns.after];
          break;
        case 'item':
          if (!this.selected.item || (!this.pos.item.column && !this.pos.item.item)) break;

          columns = this.moveItem({
            columns: arrMapped,
            item: this.selected.item,
            pos: this.pos.item,
          });
          if (columns) arr = columns;
          break;
        default: break;
      }

      return arr.sort((a, b) => a.order - b.order);
    },
  },
  methods: {
    startDrag({ evt, column }) {
      /* eslint-disable no-param-reassign */
      evt.dataTransfer.dropEffect = 'move';
      evt.dataTransfer.effectAllowed = 'move';
      this.selected.column = column;
      this.type = 'column';
      this.$emit('selected:column', this.selected.column);
    },
    onDrop({ index }) {
      if (!this.selected.column) return;
      const { items, kanbanKid, ...column } = this.selected.column;
      this.$emit('update:orderKanbanColumn', { ...column, kid: kanbanKid, kanbanKid, order: index + 1, newKanbanColumns: this.renderDynamic });
      this.restartConfig();
    },
    onDropItem({ payload }) {
      this.$emit('update:orderKanbanItem', {
        kid: this.kid,
        ...payload,
        newKanbanColumns: this.renderDynamic,
      });
      this.restartConfig();
    },
    onDragEnter({ index }) { this.pos.column = index + 1; },
    dragEnd() {
      this.$emit('unselected:column');
      this.$emit('unselected:item');
      this.restartConfig();
    },
    selectedItem(evt) {
      this.$emit('selected:item', evt);
      this.selected.item = evt;
      this.type = 'item';
    },
    updatePosItem(evt) { this.pos.item = evt; },
    restartConfig() {
      this.type = null;
      this.pos = {
        column: null,
        item: { column: null, card: null, id: null },
      };
      this.selected = { column: null, item: null };
    },
  },
};
</script>
