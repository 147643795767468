import { use, registerComponent } from '../../$utils/plugins';

import DateForm from './DateForm';
import TimeForm from './TimeForm';
import FirstNameForm from './FirstNameForm';
import LastNameForm from './LastNameForm';
import EmailForm from './EmailForm';
import NumberForm from './NumberForm';
import ButtonForm from './ButtonForm';
import LabelForm from './LabelForm';
import DescriptionForm from './DescriptionForm';
import ThankYou from './ThankYou';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, DateForm);
    registerComponent(Vue, TimeForm);
    registerComponent(Vue, FirstNameForm);
    registerComponent(Vue, LastNameForm);
    registerComponent(Vue, EmailForm);
    registerComponent(Vue, NumberForm);
    registerComponent(Vue, ButtonForm);
    registerComponent(Vue, LabelForm);
    registerComponent(Vue, DescriptionForm);
    registerComponent(Vue, ThankYou);
  },
};

use(Plugin);

export default Plugin;

export {
  DateForm,
  TimeForm,
  FirstNameForm,
  LastNameForm,
  EmailForm,
  NumberForm,
  ButtonForm,
  LabelForm,
  DescriptionForm,
  ThankYou,
};
