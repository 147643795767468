import Vue from 'vue';
import Buefy from 'buefy';
import VueTelInput from 'vue-tel-input';
import VueTextareaAutosize from 'vue-textarea-autosize';
import VueDraggable from 'vuedraggable';

import DevmoodIo from './$socket';
import vuex from './store';

const {
  VUE_APP_ANGLICO_BASE_URL: connection,
} = process.env;

Vue.use(Buefy);
Vue.use(VueTelInput);
Vue.use(VueTextareaAutosize);
Vue.use(VueDraggable);

Vue.use(new DevmoodIo({
  connection,
  vuex,
  debug: process.env.VUE_APP_MODE === 'dev',
  options: {},
}));

// Vue.directive('focus', { inserted(el) { setTimeout(() => el.focus(), 100); } });

Vue.filter('capitalize', (value) => (value ? value.toString().charAt(0).toUpperCase() + value.slice(1) : ''));

// Vue.filter('lowercase', (value) => (value ? value.toString().toLowerCase() : ''));
