<template>
  <div>
    <h1 class="title is-big">Buttons</h1>

    <br>

    <div
      v-for="(it, index) in iterations"
      :key="index"
    >
      <p
        :id="it.title"
        class="title is-3"
      >
        {{ it.title }}
      </p>

      <legend>Default</legend>
      <div class="buttons">
        <section>
          <b-button
            v-for="(type, i) in it.array"
            :key="i"
            :type="type"
            :label="type === 'is-tertiary' ? '' : type"
            :icon-left="type === 'is-tertiary' ? 'account' : ''"
          />
        </section>
      </div>

      <legend>Hovered</legend>
      <div class="buttons">
        <b-button
          v-for="(type, i) in it.array"
          :key="i"
          :type="type"
          :label="type === 'is-tertiary' ? '' : type"
          :icon-left="type === 'is-tertiary' ? 'account' : ''"
          hovered
        />
      </div>

      <legend>Focused</legend>
      <div class="buttons">
        <b-button
          v-for="(type, i) in it.array"
          :key="i"
          :type="type"
          :label="type === 'is-tertiary' ? '' : type"
          :icon-left="type === 'is-tertiary' ? 'account' : ''"
          focused
        />
      </div>

      <legend>Disabled</legend>
      <div class="buttons">
        <b-button
          v-for="(type, i) in it.array"
          :key="i"
          :type="type"
          :label="type === 'is-tertiary' ? '' : type"
          :icon-left="type === 'is-tertiary' ? 'account' : ''"
          disabled
        />
      </div>
      <hr>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TheButton',
  data() {
    return {
      types: [
        'is-primary',
        'is-secondary',
        // 'is-tertiary',
        'is-success',
        'is-danger',
      ],
      customs: Array(12).fill().map((_, i) => `is-custom-${i + 1}`),
      dashedsCustoms: Array(13).fill().map((_, i) => {
        if (i === 0) return 'is-dashed';
        return `is-dashed-${i}`;
      }),
    };
  },
  computed: {
    iterations() {
      return [
        {
          title: 'Classics',
          path: 'Classics',
          array: this.types,
        },
        {
          title: 'Customs',
          path: 'Customs',
          array: this.customs,
        },
        {
          title: 'Dashed',
          path: 'Dashed',
          array: this.dashedsCustoms,
        },
      ];
    },
  },
};
</script>
