<template>
  <div class="cchs-container">
    <template v-if="historySorted">
      <div
        v-for="ch in historySorted"
        :key="ch.chid"
        class="cch-container"
      >
        <ContactHistoryCard
          :contact-history="ch"
          :display-company="displayCompany"
          :persons="persons"
        />
        <div
          v-if="action"
          class="buttons"
        >
          <b-button
            type="is-tertiary"
            icon-pack="fa"
            icon-left="trash"
            class="text-color-1-20 ml-5"
            @click="$emit('deleteContactHistory', { chid: ch.chid })"
          />
        </div>
      </div>
    </template>
    <NoData
      v-else
      :title="$_('You have no contact history...')"
      :content="$_('Record your contacts to ensure a permanent follow-up.')"
      class="mt-5"
    />
  </div>
</template>

<script>
export default {
  name: 'ContactHistoryList',
  props: {
    historySorted: { type: Array, default: () => [] },
    states: { type: Object, required: true },
    personList: { type: Array, default: () => [] },
    client: { type: Object, default: () => {} },
    action: { type: Boolean, default: true },
    displayCompany: { type: Boolean, default: false },
  },
  computed: {
    persons() {
      if (!this.personList || !this.personList.length) return {};
      return this.personList.arrayMapper('pid');
    },
  },
};
</script>
