module.exports = (num, digits, isGraphStep = false) => {
  if (num < 1 && !isGraphStep) return num.toFixed(2);
  const lookup = [
    { value: 1, symbol: '' },
    // { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup.slice().reverse().find((el) => num >= el.value);
  return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : num;
};
