/* eslint-disable import/no-extraneous-dependencies */
import { navySeal } from '@jack/provider';

export default {
  namespaced: true,
  state: {
    hasResult: false,
  },
  mutations: {
    UPDATE_HAS_RESULTS(state) { state.hasResult = true; },
  },
  getters: {
    getterAffiliateHasResult: (state) => state.hasResult,
  },
  actions: {
    create({ commit }, { payload = {}, httpQuery = {} }) {
      return navySeal.apiWsJoe.appointmentToken.affiliate.post({ payload, httpQuery })
        .then(({ appointment, person }) => {
          if (!appointment && !person) return undefined;
          commit('UPDATE_HAS_RESULTS');
          return true;
        });
    },
  },
};
