<template>
  <article
    :class="['cch', {
      'bg-danger': states[contactHistory.state].type === 'danger',
      'bg-warning': states[contactHistory.state].type === 'warning',
      'bg-success': states[contactHistory.state].type === 'success',
      'bg-info': states[contactHistory.state].type === 'info',
      'bg-linkedin': states[contactHistory.state].type === 'linkedin',
      'bg-mail': states[contactHistory.state].type === 'mail',
      'bg-facebook': states[contactHistory.state].type === 'facebook',
      'bg-twitter': states[contactHistory.state].type === 'twitter',
      'bg-telegram': states[contactHistory.state].type === 'telegram',
      'bg-whatsapp': states[contactHistory.state].type === 'whatsapp',
      'bg-slack': states[contactHistory.state].type === 'slack',
      'bg-discord': states[contactHistory.state].type === 'discord',
    }]"
  >
    <div class="icon ml-3">
      <b-icon
        :class="[{
          'text-color-2': states[contactHistory.state].type === 'danger',
          'text-color-3': states[contactHistory.state].type === 'warning',
          'text-color-5': states[contactHistory.state].type === 'success',
          'text-white': states[contactHistory.state].type === 'info',
          'text-linkedin': states[contactHistory.state].type === 'linkedin',
          'text-mail': states[contactHistory.state].type === 'mail',
          'text-facebook': states[contactHistory.state].type === 'facebook',
          'text-twitter': states[contactHistory.state].type === 'twitter',
          'text-telegram': states[contactHistory.state].type === 'telegram',
          'text-whatsapp': states[contactHistory.state].type === 'whatsapp',
          'text-slack': states[contactHistory.state].type === 'slack',
          'text-discord': states[contactHistory.state].type === 'discord',
        }]"
        :pack="states[contactHistory.state].pack"
        :icon="states[contactHistory.state].icon"
        size="is-large"
      />
    </div>
    <div class="w-100 ml-5">
      <p class="text is-small m-0">{{ states[contactHistory.state].name }}</p>
      <div class="is-flex is-align-items-flex-start is-justify-content-flex-start">
        <article
          v-if="displayCompany && company"
          class="w-100"
        >
          <Company
            :cid="contactHistory.companyCid"
            :details="company[contactHistory.companyCid].details"
            class="mt-3"
          />
        </article>
        <article class="w-100">
          <Contact
            v-if="persons[contactHistory.personPid]"
            :pid="persons[contactHistory.personPid].pid"
            :details="persons[contactHistory.personPid].details"
            class="mt-3"
          />
        </article>
      </div>
      <div class="multibar mt-3">
        <p class="end disclamer has-text-secondary">
          {{ $moment(contactHistory.createdAt).format('MMMM Do, YYYY - HH:mm:ss') }}
        </p>
      </div>
    </div>
  </article>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */
import contactHistoryState from '@jack/addons/common/$mixins/contactHistoryState.mixins';

export default {
  name: 'ContactHistoryCard',
  mixins: [contactHistoryState],
  props: {
    displayCompany: { type: Boolean, required: true },
    contactHistory: { type: Object, required: true },
    persons: { type: Object, required: true },
    company: { type: Object, default: () => {} },
  },
};
</script>
