/* eslint-disable import/no-extraneous-dependencies */
import { navySeal } from '@jack/provider';
import { isActionAllowed } from '@jack/dataStores/$utils/helpers';

export default {
  namespaced: true,
  state: { },
  mutations: {},
  getters: {},
  actions: {
    post({ dispatch, commit, rootGetters }, { payload = {}, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'PROJECT_TAG.CREATE', dispatch)) return undefined;

      return navySeal.apiWsJoe.project.tag.post({ payload, httpQuery })
        .then((tag) => {
          const { 'Company/getterCompany': company } = rootGetters;
          if (!company.tags) company.tags = [tag.tagTid];
          else company.tags.push(tag.tagTid);

          commit('Company/SET_COMPANY', company, { root: true });
          return tag;
        });
    },
    delete({ rootGetters, dispatch }, { payload }) {
      if (!isActionAllowed(rootGetters, 'PROJECT_TAG.DELETE', dispatch)) return undefined;

      return navySeal.apiWsJoe.project.tag.delete({ payload, httpQuery: {} });
    },
  },
};
