<template>
  <aside class="jackSidePanel">
    <div class="multibar">
      <div class="end buttons">
        <b-button
          class="is-ghost"
          tag="router-link"
          :to="pathQuit"
          icon-pack="fas"
          icon-right="times"
        />
      </div>
    </div>

    <br>

    <JackSidePanelClientDetails
      v-if="client"
      :client="client"
    />

    <section v-else>
      no client
    </section>
  </aside>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TheClientsSidePannel',
  data() {
    return {
      loading: false,
      displayPhone: false,
    };
  },
  computed: {
    ...mapGetters({
      client: 'TheProjects/client',
      currentUid: 'TheClients/currentUid',
      myAuthenticate: 'TheClients/myAuthenticate',
    }),
    currentCid() {
      return this.$route.params.cid;
    },
    currentPath() {
      return this.$route.fullPath;
    },
    pathEdit() {
      return `${this.currentPath}/edit`;
    },
    pathQuit() {
      return this.currentPath.split('/').splice(0, 3).join('/');
    },
    fullName() {
      return `${this.client.firstName} ${this.client.lastName}`;
    },
  },
  mounted() {
    this.loading = true;
    const payload = { cid: this.currentCid };
    const httpQuery = {
      details: 'true', socialList: 'true', projectList: 'true', paymentList: 'true',
    };

    this.getClient({ payload, httpQuery })
      .then(() => { this.loading = false; });
  },
  methods: {
    ...mapActions({
      getClient: 'TheProjects/getClient',
    }),
  },
};
</script>
