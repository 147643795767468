<template>
  <div class="dashboard">
    <div class="content">
      <section class="template">
        <aside class="template-list-container">
          <b-field
            label="Search template"
            class="bg-solitude mb-3"
          >
            <b-input
              v-model="inputSearch"
              type="search"
              icon="magnifying-glass"
              icon-pack="fa"
              icon-right="xmark"
              icon-right-clickable
              class="w-100"
              @icon-right-click="inputSearch = ''"
            />
          </b-field>
          <nav>
            <article
              class="template-card add"
              @click="_createTemplate()"
            >
              <b-icon
                icon="plus"
                pack="fa"
                size="is-large"
              />
            </article>
            <DevDetails :cnt="{name: 'templateSorted', obj: templateSorted}" />
            <template v-if="templateSorted && templateSorted.length">
              <article
                v-for="template in templateSorted"
                :key="template.tid"
                class="template-card"
                @click="selectTemplate(template)"
              >
                <p class="text is-small m-0">
                  {{ template.subject.slice(slice.min, slice.max) }}
                  {{ template.subject.length > 50 ? '...' : '' }}
                </p>
                <p class="text is-small has-text-secondary">
                  {{ template.arrContent[0].slice(slice.min, slice.max) }}
                  {{ template.subject.length > 50 ? '...' : '' }}
                </p>
              </article>
            </template>
          </nav>
        </aside>
        <div class="template-content">
          <NoData
            v-if="!selected && templateSorted.length"
            title="No template selected"
            content="Select a template in the left panel to modify or delete it"
            class="noTemplate"
          />
          <NoData
            v-if="!templateSorted.length"
            title="No templates found"
            content="You may register a new template at any time"
            class="noTemplate"
          />

          <div v-if="selected && templateSorted.length">
            <div class="editor">
              <p
                class="title text-modifiable m-0"
                contenteditable
                :spellcheck="spellcheck"
                @input="localTemplate({ evt: $event, input: 'subject' })"
              >
                {{ selected.subject }}
              </p>
              <div class="buttons">
                <b-tooltip
                  type="is-dark"
                  label="Update"
                  position="is-bottom"
                  append-to-body
                >
                  <b-button
                    icon-pack="fa"
                    icon-left="check"
                    type="is-tertiary"
                    :disabled="!templateModified"
                    @click="_patchTemplate(selected)"
                  />
                </b-tooltip>
                <b-tooltip
                  type="is-dark"
                  label="Delete"
                  position="is-bottom"
                  append-to-body
                >
                  <b-button
                    icon-pack="fa"
                    icon-left="trash"
                    type="is-tertiary"
                    @click="_deleteTemplate({ tid: selected.tid })"
                  />
                </b-tooltip>
              </div>
            </div>
            <br>
            <p
              class="lead text-modifiable text-template-content"
              contenteditable
              :spellcheck="spellcheck"
              @input="localTemplate({ evt: $event, input: 'content' })"
            >
              <span
                v-for="(content, i) in selected.arrContent"
                :key="`key-${i}-${content}`"
                class="text-content"
              >
                {{ content }}
              </span>
            </p>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */
import { mapGetters, mapActions } from 'vuex';
import { debounce } from '@jack/addons/$utils/helpers';

export default {
  name: 'TheTemplates',
  data() {
    return {
      search: null,
      inputSearch: null,
      selected: null,
      form: {
        tid: null,
        subject: null,
        content: null,
      },
      slice: { min: 0, max: 50 },
      spellcheck: false,
    };
  },
  computed: {
    ...mapGetters({
      templateList: 'TheTemplates/templateList',
      accountAid: 'TheTemplates/currentAccountAid',
    }),
    templateModified() {
      if (!this.selected) return false;
      if (!this.form.subject && !this.form.content) return false;
      const { tid, subject, content } = this.selected;
      const tInit = JSON.stringify({ tid, subject, content });
      const tCopy = JSON.stringify(this.form);
      if (tInit === tCopy) return false;
      return true;
    },
    templateSorted() {
      if (!Object.values(this.templateList).length) return [];
      const {
        search,
        form: { tid: fTid, subject: fSubject, content: fContent },
      } = this;
      const templateList = Object.values(this.templateList);

      const tList = templateList.reduce((acc, template) => {
        if (acc[template.tid]) return acc;

        const { tid, subject, content } = template;

        if (search) {
          const _subject = subject.toLowerCase();
          const _content = content.toLowerCase();
          if (_subject.includes(search) || _content.includes(search)) {
            acc[tid] = { ...template, content, arrContent: content.split('\n') };
          }

          return acc;
        }

        if (Number(tid) === Number(fTid)) {
          const nSubject = fSubject || template.subject;
          const nContent = fContent || template.content;
          acc[tid] = {
            ...template,
            subject: nSubject,
            content: nContent,
            arrContent: nContent.split('\n'),
          };
          return acc;
        }

        acc[tid] = { ...template, content, arrContent: content.split('\n') };
        return acc;
      }, {});

      return Object.values(tList)
        .sort((a, b) => this.$moment(b.createdAt) - this.$moment(a.createdAt));
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    inputSearch: debounce(function (newVal) {
      this.search = newVal.toLowerCase();
    }, 0),
  },
  methods: {
    ...mapActions({
      patchTemplate: 'TheTemplates/patchTemplate',
      deleteTemplate: 'TheTemplates/deleteTemplate',
      createTemplate: 'TheTemplates/createTemplate',
    }),
    localTemplate({ evt, input }) { this.form[input] = evt.target.innerText; },
    tFormat(template) { return { ...template, arrContent: template.content.split('\n') }; },
    _deleteTemplate(payload) { this.deleteTemplate({ payload }); this.resetParams(); },
    _patchTemplate({ subject: sInit, content: cInit, tid }) {
      const { subject: sUpdt, content: cUpdt } = this.form;
      const subject = sUpdt || sInit;
      const content = cUpdt || cInit;
      const editedAt = this.$moment().format();

      content
        .replace('\n', '%0D%0A')
        .replace(' ', '%20')
        .replace(/'/g, '%27');

      this.patchTemplate({
        payload: {
          subject, content, tid, editedAt,
        },
      });
    },
    _createTemplate() {
      this.resetParams();
      this.createTemplate({
        payload: {
          subject: 'Type your title right here',
          content: 'Write the content of your message right here',
          accountAid: this.accountAid,
          createdAt: this.$moment().format(),
          editedAt: null,
        },
      })
        .then(({ response: template }) => {
          this.selected = this.tFormat(template);
        });
    },
    selectTemplate(template) {
      this.resetParams();
      this.form.tid = template.tid;
      this.selected = this.tFormat(template);
    },
    resetParams() {
      this.selected = null;
      this.form = { subject: null, content: null, tid: null };
    },
  },
};
</script>
