/* eslint-disable max-len */
import { SnackbarProgrammatic as Snackbar } from 'buefy';
import { dispatchAndForward, dispatchFailure, snackBarFailure } from '../$utils/helpers';

export default {
  getters: {
    companyNotesList: (_state, _getters, _rootState, rootGetters) => {
      const { 'Notes/gettersCompanyNotesList': companyNotesList } = rootGetters;
      return companyNotesList;
    },
    personNotesList: (_state, _getters, _rootState, rootGetters) => {
      const { 'Notes/gettersPersonNotesList': personNotesList } = rootGetters;
      return personNotesList;
    },
  },
  actions: {
    reportNotesList({ dispatch }, { payload = {}, httpQuery = {}, options = {} }) {
      const action = {
        dispatch,
        target: 'Notes/report',
        context: { payload, httpQuery, options },
        textNotifier: 'report notes list.',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({ message: 'Unable to report notes list.', ...snackBarFailure });
          return dispatchFailure(action);
        });
    },
    addNotes({ dispatch }, { payload = {}, httpQuery = {}, options = {} }) {
      const action = {
        dispatch,
        target: 'Notes/create',
        context: { payload, httpQuery, options },
        textNotifier: 'add notes.',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({ message: 'Unable add notes.', ...snackBarFailure });
          return dispatchFailure(action);
        });
    },
    removeNotes({ dispatch }, { payload = {}, httpQuery = {}, options = {} }) {
      const action = {
        dispatch,
        target: 'Notes/delete',
        context: { payload, httpQuery, options },
        textNotifier: 'remove notes.',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({ message: 'Unable remove notes.', ...snackBarFailure });
          return dispatchFailure(action);
        });
    },
  },
};
