<template>
  <section>
    <div class="jackClientDetailled">
      <DevDetails :cnt="{name: 'formatClientList', obj: formatClientList}" />
      <Table
        v-if="formatClientList"
        :data="formatClientList"
        :columns="Object.values(columns)"
        :loading="loading"
        :total="formatClientList.length"
        :page="page"
        :per-page="perPage"
        :sort-field="sortField"
        :sort-order="sortOrder"
        :default-sort-order="defaultSortOrder"
        :selected.sync="selected"
        :checked-rows.sync="checkedRows"
        :backend-sorting="false"
        :sticky-header="false"
        :checkable="false"
        detail-key="cid"
        @openDetails="openDetails($event)"
      />
    </div>

    <router-view name="detailledClientSidePannel" />
    <router-view name="detailledClientSidePannelEdit" />
    <router-view name="detailledClientSidePannelClient" />
  </section>
</template>

<script>
import TheClientsDetailledColumns from './$mixins/TheClientsDetailledColumns.column.mixins';

export default {
  /* eslint-disable max-len */
  /* eslint-disable no-param-reassign */
  name: 'TheClientsDetailledView',
  mixins: [TheClientsDetailledColumns],
  props: {
    userClientList: { type: Object, required: true },
  },
  data() {
    return {
      loading: false,
      total: 100,
      page: 1,
      perPage: 50,
      sortField: 'cid',
      sortOrder: 'asc',
      defaultSortOrder: 'asc',
      selected: {},
      checkedRows: [],
      currentCampaignViewDetailled: null,
    };
  },
  computed: {
    formatClientList() {
      if (!this.userClientList) return [];

      const datas = Object.values(this.userClientList).reduce((acc, row) => {
        const {
          details: {
            firstName, lastName, number,
          },
          projectList,
          paymentList,
          ...rest
        } = row;

        const totalOfPayment = paymentList
          ? paymentList.reduce((acc2, payment) => {
            if (!payment.details) return acc2;

            acc2 += payment.details.deposit;
            return acc2;
          }, 0)
          : 0;

        const totalOfProjectPrice = projectList
          ? projectList.reduce((acc2, project) => {
            if (!project.details) return acc2;
            acc2 += project.details.price;
            return acc2;
          }, 0)
          : 0;

        acc.push({
          fullName: `${firstName} ${lastName}`,
          number,
          debtor: !(totalOfPayment < totalOfProjectPrice),
          totalProjects: projectList ? projectList.length : 0,
          totalPayment: paymentList ? projectList.length : 0,
          ...rest,
        });
        return acc;
      }, []);

      return datas.sort((a, b) => this.$moment(b.createdAt).unix() - this.$moment(a.createdAt).unix());
    },
  },
  methods: {
    openDetails(row) {
      this.$router.push(`/clients/detailled-view/client/${row.cid}`);
    },
  },
};
</script>
