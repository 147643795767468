/* eslint-disable max-len */
import { getters, actions } from '../../../dataStores/commonViewStores';

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    isReduced: false,
    openSubMenu: '',
    posMouse: {
      x: null, y: null,
    },
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, newData) { state.windowWidth = newData; },
    UPDATE_POS_MOUSE(state, newData) { state.posMouse = newData; },
    UPDATE_IS_REDUCED(state, newData) { state.isReduced = newData; },
    SET_OPEN_SUBMENU(state, newData) { state.openSubMenu = newData; },
  },
  getters: {
    getterWindowWidth: (state) => state.windowWidth,
    getterPosMouse: (state) => state.posMouse,
    getterOpenSubMenu: (state) => state.openSubMenu,
    ...getters.authenticate,
    ...getters.user,
    ...getters.account,
    ...getters.socket,
  },
  actions: {
    ...actions.authenticate,
    ...actions.socket,
    UP_windowWidth({ commit }, windowWidth) {
      commit('UPDATE_WINDOW_WIDTH', windowWidth);
      commit('UPDATE_IS_REDUCED', windowWidth <= 1320);
    },
    UP_posMouse({ commit }, posMouse) {
      commit('UPDATE_POS_MOUSE', posMouse);
    },
    UP_openSubMenu({ commit }, submenu) {
      commit('SET_OPEN_SUBMENU', submenu);
    },
  },
};
