var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[(_vm.open && _vm.responsive)?_c('div',{staticClass:"overlayWithLock transparent",on:{"click":function($event){return _vm.quitPanel()}}}):_vm._e(),_c('div',{class:['jackMenu', {
      'active': _vm.open,
      'responsive': _vm.responsive
    }],attrs:{"id":"TheMenu"}},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"start"},[(_vm.responsive)?[_c('b-menu',[_c('b-menu-list',[_c('b-menu-item',{on:{"click":function($event){_vm.open = !_vm.open}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('b-icon',{staticClass:"subtext is-small ml-2",attrs:{"pack":"fa","icon":"bars"}})]},proxy:true}],null,false,243675976)})],1)],1),_c('br')]:_vm._e(),_c('nav',[_vm._l((_vm.menu),function({ legend, apps, visible }){return [(apps.length && visible)?_c('div',{key:legend,staticClass:"mb-5"},[_c('legend',{class:['has-text-secondary is-small mb-3', {
                  'subtext': !_vm.responsive,
                  'disclamer': _vm.responsive,
                }]},[_vm._v(" "+_vm._s(legend)+" ")]),_c('b-menu',[_c('b-menu-list',[_vm._l((apps),function(item){return [(item.active)?_c('b-menu-item',{key:item.name,staticClass:"m-0",attrs:{"expanded":item.expanded,"tag":item.children.length ? 'a' : 'router-link',"to":item.children.length ? '' : item.path,"disabled":item.disabled},on:{"click":function($event){item.children.length ? _vm.open = true : undefined}},scopedSlots:_vm._u([{key:"label",fn:function(props){return [_c('div',{staticClass:"multibar"},[_c('div',{staticClass:"is-flex is-align-items-center start"},[_c('b-icon',{staticClass:"subtext is-small ml-2",attrs:{"pack":item.pack,"icon":item.icon}}),_c('span',{class:['subtext is-small ml-2', {
                                'text-hide-anim': _vm.responsive,
                                'hide': _vm.responsive && !_vm.open,
                                'no-hide': _vm.responsive && _vm.open,
                              }]},[_vm._v(" "+_vm._s(item.name)+" ")])],1),(item.children.length)?_c('b-icon',{staticClass:"is-pulled-right end",attrs:{"pack":"fas","icon":props.expanded ? 'sort-up' : 'sort-down'}}):_vm._e(),(item.badge && item.badgeContent)?_c('div',{staticClass:"end badge notification"},[_vm._v(" "+_vm._s(item.badgeContent)+" ")]):_vm._e()],1)]}}],null,true)},[(item.children.length && _vm.hasCollapse)?[_vm._l((item.children),function(child){return [(child.active)?_c('b-menu-item',{key:child.name,attrs:{"tag":"router-link","to":child.path,"disabled":child.disabled},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"is-flex is-align-items-center"},[_c('b-icon',{staticClass:"subtext is-small ml-2",attrs:{"pack":child.pack,"icon":child.icon}}),_c('span',{class:['subtext is-small ml-2', {
                                    'text-hide-anim': _vm.responsive,
                                    'hide': _vm.responsive && !_vm.open,
                                    'no-hide': _vm.responsive && _vm.open,
                                  }]},[_vm._v(" "+_vm._s(child.name)+" ")])],1)]},proxy:true}],null,true)}):_vm._e()]})]:_vm._e()],2):_vm._e()]})],2)],1)],1):_vm._e()]})],2)],2),_c('footer',{staticClass:"TheMenuFooter"},[_c('div',{staticClass:"multibar"},[(_vm.currentEnv === 'dev')?_c('span',{staticClass:"has-text-centered"},[_vm._v(" "+_vm._s(_vm.windowWidth)+" ")]):_vm._e()]),_c('div',{staticClass:"TheMenuFooterButtons"},[(_vm.currentEnv === 'dev')?_c('b-button',{attrs:{"type":"is-tertiary","icon-pack":"fa","icon-left":_vm.devMode ? 'laptop' : 'laptop-code'},on:{"click":function($event){_vm.devMode = !_vm.devMode}}}):_vm._e(),_c('b-button',{class:[{
              'text-color-9': !_vm.theme,
              'text-color-4': _vm.theme,
            }],attrs:{"type":"is-tertiary","icon-pack":"fa","icon-left":_vm.theme ? 'sun' : 'moon'},on:{"click":function($event){return _vm.updateTheme(!_vm.theme)}}}),_c('b-button',{attrs:{"type":"is-tertiary","icon-pack":"fa","icon-left":"power-off"},on:{"click":function($event){return _vm.logout()}}})],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }