export default {
  data() {
    return {
      columns: {
        jid: {
          col: 'jid', label: 'jid', visible: false, isSortable: false, cmp: 'flat',
        },
        action: {
          col: 'action', label: 'Actions', visible: true, isSortable: false, event: 'openDetails', buttonType: 'tertiary', icon: 'eye', type: 'is-ghost', width: 20, centered: true,
        },
        title: {
          col: 'title', label: 'Title', visible: true, isSortable: false, cmp: 'flat',
        },
        companyName: {
          col: 'companyName', label: 'Company name', visible: true, isSortable: false, cmp: 'flat',
        },
        location: {
          col: 'location', label: 'Location', visible: true, isSortable: false, cmp: 'flat',
        },
        delete: {
          col: 'delete', label: 'Delete', visible: true, isSortable: false, event: 'delete', buttonType: 'tertiary', icon: 'trash', type: 'is-ghost', width: 20, centered: true,
        },
      },
    };
  },
};
