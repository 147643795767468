<template>
  <section class="mt">
    <div class="is-flex is-align-items-center">
      <b-input
        v-model="inputSearch"
        placeholder="Search alert"
        type="search"
        icon="magnifying-glass"
        icon-pack="fa"
        icon-right="xmark"
        icon-right-clickable
        class="w-100"
        @icon-right-click="inputSearch = ''"
      />
      <b-tooltip
        type="is-dark"
        label="Fetch job alerts"
        position="is-top"
      >
        <b-button
          type="is-tertiary"
          icon-pack="fa"
          icon-left="bullhorn"
          class="ml-3"
          expanded
          @click="open = !open"
        />
      </b-tooltip>
    </div>
    <b-collapse
      :open="open"
      aria-id="contentIdForA11y1"
      class="collapse"
    >
      <div class="collapse-content">
        <div class="content">
          <PhantomSteps
            v-model="activeStep"
            :agent="agentImportSearchJob"
            @update:activeStep="activeStep = $event"
            @launchAgent="launchAgent($event)"
          />
        </div>
      </div>
    </b-collapse>

    <DevDetails :cnt="{name: 'company.hasAlerts', obj: company}" />
    <template v-if="company.hasAlerts && company.hasAlerts.length">
      <div class="company-job-alerts-container">
        <div class="container-list">
          <article
            v-for="alert in company.hasAlerts"
            :key="alert.jid"
            class="company-alert"
          >
            <div
              class="profile-picture-alert"
              :style="`background:url('${company.logo}')`"
            />
            <!-- <div class="background-alert-container">
              <div
                class="background-alert"
                :style="`background:url('${company.banner}')`"
              >
                <div class="background-filtre" />
              </div>
            </div> -->
            <div class="company-alert-content">
              <span class="title is-small">{{ alert.details.companyName }}</span>
              <a
                :href="alert.details.announcementUrl"
                target="_blank"
              >
                <span class="text is-small m-0 is-flex">
                  {{ alert.details.title }}
                </span>
              </a>
              <p class="disclamer has-text-secondary">{{ alert.details.location }}</p>
            </div>
          </article>
        </div>
      </div>
    </template>
  </section>
</template>

<script>
/* eslint-disable-next-line */
import { debounce } from '@jack/addons/$utils/helpers';

export default {
  name: 'CompanyJobsAlert',
  props: {
    company: { type: Object, required: true },
    agentImportSearchJob: { type: Object, required: true },
  },
  data() {
    return {
      open: false,
      search: '',
      inputSearch: '',
      sessionCookie: '',
      activeStep: 0,
    };
  },
  watch: {
    // eslint-disable-next-line func-names
    inputSearch: debounce(function (newVal) {
      this.search = newVal;
    }, 500),
  },
  methods: {
    launchAgent(event) {
      this.$emit('importJobAlerts', {
        argument: {
          ...event,
          search: `https://www.linkedin.com/jobs/search/?f_C=${this.company.extrefId}`,
        },
        agent: this.agentImportSearchJob,
      });

      this.activeStep += 1;
    },
  },
};
</script>
