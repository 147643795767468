// import lib from './lib';
// import routes from './routes';
// import smarts from './smarts';
import * as stores from './stores';

export default {
  // lib,
  // routes,
  // smarts,
  stores,
};
