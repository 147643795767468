<template>
  <!-- eslint-disable max-len -->
  <section>
    <p class="subtitle">
      {{ $_('Create a client file') }}
    </p>
    <p class="text has-text-secondary">
      {{ $_('By clicking on the "Create client" button, you will create a client record that will give access to new features.') }}
    </p>
    <div class="buttons">
      <b-button
        type="is-success"
        icon-pack="fa"
        icon-left="user-plus"
        expanded
        @click="createClient()"
      />
    </div>
  </section>
</template>

<script>
export default {
  name: 'PersonNewClient',
  props: {
    person: { type: Object, required: true },
  },
  methods: {
    createClient() {
      this.$buefy.dialog.confirm({
        title: this.$_('Create new client'),
        message: this.$_('You are about to link this contact to a customer record. Are you sure you want to continue?'),
        confirmText: this.$_('Confirm'),
        cancelText: this.$_('Close'),
        type: 'is-primary',
        hasIcon: true,
        iconPack: 'fa',
        icon: 'info',
        onConfirm: () => this.$emit('createClient'),
      });
    },
  },
};
</script>
