export default {
  data() {
    return {
      columns: {
        pid: {
          col: 'pid', label: 'pid', visible: false, isSortable: false, cmp: 'flat',
        },
        action: {
          col: 'action', label: 'Action', visible: true, isSortable: true, event: 'selectProject', buttonType: 'tertiary', type: 'is-ghost', icon: 'check', sticky: true,
        },
        clientCid: {
          col: 'clientCid', label: 'ClientCid', visible: false, isSortable: false, cmp: 'flat',
        },
        isOpen: {
          col: 'isOpen', label: 'Project completed', visible: true, isSortable: false, cmp: 'isOpen', width: 160, centered: true,
        },
        label: {
          col: 'label', label: 'Label', visible: true, isSortable: false, cmp: 'flat',
        },
        description: {
          col: 'description', label: 'Description', visible: false, isSortable: false, cmp: 'flat',
        },
        price: {
          col: 'price', label: 'Price', visible: true, isSortable: false, cmp: 'symbol', symbol: '€',
        },
        createdAt: {
          col: 'createdAt', label: 'Created at', visible: true, isSortable: false, cmp: 'date',
        },
      },
    };
  },
};
