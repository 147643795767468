<template>
  <!-- eslint-disable max-len -->
  <div>
    <TagAutocomplete
      key-id="pid"
      :entite="person"
      :tag-list="tagList"
      @createTag="$emit('createTag', $event)"
      @deleteTag="$emit('deleteTag', $event)"
    />

    <br>

    <section>
      <p class="subtitle is-medium">{{ $_('Contact information') }}</p>
      <form>
        <b-field :label="$_('Mail')">
          <b-input
            v-model="personSetting.mail"
            type="mail"
            :placeholder="person.mail || 'john.doe@mail.com'"
          />
        </b-field>

        <br>

        <b-field :label="$_('Phone number')">
          <vue-tel-input
            v-model="personSetting.number"
            default-country="fr"
            :preferred-countries="preferredCountries"
            :input-options="inputOptions"
            :auto-format="false"
            :invalid-msg="$_('Invalid phone number')"
            valid-characters-only
            @country-changed="changeCodeCountry($event)"
            @validate="validPhoneNumberMethod({ ...$event, key: 'personSetting' })"
          />
        </b-field>
      </form>
    </section>

    <br>

    <section class="buttons is-flex gap-8 is-flex-wrap-nowrap">
      <b-button
        type="is-primary"
        icon-left="floppy-disk"
        icon-pack="fa"
        class="w-100"
        @click="patchPerson()"
      />
      <b-button
        v-if="person.client"
        type="is-danger"
        icon-left="trash"
        icon-pack="fa"
        @click="deleteClient()"
      />
    </section>
  </div>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */
import vueTelInputMixins from '@jack/addons/$mixins/vueTelInput.mixins';

export default {
  name: 'PersonSettings',
  mixins: [vueTelInputMixins],
  props: {
    person: { type: Object, required: true },
    tagList: { type: Object, required: true },
  },
  data() {
    return {
      personSetting: {
        mail: null,
        number: null,
      },
    };
  },
  mounted() {
    this.personSetting.mail = this.person.details.mail;
    this.personSetting.number = this.person.details.number;
  },
  methods: {
    patchPerson() {
      const payload = {
        mail: this.personSetting.mail || null,
        number: this.personSetting.number !== this.codeCountry && this.validPhoneNumber
          ? this.personSetting.number
          : null,
      };
      this.$emit('patchPerson', { ...payload });
    },
    deleteClient() {
      this.$buefy.dialog.confirm({
        title: 'Delete client\'s file',
        message: 'You\'re about to delete this client\'s file. Are you sure you want to continue?',
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        iconPack: 'fa',
        icon: 'triangle-exclamation',
        onConfirm: () => this.$emit('deleteClient'),
      });
    },
  },
};
</script>
