<template>
  <div class="dashboard">
    <DevDetails :cnt="{name: 'allEventsOfMonth', obj: allEventsOfMonth}" />
    <div
      class="content px-0 pb-0"
    >
      <FullCalendarView
        path="/calendar"
        :all-events-of-month="allEventsOfMonth"
        @monthDisplayed="_reportEventsViewList($event)"
      />
    </div>

    <router-view
      name="sidePanel"
      path="/calendar"
      :all-events-of-month="allEventsOfMonth"
    />

    <div
      v-if="$route.meta.lockBodySidePanel"
      class="overlayWithLock"
      @click="quit()"
    />
  </div>
</template>

<script>
/* eslint-disable-next-line */
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TheHome',
  data() {
    return {
      loading: false,
      date: { dateBetween: null },
      dateBetween: null,
      titles: {
        NEW_SPEND: { label: 'New spend' },
        NEW_CLIENT: { label: 'New client' },
        NEW_PROJECT: { label: 'New project' },
        NEW_PAYMENT: { label: 'New payment' },
        NEW_APPOINTMENT: { label: 'New appointment' },
      },
    };
  },
  computed: {
    ...mapGetters({
      myAuthenticate: 'TheCalendar/myAuthenticate',
      eventsViewList: 'TheCalendar/eventsViewList',
    }),
    allEventsOfMonth() {
      if (!this.eventsViewList) return [];

      const eventList = this.eventsViewList.reduce((acc, event) => {
        const date = this.$moment(event.createdFor).format('YYYY-MM-DD');

        let payload = {
          id: event.eid,
          start: date,
          end: date,
          description: null,
          tooltip: true,
        };

        let title = 'New event';
        switch (event.type) {
          case 'NEW_CLIENT':
            /* eslint-disable-next-line */
            if (event.client.company || event.client.persons) {
              title = event.client.company
                ? event.client.company.name
                : event.client.persons[0].fullName;
            }
            payload = {
              ...payload,
              title,
              color: '#5DADE2',
              // description: this.truncateText(`${event.client.firstName} ${event.client.lastName}`),
              extendedProps: {
                ...event,
                pack: 'fa',
                icon: 'shop',
                event: 'client',
              },
            };
            break;
          case 'NEW_PROJECT':
            payload = {
              ...payload,
              start: !event.project.startDate ? date : this.$moment(event.project.startDate).format('YYYY-MM-DD'),
              end: !event.project.endDate ? date : this.$moment(this.$moment(event.project.endDate).add(1, 'days')).format('YYYY-MM-DD'),
              title: event.project.label,
              description: event.project.description,
              color: '#1ABC9C',
              extendedProps: {
                ...event,
                pack: 'fa',
                icon: 'briefcase',
                event: 'project',
              },
            };
            break;
          case 'NEW_PAYMENT':
            payload = {
              ...payload,
              title: event.payment.description ? event.payment.description : 'New payment',
              color: '#2ECC71',
              extendedProps: {
                ...event,
                pack: 'fa',
                icon: 'dollar-sign',
                event: 'payment',
              },
            };
            break;
          case 'NEW_SPEND':
            payload = {
              ...payload,
              title: event.spend.label,
              color: '#FFC29F',
              extendedProps: {
                ...event,
                pack: 'fa',
                icon: 'hand-holding-usd',
                event: 'spend',
              },
            };
            break;
          case 'NEW_APPOINTMENT':
            payload = {
              ...payload,
              title: event.appointment.label,
              description: event.appointment.description,
              color: '#848484',
              extendedProps: {
                ...event,
                pack: 'fa',
                icon: 'calendar-plus',
                event: 'appointment',
              },
            };
            break;
          default:
            break;
        }

        if (!acc[date]) acc[date] = [{ ...payload }];
        else acc[date].push({ ...payload });

        return acc;
      }, {});
      return Object.values(eventList).reduce((acc, array) => acc.concat(array), []);
    },
  },
  mounted() {
    const start = this.$moment().startOf('month').format('YYYY-MM-DD');
    const end = this.$moment().endOf('month').format('YYYY-MM-DD');
    this._reportEventsViewList({ dateBetween: `${start},${end}` });
  },
  methods: {
    ...mapActions({
      reporteventsViewList: 'TheCalendar/reportEventsViewList',
    }),
    _reportEventsViewList(date) {
      if (this.date.dateBetween && this.date.dateBetween === date.dateBetween) return;
      this.date.dateBetween = date.dateBetween;

      Promise.resolve()
        .then(() => this.reporteventsViewList({
          payload: { uid: this.myAuthenticate.uid }, httpQuery: { ...date },
        }));
    },
    truncateText(text) {
      if (!text) return '';
      if (text.length > 12) return `${text.substr(0, 12)}...`;
      return text.capitalize();
    },
    quit() {
      this.$router.push({ path: '/calendar' });
    },
  },
};
</script>
