export default {
  data() {
    return {
      selected: null,
      subSelected: null,
    };
  },
  computed: {
    cmpsCreate() {
      return {
        'create-contact-by-search': 'AddContactBySearch',
        'create-contact-by-yourself': 'AddContactByYourself',
        'create-contact-by-linkedin': 'AddContactByLinkedin',
        'create-contact-by-instagram': 'AddContactByInstagram',
        'create-company-by-yourself': 'AddCompanyByYourself',
        'create-company-by-linkedin': 'AddCompanyByLinkedin',
        'create-company-by-instagram': 'AddCompanyByInstagram',
      };
    },
    createChoices() {
      return {
        contact: {
          name: this.$_('Contact'),
          key: 'contact',
          pack: 'fa',
          icon: 'address-book',
          description: this.$_('Create a new "contact" type customer'),
          disabled: false,
          visible: true,
          comingSoon: false,
          children: [
            {
              name: this.$_('Search a contact'),
              key: 'create-contact-by-search',
              description: this.$_('Find an existing contact'),
              pack: 'fa',
              icon: 'search',
              disabled: false,
              visible: true,
              comingSoon: false,
            },
            {
              name: this.$_('Create new contact'),
              key: 'create-contact-by-yourself',
              description: this.$_('Register a new contact by yourself'),
              pack: 'fa',
              icon: 'plus',
              disabled: false,
              visible: true,
              comingSoon: false,
            },
            {
              name: this.$_('Find a contact on Linkedin'),
              key: 'create-contact-by-linkedin',
              description: this.$_('Register a new contact from Linkedin'),
              pack: 'fa',
              icon: 'linkedin',
              disabled: true,
              visible: true,
              comingSoon: true,
            },
            {
              name: this.$_('Find a contact on Instagram'),
              key: 'create-contact-by-instagram',
              description: this.$_('Register a new contact from Instagram'),
              pack: 'fa',
              icon: 'instagram',
              disabled: true,
              visible: true,
              comingSoon: true,
            },
          ],
        },
        company: {
          name: this.$_('Company'),
          key: 'company',
          pack: 'fa',
          icon: 'building',
          description: this.$_('Create a new "company" type customer'),
          disabled: true,
          visible: true,
          comingSoon: true,
          children: [
            {
              name: this.$_('Create new company'),
              key: 'create-company-by-yourself',
              description: this.$_('Register a new company by yourself'),
              pack: 'fa',
              icon: 'plus',
              disabled: true,
              visible: true,
              comingSoon: true,
            },
            {
              name: this.$_('Find a company on Linkedin'),
              key: 'create-company-by-linkedin',
              description: this.$_('Register a new company from Linkedin'),
              pack: 'fa',
              icon: 'linkedin',
              disabled: true,
              visible: true,
              comingSoon: true,
            },
            {
              name: this.$_('Find a company on Instagram'),
              key: 'create-company-by-instagram',
              description: this.$_('Register a new company from Instagram'),
              pack: 'fa',
              icon: 'instagram',
              disabled: false,
              visible: true,
              comingSoon: false,
            },
          ],
        },
      };
    },
  },
  methods: {
    reset() {
      if (this.subSelected) {
        this.subSelected = null;
        return;
      }
      this.selected = null;
    },
    select({ choice: { disabled, key }, entite }) {
      if (disabled) return;
      this[entite] = key;
    },
  },
};
