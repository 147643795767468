import SLogger from '../$logger';

export default class IoEmitter {
  constructor(vuex = {}, instance) {
    SLogger.info(vuex ? 'Vuex adapter enabled' : 'Vuex adapter disabled');
    SLogger.info(vuex.mutationPrefix ? 'Vuex socket mutations enabled' : 'Vuex socket mutations disabled');
    this.store = vuex;
    this.instance = instance;
    this.actionPrefix = vuex.actionPrefix ? vuex.actionPrefix : 'SOCKET_';
    this.mutationPrefix = vuex.mutationPrefix ? vuex.mutationPrefix : 'SOCKET_';
    this.listeners = new Map();
  }

  /**
   * addListener
   *
   * @param          evt
   * @param          callback
   * @param          component
   *
   * @description    Register new event listener with vuejs cmp instance
   */
  addListener(evt, callback, component) {
    if (typeof callback === 'function') {
      if (!this.listeners.has(evt)) this.listeners.set(evt, []);
      this.listeners.get(evt).push({ callback, component });
    } else {
      throw new Error('Callback must be a function');
    }

    // SLogger.info(`#${evt} subscribe, Component: ${component.$options.name}`);
  }

  /**
   * removeListener
   *
   * @param        evt
   * @param        component
   *
   * @description  Remove a listener
   */
  removeListener(evt, component) {
    if (this.listeners.has(evt)) {
      const listeners = this.listeners
        .get(evt)
        .filter((listener) => (listener.component !== component));

      if (listeners.length) this.listeners.set(evt, listeners);
      else this.listeners.delete(evt);
    }

    // SLogger.info(`#${evt} unsubscribe, Component: ${component.$options.name}`);
  }

  /**
   * emit
   *
   * @param   evt
   * @param   args
   *
   * @return  Broadcast incoming event to components
   */
  emit(evt, args) {
    this.instance.io.emit(evt, args);

    if (evt !== 'ping' && evt !== 'pong') {
      this.dispatchStoreVuex(evt, args);
    }
  }

  /**
   * dispatchStoreVuex
   *
   * @param   evt
   * @param   args
   *
   * @return  Dispatching vuex actions
   */
  dispatchStoreVuex(evt, args) {
    if (this.store) {

    }
  }
}
