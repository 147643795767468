<template>
  <section>
    <div class="multibar">
      <h3 class="start title is-medium">
        Confirm new spend
      </h3>
    </div>

    <br>

    <fieldset>
      <div class="card">
        <div class="card-content">
          <p class="subtitle is-big">Spend</p>
          <b-field label="Category">
            <div class="multibar">
              <div class="start is-flex is-align-items-center">
                <b-icon
                  :pack="newSpend.details.categories.pack"
                  :icon="newSpend.details.categories.icon"
                />
                <p class="subtitle is-small ml-3">{{ newSpend.details.categories.label }}</p>
              </div>
              <div class="end">
                <p class="subtext has-text-secondary">{{ newSpend.details.categories.cid }}</p>
              </div>
            </div>
          </b-field>

          <br>

          <b-field label="Type of purchase">
            <div class="multibar">
              <div class="start is-flex is-align-items-center">
                <b-icon
                  :pack="newSpend.details.type.pack"
                  :icon="newSpend.details.type.icon"
                />
                <p class="subtitle is-small ml-3">{{ newSpend.details.type.label }}</p>
              </div>
              <div class="end">
                <p class="subtext has-text-secondary">{{ newSpend.details.type.value }}</p>
              </div>
            </div>
          </b-field>

          <br>

          <b-field label="Label">
            <b-input
              v-model="newSpend.details.label"
              placeholder="Label"
              required
              readonly
            />
          </b-field>

          <br>

          <b-field label="Price">
            <b-numberinput
              v-model="newSpend.details.deposit"
              icon-pack="fas"
              :controls="false"
              required
              readonly
              disabled
            />
          </b-field>
        </div>
      </div>
    </fieldset>

    <br>

    <div class="buttons is-right">
      <b-button
        class="is-primary"
        label="Add new spend"
        icon-pack="fas"
        icon-left="hand-holding-usd"
        @click="$emit('addNewSpend')"
      />
    </div>
  </section>
</template>

<script>
export default {
  name: 'JackSidePanelAddSpendConfirmation',
  model: {
    prop: 'newSpend',
  },
  props: {
    newSpend: { type: Object, required: true },
  },
};
</script>
