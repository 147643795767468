<template>
  <!-- eslint-disable max-len -->
  <section
    :class="['note-container', {
      'is-align-items-flex-end': withSource && entite
    }]"
  >
    <div
      v-if="actions"
      class="buttons mb-0"
    >
      <b-button
        type="is-tertiary"
        icon-pack="fa"
        icon-left="trash"
        class="text-color-1-20 mr-5 mb-0"
        @click="$emit('deleteNote', { nid: note.nid })"
      />
    </div>
    <article class="note">
      <p
        v-for="(c, i) in note.arrContent"
        :key="`#-${i}-${c}`"
        class="text is-small"
      >
        {{ c }}
      </p>
      <div class="multibar">
        <p class="end disclamer has-text-secondary">
          {{ $moment(note.createdAt).format('MMMM Do, YYYY - HH:mm:ss') }}
        </p>
      </div>
    </article>
    <b-tooltip
      v-if="withSource && entite"
      type="is-dark"
      :label="entite.name"
      position="is-left"
      append-to-body
    >
      <router-link
        :to="entite.url"
        class="contact-card is-clickable ml-2"
      >
        <div
          v-if="entite.img"
          class="profile-picture item size is-24x24"
          :style="`background:url('${entite.img}')`"
        />
        <InitialsPicture
          v-else
          :name="entite.name"
          class="item"
          :size="24"
        />
      </router-link>
    </b-tooltip>
  </section>
</template>

<script>
export default {
  name: 'NoteCard',
  props: {
    note: { type: Object, required: true },
    actions: { type: Boolean, default: true },
    withSource: { type: Boolean, default: false },
    companies: { type: Object, default: () => {} },
    persons: { type: Object, default: () => {} },
  },
  computed: {
    entite() {
      const { note: { companyCid, personPid }, companies, persons } = this;
      if (Object.values(companies).length && Object.values(persons).length) return null;

      if (companyCid) {
        if (!companyCid[companyCid]) return null;
        const { details: { name, logo: img } } = companies[companyCid];
        return { url: `/networking/companies/${companyCid}/details`, name, img };
      }
      if (personPid) {
        if (!persons[personPid]) return null;
        const { details: { fullName: name, imageUrl: img } } = persons[personPid];
        return { url: `/networking/contacts/${personPid}/details`, name, img };
      }

      return null;
    },
  },
};
</script>
