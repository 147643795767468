<template>
  <section
    :class="['form-add', {
      'side-panel': sidepanel
    }]"
  >
    <div class="content-form">
      <h1 class="title is-small">
        {{ $_('Search a contact') }}
      </h1>

      <form @submit.prevent="$emit('contactList', persons)">
        <b-field
          :label="$_('Search a contact')"
          class="bg-solitude"
        >
          <b-autocomplete
            v-model="search"
            :data="cSearchPerson"
            field="fullName"
            :placeholder="$_('Enter the first three letters')"
            clear-on-select
            @select="option => addPerson({ ...option, personToLinkToCompany: true })"
          >
            <template slot-scope="props">
              <div class="media">
                <div class="media-left">
                  <div
                    v-if="props.option.imageUrl"
                    class="profile-picture item size is-40x40"
                    :style="`background:url('${props.option.imageUrl}')`"
                  />
                  <InitialsPicture
                    v-else
                    :name="props.option.fullName"
                    class="item"
                    :size="40"
                  />
                </div>
                <div class="media-content">
                  <p class="text is-small cursor-pointer p-0 m-0">
                    {{ props.option.fullName }}
                  </p>
                  <p class="disclamer has-text-secondary">
                    {{ props.option.jobTitle }}
                  </p>
                </div>
              </div>
            </template>
          </b-autocomplete>
        </b-field>

        <template v-if="Object.values(persons).length">
          <br>
          <AddPersonItem
            v-for="person in persons"
            :key="person.pid"
            :person="person"
            @removePerson="removePerson($event)"
          />
        </template>

        <br>

        <b-button
          type="is-success"
          icon-pack="fa"
          icon-left="check"
          native-type="submit"
          :disabled="!Object.values(persons).length"
          expanded
        />
      </form>
    </div>
  </section>
</template>

<script>
/* eslint-disable-next-line */
import { debounce } from '@jack/addons/$utils/helpers';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'AddContactBySearch',
  props: {
    sidepanel: { type: Boolean, default: false },
  },
  data() {
    return {
      search: null,
      persons: {},
    };
  },
  computed: {
    ...mapGetters({
      gSearchPerson: 'TheNetworking/searchPerson',
    }),
    cSearchPerson() {
      return Object.values(this.gSearchPerson);
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    search: debounce(function (newVal) {
      if (newVal && newVal.length >= 3) this.mSearchPerson(newVal);
    }, 500),
  },
  methods: {
    ...mapActions({
      aSearchPerson: 'TheNetworking/searchPersonList',
    }),
    mSearchPerson(search) {
      const httpQuery = { by: 'name', search, pagin: '100,0' };
      this.aSearchPerson({ httpQuery });
    },
    addPerson(option) {
      if ((option && option.pid) && !this.persons[option.pid]) {
        this.persons = {
          ...this.persons,
          [option.pid]: { ...this.$reformat(option) },
        };
      }
    },
    removePerson(pid) {
      const newPersons = Object.values(this.persons).reduce((acc, person) => {
        if (Number(person.pid) === Number(pid)) return acc;
        acc[person.pid] = { ...person };
        return acc;
      }, {});

      this.persons = newPersons;
    },
  },
};
</script>
