<template>
  <section v-if="tags.length">
    <b-taglist>
      <b-tag
        v-for="tag in tags"
        :key="tag.tid"
        :class="`tag-color-${tag.color}`"
      >
        {{ tag.label }}
      </b-tag>
    </b-taglist>
  </section>
</template>

<script>
export default {
  name: 'TagList',
  props: {
    entite: { type: Object, required: true },
    tagList: { type: Object, required: true },
  },
  computed: {
    tags() {
      if (!this.entite.tags || !this.entite.tags.length) return [];
      return this.entite.tags.reduce((acc, tid) => {
        if (!this.tagList[tid]) return acc;
        acc.push(this.tagList[tid]);
        return acc;
      }, []);
    },
  },
};
</script>
