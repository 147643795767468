<template>
  <section>
    <div class="multibar">
      <h3 class="start title is-medium">
        Confirm new customer
      </h3>
    </div>

    <br>

    <fieldset>
      <div class="card">
        <div class="card-content">
          <p class="subtitle is-big">Payment</p>

          <br>

          <b-field
            horizontal
            label="Price"
          >
            <b-numberinput
              v-model="newPayment.details.deposit"
              required
              icon-pack="fas"
              readonly
            />
          </b-field>

          <br>

          <b-field
            v-if="newPayment.details.description"
            label="Description"
          >
            <b-input
              v-model="newPayment.details.description"
              type="textarea"
              placeholder="Description"
              readonly
            />
          </b-field>
        </div>
      </div>

      <br>

      <div
        v-if="newPayment.client"
        class="card"
      >
        <div class="card-content">
          <p class="subtitle is-big">Client</p>

          <b-field label="First name">
            <b-input
              v-model="newPayment.client.firstName"
              placeholder="First name"
              required
              readonly
            />
          </b-field>

          <br>

          <b-field label="Last name">
            <b-input
              v-model="newPayment.client.lastName"
              placeholder="Last name"
              required
              readonly
            />
          </b-field>

          <br>

          <b-field label="Phone number">
            <b-input
              v-model="newPayment.client.number"
              placeholder="Phone number"
              required
              readonly
            />
          </b-field>
        </div>
      </div>

      <br>

      <div
        v-if="newPayment.project"
        class="card"
      >
        <div class="card-content">
          <p class="subtitle is-big">Project</p>

          <b-field label="Label">
            <b-input
              v-model="newPayment.project.label"
              placeholder="Label"
              required
              readonly
            />
          </b-field>

          <br>

          <b-field
            v-if="newPayment.project.description"
            label="Description"
          >
            <b-input
              v-model="newPayment.project.description"
              type="textarea"
              placeholder="Description"
              required
              readonly
            />
          </b-field>

          <br>

          <b-field label="Price">
            <b-input
              v-model="newPayment.project.price"
              placeholder="Price"
              required
              readonly
            />
          </b-field>
        </div>
      </div>
    </fieldset>

    <br>

    <div class="buttons is-right">
      <b-button
        class="is-primary"
        label="Add new payment"
        icon-pack="fas"
        icon-left="hand-holding-usd"
        @click="$emit('addNewPayment')"
      />
    </div>
  </section>
</template>

<script>
export default {
  name: 'JackSidePanelAddPaymentConfirmation',
  model: {
    prop: 'newPayment',
  },
  props: {
    newPayment: { type: Object, default: () => {} },
  },
};
</script>
