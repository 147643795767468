<template>
  <article>
    <router-link
      :to="to ? `/networking/companies/${cid}/details` : ''"
      class="contact-card is-clickable"
    >
      <div
        v-if="details.logo"
        :class="['profile-picture item size', {
          'is-40x40': size === 40,
          'is-48x48': size === 48,
          'is-64x64': size === 64,
        }]"
        :style="`background:url('${details.logo}')`"
      />
      <InitialsPicture
        v-else
        :name="details.name"
        class="item"
        :size="size"
      />
      <div class="ml-5">
        <p class="subtitle is-big cursor-pointer p-0 m-0">
          {{ details.name }}
        </p>
        <p
          v-if="details.description"
          class="disclamer is-small has-text-secondary m-0"
        >
          {{ details.description.slice(0, 100) }}...
        </p>
      </div>
    </router-link>
  </article>
</template>

<script>
export default {
  name: 'Company',
  props: {
    cid: { type: Number, required: true },
    details: { type: Object, required: true },
    size: { type: Number, default: 40 },
    to: { type: Boolean, default: true },
  },
};
</script>
