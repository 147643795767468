export default {
  data() {
    return {
      codeCountry: null,
      validPhoneNumber: null,
    };
  },
  computed: {
    preferredCountries() {
      return ['fr'];
    },
    inputOptions() {
      return {
        placeholder: this.person.number || 'Enter a phone number',
        required: true,
        showDialCode: true,
        mode: 'national',
        styleClasses: [
          {
            'is-danger': this.validPhoneNumber === false,
            'is-success': this.validPhoneNumber === true,
          },
        ],
      };
    },
  },
  methods: {
    changeCodeCountry({ dialCode }) {
      this.codeCountry = `+${dialCode}`;
    },
    validPhoneNumberMethod({ valid, formatted, key }) {
      this.validPhoneNumber = valid;
      if (!formatted) return;
      this[key].number = formatted.replace(/ /g, '');
    },
  },
};
