<template>
  <div>
    TimeForm
  </div>
</template>

<script>
export default {
  name: 'TimeForm',
};
</script>
