/* eslint-disable max-len */
import { dispatchAndForward, dispatchFailure } from '../$utils/helpers';

export default {
  getters: {
    categoriesList: (_state, _getters, _rootState, rootGetters) => {
      const { 'Categories/getterCategoriesList': categoriesList } = rootGetters;
      return categoriesList;
    },
  },
  actions: {
    reportCategoriesList({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'Categories/report',
        context: { payload, httpQuery },
        textNotifier: 'report categories list.',
        retry: { onSuccess: false, onFailure: false },
        config: {
          start: false,
          end: true,
          error: 'Unable to report categories list',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
  },
};
