<template>
  <section>
    <div class="multibar">
      <h3 class="start title is-small">
        Information about the appointment
      </h3>
    </div>

    <br>

    <fieldset>
      <b-field
        horizontal
        label="Date"
      >
        <b-datetimepicker
          v-model="newAppointment.details.date"
          placeholder="Select a date"
          icon-pack="fa"
          icon="calendar-day"
          editable
          append-to-body
          @icon-right-click="clearDateTime"
        />
      </b-field>

      <b-field
        horizontal
        label="Label"
      >
        <b-input
          v-model="newAppointment.details.label"
          required
        />
      </b-field>

      <b-field
        horizontal
        label="Description"
      >
        <b-input
          v-model="newAppointment.details.description"
          type="textarea"
        />
      </b-field>
    </fieldset>
  </section>
</template>

<script>
export default {
  name: 'JackSidePanelAddAppointmentInformationForm',
  model: {
    prop: 'newAppointment',
  },
  props: {
    newAppointment: { type: Object, default: () => {} },
  },
  data() {
    return {
      showWeekNumber: false,
      enableSeconds: false,
      hourFormat: undefined, // Browser locale
      locale: undefined, // Browser locale
      firstDayOfWeek: undefined, // 0 - Sunday
    };
  },
  computed: {
    localNewAppointment: {
      get() { return this.newAppointment; },
      set(newValue) { this.$emit('update:newAppointment', newValue); },
    },
    dateSelected() {
      return this.$route.query.start;
    },
  },
  mounted() {
    this.newAppointment.details.date = this.$moment(this.dateSelected).toDate();
  },
  methods: {
    clearDateTime() {
      this.selected = null;
    },
  },
};
</script>
