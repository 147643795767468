/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import { navySeal } from '@jack/provider';
import { isActionAllowed } from '@jack/dataStores/$utils/helpers';

export default {
  namespaced: true,
  state: {
    spendsUserList: null,
    spendsList: null,
    spends: null,
  },
  mutations: {
    SET_SPENDS_USER_LIST(state, newData) { state.spendsUserList = newData; },
    SET_SPENDS_LIST(state, newData) { state.spendsList = newData; },
    SET_SPENDS(state, newData) { state.spends = newData; },
  },
  getters: {
    getterSpendsUserList: (state) => state.spendsUserList,
    getterSpendsList: (state) => state.spendsList,
    getterSpends: (state) => state.spends,
  },
  actions: {
    reportByUser({ commit, rootGetters, dispatch }, { payload, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'USER_SPENDS.REPORT', dispatch)) return undefined;

      return navySeal.apiWsDivision.user.spends.report({ payload, httpQuery })
        .then((spends) => {
          commit('SET_SPENDS_USER_LIST', spends);
        });
    },
    report({ commit, rootGetters, dispatch }, { payload }) {
      if (!isActionAllowed(rootGetters, 'SPENDS.REPORT', dispatch)) return undefined;

      return navySeal.apiWsDivision.user.bookAccount.spends.report({ payload, httpQuery: {} })
        .then((spends) => {
          commit('SET_SPENDS_LIST', spends);
        });
    },
    get({ commit, rootGetters, dispatch }, { payload }) {
      if (!isActionAllowed(rootGetters, 'SPENDS.GET', dispatch)) return undefined;

      return navySeal.apiWsDivision.user.bookAccount.spends.get({ payload, httpQuery: {} })
        .then((spends) => {
          commit('SET_SPENDS', spends);
        });
    },
    post({ dispatch, commit, rootGetters }, { payload }) {
      if (!isActionAllowed(rootGetters, 'SPENDS.CREATE', dispatch)) return undefined;

      return navySeal.apiWsDivision.user.bookAccount.spends.post({ payload, httpQuery: {} })
        .then(({
          event: {
            eid, accountAid: aid, uid, type, createdAt,
          },
          spend,
        }) => {
          const {
            sid,
            createdAt: spendCreatedAt,
            details: { spendsSid, ...details },
          } = spend;

          const newEvent = {
            eid,
            aid,
            uid,
            createdAt,
            type,
            sid,
            spend: {
              ...details,
              createdAt: spendCreatedAt,
            },
            projectPid: null,
            project: null,
            cid: null,
            client: null,
            appointment: null,
            appointmentAid: null,
            paymentPid: null,
            payment: null,
          };

          dispatch('User/eventsView/up_addEventEventsViewList', newEvent, { root: true });
          commit('SET_SPENDS', spend);
          return spend;
        });
    },
    put({ commit, rootGetters, dispatch }, { payload }) {
      if (!isActionAllowed(rootGetters, 'SPENDS.UPDATE', dispatch)) return undefined;

      return navySeal.apiWsDivision.user.bookAccount.spends.put({ payload, httpQuery: {} })
        .then((spends) => {
          commit('SET_SPENDS', spends);
        });
    },
    delete({ rootGetters, dispatch }, { payload }) {
      if (!isActionAllowed(rootGetters, 'SPENDS.DELETE', dispatch)) return undefined;

      return navySeal.apiWsDivision.user.bookAccount.spends.delete({ payload, httpQuery: {} });
    },
  },
};
