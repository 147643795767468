<template>
  <article class="schedule-appointment">
    <b-field>
      <b-checkbox v-model="localActive">
        <span class="text is-small">
          {{ day.label }}
        </span>
      </b-checkbox>
    </b-field>
    <div class="schedule-appointment-container-inputs">
      <legend class="text is-small">A.M.</legend>
      <div class="schedule-appointment-inputs">
        <b-field class="bg-solitude">
          <b-select
            v-model="localAmStart"
            :disabled="!localActive"
            :class="[{
              'is-disabled': !localActive
            }]"
          >
            <option
              v-for="hour in hours"
              :key="hour"
              :value="hour"
            >
              {{ hour }}
            </option>
          </b-select>
        </b-field>
        <span>-</span>
        <b-field class="bg-solitude">
          <b-select
            v-model="localAmEnd"
            :disabled="!localActive"
            :class="[{
              'is-disabled': !localActive
            }]"
          >
            <template v-for="hour in hours">
              <option
                v-if="$moment(hour, 'HH:mm').isAfter($moment(localAmStart, 'HH:mm'))"
                :key="hour"
                :value="hour"
              >
                {{ hour }}
              </option>
            </template>
          </b-select>
        </b-field>
      </div>
    </div>
    <div class="schedule-appointment-container-inputs">
      <legend class="text is-small">P.M.</legend>
      <div class="schedule-appointment-inputs">
        <b-field class="bg-solitude">
          <b-select
            v-model="localPmStart"
            :disabled="!localActive"
            :class="[{
              'is-disabled': !localActive
            }]"
          >
            <template v-for="hour in hours">
              <option
                v-if="$moment(hour, 'HH:mm').isAfter($moment(localAmEnd, 'HH:mm'))"
                :key="hour"
                :value="hour"
              >
                {{ hour }}
              </option>
            </template>
          </b-select>
        </b-field>
        <span>-</span>
        <b-field class="bg-solitude">
          <b-select
            v-model="localPmEnd"
            :disabled="!localActive"
            :class="[{
              'is-disabled': !localActive
            }]"
          >
            <template v-for="hour in hours">
              <option
                v-if="$moment(hour, 'HH:mm').isAfter($moment(localPmStart, 'HH:mm'))"
                :key="hour"
                :value="hour"
              >
                {{ hour }}
              </option>
            </template>
          </b-select>
        </b-field>
      </div>
    </div>
  </article>
</template>

<script>
/* eslint-disable object-curly-newline */
export default {
  name: 'AppointmentSettings',
  props: {
    day: { type: Object, required: true },
    appointmentSettings: { type: Object, required: true },
  },
  data() {
    return {
      dayHours: 1425,
    };
  },
  computed: {
    localActive: {
      get() { return this.appointmentSettings[this.day.key].active; },
      set(value) { this.$emit('update:appointmentSettings', { key: this.day.key, type: 'active', value }); },
    },
    localAmStart: {
      get() { return this.appointmentSettings[this.day.key].am.start; },
      set(value) { this.$emit('update:appointmentSettingsHours', { key: this.day.key, at: 'am', type: 'start', value }); },
    },
    localAmEnd: {
      get() { return this.appointmentSettings[this.day.key].am.end; },
      set(value) { this.$emit('update:appointmentSettingsHours', { key: this.day.key, at: 'am', type: 'end', value }); },
    },
    localPmStart: {
      get() { return this.appointmentSettings[this.day.key].pm.start; },
      set(value) { this.$emit('update:appointmentSettingsHours', { key: this.day.key, at: 'pm', type: 'start', value }); },
    },
    localPmEnd: {
      get() { return this.appointmentSettings[this.day.key].pm.end; },
      set(value) { this.$emit('update:appointmentSettingsHours', { key: this.day.key, at: 'pm', type: 'end', value }); },
    },
    hours() {
      return new Array(96).fill().map((_, i) => {
        const duration = this.$moment.duration(i * 15, 'minutes');
        const hour = duration.hours();
        const minute = duration.minutes();
        return this.$moment({ hour, minute }).format('HH:mm');
      });
    },
  },
};
</script>
