import account from './account';
import agents from './agents';
import appointment from './appointment';
import authenticate from './authenticate';
import categories from './categories';
import client from './client';
import company from './company';
import contactHistory from './contactHistory';
import containers from './containers';
import instagram from './instagram';
import job from './job';
import notes from './notes';
import payment from './payment';
import person from './person';
import project from './project';
import socket from './socket';
import spends from './spends';
import tag from './tag';
import templates from './templates';
import user from './user';

const commons = [
  { n: 'account', c: account },
  { n: 'agents', c: agents },
  { n: 'appointment', c: appointment },
  { n: 'authenticate', c: authenticate },
  { n: 'categories', c: categories },
  { n: 'client', c: client },
  { n: 'company', c: company },
  { n: 'instagram', c: instagram },
  { n: 'contactHistory', c: contactHistory },
  { n: 'containers', c: containers },
  { n: 'job', c: job },
  { n: 'notes', c: notes },
  { n: 'payment', c: payment },
  { n: 'person', c: person },
  { n: 'project', c: project },
  { n: 'socket', c: socket },
  { n: 'spends', c: spends },
  { n: 'tag', c: tag },
  { n: 'templates', c: templates },
  { n: 'user', c: user },
];

const commonState = commons.reduce((acc, { n, c }) => ({ ...acc, [n]: { ...c.state } }), {});
const getters = commons.reduce((acc, { n, c }) => ({ ...acc, [n]: { ...c.getters } }), {});
const actions = commons.reduce((acc, { n, c }) => ({ ...acc, [n]: { ...c.actions } }), {});

export {
  commonState,
  getters,
  actions,
};

export default {
  commonState: commons.reduce((acc, { c }) => ({ ...acc, ...c.state }), {}),
  getters: commons.reduce((acc, { c }) => ({ ...acc, ...c.getters }), {}),
  actions: commons.reduce((acc, { c }) => ({ ...acc, ...c.actions }), {}),
};
