<template>
  <div>
    <TagList
      :entite="company"
      :tag-list="tagList"
    />

    <br>

    <section>
      <article>
        <p class="text is-small">
          {{ company.description }}
        </p>

        <br>

        <template v-for="info in informations">
          <p
            v-if="company[info.key]"
            :key="info.key"
            class="disclamer m-0"
          >
            <span class="has-text-secondary">{{ info.name }}</span>
            <span class="ml-3">{{ company[info.key] }}</span>
          </p>
        </template>
      </article>
    </section>
  </div>
</template>

<script>
export default {
  name: 'CompanyInformations',
  props: {
    company: { type: Object, required: true },
    tagList: { type: Object, required: true },
  },
  computed: {
    informations() {
      return [
        { name: 'Tag line:', key: 'tagLine' },
        { name: 'Sector:', key: 'sector' },
        { name: 'Size of company:', key: 'sizeOfCompany' },
        { name: 'Employees number:', key: 'employeesNumber' },
        { name: 'Company phone:', key: 'companyPhone' },
        { name: 'Founded in:', key: 'foundedIn' },
      ];
    },
  },
};
</script>
