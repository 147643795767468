<template>
  <aside class="jackSidePanel">
    <div class="multibar">
      <div class="start buttons">
        <b-button
          class="is-ghost"
          tag="router-link"
          :to="to"
          icon-pack="fa"
          icon-right="arrow-left"
        />
      </div>
    </div>

    <br>
    <DevDetails :cnt="{name: 'userProject', obj: userProject}" />

    <div class="multibar">
      <h2 class="title start">Edit project</h2>
    </div>

    <br>

    <template v-if="userProject">
      <form @submit.prevent="updateProject()">
        <fieldset>
          <b-field label="Name">
            <b-input
              v-model="payload.label"
              required
              placeholder=""
            />
          </b-field>

          <br>

          <b-field label="Description">
            <b-input
              v-model="payload.description"
              required
              placeholder=""
            />
          </b-field>

          <br>

          <b-field label="Price">
            <b-numberinput
              v-model="payload.price"
              required
              min="0"
              icon-pack="fas"
            />
          </b-field>

          <br>

          <!-- <b-field label="Picture">
            <b-upload
              v-model="payload.urlImage"
              accept=".jpg, .jpeg, .png"
              drag-drop
              expanded
              @input="$update('urlImage', payload.urlImage)"
            >
              <section class="section">
                <div class="multibar has-text-secondary">
                  <p>
                    <b-icon
                      pack="fa"
                      icon="download"
                      size="is-large"
                    />
                  </p>
                </div>
                <div class="multibar">
                  <p class="has-text-secondary">Edit workspace picture</p>
                </div>
              </section>
            </b-upload>
          </b-field> -->
        </fieldset>

        <br>

        <div class="buttons multibar">
          <b-button
            class="end"
            label="Save"
            type="is-primary"
          />
        </div>
      </form>
    </template>
  </aside>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TheProjectSidePannelEdit',
  props: {},
  data() {
    return {
      payload: {
        label: null,
        description: null,
        price: null,
        urlImage: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      userProject: 'TheProjects/userProject',
      myAuthenticate: 'TheProjects/myAuthenticate',
    }),
    to() {
      const path = this.$route.fullPath.split('/');
      path.pop();

      return path.join('/');
    },
  },
  mounted() {
    if (!this.userProject) {
      this.$router.push(this.to);
    } else {
      const {
        userUid,
        label,
        description,
        price,
        urlImage,
      } = this.userProject;

      const { uid } = this.myAuthenticate;

      if (Number(uid) !== Number(userUid)) this.$router.push(this.to);

      this.payload = {
        label,
        description,
        price,
        urlImage: urlImage || null,
      };
    }
  },
};
</script>
