import { SnackbarProgrammatic as Snackbar } from 'buefy';
import { dispatchAndForward, dispatchFailure, snackBarFailure } from '../$utils/helpers';

/* eslint-disable max-len */
export default {
  getters: {
    theme: (_state, _getters, _rootState, rootGetters) => {
      const { 'User/theme/getterTheme': theme } = rootGetters;
      return theme;
    },
    // dashboardViewList: (_state, _getters, _rootState, rootGetters) => {
    //   const { 'User/dashboardView/getterDashboardViewList': dashboardViewList } = rootGetters;
    //   return dashboardViewList;
    // },
    userDashboardView: (_state, _getters, _rootState, rootGetters) => {
      const { 'User/userDashboardView/getterUserDashboardView': UserDashboardView } = rootGetters;
      return UserDashboardView;
    },
    eventsViewList: (_state, _getters, _rootState, rootGetters) => {
      const { 'User/eventsView/getterEventViewList': eventsViewList } = rootGetters;
      return eventsViewList;
    },
    eventsView: (_state, _getters, _rootState, rootGetters) => {
      const { 'User/eventsView/getterEventView': eventsView } = rootGetters;
      return eventsView;
    },
    userProjectList: (_state, _getters, _rootState, rootGetters) => {
      const { 'User/project/getterUserProjectList': userProjectList } = rootGetters;
      return userProjectList;
    },
    userProject: (_state, _getters, _rootState, rootGetters) => {
      const { 'User/project/getterUserProject': userProject } = rootGetters;
      return userProject;
    },
    userClientList: (_state, _getters, _rootState, rootGetters) => {
      const { 'User/client/getterClientList': clientList } = rootGetters;
      return clientList;
    },
    userClient: (_state, _getters, _rootState, rootGetters) => {
      const { 'User/client/getterClient': client } = rootGetters;
      return client;
    },
    userSpendsList: (_state, _getters, _rootState, rootGetters) => {
      const { 'User/spends/getterSpendsUserList': userSpendsList } = rootGetters;
      return userSpendsList;
    },
    appointmentList: (_state, _getters, _rootState, rootGetters) => {
      const { 'User/appointment/getterAppointmentList': appointmentList } = rootGetters;
      return appointmentList;
    },
    appointment: (_state, _getters, _rootState, rootGetters) => {
      const { 'User/appointment/getterAppointment': appointment } = rootGetters;
      return appointment;
    },
    appointmentSettings: (_state, _getters, _rootState, rootGetters) => {
      const { 'User/settings/getterAppointmentSettings': appointmentSettings } = rootGetters;
      return appointmentSettings;
    },
    socketToken: (_state, _getters, _rootState, rootGetters) => {
      const { 'Socket/getterSocket': socketToken } = rootGetters;
      return socketToken;
    },
    notificationList: (_state, _getters, _rootState, rootGetters) => {
      const { 'User/notification/getterNotificationList': notificationList } = rootGetters;
      return notificationList;
    },
    notificationCount: (_state, _getters, _rootState, rootGetters) => {
      const { 'User/notification/getterNotificationCount': notificationCount } = rootGetters;
      return notificationCount;
    },
    userLanguage: (_state, _getters, _rootState, rootGetters) => {
      const { 'User/language/getterLanguage': userLanguage } = rootGetters;
      return userLanguage;
    },
  },
  actions: {
    createUser({ dispatch }, data) {
      const action = {
        dispatch,
        target: 'User/create',
        context: {
          payload: {
            ...data,
          },
        },
        textNotifier: 'User successfully created.',
        retry: {
          onSuccess: false,
          onFailure: false,
        },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to create user.',
            ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },
    getUserDashboardView({ dispatch, getters }) {
      const { currentUid: uid } = getters;
      const action = {
        dispatch,
        target: 'User/userDashboardView/get',
        context: {
          payload: { uid },
          httpQuery: {},
        },
        textNotifier: 'get user dashboard view.',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({ message: 'Unable to get user dasboard view.', ...snackBarFailure });

          return dispatchFailure(action);
        });
    },
    reportEventsViewList({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'User/eventsView/report',
        context: { payload, httpQuery },
        textNotifier: 'User account event view reported.',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to report account event view.',
            ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },
    getEventsView({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'User/eventsView/get',
        context: { payload, httpQuery },
        textNotifier: 'User account event view get.',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to get account event view.',
            ...snackBarFailure,
          });

          return dispatchFailure(action);
        });
    },
    reportProjectListByUser({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'User/project/report',
        context: { payload, httpQuery },
        textNotifier: 'Project list by user.',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({ message: 'Unable to report project list.', ...snackBarFailure });

          return dispatchFailure(action);
        });
    },
    getProjectByUser({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'User/project/get',
        context: { payload, httpQuery },
        textNotifier: 'Project by user.',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({ message: 'Unable to get project.', ...snackBarFailure });

          return dispatchFailure(action);
        });
    },
    reportClientListByUser({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'User/client/report',
        context: { payload, httpQuery },
        textNotifier: 'Client list by user.',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({ message: 'Unable to report client list.', ...snackBarFailure });

          return dispatchFailure(action);
        });
    },
    getClientByUser({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'User/client/get',
        context: { payload, httpQuery },
        textNotifier: 'get client by user.',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({ message: 'Unable to get client by user.', ...snackBarFailure });

          return dispatchFailure(action);
        });
    },
    reportSpendsListByUser({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'User/spends/reportByUser',
        context: { payload, httpQuery },
        textNotifier: 'Spends list by user.',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({ message: 'Unable to report spends list.', ...snackBarFailure });

          return dispatchFailure(action);
        });
    },
    reportAppointmentList({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'User/appointment/report',
        context: { payload, httpQuery },
        textNotifier: 'report appointment list.',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({ message: 'Unable to report appointment list.', ...snackBarFailure });

          return dispatchFailure(action);
        });
    },
    getAppointment({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'User/appointment/get',
        context: { payload, httpQuery },
        textNotifier: 'get appointment list.',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({ message: 'Unable to get appointment list.', ...snackBarFailure });

          return dispatchFailure(action);
        });
    },
    createAppointment({ dispatch, rootGetters }, { payload = {}, httpQuery = {}, options = {} }) {
      const { 'TheAuthenticate/currentUid': uid } = rootGetters;
      const { 'TheAuthenticate/currentAccountAid': aid } = rootGetters;
      const action = {
        dispatch,
        target: 'User/appointment/post',
        context: {
          httpQuery,
          options,
          payload: {
            uid, aid, userUid: uid, ...payload,
          },
        },
        config: {
          start: false,
          end: true,
          endContent: 'Appointment created successfully',
          error: 'Unable to create appointment',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    patchAppointment({ dispatch, rootGetters }, { payload = {}, httpQuery = {}, options = {} }) {
      const { 'TheAuthenticate/currentUid': uid } = rootGetters;
      const action = {
        dispatch,
        target: 'User/appointment/patch',
        context: { payload: { uid, ...payload }, httpQuery, options },
        config: {
          start: false,
          end: true,
          endContent: 'Appointment patched successfully',
          error: 'Unable to patch an appointment.',
        },
      };
      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    deleteAppointment({ dispatch, rootGetters }, { payload = {}, httpQuery = {}, options = {} }) {
      const { 'TheAuthenticate/currentUid': uid } = rootGetters;
      const action = {
        dispatch,
        target: 'User/appointment/delete',
        context: { payload: { uid, ...payload }, httpQuery, options },
        config: {
          start: false,
          end: false,
          error: 'Unable to delete an appointment.',
        },
      };
      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    updateAppointmentSettings({ dispatch, rootGetters }, { payload = {}, httpQuery = {} }) {
      const { 'TheAuthenticate/currentUid': uid } = rootGetters;
      const action = {
        dispatch,
        target: 'User/settings/updateAppointmentSettings',
        context: { payload: { uid, ...payload }, httpQuery },
        config: {
          start: false,
          end: true,
          endContent: 'Appointment settings updated successfully',
          error: 'Unable to update appointment settings',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    reportNotifications({ dispatch, rootGetters }, { payload = {}, httpQuery = {} }) {
      const { 'TheAuthenticate/currentUid': uid } = rootGetters;
      const action = {
        dispatch,
        target: 'User/notification/report',
        context: { payload: { uid, ...payload }, httpQuery },
        config: {
          start: false,
          end: false,
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    patchNotification({ dispatch, rootGetters }, { payload = {}, httpQuery = {} }) {
      const { 'TheAuthenticate/currentUid': uid } = rootGetters;
      const action = {
        dispatch,
        target: 'User/notification/patch',
        context: { payload: { uid, ...payload }, httpQuery },
        config: { start: false, end: false },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
  },
};
