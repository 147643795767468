// import allowedAddons from '../conf/addons';
import dataStores from './dataStores';
import { documentation } from './Morpheus';

const allowedAddons = [
  'app',
  'authenticate',
  'appointment',
  'billings',
  'calendar',
  'cgu',
  'cgv',
  'clients',
  'common',
  'dashboard',
  'dev',
  'error',
  'firstConnection',
  'members',
  'menu',
  'messaging',
  'networking',
  'notifications',
  'notifier',
  'pricing',
  'projects',
  'settings',
  'tags',
  'templates',
  'wsNotifier',
  'dataStores',
  'documentation',
  // 'home',
];

const addonsFolder = require.context('./addons', true, /.js$/);

const addons = addonsFolder.keys()
  .reduce((acc, path) => {
    const [myAddon] = path.split('/').slice(1);

    if (allowedAddons.includes(myAddon) && !acc[myAddon]) {
      // eslint-disable-next-line
      const addon = require(`./addons/${myAddon}`);
      if (addon.default) {
        acc[myAddon] = addon.default;
        return acc;
      }
      Object.entries(addon).forEach(([addonName, addonCore]) => {
        acc[addonName] = addonCore;
      });
      return acc;
    }
    return acc;
  }, { dataStores, documentation });

const register = (addonsList, key) => Object.entries(addonsList)
  .reduce((acc, [addonKey, addonValue]) => {
    if (allowedAddons.includes(addonKey) && addonValue[key]) acc.push(addonValue[key]);
    return acc;
  }, []);

const routes = { ...register(addons, 'routes') };

const stores = { ...register(addons, 'stores') };

const lib = { ...register(addons, 'lib') };

const smarts = { ...register(addons, 'smarts') };

export {
  routes,
  stores,
  lib,
  smarts,
};
