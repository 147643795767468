<template>
  <section>
    <div class="is-flex is-align-items-center is-justify-content-flex-end">
      <b-tooltip
        type="is-dark"
        :label="!open ? $_('Add a new payment') : $_('Close')"
        position="is-left"
      >
        <b-button
          type="is-tertiary"
          icon-pack="fa"
          :icon-left="!open ? 'plus' : 'xmark'"
          expanded
          :class="[{
            'turn-signal': !entite.payments
          }]"
          @click="open = !open"
        />
      </b-tooltip>
    </div>
    <DevDetails :cnt="{name: 'paymentSorted', obj: paymentSorted}" />

    <div class="company-payment">
      <div class="payments-container">
        <AddPayment
          v-if="open"
          :open="open"
          :project-list="projectList"
          :currencies="currencies"
          :conditions="conditions"
          :client="client"
          @addPayment="addPayment($event)"
        />
        <PaymentList
          v-else
          :payment-list="paymentSorted"
          @deletePayment="$emit('deletePayment', $event)"
        />
      </div>
    </div>
  </section>
</template>

<script>
/* eslint-disable max-len */
import moment from 'moment';

export default {
  name: 'Payment',
  props: {
    entite: { type: Object, required: true },
    projectList: { type: Array, default: () => [] },
  },
  data() {
    return {
      open: false,
    };
  },
  computed: {
    client() {
      if (!this.entite.client) return null;
      return this.entite.client;
    },
    projects() {
      if (!this.entite.projects || !this.entite.projects.length) return null;
      return this.entite.projects;
    },
    paymentSorted() {
      if (!this.entite.payments) return [];

      const projects = this.projects
        ? this.projects.reduce((acc, p) => {
          acc[p.pid] = { ...p }; return acc;
        }, {})
        : null;

      return this.entite.payments.reduce((acc, payment) => {
        const { projectPid } = payment;
        acc.push({
          ...payment,
          project: projects && projects[projectPid] ? projects[projectPid] : null,
        });
        return acc;
      }, []).sort((a, b) => moment(b.details.date).unix() - moment(a.details.date).unix()).reverse();
    },
    conditions() {
      return [
        { name: 'Fixed', key: 'fixed' },
        { name: 'Per hour', key: 'per-hour' },
        { name: 'Per day', key: 'per-day' },
        { name: 'Per week', key: 'per-week' },
        { name: 'Per month', key: 'per-month' },
        { name: 'Per quarter', key: 'per-quarter' },
        { name: 'Per year', key: 'per-year' },
      ];
    },
    currencies() {
      return [
        { name: '€', key: 'EUR' },
        // { name: '$', key: 'USD' },
        // { name: '£', key: 'GBP' },
        // { name: '¥', key: 'JPY' },
      ];
    },
  },
  methods: {
    addPayment(event) {
      this.$emit('addPayment', event);
      this.open = false;
    },
  },
};
</script>
