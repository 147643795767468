var render = function render(){var _vm=this,_c=_vm._self._c;return _c('article',{class:['ws-notifier', {
    'ws-n-success': _vm.notif.type === 'success',
    'ws-n-danger': _vm.notif.type === 'danger',
    'ws-n-info': _vm.notif.type === 'info',
    'ws-n-warning': _vm.notif.type === 'warning',
    'ended': _vm.progressWidth >= 100 || _vm.ended
  }]},[_c('div',{staticClass:"ws-close"},[_c('b-button',{attrs:{"type":"is-tertiary ultra-small","icon-pack":"fa","icon-left":"xmark"},on:{"click":function($event){_vm.closeNotif(1000); _vm.ended = true}}})],1),_c('div',{staticClass:"ws-contents"},[_c('div',{staticClass:"ws-icon"},[_c('b-icon',{attrs:{"pack":"fa","size":"is-large","icon":_vm.icons[_vm.notif.type]}})],1),_c('div',{staticClass:"ws-content"},[_c('p',{class:['subtext is-big', {

          'text-color-5': _vm.notif.type === 'success',
          'text-color-2': _vm.notif.type === 'danger',
          'text-color-7': _vm.notif.type === 'info',
          'text-color-3': _vm.notif.type === 'warning',
        }]},[_vm._v(" "+_vm._s(_vm.notif.title)+" ")]),_c('p',{class:['disclamer is-small', {

          'text-color-5': _vm.notif.type === 'success',
          'text-color-2': _vm.notif.type === 'danger',
          'text-color-7': _vm.notif.type === 'info',
          'text-color-3': _vm.notif.type === 'warning',
        }]},[_vm._v(" "+_vm._s(_vm.notif.content)+" ")])])]),_c('div',{class:['bar-time-container', {
      'bg-color-5-40': _vm.notif.type === 'success',
      'bg-color-2-40': _vm.notif.type === 'danger',
      'bg-color-7-40': _vm.notif.type === 'info',
      'bg-color-3-40': _vm.notif.type === 'warning',
    }]},[_c('div',{class:['bar-time', {
        'bg-color-5-90': _vm.notif.type === 'success',
        'bg-color-2-90': _vm.notif.type === 'danger',
        'bg-color-7-90': _vm.notif.type === 'info',
        'bg-color-3-90': _vm.notif.type === 'warning',
      }],style:(`width:${_vm.progressWidth}%`)})])])
}
var staticRenderFns = []

export { render, staticRenderFns }