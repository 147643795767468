<template>
  <div>
    <b-collapse
      class="card no-border no-shadow"
      animation="slide"
      aria-id="contentIdForA11y3"
    >
      <template #trigger="props">
        <div
          class="card-header no-border"
          role="button"
          aria-controls="contentIdForA11y3"
          :aria-expanded="props.open"
        >
          <p class="card-header-title">
            Companies selected to import
          </p>
          <a class="card-header-icon">
            <b-icon
              pack="fa"
              :icon="props.open ? 'menu-down' : 'menu-up'"
            />
          </a>
        </div>
      </template>

      <div class="card-content list">
        <div class="content">
          <article
            v-for="row in copyCheckedRows.toFetch"
            :key="row.jid"
            class="card mb-3 no-border"
          >
            <div class="content px-5 py-3">
              <b-field class="is-flex is-align-items-center">
                <b-checkbox v-model="row.active">
                  {{ row.companyName }}
                </b-checkbox>
              </b-field>
            </div>
          </article>
        </div>
      </div>
    </b-collapse>

    <br>

    <div class="multibar">
      <b-button
        type="is-success"
        label="Export"
        class="end"
        :disabled="!hasOneActive"
        @click="$emit('launchAgent', copyCheckedRows.toFetch)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BulkImportCompaniesSelected',
  props: {
    checkedRows: { type: Array, required: true },
  },
  computed: {
    copyCheckedRows() {
      return this.checkedRows.reduce((acc, el) => {
        if (!el.companyName || !el.companyUrl) {
          acc.error.push(el);
          return acc;
        }

        const findCompany = acc.toFetch.find(({ companyName }) => companyName === el.companyName);

        if (!findCompany) acc.toFetch.push(({ ...el, active: true }));
        return acc;
      }, {
        toFetch: [],
        error: [],
      });
    },
    hasOneActive() {
      return !!this.copyCheckedRows.toFetch.find(({ active }) => active);
    },
  },
};
</script>
