import TheClients from './TheClients';
import TheClientsDetails from './TheClientsDetails';
import TheClientsAdd from './TheClientsAdd';

export default [
  {
    path: '/clients',
    name: 'Clients',
    component: TheClients,
    meta: {
      hasMenu: true,
      viewStoreNavigation: 'TheHome',
      requireAuth: true,
    },
  },
  {
    path: '/clients/:cid/details',
    name: 'Clients - Details',
    component: TheClientsDetails,
    meta: {
      hasMenu: true,
      viewStoreNavigation: 'TheClients',
      requireAuth: true,
    },
  },
  {
    path: '/clients/add',
    name: 'Clients - Add',
    component: TheClientsAdd,
    meta: {
      hasMenu: true,
      viewStoreNavigation: 'TheClients',
      requireAuth: true,
    },
  },
];
