/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import { navySeal } from '@jack/provider';

export default {
  namespaced: true,
  state: {
    projectList: null,
    project: null,
  },
  mutations: {
    SET_PROJECT_LITS(state, newData) { state.projectList = newData; },
    SET_PROJECT(state, newData) { state.project = newData; },
  },
  getters: {
    getterUserProjectList: (state) => state.projectList,
    getterUserProject: (state) => state.project,
  },
  actions: {
    report({ commit }, { payload, httpQuery = {} }) {
      return navySeal.apiWsDivision.user.project.report({ payload, httpQuery })
        .then((projects) => {
          commit('SET_PROJECT_LITS', projects.arrayMapper('pid'));
        });
    },
    get({ commit }, { payload, httpQuery = {} }) {
      return navySeal.apiWsDivision.user.project.get({ payload, httpQuery })
        .then(({ details, ...rest }) => {
          commit('SET_PROJECT', { ...details, ...rest });
          commit('Project/kanban/SET_KANBAN_LIST', rest.kanbanList.arrayMapper('kid'), { root: true });
        });
    },
    setIsOpen({ commit, getters }, { payload }) {
      return navySeal.apiWsDivision.user.project.setIsOpen.post({ payload, httpQuery: {} })
        .then(() => {
          const {
            getterUserProjectList: projectList,
            getterUserProject: project,
          } = getters;

          if (projectList) {
            projectList[payload.pid].isOpen = true;
            commit('SET_PROJECT_LITS', projectList);
          }

          if (project && project.pid && payload.pid) {
            project.isOpen = true;
            commit('SET_PROJECT', project);
          }
        });
    },
    unsetIsOpen({ commit, getters }, { payload }) {
      return navySeal.apiWsDivision.user.project.setIsOpen.delete({ payload, httpQuery: {} })
        .then(() => {
          const {
            getterUserProjectList: projectList,
            getterUserProject: project,
          } = getters;

          if (projectList) {
            projectList[payload.pid].isOpen = false;
            commit('SET_PROJECT_LITS', projectList);
          }

          if (project && project.pid && payload.pid) {
            project.isOpen = false;
            commit('SET_PROJECT', project);
          }
        });
    },
  },
};
