/* eslint-disable import/no-extraneous-dependencies */
import { getters, actions } from '@jack/dataStores/commonViewStores';
import { SnackbarProgrammatic as Snackbar } from 'buefy';
import { dispatchAndForward, dispatchFailure, snackBarFailure } from '@jack/dataStores/$utils/helpers';

export default {
  namespaced: true,
  state: {},
  getters: {
    ...getters.agents,
    ...getters.authenticate,
    phantomBusterToken: (_state, _getters, _rootState, rootGetters) => {
      const { 'PhantomBuster/token/getterToken': phantomBusterToken } = rootGetters;
      return phantomBusterToken;
    },
  },
  actions: {
    ...actions.agents,
    createToken({ dispatch, rootGetters }, { payload = {}, httpQuery = {} }) {
      const { 'TheAuthenticate/currentAccountAid': accountAid } = rootGetters;

      const action = {
        dispatch,
        target: 'PhantomBuster/token/create',
        context: { payload: { ...payload, accountAid }, httpQuery },
        textNotifier: 'create phantom buster token',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to create phantom buster token.',
            ...snackBarFailure,
          });
          return dispatchFailure(action);
        });
    },
    patchToken({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'PhantomBuster/token/patch',
        context: { payload, httpQuery },
        textNotifier: 'patch phantom buster token',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to patch phantom buster token.',
            ...snackBarFailure,
          });
          return dispatchFailure(action);
        });
    },
    deleteToken({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'PhantomBuster/token/delete',
        context: { payload, httpQuery },
        textNotifier: 'delete phantom buster token',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to delete phantom buster token.',
            ...snackBarFailure,
          });
          return dispatchFailure(action);
        });
    },
  },
};
