<template>
  <div class="dashboard">
    <div class="content">
      <section class="tag-dash">
        <aside class="tag-list-container">
          <b-field
            label="Search tag"
            class="bg-solitude mb-3"
          >
            <b-input
              v-model="inputSearch"
              type="search"
              icon="magnifying-glass"
              icon-pack="fa"
              icon-right="xmark"
              icon-right-clickable
              class="w-100"
              @icon-right-click="inputSearch = ''"
            />
          </b-field>
          <nav>
            <article
              class="tag-card add"
              @click="_create()"
            >
              <b-icon
                icon="plus"
                pack="fa"
                size="is-large"
              />
            </article>
            <DevDetails :cnt="{name: 'tagSorted', obj: tagSorted}" />
            <template v-if="tagSorted.length">
              <article
                v-for="tag in tagSorted"
                :key="tag.tid"
                :class="`tag-card border-color-${tag.color}-60 bg-color-${tag.color}-60`"
                @click="selectTag(tag)"
              >
                <p class="text is-small m-0">{{ tag.label }}</p>
              </article>
            </template>
          </nav>
        </aside>
        <div class="tag-content">
          <NoData
            v-if="!selected && tagSorted.length"
            title="No tag selected"
            content="Select a tag in the left panel to modify or delete it"
            class="noTemplate"
          />
          <NoData
            v-if="!tagSorted.length"
            title="No tags found"
            content="You may register a new tag at any time"
            class="noTemplate"
          />
          <div v-if="selected && tagSorted.length">
            <div class="editor">
              <p
                class="title text-modifiable m-0 "
                contenteditable
                :spellcheck="spellcheck"
                @input="_localPatch({ evt: $event, input: 'label' })"
              >
                {{ selected.label }}
              </p>
              <div class="buttons">
                <b-tooltip
                  type="is-dark"
                  label="Update"
                  position="is-bottom"
                  append-to-body
                >
                  <b-button
                    icon-pack="fa"
                    icon-left="check"
                    type="is-tertiary"
                    :disabled="!tagModified"
                    @click="_patch(selected)"
                  />
                </b-tooltip>
                <b-tooltip
                  type="is-dark"
                  label="Delete"
                  position="is-bottom"
                  append-to-body
                >
                  <b-button
                    icon-pack="fa"
                    icon-left="trash"
                    type="is-tertiary"
                    @click="_delete({ tid: selected.tid })"
                  />
                </b-tooltip>
              </div>
            </div>

            <br>

            <div class="buttons">
              <b-button
                v-for="color in 12"
                :key="color"
                :class="[`color-button bg-color-${color}-60`, {
                  'border-color-1': color === selected.color && selected.color === 1,
                  'border-color-2': color === selected.color && selected.color === 2,
                  'border-color-3': color === selected.color && selected.color === 3,
                  'border-color-4': color === selected.color && selected.color === 4,
                  'border-color-5': color === selected.color && selected.color === 5,
                  'border-color-6': color === selected.color && selected.color === 6,
                  'border-color-7': color === selected.color && selected.color === 7,
                  'border-color-8': color === selected.color && selected.color === 8,
                  'border-color-9': color === selected.color && selected.color === 9,
                  'border-color-10': color === selected.color && selected.color === 10,
                  'border-color-11': color === selected.color && selected.color === 11,
                  'border-color-12': color === selected.color && selected.color === 12,
                }]"
                @click="_localPatch({ value: color, input: 'color' })"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */
import { debounce } from '@jack/addons/$utils/helpers';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TheTags',
  data() {
    return {
      search: null,
      inputSearch: null,
      selected: null,
      form: {
        tid: null,
        label: null,
        color: null,
      },
      slice: { min: 0, max: 50 },
      spellcheck: false,
    };
  },
  computed: {
    ...mapGetters({
      accountAid: 'TheTags/currentAccountAid',
      tagList: 'TheTags/tagList',
    }),
    tagModified() {
      if (!this.selected) return false;
      if (!this.form.label && !this.form.color) return false;
      const { tid, label, color } = this.selected;
      const tInit = JSON.stringify({ tid, label, color });
      const tCopy = JSON.stringify(this.form);
      if (tInit === tCopy) return false;
      return true;
    },
    tagSorted() {
      if (!Object.values(this.tagList).length) return [];

      return Object.values(this.tagList).reduce((acc, tag) => {
        const { label } = tag;

        if (this.search) {
          const _label = label.toLowerCase();
          if (_label.includes(this.search)) acc.push(tag);
          return acc;
        }

        acc.push(tag);
        return acc;
      }, []).sort((a, b) => this.$moment(b.createdAt) - this.$moment(a.createdAt));
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    inputSearch: debounce(function (newVal) {
      this.search = newVal.toLowerCase();
    }, 0),
  },
  methods: {
    ...mapActions({
      _createTag: 'TheTags/createTag',
      _patchTag: 'TheTags/patchTag',
      _deleteTag: 'TheTags/deleteTag',
    }),
    _localPatch({ evt, input, value }) { this.form[input] = evt ? evt.target.innerText : value; },
    _patch({ label: lInit, color: cInit, tid }) {
      const { label: sUpdt, color: cUpdt } = this.form;
      const label = sUpdt || lInit;
      const color = cUpdt || cInit;

      this._patchTag({
        payload: {
          label, color, tid,
        },
      });
    },
    _delete(payload) { this.resetParams().then(() => this._deleteTag({ payload })); },
    _create() {
      this.resetParams()
        .then(() => this._createTag({
          payload: {
            label: 'New tag',
            color: 5,
            createdAt: this.$moment().format(),
            accountAid: this.accountAid,
          },
        }))
        .then(({ response: tag }) => this.selectTag(tag));
    },
    selectTag(tag) {
      this.resetParams()
        .then(() => {
          this.form.tid = tag.tid;
          this.selected = tag;
        });
    },
    resetParams() {
      return Promise.resolve()
        .then(() => {
          this.selected = null;
          this.form = { label: null, color: null, tid: null };
        });
    },
  },
};
</script>
