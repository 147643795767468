/* eslint-disable max-len */
import { dispatchAndForward, dispatchFailure } from '../$utils/helpers';

export default {
  getters: {
    tagList: (_state, _getters, _rootState, rootGetters) => {
      const { 'Tag/getterTagList': tagList } = rootGetters;
      return tagList;
    },
  },
  actions: {
    createTag({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'Tag/post',
        context: { payload, httpQuery },
        config: {
          start: false,
          end: true,
          endContent: 'Tag created successfully',
          error: 'Unable to create a tag.',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    patchTag({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'Tag/patch',
        context: { payload, httpQuery },
        config: {
          start: false,
          end: true,
          endContent: 'Tag updated successfully',
          error: 'Unable to updated tag',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    deleteTag({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'Tag/delete',
        context: { payload, httpQuery },
        config: {
          start: false,
          end: true,
          endContent: 'Tag deleted successfully',
          error: 'Unable to delete this tag',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
  },
};
