/* eslint-disable import/no-extraneous-dependencies */
import { actions } from '@jack/dataStores/commonViewStores';

export default {
  namespaced: true,
  state: {},
  getters: {},
  actions: {
    ...actions.socket,
  },
};
