/* eslint-disable global-require */
/* eslint-disable import/prefer-default-export */
const navySeal = {
  apiWsJoe: require('./apiWsJoe/api'),
  apiWsMi: require('./apiWsMi/api'),
  apiWsDivision: require('./apiWsDivision/api'),
  apiSocials: require('./apiSocials/api'),
  socketIo: require('./socketIo'),
};

const providers = {
  navySeal,
};

// eslint-disable-next-line import/no-mutable-exports
let store = {};

const setStore = (s) => { store = s; };

export default providers;

export {
  navySeal,
  store,
  setStore,
};
