import Vue from 'vue';
import Vuex from 'vuex';

import { stores as addonsStores } from './addons';

Vue.use(Vuex);

const storeContructor = {
  state: {},
  actions: {},
  modules: {},
};

Object.values(addonsStores).forEach((addon) => {
  Object.entries(addon).forEach(([storeName, store]) => {
    storeContructor.modules[storeName] = store;
  });
});

const store = new Vuex.Store(storeContructor);

// window.store = store;
export default store;
