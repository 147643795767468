/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable max-len */
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      devMode: false,
    };
  },
  computed: {
    ...mapGetters({
      windowWidth: 'TheMenu/getterWindowWidth',
      account: 'TheMenu/account',
      accountFeatures: 'TheMenu/accountFeatures',
      adminOnAccount: 'TheMenu/adminOnAccount',
      myAuthenticate: 'TheMenu/myAuthenticate',
      isActive: 'DevDetails/isActive',
      theme: 'Starter/getterTheme',
      notificationList: 'TheMenu/notificationList',
    }),
    menu() {
      return [
        {
          legend: this.$_('Application'),
          visible: true,
          apps: [
            this.navigations.dashboardUser,
            this.navigations.calendar,
            this.navigations.networking,
            this.navigations.clients,
            this.navigations.projects,
            this.navigations.messaging,
            this.navigations.notifications,
          ],
        },
        {
          legend: this.$_('Account'),
          visible: !!this.adminOnAccount,
          apps: [
            this.navigations.dashboardAccount,
            this.navigations.members,
            this.navigations.billings,
            this.navigations.pricing,
          ],
        },
        {
          legend: this.$_('Profile'),
          visible: true,
          apps: [
            this.navigations.userSettings,
            this.navigations.templates,
            this.navigations.tags,
          ],
        },
      ];
    },
    menuResponsive() {
      return {
        top: [
          this.navigations.userSettings,
          this.navigations.templates,
          this.navigations.tags,
        ],
        bottom: [
          this.navigations.dashboardUser,
          this.navigations.calendar,
          {
            name: this.$_('Add'),
            icon: 'plus',
            pack: 'fa',
            active: true,
            expanded: false,
            admin: false,
            children: [
              {
                name: this.$_('Appointment'), active: true, pack: 'fa', icon: 'calendar-plus', event: 'MenuAddAppointment', disabled: false,
              },
              {
                name: this.$_('Payment'), active: true, pack: 'fa', icon: 'dollar', event: 'MenuAddPayment', disabled: false,
              },
              {
                name: this.$_('Client'), active: true, pack: 'fa', icon: 'user-plus', path: '/clients/add', disabled: false,
              },
              {
                name: this.$_('Project'), active: true, pack: 'fa', icon: 'briefcase', path: '/projects/add', disabled: true,
              },
              {
                name: this.$_('Spend'), active: true, pack: 'fa', icon: 'hand-holding-usd', event: 'MenuAddSpend', disabled: true,
              },
            ],
          },
          this.navigations.messaging,
          {
            name: this.$_('Plus'),
            icon: 'ellipsis-vertical',
            pack: 'fa',
            active: true,
            expanded: false,
            admin: false,
            children: [
              ...this.navigations.networking.children,
              this.navigations.clients,
              this.navigations.projects,
            ],
          },
        ],
      };
    },
    navigations() {
      return {
        dashboardUser: {
          name: this.$_('Dashboard'),
          icon: 'gauge-simple',
          pack: 'fa',
          path: '/dashboard/user',
          active: this.hasFeatures({ feature: 'DASHBOARD', subfeature: 'MY_DASHBOARD' }),
          expanded: false,
          admin: false,
          disabled: false,
          badge: false,
          badgeContent: null,
          children: [],
        },
        dashboardAccount: {
          name: this.$_('Account dashboard'),
          icon: 'gauge',
          pack: 'fa',
          path: '/dashboard/account',
          active: this.hasFeatures({ feature: 'DASHBOARD', subfeature: 'ACCOUNT_DASHBOARD' }),
          expanded: false,
          admin: false,
          disabled: true,
          badge: false,
          badgeContent: null,
          children: [],
        },
        calendar: {
          name: this.$_('Calendar'),
          icon: 'calendar',
          pack: 'fas',
          path: '/calendar',
          active: this.hasFeatures({ feature: 'CALENDAR' }),
          expanded: false,
          admin: false,
          disabled: false,
          badge: false,
          badgeContent: null,
          children: [],
        },
        notifications: {
          name: this.$_('Notifications'),
          icon: 'bell',
          pack: 'fas',
          path: '/notifications',
          active: true,
          expanded: false,
          admin: false,
          disabled: false,
          badge: true,
          badgeContent: this.newNotifications,
          children: [],
        },
        networking: {
          name: this.$_('Networking'),
          icon: 'globe',
          pack: 'fa',
          path: '/networking',
          active: this.hasFeatures({ feature: 'NETWORKING' }),
          expanded: true,
          admin: false,
          disabled: false,
          badge: false,
          badgeContent: null,
          children: [
            // { name: this.$_('Alerts'), path: '/networking/alerts', active: this.hasFeatures({ feature: 'NETWORKING', subfeature: 'ALERT' }) },
            // { name: this.$_('Liked'), path: '/networking/liked', active: this.hasFeatures({ feature: 'NETWORKING', subfeature: 'LIKED' }) },
            {
              name: this.$_('Companies'), path: '/networking/companies', active: this.hasFeatures({ feature: 'NETWORKING', subfeature: 'COMPANIES' }), pack: 'fa', icon: 'building', disabled: false,
            },
            {
              name: this.$_('Contacts'),
              path: '/networking/contacts',
              active: this.hasFeatures({
                feature: 'NETWORKING', subfeature: 'CONTACT',
              }),
              pack: 'fa',
              icon: 'address-book',
              disabled: false,
            },
            // { name: this.$_('Manage phantom', path: '/networking/manage-phantoms', active: this.hasFeatures({ feature: 'NETWORKING', subfeature: 'MANAGE_PHANTOMS' }) },
          ],
        },
        messaging: {
          name: this.$_('Messaging'),
          icon: 'message',
          pack: 'fa',
          path: '/messaging',
          active: this.hasFeatures({ feature: 'MESSAGING' }),
          expanded: false,
          admin: false,
          disabled: false,
          badge: false,
          badgeContent: null,
          children: [],
        },
        clients: {
          name: this.$_('Clients'),
          icon: 'shop',
          pack: 'fas',
          path: '/clients',
          active: this.hasFeatures({ feature: 'CLIENTS' }),
          expanded: false,
          admin: false,
          disabled: false,
          badge: false,
          badgeContent: null,
          children: [],
        },
        projects: {
          name: this.$_('Projects'),
          icon: 'briefcase',
          pack: 'fas',
          path: '/projects',
          active: this.hasFeatures({ feature: 'PROJECTS' }),
          expanded: false,
          admin: false,
          disabled: false,
          badge: false,
          badgeContent: null,
          children: [],
        },
        userSettings: {
          name: this.$_('Settings'),
          pack: 'fa',
          icon: 'user-gear',
          path: '/profile',
          active: true,
          expanded: false,
          disabled: false,
          badge: false,
          badgeContent: null,
          children: [],
          // {
          //   name: this.$_('Theme'), path: '/settings/theme', active: true,
          // },
          // {
          //   name: this.$_('Profile'), path: '/settings/profile', active: true, pack: 'fa', icon: 'user-gear', disabled: false,
          // },
          // {
          //   name: this.$_('PhantomBuster'), path: '/settings/phantombuster', active: this.hasFeatures({ feature: 'NETWORKING', subfeature: 'SETTING_PHANTOMBUSTER' }),
          // },
        },
        templates: {
          name: this.$_('Templates'),
          pack: 'fa',
          icon: 'paper-plane',
          path: '/templates',
          active: true,
          expanded: false,
          disabled: false,
          admin: false,
          badge: false,
          badgeContent: null,
          children: [],
        },
        billings: {
          name: this.$_('Billing'),
          pack: 'fa',
          icon: 'receipt',
          path: '/billing',
          active: true,
          expanded: false,
          disabled: true,
          admin: true,
          badge: false,
          badgeContent: null,
          children: [],
        },
        pricing: {
          name: this.$_('Pricing'),
          pack: 'fa',
          icon: 'circle-plus',
          path: '/pricing',
          active: true,
          expanded: false,
          disabled: true,
          admin: true,
          badge: false,
          badgeContent: null,
          children: [],
        },
        members: {
          name: this.$_('Members'),
          pack: 'fa',
          icon: 'users-gear',
          path: '/members',
          active: true,
          expanded: false,
          disabled: true,
          badge: false,
          badgeContent: null,
          children: [],
        },
        tags: {
          name: this.$_('Tags'),
          pack: 'fa',
          icon: 'tags',
          path: '/tags',
          active: true,
          expanded: false,
          disabled: false,
          badge: false,
          badgeContent: null,
          children: [],
        },
      };
    },
    currentAccountName() {
      if (!this.myAuthenticate) return 'NO_ACCOUNT_ERROR';
      return '';
    },
    hasCollapse() {
      if (!this.responsive) return true;
      if (!this.open) return false;
      return true;
    },
    currentEnv() {
      return process.env.VUE_APP_MODE;
    },
    newNotifications() {
      if (!this.notificationList || !this.notificationList.length) return 0;
      return this.notificationList.filter(({ read }) => !read).length;
    },
  },
  watch: {
    devMode(newVal) {
      this.toggleActiveDevDetails(newVal);
    },
  },
  methods: {
    ...mapActions({
      toggleActiveDevDetails: 'DevDetails/toggleActiveDevDetails',
      _logout: 'TheMenu/logout',
      updateTheme: 'Starter/updateTheme',
    }),
    pathAccess(pathAdmin) {
      const { isAdmin } = this.myAuthenticate;

      if (!pathAdmin) return true;
      if (isAdmin) return true;

      return false;
    },
    logout() {
      this._logout({})
        .then(() => { window.location.assign('/auth'); });
    },
    hasFeatures(event) {
      const allFeatures = this.accountFeatures;

      const { feature, subfeature } = event;

      if (!allFeatures[feature]) return false;
      if (subfeature && !allFeatures[feature][subfeature]) return false;
      return true;
    },
  },
};
