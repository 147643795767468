<template>
  <aside class="jackSidePanel">
    <div class="multibar">
      <div class="end buttons">
        <b-button
          class="is-tertiary"
          icon-pack="fas"
          icon-right="times"
          @click="$emit('quit')"
        />
      </div>
    </div>

    <h2 class="title start m-0">{{ project.label }}</h2>
    <p class="lead has-text-secondary">Create card</p>

    <form @submit.prevent="createKanbanItem()">
      <b-field label="Label">
        <b-input
          v-model="kanbanItem.label"
          type="text"
          required
        />
      </b-field>
      <b-field label="Description">
        <b-input
          v-model="kanbanItem.description"
          type="textarea"
        />
      </b-field>

      <br>

      <b-button
        type="is-primary"
        icon-pack="fa"
        icon-left="check"
        native-type="submit"
        expanded
      />
    </form>
  </aside>
</template>

<script>
export default {
  name: 'TheProjectsActivitiesAddItem',
  props: {
    project: { type: Object, required: true },
    kanbanList: { type: Object, required: true },
  },
  data() {
    return {
      kanbanItem: {
        label: null,
        description: null,
      },
    };
  },
  computed: {
    pid() { return this.$currentParam('pid'); },
    kid() { return this.$currentParam('kid'); },
    kcid() { return this.$currentParam('kcid'); },
  },
  methods: {
    createKanbanItem() {
      const { columns } = this.kanbanList[this.kid];
      const order = columns.arrayMapper('kcid')[this.kcid].items.length + 1;
      this.$emit('createItem', {
        ...this.kanbanItem,
        pid: this.pid,
        kid: this.kid,
        kcid: this.kcid,
        kanbanColumnKcid: this.kcid,
        order,
        createdAt: this.$moment().format(),
        editedAt: null,
        archivedAt: null,
      });
      this.$emit('quit');
    },
  },
};
</script>
