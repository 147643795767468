import { render, staticRenderFns } from "./ContactHistory.vue?vue&type=template&id=766239d4&"
import script from "./ContactHistory.vue?vue&type=script&lang=js&"
export * from "./ContactHistory.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports