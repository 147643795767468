/* eslint-disable import/no-extraneous-dependencies */
import { navySeal } from '@jack/provider';
import { isActionAllowed } from '@jack/dataStores/$utils/helpers';

import bulkInsertJobs from './bulkInsertJobs.store';

export default {
  namespaced: true,
  state: {
    jobList: [],
    job: null,
  },
  mutations: {
    SET_JOB_LIST(state, newData) { state.jobList = newData; },
    SET_JOB(state, newData) { state.job = newData; },
  },
  getters: {
    getterJobList: (state) => state.jobList,
    getterJob: (state) => state.job,
  },
  actions: {
    report({ commit, rootGetters, dispatch }, { payload = {}, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'JOB.REPORT', dispatch)) return undefined;

      return navySeal.apiWsMi.job.report({ payload, httpQuery })
        .then(({ results: jobs }) => commit('SET_JOB_LIST', jobs));
    },
    get({ commit, rootGetters, dispatch }, { payload = {}, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'JOB.GET', dispatch)) return undefined;

      return navySeal.apiWsMi.job.get({ payload, httpQuery })
        .then((job) => commit('SET_JOB', job));
    },
    patch({ commit, rootGetters, dispatch }, { payload = {}, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'JOB.PATCH', dispatch)) return undefined;

      return navySeal.apiWsMi.job.patch({ payload, httpQuery });
      // .then((job) => commit('SET_JOB', job));
    },
  },
  modules: {
    bulkInsertJobs,
  },
};
