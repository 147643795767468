<template>
  <section>
    <div class="multibar">
      <h3 class="start title is-small">
        What do you search ?
      </h3>
    </div>

    <br>

    <b-field
      label="Search"
      class="mb-3 test"
      horizontal
    >
      <b-input v-model="form.search" />
    </b-field>

    <template v-if="form.search">
      <br>

      <div class="buttons multibar">
        <b-button
          class="end"
          type="is-success"
          label="Search jobs"
          @click="$emit('search')"
        />
      </div>
    </template>
  </section>
</template>

<script>
export default {
  name: 'SidePanelAgentSearch',
  model: {
    prop: 'form',
  },
  props: {
    form: { type: Object, required: true },
  },
};
</script>
