/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import { navySeal } from '@jack/provider';

import eventsView from './eventsView.store';
import appointment from './appointment.store';
import bookAccount from './bookAccount.store';
import client from './client.store';
import dashboardView from './dashboardView.store';
import details from './details.store';
import payment from './payment.store';
import project from './project.store';
import spends from './spends.store';
import theme from './theme.store';
import userDashboardView from './userDashboardView.store';
import settings from './settings.store';
import notification from './notification.store';
import language from './language.store';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {},
  actions: {
    create(_, { payload }) {
      return navySeal.apiWsDivision.user.post({ payload, httpQuery: {} })
        .then((newUser) => newUser);
    },
  },
  modules: {
    eventsView,
    appointment,
    bookAccount,
    client,
    dashboardView,
    details,
    payment,
    project,
    spends,
    theme,
    userDashboardView,
    settings,
    notification,
    language,
  },
};
