<template>
  <section class="bulkAction">
    <div class="bulkActionContent">
      <div class="content">
        <div class="bulkActionContentSelected">
          <p>{{ numberSelected }} selected</p>
        </div>
        <div class="divider-horizontal" />
        <div class="buttons">
          <b-button
            v-if="buttonClose"
            :type="typeClose"
            :label="labelClose"
            @click="$emit('cancel')"
          />
          <b-button
            v-if="buttonAction"
            :type="typeAction"
            :label="labelAction"
            :disabled="BulkIsOPen"
            @click="to()"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'BulkActions',
  props: {
    numberSelected: {
      type: Number,
      required: true,
    },
    buttonClose: {
      type: Boolean,
      default: true,
    },
    typeClose: {
      type: String,
      default: 'is-ghost',
    },
    labelClose: {
      type: String,
      default: 'Cancel',
    },
    buttonAction: {
      type: Boolean,
      default: true,
    },
    typeAction: {
      type: String,
      default: 'is-primary',
    },
    labelAction: {
      type: String,
      default: 'Bulk actions',
    },
    tagAction: {
      type: String,
      default: 'router-link',
    },
    toAction: {
      type: Object,
      required: true,
    },
  },
  computed: {
    BulkIsOPen() {
      if (this.$route.path.includes(this.toAction.path)) return true;
      return false;
    },
  },
  methods: {
    to() {
      this.$emit('click');
      this.$router.push(this.toAction);
    },
  },
};
</script>
