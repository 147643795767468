/* eslint-disable import/no-extraneous-dependencies */
import { navySeal } from '@jack/provider';
import { isActionAllowed } from '@jack/dataStores/$utils/helpers';

export default {
  namespaced: true,
  actions: {
    post({ commit, rootGetters, dispatch }, { payload = {}, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'AUTHENTICATE.CHANGE_PASSWORD', dispatch)) return undefined;
      return navySeal.apiWsDivision.authenticate.changePassword.post({ payload, httpQuery })
        .then((authenticate) => {
          const { 'Authenticate/getterAuthenticate': myAuthenticate } = rootGetters;
          commit('Authenticate/SET_AUTHENTICATE', { ...myAuthenticate, aid: authenticate.aid, firstConnection: false }, { root: true });
          return authenticate;
        });
    },
  },
};
