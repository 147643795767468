<template>
  <section>
    <div class="multibar">
      <h3 class="start title is-small">
        Confirm new client
      </h3>
    </div>

    <br>

    <fieldset>
      <b-field :label="$_('First name')">
        <b-input
          v-model="newClient.details.firstName"
          :placeholder="$_('First name')"
          required
          readonly
        />
      </b-field>

      <br>

      <b-field :label="$_('Last name')">
        <b-input
          v-model="newClient.details.lastName"
          :placeholder="$_('Last name')"
          required
          readonly
        />
      </b-field>

      <br>

      <b-field :label="$_('Phone number')">
        <b-input
          v-model="newClient.details.number"
          :placeholder="$_('Phone number')"
          required
          readonly
        />
      </b-field>

      <br>

      <template v-for="(social, i) in newClient.socialList">
        <div
          v-if="social"
          :key="social.id"
        >
          <b-field :label="social.platformName">
            <b-input
              v-model="social.username"
              :placeholder="$_('Username')"
              required
              readonly
            />
          </b-field>

          <br v-if="i < newClient.socialList.length">
        </div>
      </template>
    </fieldset>

    <br>

    <div class="buttons is-right">
      <b-button
        class="is-primary"
        :label="$_('Create new client')"
        icon-pack="fas"
        icon-left="plus"
        @click="$emit('createNewClient')"
      />
    </div>
  </section>
</template>

<script>
export default {
  name: 'JackSidePanelAddClientConfirmation',
  model: {
    prop: 'newClient',
  },
  props: {
    newClient: { type: Object, default: () => {} },
  },
};
</script>
