<template>
  <section>
    {{ project }}
    {{ paymentList }}
  </section>
</template>

<script>
export default {
  name: 'TheProjectsOverview',
  props: {
    project: { type: Object, required: true },
    paymentList: { type: Array, required: true },
  },
};
</script>
