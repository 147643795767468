/* eslint-disable import/no-extraneous-dependencies */
import { navySeal } from '@jack/provider';
import { isActionAllowed } from '@jack/dataStores/$utils/helpers';

import bulkInsertPersons from './bulkInsertPersons.store';
import tag from './tag.store';

export default {
  namespaced: true,
  state: {
    personList: {},
    person: null,
    searchPerson: {},
  },
  mutations: {
    SET_PERSON_LIST(state, newData) { state.personList = newData; },
    SET_PERSON(state, newData) { state.person = newData; },
    UPDATE_PERSON_IN_PERSON_LIST(state, newData) {
      state.personList[newData.pid] = { ...state.personList[newData.pid], ...newData };
    },
    SET_SEARCH_PERSON_LIST(state, newData) { state.searchPerson = newData; },
  },
  getters: {
    getterPersonList: (state) => state.personList,
    getterPerson: (state) => state.person,
    getterSearchPerson: (state) => state.searchPerson,
  },
  actions: {
    report({ commit, rootGetters, dispatch }, { payload = {}, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'PERSON.REPORT', dispatch)) return undefined;

      return navySeal.apiWsMi.person.report({ payload, httpQuery })
        .then((persons) => {
          commit('SET_PERSON_LIST', persons.arrayMapper('pid'));
        });
    },
    search({ commit, rootGetters, dispatch }, { payload = {}, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'PERSON.REPORT', dispatch)) return undefined;

      return navySeal.apiWsMi.person.report({ payload, httpQuery })
        .then((persons) => {
          commit('SET_SEARCH_PERSON_LIST', persons.arrayMapper('pid'));
        });
    },
    create({ commit, rootGetters, dispatch }, { payload = {}, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'PERSON.CREATE', dispatch)) return undefined;

      return navySeal.apiWsMi.person.post({ payload, httpQuery })
        .then((person) => {
          commit('SET_PERSON', person);
          return person;
        });
    },
    get({ commit, rootGetters, dispatch }, { payload = {}, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'PERSON.GET', dispatch)) return undefined;

      return navySeal.apiWsMi.person.get({ payload, httpQuery })
        .then((person) => {
          commit('SET_PERSON', person);
          commit('ContactHistory/SET_CONTACT_HISTORY_LIST', { entite: 'person', newData: person.histories }, { root: true });
          commit('Notes/SET_NOTES_LIST', { entite: 'person', newData: person.notes }, { root: true });
        });
    },
    patch({
      commit, rootGetters, getters, dispatch,
    }, { payload = {}, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'PERSON.PATCH', dispatch)) return undefined;

      return navySeal.apiWsMi.person.patch({ payload, httpQuery })
        .then((details) => {
          const { person: _person } = getters;
          commit('SET_PERSON', { ..._person, details: { ...details } });
        });
    },
    update({
      commit, getters, rootGetters, dispatch,
    }, { payload = {}, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'PERSON.UPDATE', dispatch)) return undefined;

      return navySeal.apiWsMi.person.update({ payload, httpQuery })
        .then(() => {
          const { details, ...person } = payload;
          const { details: _details, ...rest } = getters.getterPerson;

          const newPerson = { ...rest, ...person, details: { ..._details, ...details } };

          commit('SET_PERSON', newPerson);
          commit('UPDATE_PERSON_IN_PERSON_LIST', newPerson);
        });
    },
    delete({ commit, rootGetters, dispatch }, { payload = {}, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'PERSON.DELETE', dispatch)) return undefined;

      return navySeal.apiWsMi.person.delete({ payload, httpQuery })
        .then(() => {
          // TODO: delete person state in person list
          // TODO: delete person state
        });
    },
  },
  modules: {
    bulkInsertPersons,
    tag,
  },
};
