<template>
  <ChartJs
    type="bar"
    :width="width"
    :height="height"
    :id-chart="idChart"
    :init-chart="initChartBar"
    :data="data"
    :options-plugins-legend-labels-use-point-style="false"
    :options-plugins-legend-labels-box-width="0"
    :options-plugins-legend-labels-padding="0"
    :options-plugins-scales-y-begin-ticks-callback="optionsPluginsScalesYBeginTicksCallback"
    :options-plugins-scales-y-begin-ticks-step-size="initStepYAxis"
    options-plugins-scales-y-begin-at-zero
  />
</template>

<script>
export default {
  name: 'ChartBar',
  props: {
    width: { type: String, default: '300' },
    height: { type: String, default: '500' },
    idChart: { type: String, required: true },
    type: { type: String, default: 'line' },
    data: { type: Object, required: true },
    optionsIndexAxis: { type: String, default: 'x' },
    optionsElementsBarBackgroundColor: { type: String, default: '255, 99, 132' },
    optionsElementsBarBorderWidth: { type: Number, default: 0 },
    optionsElementsBarBorderColor: { type: String, default: '255, 99, 132' },
    optionsElementsBarBorderSkipped: { type: String, default: 'start' },
    optionsElementsBarBorderRadius: { type: [Number, Object], default: 4 },
    optionsElementsBarInflateAmount: { type: [Number, String], default: 'auto' },
    optionsElementsBarPointStyle: { type: String, default: 'circle' },
    optionsPluginsScalesYBeginTicksCallback: {
      type: Object,
      default: () => ({
        hasSymbol: false,
        hasNFormatter: true,
        symbolLeft: '€',
        symbolRight: '',
        nFormatterValue: 1,
      }),
    },
  },
  computed: {
    initChartBar() {
      return {
        bar: {
          backgroundColor: `rgba(${this.optionsElementsBarBackgroundColor}, 1)`,
          borderWidth: this.optionsElementsBarBorderWidth,
          borderColor: `rgba(${this.optionsElementsBarBorderColor}, 1)`,
          borderSkipped: this.optionsElementsBarBorderSkipped,
          borderRadius: this.optionsElementsBarBorderRadius,
          inflateAmount: this.optionsElementsBarInflateAmount,
          pointStyle: this.optionsElementsBarPointStyle,
        },
      };
    },
    initStepYAxis() {
      const { datasets: [{ data }] } = this.data;

      let sum = 0;

      const newArrayDataWithoutNullValues = data.reduce((acc, value) => {
        if (value === 'null' || !value) return acc;
        sum += Number(value);
        acc.push(Number(value));
        return acc;
      }, []);

      const min = Math.min(...newArrayDataWithoutNullValues);
      const max = Math.max(...newArrayDataWithoutNullValues);

      const maxSubtractMinLength = (max - min).toFixed(0).length;

      const tot = sum / newArrayDataWithoutNullValues.length;

      if (tot < 1) return 0.25;

      if (maxSubtractMinLength < 2) return 1;
      if (maxSubtractMinLength === 2) return 5;
      if (maxSubtractMinLength === 3) return 10 ** 2;
      if (maxSubtractMinLength >= 4 && maxSubtractMinLength < 5) return 10 ** 3;
      if (maxSubtractMinLength >= 5 && maxSubtractMinLength < 6) return 10 ** 4;
      if (maxSubtractMinLength >= 6) return 10 ** 5;
      return 10 ** 2;
    },
  },
};
</script>
