/* eslint-disable max-len */
import { SnackbarProgrammatic as Snackbar } from 'buefy';
import { dispatchAndForward, dispatchFailure, snackBarFailure } from '../$utils/helpers';

export default {
  getters: {
    companyContactHistoryList: (_state, _getters, _rootState, rootGetters) => {
      const { 'ContactHistory/gettersCompanyContactHistoryList': companyContactHistoryList } = rootGetters;
      return companyContactHistoryList;
    },
    personContactHistoryList: (_state, _getters, _rootState, rootGetters) => {
      const { 'ContactHistory/gettersPersonContactHistoryList': personContactHistoryList } = rootGetters;
      return personContactHistoryList;
    },
  },
  actions: {
    reportContactHistoryList({ dispatch }, { payload = {}, httpQuery = {}, options = {} }) {
      const action = {
        dispatch,
        target: 'ContactHistory/report',
        context: { payload, httpQuery, options },
        textNotifier: 'report contact history list.',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({ message: 'Unable to report contact history list.', ...snackBarFailure });
          return dispatchFailure(action);
        });
    },
    addContactHistory({ dispatch }, { payload = {}, httpQuery = {}, options = {} }) {
      const action = {
        dispatch,
        target: 'ContactHistory/create',
        context: { payload, httpQuery, options },
        textNotifier: 'add contact history.',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({ message: 'Unable add contact history.', ...snackBarFailure });
          return dispatchFailure(action);
        });
    },
    removeContactHistory({ dispatch }, { payload = {}, httpQuery = {}, options = {} }) {
      const action = {
        dispatch,
        target: 'ContactHistory/delete',
        context: { payload, httpQuery, options },
        textNotifier: 'remove contact history.',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({ message: 'Unable remove contact history.', ...snackBarFailure });
          return dispatchFailure(action);
        });
    },
  },
};
