<template>
  <ContactHistory
    :entite="person"
    :history-sorted="historySorted"
    :person-list="[{ ...person, personPid: person.pid }]"
    :options="{ search: false, pid: person.pid }"
    @addContactHistory="$emit('addContactHistory', $event)"
    @deleteContactHistory="$emit('deleteContactHistory', $event)"
  />
</template>

<script>
/* eslint-disable max-len */
import moment from 'moment';

export default {
  name: 'PersonCommunicationHistory',
  props: {
    person: { type: Object, required: true },
    personContactHistoryList: { type: Object, required: true },
  },
  computed: {
    historySorted() {
      if (!this.personContactHistoryList || !Object.values(this.personContactHistoryList).length) return null;

      return Object.values(this.personContactHistoryList)
        .sort((a, b) => moment(b.createdAt).unix() - moment(a.createdAt).unix());
    },
  },
};
</script>
