/* eslint-disable import/no-extraneous-dependencies */
import { navySeal } from '@jack/provider';
import { isActionAllowed } from '@jack/dataStores/$utils/helpers';

export default {
  namespaced: true,
  state: {
    token: null,
  },
  mutations: {
    SET_PHANTOMBUSTER_TOKEN(state, newData) { state.token = newData; },
  },
  getters: {
    getterToken: (state) => state.token,
  },
  actions: {
    create({ commit, rootGetters, dispatch }, { payload = {}, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'PHANTOM_BUSTER_TOKEN.CREATE', dispatch)) return undefined;
      return navySeal.apiWsMi.phantomBuster.token.post({ payload, httpQuery })
        .then((token) => {
          commit('SET_PHANTOMBUSTER_TOKEN', token);
        });
    },
    patch({ commit, rootGetters, dispatch }, { payload = {}, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'PHANTOM_BUSTER_TOKEN.PATCH', dispatch)) return undefined;
      return navySeal.apiWsMi.phantomBuster.token.patch({ payload, httpQuery })
        .then((token) => {
          commit('SET_PHANTOMBUSTER_TOKEN', token);
        });
    },
    delete({ commit, rootGetters, dispatch }, { payload = {}, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'PHANTOM_BUSTER_TOKEN.DELETE', dispatch)) return undefined;
      return navySeal.apiWsMi.phantomBuster.token.delete({ payload, httpQuery })
        .then((token) => {
          commit('SET_PHANTOMBUSTER_TOKEN', token);
        });
    },
    set_token({ commit }, pbToken) {
      commit('SET_PHANTOMBUSTER_TOKEN', pbToken);
    },
  },
};
