<template>
  <Notes
    :notes-sorted="notesSorted"
    @addNote="$emit('addNote', $event)"
    @deleteNote="$emit('deleteNote', $event)"
  />
</template>

<script>
import moment from 'moment';

export default {
  name: 'PersonNotes',
  props: {
    person: { type: Object, required: true },
    personNotesList: { type: Object, required: true },
  },
  computed: {
    notesSorted() {
      if (!this.personNotesList || !Object.values(this.personNotesList).length) return null;
      return Object.values(this.personNotesList)
        .map((el) => ({ ...el, arrContent: el.content.split('\n') }))
        .sort((a, b) => moment(b.createdAt).unix() - moment(a.createdAt).unix());
    },
  },
};
</script>
