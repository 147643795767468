<template>
  <section>
    <h1 class="title is-big">Alert</h1>

    <b-notification
      type="is-info"
      aria-close-label="Close notification"
    >
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce id fermentum quam. Proin sagittis, nibh id hendrerit imperdiet, elit sapien laoreet elit
    </b-notification>
  </section>
</template>

<script>
export default {
  name: 'TheAlert',
};
</script>
