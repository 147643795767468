<template>
  <aside
    v-if="person"
    class="jackSidePanel"
  >
    <header>
      <div class="profile">
        <div
          class="background"
          :style="`background:url('${person.backgroundUrl}')`"
        />

        <div
          v-if="person.imageUrl"
          class="profile-picture"
          :style="`background:url('${person.imageUrl}')`"
        />
        <InitialsPicture
          v-else
          :name="person.fullName"
          :size="96"
        />
      </div>
      <div class="multibar">
        <div class="end buttons">
          <b-button
            class="is-tertiary"
            icon-pack="fas"
            icon-right="times"
            @click="goHome()"
          />
        </div>
      </div>
    </header>

    <br>

    <div class="contentSidePanel">
      <DevDetails :cnt="{name: 'person', obj: person}" />
      <div class="multibar is-align-items-center">
        <div class="start">
          <div
            class="header"
            style="max-width:350px"
          >
            <h2 class="m-0 title is-big">{{ person.fullName }}</h2>
            <p class="has-text-secondary text">{{ person.jobTitle }}</p>
          </div>
        </div>
        <div class="buttons end">
          <b-tooltip
            v-if="person.number"
            type="is-dark"
            :label="person.number"
            position="is-left"
          >
            <b-button
              type="is-tertiary"
              icon-pack="fa"
              icon-left="phone"
              tag="a"
              :href="`tel:${person.number}`"
              target="blank"
              class="ml-3"
            />
          </b-tooltip>

          <b-tooltip
            v-if="person.mail"
            type="is-dark"
            :label="person.mail"
            position="is-left"
          >
            <b-button
              type="is-tertiary"
              icon-pack="fa"
              icon-left="envelope"
              class="ml-3"
              @click="openModal = true"
            />
          </b-tooltip>

          <ModalChooseTemplate
            v-if="person.mail"
            :open-modal="openModal"
            :mail="person.mail"
            @update:openModal="openModal = $event"
          />

          <b-tooltip
            v-if="person.profileUrl"
            type="is-dark"
            :label="$_('Linkedin\'s URL')"
            position="is-left"
          >
            <b-button
              v-if="person.source"
              type="is-tertiary"
              icon-pack="fa"
              :icon-left="person.source.toLowerCase()"
              tag="a"
              :href="person.profileUrl"
              target="blank"
              class="ml-3"
            />
          </b-tooltip>
        </div>
      </div>

      <br>

      <b-collapse
        v-if="open"
        :open="open"
        aria-id="contentIdForA11y1"
        class="collapse"
      >
        <div class="collapse-content">
          <div class="content">
            <PhantomSteps
              v-model="activeStep"
              :agent="agentImportProfile"
              @update:activeStep="activeStep = $event"
              @launchAgent="fetchPerson($event)"
            />
          </div>
        </div>
      </b-collapse>
      <b-tabs
        v-if="!open"
        v-model="currentTab"
        size="is-small"
        type="is-toggle-rounded"
      >
        <b-tab-item
          v-for="tab in tabulations"
          :key="tab.key"
          :visible="tab.display"
          :value="tab.key"
        >
          <template #header>
            <b-tooltip
              type="is-dark"
              :label="tab.name"
              position="is-top"
            >
              <b-button
                type="is-tertiary"
                :icon-pack="tab.pack"
                :icon-left="tab.icon"
              />
            </b-tooltip>
          </template>
          <component
            :is="tab.cmp"
            :loading="wsLoading"
            :person="_person"
            :person-notes-list="personNotesList"
            :person-contact-history-list="personContactHistoryList"
            :tag-list="tagList"
            :appointment-token="appointmentToken"
            @createClient="createClient()"
            @patchPerson="patchPerson($event)"
            @addNote="addNote($event)"
            @addAppointment="addAppointment($event)"
            @addProject="addProject($event)"
            @addPayment="addPayment($event)"
            @addContactHistory="addContactHistory($event)"
            @deleteContactHistory="deleteContactHistory($event)"
            @deleteAppointment="deleteAppointment($event)"
            @changeStateAppointment="patchAppointment($event)"
            @deleteNote="deleteNote($event)"
            @deleteProject="deleteProject($event)"
            @deletePayment="deletePayment($event)"
            @deleteClient="deleteClient()"
            @createTag="createTag($event)"
            @deleteTag="deleteTag($event)"
            @genreateAppointmentLink="genreateAppointmentLink($event)"
            @resetAppointmentToken="_resetAppointmentToken()"
          />
        </b-tab-item>
      </b-tabs>
    </div>
  </aside>
</template>

<script>
/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
/* eslint-disable import/no-extraneous-dependencies */
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import SocketMixins from '@jack/socket/$utils/SocketMixins.mixins';

const options = { entite: 'PERSON' };

export default {
  name: 'TheNetworkingContactsDetails',
  mixins: [SocketMixins],
  data() {
    return {
      activeStep: 0,
      currentTab: 'informations',
      dev: false,
      open: false,
      openModal: false,
      wsLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      _person: 'TheNetworking/person',
      agentList: 'TheNetworking/agentList',
      personNotesList: 'TheNetworking/personNotesList',
      personContactHistoryList: 'TheNetworking/personContactHistoryList',
      currentUid: 'TheNetworking/currentUid',
      currentAccountAid: 'TheNetworking/currentAccountAid',
      currentBaid: 'TheNetworking/currentBaid',
      tagList: 'TheNetworking/tagList',
      appointmentToken: 'TheNetworking/appointmentToken',
    }),
    currentPid() { return this.$route.params.pid; },
    person() {
      if (!this._person) return null;
      const { details, ...rest } = this._person;
      return { ...details, ...rest };
    },
    agentImportProfile() {
      const agent = Object.values(this.agentList).find(({ scriptId }) => Number(scriptId) === 3112);

      if (!agent || !agent.pbaid) return {};

      const steps = JSON.parse(agent.steps).steps.reduce((acc, step) => {
        if (step.slug === 'profiles-to-scrape') return acc;
        acc.steps.push(step);
        return acc;
      }, { steps: [] });

      agent.steps = JSON.stringify(steps);
      return agent;
    },
    tabulations() {
      return [
        { name: this.$_('Informations'), key: 'informations', pack: 'fa', icon: 'circle-info', display: true, cmp: 'PersonInformations' },
        { name: this.$_('Communication history'), key: 'communication-history', pack: 'fa', icon: 'comments', display: true, cmp: 'PersonCommunicationHistory' },
        { name: this.$_('Appointment'), key: 'appointments', pack: 'fa', icon: 'calendar-plus', display: true, cmp: 'PersonAppointments' },
        { name: this.$_('Notes'), key: 'notes', pack: 'fa', icon: 'note-sticky', display: true, cmp: 'PersonNotes' },
        { name: this.$_('Projects'), key: 'projects', pack: 'fa', icon: 'briefcase', display: !!this.person.client, cmp: 'PersonProjects' },
        { name: this.$_('Payments'), key: 'payments', pack: 'fa', icon: 'dollar-sign', display: !!this.person.client, cmp: 'PersonPayments' },
        { name: this.$_('New client'), key: 'new-client', pack: 'fa', icon: 'user-plus', display: !(this.person.client), cmp: 'PersonNewClient' },
        { name: this.$_('Settings'), key: 'settings', pack: 'fa', icon: 'cog', display: true, cmp: 'PersonSettings' },
      ];
    },
  },
  mounted() {
    this.getPerson();
    if (this.$route.query.tab) {
      this.currentTab = this.$route.query.tab;
    }
  },
  methods: {
    ...mapActions({
      _addClient: 'TheNetworking/createClient',
      _addNotes: 'TheNetworking/addNotes',
      _addContactHistory: 'TheNetworking/addContactHistory',
      _addAppointment: 'TheNetworking/createAppointment',
      _addProject: 'TheNetworking/createProject',
      _addPayment: 'TheNetworking/createPayment',
      _addTag: 'TheNetworking/addPersonTag',
      _deleteNotes: 'TheNetworking/removeNotes',
      _deleteContactHistory: 'TheNetworking/removeContactHistory',
      _deleteAppointment: 'TheNetworking/deleteAppointment',
      _patchAppointment: 'TheNetworking/patchAppointment',
      _deleteProject: 'TheNetworking/deleteProject',
      _deletePayment: 'TheNetworking/deletePayment',
      _deleteTag: 'TheNetworking/removePersonTag',
      _patchPerson: 'TheNetworking/patchPerson',
      _deleteClient: 'TheNetworking/deleteClient',
      _launchAgent: 'TheNetworking/launch',
      _getPerson: 'TheNetworking/getPerson',
      createAppointmentToken: 'TheNetworking/createAppointmentToken',
      _resetAppointmentToken: 'TheNetworking/resetAppointmentToken',
    }),
    getPerson() {
      const { currentPid: pid } = this;
      if (this._person && Number(this._person.pid) === Number(pid)) return;
      if (!pid) return;
      const queries = ['withCompanies', 'withClient', 'withNotes', 'withHistory', 'withFavorite', 'withAppointment', 'withProject', 'withPayment', 'withTags', 'withAppointmentToken'];
      this._getPerson({
        payload: { pid: this.currentPid },
        httpQuery: queries.reduce((acc, el) => { acc[el] = 'true'; return acc; }, {}),
      });
    },
    createTag(payload) { this._addTag({ payload }); },
    deleteTag(payload) { this._deleteTag({ payload }); },
    goHome() { this.$router.push({ path: '/networking/contacts' }); },
    deleteNote(payload) { this._deleteNotes({ payload, options }); },
    deleteContactHistory(payload) { this._deleteContactHistory({ payload, options }); },
    deleteAppointment(payload) { this._deleteAppointment({ payload, options }); },
    deleteProject(payload) { this._deleteProject({ payload, options }); },
    deletePayment(payload) { this._deletePayment({ payload: { uid: this.currentUid, baid: this.currentBaid, ...payload }, options }); },
    deleteClient() { this._deleteClient({ payload: { cid: this.person.client.cid }, options }); },
    patchAppointment(payload) { this._patchAppointment({ payload }); },
    addNote(content) {
      this._addNotes({
        payload: {
          content,
          personPid: Number(this.currentPid),
          userUid: this.currentUid,
          companyCid: null,
          createdAt: moment().format(),
        },
        options,
      });
    },
    addContactHistory({ state }) {
      this._addContactHistory({
        payload: {
          state,
          personPid: Number(this.currentPid),
          userUid: this.currentUid,
          companyCid: null,
          createdAt: moment().format(),
        },
        options,
      });
    },
    addAppointment(payload) {
      this._addAppointment({
        payload: {
          ...payload,
          details: {
            ...payload.details,
            personPid: this.person.pid,
          },
        },
        options,
      });
    },
    addProject(payload) { this._addProject({ payload: { uid: this.currentUid, aid: this.currentAccountAid, ...payload }, options }); },
    addPayment(payload) {
      this._addPayment({
        payload: {
          ...payload,
          baid: this.currentBaid,
          uid: this.currentUid,
          userUid: this.currentUid,
          aid: this.currentAccountAid,
        },
        options,
      });
    },
    fetchPerson(event) {
      if (!this.agentImportProfile.pbaid) {
        this._pushWsNotification({ type: 'danger', title: 'Phantom not found', content: 'You don\'t have a phantom that allows you to get the employees of a company. Please add the phantom in question to Phantombuster' });
        return;
      }

      const { pbaid, scriptId } = this.agentImportProfile;
      const { profileUrl } = this.person;

      const payload = {
        pbaid,
        arguments: JSON.stringify({ ...event, spreadsheetUrl: profileUrl }),
      };

      if (this.dev) payload.isStub = scriptId;

      Promise.all([
        this.activeStep += 1,
        this.wsLoading = true,
        this._launchAgent({ payload }),
        this.subscribeStream({
          entite: 'agent',
          id: pbaid,
          method: 'launchAgent',
          options: { pbaid, person: this.person, insert: 'updatePerson' },
        }),
      ]);
    },
    patchPerson(event) {
      this._patchPerson({
        payload: {
          pid: this.currentPid,
          ...Object.entries(event).reduce((acc, [key, value]) => {
            if (value !== null) acc[key] = value;
            return acc;
          }, {}),
        },
      });
    },
    createClient() {
      const { currentUid: uid, currentAccountAid: aid } = this;
      return Promise.resolve()
        .then(() => this._addClient({
          payload: {
            aid,
            uid,
            createdAt: this.$moment().format(),
            companyToCreate: null,
            personsToCreate: null,
            companyCidToLink: null,
            personPidsToLink: [this.person.pid],
          },
          options,
        }))
        .then(() => {
          this.currentTab = 'informations';
        });
    },
    genreateAppointmentLink(evnt) {
      this.createAppointmentToken({
        payload: {
          accountAid: this.currentAccountAid,
          userUid: this.currentUid,
          companyCid: null,
          personPid: this.person.pid,
          clientCid: this.person.client ? this.person.client.cid : null,
          ...evnt.reduce((acc, el) => {
            const [key, value] = el.split('=');
            const vBool = ['noEndDate', 'mail', 'number'];
            if (value === 'null') { acc[key] = null; return acc; }
            if (key === 'duration') { acc[key] = Number(value); return acc; }
            if (vBool.includes(key)) { acc[key] = true; return acc; }
            acc[key] = value;
            return acc;
          }, {}),
        },
      });
    },
  },
};
</script>
