<template>
  <header>
    <div class="client-profile">
      <div
        class="background is-relative"
        :style="`background:url('${backgroundUrl}')`"
      />
      <div
        v-if="imageUrl"
        class="profile-picture ml-5"
        :style="`background:url('${imageUrl}')`"
      />
      <InitialsPicture
        v-else
        :name="name"
        :size="96"
        class="ml-5"
      />
    </div>
  </header>
</template>

<script>
export default {
  name: 'ClientHeader',
  props: {
    backgroundUrl: { type: String, required: true },
    imageUrl: { type: String, required: true },
    name: { type: String, required: true },
  },
};
</script>
