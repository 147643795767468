<template>
  <b-collapse
    v-if="open"
    :open="open"
    aria-id="contentIdForA11y1"
    class="collapse"
  >
    <div class="collapse-content">
      <div class="content">
        <form>
          <b-field :label="$_('Call status')">
            <b-select
              v-model="state"
              placeholder="Select a state"
            >
              <option
                v-for="{ key, name } in states"
                :key="key"
                :value="key"
              >
                {{ name }}
              </option>
            </b-select>
          </b-field>

          <template v-if="options.search && personListFiltered.length">
            <b-field label="Contact">
              <b-autocomplete
                v-model="inputSearch"
                :data="personListFiltered"
                field="fullName"
                :disabled="state === 'NO_RESPONSE'"
                @select="option => selected = option"
              >
                <template slot-scope="props">
                  <div class="media">
                    <div class="media-left">
                      <div
                        v-if="props.option.details.imageUrl"
                        class="profile-picture item size is-40x40"
                        :style="`background:url('${props.option.details.imageUrl}')`"
                      />
                      <InitialsPicture
                        v-else
                        :name="props.option.details.fullName"
                        class="item"
                        :size="40"
                      />
                    </div>
                    <div class="media-content">
                      <p class="text is-small cursor-pointer p-0 m-0">
                        {{ props.option.details.fullName }}
                      </p>
                      <p class="disclamer has-text-secondary">
                        {{ props.option.details.jobTitle }}
                      </p>
                    </div>
                  </div>
                </template>
              </b-autocomplete>
            </b-field>
            <article
              v-if="selected"
              class="person is-justify-content-center"
            >
              <div class="content is-flex is-align-items-center is-justify-content-space-between">
                <div class="is-flex is-align-items-center">
                  <div
                    v-if="selected.details.imageUrl"
                    class="profile-picture item size is-40x40"
                    :style="`background:url('${selected.details.imageUrl}')`"
                  />
                  <InitialsPicture
                    v-else
                    :name="selected.details.fullName"
                    class="item"
                    :size="40"
                  />
                  <div class="ml-5">
                    <p class="subtitle is-big cursor-pointer p-0 m-0">
                      {{ selected.details.fullName }}
                    </p>
                    <p class="disclamer is-small has-text-secondary m-0">
                      {{ selected.details.location }}
                    </p>
                    <p class="subtext is-small has-text-secondary">{{ selected.details.jobTitle }}</p>
                  </div>
                </div>

                <div class="buttons">
                  <b-button
                    type="is-tertiary"
                    icon-left="xmark"
                    icon-pack="fa"
                    @click="selected = null"
                  />
                </div>
              </div>
            </article>
          </template>

          <br>

          <div class="end buttons">
            <b-button
              type="is-primary"
              icon-pack="fa"
              icon-left="check"
              :disabled="!state"
              expanded
              @click="addContactHistory()"
            />
          </div>
        </form>
      </div>
    </div>
  </b-collapse>
</template>

<script>
/* eslint-disable-next-line */
import { debounce } from '@jack/addons/$utils/helpers';

export default {
  name: 'AddContactHistory',
  props: {
    open: { type: Boolean, required: true },
    options: { type: Object, default: () => {} },
    states: { type: Object, required: true },
    personList: { type: Array, default: () => [] },
  },
  data() {
    return {
      search: '',
      inputSearch: '',
      state: '',
      selected: null,
    };
  },
  computed: {
    personListFiltered() {
      if (!this.search) return this.personList;

      return this.personList.reduce((acc, person) => {
        const { details: { fullName, mail, number } } = person;
        const _fullName = fullName.toLowerCase();
        const _mail = mail ? mail.toLowerCase() : '';
        const _number = number || '';
        const _search = this.search.toLowerCase();

        if (
          _fullName.includes(_search)
          || _mail.includes(_search)
          || _number.includes(_search)
        ) {
          acc.push(person);
        }

        return acc;
      }, []);
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    inputSearch: debounce(function (newVal) {
      this.search = newVal;
    }),
  },
  methods: {
    addContactHistory() {
      let personPid = null;

      if (this.selected) personPid = this.selected.pid;
      if (this.options && this.options.pid) personPid = this.options.pid
      this.$emit('addContactHistory', { state: this.state, personPid });
      this.state = '';
      this.selected = null;
      this.$emit('close');
    },
  },
};
</script>
