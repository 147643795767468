/* eslint-disable max-len */
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import {
  dispatchAndForward,
  // dispatchFailure,
  // dispatchComment,
} from '../../../dataStores/$utils/helpers';

export default {
  namespaced: true,
  state: {
    notifBoardIsOpen: false,
    notifierStack: {},
    latestNid: null,
  },
  mutations: {
    SET_NOTIFIER_STACK(state, newData) { state.notifierStack = { ...state.notifierStack, ...newData }; },
    SET_LATEST_NOTIF(state, newData) { state.latestNid = newData; },
    DELETE_NOTIF(state, newData) { state.notifierStack = Object.values(state.notifierStack).filter(({ nid }) => nid !== newData).arrayMapper('nid'); },
    DELETE_SUCCESS_NOTIF(state) { state.notifierStack = Object.values(state.notifierStack).filter(({ state: notifstate }) => notifstate !== 'done').arrayMapper('nid'); },
    RESET_NOTIFIER_STACK(state) { state.notifierStack = {}; },
    TOGGLE_NOTIF_BOARD(state, newData) { state.notifBoardIsOpen = newData; },
  },
  getters: {
    getterAuthenticate: (state) => state.authenticate,
    getterLatestNotif: (state) => state.notifierStack[state.latestNid],
    getterLatestNid: (state) => state.latestNid,
    getterNotifBoardIsOpen: (state) => state.notifBoardIsOpen,
    getterNotifierStack: (state) => state.notifierStack,
    getterArrayNotifs: (state) => [...Object.values(state.notifierStack)].sort((a, b) => b.when - a.when),
  },
  actions: {
    pushNotif({ commit }, notif) {
      const nid = uuidv4();
      commit('SET_NOTIFIER_STACK', {
        [nid]: {
          nid, when: moment().valueOf(), ...notif, state: 'created',
        },
      });
      commit('SET_LATEST_NOTIF', nid);
      return nid;
    },
    setLatestDone({ dispatch, getters }) {
      return dispatch('setNotifDone', getters.getterLatestNid);
    },
    setLatestFail({ dispatch, getters }) {
      return dispatch('setNotifFail', getters.getterLatestNid);
    },
    addCommentInNotif({ commit, getters }, { nid, comment }) {
      commit('SET_NOTIFIER_STACK', { [nid]: { ...getters.getterNotifierStack[nid], comment } });
      return nid;
    },
    setNotifDone({ commit, getters }, nid) {
      commit('SET_NOTIFIER_STACK', { [nid]: { ...getters.getterNotifierStack[nid], state: 'done' } });
      return nid;
    },
    setNotifFail({ commit, getters }, nid) {
      commit('SET_NOTIFIER_STACK', { [nid]: { ...getters.getterNotifierStack[nid], state: 'fail' } });
      return ({ error: nid });
    },
    deleteAllNotifications({ commit }) {
      commit('RESET_NOTIFIER_STACK');
    },
    deleteSuccessNotifications({ commit }) {
      commit('DELETE_SUCCESS_NOTIF');
      commit('SET_LATEST_NOTIF', null);
    },
    deleteNotification({ commit }, nid) {
      commit('DELETE_NOTIF', nid);
      commit('SET_LATEST_NOTIF', null);
    },
    toggleNotifBoard({ commit, getters }) {
      commit('TOGGLE_NOTIF_BOARD', !getters.getterNotifBoardIsOpen);
    },
    retry({ dispatch }, {
      target,
      context,
      textNotifier,
      retry,
    }) {
      return dispatchAndForward({
        dispatch,
        target,
        context,
        textNotifier,
        retry,
      });
    },
  },
};
