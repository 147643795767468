/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import { navySeal } from '@jack/provider';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {},
  actions: {
    post(_, { payload, httpQuery }) {
      return navySeal.apiWsDivision.account.unlink.client.post({ payload, httpQuery });
    },
  },
  modules: {},
};
