<template>
  <section class="pa-container">
    <template v-if="paymentList && paymentList.length">
      <template v-for="pa in paymentList">
        <PaymentCard
          v-if="pa"
          :key="pa.pid"
          :payment="pa"
          @deletePayment="$emit('deletePayment', $event)"
        />
      </template>
    </template>
    <NoData
      v-else
      :title="$_('You have no payment related to this customer.')"
      :content="$_('You can register a new payment for this customer at any time.')"
      class="mt-5"
    />
  </section>
</template>

<script>

export default {
  name: 'PaymentList',
  props: {
    paymentList: { type: Array, default: () => [] },
  },
};
</script>
