/* eslint-disable import/no-extraneous-dependencies */
import { navySeal } from '@jack/provider';
import { isActionAllowed } from '@jack/dataStores/$utils/helpers';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {},
  actions: {
    create({ commit, rootGetters, dispatch }, { payload = {}, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'APPOINTMENT_TOKEN_GENERATE.CREATE', dispatch)) return undefined;

      return navySeal.apiWsJoe.appointmentToken.generate.post({ payload, httpQuery })
        .then((generate) => {
          commit('AppointmentToken/SET_APPOINTMENT_TOKEN', generate, { root: true });

          if (generate.personPid) {
            const { 'Person/getterPerson': person } = rootGetters;
            if (person.appointmentTokens) {
              person.appointmentTokens.push({ ...generate, ...payload });
            } else {
              person.appointmentTokens = [{ ...generate, ...payload }];
            }
            commit('Person/SET_PERSON', person, { root: true });
          }
          return generate;
        });
    },
  },
};
