/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import { navySeal } from '@jack/provider';
import { isActionAllowed } from '@jack/dataStores/$utils/helpers';

export default {
  namespaced: true,
  state: {
    language: null,
  },
  mutations: {
    SET_LANGUAGE(state, newData) { state.language = newData; },
  },
  getters: {
    getterLanguage: (state) => state.language,
  },
  actions: {
    patch({ rootGetters, commit, dispatch }, { payload = {}, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'LANGUAGE.PATCH', dispatch)) return undefined;

      return navySeal.apiWsDivision.user.language.patch({ payload, httpQuery })
        .then((language) => {
          commit('SET_LANGUAGE', language);
        });
    },
  },
};
