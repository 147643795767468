/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import { navySeal } from '@jack/provider';
import { isActionAllowed } from '@jack/dataStores/$utils/helpers';

import project from './project.store';
import tag from './tag.store';

import linkPerson from './Link/Person';
import linkCompany from './Link/Company';
import unlinkPerson from './Unlink/Person';
import unlinkCompany from './Unlink/Company';

export default {
  namespaced: true,
  state: {
    clientList: [],
    client: {},
  },
  mutations: {
    SET_CLIENT_LIST(state, newData) { state.clientList = newData; },
    SET_CLIENT(state, newData) { state.client = newData; },
  },
  getters: {
    getterClientList: (state) => state.clientList,
    getterClient: (state) => state.client,
  },
  actions: {
    report({ commit, rootGetters, dispatch }, { payload, httpQuery }) {
      if (!isActionAllowed(rootGetters, 'CLIENT.REPORT', dispatch)) return undefined;

      return navySeal.apiWsJoe.client.report({ payload, httpQuery })
        .then((clients) => {
          const clientsList = clients.reduce((acc, client) => {
            const {
              details: { clientCid, ...details }, ...rest
            } = client;

            acc.push({ ...rest, ...details });
            return acc;
          }, []);
          commit('SET_CLIENT_LIST', clientsList);
        });
    },
    get({ commit, rootGetters, dispatch }, { payload, httpQuery }) {
      if (!isActionAllowed(rootGetters, 'CLIENT.GET', dispatch)) return undefined;

      return navySeal.apiWsJoe.client.get({ payload, httpQuery })
        .then((client) => {
          const {
            details, projectList, paymentList, ...rest
          } = client;

          let clientFormatted = { ...rest };

          if (details) clientFormatted = { ...clientFormatted, ...details };

          if (projectList) {
            clientFormatted = {
              ...clientFormatted,
              projectList: projectList.map(({ details: _details, ..._rest }) => ({ ..._rest, ..._details })),
            };
          }

          if (paymentList) {
            clientFormatted = {
              ...clientFormatted,
              paymentList: paymentList.map(({ details: _details, ..._rest }) => ({ ..._rest, ..._details })),
            };
          }

          commit('SET_CLIENT', clientFormatted);
        });
    },
    create({ dispatch, commit, rootGetters }, { payload, httpQuery, options }) {
      if (!isActionAllowed(rootGetters, 'CLIENT.CREATE', dispatch)) return undefined;

      return navySeal.apiWsJoe.client.post({ payload, httpQuery })
        .then(({
          event: {
            eid, accountAid: aid, uid, cid, type, createdAt,
          },
          client,
        }) => {
          dispatch('User/eventsView/up_addEventEventsViewList', {
            eid, aid, uid, cid, createdAt, type, client: { ...client, createdAt: client.createdAt }, appointment: null, appointmentAid: null, projectPid: null, paymentPid: null, sid: null, project: null, payment: null, spend: null,
          }, { root: true });
          commit('SET_CLIENT', client);

          const { 'Company/getterCompany': company } = rootGetters;
          const { 'Person/getterPerson': person } = rootGetters;

          switch (options.entite) {
            case 'COMPANY':
              company.client = client;
              commit('Company/SET_COMPANY', company, { root: true });
              break;
            case 'PERSON':
              person.client = client;
              commit('Person/SET_PERSON', person, { root: true });
              break;
            default:
              break;
          }

          return client;
        });
    },
    update({ rootGetters, dispatch }, { payload, httpQuery }) {
      if (!isActionAllowed(rootGetters, 'CLIENT.UPDATE', dispatch)) return undefined;

      return navySeal.apiWsJoe.client.put({ payload, httpQuery });
    },
    delete({ rootGetters, commit, dispatch }, { payload, httpQuery, options }) {
      if (!isActionAllowed(rootGetters, 'CLIENT.DELETE', dispatch)) return undefined;

      return navySeal.apiWsJoe.client.delete({ payload, httpQuery })
        .then(() => {
          const { 'Company/getterCompany': company } = rootGetters;
          const { 'Person/getterPerson': person } = rootGetters;

          switch (options.entite) {
            case 'COMPANY':
              company.client = null;
              commit('Company/SET_COMPANY', company, { root: true });
              break;
            case 'PERSON':
              person.client = null;
              commit('Person/SET_PERSON', person, { root: true });
              break;
            default:
              break;
          }
        });
    },
  },
  modules: {
    project,
    tag,
    linkPerson,
    linkCompany,
    unlinkPerson,
    unlinkCompany,
  },
};
