<template>
  <form @submit.prevent="createNewPerson()">
    <b-field
      label="First name"
      horizontal
    >
      <b-input
        v-model="form.firstName"
        required
      />
    </b-field>
    <b-field
      label="Last name"
      horizontal
    >
      <b-input
        v-model="form.lastName"
        required
      />
    </b-field>
    <b-field
      label="Email"
      horizontal
    >
      <b-input
        v-model="form.mail"
        type="email"
      />
    </b-field>
    <b-field
      horizontal
      label="Number"
    >
      <vue-tel-input
        v-model="form.number"
        default-country="fr"
        :preferred-countries="preferredCountries"
        :input-options="inputOptions"
        :auto-format="false"
        invalid-msg="Invalid phone number"
        valid-characters-only
        @country-changed="changeCodeCountry($event)"
        @validate="validPhoneNumberMethod($event)"
      />
    </b-field>

    <br>

    <div class="buttons">
      <b-button
        type="is-success"
        native-type="submit"
        icon-left="user-plus"
        icon-pack="fa"
        expanded
      />
    </div>
  </form>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */
import vueTelInputConfig from '@jack/addons/common/$mixins/vueTelInputConfig';

const moment = require('moment');

export default {
  name: 'FormNewPerson',
  mixins: [vueTelInputConfig],
  props: {
    companyCid: { type: Number, default: 0 },
    userUid: { type: Number, required: true },
    accountAid: { type: Number, required: true },
  },
  data() {
    return {
      form: {
        firstName: null,
        lastName: null,
        description: null,
        headLine: null,
        imageUrl: null,
        location: null,
        profileUrl: null,
        backgroundUrl: null,
        mail: null,
        number: null,
        subscribers: null,
        jobTitle: null,
      },
    };
  },
  methods: {
    createNewPerson() {
      const payload = {
        companyToCreate: null,
        personsToCreate: [
          {
            fetchedAt: moment().format(),
            source: 'DEFAULT',
            refreshAt: null,
            extrefId: null,
            userUid: this.userUid,
            accountAid: this.accountAid,
            details: {
              ...this.form,
              fullName: `${this.form.firstName} ${this.form.lastName}`,
            },
          },
        ],
        companyCidToLink: this.companyCid,
        personPidsToLink: null,
        createdAt: moment().format(),
      };

      this.$emit('newEmployee', payload);
    },
  },
};
</script>
