<template>
  <div>
    TheCarousel
  </div>
</template>

<script>
export default {
  name: 'TheCarousel',
};
</script>
