<template>
  <section id="TheWsNotifiers">
    <WsNotifierCard
      v-for="notif in wsNotifiers"
      :key="notif.id"
      :notif="notif"
      @deleteNotif="$emit('deleteNotif', $event)"
    />
  </section>
</template>

<script>
export default {
  name: 'WsNotifiers',
  props: {
    wsNotifiers: { type: Object, required: true },
  },
};
</script>
