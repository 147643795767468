<template>
  <article
    v-if="!appointment.over"
    :key="appointment.aid"
    :class="['appoitnment-card mb-4', {
      'bg-color-1-10 ': isPending
    }]"
  >
    <div
      :class="['a-container', {
        'is-today': isToday
      }]"
    >
      <div class="calendar">
        <div class="calendar-month">
          <div class="is-flex is-align-items-center">
            <span>{{ dates.start.month }}</span>
            <template v-if="dates.start.month !== dates.end.month">
              <span>-{{ dates.end.month }}</span>
            </template>
          </div>
        </div>
        <div class="calendar-day">
          <div class="is-flex is-align-items-center">
            <span>{{ dates.start.day }}</span>
            <template v-if="dates.start.day !== dates.end.day">
              <span>-{{ dates.end.day }}</span>
            </template>
          </div>
        </div>
      </div>

      <div class="w-100 ml-5">
        <span class="disclamer has-text-secondary">
          <span>{{ dates.start.hours }} - {{ dates.end.hours }}</span>
        </span>
        <p class="text is-small m-0">
          {{ appointment.details.label }}
        </p>
        <p class="subtext is-small has-text-secondary">
          {{ appointment.details.description }}
        </p>

        <Contact
          v-if="appointment.person"
          :pid="appointment.person.pid"
          :details="appointment.person.details"
          class="mt-3"
        />
      </div>
    </div>
    <div class="buttons">
      <div
        v-if="isPending"
        class="is-flex"
      >
        <b-tooltip
          type="is-dark"
          label="Confirm"
          position="is-left"
        >
          <b-button
            type="is-tertiary"
            icon-pack="fa"
            icon-left="check"
            class="text-color-5 mx-3"
            @click="$emit('changeStateAppointment', { state: 'CONFIRMED', aid: appointment.aid })"
          />
        </b-tooltip>
        <b-tooltip
          type="is-dark"
          label="Refuse"
          position="is-left"
        >
          <b-button
            type="is-tertiary"
            icon-pack="fa"
            icon-left="xmark"
            class="text-color-2 mx-3"
            @click="$emit('changeStateAppointment', { state: 'REFUSED', aid: appointment.aid })"
          />
        </b-tooltip>
      </div>
      <b-button
        v-else
        type="is-tertiary"
        icon-pack="fa"
        icon-left="trash"
        class="text-color-1-20 mx-3"
        @click="$emit('deleteAppointment', { aid: appointment.aid })"
      />
    </div>
    <div
      v-if="appointment.state !== 'PENDING_CONFIRMATION'"
      class="icon-state"
    >
      <b-icon
        pack="fa"
        :icon="appointment.state === 'CONFIRMED' ? 'check' : 'xmark'"
        :class="[{
          'text-color-5': appointment.state === 'CONFIRMED',
          'text-color-2': appointment.state === 'REFUSED',
        }]"
      />
    </div>
  </article>
</template>

<script>
export default {
  name: 'AppointmentInfo',
  props: {
    appointment: { type: Object, required: true },
  },
  computed: {
    dates() {
      const { details: { date, duration } } = this.appointment;
      const end = this.$moment(date).add(duration, 'minutes');

      return {
        start: {
          hours: this.$moment(date).format('H:mm A'),
          day: this.$moment(date).format('DD'),
          month: this.$moment(date).format('MMM'),
        },
        end: {
          hours: this.$moment(end).format('H:mm A'),
          day: this.$moment(end).format('DD'),
          month: this.$moment(end).format('MMM'),
        },
      };
    },
    isToday() {
      return this.$moment(this.$moment(this.appointment.details.date)
        .format('YYYY-MM-DD')).isSame(this.$moment().format('YYYY-MM-DD'));
    },
    isPending() {
      return this.appointment.state === 'PENDING_CONFIRMATION';
    },
  },
};
</script>
