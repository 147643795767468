<template>
  <!-- eslint-disable max-len -->
  <aside
    v-if="company"
    class="jackSidePanel"
  >
    <header>
      <div class="profile">
        <img
          :src="company.banner"
          :alt="company.name"
          class="background"
        >
        <div
          v-if="company.logo"
          class="profile-picture"
          :style="`background:url('${company.logo}')`"
        />
        <InitialsPicture
          v-else
          :name="company.name"
          :size="96"
        />
      </div>
      <div class="multibar">
        <div class="end buttons">
          <b-button
            class="is-tertiary"
            icon-pack="fas"
            icon-right="times"
            @click="goHome()"
          />
        </div>
      </div>
    </header>

    <br>

    <div class="contentSidePanel">
      <DevDetails :cnt="{name: 'company', obj: company}" />
      <div class="multibar is-align-items-center">
        <div class="start">
          <div class="is-flex is-align-items-center">
            <a
              :href="company.website"
              target="_blank"
            >
              <p
                :class="['m-0', {
                  'subtitle is-small': company.name.length > 30,
                  'title is-big': company.name.length <= 30,
                }]"
                style="max-width:350px"
              >
                {{ company.name }}
              </p>
            </a>
            <b-tooltip
              type="is-dark"
              label="Add to favorite"
              position="is-bottom"
            >
              <b-button
                :class="['favorite-button ml-3', {
                  'text-color-1': !company.favorite,
                  'text-color-4': company.favorite,
                }]"
                type="is-tertiary"
                icon-pack="fa"
                icon-left="star"
                @click="$emit('addFavorite', company)"
              />
            </b-tooltip>
          </div>
        </div>
        <div class="buttons end">
          <b-tooltip
            v-if="company.companyEmail"
            type="is-dark"
            :label="company.companyEmail"
            position="is-bottom"
          >
            <b-button
              type="is-tertiary"
              icon-pack="fa"
              icon-left="envelope"
              class="ml-3"
              @click="openModal = true"
            />
          </b-tooltip>

          <ModalChooseTemplate
            v-if="company.companyEmail"
            :open-modal="openModal"
            :mail="company.companyEmail"
            @update:openModal="openModal = $event"
          />

          <b-tooltip
            type="is-dark"
            label="Website"
            position="is-bottom"
          >
            <b-button
              v-if="company.website"
              type="is-tertiary"
              icon-pack="fa"
              icon-left="link"
              tag="a"
              :href="company.website"
              target="blank"
              class="ml-3"
            />
          </b-tooltip>
          <b-tooltip
            type="is-dark"
            label="Linkedin"
            position="is-bottom"
          >
            <b-button
              v-if="company.companyUrl"
              type="is-tertiary"
              icon-pack="fa"
              :icon-left="company.source.toLowerCase()"
              tag="a"
              :href="company.companyUrl"
              target="blank"
              class="ml-3"
            />
          </b-tooltip>
        </div>
      </div>

      <br>

      <b-collapse
        v-if="open"
        :open="open"
        aria-id="contentIdForA11y1"
        class="collapse"
      >
        <div class="collapse-content">
          <div class="content">
            <PhantomSteps
              v-model="activeStep"
              :agent="agentImportCompany"
              @update:activeStep="activeStep = $event"
              @launchAgent="launchAgent({ argument: $event, agent: 'agentImportCompany', insert: 'updateCompany' })"
            />
          </div>
        </div>
      </b-collapse>

      <b-tabs
        v-if="!open"
        v-model="currentTab"
        destroy-on-hide
        type="is-toggle-rounded"
        size="is-small"
      >
        <b-tab-item
          v-for="tab in tabulations"
          :key="tab.key"
          :visible="tab.display"
          :value="tab.key"
          header-class="mb-5"
        >
          <template #header>
            <b-tooltip
              type="is-dark"
              :label="tab.name"
              position="is-top"
            >
              <b-button
                type="is-tertiary"
                :icon-pack="tab.pack"
                :icon-left="tab.icon"
              />
            </b-tooltip>
          </template>
          <component
            :is="tab.cmp"
            :loading="wsLoading"
            :company="company"
            :company-notes-list="companyNotesList"
            :company-contact-history-list="companyContactHistoryList"
            :agent-import-employees="agentImportEmployees"
            :agent-import-search-job="agentImportSearchJob"
            :agent-fetch-profile="agentFetchProfile"
            :person-list="personList"
            :user-uid="currentUid"
            :account-aid="currentAccountAid"
            :tag-list="tagList"
            :appointment-token="appointmentToken"
            @deleteNote="deleteNote($event)"
            @deleteContactHistory="deleteContactHistory($event)"
            @deleteAppointment="deleteAppointment($event)"
            @deleteProject="deleteProject($event)"
            @deletePayment="deletePayment($event)"
            @createTag="createTag($event)"
            @deleteTag="deleteTag($event)"
            @deleteClient="deleteClient()"
            @addNote="addNote($event)"
            @addContactHistory="addContactHistory($event)"
            @addAppointment="addAppointment($event)"
            @addProject="addProject($event)"
            @addPayment="addPayment($event)"
            @importEmployees="launchAgent({ argument: $event, agent: 'agentImportEmployees', insert: 'fetchEmployeesFromCompany' })"
            @importJobAlerts="launchAgent({ argument: $event, agent: 'agentImportSearchJob', insert: 'fetchJobsFromCompany' })"
            @newLinkedinEmployee="launchAgent({ argument: $event, agent: 'agentFetchProfile', insert: 'insertPerson' })"
            @newDefaultEmployee="newDefaultEmployee($event)"
            @toggleLinkedPersonToClient="toggleLinkedPersonToClient($event)"
            @pushWsNotification="_pushWsNotification($event)"
            @findPerson="findPerson($event)"
            @contactList="insertContacts($event)"
            @newContactByYourself="newContactByYourself($event)"
          />
        </b-tab-item>
      </b-tabs>
    </div>
  </aside>
</template>

<script>
/* eslint-disable object-curly-newline */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable max-len */
import moment from 'moment';

import { mapGetters, mapActions } from 'vuex';
import SocketMixins from '@jack/socket/$utils/SocketMixins.mixins';

const options = { entite: 'COMPANY' };

export default {
  name: 'TheNetworkingCompanyDetails',
  mixins: [SocketMixins],
  data() {
    return {
      activeStep: 0,
      currentTab: 'informations',
      dev: false,
      open: false,
      openModal: false,
    };
  },
  computed: {
    ...mapGetters({
      agentList: 'TheNetworking/agentList',
      companyList: 'TheNetworking/companyList',
      _company: 'TheNetworking/company',
      companyNotesList: 'TheNetworking/companyNotesList',
      companyContactHistoryList: 'TheNetworking/companyContactHistoryList',
      currentUid: 'TheNetworking/currentUid',
      currentAccountAid: 'TheNetworking/currentAccountAid',
      currentBaid: 'TheNetworking/currentBaid',
      personList: 'TheNetworking/personList',
      tagList: 'TheNetworking/tagList',
      appointmentToken: 'TheNetworking/appointmentToken',
    }),
    currentCid() { return this.$route.params.cid; },
    company() {
      if (!this._company) return null;
      const { details, ...rest } = this._company;
      return { ...details, ...rest };
    },
    tabulations() {
      return [
        { name: 'Informations', key: 'informations', pack: 'fa', icon: 'circle-info', display: true, cmp: 'CompanyInformations' },
        { name: 'Employees', key: 'employees', pack: 'fa', icon: 'address-book fa-regular', display: true, cmp: 'CompanyEmployees' },
        { name: 'Communication histories', key: 'communication-histories', pack: 'fa', icon: 'comments', display: true, cmp: 'CompanyCommunicationHistory' },
        // { name: 'Job alerts', key: 'job-alerts', pack: 'fa', icon: 'bullhorn', display: true, cmp: 'CompanyJobsAlert' },
        { name: 'Appointments', key: 'appointments', pack: 'fa', icon: 'calendar-plus', display: true, cmp: 'CompanyAppointments' },
        { name: 'Notes', key: 'notes', pack: 'fa', icon: 'note-sticky', display: true, cmp: 'CompanyNotes' },
        { name: 'New client', key: 'new-client', pack: 'fa', icon: 'user-plus', display: !(this.company.client), cmp: 'CompanyNewClient' },
        { name: 'Projects', key: 'projects', pack: 'fa', icon: 'briefcase', display: !!this.company.client, cmp: 'CompanyProjects' },
        { name: 'Payments', key: 'payments', pack: 'fa', icon: 'dollar-sign', display: !!this.company.client, cmp: 'CompanyPayments' },
        { name: 'Settings', key: 'settings', pack: 'fa', icon: 'cog', display: true, cmp: 'CompanySettings' },
      ];
    },
    agentImportCompany() {
      const agent = Object.values(this.agentList).find(({ scriptId }) => Number(scriptId) === 3296);

      if (!agent || !agent.pbaid) return {};

      const steps = JSON.parse(agent.steps).steps.reduce((acc, step) => {
        if (step.slug === 'companies-to-scrape') return acc;
        acc.steps.push(step);
        return acc;
      }, { steps: [] });

      agent.steps = JSON.stringify(steps);
      return agent;
    },
    agentImportSearchJob() {
      const agent = Object.values(this.agentList).find(({ scriptId }) => Number(scriptId) === 3149);

      if (!agent || !agent.pbaid) return {};

      const steps = JSON.parse(agent.steps).steps.reduce((acc, step) => {
        if (step.slug === 'searches-to-scrape') return acc;
        acc.steps.push(step);
        return acc;
      }, { steps: [] });

      agent.steps = JSON.stringify(steps);
      return agent;
    },
    agentImportEmployees() {
      const agent = Object.values(this.agentList).find(({ scriptId }) => Number(scriptId) === 3295);

      if (!agent || !agent.pbaid) return {};
      return agent;
    },
    agentFetchProfile() {
      const agent = Object.values(this.agentList).find(({ scriptId }) => Number(scriptId) === 3112);

      if (!agent || !agent.pbaid) return {};
      return agent;
    },
  },
  mounted() { this.fetchCompanyDetails(); },
  methods: {
    ...mapActions({
      getCompany: 'TheNetworking/getCompany',
      _launchAgent: 'TheNetworking/launch',
      _addAppointment: 'TheNetworking/createAppointment',
      _addClient: 'TheNetworking/createClient',
      _addContactHistory: 'TheNetworking/addContactHistory',
      _addNotes: 'TheNetworking/addNotes',
      _addProject: 'TheNetworking/createProject',
      _addPayment: 'TheNetworking/createPayment',
      _addTag: 'TheNetworking/addCompanyTag',
      _deleteNote: 'TheNetworking/removeNotes',
      _deleteContactHistory: 'TheNetworking/removeContactHistory',
      _deleteAppointment: 'TheNetworking/deleteAppointment',
      _deleteProject: 'TheNetworking/deleteProject',
      _deletePayment: 'TheNetworking/deletePayment',
      _deleteClient: 'TheNetworking/deleteClient',
      _deleteTag: 'TheNetworking/removeCompanyTag',
      _createPerson: 'TheNetworking/createPerson',
      pushWsNotification: 'TheWsNotifier/push',
      linkPerson: 'TheNetworking/linkPerson',
      unlinkPerson: 'TheNetworking/unlinkPerson',
      reportPersonList: 'TheNetworking/reportPersonList',
      bulkInsertContacts: 'TheNetworking/bulkInsertContacts',
    }),
    _pushWsNotification(notif) { this.pushWsNotification(notif); },
    goHome() { this.$router.push({ path: '/networking/companies' }); },
    toggleLinkedPersonToClient({ method, ...payload }) { this[method]({ payload: { ...payload, cid: this.currentCid } }); },
    deleteNote(payload) { this._deleteNote({ payload, options }); },
    deleteContactHistory(payload) { this._deleteContactHistory({ payload, options }); },
    deleteAppointment(payload) { this._deleteAppointment({ payload, options }); },
    deleteProject(payload) { this._deleteProject({ payload, options }); },
    deletePayment(payload) { this._deletePayment({ payload: { uid: this.currentUid, baid: this.currentBaid, ...payload }, options }); },
    deleteClient() { this._deleteClient({ payload: { cid: this.company.client.cid }, options }); },
    createTag(payload) { this._addTag({ payload }); },
    deleteTag(payload) { this._deleteTag({ payload }); },
    addAppointment(payload) { this._addAppointment({ payload, options }); },
    addProject(payload) { this._addProject({ payload: { uid: this.currentUid, aid: this.currentAccountAid, ...payload }, options }); },
    insertContacts(event) { this._bulkInsertContactToCompany(Object.keys(event), event); },
    newContactByYourself(payload) {
      Promise.resolve()
        .then(() => this._createPerson({ payload }))
        .then(({ response }) => {
          if (!response) return;
          const { pid } = response;
          this._bulkInsertContactToCompany([pid], { [pid]: { ...response } });
        });
    },
    addPayment(payload) {
      this._addPayment({
        payload: {
          ...payload,
          baid: this.currentBaid,
          uid: this.currentUid,
          userUid: this.currentUid,
          aid: this.currentAccountAid,
        },
        options,
      });
    },
    addNote(content) {
      const { currentUid: userUid, currentCid: companyCid } = this;
      this._addNotes({
        payload: {
          userUid, content, personPid: null, companyCid: Number(companyCid), createdAt: moment().format(),
        },
        options,
      });
    },
    _bulkInsertContactToCompany(pids, args = {}) {
      const { cid } = this.company;
      this.bulkInsertContacts({ payload: { cid, pids }, options: args });
    },
    addContactHistory({ state, personPid }) {
      const { currentUid: userUid, currentCid: companyCid } = this;
      this._addContactHistory({
        payload: {
          companyCid: Number(companyCid), userUid, state, personPid, createdAt: moment().format(),
        },
        options,
      });
    },
    newDefaultEmployee(e) {
      const { currentUid: uid, currentAccountAid: aid } = this;
      const { pidsToLinkToCompany, ...event } = e;
      const payload = { aid, uid, ...event };
      return Promise.resolve()
        .then(() => {
          if (!pidsToLinkToCompany || !pidsToLinkToCompany.length) return null;
          return this._bulkInsertContactToCompany(pidsToLinkToCompany);
        })
        .then(() => this._addClient({ payload, options }))
        .then(() => this.fetchCompanyDetails())
        .then(() => { this.currentTab = 'informations'; });
    },
    launchPromise({ payload, id, options: opts }) {
      Promise.all([
        this.activeStep += 1,
        this.wsLoading = true,
        this._launchAgent({ payload }),
        this.subscribeStream({ entite: 'agent', id, method: 'launchAgent', options: opts }),
      ]);
    },
    launchAgent({ argument, agent, insert }) {
      if (!this[agent].pbaid) {
        this._pushWsNotification({ type: 'danger', title: 'Phantom not found', content: 'You don\'t have a phantom that allows you to get the employees of a company. Please add the phantom in question to Phantombuster' });
        return;
      }

      const { pbaid, scriptId } = this[agent];
      const { companyUrl } = this.company;

      const args = { ...argument };
      if (agent === 'agentImportCompany') args.spreadsheetUrl = companyUrl;

      const payload = { pbaid, arguments: JSON.stringify(args) };
      const opts = { pbaid, companyCid: this.currentCid, insert, company: this.company };

      if (this.dev) payload.isStub = scriptId;

      this.launchPromise({ payload, id: pbaid, options: { ...options, ...opts } });
    },
    findPerson(name) {
      this.reportPersonList({
        httpQuery: { by: 'name', search: name, pagin: '100,0' },
      });
    },
    fetchCompanyDetails() {
      const { currentUid: userUid, currentCid: cid } = this;
      if (!userUid || !cid) return;
      const queries = ['withPersons', 'withNotes', 'withHistory', 'withClient', 'withFavorite', 'withAppointment', 'withPayment', 'withProject', 'withTags'];
      this.getCompany({
        payload: { cid, userUid },
        httpQuery: queries.reduce((acc, el) => { acc[el] = 'true'; return acc; }, {}),
      });
    },
  },
};
</script>
