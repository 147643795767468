<template>
  <div class="jackSidePanel">
    <JackSidePanelHeader
      :path-home="pathHome"
      :previous="goBack"
    />

    <br>

    <div class="multibar">
      <h2 class="title is-big start">
        Add new spend
      </h2>
    </div>

    <br>

    <DevDetails :cnt="{name: 'newSpend', obj: newSpend}" />

    <b-steps
      v-model="activeStep"
      size="is-small"
      :has-navigation="false"
    >
      <template v-for="(step, i) in steps">
        <b-step-item
          v-if="step.displayed"
          :key="step.label"
          :label="step.label"
          :type="step.type"
        >
          <br>

          <JackStepControls
            v-if="step.stepVisible"
            :active-step.sync="activeStep"
            :step-detail="`${i + 1}/${steps.length - 1} - Create spend`"
            :next-is-allowed="step.stepControl"
            :prev="step.prev"
            :next="step.next"
          />

          <br>

          <component
            :is="step.cmp"
            v-model="newSpend"
            :categories-list="categoriesList"
            @addNewSpend="addNewSpend()"
          />
        </b-step-item>
      </template>
    </b-steps>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TheSidePanelAddSpends',
  props: {
    pathHome: { type: String, required: true },
    goBack: { type: Object, required: true },
  },
  data() {
    return {
      activeStep: 0,
      newSpend: {
        details: {
          categories: {
            cid: 'ALIMENTATIONS',
            label: 'Alimentation / Supermarché',
            icon: 'utensils',
            pack: 'fas',
          },
          label: null,
          deposit: null,
          type: {
            value: 'PERSONAL', label: 'Personal purchase', icon: 'hand-holding-heart', pack: 'fa',
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      categoriesList: 'TheSidePanel/categoriesList',
      currentBaid: 'TheSidePanel/currentBaid',
      currentAccountAid: 'TheSidePanel/currentAccountAid',
      currentUid: 'TheSidePanel/currentUid',
    }),
    steps() {
      return [
        {
          label: 'Spend', cmp: 'JackSidePanelAddSpendForm', displayed: true, type: 'is-primary', stepVisible: true, stepControl: this.stepControl1, prev: false, next: true,
        },
        {
          label: 'Confirmation', cmp: 'JackSidePanelAddSpendConfirmation', displayed: true, type: 'is-primary', stepVisible: true, stepControl: false, prev: true, next: false,
        },
        {
          label: '', cmp: 'JackSidePanelAddSpendSuccessCreate', displayed: true, type: 'is-success', stepVisible: false, stepControl: false, prev: true, next: false,
        },
      ];
    },
    stepControl1() {
      const { details: { label, deposit } } = this.newSpend;
      if (label && deposit) return true;
      return false;
    },
  },
  mounted() {
    return Promise.resolve([
      this.reportCategoriesList({}),
    ]);
  },
  methods: {
    ...mapActions({
      reportCategoriesList: 'TheSidePanel/reportCategoriesList',
      createSpends: 'TheSidePanel/createSpends',
    }),
    addNewSpend() {
      const payload = {
        uid: this.currentUid,
        aid: this.currentAccountAid,
        baid: this.currentBaid,
        createdAt: this.$moment(this.$currentQuery('start')).add(12, 'hours').format(),
        userUid: this.currentUid,
        details: {
          categoriesCid: this.newSpend.details.categories.cid,
          label: this.newSpend.details.label,
          deposit: this.newSpend.details.deposit,
          type: this.newSpend.details.type.value,
        },
      };

      this.createSpends({ payload })
        .then(({ response: spend }) => {
          if (spend) this.activeStep += 1;
        });
    },
  },
};
</script>
