/* eslint-disable import/no-extraneous-dependencies */
import { navySeal } from '@jack/provider';
import { isActionAllowed } from '@jack/dataStores/$utils/helpers';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {},
  actions: {
    create({ commit, rootGetters, dispatch }, { payload: { cid, ...payload }, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'BULK_INSERT_JOBS.CREATE', dispatch)) return undefined;

      return navySeal.apiWsMi.job.bulkInsertJobs.post({ payload, httpQuery })
        .then((jobs) => {
          if (cid) commit('Company/UPDATE_COMPANY_IN_COMPANY_LIST', { cid, hasAlerts: jobs }, { root: true });
        });
    },
  },
};
