/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import { navySeal } from '@jack/provider';
import { isActionAllowed } from '@jack/dataStores/$utils/helpers';

export default {
  namespaced: true,
  state: {
    appointment: {},
  },
  mutations: {
    SET_APPOINTMENT_SETTINGS(state, newData) { state.appointment = newData; },
  },
  getters: {
    getterAppointmentSettings: (state) => state.appointment,
  },
  actions: {
    updateAppointmentSettings({ rootGetters, dispatch, commit }, { payload, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'USER_APPOINTMENT_SETTINGS.UPDATE', dispatch)) return undefined;

      return navySeal.apiWsDivision.user.settings.put({ payload, httpQuery })
        .then((settings) => {
          commit('User/settings/SET_APPOINTMENT_SETTINGS', settings, { root: true });
          return settings;
        });
    },
  },
};
