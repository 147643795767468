<template>
  <b-field grouped>
    <template v-for="{ type, label, col } in filtersElement">
      <RangeFilter
        v-if="type === 'range'"
        :key="label"
        v-model="localFilterQuery[col]"
        :label="label"
        :loading="loading"
      />

      <BoolFilter
        v-if="type === 'bool'"
        :key="label"
        v-model="localFilterQuery[col]"
        :label="label"
        :loading="loading"
      />
    </template>
  </b-field>
</template>

<script>
export default {
  name: 'TheFilter',
  model: {
    prop: 'filterQuery',
  },
  props: {
    filterQuery: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    filtersElement: {
      type: Array,
      required: true,
    },
  },
  computed: {
    localFilterQuery: {
      get() { return { ...this.filterQuery }; },
      set(newValue) { this.$emit('input', newValue); },
    },
  },
};
</script>
