<template>
  <div>
    AddCompanyByYourself
  </div>
</template>

<script>
export default {
  name: 'AddCompanyByYourself',
};
</script>
