<template>
  <section>
    <Appointment
      :entite="company"
      :person-list="company.employees"
      :options="{ search: true, cid: company.cid }"
      type="company"
      @addAppointment="$emit('addAppointment', $event)"
      @deleteAppointment="$emit('deleteAppointment', $event)"
    />
  </section>
</template>

<script>
export default {
  name: 'CompanyAppointments',
  props: {
    company: { type: Object, required: true },
  },
};
</script>
