<template>
  <aside class="jackSidePanel">
    <div class="multibar">
      <div class="end buttons">
        <b-button
          class="is-tertiary"
          icon-pack="fas"
          icon-right="times"
          @click="$emit('quit')"
        />
      </div>
    </div>

    <h2 class="title start m-0">{{ project.label }}</h2>
    <p class="lead has-text-secondary">Create list</p>

    <br>

    <form @submit.prevent="createColumn()">
      <b-field label="Label">
        <b-input
          v-model="kanbanColumn.label"
          type="text"
          required
        />
      </b-field>

      <br>

      <b-button
        type="is-primary"
        icon-pack="fa"
        icon-left="check"
        native-type="submit"
        expanded
      />
    </form>
  </aside>
</template>

<script>
export default {
  name: 'TheProjectsActivitiesAddColumn',
  props: {
    project: { type: Object, required: true },
    kanbanList: { type: Object, required: true },
  },
  data() {
    return {
      kanbanColumn: {
        label: null,
      },
    };
  },
  computed: {
    pid() { return this.$currentParam('pid'); },
    kid() { return this.$currentParam('kid'); },
  },
  methods: {
    createColumn() {
      if (!this.kanbanList || !Object.values(this.kanbanList).length) return;

      this.$emit('createColumn', {
        pid: this.pid,
        kid: this.kid,
        ...this.kanbanColumn,
        order: this.kanbanList[this.kid].columns.length + 1,
        kanbanKid: this.kid,
        createdAt: this.$moment().format(),
        archivedAt: null,
      });
      this.$emit('quit');
      this.$emit('scroll', 'right');
    },
  },
};
</script>
