<template>
  <div>
    AddCompanyByLinkedin
  </div>
</template>

<script>
export default {
  name: 'AddCompanyByLinkedin',
};
</script>
