export default {
  beforeCreate() {
    if (!this.sockets) this.sockets = {};
    this.sockets.subscribe = (evt, callback) => {
      this.$vueSocketIo.emitter.addListener(evt, callback, this);
    };

    this.sockets.unsubscribe = (evt) => {
      this.$vueSocketIo.emitter.removeListener(evt, this);
    };

    this.sockets.reconnect = (options) => {
      const {
        VUE_APP_ANGLICO_BASE_URL: connection,
      } = process.env;
      const withCredentials = true;

      this.$vueSocketIo.reconnect(connection, { withCredentials, ...options });
    };
  },
  mounted() {
    if (this.$options.sockets) {
      Object.keys(this.$options.sockets)
        .forEach((evt) => {
          if (evt !== 'subscribe' && evt !== 'unsubscribe') {
            this.$vueSocketIo.emitter.addListener(evt, this.$options.sockets[evt], this);
            this.$vueSocketIo.listener.register(evt, this.$options.sockets[evt], this);
          }
        });
    }
  },
  beforeDestroy() {
    if (this.$options.sockets) {
      Object.keys(this.$options.sockets)
        .forEach((evt) => {
          this.$vueSocketIo.emitter.removeListener(evt, this);
        });
    }
  },
};
