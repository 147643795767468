<template>
  <aside class="jackSidePanel">
    <div class="multibar">
      <div class="end buttons">
        <b-button
          class="is-ghost"
          tag="router-link"
          to="/settings/phantombuster"
          icon-pack="fas"
          icon-right="times"
        />
      </div>
    </div>

    <br>

    {{ agent }}
  </aside>
</template>

<script>
export default {
  name: 'TheSettingsPhantomBusterAgent',
  props: {
    agent: { type: Object, required: true },
  },
  mounted() {
    if (!this.agent.pbaid) this.$router.push('/settings/phantombuster');
  },
};
</script>
