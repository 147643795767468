<template>
  <section>
    <Appointment
      :entite="person"
      :options="{ search: false }"
      :appointment-token="appointmentToken"
      type="person"
      @addAppointment="$emit('addAppointment', $event)"
      @deleteAppointment="$emit('deleteAppointment', $event)"
      @changeStateAppointment="$emit('changeStateAppointment', $event)"
      @genreateAppointmentLink="$emit('genreateAppointmentLink', $event)"
      @resetAppointmentToken="$emit('resetAppointmentToken')"
      @openModalScheduleAppointment="open = true"
    />
    <!-- <ModalScheduleAppointment
      :open="open"
      :entite="person"
      :appointment-token="appointmentToken"
      type="person"
      @update:openModal="open = $event"
      @genreateAppointmentLink="$emit('genreateAppointmentLink', $event)"
      @resetAppointmentToken="$emit('resetAppointmentToken')"
    /> -->
  </section>
</template>

<script>
export default {
  name: 'PersonAppointments',
  props: {
    person: { type: Object, required: true },
    appointmentToken: { type: Object, required: true },
  },
  data() {
    return {
      open: false,
    };
  },
};
</script>
