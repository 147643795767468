/* eslint-disable import/no-extraneous-dependencies */
import { navySeal } from '@jack/provider';
import { isActionAllowed } from '@jack/dataStores/$utils/helpers';

export default {
  namespaced: true,
  state: { },
  mutations: {},
  getters: {},
  actions: {
    post({ dispatch, rootGetters }, { payload = {}, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'CLIENT_TAG.CREATE', dispatch)) return undefined;

      return navySeal.apiWsJoe.client.tag.post({ payload, httpQuery });
    },
    delete({ rootGetters, dispatch }, { payload }) {
      if (!isActionAllowed(rootGetters, 'CLIENT_TAG.DELETE', dispatch)) return undefined;

      return navySeal.apiWsJoe.client.tag.delete({ payload, httpQuery: {} });
    },
  },
};
