<template>
  <div class="jackSidePanel">
    <JackSidePanelHeader
      :path-home="pathHome"
      :previous="goBack"
    />

    <br>

    <div class="multibar">
      <h2 class="title is-big start">
        Add new project
      </h2>
    </div>

    <br>
    <DevDetails :cnt="{name: 'newProject', obj: newProject}" />
    <DevDetails :cnt="{name: 'clientList', obj: clientList}" />
    <DevDetails :cnt="{name: 'client', obj: client}" />

    <b-steps
      v-model="activeStep"
      size="is-small"
      :has-navigation="false"
    >
      <template v-for="(step, i) in steps">
        <b-step-item
          v-if="step.displayed"
          :key="step.label"
          :label="step.label"
          :type="step.type"
        >
          <br>

          <JackStepControls
            v-if="step.stepVisible"
            :active-step.sync="activeStep"
            :step-detail="`${i + 1}/${steps.length - 1} - Create project`"
            :next-is-allowed="step.stepControl"
            :prev="step.prev"
            :next="step.next"
          />

          <br>

          <component
            :is="step.cmp"
            v-model="newProject"
            :selected="client"
            :clients="clientList"
            @createNewProject="createNewProject()"
          />
        </b-step-item>
      </template>
    </b-steps>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TheSidePanelAddProject',
  props: {
    pathHome: { type: String, required: true },
    goBack: { type: Object, required: true },
  },
  data() {
    return {
      activeStep: 0,
      newProject: {
        cid: null,
        isOpen: true,
        details: {
          label: null,
          description: null,
          urlImage: null,
          price: null,
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      client: 'TheSidePanel/client',
      clientList: 'TheSidePanel/clientList',
      project: 'TheSidePanel/project',
      currentAccountAid: 'TheSidePanel/currentAccountAid',
      currentUid: 'TheSidePanel/currentUid',
      myClientList: 'TheSidePanel/myClientList',
    }),
    steps() {
      return [
        {
          label: 'Client', cmp: 'JackSidePanelAddProjectClientForm', displayed: true, type: 'is-primary', stepVisible: true, stepControl: this.stepControl1, prev: false, next: true,
        },
        {
          label: 'Project information', cmp: 'JackSidePanelAddProjectInformationForm', displayed: true, type: 'is-primary', stepVisible: true, stepControl: this.stepControl2, prev: true, next: true,
        },
        {
          label: 'Confirmation', cmp: 'JackSidePanelAddProjectConfirmation', displayed: true, type: 'is-primary', stepVisible: true, stepControl: false, prev: true, next: false,
        },
        {
          label: '', cmp: 'JackSidePanelAddProjectSuccessCreate', displayed: true, type: 'is-success', stepVisible: false, stepControl: false, prev: true, next: false,
        },
      ];
    },
    stepControl1() {
      if (this.newProject.cid) return true;
      return false;
    },
    stepControl2() {
      const { label, price } = this.newProject.details;
      if (label && price) return true;
      return false;
    },
  },
  mounted() {
    const cids = this.myClientList.map(({ clientCid }) => clientCid);
    this.reportClientList({ payload: { cids } });
  },
  methods: {
    ...mapActions({
      reportClientList: 'TheSidePanel/reportClientList',
      createProject: 'TheSidePanel/createProject',
    }),
    createNewProject() {
      const {
        cid: { cid },
        isOpen,
        details: {
          label, description, ...rest
        },
      } = this.newProject;

      const payload = {
        aid: this.currentAccountAid,
        uid: this.currentUid,
        userUid: this.currentUid,
        clientCid: cid,
        cid,
        isOpen,
        createdAt: this.$moment(this.$currentQuery('start')).add(12, 'hours').format(),
        details: {
          label: label.trim(),
          description: description ? description.trim() : null,
          ...rest,
        },
      };

      this.createProject({ payload })
        .then(() => {
          if (this.project) this.activeStep += 1;
        });
    },
  },
};
</script>
