import TheProjects from './TheProjects';
import TheProjectsDetails from './TheProjectsDetails';
import TheProjectsAdd from './TheProjectsAdd';

import TheProjectsOverview from './views/TheProjectsOverview';
import TheProjectsActivities from './views/TheProjectsActivities';
import TheProjectsMembers from './views/TheProjectsMembers';
import TheProjectsSettings from './views/TheProjectsSettings';

import TheProjectsActivitiesAddColumn from './views/SidePannel/TheProjectsActivitiesAddColumn';
import TheProjectsActivitiesDetailsColumn from './views/SidePannel/TheProjectsActivitiesDetailsColumn';
import TheProjectsActivitiesAddItem from './views/SidePannel/TheProjectsActivitiesAddItem';
import TheProjectsActivitiesDetailsItem from './views/SidePannel/TheProjectsActivitiesDetailsItem';

export default [
  {
    path: '/projects',
    name: 'Projects',
    component: TheProjects,
    meta: {
      hasMenu: true,
      viewStoreNavigation: 'TheProjects',
      requireAuth: true,
    },
    children: [],
  },
  {
    path: '/projects/add',
    name: 'Projects - Add',
    component: TheProjectsAdd,
    meta: {
      hasMenu: true,
      viewStoreNavigation: 'TheProjects',
      requireAuth: true,
    },
  },
  {
    path: '/projects/:pid',
    redirect: '/projects/:pid/overview',
  },
  {
    path: '/projects/:pid',
    name: 'Projects - Project',
    component: TheProjectsDetails,
    meta: {
      hasMenu: true,
      viewStoreNavigation: 'TheProjects',
      requireAuth: true,
    },
    children: [
      {
        path: '/projects/:pid/overview',
        name: 'Projects - Project - Overview',
        components: { overview: TheProjectsOverview },
        meta: {
          hasMenu: true,
          viewStoreNavigation: 'TheProjects',
          requireAuth: true,
        },
      },
      {
        path: '/projects/:pid/activities',
        name: 'Projects - Project - Activities',
        components: { activities: TheProjectsActivities },
        meta: {
          hasMenu: true,
          viewStoreNavigation: 'TheProjects',
          requireAuth: true,
        },
        children: [
          {
            path: '/projects/:pid/activities/:kid/columns/add',
            name: 'Projects - Project - Activities - Columns - Add',
            components: { addColumn: TheProjectsActivitiesAddColumn },
            meta: {
              hasMenu: true,
              viewStoreNavigation: 'TheProjects',
              requireAuth: true,
              lockBodySidePanel: true,
            },
          },
          {
            path: '/projects/:pid/activities/:kid/columns/:kcid',
            name: 'Projects - Project - Activities - Columns - Details',
            components: { columnDetail: TheProjectsActivitiesDetailsColumn },
            meta: {
              hasMenu: true,
              viewStoreNavigation: 'TheProjects',
              requireAuth: true,
              lockBodySidePanel: true,
            },
          },
          {
            path: '/projects/:pid/activities/:kid/columns/:kcid/items/add',
            name: 'Projects - Project - Activities - Items - Add ',
            components: { addItem: TheProjectsActivitiesAddItem },
            meta: {
              hasMenu: true,
              viewStoreNavigation: 'TheProjects',
              requireAuth: true,
              lockBodySidePanel: true,
            },
          },
          {
            path: '/projects/:pid/activities/:kid/columns/:kcid/items/:kiid',
            name: 'Projects - Project - Activities - Items - Details',
            components: { itemDetails: TheProjectsActivitiesDetailsItem },
            meta: {
              hasMenu: true,
              viewStoreNavigation: 'TheProjects',
              requireAuth: true,
              lockBodySidePanel: true,
            },
          },
        ],
      },
      {
        path: '/projects/:pid/members',
        name: 'Projects - Project - Members',
        components: { members: TheProjectsMembers },
        meta: {
          hasMenu: true,
          viewStoreNavigation: 'TheProjects',
          requireAuth: true,
        },
      },
      {
        path: '/projects/:pid/settings',
        name: 'Projects - Project - Settings',
        components: { settings: TheProjectsSettings },
        meta: {
          hasMenu: true,
          viewStoreNavigation: 'TheProjects',
          requireAuth: true,
        },
      },
    ],
  },
];
