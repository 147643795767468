<template>
  <section>
    <div class="dashboard">
      <DevDetails :cnt="{name: 'formatProjectList', obj: formatProjectList}" />
      <div class="content">
        <template v-if="formatProjectList.length">
          <div
            :class="['cardDashboard', {
              'col-1': gridResponsive === 1,
              'col-2': gridResponsive === 2,
              'col-3': gridResponsive === 3,
              'col-4': gridResponsive === 4,
              'col-5': gridResponsive === 5,
              'col-6': gridResponsive === 6,
            }]"
          >
            <article
              v-for="project in formatProjectList"
              :key="project.pid"
              class="card walletCard no-border max-h-inherit"
            >
              <div class="card-sticker">
                <b-icon
                  pack="fa"
                  icon="circle"
                  :class="['ml-2', {
                    'has-text-success': !project.isOpen,
                    'has-text-danger': project.isOpen,
                  }]"
                />
              </div>
              <div class="card-header border-0">
                <div class="card-title">
                  <p>{{ project.label }}</p>
                </div>
                <div class="card-action">
                  <div class="buttons">
                    <b-button
                      type="is-ghost"
                      icon-pack="fa"
                      icon-left="eye"
                      tag="router-link"
                      :to="`/projects/overview-view/project/${project.pid}`"
                    />
                  </div>
                </div>
              </div>
              <div class="card-content">
                <p class="has-text-secondary subtext is-big end">
                  {{ $moment(project.createdAt).format('YYYY-MM-DD') }}
                </p>
                <p class="text">{{ project.description }}</p>

                <br>

                <div class="multibar">
                  <p class="start title is-small">
                    {{ project.price }} €
                  </p>
                </div>
              </div>
            </article>
          </div>
        </template>
      </div>
    </div>
    <router-view name="overviewProjectSidePannel" />
    <router-view name="overviewProjectSidePannelEdit" />
    <router-view name="overviewProjectSidePannelClient" />
  </section>
</template>

<script>
/* eslint-disable max-len */
import { mapGetters } from 'vuex';

export default {
  name: 'TheProjectsOverviewView',
  props: {
    userProjectList: { type: Object, required: true },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      windowWidth: 'TheMenu/getterWindowWidth',
    }),
    formatProjectList() {
      if (!this.userProjectList) return [];

      const datas = Object.values(this.userProjectList).reduce((acc, row) => {
        const { details: { projectPid, ...details }, ...rest } = row;
        acc.push({ ...details, ...rest });
        return acc;
      }, []);

      return datas.sort((a, b) => this.$moment(b.createdAt).unix() - this.$moment(a.createdAt).unix());
    },
    gridResponsive() {
      const { windowWidth } = this;
      if (windowWidth <= 800) return 1;
      if (windowWidth <= 1200) return 2;
      if (windowWidth <= 1600) return 3;
      return 4;
    },
  },
};
</script>
