<template>
  <div class="dashboard">
    <div class="content">
      <section
        v-if="!selected"
        class="client-add-choices"
      >
        <template v-for="choice in createChoices">
          <ContactChoice
            v-if="choice.visible"
            :key="choice.key"
            :choice="choice"
            entite="selected"
            @select="select($event)"
          />
        </template>
      </section>

      <section v-else>
        <div class="multibar">
          <b-button
            class="start"
            icon-pack="fa"
            icon-left="arrow-left-long"
            type="is-tertiary"
            @click="reset()"
          />
        </div>

        <br>

        <section
          v-if="!subSelected"
          class="client-add-choices"
        >
          <template v-for="child in createChoices[selected].children">
            <ContactChoice
              v-if="child.visible"
              :key="child.key"
              :choice="child"
              entite="subSelected"
              @select="select($event)"
            />
          </template>
        </section>

        <template v-else>
          <component
            :is="cmpsCreate[subSelected]"
            :user-uid="uid"
            :account-aid="aid"
            :profile-instagram="profileInstagram"
            @newContactByYourself="newClientByYourself($event)"
            @fetchProfileInstagram="getInstagramProfile($event)"
            @contactList="newClientBySearch($event)"
          />
        </template>
      </section>
    </div>
  </div>
</template>

<script>
/* eslint-disable max-len */
import { mapGetters, mapActions } from 'vuex';
import TheClientsAddMixins from './$mixins/TheClientsAddMixins.mixins';

export default {
  name: 'TheClientsAdd',
  mixins: [TheClientsAddMixins],
  computed: {
    ...mapGetters({
      uid: 'TheClients/currentUid',
      aid: 'TheClients/currentAccountAid',
      profileInstagram: 'TheClients/profile',
    }),
  },
  methods: {
    ...mapActions({
      pushWsNotification: 'TheWsNotifier/push',
      _addClient: 'TheClients/createClient',
      _getInstagramProfile: 'TheClients/getInstagramProfile',
    }),
    homeClient() { this.$router.push({ path: '/clients' }); },
    _createClient(args) {
      if (!args) return undefined;
      const { aid, uid } = this;
      const createdAt = this.$moment().format();
      return Promise.resolve().then(() => this._addClient({
        payload: {
          aid, uid, createdAt, companyToCreate: null, personsToCreate: null, companyCidToLink: null, personPidsToLink: null, ...args,
        },
      }));
    },
    getInstagramProfile(payload) { return Promise.resolve().then(() => this._getInstagramProfile({ payload })); },
    newClientByYourself(payload) {
      if (!payload) return;
      Promise.resolve()
        .then(() => this._createClient({ personsToCreate: [payload] }))
        .then((res) => { if (res) this.homeClient(); });
    },
    newClientBySearch(e) {
      if (!Object.keys(e).length) return;
      Promise.resolve()
        .then(() => this._createClient({ personPidsToLink: Object.keys(e) }))
        .then((res) => { if (res) this.homeClient(); });
    },
  },
};
</script>
