/* eslint-disable import/no-extraneous-dependencies */
import { navySeal } from '@jack/provider';
import { isActionAllowed } from '@jack/dataStores/$utils/helpers';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {},
  actions: {
    delete({ commit, rootGetters, dispatch }, { payload = {}, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'COMPANY_EMPLOYEE.DELETE', dispatch)) return undefined;

      return navySeal.apiWsMi.company.employee.delete({ payload, httpQuery })
        .then(() => {
          const { cid } = payload;
          commit('Company/UPDATE_COMPANY_IN_COMPANY_LIST', { cid, favorite: null }, { root: true });
        });
    },
  },
};
