<template>
  <article
    v-if="typeNotif"
    :class="['notification-card', {
      'is-new': !notification.read
    }]"
  >
    <div class="notification-content">
      <b-icon
        :icon="typeNotif.icon"
        :pack="typeNotif.pack"
      />
      <div>
        <p class="subtext has-text-secondary m-0">
          {{ $formatDate(notification.createdAt) }}
        </p>
        <p class="text m-0">{{ typeNotif.content }}</p>
        <template v-if="typeNotif.type === 'notification'">
          <PersonCI
            :img="notification.persons.imageUrl"
            :name="notification.persons.fullName"
            :location="notification.persons.location"
            :job-title="notification.persons.jobTitle"
            :size="48"
            @click="redirectToContact(notification.persons.pid)"
          />
        </template>
      </div>
    </div>
    <div class="notification-action">
      <b-dropdown
        aria-role="list"
        position="is-bottom-left"
      >
        <template #trigger>
          <b-button
            type="is-tertiary"
            icon-pack="fa"
            icon-left="ellipsis-vertical"
          />
        </template>
        <b-dropdown-item
          v-if="notification.read"
          @click="$emit('readed', false)"
        >
          Mark as unread
        </b-dropdown-item>
        <b-dropdown-item
          v-else
          @click="$emit('readed', true)"
        >
          Mark as read
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </article>
</template>

<script>
export default {
  name: 'Notification',
  props: {
    notification: { type: Object, required: true },
  },
  computed: {
    typeNotif() {
      switch (this.notification.type) {
        case 'N_NEW_APPOINTMENT':
          return {
            type: 'notification',
            icon: 'calendar-plus',
            pack: 'fa',
            content: 'You have had a new appointment request',
          };
        default:
          return null;
      }
    },
  },
  methods: {
    redirectToContact(id) {
      this.$emit('readed', true);
      this.$router.push({
        path: `/networking/contacts/${id}/details`,
        query: { tab: 'appointments' },
      });
    },
  },
};
</script>
