import TheBillings from './TheBillings';

export default [
  {
    path: '/billings',
    name: 'Billings',
    component: TheBillings,
    meta: {
      hasMenu: true,
      viewStoreNavigation: 'TheBillings',
      requireAuth: true,
    },
    children: [],
  },
];
