var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.loading)?_c('section',[_c('header',{staticClass:"multibar"},[_c('div',{staticClass:"header-actions is-flex is-align-items-center end"},[_c('b-tooltip',{attrs:{"type":"is-dark","label":"Filters","position":"is-bottom","append-to-body":""}},[_c('b-button',{staticClass:"ml-5",attrs:{"type":"is-tertiary","icon-pack":"fa","icon-left":"filter"},on:{"click":function($event){return _vm.toggleSidePanelFilters()}}})],1)],1)]),(_vm.openFilter)?_c('FilterPanel',{attrs:{"queries":_vm.queries,"filters":_vm.filters},on:{"refresh":function($event){return _vm.fetchCompanies()},"close":function($event){return _vm.toggleSidePanelFilters()},"clear":function($event){return _vm.clearFilters()},"update:by":function($event){_vm.queries.by = $event},"update:search":function($event){_vm.queries.search = $event},"update:refreshed":function($event){_vm.queries.refreshed = $event},"update:hasNumber":function($event){_vm.queries.hasNumber = $event},"update:hasFavorite":function($event){_vm.queries.hasFavorite = $event},"update:contactHistory":function($event){_vm.queries.contactHistory = $event},"update:valueTF":function($event){_vm.queries.valueTF = $event},"update:timeFrame":function($event){_vm.queries.timeFrame = $event}}}):_vm._e(),_c('DevDetails',{attrs:{"cnt":{name: 'sortCompanies', obj: _vm.sortCompanies}}}),(_vm.sortCompanies.length)?[_c('br'),_c('div',{staticClass:"row is-justify-content-center"},_vm._l((_vm.sortCompanies),function(company){return _c('article',{key:company.cid,class:['card company', {
          'col1': _vm.responsive === 1,
          'col2': _vm.responsive === 2,
          'col3': _vm.responsive === 3,
        }]},[_c('header',{staticClass:"mb-5"},[_c('b-button',{class:['favorite-button', {
              'text-color-1': !company.favorite,
              'text-color-4': company.favorite,
            }],attrs:{"type":"is-tertiary","icon-pack":"fa","icon-left":"star"},on:{"click":function($event){return _vm.toggleFavorite(company)}}}),_c('div',{staticClass:"background",style:(`background:url('${company.banner}')`)}),_c('div',{staticClass:"is-flex is-justify-content-center top"},[(company.logo)?_c('div',{staticClass:"profile-picture is-64x64",style:(`background:url('${company.logo}')`)}):_c('InitialsPicture',{staticClass:"profile-picture",attrs:{"name":company.name}})],1)],1),_c('br'),_c('div',{staticClass:"content"},[(company.name)?_c('p',{staticClass:"subtitle is-big cursor-pointer",on:{"click":function($event){return _vm.selectCompany(company)}}},[_vm._v(" "+_vm._s(company.name.length <= 30 ? company.name : `${company.name.slice(0, 30)}...`)+" ")]):_vm._e(),(company.tagLine)?_c('p',{staticClass:"text is-small has-text-secondary"},[_vm._v(" "+_vm._s(`${company.tagLine.slice(0, 150)}...`)+" ")]):_vm._e()])])}),0),_c('br')]:_c('NoData',{staticClass:"noCompanies",attrs:{"title":"No companies were found... Yet!","content":"You can fetch new companies at any time"}}),(_vm.sortCompanies.length)?_c('Paginations',{attrs:{"page":_vm.page,"offset":Number(_vm.queries.pagin.offset),"limit":Number(_vm.queries.pagin.limit),"list":_vm.companiesFiltered},on:{"prev":function($event){return _vm.prev()},"next":function($event){return _vm.next()}}}):_vm._e(),(_vm.$route.meta.lockBodySidePanel || _vm.openFilter)?_c('div',{staticClass:"overlayWithLock",on:{"click":function($event){return _vm.quitPanel()}}}):_vm._e(),_c('router-view',{attrs:{"name":"details"},on:{"addFavorite":function($event){return _vm.toggleFavorite($event)}}})],2):_c('LogoAnimated',{staticClass:"h-full"})
}
var staticRenderFns = []

export { render, staticRenderFns }