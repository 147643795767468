<template>
  <div>
    TheRotate
  </div>
</template>

<script>
export default {
  name: 'TheRotate',
};
</script>
