/* eslint-disable import/no-extraneous-dependencies */
import { navySeal } from '@jack/provider';
import { isActionAllowed } from '@jack/dataStores/$utils/helpers';

export default {
  namespaced: true,
  state: {
    organization: null,
  },
  mutations: {
    SET_PHANTOMBUSTER_ORGANIZATION(state, newData) { state.organization = newData; },
  },
  getters: {
    getterOrganization: (state) => state.organization,
  },
  actions: {
    fetchAndInsert({ commit, rootGetters, dispatch }, { payload = {}, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'PHANTOM_BUSTER_ORGANIZATION.FETCH_AND_INSERT', dispatch)) return undefined;

      return navySeal.apiWsMi.phantomBuster.organization.fetchAndInsert({ payload, httpQuery })
        .then(({ organization, ...user }) => {
          commit('SET_PHANTOMBUSTER_ORGANIZATION', organization);
          commit('PhantomBuster/user/SET_PHANTOMBUSTER_USER', user, { root: true });
        });
    },
  },
};
