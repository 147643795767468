<template>
  <section v-if="!loading">
    <header class="multibar">
      <div class="header-actions is-flex is-align-items-center end">
        <b-tooltip
          type="is-dark"
          label="Filters"
          position="is-bottom"
          append-to-body
        >
          <b-button
            type="is-tertiary"
            icon-pack="fa"
            icon-left="filter"
            class="ml-5"
            @click="toggleSidePanelFilters()"
          />
        </b-tooltip>
      </div>
    </header>

    <FilterPanel
      v-if="openFilter"
      :queries="queries"
      :filters="filters"
      @refresh="fetchCompanies()"
      @close="toggleSidePanelFilters()"
      @clear="clearFilters()"
      @update:by="queries.by = $event"
      @update:search="queries.search = $event"
      @update:refreshed="queries.refreshed = $event"
      @update:hasNumber="queries.hasNumber = $event"
      @update:hasFavorite="queries.hasFavorite = $event"
      @update:contactHistory="queries.contactHistory = $event"
      @update:valueTF="queries.valueTF = $event"
      @update:timeFrame="queries.timeFrame = $event"
    />

    <DevDetails :cnt="{name: 'sortCompanies', obj: sortCompanies}" />

    <template v-if="sortCompanies.length">
      <br>
      <div class="row is-justify-content-center">
        <article
          v-for="company in sortCompanies"
          :key="company.cid"
          :class="['card company', {
            'col1': responsive === 1,
            'col2': responsive === 2,
            'col3': responsive === 3,
          }]"
        >
          <header class="mb-5 ">
            <b-button
              :class="['favorite-button', {
                'text-color-1': !company.favorite,
                'text-color-4': company.favorite,
              }]"
              type="is-tertiary"
              icon-pack="fa"
              icon-left="star"
              @click="toggleFavorite(company)"
            />
            <div
              class="background"
              :style="`background:url('${company.banner}')`"
            />
            <div class="is-flex is-justify-content-center top">
              <div
                v-if="company.logo"
                class="profile-picture is-64x64"
                :style="`background:url('${company.logo}')`"
              />
              <InitialsPicture
                v-else
                :name="company.name"
                class="profile-picture"
              />
            </div>
          </header>

          <br>
          <div class="content">
            <p
              v-if="company.name"
              class="subtitle is-big cursor-pointer"
              @click="selectCompany(company)"
            >
              <!-- eslint-disable vue/no-parsing-error-->
              {{
                company.name.length <= 30
                  ? company.name
                  : `${company.name.slice(0, 30)}...`
              }}
            </p>
            <p
              v-if="company.tagLine"
              class="text is-small has-text-secondary"
            >
              {{ `${company.tagLine.slice(0, 150)}...` }}
            </p>
          </div>
        </article>
      </div>

      <br>
    </template>
    <NoData
      v-else
      title="No companies were found... Yet!"
      content="You can fetch new companies at any time"
      class="noCompanies"
    />

    <Paginations
      v-if="sortCompanies.length"
      :page="page"
      :offset="Number(queries.pagin.offset)"
      :limit="Number(queries.pagin.limit)"
      :list="companiesFiltered"
      @prev="prev()"
      @next="next()"
    />

    <div
      v-if="$route.meta.lockBodySidePanel || openFilter"
      class="overlayWithLock"
      @click="quitPanel()"
    />

    <router-view
      name="details"
      @addFavorite="toggleFavorite($event)"
    />
  </section>
  <LogoAnimated
    v-else
    class="h-full"
  />
</template>

<script>
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable-next-line */
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TheNetworkingCompanies',
  data() {
    return {
      loading: false,
      openFilter: false,
      sorted: true,
      page: 1,
      queries: {
        pagin: {
          limit: 9,
          offset: 0,
        },
        search: null,
        by: 'name',
        hasNumber: false,
        hasFavorite: false,
        refreshed: false,
        contactHistory: null,
        valueTF: 0,
        timeFrame: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      companyList: 'TheNetworking/companyList',
      currentUid: 'TheNetworking/currentUid',
      windowWidth: 'TheMenu/getterWindowWidth',
    }),
    responsive() {
      if (Number(this.windowWidth) >= 601 && Number(this.windowWidth) <= 950) return 2;
      if (Number(this.windowWidth) <= 600) return 1;
      return 3;
    },
    filters() {
      return {
        by: {
          optionsSearch: [
            { name: 'Name', key: 'name' },
            { name: 'Description', key: 'description' },
            { name: 'Tag line', key: 'tag_line' },
            { name: 'Sector', key: 'sector' },
            { name: 'Specialisations', key: 'specialisations' },
          ],
        },
        search: {},
        refreshed: {},
        hasNumber: {},
        hasFavorite: {},
        lastContact: {
          timeFrames: ['hours', 'days', 'weeks', 'months', 'years'],
          contacts: [
            { label: 'Contacted and search profile', key: 'CONTACT_AND_SEARCH_PROFILE' },
            { label: 'No response', key: 'NO_RESPONSE' },
            { label: 'Contacted but don\'t search profile', key: 'CONTACT_BUT_DONT_SEARCH_PROFILE' },
            { label: 'Contacted on Linkedin', key: 'CONTACTED_ON_LINKEDIN_CONNECTION' },
            { label: 'Contacted per mail', key: 'CONTACT_PER_MAIL' },
          ],
        },
      };
    },
    companiesFiltered() {
      if (!this.companyList || !Object.values(this.companyList).length) return [];
      if (!this.search) {
        return Object.values(this.companyList).reduce((acc, c) => {
          if (!c.name) return acc;
          const detailsValues = Object.entries(c)
            .reduce((acc2, [_, value]) => {
              if (value) acc2 += 1;
              return acc2;
            }, 0);
          acc.push({
            ...c, favoriteSorted: c.favorite ? 1 : 0, value: detailsValues,
          });
          return acc;
        }, [])
          .sort((a, b) => this.$moment(b.fetchedAt).unix() - this.$moment(a.fetchedAt).unix());
        // .sort((a, b) => b.value - a.value);
      }

      return Object.values(this.companyList).reduce((acc, company) => {
        const { cid, name, specialisations } = company;
        const search = this.search.toLowerCase();
        const _name = name.toLowerCase();
        const _specialisations = specialisations ? specialisations.toLowerCase() : '';

        if (_name.includes(search) || _specialisations.includes(search)) {
          const detailsValues = Object.entries(company)
            .reduce((acc2, [_, value]) => {
              if (value) acc2 += 1;
              return acc2;
            }, 0);
          acc.push({
            ...company, favoriteSorted: company.favorite ? 1 : 0, value: detailsValues,
          });
          // .sort((a, b) => b.value - a.value);
        }
        return acc;
      }, []);
    },
    sortCompanies() {
      return Object.values(this.companiesFiltered)
        .sort((a, b) => b.favoriteSorted - a.favoriteSorted);
    },
  },
  mounted() { this.fetchCompanies(); },
  methods: {
    ...mapActions({
      reportCompanyList: 'TheNetworking/reportCompanyList',
      addFavorite: 'TheNetworking/addFavorite',
      removeFavorite: 'TheNetworking/removeFavorite',
    }),
    toggleSidePanelFilters() {
      this.openFilter = !this.openFilter;

      if (this.openFilter) document.getElementById('app').classList.add('body-locked');
      else document.getElementById('app').classList.remove('body-locked');
    },
    fetchCompanies() {
      this.loading = true;
      const httpQuery = Object.entries(this.queries).reduce((acc, [key, value]) => {
        if (key === 'pagin') return acc;
        if (value) acc[key] = value.toString();
        return acc;
      }, {});

      if (!this.queries.search) delete httpQuery.by;
      if (!this.queries.contactHistory) {
        delete httpQuery.valueTF;
        delete httpQuery.timeFrame;
      }
      httpQuery.pagin = `${this.queries.pagin.limit},${this.queries.pagin.offset}`;

      return Promise.resolve()
        .then(() => this.reportCompanyList({ httpQuery }))
        .then(() => { this.loading = false; });
    },
    clearFilters() {
      Object.entries(this.queries).forEach(([key, value]) => {
        if (key === 'pagin') {
          this.queries[key].limit = 20;
          this.queries[key].offset = 0;
        } else if (key === 'by') {
          this.queries[key] = 'name';
        } else if (typeof value === 'boolean') {
          this.queries[key] = false;
        } else {
          this.queries[key] = null;
        }
      });
    },
    selectCompany(company) {
      const { cid } = company;
      this.$router.push({ path: `/networking/companies/${cid}/details` });
    },
    toggleFavorite(company) {
      const { favorite, cid } = company;

      if (!favorite) this.addFavorite({ payload: { cid, userUid: this.currentUid } });
      else this.removeFavorite({ payload: { cid, userUid: this.currentUid } });
    },
    prev() {
      return Promise.resolve()
        .then(() => {
          this.queries.pagin.offset -= this.queries.pagin.limit;
          this.page -= 1;
        })
        .then(() => this.fetchCompanies());
    },
    next() {
      return Promise.resolve()
        .then(() => {
          this.queries.pagin.offset += this.queries.pagin.limit;
          this.page += 1;
        })
        .then(() => this.fetchCompanies());
    },
    quitPanel() {
      if (this.$route.meta.lockBodySidePanel) this.$router.push({ path: '/networking/companies' });
      if (this.openFilter) this.openFilter = false;
    },
  },
};
</script>
