<template>
  <section v-if="client">
    <div class="multibar">
      <div class="start">
        <p class="subtext has-text-secondary">
          Created at: {{ $moment(client.createdAt).format('YYYY-MM-DD') }}
        </p>
        <h2 class="title is-big m-0">{{ fullName }}</h2>
      </div>
    </div>

    <div class="is-flex is-justify-content-space-between">
      <div
        v-if="client.socialList && client.socialList.length"
        class="buttons is-flex"
      >
        <b-button
          v-for="(social, i) in client.socialList"
          :key="i"
          type="is-ghost"
          icon-pack="fa"
          :icon-left="social.platformName.toLowerCase()"
          tag="a"
          :href="`https://www.${social.platformName.toLowerCase()}.com/${social.username}`"
          class="m-0"
        />
      </div>
      <template v-if="client.number">
        <b-button
          v-if="!displayPhone"
          type="is-ghost"
          icon-pack="fa"
          icon-left="phone"
          @click="displayPhone = true"
        />
        <b-button
          v-else
          type="is-ghost"
          :label="client.number"
          @click="displayPhone = false"
        />
      </template>
    </div>

    <template v-if="debtor">
      <br>

      <Helper
        type="is-secondary"
        tag
        tag-type="is-danger"
        tag-label="Alert"
        :content="`This customer owes ${debtor}€`"
        content-type="danger"
      />
    </template>

    <br>

    <b-tabs
      type="is-toggle"
      expanded
    >
      <b-tab-item label="Projects history">
        <JackTabClientProjectHistory
          :client="client"
        />
      </b-tab-item>
      <b-tab-item label="Payments history">
        <JackTabClientPaymentHistory
          :client="client"
        />
      </b-tab-item>
    </b-tabs>
  </section>
</template>

<script>
/* eslint-disable no-param-reassign */
export default {
  name: 'JackSidePanelClientDetails',
  props: {
    client: { type: Object, required: true },
  },
  data() {
    return {
      displayPhone: false,
    };
  },
  computed: {
    fullName() {
      if (!this.client) return 'No client';

      const { firstName, lastName } = this.client;

      if (!firstName && !lastName) return 'No name';

      return `${firstName} ${lastName}`;
    },
    debtor() {
      const { paymentList, projectList } = this.client;
      const totalOfPayment = paymentList
        ? paymentList.reduce((acc, payment) => {
          acc += payment.deposit;
          return acc;
        }, 0)
        : 0;

      const totalOfProjectPrice = projectList
        ? projectList.reduce((acc, project) => {
          acc += project.price;
          return acc;
        }, 0)
        : 0;

      if (totalOfPayment >= totalOfProjectPrice) return false;

      return totalOfProjectPrice - totalOfPayment;
    },
  },
};
</script>
