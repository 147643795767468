<template>
  <aside class="jackSidePanel">
    <div class="multibar">
      <div class="end buttons">
        <b-button
          class="is-tertiary"
          icon-pack="fas"
          icon-right="times"
          @click="$emit('quit')"
        />
      </div>
    </div>
    TheProjectsActivitiesDetailsItem
  </aside>
</template>

<script>
export default {
  name: 'TheProjectsActivitiesDetailsItem',
};
</script>
