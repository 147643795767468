<template>
  <div>
    <h1 class="title is-big">Chart.js</h1>
    <canvas
      id="myChart"
      width="400"
      height="200"
    />
  </div>
</template>

<script>
import Chart from 'chart.js/auto';

export default {
  name: 'TheChartJs',
  data() {
    return {
      chart: null,
      config: {
        type: 'doughnut',
        data: {
          labels: Array(12).fill().map((_, i) => `color-${i + 1}`),
          datasets: [{
            label: '# of Votes',
            data: Array(12).fill().map(() => Math.floor(Math.random() * 100)),
            backgroundColor: [
              'rgba(132, 132, 132, .8)',
              'rgba(255, 153, 146, .8)',
              'rgba(255, 194, 159, .8)',
              'rgba(255, 217, 160, .8)',
              'rgba(163, 223, 129, .8)',
              'rgba(166, 231, 176, .8)',
              'rgba(144, 226, 213, .8)',
              'rgba(144, 195, 247, .8)',
              'rgba(165, 155, 237, .8)',
              'rgba(210, 178, 246, .8)',
              'rgba(253, 174, 243, .8)',
              'rgba(255, 156, 193, .8)',
            ],
          }],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      },
    };
  },
  mounted() {
    const ctx = document.getElementById('myChart').getContext('2d');
    this.chart = new Chart(ctx, this.config);
  },
};
</script>
