<template>
  <section class="conditions-generales">
    <div class="content">
      <h1 class="bigtitle is-big is-uppercase text-center">
        Conditions générales d'utilisation
      </h1>

      <br>

      <p class="lead">
        Hello world
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'TheCGU',
};
</script>
