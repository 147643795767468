<template>
  <!-- eslint-disable max-len -->
  <aside class="jackSidePanel">
    <div class="is-flex is-align-items-center is-justify-content-space-between">
      <b-button
        class="is-ghost"
        tag="router-link"
        :to="goBack"
        icon-pack="fa"
        icon-right="arrow-left"
      />
      <b-button
        class="is-ghost"
        tag="router-link"
        to="/projects/detailled-view"
        icon-pack="fas"
        icon-right="times"
      />
    </div>

    <br>
    <DevDetails :cnt="{name: 'client', obj: client}" />

    <JackSidePanelClientDetails
      v-if="client"
      :client="client"
    />

    <section v-else>
      no client
    </section>
  </aside>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/order */
import TheProjectsDetailledColumns from '../../$mixins/TheProjectsDetailledColumns.column.mixins';
import utilsMixins from '@jack/addons/common/$mixins/utils.mixins';

export default {
  name: 'TheProjectSidePannelClient',
  mixins: [
    utilsMixins,
    TheProjectsDetailledColumns,
  ],
  data() {
    return {
      loading: false,
      total: 100,
      page: 1,
      perPage: 50,
      sortField: 'cid',
      sortOrder: 'asc',
      defaultSortOrder: 'asc',
      selected: {},
      checkedRows: [],
      search: '',
      inputSearch: '',
      currentCampaignViewDetailled: null,
    };
  },
  computed: {
    ...mapGetters({
      client: 'TheProjects/client',
    }),
    goBack() {
      return this.currentPath.split('/').slice(0, -2).join('/');
    },
    currentClient() {
      return this.$route.params.cid;
    },
  },
  mounted() {
    this.loading = true;
    const payload = { cid: this.currentClient };
    const httpQuery = {
      details: 'true', socialList: 'true', projectList: 'true', paymentList: 'true',
    };

    this.getClient({ payload, httpQuery })
      .then(() => { this.loading = false; });
  },
  methods: {
    ...mapActions({
      getClient: 'TheProjects/getClient',
    }),
    openDetails(row) {
      this.$router.push(`/projects/detailled-view/project/${row.pid}`);
    },
  },
};
</script>
