<template>
  <section
    id="TheMembers"
    class="dashboard"
  >
    <div class="content">
      <NoData
        title="Members is coming soon"
        content="Stay patient, we are making it to the smallest detail"
        class="noMessaging"
      />
    </div>
  </section>
</template>

<script>
export default {
  name: 'TheMembers',
};
</script>
