<template>
  <article
    :class="['containerCard card mb-5', {
      'success': resultStatus.background === 'success',
      'warning': resultStatus.background === 'warning',
      'danger': resultStatus.background === 'danger',
    }]"
  >
    <div class="content">
      <div class="multibar is-align-items-center">
        <div class="start">
          <p class="subtext has-text-secondary">
            {{ $moment(container.createdAt).format('YYYY-MM-DD') }}
          </p>
          <p class="text m-0">
            ID: <span class="has-text-secondary">{{ container.containerId }}</span>
          </p>
          <p
            v-if="container.query"
            class="text m-0"
          >
            Query: <span class="has-text-secondary">{{ container.query }}</span>
          </p>
          <p class="text m-0">
            Status:
            <span
              :class="`text-color-${resultStatus.color}`"
            >
              {{ resultStatus.status }}
            </span>
          </p>
        </div>
        <!-- <div class="end">
          <div class="buttons">
            <b-button
              icon-pack="fa"
              icon-left="arrows-rotate"
              type="is-ghost"
              @click="$emit('refreshContainer', container)"
            />
            <b-button
              icon-pack="fa"
              :icon-left="openOutput ? 'eye-slash': 'eye'"
              type="is-ghost"
              :disabled="container.status !== 'finished'"
              @click="toggleOutput()"
            />
            <b-button
              icon-pack="fa"
              icon-left="download"
              type="is-ghost"
              :disabled="!dataIsSavedSuccessfully"
              @click="$emit('resultContainer', container)"
            />
          </div>
        </div> -->
      </div>

      <template v-if="container.output && openOutput">
        <br>
        <p class="text">Output:</p>
        <p
          v-for="(output, i) in container.output.split('*')"
          :key="i"
          class="subtext m-0 has-text-secondary"
        >
          {{ output }}
        </p>
      </template>
    </div>
  </article>
</template>

<script>
export default {
  name: 'ContainerCard',
  props: {
    container: { type: Object, required: true },
  },
  data() {
    return {
      openOutput: false,
    };
  },
  computed: {
    dataIsSavedSuccessfully() {
      const { container: { output } } = this;
      if (!output) return false;

      return !!output.includes('Data successfully saved');
    },
    resultStatus() {
      const { container: { status, results } } = this;

      if (status === 'running') return { color: 3, status: 'Running', background: 'warning' };
      if (results || (status === 'finished' && this.dataIsSavedSuccessfully)) return { color: 5, status: 'Finished', background: 'success' };
      return { color: 2, status: 'Error', background: 'danger' };
    },
  },
  methods: {
    toggleOutput() {
      if (this.container.output) {
        this.openOutput = !this.openOutput;
        return;
      }
      this.$emit('outputContainer', this.container);
      this.openOutput = !this.openOutput;
    },
  },
};
</script>
