import { use, registerComponent } from '../../$utils/plugins';

import JackHomeHeader from './JackHomeHeader';

import DashboardCard from './Dashboard/DashboardCard';
import DashboardHelloWorld from './Dashboard/DashboardHelloWorld';
import DashboardTable from './Dashboard/DashboardTable';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, JackHomeHeader);

    registerComponent(Vue, DashboardCard);
    registerComponent(Vue, DashboardHelloWorld);
    registerComponent(Vue, DashboardTable);
  },
};

use(Plugin);

export default Plugin;

export {
  JackHomeHeader,

  DashboardCard,
  DashboardHelloWorld,
  DashboardTable,
};
