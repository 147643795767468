<template>
  <section class="dashboard">
    <DevDetails :cnt="{name: 'notificationList', obj: notificationList}" />
    <DevDetails :cnt="{name: 'notificationFormatted', obj: notificationFormatted}" />
    <div class="content">
      <section
        v-for="date in notificationFormatted"
        :key="date.date"
        class="py-2"
      >
        <p class="subtitle m-0">{{ date.date }}</p>

        <br>

        <div class="notification-container">
          <Notification
            v-for="notif in date.notifs"
            :key="notif.nid"
            :notification="notif"
            @readed="notifReaded(notif, $event)"
          />
        </div>
      </section>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TheNotifications',
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      notificationList: 'TheNotifications/notificationList',
    }),
    notificationFormatted() {
      if (!this.notificationList || !this.notificationList.length) return {};
      return this.notificationList
        .reduce((acc, notif) => {
          const notifDate = this.$formatDate(notif.createdAt);
          const date = this.isToday(notifDate) ? 'Today' : this.$moment(notifDate).format('dddd, MMMM Do YYYY');

          if (!acc[date]) {
            acc[date] = { date, notifs: [notif] };
            return acc;
          }

          acc[date].notifs.push(notif);
          return acc;
        }, {});
    },
  },
  methods: {
    ...mapActions({
      patchNotification: 'TheNotifications/patchNotification',
    }),
    arrSorted(arr) {
      return arr.sort((a, b) => this.$moment(b.createdAt) - this.$moment(a.createdAt).unix());
    },
    isToday(date) {
      const today = this.$formatDate(this.$moment());
      return date === today;
    },
    notifReaded({ nid, read: _read }, read) {
      if (_read === read) return;
      this.patchNotification({
        payload: {
          nid,
          read,
          readedAt: this.$moment().format(),
        },
      });
    },
  },
};
</script>
