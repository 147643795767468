var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{class:['form-add', {
    'side-panel': _vm.sidepanel
  }]},[_c('div',{staticClass:"content-form"},[_c('h1',{staticClass:"title is-small"},[_vm._v(" "+_vm._s(_vm.$_('Create a contact by yourself'))+" ")]),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.newContactByYourself()}}},[_vm._l((_vm.inputs),function(input){return [(input.key !== 'vue-tel-input')?_c('b-field',{key:input.key,class:['mb-2', {
            'bg-solitude': !_vm.sidepanel
          }],attrs:{"label":input.label}},[_c(input.cmp,{tag:"component",attrs:{"required":input.required,"type":input.type},model:{value:(_vm.person[input.key]),callback:function ($$v) {_vm.$set(_vm.person, input.key, $$v)},expression:"person[input.key]"}})],1):_vm._e(),(input.key === 'vue-tel-input')?_c('b-field',{key:input.key,class:[{
            'bg-solitude': !_vm.sidepanel
          }],attrs:{"label":input.label}},[_c('vue-tel-input',{attrs:{"default-country":"fr","preferred-countries":_vm.preferredCountries,"input-options":_vm.inputOptions,"auto-format":false,"invalid-msg":_vm.$_('Invalid phone number'),"valid-characters-only":""},on:{"country-changed":function($event){return _vm.changeCodeCountry($event)},"validate":function($event){return _vm.validPhoneNumberMethod($event)}},model:{value:(_vm.person.number),callback:function ($$v) {_vm.$set(_vm.person, "number", $$v)},expression:"person.number"}})],1):_vm._e()]}),_c('br'),_c('b-button',{attrs:{"native-type":"submit","type":"is-primary","icon-pack":"fa","icon-left":"check","expanded":"","disabled":_vm.disableButton}})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }