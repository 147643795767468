/* eslint-disable import/no-extraneous-dependencies */
import { navySeal } from '@jack/provider';
import { isActionAllowed } from '@jack/dataStores/$utils/helpers';

const moment = require('moment');

export default {
  namespaced: true,
  state: {
    containerList: {},
    container: null,
    urls: {},
  },
  mutations: {
    SET_PHANTOMBUSTER_CONTAINERS_LIST(state, newData) { state.containerList = newData; },
    UPDATE_PHANTOMBUSTER_CONTAINER_IN_CONTAINER_LIST(state, newData) {
      state.containerList[newData.pbcid] = {
        ...state.containerList[newData.pbcid],
        ...newData,
      };
    },
    SET_PHANTOMBUSTER_CONTAINER(state, newData) { state.container = newData; },
    SET_PHANTOMBUSTER_CONTAINER_URLS(state, newData) {
      state.urls[newData.pbcid] = newData;
    },
  },
  getters: {
    getterContainerList: (state) => state.containerList,
    getterContainer: (state) => state.container,
  },
  actions: {
    report({ commit, rootGetters, dispatch }, { payload = {}, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'PHANTOM_BUSTER_CONTAINERS.REPORT', dispatch)) return undefined;

      return navySeal.apiWsMi.phantomBuster.containers.report({ payload, httpQuery })
        .then((containers) => {
          const containerList = containers.reduce((acc, container) => {
            acc[container.pbcid] = { ...container };
            return acc;
          }, {});
          commit('SET_PHANTOMBUSTER_CONTAINERS_LIST', containerList);
        });
    },
    get({ commit, rootGetters, dispatch }, { payload = {}, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'PHANTOM_BUSTER_CONTAINERS.GET', dispatch)) return undefined;

      return navySeal.apiWsMi.phantomBuster.containers.get({ payload, httpQuery })
        .then((container) => {
          commit('SET_PHANTOMBUSTER_CONTAINER', container);
        });
    },
    fetchAllAndInsert({ commit, rootGetters, dispatch }, { payload = {}, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'PHANTOM_BUSTER_CONTAINERS.FETCH_ALL_AND_INSERT', dispatch)) return undefined;

      return navySeal.apiWsMi.phantomBuster.containers.fetchAllAndInsert({ payload, httpQuery })
        .then((containers) => {
          commit('SET_PHANTOMBUSTER_CONTAINERS_LIST', containers);
        });
    },
    fetchAndUpdate(
      { commit, rootGetters, dispatch },
      { payload: { pbaid, ...payload }, httpQuery = {} },
    ) {
      if (!isActionAllowed(rootGetters, 'PHANTOM_BUSTER_CONTAINERS.FETCH_AND_UPDATE', dispatch)) return undefined;

      return navySeal.apiWsMi.phantomBuster.containers.fetchAndUpdate({ payload, httpQuery })
        .then((container) => {
          const {
            id, status, createdAt, launchType,
          } = container;

          const newData = {
            ...payload,
            pbaid,
            containerId: id,
            status,
            launchType,
            createdAt: moment(createdAt).format(),
            endedAt: container.endedAt ? moment(container.endedAt).format() : null,
            output: null,
            results: false,
          };

          commit('UPDATE_PHANTOMBUSTER_CONTAINER_IN_CONTAINER_LIST', newData);
        });
    },
    fetchResult({ commit, rootGetters, dispatch }, { payload: { pbaid, ...payload }, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'PHANTOM_BUSTER_CONTAINERS.FETCH_AND_UPDATE', dispatch)) return undefined;

      return navySeal.apiWsMi.phantomBuster.containers.fetchResult({ payload, httpQuery })
        .then((result) => {
          console.log(result);
          // commit('UPDATE_PHANTOMBUSTER_CONTAINER_IN_CONTAINER_LIST', { ...result, pbaid });
        });
    },
    fetchOutput({ commit, rootGetters, dispatch }, { payload: { pbaid, ...payload }, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'PHANTOM_BUSTER_CONTAINERS.FETCH_OUTPUT', dispatch)) return undefined;

      return navySeal.apiWsMi.phantomBuster.containers.fetchOutput({ payload, httpQuery })
        .then(({ output }) => {
          commit('UPDATE_PHANTOMBUSTER_CONTAINER_IN_CONTAINER_LIST', { output, pbaid });
        });
    },
  },
};
