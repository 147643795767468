<template>
  <b-modal
    v-model="localOpenModal"
    has-modal-card
    trap-focus
    :destroy-on-hide="false"
    aria-role="dialog"
    aria-label="Example Modal"
    close-button-aria-label="Close"
    aria-modal
    @close="localOpenModal = false"
  >
    <template #default>
      <div class="modal-container">
        <header class="multibar is-align-items-center">
          <div class="header-title start">
            <h1 class="title is-small m-0">
              Templates
              <a
                :href="`mailto:${mail}`"
                class="text is-small"
              >
                ({{ mail }})
              </a>
            </h1>
          </div>
        </header>

        <br>
        <div class="template-choose">
          <TemplateCard
            v-for="template in templateSorted"
            :key="template.tid"
            :template="template"
            :mail="mail"
          />
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */
import { mapGetters } from 'vuex';
import { debounce } from '@jack/addons/$utils/helpers';

export default {
  name: 'ModalChooseTemplate',
  props: {
    openModal: { type: Boolean, required: true },
    mail: { type: String, required: true },
  },
  data() {
    return {
      search: null,
      inputSearch: null,
    };
  },
  computed: {
    ...mapGetters({
      templateList: 'TheTemplates/templateList',
    }),
    localOpenModal: {
      get() { return this.openModal; },
      set(newValue) { this.$emit('update:openModal', newValue); },
    },
    templateSorted() {
      if (!Object.values(this.templateList).length) return [];
      return Object.values(this.templateList)
        .reduce((acc, template) => {
          if (!this.search) {
            acc.push(template);
            return acc;
          }

          const { subject, content } = template;
          const _subject = subject ? subject.toLowerCase() : '';
          const _content = content ? content.toLowerCase() : '';

          if (
            _subject.includes(this.search)
            || _content.includes(this.search)
          ) acc.push(template);
          return acc;
        }, [])
        .sort((a, b) => this.$moment(b.createdAt) - this.$moment(a.createdAt));
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    inputSearch: debounce(function (newVal) {
      this.search = newVal;
    }, 0),
  },
};
</script>
