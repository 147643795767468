<template>
  <section
    v-if="paymentList"
    class="mt-5"
  >
    <div class="card">
      <div class="card-content">
        <div class="multibar">
          <p class="text has-text-secondary">
            Total payments
          </p>
        </div>

        <br>

        <div class="multibar">
          <p class="title is-big">
            {{ paymentList.length }}
          </p>
        </div>
      </div>
    </div>

    <hr>

    <div
      v-for="payment in paymentList"
      :key="payment.pid"
      class="card mb-3 no-border"
    >
      <div class="card-content">
        <div class="multibar">
          <p class="start text has-text-secondary">
            {{ $moment(payment.createdAt).format('YYYY-MM-DD') }}
          </p>
          <p class="end text m-0 has-text-secondary">
            {{ payment.projectLabel }}
          </p>
        </div>

        <div class="multibar">
          <p class="title is-medium start m-0">
            + {{ payment.deposit }} €
          </p>
          <p class="end text has-text-secondary">
            {{ payment.description }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'JackTabClientPaymentHistory',
  props: {
    client: { type: Object, required: true },
  },
  computed: {
    paymentList() {
      if (!this.client) return [];

      const { projectList, paymentList } = this.client;

      if (!paymentList || !projectList) return [];

      return paymentList.reduce((acc, row) => {
        let project;

        if (projectList && projectList.length) {
          project = projectList.find(({ pid }) => Number(pid) === Number(row.projectPid));
        }

        acc.push({
          ...row,
          projectLabel: project ? project.label : '',
        });
        return acc;
      }, []);
    },
  },
};
</script>
