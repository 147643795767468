import moment from 'moment';

const engine = () => ({
  appointmentPayload({ appointment, label, description }) {
    return {
      createdAt: moment().format(),
      state: 'PENDING_CONFIRMATION',
      details: {
        label: label || appointment.label,
        description: description || appointment.description,
        date: appointment.date,
        duration: 30,
      },
    };
  },
  personToPatchPayload({ type, person, pid }) {
    if (type !== 'SAVE_APPOINTMENT_PATCH_PERSON') return null;
    return {
      pid,
      mail: person.mail,
      number: person.number,
    };
  },
  personToCreatePayload({ type, person }) {
    if (type !== 'SAVE_APPOINTMENT_CREATE_PERSON') return null;
    return {
      fetchedAt: moment().format(),
      refreshAt: null,
      source: 'APPOINTMENT',
      extrefId: null,
      details: {
        firstName: person.firstName,
        lastName: person.lastName,
        fullName: null,
        description: null,
        headLine: null,
        imageUrl: null,
        location: null,
        profileUrl: null,
        backgroundUrl: null,
        mail: person.email,
        number: person.number,
        subscribers: null,
        jobTitle: null,
      },
    };
  },
});

export default engine;
