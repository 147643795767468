<template>
  <!-- eslint-disable max-len -->
  <section class="mt-5">
    <template v-if="informationClientprojects">
      <div class="is-flex is-justify-content-space-around w-100">
        <div
          v-for="stat in informationClientprojects"
          :key="stat.label"
          class="card"
        >
          <div class="card-content">
            <div class="multibar">
              <p class="text has-text-secondary">{{ stat.label }}</p>
            </div>

            <br>

            <div class="multibar title is-big">
              <span
                :class="[{
                  'has-text-success': stat.label === 'Projects completed' && stat.value >= 1,
                  'has-text-danger': stat.label === 'In progress' && informationClientprojects.inProgress.value >= 1
                }]"
              >
                {{ stat.value }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </template>

    <hr>

    <Table
      v-if="client.projectList && client.projectList.length"
      :data="client.projectList"
      :columns="Object.values(columns)"
      :loading="loading"
      :total="client.projectList.length"
      :page="page"
      :per-page="perPage"
      :sort-field="sortField"
      :sort-order="sortOrder"
      :default-sort-order="defaultSortOrder"
      :selected.sync="selected"
      :checked-rows.sync="checkedRows"
      :backend-sorting="false"
      :sticky-header="false"
      detail-key="pid"
      @changeStatus="updateStatus($event)"
      @onPageChange="onPageChange($event)"
      @update:checkedRows="changeCheckedRows($event)"
      @update:selected="changeSelected($event)"
      @openDetails="openDetails($event)"
    />
  </section>
</template>

<script>
/* eslint-disable-next-line */
import TheProjectsDetailledColumns from '@jack/addons/projects/$mixins/TheProjectsDetailledColumns.column.mixins';

export default {
  name: 'JackTabClientProjectHistory',
  mixins: [TheProjectsDetailledColumns],
  props: {
    client: { type: Object, required: true },
  },
  data() {
    return {
      loading: false,
      total: 100,
      page: 1,
      perPage: 50,
      sortField: 'cid',
      sortOrder: 'asc',
      defaultSortOrder: 'asc',
      selected: {},
      checkedRows: [],
      search: '',
      inputSearch: '',
      currentCampaignViewDetailled: null,
    };
  },
  computed: {
    informationClientprojects() {
      if (!this.client || !this.client.projectList) return null;
      const inProgress = this.client.projectList.reduce((acc, row) => {
        if (row.isOpen) acc.push(row);
        return acc;
      }, []).length;

      return {
        totalProject: { value: this.client.projectList.length, label: 'Total projects' },
        projectCompleted: { value: this.client.projectList.length - inProgress, label: 'Projects completed' },
        inProgress: { value: inProgress, label: 'In progress' },
      };
    },
    currentPath() {
      return this.$route.fullPath;
    },
  },
  methods: {
    openDetails(row) {
      const basePath = this.currentPath.split('/').splice(0, 3).join('/');
      if (this.$route.name.includes('Side pannel project')) {
        this.$router.push(`${basePath}/project/${row.pid}`);
      }
      if (this.$route.name.includes('Side pannel client')) {
        this.$router.push(`${this.currentPath}/project/${row.pid}`);
      }
    },
  },
};
</script>
