/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import { actions } from '@jack/dataStores/commonViewStores';

export default {
  namespaced: true,
  state: {
    isAllCustomFetched: false,
    isAllCustomAsked: false,
    theme: false,
  },
  getters: {
    isAllCustomFetched: (state) => state.isAllCustomFetched,
    isAllCustomAsked: (state) => state.isAllCustomAsked,
    getterTheme: (state) => state.theme,
  },
  mutations: {
    SET_ALL_CUSTOMS_FETCHED(state) { state.isAllCustomFetched = true; },
    SET_ALL_CUSTOMS_ASKED(state) { state.isAllCustomAsked = true; },
    RESET_STORE(state) {
      state.isAllCustomFetched = false;
    },
    SET_THEME(state, newData) { state.theme = newData; },
  },
  actions: {
    initApplication(params) {
      const { rootGetters, commit } = params;
      const { 'Authenticate/getterAuthenticate': myAuthenticate } = rootGetters;
      const {
        account: {
          // hasAgent,
          templateList,
        },
      } = myAuthenticate;

      commit('Templates/SET_TEMPLATE_LIST', templateList.arrayMapper('tid'), { root: true });
      // params.commit('SET_ALL_CUSTOMS_FETCHED');
      // const hasAgents = hasAgent && hasAgent.length
      //   ? hasAgent.map(({ pbAgentsPbaid }) => pbAgentsPbaid)
      //   : [];

      return Promise.allSettled([
        actions.user.reportNotifications(params, { payload: {}, httpQuery: {} }),
        // hasAgents && hasAgents.length
        //   ? actions.agents.report(params, { httpQuery: { whereIn: hasAgents, hasContainers: 'true' } })
        //   : null,
      ])
        .then(() => params.commit('SET_ALL_CUSTOMS_FETCHED'));
    },
    updateTheme({ commit }, payload) {
      commit('SET_THEME', payload);
    },
  },
};
