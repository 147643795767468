<template>
  <!-- eslint-disable max-len -->
  <section
    id="TheSettings"
    class="dashboard"
  >
    <div class="content">
      <section>
        <div class="multibar is-flex is-align-items-center">
          <div class="start">
            <p class="text is-small m-0">PhantomBuster's token</p>
            <span
              v-if="phantomBusterToken && phantomBusterToken.token"
              class="has-text-secondary"
            >
              {{ '*'.repeat(phantomBusterToken.token.length / 2) }}
            </span>
          </div>
          <div class="buttons end">
            <b-tooltip
              type="is-dark"
              :label="phantomBusterToken ? 'Update API key' : 'Save API key'"
              position="is-bottom"
            >
              <b-button
                type="is-tertiary"
                icon-pack="fa"
                icon-left="pen"
                tag="router-link"
                to="/settings/phantombuster/token"
              />
              <b-tooltip
                v-if="phantomBusterToken"
                type="is-dark"
                label="Delete API key"
                position="is-bottom"
              >
                <b-button
                  v-if="phantomBusterToken"
                  type="is-tertiary"
                  icon-pack="fa"
                  icon-left="trash"
                  @click="deleteToken({ payload: { pbtid: phantomBusterToken.pbtid } })"
                />
              </b-tooltip>
            </b-tooltip>
          </div>
        </div>
      </section>

      <template v-if="phantomBusterToken">
        <br>

        <section>
          <div class="multibar is-flex is-align-items-center">
            <p class="start text is-small m-0">Retrieve phantoms</p>
            <div class="end">
              <b-tooltip
                type="is-dark"
                label="Fetch phantoms"
                position="is-bottom"
              >
                <b-button
                  type="is-tertiary"
                  icon-pack="fa"
                  icon-left="ghost"
                  @click="_fetchAllAndInsertAgents({})"
                />
              </b-tooltip>
            </div>
          </div>
        </section>

        <br>

        <section>
          <p class="text is-small">Phantoms</p>
          <DevDetails :cnt="{name: 'agentList', obj: agentList}" />
          <DevDetails :cnt="{name: 'hover', obj: hover}" />

          <div
            v-if="agentList && Object.values(agentList).length"
            class="is-flex is-flex-direction-column"
          >
            <article
              v-for="a in agentList"
              :key="a.pbaid"
              class="m-0 mb-5 card phantomBuster is-flex is-flex-direction-row transparent no-shadow"
            >
              <div class="header">
                <b-button
                  icon-pack="fa"
                  :icon-left="hover !== a.pbaid ? 'ghost': 'gear'"
                  :class="[{
                    'is-hover': hover === a.pbaid
                  }]"
                  @mouseover="hover = a.pbaid"
                  @mouseleave="hover = null"
                  @click="openDetails(a)"
                />
              </div>

              <div
                class="content p-0 is-flex is-flex-direction-column is-justify-content-center ml-5"
              >
                <h3 class="text is-3 m-0">{{ a.name }}</h3>
                <p class="subtext has-text-secondary m-0">{{ a.description }}</p>
              </div>
            </article>
          </div>
        </section>
      </template>
    </div>

    <router-view name="editToken" />
    <router-view
      name="agent"
      :agent="agent"
    />
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TheSettingsPhantomBuster',
  data() {
    return {
      loading: false,
      hover: null,
      agent: {},
    };
  },
  computed: {
    ...mapGetters({
      agentList: 'TheSettingsPhantomBuster/agentList',
      phantomBusterToken: 'TheSettingsPhantomBuster/phantomBusterToken',
    }),
  },
  methods: {
    ...mapActions({
      fetchAndInsertAgent: 'TheSettingsPhantomBuster/fetchAndInsertAgent',
      fetchAllAndInsertAgents: 'TheSettingsPhantomBuster/fetchAllAndInsertAgents',
      report: 'TheSettingsPhantomBuster/report',
      deleteToken: 'TheSettingsPhantomBuster/deleteToken',
    }),
    _fetchAllAndInsertAgents() {
      if (!this.phantomBusterToken) return;
      this.fetchAllAndInsertAgents({});
    },
    openDetails(agent) {
      const { pbaid } = agent;
      this.agent = agent;
      this.$router.push(`/settings/phantombuster/agent/${pbaid}`);
    },
  },
};
</script>
