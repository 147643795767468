<template>
  <section
    v-if="person"
    class="w-100 is-flex is-align-items-center is-justify-content-space-between m-3"
  >
    <div class="w-100 is-flex is-align-items-center">
      <div class="is-flex m-3">
        <div class="media-left">
          <div
            v-if="image"
            class="profile-picture item size is-40x40"
            :style="`background:url('${image}')`"
          />
          <template v-else>
            <InitialsPicture
              v-if="fullName"
              :name="fullName"
              class="item"
              :size="40"
            />
          </template>
        </div>
        <div class="media-content">
          <p
            v-if="fullName"
            class="text is-small cursor-pointer p-0 m-0"
          >
            {{ fullName }}
          </p>
          <p
            v-if="jobTitle"
            class="disclamer has-text-secondary"
          >
            {{ jobTitle }}
          </p>
        </div>
      </div>
    </div>
    <b-button
      type="is-tertiary"
      icon-left="xmark"
      icon-pack="fa"
      class="mr-2"
      @click="$emit('removePerson', person.pid)"
    />
  </section>
</template>

<script>
export default {
  name: 'AddPersonItem',
  props: {
    person: { type: Object, required: true },
  },
  computed: {
    image() {
      if (this.person.details) return this.person.details.imageUrl;
      return this.person.imageUrl;
    },
    fullName() {
      if (this.person.details) return this.person.details.fullName;
      return this.person.fullName;
    },
    jobTitle() {
      if (this.person.details) return this.person.details.jobTitle;
      return this.person.jobTitle;
    },
  },
};
</script>
