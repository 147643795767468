/* eslint-disable import/no-extraneous-dependencies */
import { navySeal } from '@jack/provider';
import { isActionAllowed } from '@jack/dataStores/$utils/helpers';

export default {
  namespaced: true,
  state: { },
  mutations: {},
  getters: {},
  actions: {
    post({ dispatch, commit, rootGetters }, { payload = {}, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'COMPANY_TAG.CREATE', dispatch)) return undefined;

      return navySeal.apiWsMi.company.tag.post({ payload, httpQuery })
        .then((tag) => {
          const { 'Company/getterCompany': company } = rootGetters;
          if (!company.tags) company.tags = [tag.tagTid];
          else company.tags.push(tag.tagTid);

          commit('Company/SET_COMPANY', company, { root: true });
          return tag;
        });
    },
    delete({ commit, rootGetters, dispatch }, { payload = {}, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'COMPANY_TAG.DELETE', dispatch)) return undefined;

      return navySeal.apiWsMi.company.tag.delete({ payload, httpQuery })
        .then(() => {
          const { 'Company/getterCompany': company } = rootGetters;
          commit(
            'Company/SET_COMPANY',
            {
              ...company,
              tags: company.tags.filter((tid) => tid !== payload.tid),
            },
            { root: true },
          );
          return true;
        });
    },
  },
};
