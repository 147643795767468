// import { navySeal } from '@jack/provider';
// import { isActionAllowed } from '../../$utils/helpers'

export default {
  namespaced: true,
  state: {
    currentUserDetails: null,
  },
  getters: {
    gettersCurrentUserDetails: (state) => state.currentUserDetails,
  },
  mutations: {
    SET_USER_DETAIL(state, newData) {
      state.currentUserDetail = newData;
    },
  },
  actions: {
    // update({ commit, rootGetters }, { payload }) {

    // },
  },
};
