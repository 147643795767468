/* eslint-disable object-curly-newline */
/* eslint-disable import/prefer-default-export */
import { builder } from '../engine';

const target = 'apiWsJoe';

const categories = {
  report: ({ payload, httpQuery }) => builder({ url: '/categories', method: 'REPORT', payload, httpQuery, target }),
};

const client = {
  // report: ({ payload, httpQuery }) => builder({ url: '/client/', method: 'REPORT', payload, httpQuery, target }),
  // get: ({ payload, httpQuery }) => builder({ url: `/client/${payload.cid}`, method: 'GET', payload, httpQuery, target }),
  post: ({ payload, httpQuery }) => builder({ url: '/client/', method: 'POST', payload, httpQuery, target }),
  // put: ({ payload, httpQuery }) => builder({ url: `/client/${payload.cid}`, method: 'PUT', payload, httpQuery, target }),
  delete: ({ payload, httpQuery }) => builder({ url: `/client/${payload.cid}`, method: 'DELETE', payload, httpQuery, target }),
  project: {
    report: ({ payload, httpQuery }) => builder({ url: `/client/${payload.cid}/project`, method: 'REPORT', payload, httpQuery, target }),
    get: ({ payload, httpQuery }) => builder({ url: `/client/${payload.cid}/project/${payload.pid}`, method: 'GET', payload, httpQuery, target }),
    post: ({ payload, httpQuery }) => builder({ url: `/client/${payload.cid}/project`, method: 'POST', payload, httpQuery, target }),
    put: ({ payload, httpQuery }) => builder({ url: `/client/${payload.cid}/project/${payload.pid}`, method: 'PUT', payload, httpQuery, target }),
    delete: ({ payload, httpQuery }) => builder({ url: `/client/${payload.cid}/project/${payload.pid}`, method: 'DELETE', payload, httpQuery, target }),
  },
  link: {
    person: {
      post: ({ payload, httpQuery }) => builder({ url: `/client/${payload.clientCid}/link/person/${payload.personPid}`, method: 'POST', payload, httpQuery, target }),
    },
    company: {
      post: ({ payload, httpQuery }) => builder({ url: `/client/${payload.clientCid}/link/company/${payload.companyCid}`, method: 'POST', payload, httpQuery, target }),
    },
  },
  unlink: {
    person: {
      post: ({ payload, httpQuery }) => builder({ url: `/client/${payload.clientCid}/unlink/person/${payload.personPid}`, method: 'POST', payload, httpQuery, target }),
    },
    company: {
      post: ({ payload, httpQuery }) => builder({ url: `/client/${payload.clientCid}/unlink/company/${payload.companyCid}`, method: 'POST', payload, httpQuery, target }),
    },
  },
  tag: {
    post: ({ payload, httpQuery }) => builder({ url: `/client/${payload.cid}/tag/${payload.tid}`, method: 'POST', payload, httpQuery, target }),
    delete: ({ payload, httpQuery }) => builder({ url: `/client/${payload.cid}/tag/${payload.tid}`, method: 'DELETE', payload, httpQuery, target }),
  },
};

const project = {
  kanban: {
    get: ({ payload, httpQuery }) => builder({ url: `/project/${payload.pid}/kanban/${payload.kid}`, method: 'GET', payload, httpQuery, target }),
    post: ({ payload, httpQuery }) => builder({ url: `/project/${payload.pid}/kanban`, method: 'POST', payload, httpQuery, target }),
    patch: ({ payload, httpQuery }) => builder({ url: `/project/${payload.pid}/kanban/${payload.kid}`, method: 'PATCH', payload, httpQuery, target }),
    delete: ({ payload, httpQuery }) => builder({ url: `/project/${payload.pid}/kanban/${payload.kid}`, method: 'DELETE', payload, httpQuery, target }),
    kanbanColumn: {
      post: ({ payload, httpQuery }) => builder({ url: `/project/${payload.pid}/kanban/${payload.kid}/kanbanColumn`, method: 'POST', payload, httpQuery, target }),
      put: ({ payload, httpQuery }) => builder({ url: `/project/${payload.pid}/kanban/${payload.kid}/kanbanColumn/${payload.kcid}`, method: 'PUT', payload, httpQuery, target }),
      patch: ({ payload, httpQuery }) => builder({ url: `/project/${payload.pid}/kanban/${payload.kid}/kanbanColumn/${payload.kcid}`, method: 'PATCH', payload, httpQuery, target }),
      delete: ({ payload, httpQuery }) => builder({ url: `/project/${payload.pid}/kanban/${payload.kid}/kanbanColumn/${payload.kcid}`, method: 'DELETE', payload, httpQuery, target }),
      kanbanItem: {
        post: ({ payload, httpQuery }) => builder({ url: `/project/${payload.pid}/kanban/${payload.kid}/kanbanColumn/${payload.kcid}/kanbanItem`, method: 'POST', payload, httpQuery, target }),
        put: ({ payload, httpQuery }) => builder({ url: `/project/${payload.pid}/kanban/${payload.kid}/kanbanColumn/${payload.kcid}/kanbanItem/${payload.kiid}`, method: 'PUT', payload, httpQuery, target }),
        patch: ({ payload, httpQuery }) => builder({ url: `/project/${payload.pid}/kanban/${payload.kid}/kanbanColumn/${payload.kcid}/kanbanItem/${payload.kiid}`, method: 'PATCH', payload, httpQuery, target }),
        delete: ({ payload, httpQuery }) => builder({ url: `/project/${payload.pid}/kanban/${payload.kid}/kanbanColumn/${payload.kcid}/kanbanItem/${payload.kiid}`, method: 'DELETE', payload, httpQuery, target }),
      },
    },
  },
  tag: {
    post: ({ payload, httpQuery }) => builder({ url: `/project/${payload.pid}/tag/${payload.tid}`, method: 'POST', payload, httpQuery, target }),
    delete: ({ payload, httpQuery }) => builder({ url: `/project/${payload.pid}/tag/${payload.tid}`, method: 'DELETE', payload, httpQuery, target }),
  },
};

const tag = {
  post: ({ payload, httpQuery }) => builder({ url: '/tag/', method: 'POST', payload, httpQuery, target }),
  patch: ({ payload, httpQuery }) => builder({ url: `/tag/${payload.tid}`, method: 'PATCH', payload, httpQuery, target }),
  delete: ({ payload, httpQuery }) => builder({ url: `/tag/${payload.tid}`, method: 'DELETE', payload, httpQuery, target }),
};

const template = {
  post: ({ payload, httpQuery }) => builder({ url: '/template/', method: 'POST', payload, httpQuery, target }),
  patch: ({ payload, httpQuery }) => builder({ url: `/template/${payload.tid}`, method: 'PATCH', payload, httpQuery, target }),
  delete: ({ payload, httpQuery }) => builder({ url: `/template/${payload.tid}`, method: 'DELETE', payload, httpQuery, target }),
};

const appointmentToken = {
  get: ({ payload, httpQuery }) => builder({ url: '/appointmentToken', method: 'GET', payload, httpQuery, target }),
  generate: {
    post: ({ payload, httpQuery }) => builder({ url: '/appointmentToken/generate', method: 'POST', payload, httpQuery, target }),
  },
  affiliate: {
    post: ({ payload, httpQuery }) => builder({ url: `/appointmentToken/${payload.atid}/affiliate`, method: 'POST', payload, httpQuery, target }),
  },
};

const ping = ({ payload, httpQuery }) => builder({ url: '/ping', method: 'GET', payload, httpQuery, target });

export {
  ping,
  appointmentToken,
  categories,
  client,
  project,
  template,
  tag,
};
