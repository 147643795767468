<template>
  <form @submit.prevent="updatePassword()">
    <b-field
      label="New password"
      class="bg-solitude flex-grow-1"
      :horizontal="horizontal"
    >
      <b-input
        v-model="passPhrase"
        type="password"
        password-reveal
        icon-pack="fa"
        icon-right="eye"
        :expanded="expanded"
        required
      />
    </b-field>

    <b-field
      label="Confirm new password"
      class="bg-solitude flex-grow-1"
      :horizontal="horizontal"
    >
      <b-input
        v-model="confirm"
        type="password"
        password-reveal
        icon-pack="fa"
        icon-right="eye"
        :expanded="expanded"
        required
      />
    </b-field>

    <br>

    <div class="multibar">
      <div class="buttons end">
        <b-button
          type="is-primary"
          icon-pack="fa"
          icon-left="check"
          :disabled="!isTheSamePassword"
          native-type="submit"
          :expanded="expanded"
        />
      </div>
    </div>
  </form>
</template>

<script>
const moment = require('moment');

export default {
  name: 'ChangePassword',
  props: {
    horizontal: { type: Boolean, default: true },
    expanded: { type: Boolean, default: false },
  },
  data() {
    return {
      passPhrase: null,
      confirm: null,
    };
  },
  computed: {
    isTheSamePassword() {
      return this.passPhrase === this.confirm;
    },
  },
  methods: {
    updatePassword() {
      if (!this.passPhrase) return;
      this.$emit('updatePassword', {
        passPhrase: this.passPhrase,
        method: 'DEFAULT',
        createdOn: moment().format(),
        updatedOn: moment().format(),
        allowedOn: moment().format(),
        revokedOn: null,
        firstConnection: false,
      });
    },
  },
};
</script>
