<template>
  <section>
    <div class="multibar">
      <h3 class="start title is-small">
        Informations payment
      </h3>
    </div>

    <br>

    <fieldset>
      <b-field
        horizontal
        label="Description"
      >
        <b-input
          v-model="newPayment.details.description"
          type="textarea"
        />
      </b-field>

      <br>

      <b-field
        horizontal
        label="Price"
      >
        <b-numberinput
          v-model="newPayment.details.deposit"
          required
          min="0"
          icon-pack="fas"
        />
      </b-field>
    </fieldset>
  </section>
</template>

<script>
export default {
  name: 'JackSidePanelAddPaymentForm',
  model: {
    prop: 'newPayment',
  },
  props: {
    newPayment: { type: Object, required: true },
  },
  computed: {
    localNewPayment: {
      get() { return this.newPayment; },
      set(newValue) { this.$emit('update:newPayment', newValue); },
    },
  },
};
</script>
