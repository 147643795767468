var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loading)?_c('TableSkeleton'):_c('b-table',{attrs:{"data":_vm.data,"total":_vm.total,"backend-pagination":_vm.backendPagination,"paginated":_vm.paginated,"per-page":_vm.perPage,"pagination-size":_vm.paginationSize,"current-page":_vm.page,"backend-sorting":"","default-sort-direction":_vm.defaultSortOrder,"default-sort":[_vm.sortField, _vm.sortOrder],"opened-detailed":_vm.defaultOpenedDetails,"detailed":_vm.isActive,"detail-key":_vm.detailKey,"hoverable":"","checkable":_vm.checkable,"checked-rows":_vm.localChecked,"sticky-header":_vm.stickyHeader,"sticky-checkbox":"","mobile-cards":false},on:{"update:checkedRows":function($event){_vm.localChecked=$event},"update:checked-rows":function($event){_vm.localChecked=$event},"page-change":function($event){return _vm.$emit('onPageChange', $event)},"sort":_vm.onSort},scopedSlots:_vm._u([{key:"detail",fn:function(props){return [_c('DevDetails',{attrs:{"cnt":{name: 'props.row', obj: props.row}}}),_c('DevDetails',{attrs:{"cnt":{name: 'columns', obj: _vm.columns}}})]}}])},_vm._l((_vm.columns),function(column){return _c('b-table-column',{key:column.col,attrs:{"visible":column.visible,"label":column.label,"field":column.col,"sortable":column.isSortable,"sticky":column.sticky,"width":column.width,"centered":column.centered,"cell-class":column.cellClass},scopedSlots:_vm._u([(column.base === 'INSTAGRAM'
        || column.base === 'TIKTOK'
        || column.base === 'FACEBOOK'
        || column.base === 'SNAPCHAT'
        || column.base === 'TWITTER'
        || column.base === 'LINKEDIN')?{key:"header",fn:function(){return [_c('div',{staticClass:"jackHeaderTable"},[_c('b-icon',{attrs:{"pack":"fab","icon":column.base.toLowerCase()}}),_c('span',{staticClass:"start"},[_vm._v(" "+_vm._s(column.label)+" ")])],1)]},proxy:true}:null,{key:"default",fn:function(props){return [(column.cmp === 'flat')?[_vm._v(" "+_vm._s(props.row[column.col] || '-')+" ")]:_vm._e(),(column.cmp === 'bool')?[_c('b-icon',{class:[{
            'has-text-success': props.row[column.col],
            'has-text-danger': !props.row[column.col],
          }],attrs:{"pack":"fas","icon":"circle"}})]:_vm._e(),(column.cmp === 'isOpen')?[_c('b-icon',{class:[{
            'has-text-success': !props.row[column.col],
            'has-text-danger': props.row[column.col],
          }],attrs:{"pack":"fas","icon":"circle"}})]:_vm._e(),(column.cmp === 'symbol')?[(props.row[column.col])?[_c('span',[_vm._v(_vm._s(props.row[column.col])+" "+_vm._s(column.symbol))])]:[_c('span',[_vm._v("-")])]]:_vm._e(),(column.cmp === 'date')?[(props.row[column.col])?_c('span',[_vm._v(" "+_vm._s(_vm.$moment(props.row[column.col]).format('MMMM Do, YYYY'))+" ")]):_c('span',[_vm._v("-")])]:_vm._e(),(column.cmp === 'nested')?[(column.base && props.row[column.base])?_c('span',[_vm._v(" "+_vm._s(props.row[column.base][column.nestedKey1] || '-')+" ")]):_c('span',[_vm._v(" - ")])]:_vm._e(),(column.event)?[(column.buttonType === 'tertiary')?_c('b-button',{attrs:{"type":column.type,"icon-pack":"fas","icon-right":column.icon},on:{"click":function($event){return _vm.$emit(column.event, props.row)}}}):_c('b-button',{attrs:{"type":column.type,"label":column.label},on:{"click":function($event){return _vm.$emit(column.event, props.row)}}})]:_vm._e()]}}],null,true)})}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }