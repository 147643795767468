<template>
  <div>
    TheClientSidePannelEdit
  </div>
</template>

<script>
export default {
  name: 'TheClientsSidePannelEdit',
};
</script>
