<template>
  <!-- eslint-disable max-len -->
  <div class="dashboard">
    <div
      class="content"
    >
      <router-view
        name="userView"
      />
      <router-view
        v-if="adminOnAccount"
        name="accountView"
      />
    </div>
  </div>
</template>

<script>
/* eslint-disable max-len */
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TheDashboard',
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      windowWidth: 'TheMenu/getterWindowWidth',
      myAuthenticate: 'TheDashboard/myAuthenticate',
      adminOnAccount: 'TheDashboard/adminOnAccount',
      currentUid: 'TheDashboard/currentUid',
      userDashboardView: 'TheDashboard/userDashboardView',
      accountDashboard: 'TheDashboard/accountDashboard',
    }),
  },
  methods: {
    ...mapActions({
      getUserDashboardView: 'TheDashboard/getUserDashboardView',
      accountDashboardView: 'TheDashboard/accountDashboardView',
    }),
  },
};
</script>
