<template>
  <canvas
    v-if="data"
    :id="idChart"
    :width="width"
    :height="height"
  />
</template>

<script>
/* eslint-disable max-len */
/* eslint-disable-next-line */
import nFormatter from '@jack/addons/$mixins/nFormatter';
import { mapGetters } from 'vuex';
import ChartJs from 'chart.js/auto';

export default {
  name: 'ChartJs',
  props: {
    idChart: { type: String, required: true },
    width: { type: String, default: '300' },
    height: { type: String, default: '500' },
    type: { type: String, default: 'line' },

    // data
    data: { type: Object, required: true },
    initChart: { type: Object, required: true },

    // options
    optionsResponsive: { type: Boolean, default: true },
    optionsMaintainAspectRatio: { type: Boolean, default: false },
    optionsLocale: { type: String, default: 'en-US' },
    optionsEvents: { type: Array, default: () => [] },
    optionsIndexAxis: { type: String, default: 'x' },
    // // optionsOnResize: { type: Function, default: () => ({}) },
    // // optionsResizeDelay: { type: Number, default: 2 },

    // options.interaction
    optionsInteractionIntersect: { type: Boolean, default: true },
    optionsInteractionAxis: { type: String, default: 'x' },
    optionsInteractionMode: { type: String, default: 'nearest' },
    onClickOptionsInteractionsNameEvent: { type: String, default: 'interactionsOnClick' },
    onHoverOptionsInteractionsNameEvent: { type: String, default: 'interactionsOnHover' },

    // options.layout
    optionsLayoutAutoPadding: { type: Boolean, default: true },
    optionsLayoutPadding: { type: Number, default: 12 },

    // options.plugins.legend
    optionsPluginsLegendAlign: { type: String, default: 'start' },
    optionsPluginsLegendColor: { type: String, default: '132, 132, 132' },
    optionsPluginsLegendDisplay: { type: Boolean, default: true },
    optionsPluginsLegendPosition: { type: String, default: 'bottom' },
    optionsPluginsLegendMaxHeight: { type: Number, default: 0 },
    optionsPluginsLegendMaxWidth: { type: Number, default: 0 },
    optionsPluginsLegendFullSize: { type: Boolean, default: true },
    optionsPluginsLegendReverse: { type: Boolean, default: false },
    optionsPluginsLegendRtl: { type: Boolean, default: false },
    onClickOptionsPluginsLegendNameEvent: { type: String, default: 'pluginsLengendOnClick' },
    onHoverOptionsPluginsLegendNameEvent: { type: String, default: 'pluginsLengendOnHover' },
    onLeaveOptionsPluginsLegendNameEvent: { type: String, default: 'pluginsLengendOnLeave' },

    // options.plugins.legend.labels
    optionsPluginsLegendLabelsBoxWidth: { type: Number, default: 10 },
    optionsPluginsLegendLabelsBoxHeight: { type: Number, default: 10 },
    optionsPluginsLegendLabelsColor: { type: String, default: '132, 132, 132' },
    optionsPluginsLegendLabelsPadding: { type: Number, default: 16 },
    optionsPluginsLegendLabelsPointStyle: { type: String, default: 'circle' },
    optionsPluginsLegendLabelsUsePointStyle: { type: Boolean, default: true },
    // // optionsPluginsLegendLabelsTextAlign: { type: String, default: 'center' },

    // options.plugins.legend.labels.font
    optionsPluginsLegendLabelsFontSize: { type: Number, default: 12 },
    optionsPluginsLegendLabelsFontStyle: { type: String, default: 'normal' },
    optionsPluginsLegendLabelsFontWeight: { type: String, default: '16' },
    optionsPluginsLegendLabelsFontLineHeight: { type: [String, Number], default: 1.2 },

    // options.plugins.legend.title
    optionsPluginsLegendTitleColor: { type: String, default: '132, 132, 132' },
    optionsPluginsLegendTitleDisplay: { type: Boolean, default: false },
    optionsPluginsLegendTitleFont: { type: String, default: '' },
    optionsPluginsLegendTitlePadding: { type: Number, default: 16 },
    optionsPluginsLegendTitleText: { type: String, default: '' },

    // options.plugins.title
    optionsPluginsTitleAlign: { type: String, default: 'center' },
    optionsPluginsTitleColor: { type: String, default: '132? ' },
    optionsPluginsTitleDisplay: { type: Boolean, default: false },
    optionsPluginsTitleFullSize: { type: Boolean, default: true },
    optionsPluginsTitlePosition: { type: String, default: 'top' },
    optionsPluginsTitlePadding: { type: Number, default: 16 },
    optionsPluginsTitleText: { type: [String, Array], default: '' },

    // options.plugins.subtitle
    optionsPluginsSubtitleDisplay: { type: Boolean, default: true },
    optionsPluginsSubtitleText: { type: String, default: '' },

    // options.plugins.tooltip
    // // pluginsTooltipExternal: { type: Function, default: () => {} },
    // // pluginsTooltipMode: { type: String, default: this.interactionMode },
    // // pluginsTooltipIntersect: { type: String, default: this.interactionIntersect },
    // // pluginsTooltipItemSort: { type: Function, default: () => {} },
    // // pluginsTooltipFilter: { type: Function, default: () => {} },
    optionsPluginsTooltipEnable: { type: Boolean, default: true },
    optionsPluginsTooltipPosition: { type: String, default: 'average' },
    optionsPluginsTooltipBackgroundColor: { type: String, default: 'rgba(0, 0, 0, 0.6)' },
    optionsPluginsTooltipColor: { type: String, default: '#fff' },
    optionsPluginsTooltipTitleFont: { type: Object, default: () => ({ size: 12, weight: 'bold' }) },
    optionsPluginsTooltipTitleAlign: { type: String, default: 'left' },
    optionsPluginsTooltipSpacing: { type: Number, default: 16 },
    optionsPluginsTooltipMarginBottom: { type: Number, default: 6 },
    optionsPluginsTooltipBodyColor: { type: String, default: '#fff' },
    optionsPluginsTooltipBodyAlign: { type: String, default: 'left' },
    optionsPluginsTooltipBodySpacing: { type: Number, default: 16 },
    optionsPluginsTooltipBodyFont: { type: Object, default: () => ({ size: 12, weight: 'normal' }) },
    optionsPluginsTooltipFooterColor: { type: String, default: '#fff' },
    optionsPluginsTooltipFooterFont: { type: Object, default: () => ({ size: 16, weight: 'bold' }) },
    optionsPluginsTooltipFooterAlign: { type: String, default: 'left' },
    optionsPluginsTooltipFooterSpacing: { type: Number, default: 2 },
    optionsPluginsTooltipFooterMarginTop: { type: Number, default: 6 },
    optionsPluginsTooltipPadding: { type: Number, default: 16 },
    optionsPluginsTooltipCaretPadding: { type: Number, default: 2 },
    optionsPluginsTooltipCaretSize: { type: Number, default: 8 },
    optionsPluginsTooltipCornerRadius: { type: [Number, Object], default: 6 },
    optionsPluginsTooltipMultiKeyBackground: { type: String, default: '#fff' },
    optionsPluginsTooltipDisplayColor: { type: Boolean, default: true },
    optionsPluginsTooltipBoxPadding: { type: Number, default: 8 },
    optionsPluginsTooltipUsePointStyle: { type: Boolean, default: false },
    optionsPluginsTooltipBorderColor: { type: String, default: 'rgba(0, 0, 0, 0)' },
    optionsPluginsTooltipBorderWidth: { type: Number, default: 0 },
    optionsPluginsTooltipRtl: { type: Boolean, default: false },
    optionsPluginsTooltipTextDirection: { type: String, default: 'ltr' },
    optionsPluginsTooltipXAlign: { type: String, default: '' },
    optionsPluginsTooltipYAlign: { type: String, default: '' },

    // options.plugins.scales.y
    optionsPluginsScalesYBeginAtZero: { type: Boolean, default: false },

    // options.plugins.scales.y.ticks
    optionsPluginsScalesYBeginTicksStepSize: { type: Number, default: 1 },
    optionsPluginsScalesYBeginTicksCallback: { type: Object, required: true },

    // options.plugins.scales.yAxis.
    optionsPluginsScalesYAxisSuggestedMin: { type: Number, default: 1 },
    optionsPluginsScalesYAxisSuggestedMax: { type: Number, default: 10 },
    optionsPluginsScalesYAxis: { type: Boolean, default: false },

    // options.plugins.scales.yAxis.grid
    optionsPluginsScalesYAxisGridDisplay: { type: Boolean, default: false },

    // options.plugins.scales.yAxis.ticks
    optionsPluginsScalesYAxisTicksStepSize: { type: Number, default: 1 },
    optionsPluginsScalesYAxisTicksDisplay: { type: Boolean, default: false },

    // options.plugins.scales.xAxis.
    optionsPluginsScalesXAxisTicksStepSize: { type: Number, default: 1 },
    optionsPluginsScalesXAxisSuggestedMin: { type: Number, default: 1 },
    optionsPluginsScalesXAxisSuggestedMax: { type: Number, default: 10 },
    // options.plugins.scales.xAxis.grid
    optionsPluginsScalesXAxisGridDisplay: { type: Boolean, default: false },
  },
  data() {
    return {
      initialize: false,
    };
  },
  computed: {
    ...mapGetters({
      theme: 'Starter/getterTheme',
    }),
    initConfigChart() {
      return {
        type: this.type,
        data: this.data,
        options: {
          responsive: this.optionsResponsive,
          maintainAspectRatio: this.optionsMaintainAspectRatio,
          locale: this.optionsLocale,
          // events: this.optionsEvents,
          indexAxis: this.optionsIndexAxis,
          interaction: {
            intersect: this.optionsInteractionIntersect,
            axis: this.optionsInteractionAxis,
            mode: this.optionsInteractionMode,
          },
          layout: {
            autoPadding: this.optionsLayoutAutoPadding,
            padding: this.optionsLayoutPadding,
          },
          plugins: {
            legend: {
              align: this.optionsPluginsLegendAlign,
              // color: `rgb(${this.optionsPluginsLegendColor})`,
              display: this.optionsPluginsLegendDisplay,
              position: this.optionsPluginsLegendPosition,
              maxHeight: this.optionsPluginsLegendMaxHeight,
              maxWidth: this.optionsPluginsLegendMaxWidth,
              fullSize: this.optionsPluginsLegendFullSize,
              reverse: this.optionsPluginsLegendReverse,
              rtl: this.optionsPluginsLegendRtl,
              labels: {
                // boxWidth: this.optionsPluginsLegendLabelsBoxWidth,
                // boxHeight: this.optionsPluginsLegendLabelsBoxHeight,
                // color: `rgb(${this.optionsPluginsLegendLabelsColor})`,
                // padding: this.optionsPluginsLegendLabelsPadding,
                // pointStyle: this.optionsPluginsLegendLabelsPointStyle,
                // usePointStyle: this.optionsPluginsLegendLabelsUsePointStyle,
                generateLabels: (chart) => chart.data.datasets.map((el) => {
                  let count = 0;
                  el.data.filter((item) => item).forEach((item) => { count += item; });
                  return {
                    text: `(${count}) ${el.label}`,
                    fillStyle: el.backgroundColor,
                    strokeStyle: el.borderColor,
                  // fontColor: el.borderColor,
                  };
                }),
                font: {
                  size: this.optionsPluginsLegendLabelsFontSize,
                  style: this.optionsPluginsLegendLabelsFontStyle,
                  weight: this.optionsPluginsLegendLabelsFontWeight,
                  lineHeight: this.optionsPluginsLegendLabelsFontLineHeight,
                },
              },
              title: {
                color: `rgb(${this.optionsPluginsLegendTitleColor})`,
                display: this.optionsPluginsLegendTitleDisplay,
                padding: this.optionsPluginsLegendTitlePadding,
                text: this.optionsPluginsLegendTitleText,
              },
              onClick: (e) => { this.$emit(this.onClickOptionsPluginsLegendNameEvent, e); },
              onHover: (e) => { this.$emit(this.onHoverOptionsPluginsLegendNameEvent, e); },
              onLeave: (e) => { this.$emit(this.onLeaveOptionsPluginsLegendNameEvent, e); },
            },
            title: {
              align: this.optionsPluginsTitleAlign,
              color: this.optionsPluginsTitleColor,
              display: this.optionsPluginsTitleDisplay,
              fullSize: this.optionsPluginsTitleFullSize,
              position: this.optionsPluginsTitlePosition,
              padding: this.optionsPluginsTitlePadding,
              text: this.optionsPluginsTitleText,
            },
            subtitle: {
              display: this.optionsPluginsSubtitleDisplay,
              text: this.optionsPluginsSubtitleText,
            },
            tooltip: {
              // // external: this.pluginsTooltipExternal,
              // // itemSort: this.pluginsTooltipItemSort,
              // // filter: this.pluginsTooltipFilter,
              enable: this.optionsPluginsTooltipEnable,
              position: this.optionsPluginsTooltipPosition,
              backgroundColor: this.optionsPluginsTooltipBackgroundColor,
              color: this.optionsPluginsTooltipColor,
              titleFont: this.optionsPluginsTooltipTitleFont,
              titleAlign: this.optionsPluginsTooltipTitleAlign,
              spacing: this.optionsPluginsTooltipSpacing,
              marginBottom: this.optionsPluginsTooltipMarginBottom,
              bodyColor: this.optionsPluginsTooltipBodyColor,
              bodyAlign: this.optionsPluginsTooltipBodyAlign,
              bodySpacing: this.optionsPluginsTooltipBodySpacing,
              bodyFont: this.optionsPluginsTooltipBodyFont,
              footerColor: this.optionsPluginsTooltipFooterColor,
              footerFont: this.optionsPluginsTooltipFooterFont,
              footerAlign: this.optionsPluginsTooltipFooterAlign,
              footerSpacing: this.optionsPluginsTooltipFooterSpacing,
              footerMarginTop: this.optionsPluginsTooltipFooterMarginTop,
              padding: this.optionsPluginsTooltipPadding,
              caretPadding: this.optionsPluginsTooltipCaretPadding,
              caretSize: this.optionsPluginsTooltipCaretSize,
              cornerRadius: this.optionsPluginsTooltipCornerRadius,
              multiKeyBackground: this.optionsPluginsTooltipMultiKeyBackground,
              displayColor: this.optionsPluginsTooltipDisplayColor,
              boxPadding: this.optionsPluginsTooltipBoxPadding,
              usePointStyle: this.optionsPluginsTooltipUsePointStyle,
              borderColor: this.optionsPluginsTooltipBorderColor,
              borderWidth: this.optionsPluginsTooltipBorderWidth,
              rtl: this.optionsPluginsTooltipRtl,
              textDirection: this.optionsPluginsTooltipTextDirection,
              xAlign: this.optionsPluginsTooltipXAlign,
              yAlign: this.optionsPluginsTooltipYAlign,
            },
          },
          scales: {
            y: {
              grid: {
                display: this.optionsPluginsScalesYAxisGridDisplay,
                borderWidth: 0,
              },
              beginAtZero: this.optionsPluginsScalesYBeginAtZero,
              ticks: {
                stepSize: this.optionsPluginsScalesYBeginTicksStepSize,
                callback: (value) => {
                  const {
                    hasSymbol,
                    hasNFormatter,
                    symbolLeft,
                    symbolRight,
                    nFormatterValue,
                  } = this.optionsPluginsScalesYBeginTicksCallback;

                  let valueFormatted = value;

                  if (hasNFormatter) valueFormatted = nFormatter(value, nFormatterValue);
                  if (hasSymbol) valueFormatted = `${symbolLeft}${valueFormatted}${symbolRight}`;

                  return valueFormatted;
                },
                // display: this.optionsPluginsScalesYAxisTicksDisplay,
              },
            },
            yAxis: {
              grid: {
                display: this.optionsPluginsScalesYAxisGridDisplay,
                borderWidth: 0,
              },
              ticks: {
                stepSize: this.optionsPluginsScalesYAxisTicksStepSize,
                display: this.optionsPluginsScalesYAxisTicksDisplay,
              },
              suggestedMin: this.optionsPluginsScalesYAxisSuggestedMin,
              suggestedMax: this.optionsPluginsScalesYAxisSuggestedMax,
            },
            xAxis: {
              grid: {
                display: this.optionsPluginsScalesXAxisGridDisplay,
                borderWidth: 0,
              },
              ticks: {
                // reverse: false,
                stepSize: this.optionsPluginsScalesXAxisTicksStepSize,
              },
              suggestedMin: this.optionsPluginsScalesXAxisSuggestedMin,
              suggestedMax: this.optionsPluginsScalesXAxisSuggestedMax,
            },
          },
          elements: {
            ...this.initChart,
          },
          onClick: (e) => { this.$emit(this.onClickOptionsInteractionsNameEvent, e); },
          onHover: (e) => { this.$emit(this.onHoverOptionsInteractionsNameEvent, e); },
        },
        plugins: [],
      };
    },
  },
  watch: {
    data() {
      this.chart.destroy();
      this.initChartMethods();
    },
    theme() {
      this.chart.destroy();
      this.initChartMethods();
    },
  },
  mounted() {
    this.initChartMethods();
  },
  methods: {
    initChartMethods() {
      const ctx = document.getElementById(this.idChart).getContext('2d');
      const newDatasets = this.data.datasets.reduce((acc, el) => {
        if (el.withoutBg) {
          acc.push({ ...el }); return acc;
        }

        const { color } = el;
        const gradient = ctx.createLinearGradient(0, 0, 0, 400);
        gradient.addColorStop(0, `rgba(${color}, 1)`);
        gradient.addColorStop(1, this.theme ? 'rgba(11, 23, 39, .5)' : 'rgba(237, 242, 249, 0)');

        acc.push({ ...el, backgroundColor: gradient });
        return acc;
      }, []);

      this.initConfigChart.data.datasets = newDatasets;

      const config = {
        type: this.initConfigChart.type,
        data: this.initConfigChart.data,
        options: this.initConfigChart.options,
      };

      this.chart = new ChartJs(ctx, config);
    },
  },
};
</script>
