/* eslint-disable import/no-extraneous-dependencies */
import { navySeal } from '@jack/provider';

export default {
  namespaced: true,
  state: {
    features: {},
  },
  mutations: {
    SET_FEATURES(state, newData) { state.features = newData; },
  },
  getters: {
    getterFeatures: (state) => state.features,
  },
  actions: {},
  modules: {},
};
