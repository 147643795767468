var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',{staticClass:"dashboard"},[_c('DevDetails',{attrs:{"cnt":{name: 'formatProjectList', obj: _vm.formatProjectList}}}),_c('div',{staticClass:"content"},[(_vm.formatProjectList.length)?[_c('div',{class:['cardDashboard', {
            'col-1': _vm.gridResponsive === 1,
            'col-2': _vm.gridResponsive === 2,
            'col-3': _vm.gridResponsive === 3,
            'col-4': _vm.gridResponsive === 4,
            'col-5': _vm.gridResponsive === 5,
            'col-6': _vm.gridResponsive === 6,
          }]},_vm._l((_vm.formatProjectList),function(project){return _c('article',{key:project.pid,staticClass:"card walletCard no-border max-h-inherit"},[_c('div',{staticClass:"card-sticker"},[_c('b-icon',{class:['ml-2', {
                  'has-text-success': !project.isOpen,
                  'has-text-danger': project.isOpen,
                }],attrs:{"pack":"fa","icon":"circle"}})],1),_c('div',{staticClass:"card-header border-0"},[_c('div',{staticClass:"card-title"},[_c('p',[_vm._v(_vm._s(project.label))])]),_c('div',{staticClass:"card-action"},[_c('div',{staticClass:"buttons"},[_c('b-button',{attrs:{"type":"is-ghost","icon-pack":"fa","icon-left":"eye","tag":"router-link","to":`/projects/overview-view/project/${project.pid}`}})],1)])]),_c('div',{staticClass:"card-content"},[_c('p',{staticClass:"has-text-secondary subtext is-big end"},[_vm._v(" "+_vm._s(_vm.$moment(project.createdAt).format('YYYY-MM-DD'))+" ")]),_c('p',{staticClass:"text"},[_vm._v(_vm._s(project.description))]),_c('br'),_c('div',{staticClass:"multibar"},[_c('p',{staticClass:"start title is-small"},[_vm._v(" "+_vm._s(project.price)+" € ")])])])])}),0)]:_vm._e()],2)],1),_c('router-view',{attrs:{"name":"overviewProjectSidePannel"}}),_c('router-view',{attrs:{"name":"overviewProjectSidePannelEdit"}}),_c('router-view',{attrs:{"name":"overviewProjectSidePannelClient"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }