<template>
  <section>
    <DevDetails :cnt="{name: 'eventsView', obj: eventsView}" />
    <template v-if="Object.values(eventsView).length">
      <header class="is-flex is-flex-direction-column is-align-items-flex-start">
        <p class="title is-small m-0">{{ eventsView.spends.label }}</p>
        <h2 class="bigtitle is-big">-{{ eventsView.spends.deposit }} €</h2>
        <p class="text is-small has-text-secondary">{{ formatDate }}</p>
      </header>

      <br>

      <div class="multibar mt-5">
        <p class="start title is-small">Details</p>
      </div>

      <br>

      <div class="card">
        <div class="card-content">
          <div class="multibar">
            <p class="start text has-text-secondary">
              {{ eventsView.spends.category }}
            </p>
          </div>
          <div class="multibar">
            <div class="start is-flex is-align-items-center">
              <b-icon
                :pack="eventsView.spends.pack"
                :icon="eventsView.spends.icon"
              />
              <p class="text ml-3">{{ eventsView.spends.categoryLabel }}</p>
            </div>
            <div class="end">
              <p class="text has-text-secondary">
                {{ eventsView.spends.type }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </template>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TheSidePanelEventSpend',
  computed: {
    ...mapGetters({
      eventsView: 'TheSidePanel/eventsView',
      currentUid: 'TheSidePanel/currentUid',
    }),
    formatDate() {
      const date = this.$moment(this.eventsView.createdAt).format('MMMM Do, YYYY | HH:mm');
      return `${date}`;
    },
  },
  mounted() {
    const { eid } = this.$route.params;
    const payload = { uid: this.currentUid, eid };

    return Promise.all([
      this.getEventsView({ payload }),
    ]);
  },
  methods: {
    ...mapActions({
      getEventsView: 'TheSidePanel/getEventsView',
    }),
  },
};
</script>
