<template>
  <section>
    <p class="title is-big mb-3">Create appointment</p>

    <form>
      <b-field label="Link a company or a contact">
        <b-select
          v-model="selected"
          placeholder="Select a name"
          @change.native="inputSearch = ''"
        >
          <option
            v-for="option in chooseCompanyOrPerson"
            :key="option.key"
            :value="option.key"
          >
            {{ option.name }}
          </option>
        </b-select>
      </b-field>

      <template v-if="selected">
        <b-field label="Link a contact to the appointment">
          <b-autocomplete
            v-model="inputSearch"
            :data="entiteFiltered"
            field="fullName"
            placeholder="Search a by name, email, number..."
            icon="magnifying-glass"
            icon-pack="fa"
            @select="option => selectedEntite = option"
          >
            <template slot-scope="props">
              <Contact
                :details="props.option"
                :is-clickable="false"
              />
            </template>
          </b-autocomplete>
        </b-field>
        <article
          v-if="selectedEntite"
          class="person is-justify-content-center py-5"
        >
          <div class="content is-flex is-align-items-center is-justify-content-space-between">
            <Contact
              :details="selectedEntite"
              :is-clickable="false"
            />
            <div class="buttons">
              <b-button
                type="is-tertiary"
                icon-left="xmark"
                icon-pack="fa"
                @click="selectedEntite = null"
              />
            </div>
          </div>
        </article>
      </template>

      <b-field label="Starts at">
        <b-datetimepicker
          v-model="appointment.date"
          placeholder="Click to select..."
          icon="calendar"
          :icon-right="appointment.date ? 'close-circle' : ''"
          icon-pack="fa"
          :datetime-formatter="time => $moment(time).format('YYYY-MM-DD HH:mm:ss')"
          :min-datetime="new Date()"
          :timepicker="timepicker"
          @icon-right-click="appointment.date = null"
        />
      </b-field>

      <b-field
        v-if="appointment.date"
        label="Ends at"
      >
        <b-datetimepicker
          v-model="end"
          placeholder="Click to select..."
          icon="calendar"
          :icon-right="end ? 'close-circle' : ''"
          icon-pack="fa"
          position="is-top-right"
          :min-datetime="appointment.date"
          :timepicker="timepicker"
          @icon-right-click="end = null"
        />
      </b-field>

      <b-field label="Label">
        <b-input
          v-model="appointment.label"
          required
        />
      </b-field>

      <b-field label="Description">
        <b-input
          v-model="appointment.description"
          type="textarea"
        />
      </b-field>

      <br>

      <div class="buttons">
        <b-button
          type="is-primary"
          icon-pack="fa"
          icon-left="check"
          expanded
          :disabled="disabledButton"
          @click="addAppointment()"
        />
      </div>
    </form>
  </section>
</template>

<script>
/* eslint-disable-next-line */
import { debounce } from '@jack/addons/$utils/helpers';

import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'MenuAddAppointment',
  data() {
    return {
      selected: '',
      selectedEntite: null,
      search: '',
      inputSearch: '',
      appointment: {
        date: new Date(),
        label: null,
        description: null,
      },
      end: null,
    };
  },
  computed: {
    ...mapGetters({
      searchCompany: 'TheNetworking/searchCompany',
      searchPerson: 'TheNetworking/searchPerson',
    }),
    timepicker() {
      const min = new Date();
      min.setHours(6);
      const max = new Date();
      max.setHours(20);

      return {
        incrementMinutes: 10,
        minTime: min,
        maxTime: max,
      };
    },
    duration() {
      if (!this.appointment.date || !this.end) return 0;
      const end = this.$moment(this.end);
      const start = this.$moment(this.appointment.date);
      return end.diff(start, 'minutes');
    },
    chooseCompanyOrPerson() {
      return [
        { name: '', key: '' },
        { name: 'Companies', key: 'companies' },
        { name: 'Contact', key: 'contact' },
      ];
    },
    entiteFiltered() {
      const { selected } = this;

      const args = {
        companies: {
          getter: 'searchCompany',
          keys: [
            { l: 'cid', k: 'cid' },
            { l: 'imageUrl', k: 'logo' },
            { l: 'fullName', k: 'name' },
            { l: 'jobTitle', k: 'tagLine' },
          ],
        },
        contact: {
          getter: 'searchPerson',
          keys: [
            { l: 'pid', k: 'pid' },
            { l: 'imageUrl', k: 'imageUrl' },
            { l: 'fullName', k: 'fullName' },
            { l: 'jobTitle', k: 'jobTitle' },
          ],
        },
      };

      return Object.values(this[args[selected].getter])
        .reduce((acc, entite) => {
          const { keys } = args[selected];
          acc.push(keys.reduce((acc2, { l, k }) => {
            /* eslint-disable no-param-reassign */
            if (!acc2[l]) acc2[l] = entite[k];
            return acc2;
          }, {}));
          return acc;
        }, []);
    },
    disabledButton() {
      if (!this.appointment.label) return true;
      if (!this.selectedEntite) return true;
      if (!this.selectedEntite.cid && !this.selectedEntite.pid) return true;
      if (!this.duration || !this.appointment.label) return true;
      return false;
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    inputSearch: debounce(function (newVal) {
      this.searchRequest = newVal;
      if (newVal.length >= 3) {
        this.findEntite(newVal);
      }
    }, 500),
  },
  methods: {
    ...mapActions({
      searchCompanyList: 'TheNetworking/searchCompanyList',
      searchPersonList: 'TheNetworking/searchPersonList',
      _addAppointment: 'TheNetworking/createAppointment',
    }),
    addAppointment() {
      this._addAppointment({
        payload: {
          createdAt: this.$moment().format(),
          state: 'CONFIRMED',
          details: {
            ...this.appointment,
            clientCid: null,
            personPid: this.selectedEntite.pid || null,
            projectPid: null,
            companyCid: this.selectedEntite.cid || null,
            duration: this.duration,
          },
        },
      })
        .then(({ response }) => {
          if (response) this.$emit('close');
        });
    },
    findEntite(search) {
      const { selected } = this;
      const httpQuery = { by: 'name', search, pagin: '100,0' };

      if (selected === 'companies') { this.searchCompanyList({ httpQuery }); }
      if (selected === 'contact') { this.searchPersonList({ httpQuery }); }
    },
  },
};
</script>
