<template>
  <div>
    ThePulse
  </div>
</template>

<script>
export default {
  name: 'ThePulse',
};
</script>
