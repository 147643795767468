<template>
  <section>
    <p class="subtitle is-big mb-1">{{ $_('Tags') }}</p>
    <p class="text is-small has-text-secondary">
      {{ $_('Attach keywords to refine your next search.') }}
    </p>
    <b-field label="Tags">
      <b-taginput
        ref="taginput"
        v-model="tags"
        :data="_tags"
        autocomplete
        field="label"
        icon="tag"
        icon-pack="fa"
        :placeholder="$_('Add a tag')"
        @typing="search = $event"
        @add="_createTag($event)"
        @remove="_deleteTag($event)"
      >
        <template slot-scope="props">
          {{ props.option.label }}
        </template>
        <template #empty>
          {{ $_('There are no items') }}
        </template>
        <template #selected="props">
          <b-tag
            v-for="({ tid, label, color }, index) in props.tags"
            :key="tid"
            :tabstop="false"
            ellipsis
            closable
            :class="`tag-color-${color}`"
            @close="$refs.taginput.removeTag(index, $event)"
          >
            {{ label }}
          </b-tag>
        </template>
      </b-taginput>
    </b-field>
  </section>
</template>

<script>
export default {
  name: 'TagAutocomplete',
  props: {
    entite: { type: Object, required: true },
    tagList: { type: Object, required: true },
    keyId: { type: String, required: true },
  },
  data() {
    return {
      search: null,
      tags: [],
    };
  },
  computed: {
    entiteTags() {
      if (!this.entite || !this.entite.tags) return [];
      return this.entite.tags;
    },
    _tags() {
      if (!Object.values(this.tagList).length) return [];
      return Object.values(this.tagList).reduce((acc, tag) => {
        if (!this.search) {
          acc.push(tag);
          return acc;
        }
        const { label } = tag;
        const _label = label.toLowerCase();
        if (_label.includes(this.search.toLowerCase())) acc.push(tag);
        return acc;
      }, []);
    },
  },
  mounted() {
    if (this.entiteTags.length) {
      this.entiteTags.forEach((tid) => {
        if (this.tagList[tid]) this.tags.push(this.tagList[tid]);
      });
    }
  },
  methods: {
    _createTag({ tid }) { this.$emit('createTag', { tid, [this.keyId]: this.entite[this.keyId] }); },
    _deleteTag({ tid }) { this.$emit('deleteTag', { tid, [this.keyId]: this.entite[this.keyId] }); },
  },
};
</script>
