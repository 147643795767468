<template>
  <div class="jackSidePanel">
    <JackSidePanelHeader
      :path-home="pathHome"
      :previous="goBack"
    />

    <br>

    <div class="multibar">
      <h2 class="title is-big start">Add new client</h2>
    </div>

    <br>

    <b-steps
      v-model="activeStep"
      size="is-small"
      :has-navigation="false"
    >
      <template v-for="(step, i) in steps">
        <b-step-item
          v-if="step.displayed"
          :key="step.label"
          :label="step.label"
          :type="step.type"
        >
          <br>

          <JackStepControls
            v-if="step.stepVisible"
            :active-step.sync="activeStep"
            :step-detail="`${i + 1}/${steps.length - 1} - Create client`"
            :next-is-allowed="step.stepControl"
            :prev="step.prev"
            :next="step.next"
          />

          <br>

          <component
            :is="step.cmp"
            v-model="newClient"
            @createNewClient="createNewClient()"
            @deleteSocial="deleteSocial($event)"
          />
        </b-step-item>
      </template>
    </b-steps>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TheSidePanelAddClient',
  props: {
    pathHome: { type: String, required: true },
    goBack: { type: Object, required: true },
  },
  data() {
    return {
      activeStep: 0,
      newClient: {
        details: {
          firstName: null,
          lastName: null,
          number: null,
        },
        socialList: [],
        codeCountry: '+33',
      },
    };
  },
  computed: {
    ...mapGetters({
      client: 'TheSidePanel/client',
      currentAccountAid: 'TheSidePanel/currentAccountAid',
      currentUid: 'TheSidePanel/currentUid',
    }),
    stepControl1() {
      const { firstName, lastName, number } = this.newClient.details;

      if (!firstName || !lastName || !number) return false;
      return true;
    },
    stepControl2() {
      const { socialList } = this.newClient;

      if (!socialList.length) return true;

      if (socialList.find(({ platformName, username }) => !platformName || !username)) return false;

      return true;
    },
    steps() {
      return [
        {
          label: 'Informations', cmp: 'JackSidePanelAddClientInformationForm', displayed: true, type: 'is-primary', stepVisible: true, stepControl: this.stepControl1, prev: false, next: true,
        },
        {
          label: 'Social', cmp: 'JackSidePanelAddClientSocialForm', displayed: true, type: 'is-primary', stepVisible: true, stepControl: this.stepControl2, prev: true, next: true,
        },
        {
          label: 'Confirmation', cmp: 'JackSidePanelAddClientConfirmation', displayed: true, type: 'is-primary', stepVisible: true, stepControl: false, prev: true, next: false,
        },
        {
          label: '', cmp: 'JackSidePanelAddClientSuccessCreate', displayed: true, type: 'is-success', stepVisible: false, stepControl: this.stepControl1, prev: true, next: false,
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      createClient: 'TheSidePanel/createClient',
    }),
    createNewClient() {
      const { newClient: { details, socialList } } = this;

      const payload = {
        aid: this.currentAccountAid,
        uid: this.currentUid,
        createdAt: this.$moment(this.$currentQuery('start')).add(12, 'hours').format(),
        details: {
          firstName: details.firstName,
          lastName: details.lastName,
          number: details.number,
        },
        socialList: socialList
          .map(({ platformName, username }) => ({
            platformName,
            username: username.toLowerCase(),
          })),
      };

      this.createClient({ payload })
        .then(() => {
          if (this.client) this.activeStep += 1;
        });
    },
    deleteSocial({ id: _id }) {
      const index = this.newClient.socialList.findIndex(({ id }) => id === _id);
      this.newClient.socialList.splice(index, 1);
    },
  },
};
</script>
