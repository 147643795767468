<template>
  <header class="TheHomeHeader">
    <h1>Dashboard</h1>
    <div class="actions">
      <b-field class="m-0 mr-3">
        <b-input
          placeholder="Search..."
          type="search"
          icon="magnify"
          icon-clickable
        />
      </b-field>
      <div class="buttons">
        <b-button
          type="is-secondary"
          label="Add members"
        />
        <b-button
          type="is-primary"
          label="New client"
        />
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'JackHomeHeader',
};
</script>
