/* eslint-disable max-len */
import { SnackbarProgrammatic as Snackbar } from 'buefy';
import { dispatchAndForward, dispatchFailure, snackBarFailure } from '../$utils/helpers';

export default {
  getters: {
    paymentList: (_state, _getters, _rootState, rootGetters) => {
      const { 'User/payment/getterPaymentList': paymentList } = rootGetters;
      return paymentList;
    },
    payment: (_state, _getters, _rootState, rootGetters) => {
      const { 'User/payment/getterPayment': payment } = rootGetters;
      return payment;
    },
  },
  actions: {
    reportPaymentList({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'User/payment/report',
        context: { payload, httpQuery },
        textNotifier: 'report payment list.',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({ message: 'Unable to report payment list.', ...snackBarFailure });
          return dispatchFailure(action);
        });
    },
    getPayment({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'User/payment/get',
        context: { payload, httpQuery },
        textNotifier: 'get payment.',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({ message: 'Unable to get payment.', ...snackBarFailure });
          return dispatchFailure(action);
        });
    },
    createPayment({ dispatch }, { payload = {}, httpQuery = {}, options = {} }) {
      const action = {
        dispatch,
        target: 'User/payment/post',
        context: { payload, httpQuery, options },
        textNotifier: 'create new payment.',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({ message: 'Unable to create a payment.', ...snackBarFailure });
          return dispatchFailure(action);
        });
    },
    updatePayment({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'User/payment/put',
        context: { payload, httpQuery },
        textNotifier: 'update payment.',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({ message: 'Unable to update payment.', ...snackBarFailure });
          return dispatchFailure(action);
        });
    },
    deletePayment({ dispatch }, { payload = {}, httpQuery = {}, options = {} }) {
      const action = {
        dispatch,
        target: 'User/payment/delete',
        context: { payload, httpQuery, options },
        textNotifier: 'delete payment.',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({ message: 'Unable to delete a payment.', ...snackBarFailure });
          return dispatchFailure(action);
        });
    },
  },
};
