import TheMessaging from './TheMessaging';

export default [
  {
    path: '/messaging',
    name: 'Messaging',
    component: TheMessaging,
    meta: {
      hasMenu: true,
      viewStoreNavigation: 'TheMessaging',
      requireAuth: true,
    },
  },
];
