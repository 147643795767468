/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import { navySeal } from '@jack/provider';
import { isActionAllowed } from '@jack/dataStores/$utils/helpers';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {},
  actions: {
    create({ commit, rootGetters, dispatch }, { payload, httpQuery }) {
      if (!isActionAllowed(rootGetters, 'CLIENT_UNLINK_PERSON.CREATE', dispatch)) return undefined;

      const { cid, ..._payload } = payload;
      return navySeal.apiWsJoe.client.unlink.person.post({ payload: _payload, httpQuery })
        .then((unlink) => {
          if (cid) {
            const { 'Company/getterCompany': company } = rootGetters;
            if (!company.client) return;

            const persons = company.client.persons.reduce((acc, person) => {
              if (Number(person.pid) !== Number(unlink.personPid)) acc.push({ ...person });
              return acc;
            }, []);

            company.client.persons = persons;

            commit('Company/SET_COMPANY', company, { root: true });
          }
        });
    },
  },
  modules: {},
};
