import { navySeal } from '../../../$providers';
import { isActionAllowed } from '../../$utils/helpers';

export default {
  namespaced: true,
  state: {
    userDashboardView: {},
  },
  mutations: {
    SET_USER_DASHBOARD_VIEW(state, newData) { state.userDashboardView = newData; },
  },
  getters: {
    getterUserDashboardView: (state) => state.userDashboardView,
  },
  actions: {
    get({ commit, rootGetters, dispatch }, { payload, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'USER_DASHBOARD_VIEW.GET', dispatch)) return undefined;

      return navySeal.apiWsDivision.user.userDashboardView.get({ payload, httpQuery })
        .then((userDashboardView) => {
          commit('SET_USER_DASHBOARD_VIEW', userDashboardView);
        });
    },
  },
};
