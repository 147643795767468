<template>
  <section
    v-if="!loading"
    class="dashboard"
  >
    <div class="content">
      <header class="multibar is-align-items-center">
        <div class="header-actions end">
          <div class="is-flex is-align-items-center">
            <div class="buttons ml-3">
              <b-tooltip
                type="is-dark"
                :label="$_('Add a new client')"
                position="is-left"
                append-to-body
              >
                <b-button
                  type="is-tertiary"
                  tag="router-link"
                  to="/clients/add"
                  icon-pack="fa"
                  icon-left="plus"
                />
              </b-tooltip>
              <b-tooltip
                type="is-dark"
                :label="$_('Filters')"
                position="is-left"
                append-to-body
              >
                <b-button
                  type="is-tertiary"
                  icon-pack="fa"
                  icon-left="filter"
                  class="ml-5"
                  @click="toggleSidePanelFilters()"
                />
              </b-tooltip>
            </div>
          </div>
        </div>
      </header>

      <br>

      <template v-if="clientFetchedFormatted">
        <div class="dashboard-latest-container px-3">
          <template v-for="client in clientFetchedFormatted">
            <router-link
              v-if="client"
              :key="client.cid"
              :to="`/clients/${client.cid}/details`"
              class="cursor-pointer"
            >
              <article
                class="client-card py-2 px-3"
              >
                <CompanyEmployeesContact
                  v-if="client.company"
                  :client="client"
                  :size="64"
                  :is-clickable="false"
                />
                <Contact
                  v-else
                  :pid="client.persons[0].pid"
                  :details="client.persons[0].details"
                  class="is-flex is-align-items-center"
                  :size="64"
                  :is-clickable="false"
                />
                <p class="subtext has-text-secondary">
                  {{ $moment(client.createdAt).format('YYYY-MM-DD') }}
                </p>
              </article>
            </router-link>
          </template>
        </div>
      </template>
      <NoData
        v-else
        title="No clients found"
        content="You may register a new customer at any time"
        class="noClient"
      />
    </div>

    <FilterPanel
      v-if="openFilter"
      :queries="queries"
      :filters="filters"
      @refresh="fetchClients()"
      @close="toggleSidePanelFilters()"
      @clear="clearFilters()"
      @update:by="queries.by = $event"
      @update:search="queries.search = $event"
      @update:location="queries.location = $event"
      @update:hasMail="queries.hasMail = $event"
      @update:hasNumber="queries.hasNumber = $event"
      @update:refreshed="queries.refreshed = $event"
    />
    <div
      v-if="openFilter"
      class="overlayWithLock"
      @click="quitPanel()"
    />
  </section>
  <LogoAnimated
    v-else
    class="h-full"
  />
</template>

<script>
/* eslint-disable-next-line */
/* eslint-disable import/no-extraneous-dependencies */
import filterHttpQueryMixins from '@jack/addons/common/$mixins/filterHttpQuery.mixins';
import { debounce } from '@jack/addons/$utils/helpers';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TheClients',
  mixins: [filterHttpQueryMixins],
  data() {
    return {
      openFilter: false,
      loading: false,
      search: '',
      inputSearch: '',
      queries: {
        company: true,
        personList: true,
        socialList: true,
        projectList: true,
        paymentList: true,
        pagin: {
          limit: 50,
          offset: 0,
        },
        location: null,
        search: null,
        by: 'name',
        hasMail: false,
        hasNumber: false,
        refreshed: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      currentUid: 'TheClients/currentUid',
      userClientList: 'TheClients/userClientList',
      windowWidth: 'TheMenu/getterWindowWidth',
    }),
    filters() {
      return {
        by: {
          optionsSearch: [
            { name: 'Name', key: 'name' },
            { name: 'Description', key: 'description' },
            { name: 'Head line', key: 'head_line' },
            { name: 'Job', key: 'job' },
          ],
        },
        search: {},
        location: {},
        hasMail: {},
        hasNumber: {},
        refreshed: {},
      };
    },
    clientFetchedFormatted() {
      if (!this.userClientList || !Object.values(this.userClientList).length) return null;

      return Object.values(this.userClientList)
        .reduce((acc, client) => {
          let company = null;
          const { company: _company } = client;
          if (_company) {
            const { details, ...rest } = _company;
            company = { ...rest, ...details };
          }
          // const company = client.company && client.company.length ? client.company[0] : null;
          if (this.search) {
            // const findFirstName = !!firstName.toLowerCase().includes(this.search.toLowerCase());
            // const findLastName = !!lastName.toLowerCase().includes(this.search.toLowerCase());
            // const findNumber = !!number.toLowerCase().includes(this.search.toLowerCase());

            // if (findFirstName || findLastName || findNumber) acc.push(client);

            return acc;
          }

          acc.push({ ...client, company });
          return acc;
        }, [])
        .sort((a, b) => this.$moment(b.createdAt).unix() - this.$moment(a.createdAt).unix());
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    inputSearch: debounce(function (newVal) {
      this.search = newVal;
    }, 500),
  },
  mounted() {
    this.fetchClients();
  },
  methods: {
    ...mapActions({
      reportClientListByUser: 'TheClients/reportClientListByUser',
    }),
    toggleSidePanelFilters() {
      this.openFilter = !this.openFilter;

      if (this.openFilter) document.getElementById('app').classList.add('body-locked');
      else document.getElementById('app').classList.remove('body-locked');
    },
    fetchClients() {
      this.loading = true;
      const payload = { uid: this.currentUid };
      const httpQuery = Object.entries(this.queries).reduce((acc, [key, value]) => {
        if (key === 'pagin') return acc;
        if (value) acc[key] = value.toString();
        return acc;
      }, {});
      if (!this.queries.search) delete httpQuery.by;
      httpQuery.pagin = `${this.queries.pagin.limit},${this.queries.pagin.offset}`;

      return Promise.resolve()
        .then(() => this.reportClientListByUser({ payload, httpQuery }))
        .then(() => { this.loading = false; });
    },
    quitPanel() {
      if (this.openFilter) this.openFilter = false;
    },
  },
};
</script>
