<template>
  <section class="dashboard">
    <div class="content">
      <section class="settings">
        <aside class="settings-list-container">
          <div class="settings-list-menu">
            <header>
              <div class="profile">
                <div
                  v-if="myDetails.imgUrl"
                  class="profile-picture"
                  :style="`background:url('${myDetails.imgUrl}')`"
                />
                <InitialsPicture
                  v-else
                  :name="`${myDetails.firstName} ${myDetails.lastName}`"
                  :size="48"
                />
              </div>
            </header>

            <br>

            <b-menu>
              <b-menu-list>
                <b-menu-item
                  v-for="item in settingsMenu"
                  :key="item.anchor"
                  :label="item.label"
                  :icon-pack="item.pack"
                  :icon="item.icon"
                  tag="a"
                  :href="`#${item.anchor}`"
                  :active="item.active"
                  class="subtext is-small m-0"
                />
              </b-menu-list>
            </b-menu>
          </div>
        </aside>
        <div class="settings-content">
          <section
            id="informations"
            class="settings-content-section"
          >
            <header class="settings-content-header">
              <legend class="text is-small">Basic informations</legend>
            </header>
            <div class="settings-content-content py-5">
              test
            </div>
          </section>
          <section
            id="emails"
            class="settings-content-section"
          >
            <header class="settings-content-header">
              <legend class="text is-small">Email</legend>
            </header>
            <div class="settings-content-content py-5">
              test
            </div>
          </section>
          <section
            id="password"
            class="settings-content-section"
          >
            <header class="settings-content-header">
              <legend class="text is-small">Password</legend>
            </header>
            <div class="settings-content-content py-5">
              <ChangePassword
                @updatePassword="updatePassword($event)"
              />
            </div>
          </section>
          <router-view name="editAppointmentSettings" />
        </div>
      </section>
    </div>
  </section>
</template>

<script>
/* eslint-disable max-len */
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TheSettingsProfile',
  data() {
    return {
      lockBody: false,
      open: {
        email: false,
        password: false,
      },
      email: null,
      password: null,
      appointmentSettings: {},
    };
  },
  computed: {
    ...mapGetters({
      myAuthenticate: 'TheSettingsProfile/myAuthenticate',
      myDetails: 'TheSettingsProfile/myDetails',
      myPassName: 'TheSettingsProfile/myPassName',
    }),
    settingsMenu() {
      return [
        {
          label: 'Informations', anchor: 'informations', pack: 'fa', icon: 'user', active: true,
        },
        {
          label: 'Email', anchor: 'emails', pack: 'fa', icon: 'at', active: false,
        },
        {
          label: 'Password', anchor: 'password', pack: 'fa', icon: 'key', active: false,
        },
        {
          label: 'Appointment settings', anchor: 'appointment-settings', pack: 'fa', icon: 'calendar-week', active: false,
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      _changePassword: 'TheSettingsProfile/changePassword',
    }),
    updatePassword(payload) {
      this._changePassword({
        payload: {
          ...payload,
          userUid: this.myAuthenticate.uid,
          aid: this.myAuthenticate.aid,
          passName: this.myAuthenticate.passName,
        },
      });
    },
  },
};
</script>
