/* eslint-disable import/no-extraneous-dependencies */
import engine from '@jack/dataStores/IOactions';

export default {
  sockets: {
    mq(res) {
      if (!res || !res.action) return;
      const { action, ...data } = res;
      const [entity, target] = action.split(':');

      engine()[entity][target]({ store: this.$store, data });
    },
  },
};
