<template>
  <section class="is-flex is-flex-direction-column is-justify-content-space-around">
    <article
      :class="['card choice mb-5', {
        'disabled': comingSoon.includes('DEFAULT')
      }]"
      @click="emitEvent('DEFAULT')"
    >
      <p class="text">
        Add new person
      </p>
    </article>
    <article
      :class="['card choice', {
        'disabled': comingSoon.includes('LINKEDIN')
      }]"
      @click="emitEvent('LINKEDIN')"
    >
      <p class="text">
        Fetch person in Linkedin
      </p>
    </article>
  </section>
</template>

<script>
export default {
  name: 'AddPersonChoice',
  data() {
    return {
      comingSoon: ['LINKEDIN'],
    };
  },
  methods: {
    emitEvent(entite) {
      if (this.comingSoon.includes(entite)) return;
      this.$emit('choice', entite);
    },
  },
};
</script>
