<template>
  <!-- eslint-disable max-len -->
  <section v-if="appointmentFormat">
    <div class="is-flex is-align-items-center is-justify-content-space-between">
      <div class="is-flex is-flex-direction-column">
        <span class="subtitle is-small m-0">{{ $moment().locale($lang()).format('dddd').capitalize() }}</span>
        <span class="has-text-secondary text is-small m-0">{{ $moment().locale($lang()).format($_('MMMM Do, YYYY')) }}</span>
      </div>
      <b-tooltip
        type="is-dark"
        :label="$_('Calendar')"
        position="is-left"
      >
        <b-button
          type="is-tertiary"
          icon-pack="fa"
          icon-left="arrow-right"
          tag="router-link"
          to="/calendar"
        />
      </b-tooltip>
    </div>

    <br>

    <div class="appointments-container">
      <div
        v-for="day in week"
        :key="day.number"
        class="appointments-card mr-3"
      >
        <div class="divider" />
        <div class="calendar">
          <div class="calendar-month small">
            <span>{{ $_(day.dayWeek) }}</span>
          </div>
          <div class="calendar-day small">
            <span>{{ day.dayNumber }}</span>
          </div>
        </div>

        <div>
          <template v-if="Object.values(appointmentFormat[day.index]).length >= 1">
            <template v-for="row in sortByDate(Object.values(appointmentFormat[day.index]))">
              <div
                v-if="row"
                :key="row.createdAt"
                :class="['appointment-content', {
                  'mb-3': Object.values(appointmentFormat[day.index]).length > 1
                }]"
              >
                <p class="text is-big m-0">{{ row.hours }} - {{ row.label }}</p>
                <p class="text has-text-secondary is-small">
                  {{ row.description }}
                </p>

                <CompanyEmployeesContact
                  v-if="companies[row.companyCid]"
                  :client="formatCompany(companies[row.companyCid])"
                  :size="64"
                />
                <Contact
                  v-if="row.personPid"
                  :pid="row.personPid"
                  :details="persons[row.personPid].details"
                  class="mt-3"
                />
              </div>
            </template>
          </template>
          <p
            v-else
            class="has-text-secondary text is-small"
          >
            {{ $_('Nothing for this day') }}
          </p>
        </div>
      </div>
    </div>
  </section>
  <section v-else>
    <h3 class="has-text-secondary subtitle">
      No appointments are scheduled for the next 7 days...
    </h3>
  </section>
</template>

<script>
export default {
  name: 'DashboardHelloWorld',
  props: {
    appointments: { type: Array, required: true },
    clients: { type: Object, required: true },
    persons: { type: Object, required: true },
    companies: { type: Object, required: true },
  },
  data() {
    return {
      activeTab: '0',
    };
  },
  computed: {
    week() {
      const thisWeek = new Array(7).fill().map((_, i) => ({
        dayWeek: this.$moment().add(i, 'days').format('ddd'),
        dayNumber: this.$moment().add(i, 'days').format('D'),
        index: i.toString(),
      }));

      return thisWeek;
    },
    appointmentFormat() {
      if (!this.appointments) return null;
      const today = this.$formatDate();

      const datas = this.appointments.reduce((acc, row) => {
        const dateAppoitnment = this.$moment(row.date);
        const hourAppoitnment = this.$moment(row.date).format('HH:mm');

        const diff = dateAppoitnment.diff(today, 'day');
        acc[diff][row.appointmentAid] = {
          ...row,
          hours: hourAppoitnment,
          client: this.clients[row.clientCid],
        };
        return acc;
      }, {
        0: {}, 1: {}, 2: {}, 3: {}, 4: {}, 5: {}, 6: {},
      });

      return datas;
    },
  },
  methods: {
    displayDate(today, days) {
      return this.$moment(today).add(days, 'days').format('MMMM Do, YYYY');
    },
    sortByDate(array) {
      if (!array || !array.length) return [];
      return array
        .sort((a, b) => this.$moment(b.createdAt).unix() - this.$moment(a.createdAt).unix());
    },
    formatCompany({ details, ...rest }) {
      return { company: { ...rest, ...details } };
    },
  },
};
</script>
