/* eslint-disable max-len */
import { SnackbarProgrammatic as Snackbar } from 'buefy';
import { dispatchAndForward, dispatchFailure, snackBarFailure } from '../$utils/helpers';

export default {
  getters: {
    profile: (_state, _getters, _rootState, rootGetters) => {
      const { 'Instagram/getterProfile': profile } = rootGetters;

      return profile;
    },
  },
  actions: {
    getInstagramProfile({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'Instagram/get',
        context: { payload, httpQuery },
        textNotifier: 'fetch instagram\'s profile',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to fetch instagram\'s profile',
            ...snackBarFailure,
            indefinite: false,
          });
          return dispatchFailure(action);
        });
    },
  },
};
