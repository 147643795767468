import { use, registerComponent } from '../../$utils/plugins';

import ClientCompany from './ClientCompany';
import ClientPerson from './ClientPerson';
import ClientHeader from './ClientHeader';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, ClientCompany);
    registerComponent(Vue, ClientPerson);
    registerComponent(Vue, ClientHeader);
  },
};

use(Plugin);

export default Plugin;

export {
  ClientCompany,
  ClientPerson,
  ClientHeader,
};
