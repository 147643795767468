<template>
  <ChartJs
    type="line"
    :width="width"
    :height="height"
    :id-chart="idChart"
    :init-chart="initChartLine"
    :data="data"
    :options-plugins-legend-labels-use-point-style="false"
    :options-plugins-legend-labels-box-width="10"
    :options-plugins-legend-labels-padding="0"
    :options-plugins-scales-y-begin-ticks-callback="optionsPluginsScalesYBeginTicksCallback"
    :options-plugins-scales-y-begin-ticks-step-size="initStepYAxis"
    :options-elements-line-background-color="optionsElementsLineBackgroundColor"
    options-plugins-scales-y-begin-at-zero
  />
</template>

<script>

export default {
  name: 'ChartLine',
  props: {
    width: { type: String, default: '300' },
    height: { type: String, default: '500' },
    idChart: { type: String, required: true },
    type: { type: String, default: 'line' },
    data: { type: Object, required: true },
    // options.elements.line
    optionsElementsLineTension: { type: Number, default: 0.25 },
    optionsElementsLineBackgroundColor: { type: String, default: '255, 99, 132' },
    optionsElementsLineBorderWidth: { type: Number, default: 3 },
    optionsElementsLineBorderColor: { type: String, default: '255, 99, 132' },
    optionsElementsLineBorderCapStyle: { type: String, default: 'butt' },
    optionsElementsLineBorderDash: { type: [Number, Array], default: () => [] },
    optionsElementsLineBorderDashOffset: { type: Number, default: 0.0 },
    optionsElementsLineBorderJoinStyle: { type: String, default: 'miter' },
    optionsElementsLineCapBezierPoints: { type: Boolean, default: true },
    optionsElementsLineCubicInterpolationMode: { type: String, default: 'default' },
    optionsElementsLineFill: { type: [Boolean, String], default: true },
    optionsElementsLineStepper: { type: Boolean, default: false },

    // options.elements.point
    optionsElementsPointRadius: { type: Number, default: 6 },
    optionsElementsPointPointStyle: { type: String, default: 'circle' },
    optionsElementsPointRotation: { type: Number, default: 0 },
    optionsElementsPointBackgroundColor: { type: String, default: '255, 255, 255' },
    optionsElementsPointBorderWidth: { type: Number, default: 1 },
    optionsElementsPointBorderColor: { type: String, default: '255, 99, 132' },
    optionsElementsPointHitRadius: { type: Number, default: 1 },
    optionsElementsPointHoverRadius: { type: Number, default: 4 },
    optionsElementsPointHoverBorderWidth: { type: Number, default: 1 },
    optionsPluginsScalesYBeginTicksCallback: {
      type: Object,
      default: () => ({
        hasSymbol: false,
        hasNFormatter: true,
        symbolLeft: '€',
        symbolRight: '',
        nFormatterValue: 1,
      }),
    },
  },
  computed: {
    initChartLine() {
      return {
        line: {
          tension: this.optionsElementsLineTension,
          backgroundColor: `rgba(${this.optionsElementsLineBackgroundColor}, 1)`,
          borderWidth: this.optionsElementsLineBorderWidth,
          borderColor: `rgba(${this.optionsElementsLineBorderColor}, 1)`,
          borderCapStyle: this.optionsElementsLineBorderCapStyle,
          borderDash: this.optionsElementsLineBorderDash,
          borderDashOffset: this.optionsElementsLineBorderDashOffset,
          borderJoinStyle: this.optionsElementsLineBorderJoinStyle,
          capBezierPoints: this.optionsElementsLineCapBezierPoints,
          cubicInterpolationMode: this.optionsElementsLineCubicInterpolationMode,
          fill: this.optionsElementsLineFill,
          stepper: this.optionsElementsLineStepper,
        },
        point: {
          radius: this.optionsElementsPointRadius,
          pointStyle: this.optionsElementsPointPointStyle,
          rotation: this.optionsElementsPointRotation,
          backgroundColor: `rgba(${this.optionsElementsPointBackgroundColor}, 1)`,
          borderWidth: this.optionsElementsPointBorderWidth,
          borderColor: `rgba(${this.optionsElementsPointBorderColor}, 1)`,
          hitRadius: this.optionsElementsPointHitRadius,
          hoverRadius: this.optionsElementsPointHoverRadius,
          hoverBorderWidth: this.optionsElementsPointHoverBorderWidth,
        },
      };
    },
    initStepYAxis() {
      const { datasets: [{ data }] } = this.data;

      let sum = 0;

      const newArrayDataWithoutNullValues = data.reduce((acc, value) => {
        if (value === 'null' || !value) return acc;
        sum += Number(value);
        acc.push(Number(value));
        return acc;
      }, []);

      const min = Math.min(...newArrayDataWithoutNullValues);
      const max = Math.max(...newArrayDataWithoutNullValues);

      const maxSubtractMinLength = (max - min).toFixed(0).length;

      const tot = sum / newArrayDataWithoutNullValues.length;

      if (tot < 1) return 0.25;

      if (maxSubtractMinLength < 2) return 1;
      if (maxSubtractMinLength === 2) return 5;
      if (maxSubtractMinLength === 3) return 10 ** 2;
      if (maxSubtractMinLength >= 4 && maxSubtractMinLength < 5) return 10 ** 3;
      if (maxSubtractMinLength >= 5 && maxSubtractMinLength < 6) return 10 ** 4;
      if (maxSubtractMinLength >= 6) return 10 ** 5;
      return 10 ** 2;
    },
  },
};
</script>
