/* eslint-disable import/no-extraneous-dependencies */
import { navySeal } from '@jack/provider';
import changePassword from './changePassword.store';

const engine = ({ dispatch, commit, authenticate }) => {
  const {
    account, appointmentSettings, socket: { token }, language,
  } = authenticate;
  const { pbToken, hasFeature, tagList } = account;
  commit('User/language/SET_LANGUAGE', language.language, { root: true });
  commit('Account/SET_ACCOUNT', account, { root: true });
  commit('Socket/SET_SOCKET_TOKEN', token, { root: true });
  commit('User/settings/SET_APPOINTMENT_SETTINGS', appointmentSettings, { root: true });
  commit('Tag/SET_TAG_LIST', tagList.arrayMapper('tid'), { root: true });
  commit('SET_AUTHENTICATE', authenticate);
  commit(
    'Account/features/SET_FEATURES',
    hasFeature
      .map(({ featureFid }) => featureFid)
      .reduce((acc, feature) => {
        const [key, feat] = feature.split('.');
        if (!feat) { acc[key] = true; return acc; }
        acc[key] = { ...acc[key], [feat]: true };
        return acc;
      }, {}),
    { root: true },
  );

  dispatch('PhantomBuster/token/set_token', pbToken, { root: true });
};

export default {
  namespaced: true,
  state: {
    authenticate: null,
  },
  mutations: {
    SET_AUTHENTICATE(state, newData) { state.authenticate = newData; },
  },
  getters: {
    getterAuthenticate: (state) => state.authenticate,
  },
  actions: {
    connectUser({ dispatch, commit }, { payload }) {
      return navySeal.apiWsDivision.authenticate.post({ payload, httpQuery: {} })
        .then((authenticate) => {
          engine({ dispatch, commit, authenticate });
        })
        .catch(({ status, message }) => {
          if (status === 400) commit('SET_AUTHENTICATE', null);
          switch (message) {
            case 'E_NOT_FOUND_AUTHENTICATE_NO_AUTHS_FOR_PASSNAME_AND_METHOD':
              dispatch('TheWsNotifier/push', { type: 'danger', title: 'Connection rejected', content: 'Incorrect email or password' }, { root: true });
              break;
            case 'E_BAD_REQUEST_AUTHENTICATE_USER_AUTH_PASSWORD_DONT_MATCH':
              dispatch('TheWsNotifier/push', { type: 'danger', title: 'Connection rejected', content: 'Incorrect email or password' }, { root: true });
              break;
            default:
              break;
          }
        });
    },
    reconnectUser({ dispatch, commit }) {
      return navySeal.apiWsDivision.authenticate.get({ payload: {}, httpQuery: {} })
        .then((authenticate) => {
          engine({ dispatch, commit, authenticate });
        })
        .catch((error) => {
          if (error.status === 401) commit('SET_AUTHENTICATE', null);
          throw new Error(error);
        });
    },
    disconnectUser({ commit }) {
      return navySeal.apiWsDivision.authenticate.delete({ payload: {}, httpQuery: {} })
        .then(() => commit('SET_AUTHENTICATE', null))
        .catch(({ status }) => {
          if (status === 400) commit('SET_AUTHENTICATE', null);
        });
    },
    up_authenticate({ commit }, __authenticate__) {
      const authenticate = __authenticate__;
      commit('SET_AUTHENTICATE', authenticate);
    },
  },
  modules: {
    changePassword,
  },
};
