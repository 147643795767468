<template>
  <section class="company-notes">
    <div class="notes-container">
      <template v-if="notesSorted">
        <template
          v-for="note in notesSorted"
        >
          <NoteCard
            v-if="note"
            :key="note.cnid"
            :note="note"
            @deleteNote="$emit('deleteNote', $event)"
          />
        </template>
      </template>
    </div>
    <NoteTextarea
      :value="value"
      @update:value="value = $event"
      @addNote="addNote()"
    />
  </section>
</template>

<script>
export default {
  name: 'Notes',
  props: {
    notesSorted: { type: Array, default: () => [] },
  },
  data() {
    return {
      value: '',
    };
  },
  methods: {
    addNote() {
      if (!this.value) return;
      this.$emit('addNote', this.value);
      this.value = '';
    },
  },
};
</script>
