<template>
  <!-- eslint-disable max-len -->
  <section
    v-if="!loading"
    class="schedule-appointment-container"
  >
    <header class="schedule-appointment-header">
      <div
        v-if="!error"
        class="barre-steps"
      >
        <div
          class="load"
          :style="`width:${progressWidth}%`"
        />
      </div>
      <b-image
        class="bg-svg is-large"
        :src="background"
        alt="Fond devmood"
      />
    </header>
    <template v-if="params.t">
      <template v-if="error">
        <section class="text-center">
          <b-icon
            icon="triangle-exclamation"
            pack="fa"
            size="is-large"
            class="ultraTitle"
          />
          <p class="mt-5 title is-big mb-2">Invalid link</p>
          <p class="lead">
            Please contact the person who provided the link so that they can send you a new valid link
          </p>
        </section>
      </template>
      <template v-else>
        <section
          v-if="appointmentToken.atid"
          class="schedule-appointment-form"
        >
          <div>
            <h1 class="bigtitle animTitle">
              <span v-if="display.title">{{ hi }}</span>
            </h1>
            <div
              v-if="display.subtitle"
              class="lead animTitle"
            >
              <template v-if="labelValue">
                <p class="subtitle is-small">{{ appointmentToken.user.name }} would like to make an appointment with you for the following context:</p>
                <p>{{ labelValue }}</p>
                <p
                  v-if="descriptionValue"
                  class="has-text-secondary"
                >
                  {{ descriptionValue }}
                </p>
              </template>
              <template v-else>
                <p>Please fill in the following fields to make an appointment with {{ appointmentToken.user.name }}.</p>
              </template>
            </div>

            <br>

            <template v-if="display.form">
              <template v-for="(step, i) in steps">
                <component
                  :is="step.cmp"
                  v-if="step.step === activeStep"
                  :key="step.cmp"
                  :user="appointmentToken.user || {}"
                  :person="person"
                  :appointment="appointment"
                  :appointments="appointmentToken.appointments"
                  :duration="appointmentToken.duration"
                  :ms="ms"
                  :sc-person="scPerson"
                  :sc-appointment="scAppointment"
                  :dates="dates"
                  :name="appointmentToken.person ? appointmentToken.person.name : ''"
                  @update:mail="person.mail = $event"
                  @update:number="person.number = $event"
                  @update:date="appointment.date = $event"
                  @update:label="appointment.label = $event"
                  @update:description="appointment.description = $event"
                  @finished="finished()"
                  @next="next(i + 1)"
                />
              </template>
            </template>
            <section v-else />
          </div>
        </section>
      </template>
    </template>
    <!-- <div class="authenticate-image">
    </div> -->
    <template v-else>
      No token
    </template>
    <footer class="schedule-appointment-footer">
      <div>
        <p class="subtext has-text-secondary">Powered by</p>
        <b-image
          :src="theme ? logo.white : logo.black"
          alt="Devmood logo"
          class="logo"
        />
      </div>
    </footer>
  </section>
  <LogoAnimated v-else />
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */
import Joi from 'joi';
import { mapGetters, mapActions } from 'vuex';
import engine from './$mixins/engine';

const logoWhite = require('@jack/public/assets/images/icons/DEVMOOD_TEXT_WHITE_SVG.svg');
const logoBlack = require('@jack/public/assets/images/icons/DEVMOOD_TEXT_BLACK_SVG.svg');
const cccoil = require('@jack/public/assets/images/cccoil.svg');

export default {
  name: 'TheScheduleAppointment',
  data() {
    return {
      ms: 2000,
      display: {
        title: false,
        subtitle: false,
        form: false,
      },
      loading: false,
      activeStep: 0,
      appointment: {
        date: null,
        label: null,
        description: null,
      },
      logo: {
        white: logoWhite,
        black: logoBlack,
      },
      background: cccoil,
      person: {
        mail: null,
        number: null,
        firstName: null,
        lastName: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      error: 'TheScheduleAppointment/appointmentTokenError',
      appointmentToken: 'TheScheduleAppointment/appointmentToken',
      theme: 'Starter/getterTheme',
    }),
    contactRequired() { return !!this.params.newPerson; },
    emailRequired() { return !!this.params.mail; },
    numberRequired() { return !!this.params.number; },
    labelValue() { return this.params.label; },
    descriptionValue() { return this.params.description; },
    durationValue() { return this.params.duration; },
    dateRangeValue() { return this.params.dateRange; },
    noEndDateValue() { return this.params.noEndDate; },
    maxDateValue() { return this.params.maxDate; },
    durationRequired() { return !!this.params.duration; },
    hi() {
      return this.appointmentToken.person ? `Hi ${this.appointmentToken.person.name}!` : 'Hi!';
    },
    progressWidth() {
      return this.display.form ? ((this.activeStep + 1) / (this.steps.length)) * 100 : 0;
    },
    dates() {
      if (this.noEndDateValue) return [];
      if (this.dateRangeValue) return this.dateRangeValue.split(',');
      return this.maxDateValue.split(',');
    },
    steps() {
      const steps = [];

      if (!this.labelValue) {
        steps.push(
          { cmp: 'LabelForm', step: steps.length },
          { cmp: 'DescriptionForm', step: steps.length + 1 },
        );
      }

      steps.push({ cmp: 'DateForm', step: steps.length });

      if (this.emailRequired) steps.push({ cmp: 'EmailForm', step: steps.length });
      if (this.numberRequired) steps.push({ cmp: 'NumberForm', step: steps.length });
      if (this.contactRequired) {
        steps.push(
          { cmp: 'FirstNameForm', step: steps.length },
          { cmp: 'LastNameForm', step: steps.length + 1 },
          { cmp: 'EmailForm', step: steps.length + 2 },
          { cmp: 'NumberForm', step: steps.length + 3 },
        );
      }

      steps.push({ cmp: 'ThankYou', step: steps.length });
      return steps;
    },
    scPerson() {
      return Joi.object().keys({
        firstName: Joi.string().allow(null).required(),
        lastName: Joi.string().allow(null).required(),
        mail: Joi.string().allow(null).required(),
        number: Joi.string().allow(null).required(),
      });
    },
    scAppointment() {
      return Joi.object().keys({
        label: Joi.string().allow(null).required(),
        description: Joi.string().allow(null).required(),
        date: Joi.date().allow(null).required(),
      });
    },
    params() {
      if (!this.$route.query.v) return {};
      return this.$currentQuery('v')
        .split(';')
        .reduce((acc, el) => {
          const [key, value] = el.split('=');
          acc[key] = value;
          return acc;
        }, {});
    },
  },
  mounted() {
    if (!this.params.t || !this.$route.query.v) {
      window.location = 'https://devmood.fr';
    }
    if (this.params.t) this._fetchAppointmentToken();
  },
  methods: {
    ...mapActions({
      _getAppointmentToken: 'TheScheduleAppointment/getAppointmentToken',
      _appointmentTokenAffiliate: 'TheScheduleAppointment/appointmentTokenAffiliate',
    }),
    next(index) { this.activeStep = index; },
    _fetchAppointmentToken() {
      this.loading = true;
      this._getAppointmentToken({ httpQuery: { t: this.params.t } })
        .then(() => {
          this.loading = false;
          setTimeout(() => { this.display.title = true; }, (this.ms * 0));
          setTimeout(() => { this.display.subtitle = true; }, (this.ms / 2));
          setTimeout(() => { this.display.form = true; }, this.ms);
        });
    },
    finished() {
      let type = 'SAVE_APPOINTMENT';
      const {
        appointment,
        appointmentToken: { atid, person: { pid } },
        person,
      } = this;

      if (this.emailRequired || this.numberRequired) type = 'SAVE_APPOINTMENT_PATCH_PERSON';
      if (this.contactRequired) type = 'SAVE_APPOINTMENT_CREATE_PERSON';

      this._appointmentTokenAffiliate({
        payload: {
          atid,
          type,
          personToPatch: engine().personToPatchPayload({ type, pid, person }),
          personToCreate: engine().personToCreatePayload({ type, person }),
          appointment: engine().appointmentPayload({
            appointment,
            label: this.labelValue,
            description: this.descriptionValue,
          }),
        },
      });
    },
  },
};
</script>
