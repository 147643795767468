/* eslint-disable max-len */
export default {
  namespaced: true,
  state: {
    navigation: {
      menuItems: [
        {
          path: '/style-guide/accordion',
          text: 'Accordion',
          chapter: 'base',
        },
        {
          path: '/style-guide/alert',
          text: 'Alert',
          chapter: 'base',
        },
        {
          path: '/style-guide/breadcrumb',
          text: 'Breadcrumb',
          chapter: 'base',
        },
        {
          path: '/style-guide/bullet',
          text: 'Bullet',
          chapter: 'base',
        },
        {
          path: '/style-guide/button',
          text: 'Button',
          chapter: 'base',
        },
        {
          path: '/style-guide/card',
          text: 'Card',
          chapter: 'base',
        },
        {
          path: '/style-guide/carousel',
          text: 'Carousel',
          chapter: 'base',
        },
        {
          path: '/style-guide/form',
          text: 'Form',
          chapter: 'base',
        },
        {
          path: '/style-guide/indicator',
          text: 'Indicator',
          chapter: 'base',
        },
        {
          path: '/style-guide/modal',
          text: 'Modal',
          chapter: 'base',
        },
        {
          path: '/style-guide/overlay',
          text: 'Overlay',
          chapter: 'base',
        },
        {
          path: '/style-guide/pagination',
          text: 'Pagination',
          chapter: 'base',
        },
        {
          path: '/style-guide/popover',
          text: 'Popover',
          chapter: 'base',
        },
        {
          path: '/style-guide/pulse',
          text: 'Pulse',
          chapter: 'base',
        },
        {
          path: '/style-guide/rating',
          text: 'Rating',
          chapter: 'base',
        },
        {
          path: '/style-guide/ribbon',
          text: 'Ribbon',
          chapter: 'base',
        },
        {
          path: '/style-guide/rotate',
          text: 'Rotate',
          chapter: 'base',
        },
        {
          path: '/style-guide/separator',
          text: 'Separator',
          chapter: 'base',
        },
        {
          path: '/style-guide/symbol',
          text: 'Symbol',
          chapter: 'base',
        },
        {
          path: '/style-guide/table',
          text: 'Table',
          chapter: 'base',
        },
        {
          path: '/style-guide/tab',
          text: 'Tab',
          chapter: 'base',
        },
        {
          path: '/style-guide/toast',
          text: 'Toast',
          chapter: 'base',
        },
        {
          path: '/style-guide/tooltip',
          text: 'Tooltip',
          chapter: 'base',
        },
        {
          path: '/style-guide/chart-js',
          text: 'Chart.js',
          chapter: 'plugin',
        },
        {
          path: '/style-guide/full-calendar',
          text: 'FullCalendar',
          chapter: 'plugin',
        },
      ],
    },
  },
  getters: {
    navigation: (state) => state.navigation,
  },
};
