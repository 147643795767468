var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_vm._m(0),_c('br'),_c('div',{staticClass:"multibar"},[_c('div',{staticClass:"end buttons"},[_c('b-button',{attrs:{"label":"Quit","type":"is-ghost","tag":"router-link","to":{
          path: '/home/calendar/sidePanel',
          query: _vm.$route.query
        }}}),_c('b-button',{attrs:{"label":"Create new project with this client","type":"is-primary","tag":"router-link","icon-pack":"fas","icon-left":"plus","to":{
          path: '/home/calendar/sidePanel/addProject',
          query: _vm.$route.query
        }}})],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"multibar"},[_c('h2',{staticClass:"start title is-medium"},[_vm._v(" The client was created with successfully ! 🥳 ")])])
}]

export { render, staticRenderFns }