import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      theme: 'Starter/getterTheme',
    }),
    states() {
      return {
        CONTACT_AND_SEARCH_PROFILE: {
          key: 'CONTACT_AND_SEARCH_PROFILE', name: this.$_('Contacted and looking for a profile'), pack: 'fa', icon: 'phone-volume', type: 'success', color: '46, 204, 113', base: true,
        },
        NO_RESPONSE: {
          key: 'NO_RESPONSE', name: this.$_('No response'), pack: 'fa', icon: 'voicemail', type: 'warning', color: '230, 126, 34', base: true,
        },
        CONTACT_BUT_DONT_SEARCH_PROFILE: {
          key: 'CONTACT_BUT_DONT_SEARCH_PROFILE', name: this.$_('Contacted but not looking for a profile'), pack: 'fa', icon: 'phone', type: 'danger', color: '192, 57, 43', base: true,
        },
        CONTACTED_ON_LINKEDIN_CONNECTION: {
          key: 'CONTACTED_ON_LINKEDIN_CONNECTION', name: this.$_('Contacted on likedin connection'), pack: 'fa', icon: 'linkedin', type: 'info', color: '14, 118, 168', base: false,
        },
        CONTACT_PER_MAIL: {
          key: 'CONTACT_PER_MAIL', name: this.$_('Contacted per mail'), pack: 'fa', icon: 'envelope', type: 'mail', color: this.theme ? '234, 241, 251' : '86, 86, 86', base: false,
        },
        CONTACT_PER_SMS: {
          key: 'CONTACT_PER_SMS', name: this.$_('Contacted per sms'), pack: 'fa', icon: 'comment', type: 'info', color: '234, 241, 251', base: false,
        },
        CONTACT_PER_SLACK: {
          key: 'CONTACT_PER_SLACK', name: this.$_('Contacted on Slack'), pack: 'fa', icon: 'slack', type: 'slack', color: '236, 178, 46', base: false,
        },
        CONTACT_PER_TWITTER: {
          key: 'CONTACT_PER_TWITTER', name: this.$_('Contacted on Twitter'), pack: 'fa', icon: 'twitter', type: 'twitter', color: '29, 161, 242', base: false,
        },
        CONTACT_PER_FACEBOOK: {
          key: 'CONTACT_PER_FACEBOOK', name: this.$_('Contacted on Facebook'), pack: 'fa', icon: 'facebook', type: 'facebook', color: '66, 103, 178', base: false,
        },
        CONTACT_PER_DISCORD: {
          key: 'CONTACT_PER_DISCORD', name: this.$_('Contacted on Discord'), pack: 'fa', icon: 'discord fa-brands', type: 'discord', color: '114, 137, 218', base: false,
        },
        CONTACT_PER_WHATSAPP: {
          key: 'CONTACT_PER_WHATSAPP', name: this.$_('Contacted on WhatsApp'), pack: 'fa', icon: 'whatsapp', type: 'whatsapp', color: '37, 211, 102', base: false,
        },
        CONTACT_PER_TELEGRAM: {
          key: 'CONTACT_PER_TELEGRAM', name: this.$_('Contacted on Telegram'), pack: 'fa', icon: 'telegram', type: 'telegram', color: '0, 136, 204', base: false,
        },
      };
    },
  },
};
