<template>
  <div>
    TheProjectsAdd
  </div>
</template>

<script>
export default {
  name: 'TheProjectsAdd',
};
</script>

<style lang="scss" scoped>

</style>
