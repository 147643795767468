<template>
  <section>
    <div class="multibar">
      <h3 class="start title is-small">
        Search a client
      </h3>
    </div>

    <br>

    <b-field>
      <b-input
        v-model="inputSearch"
        placeholder="Search..."
        type="search"
        icon-pack="fa"
        icon="search"
        icon-right="xmark"
        icon-right-clickable
        @icon-right-click="inputSearch = ''"
      />
    </b-field>

    <br>

    <template v-if="clientSelected">
      <div>
        <h4 class="start title is-small">Client selected</h4>

        <br>

        <Table
          v-if="Object.values(clientSelected)"
          :data="clientSelected"
          :loading="loading"
          :columns="Object.values(columns)"
          :total="total"
          :page="page"
          :per-page.sync="perPage"
          :sort-field="sortField"
          :sort-order="sortOrder"
          :default-sort-order="defaultSortOrder"
          :selected.sync="rowSelected"
          :checked-rows.sync="checkedRows"
          :checkable="false"
          :sticky-header="false"
          detail-key="cid"
        />
      </div>
    </template>

    <br>

    <div class="jackTableAddProject">
      <Table
        v-if="clientFetchedFormated.length"
        :data="clientFetchedFormated"
        :loading="loading"
        :columns="Object.values(columnsClientsFetched)"
        :total="total"
        :page="page"
        :per-page.sync="perPage"
        :sort-field="sortField"
        :sort-order="sortOrder"
        :default-sort-order="defaultSortOrder"
        :selected.sync="rowSelected"
        :checked-rows.sync="checkedRows"
        :checkable="false"
        detail-key="cid"
        @selectClient="selectClient($event)"
      />
      <template v-else>
        <NoData
          title="No projects were found"
          action
          :buttons-action="buttonsAction"
          @reset="inputSearch = ''"
        />

        <br>

        <div class="multibar">
          <b-button
            label="Create a new client"
            class="end"
            tag="router-link"
            :to="{
              path: '/home/sidePanel/addClient',
              query: $route.query
            }"
            type="is-primary"
            icon-pack="fas"
            icon-left="user-plus"
          />
        </div>
      </template>
    </div>
  </section>
</template>

<script>
/* eslint-disable max-len */
/* eslint-disable-next-line */
import { debounce } from '@jack/addons/$utils/helpers';
import clientSelectColumns from '../../../$mixins/clientSelectTable.columns.mixins';

export default {
  name: 'JackSidePanelAddProjectClientForm',
  mixins: [clientSelectColumns],
  model: {
    prop: 'newProject',
  },
  props: {
    selected: { type: Object, default: () => {} },
    newProject: { type: Object, required: true },
    clients: { type: Array, required: true },
  },
  data() {
    return {
      loading: false,
      total: 0,
      page: 0,
      perPage: 20,
      sortField: 'cpid',
      sortOrder: 'asc',
      defaultSortOrder: 'asc',
      rowSelected: {},
      checkedRows: [],
      inputSearch: '',
      search: '',
      clientSelected: null,
    };
  },
  computed: {
    localNewProject: {
      get() { return this.newProject; },
      set(newValue) { this.$emit('update:newProject', newValue); },
    },
    selectedFormated() {
      if (!Object.values(this.selected).length) return [];
      const { details, socialList, ...rest } = this.selected;
      return [
        { ...rest, ...details, ...socialList.arrayMapper('platformName') },
      ];
    },
    clientFetchedFormated() {
      if (!this.clients || !this.clients.length) return [];

      const query = this.clients.reduce((acc, client) => {
        const { details, socialList, ...rest } = client;

        if (this.search) {
          const firstNameFind = details ? !!details.firstName.toLowerCase().includes(this.search.toLowerCase()) : false;
          const lastNameFind = details ? !!details.lastName.toLowerCase().includes(this.search.toLowerCase()) : false;
          const numberFind = details ? !!details.number.includes(this.search) : false;
          const usernameFind = socialList.length ? socialList.find(({ username }) => username.toLowerCase().includes(this.search.toLowerCase())) : false;

          if (firstNameFind || lastNameFind || numberFind || usernameFind) {
            acc.push({ ...rest, ...details, ...socialList.arrayMapper('platformName') });
          }
        } else {
          acc.push({ ...rest, ...details, ...socialList.arrayMapper('platformName') });
        }
        return acc;
      }, []);

      return query;
    },
    buttonsAction() {
      return [
        {
          label: 'Reset filter', type: 'is-primary', event: 'reset', buttonType: 'event',
        },
      ];
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    inputSearch: debounce(function (newVal) {
      this.search = newVal;
    }, 500),
  },
  mounted() {
    if (Object.values(this.selected).length) {
      const { details, socialList, ...rest } = this.selected;
      const row = { ...rest, ...details, socialList: socialList ? { ...socialList.arrayMapper('platformName') } : {} };
      this.clientSelected = [row];
      this.localNewProject.cid = row;
    }
  },
  methods: {
    selectClient(row) {
      this.localNewProject.cid = row;
      this.clientSelected = [row];
    },
  },
};
</script>
