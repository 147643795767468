<template>
  <section class="fullCalendarView">
    <DevDetails :cnt="{name: 'calendarOptions', obj: calendarOptions.events}" />
    <FullCalendar
      ref="fullCalendar"
      :options="calendarOptions"
    />

    <JackFullCalendarTooltip
      v-show="tooltip"
      :event-tooltip="eventTooltip"
    />
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

import '@fullcalendar/core/vdom';
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';

export default {
  name: 'FullCalendarView',
  components: {
    FullCalendar,
  },
  props: {
    activeChangeView: { type: Boolean, default: false },
    path: { type: String, required: true },
    allEventsOfMonth: { type: Array, required: true },
  },
  data() {
    return {
      initialView: 'dayGridWeek',
      tooltip: false,
      eventTooltip: {},
    };
  },
  computed: {
    ...mapGetters({
      windowWidth: 'TheMenu/getterWindowWidth',
    }),
    viewsFullCalendar() {
      return [
        { name: 'Day grid month', value: 'dayGridMonth' },
        { name: 'Time grid week', value: 'timeGridWeek' },
        { name: 'List week', value: 'listWeek' },
        { name: 'Day grid week', value: 'dayGridWeek' },
      ];
    },
    calendarOptions() {
      return ({
        plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
        initialView: this.view,
        selectable: true,
        aspectRatio: 2,
        showNonCurrentDates: false,
        dateClick: (info) => { this.clickOnOneDate(info); },
        // select: (infos) => { this.selectMultipleDate(infos); },
        // eventClick: (infos) => { this.clickEvent(infos); },
        eventMouseEnter: (infos) => { this.hoverEvent(infos); },
        eventMouseLeave: (infos) => { this.hoverEvent(infos); },
        datesSet: (infos) => { this.emitMonthDisplay(infos); },
        events: this.allEventsOfMonth,
        eventOrder: true,
        headerToolbar: {
          left: '',
          center: 'prev title next',
          right: 'today',
        },
      });
    },
    responsive() {
      if (this.windowWidth <= 1200) return true;
      return false;
    },
    view() { return 'dayGridMonth'; },
  },
  watch: {
    initialView(newVal) {
      if (!newVal) return;
      this.$refs.fullCalendar.getApi().changeView(newVal);
    },
  },
  methods: {
    clickOnOneDate(event) {
      this.$router.push({
        path: `${this.path}/sidePanel`,
        query: { start: event.dateStr },
      });
    },
    selectMultipleDate(event) {
      this.$router.push({
        path: `${this.path}/sidePanel`,
        query: {
          start: event.startStr,
          end: this.$moment(event.endStr).subtract(1, 'days').format('YYYY-MM-DD'),
        },
      });
    },
    emitMonthDisplay(month) {
      const start = this.$moment(month.startStr).startOf('month').format('YYYY-MM-DD');
      const end = this.$moment(month.endStr).endOf('month').subtract(1, 'months').format('YYYY-MM-DD');

      this.$emit('monthDisplayed', {
        dateBetween: `${start},${end}`,
      });
    },
    clickEvent(infos) {
      if (infos.event.extendedProps.tooltip) {
        this.$router.push({
          path: '/home/calendar/sidePanel/event',
          query: {
            eid: infos.event._def.publicId,
            type: infos.event._def.extendedProps.event,
            start: this.$moment(infos.view.getCurrentData().currentDate).format('YYYY-MM-DD'),
          },
        });
      }
    },
    hoverEvent(infos) {
      if (infos.event.extendedProps.tooltip) {
        this.tooltip = !this.tooltip;

        if (!this.tooltip) {
          this.eventTooltip = {};
        } else {
          this.eventTooltip = {
            x: infos.jsEvent.clientX,
            y: infos.jsEvent.clientY,
            id: infos.event._def.publicId,
            title: infos.event._def.title,
            description: infos.event.extendedProps.description,
            pack: infos.event.extendedProps.pack,
            icon: infos.event.extendedProps.icon,
            type: infos.event.extendedProps.event,
          };
        }
      }
    },
  },
};
</script>
