/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */

import kanban from './kanban.store';
import kanbanColumn from './kanbanColumn.store';
import kanbanItem from './kanbanItem.store';
import tag from './tag.store';

export default {
  namespaced: true,
  state: {
    projectList: {},
    project: {},
  },
  mutations: {
    SET_PROJECT_LIST(state, newData) { state.projectList = newData; },
    SET_PROJECT(state, newData) { state.project = newData; },
    UPDATE_PERSON_IN_PERSON_LIST(state, newData) {
      state.personList[newData.pid] = { ...state.personList[newData.pid], ...newData };
    },
  },
  getters: {
    getterProjectList: (state) => state.projectList,
    getterProject: (state) => state.project,
  },
  actions: {},
  modules: {
    kanban,
    kanbanColumn,
    kanbanItem,
    tag,
  },
};
