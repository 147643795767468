/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
const engine = () => ({
  valuesOfEntities: (obj, bonus = 0) => (obj
    ? Object.entries(obj)
      .reduce((acc, [_, value]) => {
        if (value) acc += 1;
        return acc;
      }, 0 + bonus)
    : 0),
});

module.exports = engine;
