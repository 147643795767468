<template>
  <div>
    <DevDetails :cnt="{name: 'cardPaymentsTeamChartLine', obj: cardPaymentsTeamChartLine}" />
    <DevDetails :cnt="{name: 'cardTurnover', obj: cardTurnover}" />
    <DevDetails :cnt="{name: 'cardClient', obj: cardClient}" />
    <DevDetails :cnt="{name: 'dashboardByUser', obj: dashboardByUser}" />

    <section>
      <div class="buttons">
        <b-dropdown
          aria-role="list"
          :close-on-click="false"
        >
          <template #trigger>
            <b-button
              :label="memberSelected.length
                ? `Members selected (${memberSelected.length})`
                : 'Members'"
              type="is-dashed-1"
            />
          </template>

          <b-dropdown-item
            v-for="user in userList"
            :key="user.uid"
          >
            <b-checkbox
              v-model="memberSelected"
              :native-value="user"
            >
              {{ user.firstName }} {{ user.lastName }}
            </b-checkbox>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </section>

    <br>

    <section class="cardDashboard col-3">
      <DashboardCard
        v-if="cardTurnover"
        title="Revenues"
        :data="cardTurnover"
        symbol="€"
      />
      <DashboardCard
        v-if="cardClient"
        title="Clients"
        :data="cardClient"
      />
      <DashboardCard
        v-if="cardProjects"
        title="Projects"
        :data="cardProjects"
      />
    </section>

    <br>

    <section class="cardDashboard">
      <article class="no-border transparent w-100">
        <div class="card-content">
          <div class="multibar">
            <p class="start subtitle">{{ cardsChart[0].title }}</p>
          </div>

          <div class="containerChart xxl">
            <ChartLine
              v-if="cardsChart[0].datas"
              :id-chart="cardsChart[0].id"
              :data="cardsChart[0].datas"
              height="550"
            />
          </div>
        </div>
      </article>
    </section>

    <section
      :class="['cardDashboard', {
        'col-1': gridResponsiveChart === 1,
        'col-2': gridResponsiveChart === 2,
      }]"
    >
      <template
        v-for="(chart, i) in cardsChart"
      >
        <article
          v-if="chart.visible && i > 0"
          :key="chart.id"
          class="no-border transparent w-100"
        >
          <div class="card-content">
            <div class="multibar">
              <p class="start subtitle">{{ chart.title }}</p>
            </div>

            <div class="containerChart xxl">
              <ChartLine
                v-if="chart.datas"
                :id-chart="chart.id"
                :data="chart.datas"
                height="550"
              />
            </div>
          </div>
        </article>
      </template>
    </section>
  </div>
</template>

<script>
/* eslint-disable max-len */
/* eslint-disable-next-line */
import colorsMixins from '@jack/addons/common/$mixins/colors.mixins';
import dashboardColumnsMixins from './$mixins/TheDashboardColumns.mixins.columns';
import dashboardFunctionsMixins from './$mixins/TheDashboardFunctions.mixins.functions';

export default {
  name: 'TheDashboardAccountView',
  mixins: [
    dashboardColumnsMixins,
    dashboardFunctionsMixins,
    colorsMixins,
  ],
  props: {
    // accountDashboard: { type: Object, required: true },
    // loading: { type: Boolean, required: true },
  },
  data() {
    return {
      memberSelected: [],
    };
  },
  computed: {
    dashboardByUser() {
      const {
        users, payments, appointments, clients, projects,
      } = this.accountDashboard;

      if (!users || !users.length) return null;

      return users.reduce((acc, user) => {
        if (this.memberSelected.length) {
          if (!this.memberSelected.find(({ uid: _uid }) => Number(_uid) === Number(user.uid))) return acc;
        }
        acc.push({
          ...user,
          payments: payments && payments.length
            ? payments.filter(({ userUid }) => Number(userUid) === Number(user.uid)) : null,
          appointments: appointments && appointments.length
            ? appointments.filter(({ userUid }) => Number(userUid) === Number(user.uid)) : null,
          clients: clients && clients.length
            ? clients.filter(({ userUid }) => Number(userUid) === Number(user.uid)) : null,
          projects: projects && projects.length
            ? projects.filter(({ userUid }) => Number(userUid) === Number(user.uid)) : null,
        });

        return acc;
      }, []);
    },
    userList() {
      if (!this.accountDashboard.users) return null;
      return this.accountDashboard.users.arrayMapper('uid');
    },
    cardTurnover() {
      if (!this.dashboardByUser) return null;

      return this.financialFormattingMultiple('payments');
    },
    cardClient() {
      if (!this.dashboardByUser) return null;

      return this.entityFormattingMultiple('clients');
    },
    cardProjects() {
      if (!this.dashboardByUser) return null;

      return this.entityFormattingMultiple('projects');
    },
    cardsChart() {
      return [
        {
          title: 'View of all team payments', id: 'paymentsTeam', datas: this.cardPaymentsTeamChartLine, visible: true,
        },
        {
          title: 'View of all team clients', id: 'clientsTeam', datas: this.cardClientsTeamChartLine, visible: true,
        },
        {
          title: 'View of all team projects', id: 'projectsTeam', datas: this.cardProjectsTeamChartLine, visible: true,
        },
      ];
    },
    cardPaymentsTeamChartLine() {
      if (!this.accountDashboard.payments || !this.accountDashboard.payments.length) return null;

      return this.graphFormattingDataset('payments', 'financial');
    },
    cardClientsTeamChartLine() {
      if (!this.accountDashboard.clients || !this.accountDashboard.clients.length) return null;

      return this.graphFormattingDataset('clients', 'default');
    },
    cardProjectsTeamChartLine() {
      if (!this.accountDashboard.projects || !this.accountDashboard.projects.length) return null;

      return this.graphFormattingDataset('projects', 'default');
    },
  },
  methods: {
    seed(str) {
      if (!str) return 0;
      let nb = 0;
      for (let i = 0; i < str.length; i += 1) {
        nb += str.charCodeAt(i);
      }
      const x = Math.sin(nb / str.length) * 10000;
      return x - Math.floor(x);
    },
    entityFormattingMultiple(entity) {
      const dataReturn = {
        lastMonth: 0, currentMonth: 0, datasCurrentMonth: [], datasLastMonth: [],
      };

      this.dashboardByUser.forEach((user) => {
        const {
          lastMonth, currentMonth, datasCurrentMonth, datasLastMonth,
        } = this.entityFormatting(this.formatDataCurrentAndLastMonth(user[entity]));

        dataReturn.lastMonth += lastMonth;
        dataReturn.currentMonth += currentMonth;

        if (entity === 'payments') {
          dataReturn.datasCurrentMonth.push(datasCurrentMonth);
          dataReturn.datasLastMonth.push(datasLastMonth);
        }
      });

      dataReturn.percent = (dataReturn.currentMonth / dataReturn.lastMonth) * 100 - 100;

      return dataReturn;
    },
    financialFormattingMultiple(entity) {
      const dataReturn = {
        lastMonth: 0, currentMonth: 0, datasCurrentMonth: [], datasLastMonth: [],
      };

      this.dashboardByUser.forEach((user) => {
        const {
          lastMonth, currentMonth, datasCurrentMonth, datasLastMonth,
        } = this.financialDataFormatting(this.formatDataCurrentAndLastMonth(user[entity]));

        dataReturn.lastMonth += lastMonth;
        dataReturn.currentMonth += currentMonth;
        dataReturn.datasCurrentMonth.push(datasCurrentMonth);
        dataReturn.datasLastMonth.push(datasLastMonth);
      });

      dataReturn.percent = (dataReturn.currentMonth / dataReturn.lastMonth) * 100 - 100;

      return dataReturn;
    },
    graphFormattingDataset(entity, type) {
      if (!this.accountDashboard[entity] || !this.accountDashboard[entity].length) return null;

      return {
        labels: this.labelOfCurrentAndLastMonth,
        datasets: this.dashboardByUser.reduce((acc, dashboard) => {
          const {
            firstName, lastName,
          } = dashboard;

          const fullName = `${firstName} ${lastName}`;
          const indexColor = this.formatIndexColor(fullName);

          const formatEntity = type === 'default'
            ? this.formatDataCurrentAndLastMonth(dashboard[entity])
            : this.financialDataFormatting(this.formatDataCurrentAndLastMonth(dashboard[entity]));

          acc.push(
            {
              label: `${fullName} (Last month)`,
              data: type === 'default'
                ? this.entityDataGraph(formatEntity.lastMonth)
                : this.financialDataGraph(formatEntity.datasLastMonth),
              backgroundColor: ['rgba(0, 0, 0, 0)'],
              borderColor: [`rgba(${this.colors[indexColor]}, 0.25)`],
              spanGaps: true,
              fill: true,
            },
            {
              label: `${dashboard.firstName} ${dashboard.lastName} (Current month)`,
              data: type === 'default'
                ? this.entityDataGraph(formatEntity.currentMonth)
                : this.financialDataGraph(formatEntity.datasCurrentMonth),
              backgroundColor: ['rgba(0, 0, 0, 0)'],
              borderColor: [`rgba(${this.colors[indexColor]}, 1)`],
              spanGaps: true,
              fill: true,
            },
          );
          return acc;
        }, []),
      };
    },
    formatIndexColor(fullName) {
      return Math.floor(this.seed(fullName) * (12 - 1) + 1);
    },
  },
};
</script>
