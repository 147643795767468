/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable max-len */
import { navySeal } from '@jack/provider';
import { isActionAllowed } from '@jack/dataStores/$utils/helpers';

export default {
  namespaced: true,
  state: {
    contactHistory: {
      company: {},
      person: {},
    },
  },
  mutations: {
    SET_CONTACT_HISTORY_LIST(state, { entite, newData }) {
      state.contactHistory[entite.toLowerCase()] = newData.reduce((acc, { chid, ...rest }) => {
        acc[chid] = { chid, ...rest };
        return acc;
      }, {});
    },
    UPDATE_CONTACT_HISTORY_LIST(state, { entite, newData }) {
      state.contactHistory[entite.toLowerCase()] = {
        ...state.contactHistory[entite.toLowerCase()],
        [newData.chid]: { ...newData },
      };
    },
    DELETE_CONTACT_HISTORY_IN_LIST(state, { entite, newData }) {
      state.contactHistory[entite.toLowerCase()] = Object.values(state.contactHistory[entite.toLowerCase()])
        .reduce((acc, cch) => {
          if (newData.chid === cch.chid) return acc;
          acc[cch.chid] = { ...cch };
          return acc;
        }, {});
    },
  },
  getters: {
    gettersCompanyContactHistoryList: (state) => state.contactHistory.company,
    gettersPersonContactHistoryList: (state) => state.contactHistory.person,
  },
  actions: {
    report({ commit, rootGetters, dispatch }, { payload, httpQuery = {}, options = {} }) {
      if (!isActionAllowed(rootGetters, 'CONTACT_HISTORY.REPORT', dispatch)) return undefined;
      const { entite } = options;
      return navySeal.apiWsMi.contactHistory.report({ payload, httpQuery })
        .then((contactHistory) => {
          commit('SET_CONTACT_HISTORY_LIST', { entite, newData: contactHistory });
        });
    },
    create({ commit, rootGetters, dispatch }, { payload, httpQuery = {}, options = {} }) {
      if (!isActionAllowed(rootGetters, 'CONTACT_HISTORY.CREATE', dispatch)) return undefined;
      const { entite } = options;

      return navySeal.apiWsMi.contactHistory.post({ payload, httpQuery })
        .then((contactHistory) => {
          const { 'Company/getterCompany': company } = rootGetters;
          const { 'Person/getterPerson': person } = rootGetters;
          let cch;

          switch (entite) {
            case 'COMPANY':
              if (payload.companyCid) {
                const { employees } = company;

                cch = contactHistory.personPid
                  ? { ...contactHistory, person: employees.find(({ pid }) => Number(contactHistory.personPid) === Number(pid)) }
                  : contactHistory;
              }
              break;
            case 'PERSON':
              if (payload.personPid) cch = { ...contactHistory, person };
              break;
            default:
              break;
          }
          commit('UPDATE_CONTACT_HISTORY_LIST', { entite, newData: cch });
        });
    },
    delete({ commit, rootGetters, dispatch }, { payload, httpQuery = {}, options = {} }) {
      if (!isActionAllowed(rootGetters, 'CONTACT_HISTORY.DELETE', dispatch)) return undefined;
      const { entite } = options;
      return navySeal.apiWsMi.contactHistory.delete({ payload, httpQuery })
        .then(() => { commit('DELETE_CONTACT_HISTORY_IN_LIST', { entite, newData: { chid: payload.chid } }); });
    },
  },
};
