<template>
  <div class="is-flex is-align-items-center is-justify-content-space-between">
    <p class="has-text-secondary disclamer m-0">{{ stepDetail }}</p>
    <div class="buttons">
      <b-button
        v-if="prev"
        label="Previous"
        class="is-ghost m-0"
        icon-pack="fas"
        icon-left="arrow-left"
        :disabled="localActiveStep === 0"
        @click="localActiveStep === 1 ? localActiveStep = 0 : localActiveStep -= 1"
      />
      <b-button
        v-if="next"
        label="Next"
        class="is-ghost m-0"
        icon-pack="fas"
        icon-right="arrow-right"
        :disabled="!nextIsAllowed"
        @click="localActiveStep += 1"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'JackStepControls',
  props: {
    activeStep: {
      type: Number,
      required: true,
    },
    stepDetail: {
      type: String,
      required: true,
    },
    nextIsAllowed: {
      type: Boolean,
      required: true,
    },
    prev: {
      type: Boolean,
      default: true,
    },
    next: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    localActiveStep: {
      get() { return this.activeStep; },
      set(newValue) { this.$emit('update:activeStep', newValue); },
    },
  },
};
</script>
