/* eslint-disable import/no-extraneous-dependencies */
import { navySeal } from '@jack/provider';
import { isActionAllowed } from '@jack/dataStores/$utils/helpers';

export default {
  namespaced: true,
  state: {
    projectList: [],
    project: {},
  },
  mutations: {
    SET_PROJECT_LIST(state, newData) { state.projectList = newData; },
    SET_PROJECT(state, newData) { state.project = newData; },
  },
  getters: {
    getterProjectList: (state) => state.projectList,
    getterProject: (state) => state.project,
  },
  actions: {
    report({ commit, rootGetters, dispatch }, { payload, httpQuery }) {
      if (!isActionAllowed(rootGetters, 'PROJECT.REPORT', dispatch)) return undefined;

      return navySeal.apiWsJoe.client.project.report({ payload, httpQuery })
        .then((projects) => {
          commit('SET_PROJECT_LIST', projects.map(({ details, ...rest }) => ({ ...rest, ...details })));
        });
    },
    get({ commit, rootGetters, dispatch }, { payload }) {
      if (!isActionAllowed(rootGetters, 'PROJECT.GET', dispatch)) return undefined;

      return navySeal.apiWsJoe.client.project.get({ payload, httpQuery: {} })
        .then((project) => {
          commit('SET_PROJECT', project);
        });
    },
    post({ dispatch, commit, rootGetters }, { payload = {}, httpQuery = {}, options = {} }) {
      if (!isActionAllowed(rootGetters, 'PROJECT.CREATE', dispatch)) return undefined;

      return navySeal.apiWsJoe.client.project.post({ payload, httpQuery })
        .then((results) => {
          const {
            event: {
              eid, accountAid: aid, uid, projectPid: pid, type, createdAt,
            },
            project,
          } = results;

          const {
            isOpen,
            createdAt: projectCreatedAt,
            details: { projectPid, ...details },
          } = project;

          const newEvent = {
            eid,
            aid,
            uid,
            createdAt,
            type,
            projectPid: pid,
            project: {
              ...details,
              createdAt: projectCreatedAt,
              isOpen,
            },
            cid: null,
            client: null,
            appointment: null,
            appointmentAid: null,
            paymentPid: null,
            sid: null,
            payment: null,
            spend: null,
          };

          const { 'Company/getterCompany': company } = rootGetters;
          const { 'Person/getterPerson': person } = rootGetters;

          switch (options.entite) {
            case 'COMPANY':
              if (company.projects) company.projects.push(project);
              else company.projects = [project];
              commit('Company/SET_COMPANY', company, { root: true });
              break;
            case 'PERSON':
              if (person.projects) person.projects.push(project);
              else person.projects = [project];
              commit('Person/SET_PERSON', person, { root: true });
              break;
            default:
              break;
          }

          dispatch('User/eventsView/up_addEventEventsViewList', newEvent, { root: true });
          commit('SET_PROJECT', project);
        });
    },
    put({ commit, rootGetters, dispatch }, { payload }) {
      if (!isActionAllowed(rootGetters, 'PROJECT.UPDATE', dispatch)) return undefined;

      return navySeal.apiWsJoe.client.project.put({ payload, httpQuery: {} })
        .then((project) => {
          commit('SET_PROJECT', project);
        });
    },
    delete({ commit, rootGetters, dispatch }, { payload }) {
      if (!isActionAllowed(rootGetters, 'PROJECT.DELETE', dispatch)) return undefined;

      return navySeal.apiWsJoe.client.project.delete({ payload, httpQuery: {} })
        .then(() => {
          const { 'Company/getterCompany': company } = rootGetters;

          if (company && company.projects) {
            const newProject = company.projects.reduce((acc, project) => {
              if (Number(project.pid) !== Number(payload.pid)) acc.push(project);
              return acc;
            }, []);
            company.projects = newProject.length ? newProject : null;

            commit('Company/SET_COMPANY', company, { root: true });
          }
        });
    },
  },
};
