<template>
  <div class="notes-textarea-container">
    <div class="notes-textarea">
      <textarea-autosize
        ref="myTextarea"
        v-model="localValue"
        class="text is-3 mb-3"
        placeholder="Type something here..."
        :min-height="85"
        :max-height="85"
        @keypress.ctrl.enter.native="$emit('addNote')"
      />

      <br>

      <div class="multibar">
        <div class="buttons end">
          <b-button
            type="is-tertiary"
            icon-pack="fa"
            icon-left="paper-plane"
            :disabled="!localValue"
            @click="$emit('addNote')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NoteTextarea',
  props: {
    value: { type: String, required: true },
  },
  computed: {
    localValue: {
      get() { return this.value; },
      set(newValue) { this.$emit('update:value', newValue); },
    },
  },
};
</script>
