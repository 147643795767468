export default {
  data() {
    return {
      columnsClient: {
        cid: {
          col: 'cid', label: 'cid', visible: false, isSortable: false, cmp: 'flat',
        },
        action: {
          label: this.$_('Action'), visible: true, isSortable: false, event: 'openDetails', buttonType: 'tertiary', icon: 'eye', type: 'is-ghost', width: 20, centered: true,
        },
        name: {
          col: 'name', label: this.$_('Name'), visible: true, isSortable: false, cmp: 'flat',
        },
        number: {
          col: 'number', label: this.$_('Number'), visible: true, isSortable: false, cmp: 'flat',
        },
        createdAt: {
          col: 'createdAt', label: this.$_('Created on'), visible: true, isSortable: false, cmp: 'date',
        },
      },
      columnsProjetc: {
        pid: {
          col: 'pid', label: 'pid', visible: false, isSortable: false, cmp: 'flat',
        },
        clientCid: {
          col: 'clientCid', label: 'Client ID', visible: false, isSortable: false, cmp: 'flat',
        },
        action: {
          col: 'action', label: this.$_('Action'), visible: true, isSortable: false, event: 'openDetails', buttonType: 'tertiary', icon: 'eye', type: 'is-ghost', width: 20, centered: true,
        },
        isOpen: {
          col: 'isOpen', label: this.$_('Project completed'), visible: true, isSortable: true, cmp: 'isOpen', width: 160, centered: true,
        },
        label: {
          col: 'label', label: this.$_('Label'), visible: true, isSortable: true, cmp: 'flat',
        },
        price: {
          col: 'price', label: this.$_('Price'), visible: true, isSortable: true, cmp: 'symbol', symbol: '€', centered: true,
        },
        createdAt: {
          col: 'createdAt', label: this.$_('Created on'), visible: true, isSortable: true, cmp: 'date',
        },
      },
    };
  },
};
