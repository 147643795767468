<template>
  <div>
    MenuAdd
  </div>
</template>

<script>
export default {
  name: 'MenuAddProject',
};
</script>
