/* eslint-disable import/no-extraneous-dependencies */
import { navySeal } from '@jack/provider';
import { isActionAllowed } from '@jack/dataStores/$utils/helpers';

export default {
  namespaced: true,
  state: {
    tagList: {},
  },
  mutations: {
    SET_TAG_LIST(state, newData) { state.tagList = newData; },
    UPDATE_TAG_IN_TAG_LIST(state, newData) {
      state.tagList[newData.tid] = { ...state.tagList[newData.tid], ...newData };
    },
    ADD_TAG_IN_TAG_LIST(state, newData) {
      state.tagList = { ...state.tagList, [newData.tid]: newData };
    },
  },
  getters: {
    getterTagList: (state) => state.tagList,
  },
  actions: {
    post({ commit, rootGetters, dispatch }, { payload = {}, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'TAG.CREATE', dispatch)) return undefined;
      return navySeal.apiWsJoe.tag.post({ payload, httpQuery })
        .then((tag) => { commit('ADD_TAG_IN_TAG_LIST', tag); return tag; });
    },
    patch({ commit, rootGetters, dispatch }, { payload = {}, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'TAG.PATCH', dispatch)) return undefined;
      return navySeal.apiWsJoe.tag.patch({ payload, httpQuery })
        .then((tag) => { commit('UPDATE_TAG_IN_TAG_LIST', tag); return tag; });
    },
    delete({
      commit, rootGetters, dispatch, getters,
    }, { payload = {}, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'TAG.DELETE', dispatch)) return undefined;
      return navySeal.apiWsJoe.tag.delete({ payload, httpQuery })
        .then(() => {
          const { getterTagList: tagList } = getters;

          const _tagList = Object.values(tagList).reduce((acc, tag) => {
            const { tid } = tag;
            if (Number(tid) === Number(payload.tid)) return acc;
            if (acc[tid]) return acc;
            acc[tid] = tag;
            return acc;
          }, {});

          commit('SET_TAG_LIST', _tagList);
        });
    },
  },
  modules: {},
};
