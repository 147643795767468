<template>
  <section
    class="logo-animated-container"
  >
    <div class="logo-animated">
      <object
        type="image/svg+xml"
        :data="theme ? logo.white : logo.black"
      />
    </div>
    <p class="subtitle is-small has-text-secondary">
      LOADING...
    </p>
  </section>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */
import { mapGetters } from 'vuex';

const logoWhiteAnimated = require('@jack/public/assets/images/icons/DEVMOOD_LOGO_WHITE_ANIMATED.svg');
const logoBlackAnimated = require('@jack/public/assets/images/icons/DEVMOOD_LOGO_BLACK_ANIMATED.svg');

export default {
  name: 'LogoAnimated',
  data() {
    return {
      logo: {
        white: logoWhiteAnimated,
        black: logoBlackAnimated,
      },
    };
  },
  computed: {
    ...mapGetters({
      theme: 'Starter/getterTheme',
    }),
  },
};
</script>
