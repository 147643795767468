<template>
  <section>
    <div class="multibar">
      <h3 class="start title is-medium m-0">
        Confirm new project
      </h3>
      <div class="buttons end">
        <b-button
          class="is-primary"
          label="Create new project"
          icon-pack="fas"
          icon-left="plus"
          @click="$emit('createNewProject')"
        />
      </div>
    </div>

    <br>

    <fieldset>
      <div class="card no-border">
        <div class="card-content">
          <p class="subtitle is-big">Project</p>
          <b-field label="Label">
            <b-input
              v-model="newProject.details.label"
              placeholder="Label"
              required
              readonly
            />
          </b-field>

          <br>

          <template v-if="newProject.details.description">
            <b-field label="Description">
              <b-input
                v-model="newProject.details.description"
                type="textarea"
                placeholder="Description"
                required
                readonly
              />
            </b-field>

            <br>
          </template>

          <b-field label="Price">
            <b-numberinput
              v-model="newProject.details.price"
              icon-pack="fas"
              :controls="false"
              required
              readonly
              disabled
            />
          </b-field>
        </div>
      </div>

      <br>

      <div
        v-if="newProject.cid"
        class="card no-border"
      >
        <div class="card-content">
          <p class="subtitle is-big">Client</p>

          <b-field label="First name">
            <b-input
              v-model="newProject.cid.firstName"
              placeholder="First name"
              required
              readonly
            />
          </b-field>

          <br>

          <b-field label="Last name">
            <b-input
              v-model="newProject.cid.lastName"
              placeholder="Last name"
              required
              readonly
            />
          </b-field>

          <br>

          <b-field label="Phone number">
            <b-input
              v-model="newProject.cid.number"
              placeholder="Phone number"
              required
              readonly
            />
          </b-field>
        </div>
      </div>
    </fieldset>

    <br>

    <div class="buttons is-right">
      <b-button
        class="is-primary"
        label="Create new project"
        icon-pack="fas"
        icon-left="plus"
        @click="$emit('createNewProject')"
      />
    </div>
  </section>
</template>

<script>
export default {
  name: 'JackSidePanelAddProjectConfirmation',
  model: {
    prop: 'newProject',
  },
  props: {
    newProject: { type: Object, default: () => {} },
  },
};
</script>
