<template>
  <b-collapse
    v-if="open"
    :open="open"
    aria-id="contentAppointmentId"
    class="collapse"
  >
    <div class="collapse-content">
      <div class="content">
        <form>
          <b-field :label="$_('Label')">
            <b-input
              v-model="project.label"
              required
            />
          </b-field>

          <br>

          <b-field :label="$_('Description')">
            <b-input
              v-model="project.description"
              type="textarea"
            />
          </b-field>

          <br>

          <div class="is-flex is-align-items-center is-justify-content-space-between">
            <b-field :label="$_('Start date')">
              <b-datepicker
                v-model="project.startDate"
                position="is-bottom-right"
              />
            </b-field>

            <br>

            <b-field :label="$_('End date')">
              <b-datepicker
                v-model="project.endDate"
                position="is-bottom-left"
              />
            </b-field>
          </div>

          <br>

          <b-field :label="$_('Conditions')">
            <b-select
              v-model="project.condition"
              :placeholder="$_('Fixed, per day, per week...')"
            >
              <option
                v-for="option in conditions"
                :key="option.key"
                :value="option.key"
              >
                {{ option.name }}
              </option>
            </b-select>
          </b-field>

          <br>

          <div class="field">
            <label class="label">Price</label>
            <div class="number-grouped-select">
              <b-select
                v-model="project.currency"
                class="select-no-border"
                placeholder="€"
              >
                <option
                  v-for="option in currencies"
                  :key="option.key"
                  :value="option.key"
                >
                  {{ option.name }}
                </option>
              </b-select>
              <b-numberinput
                v-model="project.price"
                required
                expanded
                min="0"
                icon-pack="fas"
                :controls="false"
              />
            </div>
          </div>

          <br>

          <div class="end buttons">
            <b-button
              type="is-primary"
              icon-pack="fa"
              icon-right="check"
              expanded
              @click="addProject()"
            />
          </div>
        </form>
      </div>
    </div>
  </b-collapse>
</template>

<script>
import moment from 'moment';

export default {
  name: 'AddProject',
  props: {
    open: { type: Boolean, required: true },
    client: { type: Object, default: () => {} },
    conditions: { type: Array, required: true },
    currencies: { type: Array, required: true },
  },
  data() {
    return {
      project: {
        urlImage: null,
        label: null,
        description: null,
        price: 0,
        condition: null,
        currency: 'EUR',
        startDate: null,
        endDate: null,
        state: 'in-progress',
      },
      avanced: false,
    };
  },
  methods: {
    addProject() {
      this.$emit('addProject', {
        isOpen: true,
        createdAt: moment().format(),
        details: { ...this.project },
      });
    },
  },
};
</script>
