<template>
  <article>
    <template v-if="isClickable">
      <router-link
        :to="`/networking/contacts/${pid}/details`"
        class="contact-card is-clickable"
      >
        <div
          v-if="details.imageUrl"
          :class="['profile-picture item size', {
            'is-8x8': size === 8,
            'is-16x16': size === 16,
            'is-18x18': size === 18,
            'is-24x24': size === 24,
            'is-32x32': size === 32,
            'is-40x40': size === 40,
            'is-48x48': size === 48,
            'is-64x64': size === 64,
            'is-96x96': size === 96,
            'is-128x128': size === 128,
          }]"
          :style="`background:url('${details.imageUrl}')`"
        />
        <InitialsPicture
          v-else
          :name="details.fullName"
          class="item"
          :size="size"
        />
        <div class="ml-5">
          <p class="subtitle is-big cursor-pointer p-0 m-0">
            {{ details.fullName }}
          </p>
          <p class="disclamer is-small has-text-secondary m-0">
            {{ details.location }}
          </p>
          <p class="subtext is-small has-text-secondary">
            {{ details.jobTitle }}
          </p>
        </div>
      </router-link>
    </template>
    <template v-else>
      <div class="contact-card">
        <div
          v-if="details.imageUrl"
          :class="['profile-picture item size', {
            'is-8x8': size === 8,
            'is-16x16': size === 16,
            'is-18x18': size === 18,
            'is-24x24': size === 24,
            'is-32x32': size === 32,
            'is-40x40': size === 40,
            'is-48x48': size === 48,
            'is-64x64': size === 64,
            'is-96x96': size === 96,
            'is-128x128': size === 128,
          }]"
          :style="`background:url('${details.imageUrl}')`"
        />
        <InitialsPicture
          v-else
          :name="details.fullName"
          class="item"
          :size="size"
        />
        <div class="ml-5">
          <p
            v-if="details.fullName"
            class="subtitle is-big p-0 m-0"
          >
            {{ details.fullName }}
          </p>
          <p
            v-if="details.location"
            class="disclamer is-small has-text-secondary m-0"
          >
            {{ details.location }}
          </p>
          <p
            v-if="details.jobTitle "
            class="subtext is-small has-text-secondary"
          >
            {{ details.jobTitle }}
          </p>
        </div>
      </div>
    </template>
  </article>
</template>

<script>
export default {
  name: 'Contact',
  props: {
    pid: { type: Number, default: 0 },
    details: { type: Object, required: true },
    size: { type: Number, default: 40 },
    to: { type: Boolean, default: true },
    isClickable: { type: Boolean, default: true },
  },
};
</script>
