<template>
  <section v-if="retrieveEventsOfTheDay.length">
    <h3 class="title is-small">Timeline</h3>
    <JackSidePanelSummaryEventCardItem
      v-for="(eventDate, i) in retrieveEventsOfTheDay"
      :key="i"
      :event="eventDate"
      :path="path"
    />
  </section>
  <section
    v-else
    class="sidePannelSummary"
  >
    <NoData
      title="No events... Yet!"
      content="You have not registered an event for this day"
    />
  </section>
</template>

<script>
export default {
  name: 'JackSidePanelSummary',
  props: {
    retrieveEventsOfTheDay: { type: Array, required: true },
    path: { type: String, required: true },
  },
};
</script>
