<template>
  <div
    class="jackFullCalendarTooltip"
    :style="`top: ${eventTooltip.y}px; left: calc(${eventTooltip.x}px - 350px)`"
  >
    <div class="content">
      <p
        v-if="eventTooltip.type"
        class="subtext has-text-secondary ml-1"
      >
        {{ eventTooltip.type.capitalize() }}
      </p>
      <div class="is-flex is-align-items-start">
        <b-icon
          v-if="eventTooltip.icon"
          :pack="eventTooltip.pack"
          :icon="eventTooltip.icon"
        />
        <div class="ml-3">
          <p class="lead m-0">{{ eventTooltip.title }}</p>
          <p class="text is-small has-text-secondary">
            {{ eventTooltip.description }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'JackFullCalendarTooltip',
  props: {
    eventTooltip: { type: Object, required: true },
  },
};
</script>
