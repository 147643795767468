export default {
  data() {
    return {
      columns: {
        pid: {
          col: 'pid', label: 'pid', visible: false, isSortable: false, cmp: 'flat',
        },
        clientCid: {
          col: 'clientCid', label: 'Client ID', visible: false, isSortable: false, cmp: 'flat',
        },
        action: {
          col: 'action', label: 'Actions', visible: true, isSortable: false, event: 'openDetails', buttonType: 'tertiary', icon: 'eye', type: 'is-ghost', width: 20, centered: true,
        },
        fullName: {
          col: 'fullName', label: 'Full name', visible: true, isSortable: true, cmp: 'flat',
        },
        debtor: {
          col: 'debtor', label: 'Debtor', visible: true, isSortable: true, cmp: 'bool', centered: true,
        },
        totalProjects: {
          col: 'totalProjects', label: 'Total projects', visible: true, isSortable: true, cmp: 'flat', centered: true,
        },
        totalPayment: {
          col: 'totalPayment', label: 'Total payment', visible: true, isSortable: true, cmp: 'flat', centered: true,
        },
        createdAt: {
          col: 'createdAt', label: 'Created at', visible: true, isSortable: true, cmp: 'date',
        },
      },
    };
  },
};
