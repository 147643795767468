/* eslint-disable max-len */
import { SnackbarProgrammatic as Snackbar } from 'buefy';
import { dispatchAndForward, dispatchFailure, snackBarFailure } from '../$utils/helpers';

export default {
  getters: {
    personList: (_state, _getters, _rootState, rootGetters) => {
      const { 'Person/getterPersonList': personList } = rootGetters;
      return personList;
    },
    person: (_state, _getters, _rootState, rootGetters) => {
      const { 'Person/getterPerson': person } = rootGetters;
      return person;
    },
    searchPerson: (_state, _getters, _rootState, rootGetters) => {
      const { 'Person/getterSearchPerson': searchPerson } = rootGetters;
      return searchPerson;
    },
  },
  actions: {
    reportPersonList({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'Person/report',
        context: { payload, httpQuery },
        config: {
          start: false,
          end: false,
          endContent: 'Person reported successfully',
          error: 'Unable to report person.',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    searchPersonList({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'Person/search',
        context: { payload, httpQuery },
        config: {
          start: false,
          end: false,
          endContent: 'Person searched successfully',
          error: 'Unable to search person.',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    getPerson({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'Person/get',
        context: { payload, httpQuery },
        config: {
          start: false,
          end: false,
          endContent: 'Person getted successfully',
          error: 'Unable to get person.',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    bulkInsertPersons({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'Person/bulkInsertPersons/create',
        context: { payload, httpQuery },
        config: {
          start: false,
          end: true,
          endContent: 'Persons added successfully',
          error: 'Unable to add persons.',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    createPerson({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'Person/create',
        context: { payload, httpQuery },
        config: {
          start: false,
          end: true,
          endContent: 'Persons created successfully',
          error: 'Unable to create persons.',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    updatePerson({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'Person/update',
        context: { payload, httpQuery },
        config: {
          start: false,
          end: true,
          endContent: 'Persons updated successfully',
          error: 'Unable to update persons.',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    patchPerson({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'Person/patch',
        context: { payload, httpQuery },
        config: {
          start: false,
          end: true,
          endContent: 'Persons patched successfully',
          error: 'Unable to patche persons.',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    deletePerson({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'Person/delete',
        context: { payload, httpQuery },
        config: {
          start: false,
          end: true,
          endContent: 'Persons deleted successfully',
          error: 'Unable to delete persons.',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    addPersonTag({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'Person/tag/post',
        context: { payload, httpQuery },
        config: {
          start: false,
          end: true,
          endContent: 'Tag linked to person successfully',
          error: 'Unable to link a tag to the person.',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    removePersonTag({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'Person/tag/delete',
        context: { payload, httpQuery },
        config: {
          start: false,
          end: true,
          endContent: 'Tag unlinked to person successfully',
          error: 'Unable to unlink a tag to the person.',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
  },
};
