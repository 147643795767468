<template>
  <aside class="jackStyleGuideMenu">
    <section
      v-for="chapter in Object.keys(menuItemsFormatted)"
      :key="chapter"
      class="chapterMenu"
    >
      <h3>{{ chapter.toUpperCase() }}</h3>

      <ul class="menuList">
        <li
          v-for="item in menuItemsFormatted[chapter]"
          :key="item.text"
          class="menuItem"
        >
          <router-link :to="item.path">{{ item.text }}</router-link>
        </li>
      </ul>
    </section>
  </aside>
</template>

<script>
export default {
  name: 'TheStyleGuildeMenu',
  props: {
    menuItems: {
      type: Array,
      required: true,
    },
  },
  computed: {
    menuItemsFormatted() {
      return this.menuItems.reduce((acc, { chapter, ...rest }) => {
        if (!acc[chapter]) {
          acc[chapter] = [
            { ...rest },
          ];
          return acc;
        }

        acc[chapter].push({ ...rest });
        return acc;
      }, {});
    },
  },
};
</script>
