<template>
  <section>
    <div class="is-flex is-align-items-center is-justify-content-flex-end gap-8">
      <b-tooltip
        v-if="type === 'person'"
        type="is-dark"
        :label="!openScheduleAppointment ? $_('Generate an appointment link') : $_('Close')"
        position="is-left"
      >
        <b-button
          type="is-tertiary"
          :icon-pack="!openScheduleAppointment ? 'fa-regular' : 'fa'"
          :icon-left="!openScheduleAppointment ? 'calendar-plus' : 'xmark'"
          expanded
          @click="openScheduleAppointment = !openScheduleAppointment; openNewAppointment = false"
        />
      </b-tooltip>
      <b-tooltip
        type="is-dark"
        :label="!openNewAppointment ? $_('Add an appointment') : $_('Close')"
        position="is-left"
      >
        <b-button
          type="is-tertiary"
          icon-pack="fa"
          :icon-left="!openNewAppointment ? 'plus' : 'xmark'"
          expanded
          :class="[{
            'turn-signal': !entite.appointments
          }]"
          @click="openScheduleAppointmentForm()"
        />
      </b-tooltip>
    </div>
    <DevDetails :cnt="{name: 'appointmentSorted', obj: appointmentSorted}" />

    <div class="company-appointment">
      <div class="appointments-container">
        <AddAppointment
          v-if="openNewAppointment"
          :open="openNewAppointment"
          :person-list="personList || []"
          :client="client"
          :options="options"
          @addAppointment="addAppointment($event)"
        />
        <template v-else-if="openScheduleAppointment">
          <FormScheduledAppointment
            v-if="type === 'person'"
            :entite="entite"
            :appointment-token="appointmentToken"
            @update:openModal="openScheduleAppointment = $event"
            @genreateAppointmentLink="genreateAppointmentLink($event)"
            @resetAppointmentToken="$emit('resetAppointmentToken')"
          />
        </template>
        <AppointmentList
          v-else
          :entite="entite"
          :appointments-list="appointmentSorted"
          :appointment-tokens="appointmentTokens"
          @deleteAppointment="$emit('deleteAppointment', $event)"
          @changeStateAppointment="$emit('changeStateAppointment', $event)"
        />
      </div>
    </div>
  </section>
</template>

<script>
/* eslint-disable max-len */
import moment from 'moment';

export default {
  name: 'Appointment',
  props: {
    entite: { type: Object, required: true },
    appointmentToken: { type: Object, required: true },
    type: { type: String, required: true },
    personList: { type: Array, default: () => [] },
    options: { type: Object, default: () => ({ search: true }) },
  },
  data() {
    return {
      openNewAppointment: false,
      openScheduleAppointment: false,
      over: false,
    };
  },
  computed: {
    client() {
      if (!this.entite.client) return null;
      return this.entite.client;
    },
    employees() {
      if (!this.entite.employees || !this.entite.employees.length) return null;
      return this.entite.employees;
    },
    projects() {
      if (!this.entite.projects || !this.entite.projects.length) return null;
      return this.entite.projects;
    },
    appointmentTokens() {
      if (!this.entite.appointmentTokens || !this.entite.appointmentTokens.length) return null;
      return this.entite.appointmentTokens;
    },
    appointmentSorted() {
      if (!this.entite.appointments) return [];

      const persons = this.employees
        ? this.employees.arrayMapper('pid')
        : null;

      const projects = this.projects
        ? this.projects.arrayMapper('pid')
        : null;

      return this.entite.appointments.reduce((acc, a) => {
        const { personPid, projectPid } = a.details;
        // if (this.over && a.over) return acc;
        acc.push({
          ...a,
          client: this.client,
          person: persons && persons[personPid] ? persons[personPid] : null,
          projects: projects && projects[projectPid] ? projects[projectPid] : null,
          over: moment(a.details.date).isBefore(moment().format()),
        });
        return acc;
      }, []).sort((a, b) => moment(b.details.date).unix() - moment(a.details.date).unix()).reverse();
    },
  },
  methods: {
    addAppointment(event) {
      this.$emit('addAppointment', event);
      this.openNewAppointment = false;
    },
    openScheduleAppointmentForm() {
      this.openNewAppointment = !this.openNewAppointment;
      this.openScheduleAppointment = false;
      this.$emit('resetAppointmentToken');
    },
    genreateAppointmentLink(evt) {
      this.$emit('genreateAppointmentLink', evt);
      this.openScheduleAppointment = false;
    },
  },
};
</script>
