/* eslint-disable object-curly-newline */
/* eslint-disable import/prefer-default-export */
// import { builder } from '../engine';

// const target = 'apiSocials';

const instagram = {
  // get: ({ payload, httpQuery = {} }) => builder({ url: `/${payload.username}/`, method: 'GET', payload, httpQuery: { ...httpQuery, __a: 1, __d: 'dis' }, target }),
};

export {
  instagram,
};
