<template>
  <section>
    <div class="drawer is-content">
      <div class="jackProjectDetailled start">
        <DevDetails :cnt="{name: 'formatProjectList', obj: formatProjectList}" />
        <Table
          v-if="formatProjectList"
          :data="formatProjectList"
          :columns="Object.values(columns)"
          :loading="loading"
          :total="formatProjectList.length"
          :page="page"
          :per-page="perPage"
          :sort-field="sortField"
          :sort-order="sortOrder"
          :default-sort-order="defaultSortOrder"
          :selected.sync="selected"
          :checked-rows.sync="checkedRows"
          :backend-sorting="false"
          :sticky-header="false"
          :checkable="false"
          detail-key="cid"
          @openDetails="openDetails($event)"
        />
      </div>
    </div>

    <router-view name="detailledProjectSidePannel" />
    <router-view name="detailledProjectSidePannelEdit" />
    <router-view name="detailledProjectSidePannelClient" />
  </section>
</template>

<script>
/* eslint-disable max-len */
import TheProjectsDetailledColumns from './$mixins/TheProjectsDetailledColumns.column.mixins';

export default {
  name: 'TheProjectsDetailledView',
  mixins: [TheProjectsDetailledColumns],
  props: {
    userProjectList: { type: Object, required: true },
  },
  data() {
    return {
      loading: false,
      total: 100,
      page: 1,
      perPage: 50,
      sortField: 'cid',
      sortOrder: 'asc',
      defaultSortOrder: 'asc',
      selected: {},
      checkedRows: [],
      currentCampaignViewDetailled: null,
    };
  },
  computed: {
    formatProjectList() {
      if (!this.userProjectList) return [];

      const datas = Object.values(this.userProjectList).reduce((acc, row) => {
        const { details: { projectPid, ...details }, ...rest } = row;
        acc.push({ ...details, ...rest });
        return acc;
      }, []);

      return datas.sort((a, b) => this.$moment(b.createdAt).unix() - this.$moment(a.createdAt).unix());
    },
  },
  methods: {
    openDetails(row) {
      this.$router.push(`/projects/detailled-view/project/${row.pid}`);
    },
  },
};
</script>
