<template>
  <aside
    class="kanban-column selected"
    :style="{top: `${posMouse.y}px`, left: `${posMouse.x}px`}"
  >
    <div class="kanban-column-header">
      <p class="subtext is-small m-0">
        {{ column.label }}
      </p>
    </div>

    <div class="kanban-column-content">
      <hr class="mt-0">

      <template v-if="column.items && column.items.length">
        <KanbanItem
          v-for="(item, indexItem) in column.items"
          :key="item.kiid"
          :kiid="item.kiid"
          :item="item"
          :index="indexItem"
        />
      </template>
    </div>
  </aside>
</template>

<script>
export default {
  name: 'KanbanColumnCopy',
  props: {
    column: { type: Object, required: true },
    posMouse: { type: Object, required: true },
  },
};
</script>
