<template>
  <div>
    <h3 class=" py-6">It seems your invitation link has expired 😢</h3>
    <p class="TheError408_text header1">
      Please, ask the person who invited you to generate a new invitation link.
    </p>
    <router-link
      to="/"
    >
    <!--  -->
    </router-link>
  </div>
</template>

<script>

export default {
  name: 'TheError408',
};
</script>

<style lang="scss">
  .TheError408_text {
    // color: var(--darkgrey);
    padding-bottom: 36px;
  }
</style>
