<template>
  <aside class="jackSidePanel">
    <div class="multibar">
      <div class="end buttons">
        <b-button
          class="is-ghost"
          icon-pack="fas"
          icon-right="times"
          @click="quitBulk()"
        />
      </div>
    </div>

    <br>

    <header>
      <h2 class="title m-0">Bulk actions</h2>
      <p class="text is-small has-text-secondary">
        Please select an action from the available choices to perform a group action
      </p>
    </header>

    <br>

    <template v-if="actions.length && bulkActions.length">
      <article
        v-for="(bulk, i) in bulkActions"
        :key="bulk.cmp"
      >
        <b-button
          :label="bulk.name"
          type="is-dashed-1"
          :icon-pack="bulk.iconPack"
          :icon-left="bulk.icon"
          expanded
          @click="selectBulk(bulk)"
        />

        <br v-if="i !== bulkActions.length - 1">
      </article>

      <component
        :is="bulkSelected.cmp"
        v-if="bulkSelected"
        v-model="bulkSelected.argument"
        :checked-rows="checkedRows"
        :agent="agent || {}"
        @home="goHome()"
        @quit="quitBulk()"
        @launchAgent="launchAgent($event)"
      />
    </template>
  </aside>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */
import { mapActions, mapGetters } from 'vuex';
import SocketMixins from '@jack/socket/$utils/SocketMixins.mixins';
import agentsBulkInformations from '../../$mixins/agentsBulkInformations.mixins';

export default {
  name: 'TheBulkActionsSidePanel',
  mixins: [
    agentsBulkInformations,
    SocketMixins,
  ],
  props: {
    path: { type: String, required: true },
    actions: { type: Array, required: true },
    checkedRows: { type: Array, required: true },
  },
  data() {
    return {
      dev: false,
      bulkSelected: null,
      error: null,
    };
  },
  computed: {
    ...mapGetters({
      agentList: 'TheSidePanel/agentList',
      agent: 'TheJobs/agent',
    }),
    bulkActions() {
      if (!this.actions) return [];

      return this.actions.map((el) => this.bulks[el]);
    },
    agentDB() {
      return Object.values(this.agentList)
        .find(({ scriptId }) => scriptId === this.bulkSelected.scriptId) || {};
    },
  },
  mounted() {
    if (!this.checkedRows || !this.checkedRows.length) this.quitBulk();
  },
  methods: {
    ...mapActions({
      _fetchAgent: 'TheJobs/fetch',
      _launchAgent: 'TheSidePanel/launch',
    }),
    goHome() { this.bulkSelected = null; },
    quitBulk() { this.$router.push({ path: this.path }); },
    selectBulk(bulk) {
      this.bulkSelected = bulk;
      this.fetchAgent();
    },
    fetchAgent() {
      if (!this.agentDB) return;
      const { extrefId: id } = this.agentDB;
      this._fetchAgent({ httpQuery: { id } })
        .then(() => {
          if (!this.agent) return;
          const { argument: pbArgsFetched } = this.agent;
          const { argument: args, defaultValueKeys } = this.bulkSelected;

          const argumentFetched = JSON.parse(pbArgsFetched);

          Object.entries(argumentFetched)
            .forEach(([key, value]) => {
              if (defaultValueKeys.includes(key)) {
                if (key === 'companiesPerLaunch') args.companiesPerLaunch = 250;
                if (key === 'delayBetween') args.delayBetween = 2;
              }
              args[key] = value;
            });
        });
    },
    launchAgent() {
      this.error = null;
      if (!this.agentDB && !this.agentDB.pbaid) {
        this.error = {
          type: 'is-danger',
          content: 'You do not have the ghost that allows you to perform this action. Please contact the technical team for more information.',
        };
        return;
      }

      const payload = {
        pbaid: this.agentDB.pbaid,
        arguments: JSON.stringify(this.bulkSelected.argument),
      };

      if (this.dev) payload.isStub = this.agentDB.scriptId;

      Promise.all([
        this._launchAgent({ payload }),
        this.subscribeStream({
          entite: 'agent',
          id: this.agentDB.pbaid,
          method: 'launchAgent',
          options: { pbaid: this.agentDB.pbaid, insert: 'insertCompany' },
        }),
      ]);
    },
  },
};
</script>
