const isActionAllowed = (rootGetters, right, dispatch) => {
  const { rights } = rootGetters['Authenticate/getterAuthenticate'];
  if (!rights[right]) {
    dispatch(
      'TheWsNotifier/push',
      {
        title: 'Permission denied',
        type: 'danger',
        content: `You are not allowed to do that. ("${right}").`,
      },
      { root: true },
    );
    return false;
  }
  return true;
};

/* eslint-disable no-param-reassign */
const dispatchAndForward = (action) => Promise.resolve()
  .then(() => {
    const { config, dispatch } = action;
    if (!config || !config.start) return;
    dispatch('TheWsNotifier/push', { type: 'info', title: 'In progress', content: config.startContent }, { root: true });
  })
  .then(() => action.dispatch(action.target, action.context, { root: true }))
  .then((response) => {
    if (response) {
      action.response = response;
      const { config, dispatch } = action;
      if (!config || !config.end) return;
      dispatch('TheWsNotifier/push', { type: 'success', title: 'Completed', content: config.endContent }, { root: true });
    }
  })
  .then(() => action);

const dispatchFailure = (action) => Promise.resolve()
  .then(() => {
    if (action.config) {
      action.dispatch(
        'TheWsNotifier/push',
        { type: 'danger', title: 'Request declined', content: action.config.error },
        { root: true },
      );
    }
  });

const snackBarSuccess = {
  type: 'is-success',
  position: 'is-top',
  duration: 5000,
};

const snackBarFailure = {
  type: 'is-danger',
  position: 'is-top',
  duration: 5000,
};

export {
  // eslint-disable-next-line import/prefer-default-export
  isActionAllowed,
  dispatchAndForward,
  dispatchFailure,
  snackBarSuccess,
  snackBarFailure,
};
