<template>
  <Payment
    :entite="person"
    :project-list="person.projects"
    @addPayment="$emit('addPayment', $event)"
    @deletePayment="$emit('deletePayment', $event)"
  />
</template>

<script>
export default {
  name: 'PersonPayments',
  props: {
    person: { type: Object, required: true },
  },
};
</script>
