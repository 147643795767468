<template>
  <section v-if="!loading">
    <header class="multibar">
      <div class="header-actions is-flex is-align-items-center end">
        <b-tooltip
          type="is-dark"
          label="Filters"
          position="is-bottom"
          append-to-body
        >
          <b-button
            type="is-tertiary"
            icon-pack="fa"
            icon-left="filter"
            class="ml-5"
            @click="toggleSidePanelFilters()"
          />
        </b-tooltip>
      </div>
    </header>

    <FilterPanel
      v-if="openFilter"
      :queries="queries"
      :filters="filters"
      @refresh="fetchPersons(true)"
      @close="toggleSidePanelFilters()"
      @clear="clearFilters()"
      @update:by="queries.by = $event"
      @update:search="queries.search = $event"
      @update:location="queries.location = $event"
      @update:hasMail="queries.hasMail = $event"
      @update:hasNumber="queries.hasNumber = $event"
      @update:refreshed="queries.refreshed = $event"
      @update:contactHistory="queries.contactHistory = $event"
      @update:valueTF="queries.valueTF = $event"
      @update:timeFrame="queries.timeFrame = $event"
    />

    <DevDetails :cnt="{name: 'personFiltered', obj: personFiltered}" />

    <template v-if="personFiltered && personFiltered.length">
      <br>
      <div class="row is-justify-content-space-around">
        <article
          v-for="person in personFiltered"
          :key="person.pid"
          :class="['card person is-justify-content-center', {
            'col1': responsive === 1,
            'col2': responsive === 2,
            'col3': responsive === 3,
          }]"
        >
          <div
            v-if="person.backgroundUrl"
            class="background-contact-card-container"
          >
            <div class="background-contact-filter" />
            <div
              class="background-contact-card"
              :style="`background: url('${person.backgroundUrl}');`"
            />
          </div>
          <div class="content is-flex is-align-items-center">
            <div
              v-if="person.imageUrl"
              class="profile-picture item size is-64x64"
              :style="`background:url('${person.imageUrl}');`"
            />
            <InitialsPicture
              v-else
              :name="person.fullName"
              class="item"
            />
            <div class="ml-5">
              <p
                class="subtitle is-big cursor-pointer p-0 m-0"
                @click="selectPerson(person)"
              >
                {{ person.fullName }}
              </p>
              <p class="disclamer is-small has-text-secondary m-0">{{ person.location }}</p>
              <p class="subtext is-small has-text-secondary">{{ person.jobTitle }}</p>
            </div>
          </div>
        </article>
      </div>
      <br>
    </template>
    <NoData
      v-else
      title="No contacts were found... Yet!"
      content="You can fetch new contacts at any time"
      class="noContacts"
    />

    <Paginations
      v-if="personFiltered.length"
      :page="page"
      :offset="Number(queries.pagin.offset)"
      :limit="Number(queries.pagin.limit)"
      :list="personFiltered"
      @prev="prev()"
      @next="next()"
    />

    <div
      v-if="$route.meta.lockBodySidePanel || openFilter"
      class="overlayWithLock"
      @click="quitPanel()"
    />

    <router-view
      name="details"
      @addFavorite="toggleFavorite($event)"
    />
  </section>
  <LogoAnimated
    v-else
    class="h-full"
  />
</template>

<script>
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import filterHttpQueryMixins from '@jack/addons/common/$mixins/filterHttpQuery.mixins';
import { mapActions, mapGetters } from 'vuex';
import engine from './$utils/engine';

const { valuesOfEntities } = engine();

export default {
  name: 'TheNetworkingContacts',
  mixins: [filterHttpQueryMixins],
  data() {
    return {
      loading: false,
      openFilter: false,
      sorted: true,
      page: 1,
      queries: {
        pagin: {
          limit: 12,
          offset: 0,
        },
        location: null,
        search: null,
        by: 'name',
        hasMail: false,
        hasNumber: false,
        refreshed: false,
        contactHistory: null,
        valueTF: 0,
        timeFrame: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      personList: 'TheNetworking/personList',
      windowWidth: 'TheMenu/getterWindowWidth',
    }),
    responsive() {
      if (Number(this.windowWidth) <= 1200) return 1;
      return 2;
    },
    filters() {
      return {
        by: {
          optionsSearch: [
            { name: 'Name', key: 'name' },
            { name: 'Description', key: 'description' },
            { name: 'Head line', key: 'head_line' },
            { name: 'Job', key: 'job' },
          ],
        },
        search: {},
        location: {},
        hasMail: {},
        hasNumber: {},
        refreshed: {},
        lastContact: {
          timeFrames: ['hours', 'days', 'weeks', 'months', 'years'],
          contacts: [
            { label: 'Contacted and search profile', key: 'CONTACT_AND_SEARCH_PROFILE' },
            { label: 'No response', key: 'NO_RESPONSE' },
            { label: 'Contacted but don\'t search profile', key: 'CONTACT_BUT_DONT_SEARCH_PROFILE' },
            { label: 'Contacted on Linkedin', key: 'CONTACTED_ON_LINKEDIN_CONNECTION' },
            { label: 'Contacted per mail', key: 'CONTACT_PER_MAIL' },
          ],
        },
      };
    },
    personFiltered() {
      if (!this.personList || !Object.values(this.personList).length) return [];
      if (!this.search) {
        return Object.values(this.personList)
          .reduce((acc, el) => {
            const detailsValues = valuesOfEntities(el);

            acc.push({ ...el, value: detailsValues });
            return acc;
          }, [])
          .sort((a, b) => this.$moment(b.fetchedAt).unix() - this.$moment(a.fetchedAt).unix());
      }

      return Object.values(this.personList)
        .reduce((acc, person) => {
          const search = this.search.toLowerCase();
          const _fullName = person.fullName.toLowerCase();
          const _jobTitle = person.jobTitle.toLowerCase();

          if (!_fullName.includes(search) && !_jobTitle.includes(search)) return acc;
          const detailsValues = valuesOfEntities(person);

          acc.push({ ...person, value: detailsValues });
          return acc;
        }, [])
        .sort((a, b) => b.value - a.value);
    },
  },
  mounted() { this.fetchPersons(); },
  methods: {
    ...mapActions({
      reportPersonList: 'TheNetworking/reportPersonList',
    }),
    toggleSidePanelFilters() {
      this.openFilter = !this.openFilter;

      if (this.openFilter) document.getElementById('app').classList.add('body-locked');
      else document.getElementById('app').classList.remove('body-locked');
    },
    selectPerson(person) {
      const { pid } = person;
      this.$router.push({ path: `/networking/contacts/${pid}/details` });
    },
    fetchPersons(isFilter = false) {
      this.loading = true;
      if (isFilter) this.resetParameters();
      const httpQuery = Object.entries(this.queries).reduce((acc, [key, value]) => {
        if (key === 'pagin') return acc;
        if (value) acc[key] = value.toString();
        return acc;
      }, {});

      if (!this.queries.search) delete httpQuery.by;
      if (!this.queries.contactHistory) {
        delete httpQuery.valueTF;
        delete httpQuery.timeFrame;
      }
      httpQuery.pagin = `${this.queries.pagin.limit},${this.queries.pagin.offset}`;

      return Promise.resolve()
        .then(() => this.reportPersonList({ httpQuery }))
        .then(() => { this.loading = false; });
    },
    prev() {
      return Promise.resolve()
        .then(() => {
          this.queries.pagin.offset -= this.queries.pagin.limit;
          this.page -= 1;
        })
        .then(() => this.fetchPersons());
    },
    next() {
      return Promise.resolve()
        .then(() => {
          this.queries.pagin.offset += this.queries.pagin.limit;
          this.page += 1;
        })
        .then(() => this.fetchPersons());
    },
    quitPanel() {
      if (this.$route.meta.lockBodySidePanel) this.$router.push({ path: '/networking/contacts' });
      if (this.openFilter) this.openFilter = false;
    },
  },
};
</script>
