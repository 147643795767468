var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{class:['header-top', {
    'mobile-view': _vm.mobileView,
    'computer-view': !_vm.mobileView
  }]},[_c('div',{staticClass:"header-nav top"},[_c('div',{staticClass:"header-logo"},[_c('b-image',{attrs:{"src":_vm.theme ? _vm.logo.white : _vm.logo.black,"alt":"DEVMOOD logo"}})],1),_c('div',{staticClass:"header-nav-item"},[_c('b-button',{attrs:{"type":"is-tertiary"},on:{"click":function($event){return _vm.$router.push({ path: '/notifications' })}}},[_c('b-icon',{staticClass:"m-0 p-0",attrs:{"icon":"bell","pack":"fa"}}),(_vm.notificationCount)?_c('div',{staticClass:"badge notification fly-over"},[_vm._v(" "+_vm._s(_vm.notificationCount)+" ")]):_vm._e()],1),_c('b-button',{attrs:{"type":"is-tertiary"},on:{"click":function($event){return _vm.openDropdown('top')}}},[(_vm.fullName)?_c('InitialsPicture',{staticClass:"item",attrs:{"name":_vm.fullName,"size":40}}):_vm._e()],1),(_vm.open === 'top')?[_c('div',{staticClass:"header-dropdown top"},[_c('div',{staticClass:"header-dropdown-content"},[_vm._l((_vm.menuResponsive.top),function(el){return [(el.active)?_c('div',{key:el.name},[(!el.event)?_c('b-button',{attrs:{"icon-pack":el.pack,"icon-left":el.icon,"type":"is-tertiary","tag":"router-link","to":el.path,"disabled":el.disabled},nativeOn:{"click":function($event){return _vm.redirect(el, false)}}}):_vm._e()],1):_vm._e()]}),(_vm.currentEnv === 'dev')?_c('b-button',{attrs:{"type":"is-tertiary","icon-pack":"fa","icon-left":_vm.devMode ? 'laptop' : 'laptop-code'},on:{"click":function($event){_vm.devMode = !_vm.devMode}}}):_vm._e(),_c('b-button',{class:[{
                'text-color-9': !_vm.theme,
                'text-color-4': _vm.theme,
              }],attrs:{"type":"is-tertiary","icon-pack":"fa","icon-left":_vm.theme ? 'sun' : 'moon'},on:{"click":function($event){return _vm.updateTheme(!_vm.theme)}}}),(_vm.currentEnv === 'dev')?_c('b-button',{class:[{
              }],attrs:{"type":"is-tertiary","icon-pack":"fa","icon-left":"rocket"},on:{"click":function($event){return _vm.toggleWebsocket()}}}):_vm._e(),_c('b-button',{attrs:{"type":"is-tertiary","icon-pack":"fa","icon-left":"power-off"},on:{"click":function($event){return _vm.logout()}}})],2)])]:_vm._e()],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }