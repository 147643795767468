import { use, registerComponent } from '../../$utils/plugins';

import ProjectOverview from './ProjectOverview';
import ProjectKanban from './ProjectKanban';
import ProjectMembers from './ProjectMembers';
import ProjectSettings from './ProjectSettings';
import KanbanForm from './KanbanForm';
import Kanban from './Kanban';
import KanbanColumn from './KanbanColumn';
import KanbanColumnCopy from './KanbanColumnCopy';
import KanbanItem from './KanbanItem';
import KanbanItemCopy from './KanbanItemCopy';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, ProjectOverview);
    registerComponent(Vue, ProjectKanban);
    registerComponent(Vue, ProjectMembers);
    registerComponent(Vue, ProjectSettings);
    registerComponent(Vue, KanbanForm);
    registerComponent(Vue, Kanban);
    registerComponent(Vue, KanbanColumn);
    registerComponent(Vue, KanbanColumnCopy);
    registerComponent(Vue, KanbanItem);
    registerComponent(Vue, KanbanItemCopy);
  },
};

use(Plugin);

export default Plugin;

export {
  ProjectOverview,
  ProjectKanban,
  ProjectMembers,
  ProjectSettings,
  KanbanForm,
  Kanban,
  KanbanColumn,
  KanbanColumnCopy,
  KanbanItem,
  KanbanItemCopy,
};
