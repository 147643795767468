<template>
  <div>
    TheTab
  </div>
</template>

<script>
export default {
  name: 'TheTab',
};
</script>
