<template>
  <section>
    <div class="multibar">
      <h3 class="start title is-medium m-0">
        Confirm new appointment
      </h3>
      <div class="buttons end">
        <b-button
          class="is-primary"
          label="Create new appointment"
          icon-pack="fas"
          icon-left="plus"
          @click="$emit('createNewAppointment')"
        />
      </div>
    </div>

    <br>

    <template v-for="info in infosAppointment">
      <div
        v-if="info"
        :key="info.cardTitle"
        class="card no-border"
      >
        <div class="card-content">
          <p class="subtitle is-big">{{ info.cardTitle }}</p>
          <template v-for="el in info.elements">
            <div
              v-if="el"
              :key="el.label"
            >
              <p class="has-text-secondary text">{{ el.label }}</p>
              <span>{{ el.value }}</span>
            </div>

            <br
              v-if="info"
              :key="`br-${el.label}`"
            >
          </template>
        </div>
      </div>

      <br
        v-if="info"
        :key="`br-${info.cardTitle}`"
      >
    </template>

    <br>

    <div class="buttons is-right">
      <b-button
        class="is-primary"
        label="Create new appointment"
        icon-pack="fas"
        icon-left="plus"
        @click="$emit('createNewAppointment')"
      />
    </div>
  </section>
</template>

<script>
export default {
  name: 'JackSidePanelAddAppointmentConfirmation',
  model: {
    prop: 'newAppointment',
  },
  props: {
    newAppointment: { type: Object, default: () => {} },
  },
  computed: {
    infosAppointment() {
      const {
        details: {
          date, label, description, client,
        },
      } = this.newAppointment;

      const infos = [
        {
          cardTitle: 'Informations appointment',
          elements: [
            { label: 'Date', value: this.$moment(date).format('MMMM Do, YYYY | HH:mm') },
            { label: 'Label', value: label },
            { label: 'description', value: description || 'No description' },
          ],
        },
      ];

      if (client) {
        infos.push({
          cardTitle: 'Informations client',
          elements: [
            { label: 'Full name', value: `${client.firstName} ${client.lastName}` },
            { label: 'Number', value: client.number },
          ],
        });
      }

      return infos;
    },
  },
};
</script>
