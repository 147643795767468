<template>
  <aside class="jackSidePanel">
    <div class="multibar">
      <div class="end buttons">
        <b-button
          class="is-tertiary"
          tag="router-link"
          to="/settings/phantombuster"
          icon-pack="fas"
          icon-right="times"
        />
      </div>
    </div>

    <br>

    <section>
      <div class="multbar">
        <h3 class="start title is-big">
          Phantom Buster's token
        </h3>
      </div>

      <br>

      <template v-if="phantomBusterToken">
        <Helper
          content="The PhantomBuster's token is saved"
          content-type="success"
        />

        <br>
      </template>

      <form>
        <fieldset>
          <b-field
            horizontal
            label="Token"
          >
            <b-input
              v-model="token"
              type="password"
              password-reveal
              icon-pack="fa"
              icon-right="eye"
              required
            />
          </b-field>
        </fieldset>

        <br>

        <div class="buttons multibar">
          <b-button
            type="is-primary"
            label="Save"
            class="end"
            @click="saveToken()"
          />
        </div>
      </form>
    </section>
  </aside>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */
import SocketMixins from '@jack/socket/$utils/SocketMixins.mixins';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TheSettingsPhantomBusterPanelToken',
  mixins: [SocketMixins],
  data() {
    return {
      token: '',
    };
  },
  computed: {
    ...mapGetters({
      phantomBusterToken: 'TheSettingsPhantomBuster/phantomBusterToken',
      currentAccountAid: 'TheSettingsPhantomBuster/currentAccountAid',
    }),
  },
  methods: {
    ...mapActions({
      createToken: 'TheSettingsPhantomBuster/createToken',
      patchToken: 'TheSettingsPhantomBuster/patchToken',
      deleteToken: 'TheSettingsPhantomBuster/deleteToken',
    }),
    saveToken() {
      const payload = { token: this.token };

      if (!this.phantomBusterToken) {
        Promise.all([
          this.createToken({ payload }),
          this.subscribeStream({
            entite: 'token', id: this.currentAccountAid, method: 'addToken', options: { accountAid: this.currentAccountAid },
          }),
        ]);
      }
    },
    _deleteToken() {
      if (!this.phantomBusterToken) return;
      const { pbtid } = this.phantomBusterToken;
      this.deleteToken({ payload: { pbtid } });
    },
  },
};
</script>
