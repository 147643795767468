<template>
  <section>
    <ClientHeader
      :background-url="person.details.backgroundUrl || ''"
      :image-url="person.details.imageUrl || ''"
      :name="person.details.fullName"
    />
    <br>

    <div class="dashboard mt-5">
      <div class="content">
        <div class="multibar is-align-items-center">
          <div class="header-title start">
            <h1 class="title is-big m-0">{{ person.details.fullName }}</h1>
            <p class="has-text-secondary text">{{ person.details.jobTitle }}</p>
          </div>
          <div class="buttons end">
            <b-tooltip
              v-if="person.details.number"
              type="is-dark"
              :label="person.details.number"
              position="is-bottom"
              append-to-body
            >
              <b-button
                type="is-tertiary"
                icon-pack="fa"
                icon-left="phone"
                tag="a"
                :href="`tel:${person.details.number}`"
                target="blank"
                class="ml-3"
              />
            </b-tooltip>

            <b-tooltip
              v-if="person.details.mail"
              type="is-dark"
              :label="person.details.mail"
              position="is-bottom"
              append-to-body
            >
              <b-button
                type="is-tertiary"
                icon-pack="fa"
                icon-left="envelope"
                tag="a"
                :href="`mailto:${person.details.mail}`"
                class="ml-3"
              />
            </b-tooltip>

            <b-tooltip
              v-if="person.details.profileUrl"
              type="is-dark"
              label="Linkedin's URL"
              position="is-bottom"
            >
              <b-button
                v-if="person.source"
                type="is-tertiary"
                icon-pack="fa"
                :icon-left="person.source.toLowerCase()"
                tag="a"
                :href="person.details.profileUrl"
                target="blank"
                class="ml-3"
              />
            </b-tooltip>
          </div>
        </div>

        <br>

        <div
          v-if="person.details.subscribers"
          class="mb-2"
        >
          <span class="text">Followers</span>
          <p class="subtext has-text-secondary">{{ person.details.subscribers }}</p>
        </div>
        <div
          v-if="person.details.jobTitle"
          class="mb-2"
        >
          <span class="text">Job title</span>
          <p class="subtext has-text-secondary">{{ person.details.jobTitle }}</p>
        </div>
        <div
          v-if="person.details.description"
          class="mb-2"
        >
          <span class="text">Description</span>
          <p class="subtext has-text-secondary">{{ person.details.description }}</p>
        </div>
      </div>
    </div>

    <!-- <div class="header-actions end">
<div class="is-flex is-align-items-center">
<div class="buttons ml-3">
  <b-dropdown
    aria-role="list"
    position="is-bottom-left"
    append-to-body
  >
    <template #trigger>
      <b-button
        type="is-tertiary"
        icon-pack="fa"
        icon-left="ellipsis-vertical"
      />
    </template>
    <b-dropdown-item
      v-for="option in options"
      :key="option.key"
      aria-role="listitem"
    >
      <div class="media">
        <b-icon
          pack="fa"
          :icon="option.icon"
        />
        <div class="media-content">
          <p class="text is-small m-0">
            {{ option.label }}
          </p>
        </div>
      </div>
    </b-dropdown-item>
  </b-dropdown>
</div>
</div>
</div> -->
  </section>
</template>

<script>
export default {
  name: 'ClientPerson',
  props: {
    person: { type: Object, required: true },
    options: { type: Array, required: true },
  },
};
</script>
