/* eslint-disable no-multi-spaces */

import TheTags from './TheTags';

export default [
  {
    path: '/tags',
    name: 'Tags',
    component: TheTags,
    meta: {
      hasMenu: true,
      viewStoreNavigation: 'TheTags',
      requireAuth: true,
    },
  },
];
