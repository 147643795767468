<template>
  <!-- eslint-disable max-len -->
  <div>
    <TagAutocomplete
      key-id="cid"
      :entite="company"
      :tag-list="tagList"
      @createTag="$emit('createTag', $event)"
      @deleteTag="$emit('deleteTag', $event)"
    />

    <br>

    <section>
      <template v-if="company.client">
        <p class="subtitle is-big mb-1">Delete client</p>
        <p class="text is-small has-text-secondary">
          By deleting the customer record, you will also delete everything that is related to this record. (Payments, projects, etc ...)
        </p>

        <br>

        <b-button
          type="is-danger"
          icon-left="trash"
          icon-pack="fa"
          expanded
          @click="_deleteClient()"
        />
      </template>
    </section>
  </div>
</template>

<script>
export default {
  name: 'CompanySettings',
  props: {
    company: { type: Object, required: true },
    tagList: { type: Object, required: true },
  },
  methods: {
    _deleteClient() {
      this.$buefy.dialog.confirm({
        title: 'Delete client\'s file',
        message: 'You\'re about to delete this client\'s file. Are you sure you want to continue?',
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        iconPack: 'fa',
        icon: 'triangle-exclamation',
        onConfirm: () => this.$emit('deleteClient'),
      });
    },
  },
};
</script>
