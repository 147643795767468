<template>
  <article v-if="!loading && client">
    <ClientCompany
      v-if="client.clientType === 'COMPANY'"
      :company="client.company"
      :contacts-company="client.contactsCompany"
      :options="options"
    />
    <ClientPerson
      v-if="client.clientType === 'PERSON'"
      :person="client.person"
      :options="options"
    />
    <div class="dashboard">
      <div class="content">
        <div class="dashboard-latest gap-8">
          <article
            v-if="payments"
            class="walletCard no-shadow transparent w-100"
          >
            <div class="multibar">
              <p class="subtitle start m-0">Revenues</p>
            </div>
            <div class="is-flex is-align-items-flex-start">
              <p class="ultraTitle text-color-5 m-0">
                {{ payments.totalRevenu }} €
              </p>
              <span class="has-text-secondary subtext mt-5 ml-3">
                {{ payments.totalPayment }} payout(s)
              </span>
            </div>
          </article>
        </div>

        <div class="dashboard-latest gap-8">
          <section class="mb-3 w-100">
            <h3 class="subtitle">Projects</h3>
            <div
              v-if="client.projectList && client.projectList.length"
              class="dashboard-latest-container"
            >
              <article
                v-for="project in client.projectList"
                :key="project.pid"
                class="w-100 mb-3"
              >
                <ProjectCard :project="project" />
              </article>
            </div>
            <p
              v-else
              class="has-text-secondary text is-small is-flex is-justify-content-center"
            >
              There are no projects related to this client...
            </p>
          </section>

          <section class="mb-3 w-100">
            <h3 class="subtitle">Payments</h3>
            <PaymentList
              v-if="client.paymentList && client.paymentList.length"
              :payment-list="client.paymentList"
            />
            <p
              v-else
              class="has-text-secondary text is-small is-flex is-justify-content-center"
            >
              No payment is linked to this client...
            </p>
          </section>
        </div>
        <DevDetails :cnt="{name: 'client', obj: client}" />
        <!-- <header class="multibar is-align-items-center">
            <div class="header-title start">
              <h1 class="title is-small">
                {{ nameClient }}
              </h1>
            </div>

            <div class="header-actions end">
              <div class="is-flex is-align-items-center">
                <div class="buttons ml-3">
                  <b-dropdown
                    aria-role="list"
                    position="is-bottom-left"
                    append-to-body
                  >
                    <template #trigger>
                      <b-button
                        type="is-tertiary"
                        icon-pack="fa"
                        icon-left="ellipsis-vertical"
                      />
                    </template>
                    <b-dropdown-item
                      v-for="option in options"
                      :key="option.key"
                      aria-role="listitem"
                    >
                      <div class="media">
                        <b-icon
                          pack="fa"
                          :icon="option.icon"
                        />
                        <div class="media-content">
                          <p class="text is-small m-0">
                            {{ option.label }}
                          </p>
                        </div>
                      </div>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </div>
          </header>

          <br>

          <p class="text has-text-secondary">
            {{ detailsClient.description }}
          </p> -->
      </div>
    </div>
  </article>
  <LogoAnimated
    v-else
    class="h-full"
  />
</template>

<script>
/* eslint-disable max-len */
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TheClientsDetails',
  data() {
    return {
      loading: false,
      dropdownOptions: false,
    };
  },
  computed: {
    ...mapGetters({
      client: 'TheClients/userClient',
      currentUid: 'TheClients/currentUid',
    }),
    payments() {
      if (!this.client.paymentList || !this.client.paymentList.length) {
        return {
          totalRevenu: 0,
          totalPayment: 0,
          currentMonth: 0,
          lastMonth: 0,
        };
      }
      const dates = {
        startCurrentMonth: this.$moment().startOf('month').format(),
        endCurrentMonth: this.$moment().endOf('month').format(),
        startLastMonth: this.$moment().subtract(1, 'month').startOf('month').format(),
        endLastMonth: this.$moment().subtract(1, 'month').endOf('month').format(),
      };

      return this.client.paymentList.reduce((acc, payment) => {
        const { createdAt, details: { deposit } } = payment;
        const date = this.$moment(createdAt).format();
        acc.totalRevenu += deposit;

        if (this.$moment(date).isBetween(dates.startCurrentMonth, dates.endCurrentMonth)) acc.currentMonth += deposit;
        if (this.$moment(date).isBetween(dates.startLastMonth, dates.endLastMonth)) acc.lastMonth += deposit;

        return acc;
      }, {
        totalRevenu: 0,
        totalPayment: this.client.paymentList.length,
        currentMonth: 0,
        lastMonth: 0,
      });
    },
    currentCid() {
      return this.$route.params.cid;
    },
    options() {
      return [
        {
          key: 'delete-client', label: 'Delete client', icon: 'trash', color: '2', method: 'deleteClient',
        },
      ];
    },
  },
  mounted() { this.fetchClient(); },
  methods: {
    ...mapActions({
      getClientByUser: 'TheClients/getClientByUser',
    }),
    fetchClient() {
      this.loading = true;
      return Promise.resolve()
        .then(() => this.getClientByUser({
          payload: { uid: this.currentUid, cid: this.currentCid },
          httpQuery: {
            company: 'true',
            personList: 'true',
            projectList: 'true',
            paymentList: 'true',
          },
        }))
        .then(() => { this.loading = false; });
    },
  },
};
</script>
