/* eslint-disable max-len */
import { SnackbarProgrammatic as Snackbar } from 'buefy';
import { dispatchAndForward, dispatchFailure, snackBarFailure } from '../$utils/helpers';

export default {
  getters: {
    companyList: (_state, _getters, _rootState, rootGetters) => {
      const { 'Company/getterCompanyList': companyList } = rootGetters;
      return companyList;
    },
    company: (_state, _getters, _rootState, rootGetters) => {
      const { 'Company/getterCompany': company } = rootGetters;
      return company;
    },
    searchCompany: (_state, _getters, _rootState, rootGetters) => {
      const { 'Company/getterSearchCompany': searchCompany } = rootGetters;
      return searchCompany;
    },
  },
  actions: {
    reportCompanyList({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'Company/report',
        context: { payload, httpQuery },
        config: {
          start: false,
          end: false,
          endContent: 'Company reported successfully',
          error: 'Unable to report company.',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    searchCompanyList({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'Company/search',
        context: { payload, httpQuery },
        config: {
          start: false,
          end: false,
          endContent: 'Company searched successfully',
          error: 'Unable to search company.',
        },
        textNotifier: 'search company.',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    getCompany({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'Company/get',
        context: { payload, httpQuery },
        config: {
          start: false,
          end: false,
          endContent: 'Company get successfully',
          error: 'Unable to get company.',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    updateCompany({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'Company/update',
        context: { payload, httpQuery },
        config: {
          start: false,
          end: true,
          endContent: 'Company updated successfully',
          error: 'Unable to update company.',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    patchCompany({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'Company/patch',
        context: { payload, httpQuery },
        config: {
          start: false,
          end: true,
          endContent: 'Company patched successfully',
          error: 'Unable to patche company.',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    bulkInsertCompanies({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'Company/bulkInsertCompanies/create',
        context: { payload, httpQuery },
        config: {
          start: false,
          end: true,
          endContent: 'Companies added successfully',
          error: 'Unable to add companies.',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    addFavorite({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'Company/favorite/create',
        context: { payload, httpQuery },
        config: {
          start: false,
          end: false,
          endContent: 'Companies added to favorite successfully',
          error: 'Unable to add company to favorite.',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    removeFavorite({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'Company/favorite/delete',
        context: { payload, httpQuery },
        textNotifier: 'remove company in favorite.',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    insertEmployee({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'Company/employee/create',
        context: { payload, httpQuery },
        textNotifier: 'insert employee in company.',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    deleteEmployee({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'Company/employee/delete',
        context: { payload, httpQuery },
        textNotifier: 'delete employee in company.',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    bulkInsertContacts({ dispatch }, { payload = {}, httpQuery = {}, options = {} }) {
      const action = {
        dispatch,
        target: 'Company/bulkInsertContacts/create',
        context: { payload, httpQuery, options },
        config: {
          start: false,
          end: true,
          endContent: 'Contacts are linked to company',
          error: 'Unable to link contacts to company.',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    addCompanyTag({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'Company/tag/post',
        context: { payload, httpQuery },
        config: {
          start: false,
          end: true,
          endContent: 'Tag linked to company successfully',
          error: 'Unable to link a tag to the company.',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    removeCompanyTag({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'Company/tag/delete',
        context: { payload, httpQuery },
        config: {
          start: false,
          end: true,
          endContent: 'Tag unlinked to company successfully',
          error: 'Unable to unlink a tag to the company.',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
  },
};
