<template>
  <aside class="jackSidePanel small filtersPanel">
    <header>
      <div class="w-100 is-flex is-align-items-center is-justify-content-space-between">
        <h2 class="subtitle m-0 p-0">Filters</h2>
        <div class="buttons">
          <b-button
            class="is-tertiary"
            icon-pack="fas"
            icon-right="times"
            @click="$emit('close')"
          />
        </div>
      </div>
    </header>

    <br>

    <template v-if="filters.hasFavorite">
      <section class="filter multibar is-align-items-center">
        <p class="text start m-0">Only favorite</p>

        <b-field class="end">
          <b-switch
            v-model="localQueriesHasFavorite"
            size="is-small"
          />
        </b-field>
      </section>

      <br>
    </template>

    <template v-if="filters.refreshed">
      <section class="filter multibar is-align-items-center">
        <p class="text start m-0">Only refreshed</p>

        <b-field class="end">
          <b-switch
            v-model="localQueriesRefreshed"
            size="is-small"
          />
        </b-field>
      </section>

      <br>
    </template>

    <template v-if="filters.search">
      <section class="filter">
        <p class="text">Search</p>
        <b-field
          v-for="option in filters.by.optionsSearch"
          :key="option.key"
        >
          <b-radio
            v-model="localQueriesBy"
            size="is-small"
            :native-value="option.key"
          >
            {{ option.name }}
          </b-radio>
        </b-field>

        <b-field
          label="Search"
          class="mt-3"
        >
          <b-input
            v-model="localQueriesSearch"
            type="search"
            icon="magnifying-glass"
            icon-pack="fa"
            icon-right="xmark"
            icon-right-clickable
            @icon-right-click="localQueriesSearch = null"
          />
        </b-field>
      </section>

      <br>
    </template>

    <template v-if="filters.lastContact">
      <section class="filter">
        <p class="text">Last contact</p>
        <div class="time-frames-group">
          <b-field label="After">
            <b-input
              v-model="localQueriesValueTF"
              type="number"
              min="1"
            />
          </b-field>
          <b-field
            label="Time frame"
            class="w-100"
          >
            <b-select v-model="localQueriesTimeFrame">
              <option
                v-for="option in filters.lastContact.timeFrames"
                :key="option"
                :value="option"
              >
                {{ option }}
              </option>
            </b-select>
          </b-field>
        </div>
        <b-field
          label="Contact history"
          class="w-100"
        >
          <b-select
            v-model="localQueriesContactHistory"
          >
            <option
              v-for="option in filters.lastContact.contacts"
              :key="option.key"
              :value="option.key"
            >
              {{ option.label }}
            </option>
          </b-select>
        </b-field>
      </section>

      <br>
    </template>

    <template v-if="filters.location">
      <section class="filter">
        <p class="text">Location</p>

        <b-field
          label="Search"
          class="mt-3"
        >
          <b-input
            v-model="localQueriesLocation"
            type="search"
            icon="magnifying-glass"
            icon-pack="fa"
            icon-right="xmark"
            icon-right-clickable
            @icon-right-click="localQueriesLocation = null"
          />
        </b-field>
      </section>

      <br>
    </template>

    <template v-if="filters.hasMail">
      <section class="filter multibar is-align-items-center">
        <p class="text m-0 start">Has a mail</p>

        <b-field class="end">
          <b-switch
            v-model="localQueriesHasMail"
            size="is-small"
          />
        </b-field>
      </section>

      <br>
    </template>

    <template v-if="filters.hasNumber">
      <section class="filter multibar is-align-items-center">
        <p class="text m-0 start">Has a number</p>

        <b-field class="end">
          <b-switch
            v-model="localQueriesHasNumber"
            size="is-small"
          />
        </b-field>
      </section>
    </template>

    <div class="is-flex mt-3">
      <b-button
        icon-pack="fa"
        icon-left="check"
        type="is-success"
        expanded
        @click="refresh()"
      />
      <b-button
        icon-pack="fa"
        icon-right="trash"
        type="is-tertiary"
        expanded
        class="ml-3"
        @click="clear()"
      />
    </div>
  </aside>
</template>

<script>
export default {
  name: 'FilterPanel',
  model: {
    prop: 'queries',
  },
  props: {
    queries: { type: Object, required: true },
    filters: { type: Object, required: true },
  },
  computed: {
    localQueriesBy: {
      get() { return this.queries.by; },
      set(newValue) { this.$emit('update:by', newValue); },
    },
    localQueriesSearch: {
      get() { return this.queries.search; },
      set(newValue) { this.$emit('update:search', newValue); },
    },
    localQueriesLocation: {
      get() { return this.queries.location; },
      set(newValue) { this.$emit('update:location', newValue); },
    },
    localQueriesHasMail: {
      get() { return this.queries.hasMail; },
      set(newValue) { this.$emit('update:hasMail', newValue); },
    },
    localQueriesHasNumber: {
      get() { return this.queries.hasNumber; },
      set(newValue) { this.$emit('update:hasNumber', newValue); },
    },
    localQueriesRefreshed: {
      get() { return this.queries.refreshed; },
      set(newValue) { this.$emit('update:refreshed', newValue); },
    },
    localQueriesHasFavorite: {
      get() { return this.queries.hasFavorite; },
      set(newValue) { this.$emit('update:hasFavorite', newValue); },
    },
    localQueriesContactHistory: {
      get() { return this.queries.contactHistory; },
      set(newValue) { this.$emit('update:contactHistory', newValue); },
    },
    localQueriesValueTF: {
      get() { return this.queries.valueTF; },
      set(newValue) { this.$emit('update:valueTF', newValue); },
    },
    localQueriesTimeFrame: {
      get() { return this.queries.timeFrame; },
      set(newValue) { this.$emit('update:timeFrame', newValue); },
    },
  },
  methods: {
    refresh() {
      this.$emit('refresh');
      this.$emit('close');
    },
    clear() {
      this.$emit('clear');
      this.$emit('refresh');
    },
  },
};
</script>
