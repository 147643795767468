/* eslint-disable import/no-extraneous-dependencies */
import { navySeal } from '@jack/provider';

import generate from './generate.store';
import affiliate from './affiliate.store';

export default {
  namespaced: true,
  state: {
    appointmentToken: {},
    error: null,
  },
  mutations: {
    SET_APPOINTMENT_TOKEN(state, newData) { state.appointmentToken = newData; },
    RESET_APPOINTMENT(state) { state.appointmentToken = {}; },
    SET_ERROR(state, newData) { state.error = newData; },
  },
  getters: {
    getterAppointmentToken: (state) => state.appointmentToken,
    getterAppointmentTokenError: (state) => state.error,
  },
  actions: {
    get({ commit }, { payload = {}, httpQuery = {} }) {
      return navySeal.apiWsJoe.appointmentToken.get({ payload, httpQuery })
        .then((appointmenToken) => {
          commit('SET_APPOINTMENT_TOKEN', appointmenToken);
        })
        .catch(({ message }) => {
          commit('SET_ERROR', message);
        });
    },
    reset({ commit }) { commit('RESET_APPOINTMENT'); },
  },
  modules: {
    generate,
    affiliate,
  },
};
