/* eslint-disable no-multi-spaces */

import TheSettings from './TheSettings';
import TheSettingsTheme from './TheSettingsTheme';
import TheSettingsProfile from './TheSettingsProfile';
import TheSettingsPhantomBuster from './TheSettingsPhantomBuster';
import TheSettingsPhantomBusterPanelToken from './views/TheSettingsPhantomBusterPanelToken';
import TheSettingsPhantomBusterAgent from './views/TheSettingsPhantomBusterAgent';
import TheSettingsProfileAppointmentSettings from './views/TheSettingsProfileAppointmentSettings';

export default [
  {
    path: '/theme',
    name: 'Profile - Theme',
    component: TheSettingsTheme,
    meta: {
      hasMenu: true,
      viewStoreNavigation: 'TheSettings',
      requireAuth: true,
    },
  },
  {
    path: '/phantombuster',
    name: 'Profile - PhantomBuster',
    component: TheSettingsPhantomBuster,
    meta: {
      hasMenu: true,
      viewStoreNavigation: 'TheSettings',
      requireAuth: true,
    },
    children: [
      {
        path: '/phantombuster/agent/:pbaid',
        name: 'Profile - PhantomBuster - Agent',
        meta: {
          hasMenu: true,
          viewStoreNavigation: 'TheSettings',
          requireAuth: true,
          lockBodySidePanel: true,
        },
        components: { agent: TheSettingsPhantomBusterAgent },
      },
      {
        path: '/phantombuster/token',
        name: 'Profile - PhantomBuster - Token',
        meta: {
          hasMenu: true,
          viewStoreNavigation: 'TheSettings',
          requireAuth: true,
          lockBodySidePanel: true,
        },
        components: { editToken: TheSettingsPhantomBusterPanelToken },
      },
    ],
  },
  {
    path: '/profile',
    name: 'Profile - Profile',
    component: TheSettingsProfile,
    meta: {
      hasMenu: true,
      viewStoreNavigation: 'TheSettings',
      requireAuth: true,
    },
    children: [
      {
        path: '/profile',
        name: 'Profile - Appointment - Settings',
        meta: {
          hasMenu: true,
          viewStoreNavigation: 'TheSettings',
          requireAuth: true,
        },
        components: { editAppointmentSettings: TheSettingsProfileAppointmentSettings },
      }],
  },
];
