export default {
  data() {
    return {
      bulks: {
        IMPORT_COMPANY: {
          name: 'Import company',
          cmp: 'BulkImportCompany',
          iconPack: 'fa',
          icon: 'building',
          scriptId: '3296',
          argument: {
            companiesPerLaunch: null,
            delayBetween: null,
            sessionCookie: null,
            spreadsheetUrl: null,
          },
          defaultValueKeys: ['companiesPerLaunch', 'delayBetween'],
        },
      },
    };
  },
};
