import { use, registerComponent } from '../$utils/plugins';

import DevDetails from './DevDetails';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, DevDetails);
  },
};

use(Plugin);

export default Plugin;

export {
  DevDetails,
};
