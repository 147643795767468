<template>
  <aside class="jackSidePanel">
    <div class="multibar">
      <div class="end buttons">
        <b-button
          class="is-ghost"
          tag="router-link"
          :to="pathQuit"
          icon-pack="fas"
          icon-right="times"
        />
      </div>
    </div>

    <br>
    <DevDetails :cnt="{name: 'userProject', obj: userProject}" />

    <JackSidePanelProjectDetails
      v-if="userProject"
      :user-project="userProject"
      :current-uid="myAuthenticate.uid"
      :all-payments="allPayments"
      @isOpen="$event ? openProject() : closeProject()"
    />
  </aside>
</template>

<script>
/* eslint-disable max-len */
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TheClientsSidePannelProject',
  computed: {
    ...mapGetters({
      currentUid: 'TheProjects/currentUid',
      userProject: 'TheProjects/userProject',
      myAuthenticate: 'TheProjects/myAuthenticate',
    }),
    currentPid() {
      return this.$route.params.pid;
    },
    allPayments() {
      if (!this.userProject) return null;
      if (!this.userProject.paymentList) return null;

      let value = 0;
      const projectPrice = this.userProject.price;

      this.userProject.paymentList.reduce((acc, { details: { deposit } }) => {
        value += deposit;
        return acc;
      }, 0);

      return projectPrice - value;
    },
    currentPath() {
      return this.$route.fullPath;
    },
    pathQuit() {
      return this.currentPath.split('/').splice(0, 3).join('/');
    },
  },
  mounted() {
    const payload = { uid: this.currentUid, pid: this.currentPid };
    const httpQuery = {
      details: 'true',
      client: 'true',
      paymentList: 'true',
    };

    return Promise.all([
      this.getProjectByUser({ payload, httpQuery }),
    ]);
  },
  methods: {
    ...mapActions({
      getProjectByUser: 'TheProjects/getProjectByUser',
      setIsOpen: 'TheProjects/setIsOpen',
      unsetIsOpen: 'TheProjects/unsetIsOpen',
    }),
    openProject() {
      if (!this.allPayments) {
        this.$buefy.dialog.confirm({
          title: 'Open project',
          message: 'Do you really want to open this project? The client no longer owes you anything.',
          confirmText: 'Open project',
          type: 'is-success',
          hasIcon: true,
          onConfirm: () => this.setIsOpen({
            payload: { uid: this.currentUid, pid: this.currentPid },
          }),
        });
      } else {
        this.setIsOpen({ payload: { uid: this.currentUid, pid: this.currentPid } });
      }
    },
    closeProject() {
      if (this.allPayments) {
        this.$buefy.dialog.confirm({
          title: 'Close project',
          message: `Do you really want to close this project? The client still owes you ${this.allPayments}€.`,
          confirmText: 'Close project',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => this.unsetIsOpen({
            payload: { uid: this.currentUid, pid: this.currentPid },
          }),
        });
      } else {
        this.unsetIsOpen({ payload: { uid: this.currentUid, pid: this.currentPid } });
      }
    },
  },
};
</script>
