<template>
  <!-- eslint-disable max-len -->
  <section>
    <DevDetails :cnt="{name: 'stepsJson', obj: stepsJson}" />
    <DevDetails :cnt="{name: 'argumentsSchema', obj: argumentsSchema}" />
    <p class="title">{{ agent.name }}</p>
    <b-steps
      v-if="stepsJson"
      v-model="activeStep"
      size="is-small"
      :has-navigation="false"
    >
      <template v-for="(step, index) in stepsJson.steps">
        <b-step-item
          v-if="step"
          :key="step.slug"
          :label="step.title"
          :type="step.type ? step.type : 'is-primary'"
          :clickable="false"
        >
          <br>

          <JackStepControls
            :active-step.sync="localActiveStep"
            :step-detail="`${index + 1}/${stepsJson.steps.length - 1} - ${agent.name}`"
            :next-is-allowed="nextIsAllowed"
            :prev="index > 0 && index !== stepsJson.steps.length - 1"
            :next="index < stepsJson.steps.length - 2"
          />

          <br>

          <Helper
            v-if="step.helpers"
            icon-left="circle-info"
            pack-left="fa"
            :content="step.helpers"
            color="7"
            content-type="info"
          />

          <Helper
            v-if="fonctionnalityCommingSoon"
            icon-left="xmark"
            pack-left="fa"
            :content="`The scrapping of this category (${fonctionnalityCommingSoon}) is not yet implemented.`"
            color="2"
            content-type="danger"
          />

          <form @submit.prevent="$emit('launchAgent', argument)">
            <template v-if="step.fields">
              <div
                v-for="(field, i) in formatFields(step.fields)"
                :id="field.key"
                :key="`${field.label}-field-${i}`"
                class="mb-3 multibar"
              >
                <div class="start w-50">
                  <span class="text is-medium">
                    {{ field.label }}
                  </span>
                  <b-tooltip
                    v-if="field.helpers"
                    :key="`${field.label}-tooltip-${i}`"
                    type="is-dark"
                    :label="Array.isArray(field.helpers) ? field.helpers.join('.\n') : field.helpers"
                    append-to-body
                    multilined
                  >
                    <b-icon
                      size="is-small"
                      pack="fa"
                      icon="circle-info"
                    />
                  </b-tooltip>
                </div>

                <b-select
                  v-if="field.type === 'select' && field.enum"
                  :placeholder="field.placeholder"
                  class="w-50"
                  expanded
                  @input="formatPayload({ value: $event, field })"
                >
                  <option
                    v-for="e in field.enum"
                    :key="e"
                    :value="e"
                  >
                    {{ e }}
                  </option>
                </b-select>
                <component
                  :is="inputs[field.type]"
                  :placeholder="field.placeholder"
                  :required="!!field.required"
                  :min="field.minimum"
                  icon-pack="fa"
                  class="end w-50"
                  expanded
                  @input="formatPayload({ value: $event, field })"
                />
              </div>
            </template>

            <template v-if="step.action">
              <div class="multibar">
                <div class="buttons end">
                  <b-button
                    type="is-primary"
                    label="Launch phantom"
                    native-type="submit"
                  />
                </div>
              </div>
            </template>
          </form>
        </b-step-item>
      </template>
    </b-steps>
  </section>
</template>

<script>
/* eslint-disable max-len */
export default {
  name: 'PhantomSteps',
  model: {
    prop: 'activeStep',
  },
  props: {
    agent: { type: Object, required: true },
    activeStep: { type: Number, required: true },
  },
  data() {
    return {
      inputs: {
        string: 'b-input',
        boolean: 'b-switch',
        number: 'b-numberinput',
      },
      argument: {},
      fonctionnalityCommingSoon: false,
      nextIsAllowed: true,
    };
  },
  computed: {
    localActiveStep: {
      get() { return this.activeStep; },
      set(newValue) { this.$emit('update:activeStep', newValue); },
    },
    argumentsSchema() {
      if (!this.agent.name || !this.agent.arguments) return null;
      return JSON.parse(this.agent.arguments);
    },
    stepsJson() {
      if (!this.agent.name || !this.agent.steps) return null;
      const json = JSON.parse(this.agent.steps);

      json.steps[json.steps.length - 1].action = 'launchAgent';
      json.steps.push({
        slug: 'success',
        title: 'Success',
        type: 'is-success',
        helpers: [
          'For a question of security of your Linkedin account, the script takes several minutes to scrape the data you have requested. Please wait a few minutes to receive the results of your search',
          `The phantom ${this.agent.name} was successfully launched`,
        ],
      });
      return json;
    },
  },
  watch: {
    argument(newVal) {
      const categoriesComingSoon = ['Content', 'Groups', 'Schools', 'Events', 'Services', 'Courses'];
      switch (this.agent.scriptId) {
        case '3149':
          if (categoriesComingSoon.includes(newVal.category)) {
            this.fonctionnalityCommingSoon = newVal.category;
            this.nextIsAllowed = false;
          } else {
            this.fonctionnalityCommingSoon = null;
            this.nextIsAllowed = true;
          }
          break;
        default: break;
      }
    },
  },
  methods: {
    formatFields(fields) {
      const keys = Object.keys(fields);
      const { required, properties } = this.argumentsSchema;

      return Object.entries(properties).reduce((acc, [key, values]) => {
        if (!keys.includes(key)) return acc;
        acc.push({
          key,
          ...values,
          ...fields[key],
          type: values.enum ? 'select' : values.type,
          required: !!required.includes(key),
        });
        return acc;
      }, []);
    },
    formatPayload({ value, field: { key } }) {
      this.argument = {
        ...this.argument,
        [key]: value,
      };
    },
  },
};
</script>
