import agents from './agents.store';
import containers from './containers.store';
import token from './token.store';
import organization from './organization.store';
import user from './user.store';

export default {
  namespaced: true,
  modules: {
    agents,
    containers,
    organization,
    token,
    user,
  },
};
