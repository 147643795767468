/* eslint-disable import/no-extraneous-dependencies */
import { navySeal } from '@jack/provider';
import { isActionAllowed } from '@jack/dataStores/$utils/helpers';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {},
  actions: {
    create({ rootGetters, dispatch }, { payload = {}, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'BULK_INSERT_PERSONS.CREATE', dispatch)) return undefined;

      return navySeal.apiWsMi.person.bulkInsertPersons.post({ payload, httpQuery });
    },
  },
};
