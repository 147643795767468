<template>
  <section>
    <header class="multibar is-align-items-center">
      <div class="header-title start">
        <p class="title is-small">Alerts</p>
      </div>
    </header>

    <br>

    <div
      v-if="currentJob"
      class="jobs"
    >
      <div class="job-poster">
        <div class="header">
          <div class="images">
            <img
              :src="currentJob.details.logoUrl"
              :alt="currentJob.details.companyName"
              class="logo-company"
            >
            <div
              v-if="currentJob.details.isPromoted"
              class="pellets is-promoted"
            >
              <b-icon
                pack="fa"
                icon="medal"
              />
            </div>
            <div class="pellets source">
              <b-icon
                :pack="sources[currentJob.source].pack"
                :icon="sources[currentJob.source].icon"
              />
            </div>
          </div>
          <div class="company-name">
            <b-button
              tag="a"
              :href="currentJob.details.companyUrl"
              :label="currentJob.details.companyName.toUpperCase()"
              class="mt-5 title is-ghost"
              target="blank"
              icon-pack="fa"
              icon-right="arrow-up-right-from-square"
            />
          </div>
        </div>

        <div class="content">
          <h2 class="title is-2 is-flex is-align-items-center">
            <b-button
              tag="a"
              :href="currentJob.details.announcementUrl"
              class="bigtitle is-big p-0"
              target="blank"
              icon-pack="fa"
              icon-right="arrow-up-right-from-square"
              type="is-ghost"
            >
              <span class="bigtitle">{{ currentJob.details.title }}</span>
            </b-button>
          </h2>
          <p class="text is-1 m-0">{{ currentJob.details.location }}</p>
          <p class="text is-1 m-0 has-text-secondary">
            Query: {{ currentJob.details.query }}
          </p>
        </div>
      </div>
      <div class="tin-jobs">
        <b-button
          tag="button"
          class="tin-button text-color-1"
          icon-pack="fa"
          icon-left="xmark"
          @click="jobChangeState(currentJob, 'NOT_INTERESTED')"
        />
        <b-button
          tag="button"
          class="tin-button text-color-2"
          icon-pack="fa"
          icon-left="heart"
          @click="jobChangeState(currentJob, 'INTERESTED')"
        />
      </div>
    </div>
    <NoData
      v-else
      title="No alerts were found... Yet!"
      content="You can fetch new alerts at any time"
      action
      :buttons-action="noDataButtons"
      class="noAlerts"
    />
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TheNetworkingAlert',
  data() {
    return {
      index: 0,
      pagin: {
        min: 0,
        max: 50,
      },
    };
  },
  computed: {
    ...mapGetters({
      jobList: 'TheNetworking/jobList',
      agentList: 'TheNetworking/agentList',
    }),
    currentJob() { return this.jobList[this.index]; },
    sources() {
      return { LINKEDIN: { icon: 'linkedin', pack: 'fa' } };
    },
    agentJobScraper() {
      if (!this.agentList || !this.agentList.length) { return null; }
      return this.agentList.find(({ scriptId }) => scriptId === '6772788738377011');
    },
    noDataButtons() {
      return [{
        buttonType: 'link',
        label: 'Search jobs',
        type: 'is-primary',
        pack: 'fa',
        left: 'ghost',
        to: '/networking/manage-phantoms',
      }];
    },
  },
  mounted() { this.fetchJobList(); },
  methods: {
    ...mapActions({
      reportJobList: 'TheNetworking/reportJobList',
      patchStateJob: 'TheNetworking/patchStateJob',
      launchAgent: 'TheNetworking/launch',
    }),
    jobChangeState({ jid }, state) {
      this.patchStateJob({ payload: { jid, state } });
      this.nextJob();
    },
    nextJob() {
      if (this.index < this.jobList.length) {
        /* eslint-disable no-useless-return */
        this.index = this.index + 1;
        return;
      }
      this.index = 0;
      this.fetchJobList();
    },
    fetchJobList() {
      const { min, max } = this.pagin;
      this.reportJobList({
        httpQuery: {
          pagin: `${min.toString()},${max.toString()}`,
          state: 'AWAITING',
        },
      });
    },
  },
};
</script>
