/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import { navySeal } from '@jack/provider';

export default {
  namespaced: true,
  state: {
    dashboardViewList: null,
  },
  mutations: {
    SET_DASHBOARD_VIEW_LIST(state, newData) { state.dashboardViewList = newData; },
  },
  getters: {
    getterDashboardViewList: (state) => state.dashboardViewList,
  },
  actions: {
    report({ commit }, { payload, httpQuery }) {
      return navySeal.apiWsDivision.user.dashboardView.report({ payload, httpQuery })
        .then((dashboardView) => {
          commit('SET_DASHBOARD_VIEW_LIST', dashboardView);
        });
    },
  },
};
