<template>
  <section>
    <div class="multibar">
      <h3 class="start title is-small">
        Connect your Linkedin cookie session
      </h3>
    </div>

    <br>

    <b-field
      label="Cookie session"
      class="mb-3 test"
      horizontal
    >
      <b-input v-model="form.sessionCookie" />
    </b-field>
  </section>
</template>

<script>
export default {
  name: 'SidePanelAgentConnectToLinkedin',
  model: {
    prop: 'form',
  },
  props: {
    form: { type: Object, required: true },
  },
};
</script>
