/* eslint-disable import/no-extraneous-dependencies */
import { navySeal } from '@jack/provider';
import { isActionAllowed } from '@jack/dataStores/$utils/helpers';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {},
  actions: {
    create({ rootGetters, dispatch }, { payload = {}, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'BULK_INSERT_COMPANIES.CREATE', dispatch)) return undefined;

      return navySeal.apiWsMi.company.bulkInsertCompanies.post({ payload, httpQuery })
        .then((companies) => {
          // commit('UPDATE_COMPANY_CONTACT_HISTORY_LIST', companyContactHistory);
        });
    },
  },
};
