<template>
  <!-- eslint-disable max-len -->
  <b-modal
    v-model="localOpen"
    has-modal-card
    trap-focus
    destroy-on-hide
    aria-role="dialog"
    aria-label="Example Modal"
    close-button-aria-label="Close"
    aria-modal
    @close="close()"
  >
    <template #default>
      <h1 class="title is-small">Schedule appointment parameters</h1>
      <template v-if="appointmentToken.token">
        <br>
        <p class="text is-small">
          The token has been created. You can now send it by email if the entity has an email address or copied the URL
        </p>
      </template>

      <template v-else>
        <FormScheduledAppointment
          :label="queries.label"
          :description="queries.description"
          :duration="queries.duration"
          :email="queries.email"
          :number="queries.number"
          :has-email="hasEmail"
          :has-number="hasNumber"
          @update:email="queries.email = $event"
          @update:number="queries.number = $event"
          @update:label="queries.label = $event"
          @update:description="queries.description = $event"
          @update:duration="queries.duration = $event"
        />
      </template>

      <br>

      <div class="modal-footer">
        <b-tooltip
          v-if="!appointmentToken.token"
          type="is-dark"
          label="Generate token"
          position="is-bottom"
          class="w-100"
        >
          <b-button
            type="is-success"
            icon-pack="fa"
            icon-left="check"
            :disabled="disable"
            expanded
            @click="$emit('genreateAppointmentLink'); copy()"
          />
        </b-tooltip>
        <div
          v-else
          class="is-flex is-align-items-center gap-8 is-justify-content-flex-end"
        >
          <b-tooltip
            :type="!entite.mail ? 'is-danger' : 'is-dark'"
            :label="!entite.mail ? 'No registered email address' : 'Send by email'"
            position="is-bottom"
            class="w-100"
          >
            <b-button
              tag="a"
              type="is-primary"
              icon-pack="fa"
              icon-left="envelope"
              class="w-100"
              :href="mailContent"
              :disabled="!entite.mail"
            />
          </b-tooltip>
          <b-tooltip
            type="is-dark"
            label="Copy link"
            position="is-bottom"
          >
            <b-button
              type="is-tertiary"
              icon-pack="fa"
              icon-left="copy"
              @click="copy()"
            />
          </b-tooltip>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
/* eslint-disable max-len */
import { mapActions } from 'vuex';

export default {
  name: 'ModalScheduleAppointment',
  props: {
    open: { type: Boolean, required: true },
    entite: { type: Object, required: true },
    appointmentToken: { type: Object, required: true },
    type: { type: String, default: 'company' },
  },
  data() {
    return {
      queries: {
        newPerson: false,
        label: null,
        duration: null,
        description: null,
        email: false,
        number: false,
      },
    };
  },
  computed: {
    localOpen: {
      get() { return this.open; },
      set(newValue) { this.$emit('update:openModal', newValue); },
    },
    hasEmail() { return !!this.entite.details.mail; },
    hasNumber() { return !!this.entite.details.number; },
    disable() {
      if (!this.queries.label || !this.queries.duration) return true;
      return false;
    },
    generateUrl() {
      const { token } = this.appointmentToken;
      const valBool = ['mail', 'number'];

      const _queries = Object.entries(this.queries).reduce((acc, [key, value]) => {
        if (!value) return acc;
        if (valBool.includes(key)) {
          acc.push(`${key}=true`);
          return acc;
        }

        acc.push(`${key}=${value}`);
        return acc;
      }, []);

      const httpQuery = _queries.length ? `${_queries.join(';')}` : '';
      const url = `${process.env.VUE_APP_URL}/schedule-appointment?v=${httpQuery};t=${token}`;
      return url;
    },
    mailContent() {
      if (!this.entite.details.email) return '';
      const mail = this.entite.details.email;
      const subject = 'Reserve a time slot for an appointment.';
      const body = `Hello,%0D%0A%0D%0AHere is a link to book an appointment. See you soon. %0D%0A%0D%0A${this.generateUrl}`;

      return `mailto:${mail}?subject=${subject}&body=${body}`;
    },
  },
  methods: {
    ...mapActions({ pushWsNotification: 'TheWsNotifier/push' }),
    close() {
      this.localOpen = false;
      this.$emit('resetAppointmentToken');
    },
    copy() {
      navigator.clipboard.writeText(this.generateUrl);
      this.pushWsNotification({ type: 'info', title: 'Schedule appointment link copied!' });
    },
  },
};
</script>
