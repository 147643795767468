<template>
  <section>
    <div class="multibar">
      <h2 class="start title is-medium">
        The payment was created with successfully ! 🥳
      </h2>
    </div>

    <br>

    <div class="multibar">
      <div class="end buttons">
        <b-button
          label="Quit"
          type="is-ghost"
          tag="router-link"
          :to="{
            path: '/home/calendar/sidePanel',
            query: $route.query
          }"
        />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'JackSidePanelAddPaymentSuccessCreate',
};
</script>
