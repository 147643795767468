<template>
  <section
    :class="[{
      'hide': hide
    }]"
  >
    <p class="subtitle is-small m-0">
      Please enter your email so {{ user.name }} can contact you by email
    </p>

    <b-field
      label="Email"
      class="bg-solitude"
    >
      <b-input
        v-model="localEmail"
        type="email"
        required
      />
    </b-field>

    <ButtonForm
      :disabled="disabled"
      @next="$emit('next')"
    />
  </section>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */
import animationMixins from '../$mixins/animation.mixins';

export default {
  name: 'EmailForm',
  mixins: [animationMixins],
  props: {
    user: { type: Object, default: () => {} },
    person: { type: Object, required: true },
    scPerson: { type: Object, required: true },
    ms: { type: Number, required: true },
  },
  computed: {
    localEmail: {
      get() { return this.person.mail; },
      set(newValue) { this.$emit('update:mail', newValue); },
    },
    disabled() {
      return !!(this.scPerson.validate(this.person).error || !this.person.mail);
    },
  },
};
</script>
