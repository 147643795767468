import { use, registerComponent } from '../../../addons/$utils/plugins';

import TheStyleGuildeMenu from './TheStyleGuildeMenu';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, TheStyleGuildeMenu);
  },
};

use(Plugin);

export default Plugin;

export {
  TheStyleGuildeMenu,
};
