import { use, registerComponent } from '../../$utils/plugins';

import ChangePassword from './ChangePassword';
import AppointmentSettings from './AppointmentSettings';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, ChangePassword);
    registerComponent(Vue, AppointmentSettings);
  },
};

use(Plugin);

export default Plugin;

export {
  ChangePassword,
  AppointmentSettings,
};
