<template>
  <section
    id="TheSettings"
    class="dashboard"
  >
    <div class="drawer is-header">
      <header class="row scrool-hidden header multibar is-align-items-center">
        <div class="start">
          <h1 class="title is-medium">
            Theme
          </h1>
        </div>
      </header>
    </div>
  </section>
</template>

<script>
export default {
  name: 'TheSettingsTheme',
};
</script>
