/* eslint-disable import/no-extraneous-dependencies */
import { navySeal } from '@jack/provider';

export default {
  namespaced: true,
  state: {
    categoriesList: [],
  },
  mutations: {
    SET_CATEGORIES_LIST(state, newData) { state.categoriesList = newData; },
  },
  getters: {
    getterCategoriesList: (state) => state.categoriesList,
  },
  actions: {
    report({ commit }, { payload }) {
      return navySeal.apiWsJoe.categories.report({ payload, httpQuery: {} })
        .then((categories) => { commit('SET_CATEGORIES_LIST', categories); });
    },
  },
};
