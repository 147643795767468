<template>
  <section>
    <div class="header is-flex is-align-items-center is-justify-content-space-between">
      <h2>Full calendar</h2>
      <b-field>
        <b-select
          v-model="initialView"
          placeholder="Select a view"
        >
          <option
            v-for="option in viewsFullCalendar"
            :key="option.value"
            :value="option.value"
          >
            {{ option.name }}
          </option>
        </b-select>
      </b-field>
    </div>

    <DevDetails :cnt="{name: 'calendarOptions', obj: calendarOptions}" />
    <DevDetails :cnt="{name: 'initialView', obj: initialView}" />
    <br>

    <FullCalendar
      ref="fullCalendar"
      :options="calendarOptions"
    />

    <b-modal
      v-model="open"
      @close="dateTargeted = {}"
    >
      <div class="card">
        <div class="card-header">
          <p class="card-title">
            {{ $moment(dateTargeted.date).format('dddd, MMMM Do YYYY | hh:mm:ss') }}
          </p>
        </div>
        <div class="card-content">
          Hello world :)
        </div>
      </div>
    </b-modal>
  </section>
</template>

<script>
import moment from 'moment';
import '@fullcalendar/core/vdom';
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';

export default {
  name: 'TheFullCalendar',
  components: {
    FullCalendar,
  },
  data() {
    return {
      initialView: 'timeGridWeek',
      open: false,
      dateTargeted: {},
    };
  },
  computed: {
    viewsFullCalendar() {
      return [
        { name: 'Day grid month', value: 'dayGridMonth' },
        { name: 'Time grid week', value: 'timeGridWeek' },
        { name: 'List week', value: 'listWeek' },
        { name: 'Day grid week', value: 'dayGridWeek' },
      ];
    },
    calendarOptions() {
      return ({
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
        initialView: this.initialView,
        selectable: true,
        contentHeight: 600,
        dateClick: (info) => { this.eventDate(info); },
      });
    },
  },
  watch: {
    initialView(newVal) {
      if (!newVal) return;
      this.$refs.fullCalendar.getApi().changeView(newVal);
    },
  },
  methods: {
    eventDate(event) {
      this.open = true;
      this.dateTargeted = event;
    },
  },
};
</script>
