<template>
  <section v-if="userProject">
    <div class="multibar is-align-items-center">
      <div class="start">
        <div class="is-flex is-align-items-center">
          <h2 class="title is-small m-0">{{ userProject.label }}</h2>
          <b-icon
            pack="fa"
            icon="circle"
            :class="['ml-2', {
              'has-text-success': !userProject.isOpen,
              'has-text-danger': userProject.isOpen,
            }]"
          />
        </div>
        <p class="text has-text-secondary">
          {{ $moment(userProject.createdAt).format('YYYY-MM-DD') }}
        </p>
      </div>
      <div
        v-if="canUpdateProject"
        class="buttons end"
      >
        <b-button
          type="is-ghost"
          icon-pack="fa"
          icon-left="pen"
          label="Edit"
          @click="openEditView()"
        />
        <b-button
          type="is-primary"
          icon-pack="fa"
          :icon-left="!userProject.isOpen ? 'lock-open' : 'lock'"
          :label="!userProject.isOpen ? 'Open' : 'Close'"
          @click="$emit('isOpen', !userProject.isOpen ? true : false)"
        />
      </div>
    </div>

    <br>

    <p class="text is-small m-0">
      <span class="has-text-secondary">Price of the project:</span> {{ userProject.price }} €
    </p>

    <p
      v-if="allPayments"
      class="text is-small m-0"
    >
      <span class="has-text-secondary">Remainder to pay: </span>
      <span class="has-text-danger">{{ allPayments }} €</span>
    </p>

    <p
      v-else
      class="has-text-success"
    >
      The project was paid
    </p>

    <template v-if="userProject.description">
      <br>

      <p class="subtitle is-small">{{ userProject.description }}</p>
    </template>

    <br>

    <template v-if="userProject.paymentList.length">
      <p class="title is-small">All payments:</p>
      <div
        v-for="payment in userProject.paymentList"
        :key="payment.pid"
      >
        <div class="card">
          <div class="card-content">
            <div class="multibar">
              <div class="start">
                <p class="title is-small m-0">{{ payment.details.deposit }} €</p>
                <p class="text is-small has-text-secondary">
                  {{ $moment(payment.createdAt).format('YYYY-MM-DD') }}
                </p>
              </div>
              <div
                v-if="payment.details.description"
                class="end"
              >
                <p class="text">
                  {{ payment.details.description }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <br>
      </div>
    </template>

    <div class="multibar">
      <div class="buttons end">
        <b-button
          label="See customer"
          type="is-primary"
          icon-pack="fa"
          icon-left="user"
          @click="openCustomer(userProject.client.cid)"
        />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'JackSidePanelProjectDetails',
  props: {
    userProject: { type: Object, required: true },
    currentUid: { type: Number, required: true },
    allPayments: { type: Number, required: true },
  },
  computed: {
    canUpdateProject() {
      if (!this.userProject) return false;
      if (!this.currentUid) return false;

      const { userUid } = this.userProject;

      if (Number(this.currentUid) !== Number(userUid)) return false;

      return true;
    },
    currentPid() {
      return this.$route.params.pid;
    },
    currentPath() {
      return this.$route.fullPath;
    },
    pathEdit() {
      return `${this.currentPath}/edit`;
    },
    currentView() {
      return this.currentPath.split('/')[2];
    },
  },
  methods: {
    openCustomer(cid) {
      const basePath = this.currentPath.split('/').splice(0, 3).join('/');
      if (this.$route.name.includes('Side pannel project')) {
        this.$router.push(`${this.currentPath}/client/${cid}`);
      }
      if (this.$route.name.includes('Side pannel client')) {
        this.$router.push(`${basePath}/client/${cid}`);
      }
    },
    openEditView() {
      this.$router.push(`/projects/${this.currentView}/project/${this.currentPid}/edit`);
    },
  },
};
</script>
