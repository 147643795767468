import TheCGU from './TheCGU';

export default [
  {
    path: '/cgu',
    name: 'Conditions Générales d\'Utilisation',
    component: TheCGU,
    meta: {
      hasMenu: false,
      viewStoreNavigation: 'TheCGU',
      requireAuth: false,
    },
    children: [],
  },
];
