/* eslint-disable max-len */
import { dispatchAndForward, dispatchFailure } from '../$utils/helpers';

export default {
  getters: {
    projectList: (_state, _getters, _rootState, rootGetters) => {
      const { 'Client/project/getterProjectList': projectList } = rootGetters;
      return projectList;
    },
    project: (_state, _getters, _rootState, rootGetters) => {
      const { 'Client/project/getterProject': project } = rootGetters;
      return project;
    },
    kanbanList: (_state, _getters, _rootState, rootGetters) => {
      const { 'Project/kanban/getterKanbanList': kanbanList } = rootGetters;
      return kanbanList;
    },
    kanban: (_state, _getters, _rootState, rootGetters) => {
      const { 'Project/kanban/getterKanban': kanban } = rootGetters;
      return kanban;
    },
    kanbanColumnList: (_state, _getters, _rootState, rootGetters) => {
      const { 'Project/kanbanColumn/getterKanbanColumnList': kanbanColumnList } = rootGetters;
      return kanbanColumnList;
    },
    kanbanColumn: (_state, _getters, _rootState, rootGetters) => {
      const { 'Project/kanbanColumn/getterKanbanColumn': kanbanColumn } = rootGetters;
      return kanbanColumn;
    },
    kanbanItemList: (_state, _getters, _rootState, rootGetters) => {
      const { 'Project/kanbanItem/getterKanbanItemList': kanbanItemList } = rootGetters;
      return kanbanItemList;
    },
    kanbanItem: (_state, _getters, _rootState, rootGetters) => {
      const { 'Project/kanbanItem/getterKanbanItem': kanbanItem } = rootGetters;
      return kanbanItem;
    },
  },
  actions: {
    reportProjectList({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'Client/project/report',
        context: { payload, httpQuery },
        config: {
          start: false,
          end: false,
          error: 'Unable to report project list',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    getProject({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'Client/project/get',
        context: { payload, httpQuery },
        config: {
          start: false,
          end: false,
          error: 'Unable to get project',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    createProject({ dispatch }, { payload = {}, httpQuery = {}, options = {} }) {
      const action = {
        dispatch,
        target: 'Client/project/post',
        context: { payload, httpQuery, options },
        config: {
          start: false,
          end: true,
          endContent: 'Project created successfully',
          error: 'Unable to create new project',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    updateProject({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'Client/project/put',
        context: { payload, httpQuery },
        config: {
          start: false,
          end: true,
          endContent: 'Project updated successfully',
          error: 'Unable to update this project',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    deleteProject({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'Client/project/delete',
        context: { payload, httpQuery },
        config: {
          start: false,
          end: true,
          endContent: 'Project deleted successfully',
          error: 'Unable to delete this project',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    setIsOpen({ dispatch }, { payload }) {
      const action = {
        dispatch,
        target: 'User/project/setIsOpen',
        context: { payload, httpQuery: {} },
        config: {
          start: false,
          end: true,
          endContent: 'The project is open',
          error: 'Unable to open this project',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    unsetIsOpen({ dispatch }, { payload }) {
      const action = {
        dispatch,
        target: 'User/project/unsetIsOpen',
        context: { payload, httpQuery: {} },
        config: {
          start: false,
          end: true,
          endContent: 'The project is close',
          error: 'Unable to close this project',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    getKanban({ dispatch }, { payload = {}, httpQuery = {}, options = {} }) {
      const action = {
        dispatch,
        target: 'Project/kanban/get',
        context: { payload, httpQuery, options },
        config: {
          start: false,
          end: false,
          error: 'Unable to get kanban',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    createKanban({ dispatch }, { payload = {}, httpQuery = {}, options = {} }) {
      const action = {
        dispatch,
        target: 'Project/kanban/post',
        context: { payload, httpQuery, options },
        config: {
          start: false,
          startContent: 'Create new kanban',
          end: true,
          endContent: 'Kanban created successfully',
          error: 'Unable to create new kanban',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    patchKanban({ dispatch }, { payload = {}, httpQuery = {}, options = {} }) {
      const action = {
        dispatch,
        target: 'Project/kanban/patch',
        context: { payload, httpQuery, options },
        config: {
          start: false,
          startContent: 'Patch kanban',
          end: true,
          endContent: 'Kanban patched successfully',
          error: 'Unable to patch kanban',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    deleteKanban({ dispatch }, { payload = {}, httpQuery = {}, options = {} }) {
      const action = {
        dispatch,
        target: 'Project/kanban/delete',
        context: { payload, httpQuery, options },
        config: {
          start: false,
          startContent: 'Delete kanban',
          end: true,
          endContent: 'Kanban deleted successfully',
          error: 'Unable to delete kanban',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    createKanbanColumn({ dispatch }, { payload = {}, httpQuery = {}, options = {} }) {
      const action = {
        dispatch,
        target: 'Project/kanbanColumn/post',
        context: { payload, httpQuery, options },
        config: {
          start: false,
          startContent: 'Create kanban column',
          end: true,
          endContent: 'Kanban column created successfully',
          error: 'Unable to create kanban column',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    updateKanbanColumn({ dispatch }, { payload = {}, httpQuery = {}, options = {} }) {
      const action = {
        dispatch,
        target: 'Project/kanbanColumn/update',
        context: { payload, httpQuery, options },
        config: {
          start: false,
          startContent: 'Update kanban column',
          false: true,
          endContent: 'Kanban column updated successfully',
          error: 'Unable to update kanban column',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    patchKanbanColumn({ dispatch }, { payload = {}, httpQuery = {}, options = {} }) {
      const action = {
        dispatch,
        target: 'Project/kanbanColumn/patch',
        context: { payload, httpQuery, options },
        config: {
          start: false,
          startContent: 'Patch kanban column',
          end: true,
          endContent: 'Kanban column patched successfully',
          error: 'Unable to patch kanban column',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    deleteKanbanColumn({ dispatch }, { payload = {}, httpQuery = {}, options = {} }) {
      const action = {
        dispatch,
        target: 'Project/kanbanColumn/delete',
        context: { payload, httpQuery, options },
        config: {
          start: false,
          startContent: 'Delete kanban column',
          end: true,
          endContent: 'Kanban column deleted successfully',
          error: 'Unable to delete kanban column',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    createKanbanItem({ dispatch }, { payload = {}, httpQuery = {}, options = {} }) {
      const action = {
        dispatch,
        target: 'Project/kanbanItem/post',
        context: { payload, httpQuery, options },
        config: {
          start: false,
          startContent: 'Create kanban item',
          end: true,
          endContent: 'Kanban item created successfully',
          error: 'Unable to create kanban item',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    updateKanbanItem({ dispatch }, { payload = {}, httpQuery = {}, options = {} }) {
      const action = {
        dispatch,
        target: 'Project/kanbanItem/update',
        context: { payload, httpQuery, options },
        config: {
          start: false,
          startContent: 'Update kanban item',
          end: false,
          endContent: 'Kanban item updated successfully',
          error: 'Unable to update kanban item',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    patchKanbanItem({ dispatch }, { payload = {}, httpQuery = {}, options = {} }) {
      const action = {
        dispatch,
        target: 'Project/kanbanItem/patch',
        context: { payload, httpQuery, options },
        config: {
          start: false,
          startContent: 'Patch kanban item',
          end: true,
          endContent: 'Kanban item patched successfully',
          error: 'Unable to patch kanban item',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    deleteKanbanItem({ dispatch }, { payload = {}, httpQuery = {}, options = {} }) {
      const action = {
        dispatch,
        target: 'Project/kanbanItem/delete',
        context: { payload, httpQuery, options },
        config: {
          start: false,
          startContent: 'Delete kanban item',
          end: true,
          endContent: 'Kanban item deleted successfully',
          error: 'Unable to delete kanban item',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    addTag({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'Project/tag/post',
        context: { payload, httpQuery },
        config: {
          start: false,
          end: false,
          endContent: 'Tag linked to project successfully',
          error: 'Unable to link a tag to the project.',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
    removeTag({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'Project/tag/delete',
        context: { payload, httpQuery },
        config: {
          start: false,
          end: false,
          endContent: 'Tag unlinked to project successfully',
          error: 'Unable to unlink a tag to the project.',
        },
      };

      return dispatchAndForward(action)
        .catch(() => dispatchFailure(action));
    },
  },
};
