import { use, registerComponent } from '../../$utils/plugins';

const Plugin = {
  install(Vue) {
  },
};

use(Plugin);

export default Plugin;

export {
};
