/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import { navySeal } from '@jack/provider';

export default {
  namespaced: true,
  state: {
    clientList: null,
    client: null,
  },
  mutations: {
    SET_CLIENT_LIST(state, newData) { state.clientList = newData; },
    SET_CLIENT(state, newData) { state.client = newData; },
  },
  getters: {
    getterClientList: (state) => state.clientList,
    getterClient: (state) => state.client,
  },
  actions: {
    report({ commit }, { payload, httpQuery = {} }) {
      return navySeal.apiWsDivision.user.client.report({ payload, httpQuery })
        .then((clients) => {
          commit('SET_CLIENT_LIST', clients.arrayMapper('cid'));
        });
    },
    get({ commit }, { payload, httpQuery = {} }) {
      return navySeal.apiWsDivision.user.client.get({ payload, httpQuery })
        .then(({ details, ...rest }) => {
          commit('SET_CLIENT', { ...details, ...rest });
        });
    },
  },
};
