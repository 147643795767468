<template>
  <!-- eslint-disable max-len -->
  <section>
    <header class="multibar is-align-items-center">
      <div class="header-title start">
        <p class="title is-small">Manage phantoms</p>
      </div>
    </header>

    <br>

    <DevDetails :cnt="{name: 'agentList', obj: agentList}" />

    <template v-if="phantomBusterNeeded.length > 1">
      <div class="multibar">
        <div class="buttons end">
          <b-tooltip
            type="is-dark"
            label="PhantomBuster's settings"
            position="is-bottom"
            append-to-body
          >
            <b-button
              tag="router-link"
              type="is-tertiary"
              icon-pack="fa"
              icon-left="ghost"
              to="/settings/phantombuster"
            />
          </b-tooltip>
        </div>
      </div>

      <br>

      <Helper
        icon-left="circle-info"
        pack-left="fa"
        :content="phantomBusterNeeded"
        color="7"
        content-type="info"
      />

      <br>
    </template>

    <template v-else>
      <div class="is-flex is-align-items-center">
        <b-select
          v-model="agent"
          icon-pack="fa"
          icon="ghost"
          class="w-100"
          @input.native="reset()"
        >
          <option
            v-for="a in agentList"
            :key="a.slug"
            :value="a"
          >
            {{ a.name }}
          </option>
        </b-select>

        <b-tooltip
          type="is-dark"
          label="PhantomBuster's settings"
          position="is-bottom"
          append-to-body
        >
          <b-button
            tag="router-link"
            type="is-tertiary"
            icon-pack="fa"
            icon-left="ghost"
            to="/settings/phantombuster"
            class="ml-5"
          />
        </b-tooltip>

        <b-tooltip
          type="is-dark"
          label="History"
          position="is-bottom"
          append-to-body
        >
          <b-button
            type="is-tertiary"
            tag="router-link"
            icon-pack="fa"
            icon-left="list"
            :to="`/networking/manage-phantoms/agent/${agent.pbaid}`"
            class="ml-2"
          />
        </b-tooltip>
      </div>

      <br>

      <PhantomSteps
        v-model="activeStep"
        :agent="agent"
        @update:activeStep="activeStep = $event"
        @launchAgent="launchAgent($event)"
      />
    </template>

    <router-view name="agent" />

    <div
      v-if="$route.meta.lockBodySidePanel"
      class="overlayWithLock"
    />
  </section>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */
import { mapGetters, mapActions } from 'vuex';
import SocketMixins from '@jack/socket/$utils/SocketMixins.mixins';

export default {
  name: 'TheNetworkingManagePhantoms',
  mixins: [SocketMixins],
  data() {
    return {
      dev: false,
      activeStep: 0,
      scripts: {
        3149: {
          name: 'LinkedIn Search Export',
          slug: 'linkedin-search-export',
          insert: {
            Jobs: 'insertJob',
            People: 'insertPerson',
            Companies: 'insertCompany',
          },
        },
        // 2818: { name: 'LinkedIn Auto Connect', slug: 'linkedin-auto-connect', insert: 'contactAndConnect' },
        // 3295: { name: 'LinkedIn Company Employees Export', slug: 'linkedin-companies-employees', insert: 'fetchEmployeesFromCompany' },
        3112: { name: 'LinkedIn Profile Scraper', slug: 'linkedin-profile-scraper', insert: 'updateEmployee' }, // Vérifier que ça fonctionne
        3296: { name: 'LinkedIn Company Scraper.js', slug: 'linkedin-company-scraper', insert: 'insertCompany' },
      },
      inputs: {
        string: 'b-input',
        boolean: 'b-switch',
        number: 'b-numberinput',
      },
      hover: null,
      agent: {},
    };
  },
  computed: {
    ...mapGetters({
      agentList: 'TheNetworking/agentList',
    }),
    phantomBusterNeeded() {
      return Object.entries(this.scripts).reduce((acc, [key, script]) => {
        const { name } = script;
        const agent = Object.values(this.agentList).find(({ scriptId }) => scriptId === key);

        if (agent) return acc;
        acc.push(name);
        return acc;
      }, ['To make the "Jobs" feature work, we need at least these four agents']);
    },
  },
  mounted() {
    if (this.agentList && Object.values(this.agentList).length) {
      /* eslint-disable prefer-destructuring */
      this.agent = Object.values(this.agentList)[0];
    }
  },
  methods: {
    ...mapActions({
      _fetchAgent: 'TheNetworking/fetch',
      _launchAgent: 'TheNetworking/launch',
    }),
    reset() {
      this.activeStep = 0;
      this.arguments = {};
    },
    openDetails(agent) {
      const { pbaid, scriptId } = agent;
      this.$router.push({
        path: `/networking/manage-phantoms/agent/${pbaid}/summary`,
        query: {
          insert: this.scripts[scriptId].insert,
        },
      });
    },
    launchAgent(argument) {
      if (!argument.sessionCookie) {
        this.activeStep = 0;
        return;
      }

      const { pbaid, scriptId } = this.agent;
      const query = argument.spreadsheetUrl || argument.search;

      const payload = { pbaid, arguments: JSON.stringify(argument) };
      if (this.dev) payload.isStub = scriptId;

      Promise.all([
        this._launchAgent({ payload }),
        this.subscribeStream({
          entite: 'agent',
          id: pbaid,
          method: 'launchAgent',
          options: {
            pbaid,
            insert: this.agent.scriptId === '3149'
              ? this.scripts[this.agent.scriptId].insert[argument.category]
              : this.scripts[this.agent.scriptId].insert,
            query,
          },
        }),
        this.activeStep += 1,
      ]);
    },
  },
};
</script>
