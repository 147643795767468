/* eslint-disable prefer-rest-params */
export default new class ioLogger {
  constructor() {
    this.debug = false;
    this.prefix = 'DEVMOOD.io: ';
  }

  info(text, data = ' ') {
    if (!this.debug) return;
    window.console.info(`${this.prefix}${text}`, data);
  }

  error() {
    if (!this.debug) return;
    console.error(this.prefix, 'color:blue', ...arguments);
  }

  warn() {
    if (!this.debug) return;
    console.warn(this.prefix, 'color:blue', ...arguments);
  }

  event(text, data = '') {
    if (!this.debug) return;
    console.info(`${this.prefix}${text}`, 'color:blue', data);
  }
}();
