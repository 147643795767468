<template>
  <section>
    <div class="multibar">
      <h3 class="start title is-small">
        Project information
      </h3>
    </div>

    <br>

    <fieldset>
      <b-field
        horizontal
        label="Project label"
      >
        <b-input
          v-model="newProject.details.label"
          required
        />
      </b-field>
      <b-field
        horizontal
        label="Description"
      >
        <b-input
          v-model="newProject.details.description"
          type="textarea"
        />
      </b-field>
      <b-field
        horizontal
        label="Price"
      >
        <b-numberinput
          v-model="newProject.details.price"
          required
          min="0"
          icon-pack="fas"
        />
      </b-field>
    </fieldset>
  </section>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */
import VModelMixin from '../../../../$mixins/VModelMixin';

export default {
  name: 'JackSidePanelAddProjectInformationForm',
  mixins: [VModelMixin],
  model: {
    prop: 'newProject',
  },
  props: {
    newProject: { type: Object, default: () => {} },
  },
};
</script>
