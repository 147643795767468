/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import { navySeal } from '@jack/provider';
import { isActionAllowed } from '@jack/dataStores/$utils/helpers';

export default {
  namespaced: true,
  state: {
    paymentList: null,
    payment: null,
  },
  mutations: {
    SET_PAYMENT_LIST(state, newData) { state.paymentList = newData; },
    SET_PAYMENT(state, newData) { state.payment = newData; },
  },
  getters: {
    getterPaymentList: (state) => state.paymentList,
    getterPayment: (state) => state.payment,
  },
  actions: {
    report({ commit, rootGetters, dispatch }, { payload = {}, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'PAYMENT.REPORT', dispatch)) return undefined;

      return navySeal.apiWsDivision.user.bookAccount.payment.report({ payload, httpQuery })
        .then((payments) => {
          commit('SET_PAYMENT_LIST', payments);
        });
    },
    get({ commit, rootGetters, dispatch }, { payload = {}, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'PAYMENT.GET', dispatch)) return undefined;

      return navySeal.apiWsDivision.user.bookAccount.payment.get({ payload, httpQuery })
        .then((payment) => {
          commit('SET_PAYMENT', payment);
        });
    },
    post({ dispatch, commit, rootGetters }, { payload = {}, httpQuery = {}, options = {} }) {
      if (!isActionAllowed(rootGetters, 'PAYMENT.CREATE', dispatch)) return undefined;

      return navySeal.apiWsDivision.user.bookAccount.payment.post({ payload, httpQuery })
        .then((results) => {
          const {
            event: {
              eid, accountAid: aid, uid, type, createdAt,
            },
            payment,
          } = results;

          const {
            pid,
            projectPid,
            createdAt: paymentCreatedAt,
            details: { paymentPid, ...details },
          } = payment;

          const { entite } = options;

          const newEvent = {
            eid,
            aid,
            uid,
            createdAt,
            type,
            paymentPid: pid,
            payment: {
              ...details,
              projectPid,
              createdAt: paymentCreatedAt,
            },
            projectPid: null,
            project: null,
            cid: null,
            client: null,
            appointment: null,
            appointmentAid: null,
            sid: null,
            spend: null,
          };
          const { 'Company/getterCompany': company } = rootGetters;
          const { 'Person/getterPerson': person } = rootGetters;

          switch (entite) {
            case 'COMPANY':
              if (company.payments) company.payments.push(payment);
              else company.payments = [payment];
              commit('Company/SET_COMPANY', company, { root: true });
              break;
            case 'PERSON':
              if (person.payments) person.payments.push(payment);
              else person.payments = [payment];
              commit('Person/SET_PERSON', person, { root: true });
              break;
            default:
              break;
          }

          dispatch('User/eventsView/up_addEventEventsViewList', newEvent, { root: true });
          commit('SET_PAYMENT', payment);
        });
    },
    put({ commit, rootGetters, dispatch }, { payload = {}, httpQuery = {} }) {
      if (!isActionAllowed(rootGetters, 'PAYMENT.UPDATE', dispatch)) return undefined;

      return navySeal.apiWsDivision.user.bookAccount.payment.put({ payload, httpQuery })
        .then((payment) => {
          commit('SET_PAYMENT', payment);
        });
    },
    delete({ commit, rootGetters, dispatch }, { payload = {}, httpQuery = {}, options = {} }) {
      if (!isActionAllowed(rootGetters, 'PAYMENT.DELETE', dispatch)) return undefined;
      const { entite } = options;

      return navySeal.apiWsDivision.user.bookAccount.payment.delete({ payload, httpQuery })
        .then(() => {
          const { 'Company/getterCompany': company } = rootGetters;
          const { 'Person/getterPerson': person } = rootGetters;

          switch (entite) {
            case 'COMPANY':
              if (company && company.payments) {
                const newPayment = company.payments.reduce((acc, payment) => {
                  if (Number(payment.pid) !== Number(payload.pid)) acc.push(payment);
                  return acc;
                }, []);
                company.payments = newPayment.length ? newPayment : null;
                commit('Company/SET_COMPANY', company, { root: true });
              }
              break;
            case 'PERSON':
              if (person && person.payments) {
                const newProject = person.payments.reduce((acc, payment) => {
                  if (Number(payment.pid) !== Number(payload.pid)) acc.push(payment);
                  return acc;
                }, []);
                person.payments = newProject.length ? newProject : null;
                commit('Person/SET_PERSON', person, { root: true });
              }
              break;
            default:
              break;
          }
        });
    },
  },
};
