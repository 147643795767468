<template>
  <div>
    TheOverlay
  </div>
</template>

<script>
export default {
  name: 'TheOverlay',
};
</script>
