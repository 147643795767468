/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import { navySeal } from '@jack/provider';

export default {
  namespaced: true,
  state: {
    eventViewList: [],
    eventView: {},
  },
  mutations: {
    SET_EVENTS_VIEW_LIST(state, newData) { state.eventViewList = newData; },
    SET_EVENTS_VIEW(state, newData) { state.eventView = newData; },
  },
  getters: {
    getterEventViewList: (state) => state.eventViewList,
    getterEventView: (state) => state.eventView,
  },
  actions: {
    report({ commit }, { payload, httpQuery }) {
      return navySeal.apiWsDivision.user.eventsView.report({ payload, httpQuery })
        .then((eventsView) => {
          commit('SET_EVENTS_VIEW_LIST', eventsView);
        });
    },
    get({ commit }, { payload, httpQuery }) {
      return navySeal.apiWsDivision.user.eventsView.get({ payload, httpQuery })
        .then((eventsView) => {
          commit('SET_EVENTS_VIEW', eventsView);
        });
    },
    up_addEventEventsViewList({ commit, getters }, event) {
      const { getterEventViewList: oldEventsViewList } = getters;
      const newEventsViewList = [...oldEventsViewList, event];

      commit('SET_EVENTS_VIEW_LIST', newEventsViewList);
    },
  },
};
