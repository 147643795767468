<template>
  <aside class="jackSidePanel">
    <div class="multibar">
      <div class="end buttons">
        <b-button
          class="is-tertiary"
          icon-pack="fas"
          icon-right="times"
          @click="$emit('quit')"
        />
      </div>
    </div>

    <div class="multibar">
      <h2 class="title start">{{ project.label }}</h2>
    </div>
    <p
      v-if="column"
      class="subtitle has-text-secondary"
    >
      {{ column.label }}
    </p>

    <section>
      <p class="subtitle">Delete column</p>

      <b-button
        type="is-danger"
        icon-pack="fa"
        icon-left="trash"
        expanded
        @click="deleteColumn()"
      />
    </section>
  </aside>
</template>

<script>
export default {
  name: 'TheProjectsActivitiesDetailsColumn',
  props: {
    project: { type: Object, required: true },
  },
  computed: {
    pid() { return this.$currentParam('pid'); },
    kid() { return this.$currentParam('kid'); },
    kcid() { return this.$currentParam('kcid'); },
    column() {
      if (!this.project || !this.project.kanbanList || !this.project.kanbanList.length) return null;
      const kanbanList = this.project.kanbanList.arrayMapper('kid');

      return kanbanList[this.kid].columns[this.kcid];
    },
  },
  methods: {
    deleteColumn() {
      this.$emit('deleteColumn', { pid: this.pid, kid: this.kid, kcid: this.kcid });
      this.$emit('quit');
    },
  },
};
</script>
