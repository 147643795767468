<template>
  <div>
    FirstNameForm
  </div>
</template>

<script>
export default {
  name: 'FirstNameForm',
};
</script>
