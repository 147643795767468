<template>
  <b-dropdown
    v-model="filterQuery"
  >
    <template #trigger>
      <b-button
        :label="label"
        type="is-dashed"
      />
    </template>

    <b-dropdown-item>
      <div>el</div>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
export default {
  name: 'RangeFilter',
  model: {
    prop: 'filterQuery',
  },
  props: {
    filterQuery: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    step: {
      type: Number,
      default: 1,
    },
    label: {
      type: String,
      default: 'Filter range',
    },
  },
  computed: {
    min: {
      get() { return this.filterQuery[0]; },
      set(val) {
        this.$emit('input', [val, this.filterQuery[1]]);
      },
    },
    max: {
      get() { return this.filterQuery[1]; },
      set(val) {
        this.$emit('input', [this.filterQuery[0], val]);
      },
    },
  },
};
</script>
