<template>
  <b-collapse
    v-if="open"
    :open="open"
    aria-id="contentAppointmentId"
    class="collapse"
  >
    <div class="collapse-content">
      <div class="content">
        <form>
          <b-field :label="$_('Project')">
            <template v-if="selected">
              <article class="person is-justify-content-center is-align-items-center mt-5">
                <div class="is-flex">
                  <ProjectCard :project="selected" />
                </div>
              </article>

              <br>
            </template>

            <b-select
              v-model="selected"
              :placeholder="$_('Select a project...')"
              :disabled="!projectList || !projectList.length"
            >
              <option
                v-for="project in projectList"
                :key="project.pid"
                :value="project"
              >
                {{ project.details.label }}
              </option>
            </b-select>
          </b-field>

          <br>

          <b-field :label="$_('Description')">
            <b-input
              v-model="payment.description"
              type="textarea"
            />
          </b-field>

          <br>

          <div class="field">
            <label class="label">{{ $_('Deposit') }}</label>
            <div class="number-grouped-select">
              <b-select
                v-model="payment.currency"
                class="select-no-border"
                required
              >
                <option
                  v-for="option in currencies"
                  :key="option.key"
                  :value="option.key"
                >
                  {{ option.name }}
                </option>
              </b-select>
              <b-numberinput
                v-model="payment.deposit"
                required
                expanded
                min="0"
                icon-pack="fas"
                :controls="false"
              />
            </div>
          </div>

          <br>

          <div class="end buttons">
            <b-button
              type="is-primary"
              icon-pack="fa"
              icon-right="check"
              expanded
              :disabled="!payment.currency || !payment.deposit"
              @click="addPayment()"
            />
          </div>
        </form>
      </div>
    </div>
  </b-collapse>
</template>

<script>
import moment from 'moment';

export default {
  name: 'AddPayment',
  props: {
    open: { type: Boolean, required: true },
    currencies: { type: Array, required: true },
    client: { type: Object, required: true },
    projectList: { type: Array, default: () => [] },
  },
  data() {
    return {
      payment: {
        description: null,
        currency: 'EUR',
        deposit: null,
      },
      search: '',
      inputSearch: '',
      selected: null,
    };
  },
  methods: {
    addPayment() {
      if (!this.payment.currency || !this.payment.deposit) return;
      this.$emit('addPayment', {
        createdAt: moment().format(),
        projectPid: this.selected ? this.selected.pid : null,
        clientCid: this.client.cid,
        cid: this.client.cid,
        details: { ...this.payment },
      });
    },
  },
};
</script>
