/* eslint-disable max-len */
import { SnackbarProgrammatic as Snackbar } from 'buefy';
import { dispatchAndForward, dispatchFailure, snackBarFailure } from '../$utils/helpers';

export default {
  getters: {
    containerList: (_state, _getters, _rootState, rootGetters) => {
      const { 'PhantomBuster/containers/getterContainerList': containerList } = rootGetters;

      return containerList;
    },
    container: (_state, _getters, _rootState, rootGetters) => {
      const { 'PhantomBuster/containers/getterContainer': container } = rootGetters;

      return container;
    },
  },
  actions: {
    reportContainers({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'PhantomBuster/containers/report',
        context: { payload, httpQuery },
        textNotifier: 'fetch all and insert containers',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to fetch all and insert containers',
            ...snackBarFailure,
            indefinite: false,
          });
          return dispatchFailure(action);
        });
    },
    getContainers({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'PhantomBuster/containers/get',
        context: { payload, httpQuery },
        textNotifier: 'fetch all and insert containers',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to fetch all and insert containers',
            ...snackBarFailure,
            indefinite: false,
          });
          return dispatchFailure(action);
        });
    },
    fetchAndUpdate({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'PhantomBuster/containers/fetchAndUpdate',
        context: { payload, httpQuery },
        textNotifier: 'fetch and update container',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to fetch and update container',
            ...snackBarFailure,
            indefinite: false,
          });
          return dispatchFailure(action);
        });
    },
    fetchAllAndInsertContainers({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'PhantomBuster/containers/fetchAllAndInsert',
        context: { payload, httpQuery },
        textNotifier: 'fetch all and insert containers',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to fetch all and insert containers',
            ...snackBarFailure,
            indefinite: false,
          });
          return dispatchFailure(action);
        });
    },
    fetchResult({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'PhantomBuster/containers/fetchResult',
        context: { payload, httpQuery },
        textNotifier: 'fetch container result',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to fetch container result',
            ...snackBarFailure,
            indefinite: false,
          });
          return dispatchFailure(action);
        });
    },
    fetchOutput({ dispatch }, { payload = {}, httpQuery = {} }) {
      const action = {
        dispatch,
        target: 'PhantomBuster/containers/fetchOutput',
        context: { payload, httpQuery },
        textNotifier: 'fetch container output',
        retry: { onSuccess: false, onFailure: false },
      };

      return dispatchAndForward(action)
        .catch(() => {
          Snackbar.open({
            message: 'Unable to fetch container output',
            ...snackBarFailure,
            indefinite: false,
          });
          return dispatchFailure(action);
        });
    },
  },
};
