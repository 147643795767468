<template>
  <div>
    TheSeparator
  </div>
</template>

<script>
export default {
  name: 'TheSeparator',
};
</script>
