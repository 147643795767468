<template>
  <!-- eslint-disable max-len -->
  <div>
    <div class="multibar">
      <div class="buttons end">
        <b-button
          type="is-tertiary"
          :icon-left="!openKanbanCreate ? 'plus' : 'minus'"
          icon-pack="fa"
          :class="[{
            'turn-signal': !Object.values(kanbanList).length
          }]"
          @click="openKanbanCreate = !openKanbanCreate"
        />
      </div>
    </div>

    <KanbanColumnCopy
      v-if="selectedColumn"
      :column="selectedColumn"
      :pos-mouse="posMouse"
    />

    <!-- <KanbanItemCopy
      v-if="selectedItem"
      :item="selectedItem"
      :pos-mouse="posMouse"
    /> -->

    <DevDetails :cnt="{name: 'kanbanList', obj: { kanbanList }}" />
    <KanbanForm
      v-if="openKanbanCreate"
      :user-uid="currentUid"
      @createKanban="createKanban($event)"
    />

    <section v-else>
      <br>
      <template v-if="kanbanList && Object.values(kanbanList).length">
        <b-tabs
          v-model="activeTab"
          type="is-toggle-rounded"
          size="is-small left"
          class="kanban-tabs"
        >
          <template>
            <b-tab-item
              v-for="(k, i) in kanbanList"
              :key="k.kid"
              :value="i"
              :label="k.label"
            >
              <Kanban
                ref="kanban"
                :kid="k.kid"
                :columns="k.columns"
                :index="i"
                @openPanel="openPanel($event)"
                @update:orderKanbanColumn="updateOrderKanbanColumn($event)"
                @update:orderKanbanItem="updateOrderKanbanItem($event)"
                @selected:column="setSelected({ type: 'set:column', obj: $event })"
                @unselected:column="setSelected({ type: 'unset' })"
                @selected:item="setSelected({ type: 'set:item', obj: $event })"
                @unselected:item="setSelected({ type: 'unset' })"
              />
            </b-tab-item>
          </template>
        </b-tabs>
      </template>
    </section>

    <router-view
      v-for="name in routerNames"
      :key="name"
      :name="name"
      :project="project"
      :kanban-list="kanbanList"
      @createColumn="createKanbanColumn($event)"
      @deleteColumn="deleteColumn($event)"
      @createItem="createKanbanItem($event)"
      @deleteItem="deleteItem($event)"
      @quit="quitPanel()"
      @scroll="scroll($event)"
    />

    <div
      v-if="$route.meta.lockBodySidePanel"
      class="overlayWithLock"
      @click="quitPanel()"
    />
  </div>
</template>

<script>
/* eslint-disable object-curly-newline */
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TheProjectsActivities',
  data() {
    return {
      openKanbanCreate: false,
      panelCreateColumn: false,
      activeTab: 0,
      routerNames: ['addColumn', 'columnDetail', 'addItem', 'itemDetails'],
    };
  },
  computed: {
    ...mapGetters({
      project: 'TheProjects/userProject',
      currentUid: 'TheProjects/currentUid',
      kanbanList: 'TheProjects/kanbanList',
      selectedColumn: 'TheProjects/getterSelectedColumn',
      selectedItem: 'TheProjects/getterSelectedItem',
      posMouse: 'TheMenu/getterPosMouse',
    }),
    currentPid() { return this.$currentParam('pid'); },
  },
  methods: {
    ...mapActions({
      setSelected: 'TheProjects/setSelected',
      _createKanban: 'TheProjects/createKanban',
      _createColumn: 'TheProjects/createKanbanColumn',
      _createItem: 'TheProjects/createKanbanItem',
      _deleteColumn: 'TheProjects/deleteKanbanColumn',
      _deleteItem: 'TheProjects/deleteKanbanItem',
      _updateKanbanColumn: 'TheProjects/updateKanbanColumn',
      _updateKanbanItem: 'TheProjects/updateKanbanItem',
    }),
    scroll(to) {
      const kanban = this.$refs.kanban[this.activeTab];
      if (to === 'right') kanban.scrollLeft = (kanban.scrollLeftMax);
      if (to === 'left') kanban.scrollLeft = 0;
    },
    createKanban(payload) {
      return Promise.resolve()
        .then(() => this._createKanban({ payload }))
        .then(() => { this.openKanbanCreate = false; });
    },
    createKanbanColumn(payload) { this._createColumn({ payload }); },
    deleteColumn(payload) { this._deleteColumn({ payload }); },
    createKanbanItem(payload) { this._createItem({ payload }); },
    deleteItem(payload) { this._deleteItem({ payload }); },
    openPanel({ type, ...args }) {
      const _base = `/projects/${this.currentPid}/activities/${args.kid}/columns`;
      switch (type) {
        case 'column:details': this.$router.push({ path: `${_base}/${args.kcid}` }); break;
        case 'column:add': this.$router.push({ path: `${_base}/add` }); break;
        case 'item:details': this.$router.push({ path: `${_base}/${args.kcid}/items/${args.kiid}` }); break;
        case 'item:add': this.$router.push({ path: `${_base}/${args.kcid}/items/add` }); break;
        default:
          break;
      }
    },
    quitPanel() {
      if (!this.$route.meta.lockBodySidePanel) return;
      this.$router.push({
        path: `/projects/${this.currentPid}/activities/`,
      });
    },
    updateOrderKanbanColumn(payload) {
      this.setSelected({ type: 'unset' });
      this._updateKanbanColumn({ payload: { ...payload, pid: this.currentPid } });
    },
    updateOrderKanbanItem(payload) {
      this.setSelected({ type: 'unset' });
      this._updateKanbanItem({ payload: { ...payload, pid: this.currentPid } });
    },
  },
};
</script>
