/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import { getters, actions } from '@jack/dataStores/commonViewStores';

export default {
  namespaced: true,
  state: {
    selected: {
      column: null,
      item: null,
    },
  },
  mutations: {
    SET_SELECTED_COLUMN(state, newData) { state.selected.column = newData; },
    SET_SELECTED_ITEM(state, newData) { state.selected.item = newData; },
    UNSET_SELECTED(state) { state.selected = { column: null, item: null }; },
  },
  getters: {
    ...getters.authenticate,
    ...getters.client,
    ...getters.project,
    ...getters.user,
    getterSelectedColumn: (state) => state.selected.column,
    getterSelectedItem: (state) => state.selected.item,
  },
  actions: {
    ...actions.client,
    ...actions.project,
    ...actions.user,
    setSelected({ commit }, { type, obj }) {
      switch (type) {
        case 'set:column': commit('SET_SELECTED_COLUMN', obj); break;
        case 'set:item': commit('SET_SELECTED_ITEM', obj); break;
        case 'unset': commit('UNSET_SELECTED'); break;
        default: commit('UNSET_SELECTED'); break;
      }
    },
  },
};
